<template>
  <alert-error>
    <div class="d-flex">
      <span class="d-block">{{ msg }}</span>
      <v-spacer />
      <v-btn
        color="white"
        variant="outlined"
        size="small"
        @click="$emit('try-again')"
      >
        Try Again
      </v-btn>
    </div>
  </alert-error>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AlertError from '@/components/common/AlertError.vue'

export default defineComponent({
  name: 'AlertTryAgain',
  props: {
    msg: {
      type: String,
      required: true,
    },
  },
  components: { AlertError },
})
</script>
