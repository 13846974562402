<template>
  <v-menu transition="slide-y-transition" offset-y>
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        aria-label="Navigation Menu"
        :ripple="false"
        variant="plain"
        depressed
      >
        <v-icon size="x-large">mdi-menu</v-icon>
      </v-btn>
    </template>

    <!-- List -->
    <v-list min-width="180">
      <v-list-item
        v-for="option in menuOptions"
        :key="option.text"
        :disabled="option.disabled"
        :to="option.disabled ? undefined : option.to"
        :ripple="false"
        color="primary"
      >
        <v-list-item-title>{{ option.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'

export type NavMenuListItem = {
  text: string
  to: VueRouterLocation
  disabled?: boolean
}

export default defineComponent({
  name: 'CeMenu',
  props: {
    menuOptions: {
      type: Array as PropType<NavMenuListItem[]>,
      required: true,
    },
  },
})
</script>
