<template>
  <div class="c-CeSlider">
    <div class="slider-title" v-if="title">
      <span class="text-no-wrap">
        {{ title }}
      </span>
      <slot></slot>
    </div>
    <v-slider
      :disabled
      :min="min"
      :max="max"
      :step="step"
      :modelValue
      :tick-size="0"
      :ticks="tickLabels"
      show-ticks="always"
      :track-color="trackColor"
      thumb-color="primary"
      :thumb-label="thumbLabel ? 'always' : undefined"
      :trackFillColor
      @update:model-value="(newValue) => $emit('update:model-value', newValue)"
      hide-details
    >
      <template v-slot:prepend v-if="prependText">
        <span class="text-caption text-grey-darken-1">{{ prependText }}</span>
      </template>
      <template v-slot:append v-if="appendText">
        <span class="text-caption text-grey-darken-1">{{ appendText }}</span>
      </template>
      <template v-slot:thumb-label>
        {{ thumbLabel }}
      </template>
    </v-slider>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { GREY1 } from '@/constants/colors'
import { TickLabels } from '@/types/vuetify3'

export default defineComponent({
  name: 'CeSlider',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    tickLabels: {
      type: Object as PropType<TickLabels>,
      required: false,
    },
    min: {
      type: Number,
      default: 0,
      required: false,
    },
    max: {
      type: Number,
      default: 100,
      required: false,
    },
    step: {
      type: Number,
      default: 3,
      required: false,
    },
    trackColor: {
      type: String,
      default: GREY1.hex,
      required: false,
    },
    trackFillColor: {
      type: String,
      default: GREY1.hex,
      required: false,
    },
    prependText: {
      type: String,
      required: false,
    },
    appendText: {
      type: String,
      required: false,
    },
    thumbLabel: {
      type: String,
      required: false,
    },
  },
})
</script>
<style lang="scss">
.c-CeSlider {
  .v-slider {
    margin: 0px;
  }
  .v-label {
    color: black;
  }
  .v-slider-track__tick-label {
    color: #6c6c6c;
    font-size: 12px;
    padding-top: 21px;
  }
  .slider-title {
    display: flex;
    justify-content: space-between;
    .v-input {
      margin-top: 0px;
      padding-top: 0px;
    }
  }
}
</style>
