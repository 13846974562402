<template>
  <v-dialog :model-value="true" max-width="400" persistent>
    <!--
      NOTE: Do not remove the `persistent` props.
      This will force the user to choose between CANCEL and CONFIRM action.
     -->
    <v-card class="pa-4">
      <h6 class="text-h6 pt-4 px-6 pb-2">{{ title }}</h6>

      <p class="text-black text-subtitle-2 font-weight-regular px-6">
        {{ body }}
      </p>

      <div class="d-flex px-6 pt-6 pb-2">
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          variant="outlined"
          class="mr-2"
          :disabled="isLoading"
          @click="() => $emit('cancel')"
        >
          Cancel
        </v-btn>

        <v-btn
          type="submit"
          color="primary"
          class="elevation-0"
          :loading="isLoading"
          :disabled="isLoading"
          @click="() => $emit('confirm')"
        >
          Confirm
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DialogConfirm',
  props: {
    title: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
</script>
