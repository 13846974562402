import * as Sentry from '@sentry/vue'
import { CeUser } from '@/model/user'
import { RittaConfig } from '@/config'

type IncludeEmail = {
  includeEmail: true
  config: Readonly<RittaConfig>
}
type Options = {} & IncludeEmail

export async function sentryCaptureMessage(
  msg: string,
  severity: Sentry.SeverityLevel,
  opts?: Options
): Promise<void> {
  if (opts?.includeEmail) {
    try {
      const userEmail = await CeUser.getUserEmail(opts.config)
      msg = msg + '; user email: ' + userEmail
    } catch {
      msg = msg + '; could not log user email'
    }
  }
  if (import.meta.env.PROD) {
    Sentry.captureMessage(msg, severity)
  } else {
    console.error(msg)
  }
}
