// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/pagination.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ListValue, proto3, Value } from "@bufbuild/protobuf";

/**
 * @generated from message ritta.frontend.proto.FilterBy
 */
export const FilterBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.FilterBy",
  () => [
    { no: 1, name: "property", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "equals", kind: "message", T: Value, oneof: "criteria" },
    { no: 3, name: "any_of", kind: "message", T: ListValue, oneof: "criteria" },
    { no: 4, name: "in_range", kind: "message", T: FilterBy_Range, oneof: "criteria" },
  ],
);

/**
 * Ranges can use numeric or string values.
 *
 * @generated from message ritta.frontend.proto.FilterBy.Range
 */
export const FilterBy_Range = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.FilterBy.Range",
  () => [
    { no: 1, name: "min", kind: "message", T: Value },
    { no: 2, name: "max", kind: "message", T: Value },
  ],
  {localName: "FilterBy_Range"},
);

/**
 * @generated from message ritta.frontend.proto.FilterOptions
 */
export const FilterOptions = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.FilterOptions",
  () => [
    { no: 1, name: "property", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "options", kind: "message", T: ListValue },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GroupBy
 */
export const GroupBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GroupBy",
  () => [
    { no: 1, name: "property", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_keys", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.OrderBy
 */
export const OrderBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.OrderBy",
  () => [
    { no: 1, name: "property", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "descending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

