import { DateTime, Duration, Interval } from 'luxon'

/**
 * Returns an iterable of Intervals that move forward in time.
 * Each interval is `tileSizeInWeeks` weeks long. The first tile contains the `start` date,
 * and the tile begins at the start of the week.
 */
export function tileIntervalsAfter(
  tileSizeInWeeks: number,
  start = DateTime.now()
): Generator<Interval> {
  return generateIntervals('forward', tileSizeInWeeks, start)
}

/**
 * Returns an iterable of Intervals that move backward in time.
 * Each interval is `tileSizeInWeeks` weeks long. The first tile contains the `start` date,
 * and the tile begins at the start of the week.
 */
export function tileIntervalsBefore(
  tileSizeInWeeks: number,
  start = DateTime.now()
): Generator<Interval> {
  return generateIntervals('backward', tileSizeInWeeks, start)
}

function* generateIntervals(
  direction: 'forward' | 'backward',
  tileSizeInWeeks: number,
  start: DateTime
): Generator<Interval> {
  // Reset the start to the beginning of the tile based on "start"
  let current = tileStartFromDateTime(start, tileSizeInWeeks)
  // Create a duration that spans the size of the tile
  const duration = Duration.fromObject({ weeks: tileSizeInWeeks })

  while (current.isValid) {
    let interval = Interval.after(current, duration)
    // Make sure the tile does not extend past start
    if (direction === 'backward' && interval.end > start) {
      interval = interval.set({ end: start })
    } else if (direction === 'forward' && interval.start < start) {
      interval = interval.set({ start })
    }

    yield interval

    if (direction === 'forward') {
      current = current.plus(duration)
    } else {
      current = current.minus(duration)
    }
  }
}

function tileStartFromDateTime(input: DateTime, tileSize: number): DateTime {
  // Determine what ISO-week number we're in. From there, we can see what tile
  // that falls into based on the tile size. A tile starts at ISO week 1 and is "tileSize"
  // weeks long.
  const tileNumber = Math.floor((input.weekNumber - 1) / tileSize)

  return input.set({ weekNumber: tileNumber * tileSize + 1 }).startOf('week')
}
