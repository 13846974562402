import { PeriodAssessed, PresenceBy } from '@/constants/voltageMapping'
import { defineImmutableStore } from './defineStore'
import { Timespan } from 'rfs/frontend/proto/hosting_pb'

export interface VoltageMappingProps {
  timespan: Timespan
  magnitudeDeviation: number
  metersOrTime: number
  metersOrTimeOption: string
}

interface VoltageDeviations {
  [areaId: string]: number
}

interface SelectedZone {
  zoneId: string | null
  voltage: string | null
}

interface State {
  prevalenceUnder: VoltageDeviations
  prevalenceOver: VoltageDeviations
  selectedZone: SelectedZone
}

export const useVoltageMapStore = defineImmutableStore('voltageMap', {
  state(): State {
    return {
      prevalenceUnder: {},
      prevalenceOver: {},
      selectedZone: { zoneId: null, voltage: null },
    }
  },
  actions: {
    async getVoltageDeviations(payload: VoltageMappingProps): Promise<void> {
      const { timespan, magnitudeDeviation, metersOrTimeOption, metersOrTime } =
        payload

      if (!PeriodAssessed.includes(timespan)) {
        throw new Error(
          `Invalid Period assessed. Please choose a valid assessed period.`
        )
      }

      try {
        const { prevalenceUnder, prevalenceOver } =
          await this.services.hostingService.voltageDeviations({
            timespan,
            voltagePercent: magnitudeDeviation,
            criteria:
              metersOrTimeOption === PresenceBy.METERS
                ? { case: 'meterPercent', value: metersOrTime }
                : { case: 'timePercent', value: metersOrTime },
          })
        this.prevalenceUnder = prevalenceUnder
        this.prevalenceOver = prevalenceOver
      } catch (err) {
        console.error(`getVoltageDeviations: unable to load ${err}`)
      }
    },
    setSelectedZone(selectedZone: SelectedZone): void {
      this.selectedZone = selectedZone
    },
  },
})
