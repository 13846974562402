// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/resolution.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Time series resolution for grouping query results 
 *
 * @generated from enum ritta.frontend.proto.Resolution
 */
export const Resolution = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Resolution",
  [
    {no: 0, name: "RAW"},
    {no: 2, name: "ONE_MINUTE"},
    {no: 3, name: "FIVE_MINUTE"},
    {no: 4, name: "FIFTEEN_MINUTE"},
    {no: 5, name: "THIRTY_MINUTE"},
    {no: 6, name: "ONE_HOUR"},
    {no: 7, name: "THREE_HOUR"},
    {no: 8, name: "ONE_DAY"},
  ],
);

