import { DateTime, Interval } from 'luxon'
import type { PlainMessage } from '@bufbuild/protobuf'
import { sortedIndexBy as _sortedIndexBy } from 'lodash-es'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { BLUE3, PURPLE1 } from '@/constants/colors'
import type { TimeSeriesMap } from '@/model/charts'
import {
  type NamedTimeSeries,
  type TimeSeriesConfig,
  filterTimeSeriesWithin,
  findMaxMinMeanY,
} from '@/model/charts/TimeSeriesDataSource'
import { Metric } from '@/constants/metrics'
import { PADDING } from '@/model/control'
import { computeMinMaxMean } from '@/utils/charts'
import { Format } from '@/utils/format'
import { getPartialData } from '@/utils/chartjs'
import {
  ChartType,
  type ChartDefinition,
  type DataPointWithNull,
  type NumberOrNull,
} from '@/types/charts'
import type { Group } from 'rfs/control/proto/model_pb'
import { TimeSeries_DataPoint as TimeSeriesDataPoint } from 'rfs/frontend/proto/tsdb_pb'
import { GroupHelper } from './group/helper'

export type Summary = {
  min: NumberOrNull
  max: NumberOrNull
  mean: NumberOrNull
}

export type Series = DataPointWithNull[]

export type SeriesAndSummary = {
  series: Series
  summary?: Summary
}

export const ACTIVE_POWER_ID = 'active-power-chart'
export const ACTIVE_POWER_TITLE = 'Active Power'

export const REACTIVE_POWER_ID = 'reactive-power-chart'
export const REACTIVE_POWER_TITLE = 'Reactive Power'

export const SOC_ID = 'soc-chart'
export const SOC_TITLE = 'State of Charge'

export const TRANSFORMER_LOADING_ID = 'transformer-loading'
export const TRANSFORMER_LOADING_TITLE = 'Transformer Loading'

export const activePowerChartDefinition: ChartDefinition = {
  id: ACTIVE_POWER_ID,
  title: ACTIVE_POWER_TITLE,
  type: ChartType.Power,
}

export const reactivePowerChartDefinition: ChartDefinition = {
  id: REACTIVE_POWER_ID,
  title: REACTIVE_POWER_TITLE,
  type: ChartType.ReactivePower,
}

export const socChartDefinition: ChartDefinition = {
  id: SOC_ID,
  title: SOC_TITLE,
  type: ChartType.EnergyLine,
}

export const socPercentageChartDefinition: ChartDefinition = {
  id: SOC_ID,
  title: SOC_TITLE,
  type: ChartType.EnergyLinePercentage,
  yAxis: { min: 0, max: 1 }, // forces the chart to stop at 100%.
}

export const transformerLoadingChartDefinition: ChartDefinition = {
  id: TRANSFORMER_LOADING_ID,
  type: ChartType.ApparentPower,
  isAreaChart: true,
  title: TRANSFORMER_LOADING_TITLE,
}

export function createActivePowerTimeSeriesConfig(
  group: Group
): TimeSeriesConfig {
  return {
    metric: Metric.COND_POWER_REAL,
    unit: GroupHelper.hasNegativePower(group) ? '-W' : 'W',
    config: {
      seriesName: ACTIVE_POWER_TITLE,
      seriesColor: BLUE3.hex,
    },
  }
}

export function createReactivePowerTimeSeriesConfig(): TimeSeriesConfig {
  return {
    metric: Metric.COND_POWER_REACTIVE,
    config: {
      seriesName: REACTIVE_POWER_TITLE,
      seriesColor: BLUE3.hex,
    },
  }
}

export const socTimeSeriesConfig: TimeSeriesConfig = {
  metric: Metric.COND_CHARGE_STATE,
  config: { seriesName: SOC_TITLE, seriesColor: PURPLE1.hex },
}

export function activePowerYAxisFormatter(v: NumberOrNull): string {
  return v !== null ? Format.fmtWatts(v) : TEXT_NO_VALUE
}

export function reactivePowerYAxisFormatter(v: NumberOrNull): string {
  return v !== null ? Format.fmtReactivePower(v) : TEXT_NO_VALUE
}

export function socYAxisFormatter(v: NumberOrNull) {
  return v !== null ? Format.fmtEnergy(v) : TEXT_NO_VALUE
}

export function percentageYAxisFormatter(v: NumberOrNull) {
  return v !== null ? Format.fmtPercent(v) : TEXT_NO_VALUE
}

type TimeSeriesSummary = {
  last: null | PlainMessage<TimeSeriesDataPoint>
  minY: NumberOrNull
  meanY: NumberOrNull
  maxY: NumberOrNull
  rmse?: NumberOrNull
}

export function emptySummary(): TimeSeriesSummary {
  return { last: null, minY: null, meanY: null, maxY: null }
}

/**
 * Computes a summary for the first time series of a given chart, including:
 * - The last valid data point, considering a maximum age limit in minutes for recency;
 * - Min, max, and mean statistics within a specified time interval.
 */
export function getFirstTimeSeriesSummary(
  chartId: string,
  timeSeriesMap: TimeSeriesMap,
  interval: Interval,
  now: DateTime,
  ageLimitMinutes: number
): TimeSeriesSummary {
  const firstTimeSeries = timeSeriesMap.get(chartId)?.[0]

  if (!firstTimeSeries || !firstTimeSeries.data.length) return emptySummary()

  const lastDp = firstTimeSeries.data.at(-1)

  return {
    last:
      lastDp && isRecentDataPoint(now, lastDp, ageLimitMinutes) ? lastDp : null,
    ...computeMinMaxMean(
      getPartialData({
        start: interval.start.toMillis(),
        end: interval.end.toMillis(),
        data: firstTimeSeries.data,
      })
    ),
  }
}

/**
 * Calculates the root mean square error (RMSE) for the transformer loading chart.
 *
 * The RMSE is calculated as follows:
 * - At each timestamp (charger data to be added in the future):
 *   - Compute the squared error:
 *     ```
 *     error² = (computed.power.apparent[meter] - forecast.latest.computed.power.apparent[meter] - computed.power.apparent[charger])²
 *     ```
 * - Calculate the mean of these squared errors over the interval.
 * - Return the square root of the mean squared error:
 *   ```
 *   RMSE = sqrt(mean(error²))
 *   ```
 *
 * @param {TimeSeriesMap} timeSeriesMap - A map containing the forecast and computed power time series.
 * @param {Interval} interval - The time interval (visible chart dates) over which to calculate the RMSE.
 * @returns {NumberOrNull} The calculated RMSE value, or null if the calculation cannot be performed.
 */
export function getRMSEfromTimeSeriesMap(
  timeSeriesMap: TimeSeriesMap,
  interval: Interval
): NumberOrNull {
  // Get the time series for the transformer loading
  const timeSeries = timeSeriesMap.get('transformer-loading')
  if (!timeSeries) return null

  // Filter the time series within the interval
  const timeSeriesSlice = filterTimeSeriesWithin(interval, timeSeries)
  if (!timeSeriesSlice) return null

  // Get the time series with the squared errors
  const errorSquareTimeSeries =
    getForecastPowerErrorSquaredTimeSeries(timeSeriesSlice)
  if (!errorSquareTimeSeries || errorSquareTimeSeries.length === 0) return null

  // Find the mean of the squared errors
  const { meanY: errorSquaredMeanY } = findMaxMinMeanY(errorSquareTimeSeries)
  if (errorSquaredMeanY === null) return null

  // Return the square root of the mean of the squared errors (RMSE)
  return Math.sqrt(errorSquaredMeanY)
}

/**
 * Creates a time series with the squared errors of the forecast power and the computed power
 * at each timestamp.
 */
export function getForecastPowerErrorSquaredTimeSeries(
  timeSeries: NamedTimeSeries[]
): TimeSeriesDataPoint[] | null {
  // Find the time series to compare
  const computedPowerApparent = timeSeries.find(
    (ts) => ts.metric === 'computed.power.apparent'
  )
  const forecastPowerApparent = timeSeries.find(
    (ts) => ts.metric === 'forecast.computed.power.apparent'
  )
  // TODO(Isaac): Add charger data
  // const computedPowerApparentCharger = timeSeries.find(
  //   (ts) => ts.metric === 'computed.power.apparent.charger'
  // )

  if (!computedPowerApparent || !forecastPowerApparent) return null

  // go through the timestamps of the time series
  // and create a new time series with the squared errors
  const errorSquaredTimeSeriesDPs = forecastPowerApparent.data.map((dp) => {
    const timestamp = dp.x
    const forecastPowerApparentDP = dp.y ?? null
    const computedPowerApparentDP = getValueAtTimestamp(
      computedPowerApparent,
      timestamp
    )
    // TODO(Isaac): Add charger data
    // const computedPowerApparentCharger = getValueAtTimestamp(
    //   computedPowerApparentCharger,
    //   timestamp
    // )

    if (forecastPowerApparentDP === null || computedPowerApparentDP === null) {
      return new TimeSeriesDataPoint({ x: timestamp })
    }

    const error = computedPowerApparentDP - forecastPowerApparentDP
    return new TimeSeriesDataPoint({ x: timestamp, y: error ** 2 })
  })

  return errorSquaredTimeSeriesDPs.filter(
    (dp) => dp.y !== undefined && dp.y !== null && !Number.isNaN(dp.y)
  )
}

export function getValueAtTimestamp(
  timeSeries: NamedTimeSeries,
  timestamp: number
): number | null {
  // Find the data point with the timestamp using binary search
  const dpIdx = _sortedIndexBy(timeSeries.data, { x: timestamp }, (dp) => dp.x)
  const dp = timeSeries.data[dpIdx]

  // If not, return null
  return dp && dp.x === timestamp ? dp.y ?? null : null
}

/** * Ensures the last data point is recent enough for display. */
function isRecentDataPoint(
  now: DateTime,
  dp: PlainMessage<TimeSeriesDataPoint>,
  ageLimitMinutes: number
): boolean {
  return DateTime.fromMillis(dp.x) > now.minus({ minutes: ageLimitMinutes })
}

export function newInitialInterval(now: DateTime): Interval {
  return Interval.fromDateTimes(now.minus({ hours: 24 }), now.plus(PADDING))
}
