import { ResourceType } from '@/constants/resourceType'
import type { Resource } from 'rfs/pb/resource_pb'

export const SiteHelper = {
  findMeterElectricalResource(
    site: Resource,
    siteResources: Resource[]
  ): undefined | Resource {
    return siteResources.find(
      (r) =>
        r.type === ResourceType.METER_ELECTRICAL &&
        r.meter?.electrical === site.meter?.electrical
    )
  },

  getUnqualifiedTransformerId(meter?: Resource): undefined | string {
    return meter?.upline?.transformer || meter?.upline?.bank?.transformer
  },
}
