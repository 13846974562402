import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'

import { GcsService } from 'rfs/frontend/proto/gcs_connect'
import { GcsFileRequest } from 'rfs/frontend/proto/gcs_pb'

export class GCSService {
  private client: PromiseClient<typeof GcsService>

  constructor(transport: Transport) {
    this.client = createPromiseClient(GcsService, transport)
  }

  // code copied more or less from ApiService
  public async fetchJSON(path: string): Promise<unknown> {
    try {
      const req = new GcsFileRequest({ object: path })
      const res = await this.client.getFile(req)
      const utf8decoder = new TextDecoder() // default 'utf-8' or 'utf8'

      const data: unknown = JSON.parse(utf8decoder.decode(res.data))
      return data
    } catch (err) {
      throw new Error(
        `[GCSService::fetchJSON] unable to fetch ${path}: ${(
          err as any
        ).toString()}`
      )
    }
  }

  /**
   * Return an image in the form of a Data URL: `data:image/png;base64,ABCDEF...`
   */
  public async fetchImage(path: string): Promise<string> {
    try {
      const req = new GcsFileRequest({ object: path })
      const res = await this.client.getFile(req)

      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.readAsDataURL(new Blob([res.data]))
      })
    } catch (err) {
      throw new Error(
        `[GCSService::fetchImage] unable to fetch ${path}: ${(
          err as any
        ).toString()}`
      )
    }
  }
}
