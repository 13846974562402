<template>
  <section aria-label="Downline meters">
    <ce-data-table
      :headers
      :table
      :filters
      :options
      :isLoading
      :serverItemsLength
      :downloadHref
      :bgColor
      sticky
      dense
      @new-filters="updateFilters"
      @reset-filters="updateFilters"
      @new-options="updateOptions"
    >
      <template v-slot:left-of-actions>
        <ce-title>Downline meters</ce-title>
      </template>
    </ce-data-table>
  </section>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { GREY6 } from '@/constants/colors'
import type {
  Filters,
  Options,
  NewOptionsContext,
} from '@/model/tables/DataTable'
import {
  convertFilters,
  convertOrderBy,
  newFiltersMap,
} from '@/model/tables/column'
import { createDefaultOptions } from '@/model/tables/helper'
import {
  createHeaders,
  type MeterImpactDataTable,
  MeterImpactTableResponse,
} from '@/model/grid/ResourceDownlineMetersDataTable'
import CeTitle from '@/components/CeTitle.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'ResourceImpactsMetersTable',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: { CeTitle, CeDataTable },
  setup() {
    return { bgColor: GREY6.hex }
  },
  data() {
    const headers = createHeaders(this.$dictionary)

    return shallowReactive({
      headers,
      isLoading: false,
      filters: newFiltersMap(headers),
      options: createDefaultOptions(headers[0].key),
      response: new MeterImpactTableResponse(),
    })
  },
  watch: {
    resource: {
      immediate: true,
      handler: function () {
        this.fetchData({ isNewResource: true })
      },
    },
  },
  computed: {
    table(): MeterImpactDataTable {
      return { rows: this.response.rows }
    },
    serverItemsLength(): number {
      return this.response.totalRows
    },
    downloadHref(): string {
      return this.response.exportUrl
    },
  },
  methods: {
    updateFilters(newFilters?: Filters): void {
      this.filters = newFilters ?? newFiltersMap(this.headers)
      this.fetchData()
    },
    updateOptions(newOptions: Options, ctx?: NewOptionsContext): void {
      this.options = newOptions
      if (ctx?.triggeredByFilter) return
      this.fetchData()
    },
    async fetchData(opts?: { isNewResource: boolean }): Promise<void> {
      this.isLoading = true

      // Only reset the state when a new resource.
      if (opts?.isNewResource) {
        this.response = new MeterImpactTableResponse()
      }

      try {
        this.response =
          await this.$services.analysisService.downlineMetersTable({
            resource: this.resource.id,
            limit: this.options.itemsPerPage,
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            filterBy: convertFilters(this.filters),
            orderBy: convertOrderBy(this.options.orderBy),
          })
      } catch (err) {
        console.error('GridImpactResourceMetersTable.fetchData:', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
