<template>
  <time-series-chart-group
    :charts="charts"
    :data-source="dataSource"
    :interval="interval"
    :max-end-time="maxEndTime"
    :interval-broadcaster="intervalBroadcaster"
    @new-interval="handleNewInterval"
    @new-time-series="handleNewTimeSeries"
  >
    <!-- Summary Box -->
    <template
      v-for="sb of summaryBoxes"
      :key="sb.title"
      v-slot:[`right-side-of-${sb.chartId}`]
    >
      <telemetry-summary-box
        :data-test="sb.title"
        :summary="sb"
        :is-refreshing="isRefreshing"
      />
    </template>
  </time-series-chart-group>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Interval } from 'luxon'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { ITimeSeriesDataSource, TimeSeriesMap } from '@/model/charts'
import { EventName } from '@/model/charts/TimeSeriesDataSource'
import { TimeSeriesChartGroup } from '@/components/common'
import TelemetrySummaryBox, {
  TelemetrySummary,
} from '@/components/control/TelemetrySummaryBox.vue'
import { ChartDefinition } from '@/types/charts'

export interface TelemetrySummaryWithChartId extends TelemetrySummary {
  chartId: string
}

export default defineComponent({
  name: 'TelemetryCharts',
  props: {
    charts: {
      type: Array as PropType<ChartDefinition[]>,
      required: true,
    },
    dataSource: {
      type: Object as PropType<ITimeSeriesDataSource>,
      required: true,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    summaryBoxes: {
      type: Array as PropType<TelemetrySummaryWithChartId[]>,
      required: true,
    },
    /**
     * Instructs component to refresh chart data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<undefined | IntervalBroadcaster>,
      required: false,
    },
  },
  components: { TimeSeriesChartGroup, TelemetrySummaryBox },
  data() {
    return { maxEndTime: this.interval.end, isRefreshing: false }
  },
  watch: {
    dataSource: {
      immediate: true,
      handler: function (
        newValue: ITimeSeriesDataSource,
        oldValue: undefined | ITimeSeriesDataSource
      ): void {
        if (oldValue) this.unsubscribeFromDataSource(oldValue)
        this.subscribeToDataSource(newValue)
      },
    },
  },
  beforeUnmount(): void {
    this.unsubscribeFromDataSource(this.dataSource)
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.$emit('new-interval', newInterval)
    },
    handleNewTimeSeries(newMap: TimeSeriesMap): void {
      this.$emit('new-time-series', newMap)
    },
    updateIsRefreshing(newValue: boolean): void {
      this.isRefreshing = newValue
    },
    subscribeToDataSource(dataSource: ITimeSeriesDataSource): void {
      dataSource.subscribe?.(EventName.IS_REFRESHING, this.updateIsRefreshing)
    },
    unsubscribeFromDataSource(dataSource: ITimeSeriesDataSource): void {
      dataSource.unsubscribe?.(EventName.IS_REFRESHING, this.updateIsRefreshing)
    },
  },
})
</script>
