import { Color } from '@deck.gl/core'
import { GeoJsonLayer } from '@deck.gl/layers'
import type { Feature } from 'geojson'

import { InfoColumnAllProps, InfoColumnItem } from '@/components/InfoColumn'
import { CustomMapLayer } from '@/stores/customFiles'
import { COMMON_SIZE_DIVISOR, ICON_MAX_PIXELS } from '@/model/map/constants'
import type { ExtraProps } from './catalog'

const DEFAULT_COLOR = '#555555'

const ICON_DEFAULTS = {
  width: ICON_MAX_PIXELS,
  height: ICON_MAX_PIXELS,
}

const SVG_FIX = {
  resizeWidth: 256,
  resizeHeight: 256,
  resizeQuality: 'high',
}

/** Requests for the file and the icon are handled by RFS, so we need credentials */
const WITH_CREDS = { credentials: 'include', mode: 'cors' } as const

/**
 * Returns a GeoJSON layer that implements the mapbox/simplestyle spec for GeoJSON.
 * @see https://github.com/mapbox/simplestyle-spec/tree/master/1.1.0
 */
export function newCustomLayer(custom: CustomMapLayer) {
  // The icon URL comes from the map layer storage object
  // The size is hard-coded, but that may change in the future
  const icon = Object.assign({ url: custom.icon }, ICON_DEFAULTS)

  return new GeoJsonLayer<{}, ExtraProps>({
    id: custom.id,
    data: fetch(custom.url, WITH_CREDS).then((res) => res.json()),
    // If `custom.icon` is blank, we could use "text" or "circle"
    pointType: 'icon',
    lineWidthUnits: 'pixels',
    pickable: true,
    infoWindow: {
      infoColumn: (f) => infoWindowInfoColumn(f as Feature),
      ignoresResource: true,
    },
    _subLayerProps: {
      'points-icon': {
        // If the SVG doesn't have a specific width & height, this is needed to allow
        // it to be converted to a bitmap.
        loadOptions: { imagebitmap: SVG_FIX, fetch: WITH_CREDS },
      },
    },
    getIcon: () => icon,
    /* Use the same sizing algorithm as the Transformer layer. */
    iconSizeUnits: 'common',
    iconSizeMinPixels: ICON_MAX_PIXELS / 2,
    iconSizeMaxPixels: ICON_MAX_PIXELS,
    getIconSize: (ICON_MAX_PIXELS * 0.75) / COMMON_SIZE_DIVISOR,
    getLineColor(feat: Feature) {
      const color = feat.properties?.stroke ?? DEFAULT_COLOR
      const opacity = feat.properties?.['stroke-opacity'] ?? 1
      return convertColor(color, opacity)
    },
    getLineWidth(feat: Feature) {
      return feat.properties?.['stroke-width'] ?? 2
    },
    getFillColor(feat: Feature) {
      const color = feat.properties?.fill
      const opacity = feat.properties?.['fill-opacity']
      return convertColor(color ?? DEFAULT_COLOR, opacity ?? 0.6)
    },
  })
}

function convertColor(color: string, opacity: number): Color {
  // Should be of the form "[#]RRGGBB"
  const colorParts = color.match(/\w\w/g)

  if (colorParts == null) return [85, 85, 85, opacity * 255]

  const [r, g, b] = colorParts.map((x) => parseInt(x, 16))
  return [r, g, b, opacity * 255]
}

function infoWindowInfoColumn(f: Feature): InfoColumnAllProps {
  return {
    title: f.properties?.title,
    subtitle: f.properties?.description,
    items: getCustomProperties(f),
  }
}

const SIMPLE_STYLE_PROPS = [
  'title',
  'description',
  'marker-size',
  'marker-symbol',
  'marker-color',
  'stroke',
  'stroke-opacity',
  'stroke-width',
  'fill',
  'fill-opacity',
]

function getCustomProperties(feat: Feature): InfoColumnItem[] {
  const properties = feat.properties as Record<string, any>

  return Object.keys(properties)
    .filter((key) => !SIMPLE_STYLE_PROPS.includes(key))
    .map((key) => ({ label: key, text: properties[key] }))
}
