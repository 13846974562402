import { PACIFIC, WALLABY } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import { Format } from '@/utils/format'
import type { TimeSeriesConfig } from '@/model/charts/TimeSeriesDataSource'
import type { MapLayerFeature } from '@/model/map/types'
import { hasFilterValue } from '@/model/tables/helper'
import {
  CustomFilters,
  CustomOptions,
  columnFieldMap,
} from '@/model/transformer/TransformersDataTable'
import {
  ListTopTransformerLoadRequest_OrderBy as OrderBy,
  ListTopTransformerLoadRequest_FilterBy as FilterBy,
  type ListTopTransformerLoadResponse_TransformerLoad as TransformerLoad,
} from 'rfs/frontend/proto/transformer_details_pb'
import { Resource } from 'rfs/pb/resource_pb'

export * from './downlineMeters'
export * from './TransformerSeriesDataSource'

export function toOrderByList(options: CustomOptions): OrderBy {
  const { column, descending } = options.orderBy

  return new OrderBy({ field: columnFieldMap.get(column), descending })
}

export function toFilterByList(filters: CustomFilters): FilterBy[] {
  const filterBy: FilterBy[] = []

  for (const [name, field] of filters) {
    if (!hasFilterValue(field)) {
      continue
    }
    const newFilter = new FilterBy({ field: columnFieldMap.get(name) })

    switch (field.type) {
      case 'min-max': {
        const { min, max, multiplier = 1 } = field
        newFilter.min = min.value != null ? min.value * multiplier : undefined
        newFilter.max = max.value != null ? max.value * multiplier : undefined
        filterBy.push(newFilter)
        break
      }
      case 'multiselect': {
        newFilter.anyOf = field.value
        filterBy.push(newFilter)
      }
    }
  }
  return filterBy
}

/**
 * Convert TransformerLoad objects into objects that can be shown in map layers.
 */
export function transformerToMapFeature(
  transformer: TransformerLoad
): MapLayerFeature {
  if (transformer.transformer == null) {
    throw new Error('Invalid TransformerLoad -- missing Transformer')
  }
  const { id, location, feeder, substation, rating } = transformer.transformer

  return {
    resource: new Resource({
      id: `transformer/${id}`,
      type: ResourceType.TRANSFORMER,
      gis: { element: id },
      location: { point: location },
      ratings: { power: { apparent: rating } },
      upline: { feeder, substation },
    }),
    properties: {
      loadFactorPercent: transformer.loadFactorPercent,
      maxLoadPercent: transformer.maxLoadPercent,
    },
  }
}

export const transformerLoadingTimeSeriesConfigs: TimeSeriesConfig[] = [
  {
    metric: Metric.COMPUTED_POWER_APPARENT,
    config: { seriesName: 'Net load (via AMI)', seriesColor: PACIFIC.hex },
  },
  {
    metric: Metric.RATINGS_POWER_APPARENT,
    config: {
      seriesName: 'Load %',
      seriesColor: WALLABY.hex,
      isGhostSeries: true,
      // NOTE(rafael): since this series won't be displayed in an additional Y
      // axis for itself, we need to use this formatter instead of the
      // formatter used for ChartType.ApparentPower.
      formatter: (val: number, _opts?: any) => Format.fmtPercent(val),
    },
  },
]
