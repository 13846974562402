<template>
  <full-height-layout class="px-6">
    <template v-slot:top>
      <ce-title big>{{ title }}</ce-title>
    </template>

    <ce-data-table
      :headers="headers"
      :table="table"
      :filters="filters"
      :options="options"
      :is-loading="!isInitialState && isLoading"
      :server-items-length="serverItemsLength"
      :bg-color="bgColor"
      :download-href="downloadHref"
      v-model:search="search"
      :search-min-length="3"
      :visible-headers="visibleHeaders"
      fixed-header
      dense
      @new-visible-headers="updateVisibleHeaders"
      @new-filters="updateFilters"
      @reset-filters="updateFilters"
      @new-options="updateOptions"
      @search="handleNewSearch"
    />
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { GREY6 } from '@/constants/colors'
import { headers } from '@/model/ders/DersDataTable'
import { useDersTableStore } from '@/stores/dersTable'
import CeTitle from '@/components/CeTitle.vue'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'

export default defineComponent({
  name: 'DersLeftPanel',
  components: { CeTitle, CeDataTable, FullHeightLayout },
  setup() {
    return {
      headers,
      title: 'DERs',
      bgColor: GREY6.rgb,
    }
  },
  data() {
    return { search: useDersTableStore().searchText }
  },
  computed: {
    ...mapState(useDersTableStore, [
      'visibleHeaders',
      'options',
      'filters',
      'table',
      'isInitialState',
      'isLoading',
      'hasLoadingFailed',
      'serverItemsLength',
      'downloadHref',
    ]),
  },
  created() {
    // Run an initial search if this is the first time the page is loaded
    if (this.isInitialState) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions(useDersTableStore, [
      'updateVisibleHeaders',
      'updateOptions',
      'updateFilters',
      'updateSearchText',
      'fetchData',
    ]),
    handleNewSearch(): void {
      this.updateSearchText(this.search)
    },
  },
})
</script>
