import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { Interceptor } from '@connectrpc/connect'

const tokenCache = new Map<string, string>()

/**
 * An interceptor which sets the `Authorization` header using the Vouch cookie.
 *
 * @see https://github.com/CamusEnergy/bando/blob/master/ansible/roles/vouch/templates/config.yml.j2
 */
export const VouchAuthInterceptor: Interceptor = (next) => async (req) => {
  // `VITE_OAUTH_ID_TOKEN` will be available during development mode
  // (local dev or sandbox `rfahot` mode).
  // Check the `token.sh` script, which is called when `npm run serve` or
  // `npm run sandboxserve` (`rfahot` mode).
  const devEnvToken: string | undefined = import.meta.env.VITE_OAUTH_ID_TOKEN

  /** * Development only. */
  if (devEnvToken) {
    req.header.set('Authorization', `Bearer ${devEnvToken}`)
  } else {
    const cookieValue = Cookies.get('VouchCookie')

    if (cookieValue) {
      let idToken = tokenCache.get(cookieValue)

      // NOTE(rafael): only decode if new cookie value.
      if (!idToken) {
        const claims = jwtDecode(cookieValue) as Record<string, string>
        idToken = claims['PIdToken']
        tokenCache.set(cookieValue, idToken)
      }

      idToken && req.header.set('Authorization', `Bearer ${idToken}`)
    }
  }

  return next(req)
}
