// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file control/proto/log.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3, Timestamp } from "@bufbuild/protobuf";
import { Params, Policy } from "./policy_pb.js";
import { Waypoint } from "./waypoints_pb.js";
import { DeviceSchedule, DeviceScheduleTimeRange } from "../../device/proto/proxy_pb.js";

/**
 * GroupLogEntry is an entry in the history log for a control group.
 *
 * TODO(james): Generalize beyond groups.  Note that doing so will require a
 * stable way of identifying resources (as the "subject" of the log entry).
 *
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.GroupLogEntry
 */
export const GroupLogEntry = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GroupLogEntry",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "payload", kind: "message", T: Any },
  ],
);

/**
 * SetPolicyEvent is a log event describing a user-requested change to the
 * currently-enforced policy for a group.
 *
 * NEXT TAG: 9
 *
 * @generated from message ritta.partner.hce.control.proto.SetPolicyEvent
 */
export const SetPolicyEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.SetPolicyEvent",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "policy_start_time", kind: "message", T: Timestamp },
    { no: 4, name: "policy_end_time", kind: "message", T: Timestamp },
    { no: 5, name: "policy", kind: "enum", T: proto3.getEnumType(Policy) },
    { no: 6, name: "params", kind: "message", T: Params },
    { no: 7, name: "next_policy", kind: "enum", T: proto3.getEnumType(Policy), opt: true },
    { no: 8, name: "next_policy_params", kind: "message", T: Params, opt: true },
  ],
);

/**
 * SubmitWaypointsEvent is a log event describing the a user's request to change the scheduled waypoints for a control group.
 *
 * NEXT TAG: 6
 *
 * @generated from message ritta.partner.hce.control.proto.SubmitWaypointsEvent
 */
export const SubmitWaypointsEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.SubmitWaypointsEvent",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "schedule_start_time", kind: "message", T: Timestamp },
    { no: 4, name: "schedule_end_time", kind: "message", T: Timestamp },
    { no: 5, name: "waypoints", kind: "message", T: Waypoint, repeated: true },
  ],
);

/**
 * Log format for DeviceSchedule events from proxy.proto
 * NEXT TAG: 5
 *
 * @generated from message ritta.partner.hce.control.proto.LogDeviceScheduleEvent
 */
export const LogDeviceScheduleEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.LogDeviceScheduleEvent",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "send_schedule", kind: "message", T: DeviceSchedule, oneof: "request" },
    { no: 4, name: "delete_timerange", kind: "message", T: DeviceScheduleTimeRange, oneof: "request" },
  ],
);

