<template>
  <div>
    <!-- Table -->
    <ce-data-table
      :headers="headers"
      :table="table"
      :options="options"
      :bg-color="bgColor"
      :is-loading="isLoading"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      @new-options="handleNewOptions"
    >
      <!-- Custom <td> content for "Params" -->
      <template v-slot:[`item.${Columns.POLICY_PARAMS}`]="{ item }">
        <v-btn
          v-if="item.infoColumnGroup || item.scheduleId"
          color="primary"
          variant="outlined"
          size="small"
          @click="() => openModal(item)"
        >
          See Details
        </v-btn>
        <template v-else>
          {{ item[Columns.POLICY_PARAMS] }}
        </template>
      </template>
    </ce-data-table>

    <!-- Modal -->
    <v-dialog
      v-model="isDialogOpen"
      max-width="430"
      @click:outside="closeModal"
    >
      <v-card
        v-if="dialogRow"
        class="position-relative"
        style="min-height: 13rem"
      >
        <!-- Close btn -->
        <v-btn
          icon="mdi-close"
          size="small"
          elevation="0"
          aria-label="Close"
          style="position: absolute; top: 0.5rem; right: 0.5rem"
          @click="closeModal"
        />

        <!-- Loading Recurring Schedule -->
        <centered-spinner v-if="isLoadingRecurringSchedule" />

        <!-- Content -->
        <template v-else>
          <!-- Top bar -->
          <v-card-title tag="header" class="d-block pb-8 pr-12">
            <!-- Name -->
            <span aria-label="Name">
              {{ dialogRow[Columns.GROUP_NAME] }}
            </span>

            <!-- Policy -->
            <span class="d-block body-2" aria-label="Policy">
              {{ dialogRow[Columns.POLICY] }}
            </span>

            <!-- Created At -->
            <span class="d-block body-2" aria-label="Created At">
              {{
                `Created At: ${formatDateTime(dialogRow[Columns.CREATED_AT])}`
              }}
            </span>
          </v-card-title>

          <!-- Body -->
          <v-card-text>
            <info-column-group
              v-if="infoColumnGroup"
              :group="infoColumnGroup"
              class="pb-6"
            />
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { WHITE } from '@/constants/colors'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import {
  headers,
  Columns,
  CustomOptions,
  createInitialOptions,
  formatDateTime,
  LogRow,
  HistoryDataTable,
  createRecurringWaypointsDetails,
} from '@/model/control/history'
import CeDataTable from '@/components/common/CeDataTable.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import InfoColumnGroup from '@/components/InfoColumnGroup.vue'
import { Group as InfoColumnGroupProp } from '@/components/InfoColumnGroup'

export default defineComponent({
  name: 'HistoryDataTable',
  props: {
    table: {
      type: Object as PropType<HistoryDataTable>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CeDataTable, CenteredSpinner, InfoColumnGroup },
  setup() {
    return {
      TOP_BAR_HEIGHT,
      headers,
      Columns,
      formatDateTime,
      bgColor: WHITE.hex,
    }
  },
  data() {
    return shallowReactive({
      options: createInitialOptions(),
      isDialogOpen: false,
      dialogRow: null as null | LogRow,
      isLoadingRecurringSchedule: false,
      recurringScheduleInfoColumnGroup: null as null | InfoColumnGroupProp,
    })
  },
  computed: {
    infoColumnGroup(): undefined | InfoColumnGroupProp {
      return (
        this.recurringScheduleInfoColumnGroup || this.dialogRow?.infoColumnGroup
      )
    },
  },
  methods: {
    handleNewOptions(newValue: CustomOptions): void {
      this.options = newValue
    },
    openModal(row: LogRow): void {
      this.isDialogOpen = true
      this.dialogRow = row
      if (row.scheduleId) this.fetchRecurringSchedule(row.scheduleId)
    },
    closeModal(): void {
      this.isDialogOpen = false
      this.dialogRow = null
      this.recurringScheduleInfoColumnGroup = null
    },
    async fetchRecurringSchedule(scheduleId: bigint): Promise<void> {
      this.isLoadingRecurringSchedule = true

      try {
        const { schedule } = await this.$services.control.getSchedule({
          id: scheduleId,
        })

        if (!schedule) throw new Error('No schedule returned')

        this.recurringScheduleInfoColumnGroup = createRecurringWaypointsDetails(
          schedule.metric,
          schedule.waypoints
        )
      } catch (err) {
        this.closeModal()
        console.error('HistoryDataTable.fetchSchedule: %o', err)
      } finally {
        this.isLoadingRecurringSchedule = false
      }
    },
  },
})
</script>
