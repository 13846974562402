import {
  DataTable,
  FilterMultiSelect,
  Filters,
  Header,
  Options,
} from '@/model/tables/DataTable'
import { ResourceType } from '@/constants/resourceType'
import {
  getNominalVoltage,
  getResourceTypeDisplayName,
  getUnqualifiedId,
} from '@/model/resource'
import { ColumnType } from '@/model/tables/column'
import { Resource } from 'rfs/pb/resource_pb'
import { Metric } from '@/constants/metrics'
import { ResourcesTimeSeries } from './FeederOperationsMapCatalog'
import { getCurrentPhases, getVoltagePhases } from './OperationsMapUtils'

type FeederOperationsRow = {
  // Resource ID: string
  id: Resource['id']
  // Resource Type: string
  resourceType: Resource['type']
  // Current Rating (kVA): number
  currentRating?: number
  // Voltage Rating (V): number
  voltageRating?: number

  // Voltages
  [Metric.FORECAST_UPLINE_COND_VOLTAGE_A]: number
  [Metric.FORECAST_UPLINE_COND_VOLTAGE_B]: number
  [Metric.FORECAST_UPLINE_COND_VOLTAGE_C]: number

  // Currents
  [Metric.FORECAST_COND_CURRENT_A]: number
  [Metric.FORECAST_COND_CURRENT_B]: number
  [Metric.FORECAST_COND_CURRENT_C]: number
}
export type CustomOptions = Options<Columns>
export type FeederOperationsTableData = DataTable<Readonly<Resource>>
export type FeederOperationsDataTable = DataTable<FeederOperationsRow>

function createFeederOperationsRow(
  resource: Resource,
  resourcesTimeSeries: ResourcesTimeSeries
): FeederOperationsRow {
  const { tsValues, timeStampMillis } = resourcesTimeSeries
  const [voltagePhaseA, voltagePhaseB, voltagePhaseC] = getVoltagePhases(
    tsValues,
    timeStampMillis,
    resource.id
  )

  const [currentPhaseA, currentPhaseB, currentPhaseC] = getCurrentPhases(
    tsValues,
    timeStampMillis,
    resource.id
  )

  return {
    id: resource.id,
    resourceType: resource.type,
    currentRating: resource.ratings?.power?.apparent,
    voltageRating: getNominalVoltage(resource),

    // Voltages
    [Metric.FORECAST_UPLINE_COND_VOLTAGE_A]: voltagePhaseA,
    [Metric.FORECAST_UPLINE_COND_VOLTAGE_B]: voltagePhaseB,
    [Metric.FORECAST_UPLINE_COND_VOLTAGE_C]: voltagePhaseC,

    // Currents
    [Metric.FORECAST_COND_CURRENT_A]: currentPhaseA,
    [Metric.FORECAST_COND_CURRENT_B]: currentPhaseB,
    [Metric.FORECAST_COND_CURRENT_C]: currentPhaseC,
  }
}

export function createFeederOperationsDataTable(
  resources: Resource[],
  resourcesTimeSeries: ResourcesTimeSeries
): FeederOperationsRow[] {
  return resources.map((r) => createFeederOperationsRow(r, resourcesTimeSeries))
}

export enum Columns {
  ELEMENT_ID = 'id',
  TYPE = 'resourceType',
  CURRENT_RATING = 'currentRating',
  VOLTAGE_RATING = 'voltageRating',

  // Voltage metrics
  VOLTAGE_A = Metric.FORECAST_UPLINE_COND_VOLTAGE_A,
  VOLTAGE_B = Metric.FORECAST_UPLINE_COND_VOLTAGE_B,
  VOLTAGE_C = Metric.FORECAST_UPLINE_COND_VOLTAGE_C,

  // Current metrics
  CURRENT_A = Metric.FORECAST_COND_CURRENT_A,
  CURRENT_B = Metric.FORECAST_COND_CURRENT_B,
  CURRENT_C = Metric.FORECAST_COND_CURRENT_C,
}

export const feederOperationsHeaders: Header[] = [
  {
    title: 'Element ID',
    key: Columns.ELEMENT_ID,
    value: (item) => `${getUnqualifiedId(item as Resource)}`,
    width: '10%',
    sortable: false,
  },
  {
    title: 'Type',
    key: Columns.TYPE,
    valueType: ColumnType.RESOURCE_TYPE,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Rating (kVA)',
    key: Columns.CURRENT_RATING,
    valueType: ColumnType.KILO_VA,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Voltage (V)',
    key: Columns.VOLTAGE_RATING,
    valueType: ColumnType.INTEGER,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Voltage (A)',
    key: Columns.VOLTAGE_A,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Voltage (B)',
    key: Columns.VOLTAGE_B,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Voltage (C)',
    key: Columns.VOLTAGE_C,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Current (A)',
    key: Columns.CURRENT_A,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Current (B)',
    key: Columns.CURRENT_B,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
  {
    title: 'Current (C)',
    key: Columns.CURRENT_C,
    valueType: ColumnType.DECIMAL,
    width: '10%',
    sortable: true,
  },
]

export function createInitialFilters(
  defaultTypes?: ResourceType[]
): Filters<Columns> {
  return new Map([
    [
      Columns.TYPE,
      {
        type: 'multiselect',
        items: [],
        value: defaultTypes ?? [],
      },
    ],
  ])
}

export function updateSelectFilters(
  filters: Filters<Columns>,
  resources: Resource[]
): Filters<Columns> {
  const map: Filters<Columns> = new Map()

  if (filters.has(Columns.TYPE)) {
    map.set(Columns.TYPE, {
      type: 'multiselect',
      items: getResourceTypeCountMap(resources)
        .map(([type, count]) => ({
          title: `${getResourceTypeDisplayName(type)} (${count})`,
          value: type,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
      value: (filters.get(Columns.TYPE) as FilterMultiSelect)?.value ?? [],
    })
  }

  return map
}

function getResourceTypeCountMap(
  resources: Resource[]
): [ResourceType, number][] {
  const typeCountMap = new Map<ResourceType, number>()
  resources.forEach((r) => {
    const type = r.type as ResourceType
    typeCountMap.set(type, (typeCountMap.get(type) ?? 0) + 1)
  })
  return Array.from(typeCountMap)
}
