<template>
  <v-progress-circular
    v-if="isLoading"
    :size="20"
    color="primary"
    indeterminate
  />
  <router-link v-else-if="link" :to="link">
    {{ cellValue }}
  </router-link>
  <template v-else-if="isSparkline">
    <sparkline-chart :time-series="cellValue" />
  </template>
  <template v-else>
    {{ cellValue }}
  </template>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import { LocationAsRelativeRaw } from 'vue-router'
import { get } from 'lodash-es'
import { routeForResource } from '@/router/routeForResource'
import { AnyRow, Header } from '@/model/tables/DataTable'
import { IS_LOADING } from '@/model/tables/helper'
import { ColumnType, getCellValue } from '@/model/tables/column'
import { Resource } from 'rfs/pb/resource_pb'
import SparklineChart from '../SparklineChart.vue'

export default defineComponent({
  name: 'DataTableCell',
  components: { SparklineChart },
  props: {
    row: {
      type: Object as PropType<AnyRow>,
      required: true,
    },
    header: {
      type: Object as PropType<Header>,
      required: true,
    },
  },
  computed: {
    link(): undefined | LocationAsRelativeRaw {
      if (this.header.routeFactory) {
        const route = this.header.routeFactory(
          this.$rittaConfig,
          this.row,
          this.header.key
        )
        const isRouteAvailable = route?.name
          ? this.$router.hasRoute(route.name)
          : false
        return isRouteAvailable ? route : undefined
      } else if (this.header.valueType === ColumnType.RESOURCE_ROUTE_LINK) {
        const r = get(this.row, 'resource') as undefined | Resource
        return r ? routeForResource(r) : undefined
      } else {
        return undefined
      }
    },
    cellValue(): any {
      if (typeof this.header.value === 'function') {
        return this.header.value(this.row)
      } else if (this.header.value) {
        return this.header.value
      } else {
        return getCellValue(this.row, this.header)
      }
    },
    isLoading(): boolean {
      return this.cellValue === IS_LOADING
    },
    isSparkline(): boolean {
      return this.header.valueType === ColumnType.SPARKLINE
    },
  },
})
</script>
