import { isFinite } from 'lodash-es'

/**
 * Return `true` if the argument is an object that is not an instance
 * of a class such as `Array` or `Date`.
 * This is like lodash's `isPlainObject` but has a better TypeScript return type
 * to facilitate converting `Foo | Foo[]` into just `Foo`.
 */
export function isPlainObject<T>(o: T | T[] | null | undefined): o is T {
  return o != null && Object.getPrototypeOf(o) === Object.prototype
}

/** Return the number if it's a finite value */
export function numberOrNull(v: any): number | null {
  return isFinite(v) ? v : null
}
