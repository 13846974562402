import { MapLayerId, RittaConfig } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { Services } from '@/services'
import { MapManagerLayer } from '@/model/map/types'
import { WindHelper } from '@/model/resource/wind/helper'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'
import iconWindApp from '@/assets/imgs/albert_ic_wind--black.svg'

const ID = MapLayerId.WIND

function mapManagerLayer(
  _config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id: ID,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 12, // Larger tile size as there are fewer wind resources in a grid
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.WIND, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {})
    },
  })
}

export const Wind: ApplicationMapLayer = {
  id: ID,
  label: 'Wind',
  icon: iconWindApp,
  infoWindow: {
    infoColumn: (f) => WindHelper.infoWindowInfoColumn(f.resource!),
  },
  mapManagerLayer,
}
