<template>
  <div class="c-ColorLegend">
    <span class="d-flex align-center">
      <span class="d-block pr-2">
        {{ title }}
      </span>
      <slot></slot>
    </span>
    <div class="color-bar">
      <span
        class="my-4"
        v-for="color in colors"
        :key="color"
        :style="cellColor(color)"
        :class="{ 'mt-3': selectedColor === color }"
      ></span>
    </div>
    <div class="legend-labels">
      <span v-for="(label, idx) in legendLabels" :key="label + idx">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ColorLegend',
  props: {
    title: {
      type: String,
      required: true,
    },
    colors: {
      type: Array as PropType<string[]>,
      required: true,
    },
    legendLabels: {
      type: Array as PropType<string[]>,
      required: false,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      default: 8,
      required: false,
    },
    selectedColor: {
      type: String as PropType<null | string>,
      required: false,
    },
  },
  methods: {
    cellColor(color: string) {
      return `background-color: ${color};
              width: ${this.width}px;
              height: ${this.height}px;`
    },
  },
})
</script>

<style lang="scss">
.c-ColorLegend {
  .color-bar {
    overflow-x: hidden;
    display: flex;
    align-items: stretch;
  }
  .legend-labels {
    display: flex;
    justify-content: space-between;
    flex-flow: row;
    font-size: 12px;
    color: #6c6c6c;
  }
}
</style>
