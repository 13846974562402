<template>
  <div class="d-flex justify-end">
    <!-- Actions -->
    <v-tooltip
      v-for="(a, index) of actions"
      :key="a.name"
      :open-delay="OPEN_DELAY"
      location="bottom"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :disabled="isDisabled"
          class="highlight-on-focus"
          :class="{ 'mr-4': index !== actions.length - 1 }"
          :style="{ width: '36px', height: '36px' }"
          color="transparent"
          variant="text"
          icon
          @click="() => $emit(a.event)"
        >
          <v-icon color="primary">{{ a.icon }}</v-icon>
        </v-btn>
      </template>
      {{ a.name }}
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { OPEN_DELAY } from '@/constants/tooltip'

type Action = {
  name: string
  icon: string
  listener: string
  event: string
}

const actions: Action[] = [
  {
    name: 'Save',
    icon: 'mdi-check-circle-outline',
    listener: 'onClickSave',
    event: 'click-save',
  },
  {
    name: 'Cancel',
    icon: 'mdi-close-circle-outline',
    listener: 'onClickCancel',
    event: 'click-cancel',
  },
  {
    name: 'Edit',
    icon: 'mdi-pencil-outline',
    listener: 'onClickEdit',
    event: 'click-edit',
  },
  {
    name: 'Delete',
    icon: 'mdi-delete-outline',
    listener: 'onClickDelete',
    event: 'click-delete',
  },
]

export default defineComponent({
  name: 'RowActions',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return { OPEN_DELAY }
  },
  computed: {
    actions() {
      return actions.filter((a) =>
        Boolean(this.$attrs[a.listener] as undefined | Function)
      )
    },
  },
})
</script>
