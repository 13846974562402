<template>
  <div
    class="d-flex flex-column panel-transition ce-border-right"
    :style="computedStyle"
  >
    <!-- Content -->
    <div class="position-relative pt-6" style="flex: 1">
      <!-- Router View -->
      <router-view name="leftPanel" />
    </div>

    <!-- Footer -->
    <div class="pb-1 pl-6">
      <a
        href="mailto:support@camus.energy?subject=Requesting%20Assistance"
        class="text-caption text-black text-decoration-none"
      >
        Powered by Camus Energy
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from 'vue'
import { mapState } from 'pinia'
import { GREY6 } from '@/constants/colors'
import { useNavigationControlsStore } from '@/stores/navigationControls'

export default defineComponent({
  name: 'LeftPanel',
  computed: {
    ...mapState(useNavigationControlsStore, [
      'drawerLeftOpened',
      'drawerLeftWidth',
    ]),
    computedStyle(): StyleValue {
      return {
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: GREY6.hex,
        transitionProperty: 'width, transform',
        width: `${this.drawerLeftWidth}px`,
        transform: this.drawerLeftOpened
          ? 'translateX(0%)'
          : 'translateX(-100%)',
      }
    },
  },
})
</script>
