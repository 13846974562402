import {
  FilterMultiSelect,
  Filters,
  Header,
  Options,
  DataTable,
} from '@/model/tables/DataTable'
import { createSiteRoute } from '@/utils/router/create'
import { newResourceTypeFilter } from '@/model/tables/column'
import { Resource } from 'rfs/pb/resource_pb'
import { ResourceType } from '@/constants/resourceType'

/** Subset of Resource that search table cares about */
export interface SearchResource {
  id: string
  type: string
  meter?: { electrical: string }
  site?: { id: string }
  subscription?: { electrical: string }
  billing?: { party: string; rate: string }
  upline?: { substation: string }
}

/**
 * Each column reads from a specific property on a Resource.
 * The Resource is a composite resource that combines various properties
 * from a `meter/electrical` and `subscription/electrical`
 */
export enum SearchTableColumn {
  METER = 'meter.electrical',
  SITE = 'site.id',
  SUBSCRIPTION = 'subscription.electrical',
  CUSTOMER = 'billing.party',
  RATE_TYPE = 'billing.rate',
  SUBSTATION = 'upline.substation',
}

export type SearchFilters = Filters<SearchTableColumn>

export type SearchOptions = Options<SearchTableColumn>

/** Meter search results are Resource objects */
type MeterRow = Readonly<SearchResource>
export type SearchTableData = DataTable<MeterRow>

export const searchTableHeaders: Header[] = [
  {
    title: 'Meter number',
    key: SearchTableColumn.METER,
    sortable: true,
    width: 128, // Fixed width reduces table jitter when sort changes
    columnSelector: { required: true },
  },
  <Header<MeterRow>>{
    title: 'Site',
    key: SearchTableColumn.SITE,
    sortable: true,
    routeFactory: (_config, r) =>
      createSiteRoute(new Resource({ site: { id: r.site?.id } })),
  },
  {
    title: 'Subscription',
    key: SearchTableColumn.SUBSCRIPTION,
    sortable: true,
  },
  {
    title: 'Customer name',
    key: SearchTableColumn.CUSTOMER,
    sortable: true,
  },
  {
    title: 'Rate type',
    key: SearchTableColumn.RATE_TYPE,
    sortable: true,
  },
  {
    title: 'Substation',
    key: SearchTableColumn.SUBSTATION,
    sortable: true,
  },
]

export function createNewFilters(): SearchFilters {
  const filters = new Map()

  return filters
    .set(SearchTableColumn.RATE_TYPE, newRateTypeFilter())
    .set(
      SearchTableColumn.SUBSTATION,
      newResourceTypeFilter(ResourceType.SUBSTATION)
    )
}

function newRateTypeFilter(): FilterMultiSelect {
  return {
    type: 'multiselect',
    items: [], // Will be populated by RFS response
    value: [],
  }
}
