<template>
  <div class="position-relative pa-6 min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-else>
      <!-- Controllable Resources Chart -->
      <overview-chart
        v-if="isResourceOverviewChartEnabled"
        :groups="groupsWithResources"
      />

      <!-- Groups -->
      <control-overview-groups :groups :devices :mapGroupResourceIds />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { DeviceHelper } from '@/model/control/device/helper'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import OverviewChart from '@/components/control/charts/OverviewChart.vue'
import ControlOverviewGroups from '@/components/control/ControlOverviewGroups.vue'
import type { Device, Group } from 'rfs/control/proto/model_pb'

export default defineComponent({
  name: 'ControlOverviewPage',
  components: { CenteredSpinner, OverviewChart, ControlOverviewGroups },
  data() {
    return shallowReactive({
      isLoading: false,
      groups: [] as Group[],
      devices: [] as Device[],
      mapGroupResourceIds: new Map<Group, string[]>(),
    })
  },
  computed: {
    isResourceOverviewChartEnabled(): boolean {
      return !!this.$rittaConfig.control?.resourceOverviewChart?.enabled
    },
    groupsWithResources(): Group[] {
      return Array.from(this.mapGroupResourceIds.entries())
        .filter(([_group, resourceIds]) => resourceIds.length)
        .map((item) => item[0])
    },
  },
  created(): void {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true

      try {
        const [{ groups }, { devices }] = await Promise.all([
          this.$services.control.listGroups({
            includeHidden: this.$rittaConfig.control?.includeHidden,
          }),
          this.$services.control.listDevices({
            // NOTE: when no params, all devices are sent.
          }),
        ])

        this.groups = groups

        this.groups.forEach((g) => this.mapGroupResourceIds.set(g, []))

        // Discard Devices that don't belong to any group.
        this.devices = devices.filter((d) => !!d.groupId)

        await Promise.all(this.devices.map(this.getResourceIds))
      } catch (err) {
        console.error('ControlOverviewPage.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    async getResourceIds(device: Device): Promise<void> {
      const group = this.groups.find((g) => g.id === device.groupId)

      if (!group) return

      // Pool device detected. A device that work as a "group" of Resources.
      const poolId = DeviceHelper.getPoolId(device)

      if (poolId) {
        try {
          const resources =
            await this.$services.queryService.getResourcesByPoolID(poolId)
          const list = this.mapGroupResourceIds.get(group) ?? []

          if (resources.length) {
            resources.forEach((r) => list.push(r.id))
          } else {
            // If there's no constituting resources, push the device itself.
            list.push(device.camusResourceId)
          }

          this.mapGroupResourceIds.set(group, list)
        } catch (err) {
          console.error('ControlOverviewPage.getResourceIds: %o', err)
        }
      } else {
        if (device.camusResourceId) {
          const list = this.mapGroupResourceIds.get(group) ?? []
          list.push(device.camusResourceId)
          this.mapGroupResourceIds.set(group, list)
        }
      }
    },
  },
})
</script>
