import { RouteLocationNormalized } from 'vue-router'
import { TabId } from '@/config/types'
import { useNavigationControlsStore } from '@/stores/navigationControls'

/** * When the route doesn't specify the tab, "MONITOR" is going to be used. */
function computeRouteTab(route: RouteLocationNormalized): TabId {
  return route.meta?.tab !== undefined ? route.meta.tab : TabId.MONITOR
}

/**
 * Always opens the left panel and sets its width.
 */
export function setLeftPanel(route: RouteLocationNormalized): void {
  if (computeRouteTab(route) !== TabId.MONITOR) {
    // NOTE(rafael): Only change the left panel state when dealing with a route
    // that belongs to the "MONITOR" tab.
    return
  }

  const store = useNavigationControlsStore()

  store.setDrawerLeftOpened(!(route.meta?.drawerLeftDisabled ?? false))

  // Set left panel width
  if (route.meta?.drawerLeftWide) {
    store.setDrawerLeftWidthWide()
  } else {
    store.setDrawerLeftWidthNarrow()
  }
}
