import { shallowReactive } from 'vue'
import { defineImmutableStore } from './defineStore'
import { SiteTab } from '@/model/site/tabs'

export const DRAWER_LEFT_WIDTH_NARROW = 324
export const DRAWER_LEFT_WIDTH_WIDE = 1020

export const useNavigationControlsStore = defineImmutableStore(
  'navigationControls',
  {
    persist: { paths: ['selectedSiteTab'] },
    state: () => {
      return shallowReactive({
        drawerLeftWidth: DRAWER_LEFT_WIDTH_NARROW,
        drawerLeftOpened: false,
        isStreetViewVisible: false,
        selectedSiteTab: SiteTab.SITE,
      })
    },
    actions: {
      setDrawerLeftWidthNarrow() {
        this.drawerLeftWidth = DRAWER_LEFT_WIDTH_NARROW
      },
      setDrawerLeftWidthWide() {
        this.drawerLeftWidth = DRAWER_LEFT_WIDTH_WIDE
      },
      setDrawerLeftOpened(newValue: boolean) {
        this.drawerLeftOpened = newValue
      },
      toggleDrawerLeftOpened() {
        this.drawerLeftOpened = !this.drawerLeftOpened
      },
      updateStreetViewVisibility(newValue: boolean) {
        this.isStreetViewVisible = newValue
      },
      setPageTitle(newTitle?: string) {
        if (!newTitle) return
        document.title = newTitle
      },
      setSelectedSiteTab(newValue: SiteTab) {
        this.selectedSiteTab = newValue
      },
    },
  }
)
