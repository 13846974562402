<template>
  <div class="c-CeDivider" :style="{ height: `${height}` }">
    <div
      class="c-CeDivider__line"
      :style="`border-top: 1px solid ${colorHex}`"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BLACK } from '@/constants/colors'

export default defineComponent({
  name: 'CeDivider',
  props: {
    height: {
      type: String,
      required: false,
      default: '3rem',
    },
    colorHex: {
      type: String,
      required: false,
      default: BLACK.hex,
    },
  },
})
</script>

<style lang="scss">
.c-CeDivider {
  position: relative;
  width: 100%;

  &__line {
    position: absolute;
    top: 50%;
    width: 100%;
  }
}
</style>
