<template>
  <full-height-layout class="px-6">
    <!-- Top -->
    <template v-slot:top>
      <ce-title big>Meters</ce-title>
    </template>

    <!-- Failed -->
    <!-- TODO(rafael) -->

    <!-- Content -->
    <ce-data-table
      :headers="tableHeaders"
      :table="tableData"
      :filters="filters"
      :options="options"
      :visible-headers="visibleHeaders"
      :is-loading="!isInitialState && isLoading"
      :server-items-length="serverItemsLength"
      :bg-color="bgColor"
      :download-href="downloadHref"
      v-model:search="search"
      :search-hint="searchHint"
      fixed-header
      dense
      @new-filters="updateFilters"
      @reset-filters="updateFilters"
      @new-options="updateOptions"
      @new-visible-headers="updateVisibleHeaders"
      @search="handleNewSearch"
    />
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { mapActions, mapState } from 'pinia'
import { GREY6 } from '@/constants/colors'
import type { Header } from '@/model/tables/DataTable'
import CeTitle from '@/components/CeTitle.vue'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import {
  SearchTableData,
  SearchResource,
} from '@/model/meter/MeterSearchDataTable'
import { useMeterSearchStore } from '@/stores/meterSearch'

export default defineComponent({
  name: 'MetersLeftPanel',
  components: { CeTitle, CeDataTable, FullHeightLayout },
  setup() {
    return { bgColor: GREY6.rgb }
  },
  data() {
    return shallowReactive({ search: useMeterSearchStore().searchText })
  },
  computed: {
    ...mapState(useMeterSearchStore, [
      'isInitialState',
      'isLoading',
      'serverItemsLength',
      'lastResponse',
      'csvDownloadUrl',
      'filters',
      'options',
      'headers',
      'visibleHeaders',
    ]),
    tableHeaders(): Header[] {
      return this.headers as Header[]
    },
    tableData(): SearchTableData {
      const results = this.lastResponse?.results as undefined | SearchResource[]

      return {
        rows: results ?? [],
        noDataText: this.isInitialState ? 'Loading meters...' : 'No results',
      }
    },
    downloadHref(): undefined | string {
      return this.csvDownloadUrl?.href
    },
    searchHint(): string {
      const subscription = this.$dictionary('Subscription').toLowerCase()
      const site =
        this.$dictionary('Site') === 'Site'
          ? 'site ID'
          : this.$dictionary('Site').toLowerCase()

      return `Enter whole or partial meter number, ${subscription}, ${site}, address, or customer name`
    },
  },
  created() {
    // Run an initial search if this is the first time the page is loaded
    if (this.isInitialState) {
      this.fetchData()
    }
  },
  methods: {
    ...mapActions(useMeterSearchStore, [
      'fetchData',
      'updateOptions',
      'updateFilters',
      'updateSearchText',
      'updateVisibleHeaders',
    ]),
    handleNewSearch(): void {
      this.updateSearchText(this.search)
    },
  },
})
</script>
