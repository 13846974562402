<template>
  <section
    aria-label="Operating Envelope options"
    class="d-flex rounded px-4 py-5"
    :style="{ border: `1px solid ${strokeColor}`, backgroundColor: bgColor }"
  >
    <p class="text-subtitle-1 font-weight-medium pr-6">Operating Envelope:</p>
    <v-radio-group
      :model-value="modelValue"
      class="py-1"
      @update:model-value="(newValue: any) => $emit('update:model-value', newValue)"
    >
      <v-radio
        v-for="option in radioGroupOptions"
        :key="option"
        :label="generateRadioLabel(option)"
        :value="option"
        :disabled="isOptionDisabled(option)"
      />
    </v-radio-group>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { GRAY_COOL_50, NEUTRAL_200 } from '@/constants/colors'
import { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'

export const RADIO_OPTION_ACTIVATE = 'ACTIVATE' as const
export const RADIO_OPTION_DEACTIVATE = 'DEACTIVATE' as const
export const RADIO_OPTION_RETAIN = 'RETAIN' as const

const radioGroupOptions = [
  RADIO_OPTION_ACTIVATE,
  RADIO_OPTION_DEACTIVATE,
  RADIO_OPTION_RETAIN,
] as const

export type RadioOption = (typeof radioGroupOptions)[number]

export default defineComponent({
  name: 'EnvelopeStatusRadioGroup',
  props: {
    /* v-model */
    modelValue: {
      type: String as PropType<RadioOption>,
      required: true,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: true,
    },
  },
  setup() {
    return {
      radioGroupOptions,
      strokeColor: NEUTRAL_200.hex,
      bgColor: GRAY_COOL_50.hex,
    }
  },
  methods: {
    generateRadioLabel(option: RadioOption): string {
      switch (option) {
        case RADIO_OPTION_ACTIVATE:
          return 'Activate for entire group'
        case RADIO_OPTION_DEACTIVATE:
          return 'Deactivate for entire group'
        case RADIO_OPTION_RETAIN:
          return 'Retain status of individual group members'
        default:
          throw new Error(
            'EnvelopeStatusRadioGroup.generateRadioLabel: unexpected option'
          )
      }
    },
    isOptionDisabled(option: RadioOption): boolean {
      if (
        option === RADIO_OPTION_RETAIN ||
        // When "Partial/Mixed", allow both options.
        this.currentOEStatus === OperatingEnvelopeStatus.PARTIAL ||
        (option === RADIO_OPTION_ACTIVATE &&
          this.currentOEStatus === OperatingEnvelopeStatus.INACTIVE) ||
        (option === RADIO_OPTION_DEACTIVATE &&
          this.currentOEStatus === OperatingEnvelopeStatus.ACTIVE)
      ) {
        return false
      }

      return true
    },
  },
})
</script>
