<template>
  <div class="d-flex justify-end" :style="{ 'min-width': minWidth }">
    <template v-for="(action, index) of actions">
      <!-- NOTE(rafael): keep the "d-block" class so when the tooltip renders
    first time it maintain the wrapped button's height -->
      <v-tooltip
        v-if="action.show"
        :key="action.tooltip"
        :open-delay="OPEN_DELAY"
        location="bottom"
        class="d-block"
      >
        <template v-slot:activator="{ props }">
          <button-segmented
            v-if="action.show"
            :href="action.href"
            :aria-label="action.tooltip"
            v-bind="props"
            :icon="action.icon"
            :class="{ 'mr-2': index + 1 !== actions.length }"
            @click="() => handleActionClick(action)"
          />
        </template>
        {{ action.tooltip }}
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { OPEN_DELAY } from '@/constants/tooltip'
import { DataTableAction } from '@/model/tables/DataTable'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import { Analytics } from '@/utils/analytics'

export default defineComponent({
  name: 'DataTableActions',
  props: {
    actions: {
      type: Array as PropType<DataTableAction[]>,
      required: true,
    },
  },
  components: { ButtonSegmented },
  setup() {
    return { OPEN_DELAY }
  },
  computed: {
    /**
     * Computes the minimum width for the action container. This ensures a
     * consistent layout by reserving space equivalent to the total width
     * of all actions. Prevents layout shifts when actions are
     * dynamically shown or hidden.
     */
    minWidth(): string {
      const numberOfVisibleActions = this.actions.filter((a) => a.show).length
      const btnWidth = 48 + 8 // width + margin of each segmented btn.
      return `${btnWidth * numberOfVisibleActions}px`
    },
  },
  methods: {
    /**
     * Specifically for the reset filters icon.
     */
    shouldFlipHorizontally(action: DataTableAction): boolean {
      return action.icon === 'mdi-refresh'
    },
    handleActionClick(action: DataTableAction): void {
      const cb = action.callback

      if (!cb) return

      // Restore.
      this.actions
        .filter((ac) => ac.tooltip !== action.tooltip)
        .forEach((ac) => ac.callbackRestore?.())

      // Act.
      this.$nextTick(() => cb())

      // Log.
      Analytics.logTableAction(action.tooltip)
    },
  },
})
</script>
