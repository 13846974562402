import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import { InfoColumnAllProps } from '@/model/types'
import { Resource } from 'rfs/pb/resource_pb'
import { getDisplayName } from '..'

export const WindHelper = {
  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    const capacityRaw = r.ratings?.wattage

    return {
      title: getDisplayName(r),
      items: [
        {
          label: 'Capacity',
          text:
            capacityRaw !== undefined
              ? Format.fmtWatts(capacityRaw)
              : TEXT_NO_VALUE,
        },
      ],
    }
  },
}
