// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file control/proto/control_service.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Metric } from "../../device/proto/proxy_pb.js";
import { Device, Group } from "./model_pb.js";
import { Params, Params_AutomaticControlEvent_FtmDerConfig, Policy } from "./policy_pb.js";
import { GroupLogEntry } from "./log_pb.js";
import { Waypoint } from "./waypoints_pb.js";

/**
 * ApexSeries encapsulates encoded JSON values inteded to be used as the `data`
 * value in an Apex Charts `series` values.  The schema is context-specific, but
 * is usually:
 *     [
 *         [<timestamp_ms>, <value>],
 *         ...
 *     ]
 *
 * See https://apexcharts.com/docs/series/ for more information.
 *
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ApexSeries
 */
export const ApexSeries = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ApexSeries",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GetGroupRequest
 */
export const GetGroupRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetGroupRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GetGroupSupportedMetricTypesRequest
 */
export const GetGroupSupportedMetricTypesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetGroupSupportedMetricTypesRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GetGroupSupportedMetricTypesResponse
 */
export const GetGroupSupportedMetricTypesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetGroupSupportedMetricTypesResponse",
  () => [
    { no: 1, name: "supported_metrics", kind: "enum", T: proto3.getEnumType(Metric), repeated: true },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListGroupsRequest
 */
export const ListGroupsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListGroupsRequest",
  () => [
    { no: 1, name: "include_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListGroupsResponse
 */
export const ListGroupsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListGroupsResponse",
  () => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
  ],
);

/**
 * NEXT TAG: 7
 *
 * @generated from message ritta.partner.hce.control.proto.SetGroupPolicyRequest
 */
export const SetGroupPolicyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.SetGroupPolicyRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "policy", kind: "enum", T: proto3.getEnumType(Policy) },
    { no: 3, name: "params", kind: "message", T: Params },
    { no: 5, name: "end_time", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "next_policy", kind: "message", T: SetGroupPolicyRequest_NextPolicy, opt: true },
  ],
);

/**
 * @generated from message ritta.partner.hce.control.proto.SetGroupPolicyRequest.NextPolicy
 */
export const SetGroupPolicyRequest_NextPolicy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.SetGroupPolicyRequest.NextPolicy",
  () => [
    { no: 1, name: "policy", kind: "enum", T: proto3.getEnumType(Policy) },
    { no: 2, name: "params", kind: "message", T: Params },
  ],
  {localName: "SetGroupPolicyRequest_NextPolicy"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GroupHistoryRequest
 */
export const GroupHistoryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GroupHistoryRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GroupHistoryResponse
 */
export const GroupHistoryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GroupHistoryResponse",
  () => [
    { no: 1, name: "entries", kind: "message", T: GroupLogEntry, repeated: true },
  ],
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.GetDeviceRequest
 */
export const GetDeviceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetDeviceRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListDevicesRequest
 */
export const ListDevicesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListDevicesRequest",
  () => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListDevicesResponse
 */
export const ListDevicesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListDevicesResponse",
  () => [
    { no: 1, name: "devices", kind: "message", T: Device, repeated: true },
  ],
);

/**
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.UpdateDevicesRequest
 */
export const UpdateDevicesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.UpdateDevicesRequest",
  () => [
    { no: 1, name: "updates", kind: "message", T: UpdateDevicesRequest_DeviceUpdate, repeated: true },
  ],
);

/**
 * @generated from message ritta.partner.hce.control.proto.UpdateDevicesRequest.DeviceUpdate
 */
export const UpdateDevicesRequest_DeviceUpdate = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.UpdateDevicesRequest.DeviceUpdate",
  () => [
    { no: 1, name: "device_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "oe_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
  {localName: "UpdateDevicesRequest_DeviceUpdate"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.UpdateDevicesResponse
 */
export const UpdateDevicesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.UpdateDevicesResponse",
  () => [
    { no: 1, name: "devices", kind: "message", T: Device, repeated: true },
  ],
);

/**
 * NEXT TAG: 7
 *
 * @generated from message ritta.partner.hce.control.proto.ScheduleRequest
 */
export const ScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ScheduleRequest",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 4, name: "waypoints_list", kind: "message", T: ScheduleRequest_WayPoints },
    { no: 5, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 7, name: "ftm_der_config", kind: "message", T: Params_AutomaticControlEvent_FtmDerConfig, opt: true },
    { no: 8, name: "plan_as_of_time", kind: "message", T: Timestamp, opt: true },
  ],
);

/**
 * old field, deprecated: repeated Waypoint waypoints = 3;
 * Use waypoints_list instead of waypoints.
 * Nest waypoints object so that we can differentiate null vs empty list.
 *
 * @generated from message ritta.partner.hce.control.proto.ScheduleRequest.WayPoints
 */
export const ScheduleRequest_WayPoints = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ScheduleRequest.WayPoints",
  () => [
    { no: 1, name: "waypoints", kind: "message", T: Waypoint, repeated: true },
  ],
  {localName: "ScheduleRequest_WayPoints"},
);

/**
 * NEXT TAG: 11
 *
 * @generated from message ritta.partner.hce.control.proto.ScheduleResponse
 */
export const ScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ScheduleResponse",
  () => [
    { no: 1, name: "pv_forecast", kind: "message", T: ApexSeries },
    { no: 2, name: "gross_load_forecast", kind: "message", T: ApexSeries },
    { no: 3, name: "battery_soc", kind: "message", T: ApexSeries },
    { no: 4, name: "battery_dispatch", kind: "message", T: ApexSeries },
    { no: 5, name: "curtailed_pv", kind: "message", T: ApexSeries },
    { no: 6, name: "waypoints", kind: "message", T: Waypoint, repeated: true },
    { no: 7, name: "point_of_common_coupling", kind: "message", T: ApexSeries },
    { no: 8, name: "generation_load_ratio", kind: "message", T: ApexSeries },
    { no: 9, name: "net_load", kind: "message", T: ApexSeries },
    { no: 10, name: "battery_soc_percent", kind: "message", T: ApexSeries },
    { no: 11, name: "max_power_consumed_watts", kind: "message", T: ApexSeries },
  ],
);

/**
 * NEXT TAG: 5
 *
 * @generated from message ritta.partner.hce.control.proto.HistoricalScheduleRequest
 */
export const HistoricalScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.HistoricalScheduleRequest",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ],
);

/**
 * NEXT TAG: 16
 *
 * @generated from message ritta.partner.hce.control.proto.HistoricalScheduleResponse
 */
export const HistoricalScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.HistoricalScheduleResponse",
  () => [
    { no: 2, name: "pv_actual", kind: "message", T: ApexSeries },
    { no: 4, name: "gross_load_actual", kind: "message", T: ApexSeries },
    { no: 6, name: "net_load_actual", kind: "message", T: ApexSeries },
    { no: 8, name: "generation_load_ratio_actual", kind: "message", T: ApexSeries },
    { no: 9, name: "battery_soc_actual", kind: "message", T: ApexSeries },
    { no: 10, name: "battery_dispatch_actual", kind: "message", T: ApexSeries },
    { no: 12, name: "waypoints", kind: "message", T: Waypoint, repeated: true },
    { no: 13, name: "pcc_actual", kind: "message", T: ApexSeries },
    { no: 15, name: "battery_soc_percent_actual", kind: "message", T: ApexSeries },
  ],
);

/**
 * This is distinct from the standard waypoints.proto value since it
 * has a time within the given day specified as a string
 * NEXT TAG: 7
 *
 * @generated from message ritta.partner.hce.control.proto.ScheduleWaypoint
 */
export const ScheduleWaypoint = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ScheduleWaypoint",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "schedule_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "start_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * Subset of the ScheduleWaypoint with fields needed for creation
 * NEXT TAG: 5
 *
 * @generated from message ritta.partner.hce.control.proto.CreateScheduleWaypoint
 */
export const CreateScheduleWaypoint = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.CreateScheduleWaypoint",
  () => [
    { no: 1, name: "schedule_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "start_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "end_time", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 8
 *
 * @generated from message ritta.partner.hce.control.proto.Schedule
 */
export const Schedule = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Schedule",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "metric", kind: "enum", T: proto3.getEnumType(Metric) },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "waypoints", kind: "message", T: ScheduleWaypoint, repeated: true },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GetScheduleRequest
 */
export const GetScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetScheduleRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.GetScheduleResponse
 */
export const GetScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.GetScheduleResponse",
  () => [
    { no: 1, name: "schedule", kind: "message", T: Schedule },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListSchedulesRequest
 */
export const ListSchedulesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListSchedulesRequest",
  () => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.ListSchedulesResponse
 */
export const ListSchedulesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ListSchedulesResponse",
  () => [
    { no: 1, name: "schedules", kind: "message", T: Schedule, repeated: true },
  ],
);

/**
 * NEXT TAG: 6
 *
 * @generated from message ritta.partner.hce.control.proto.CreateScheduleRequest
 */
export const CreateScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.CreateScheduleRequest",
  () => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metric", kind: "enum", T: proto3.getEnumType(Metric) },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "waypoints", kind: "message", T: CreateScheduleWaypoint, repeated: true },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.CreateScheduleResponse
 */
export const CreateScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.CreateScheduleResponse",
  () => [
    { no: 1, name: "schedule", kind: "message", T: Schedule },
  ],
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.DeleteScheduleRequest
 */
export const DeleteScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.DeleteScheduleRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * Command Generator Configs
 *
 * @generated from message ritta.partner.hce.control.proto.BatteryFleetSchedulerConfig
 */
export const BatteryFleetSchedulerConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.BatteryFleetSchedulerConfig",
  () => [
    { no: 1, name: "telemetry_metrics", kind: "enum", T: proto3.getEnumType(Metric), repeated: true },
    { no: 2, name: "waypoint_look_back_minutes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "waypoint_look_ahead_minutes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "command_generation_interval_seconds", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "command_duration_seconds", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message ritta.partner.hce.control.proto.FtmDerSchedulerConfig
 */
export const FtmDerSchedulerConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.FtmDerSchedulerConfig",
  () => [
    { no: 1, name: "pv_array_group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pcc_resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "net_load_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "waypoint_look_ahead_minutes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "default_policy_parameters", kind: "message", T: Params_AutomaticControlEvent_FtmDerConfig },
  ],
);

/**
 * @generated from message ritta.partner.hce.control.proto.ChargerSchedulerConfig
 */
export const ChargerSchedulerConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.ChargerSchedulerConfig",
  () => [
    { no: 3, name: "waypoint_look_ahead_minutes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 6, name: "require_device_opt_in", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.partner.hce.control.proto.VirtualBatterySchedulerConfig
 */
export const VirtualBatterySchedulerConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.VirtualBatterySchedulerConfig",
  () => [
    { no: 1, name: "waypoint_look_ahead_minutes", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.SubmitWaypointsResponse
 */
export const SubmitWaypointsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.SubmitWaypointsResponse",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "affected_resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "start_time", kind: "message", T: Timestamp },
    { no: 4, name: "end_time", kind: "message", T: Timestamp },
  ],
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.CreateOptoutRequest
 */
export const CreateOptoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.CreateOptoutRequest",
  () => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * No fields needed at this time
 *
 * @generated from message ritta.partner.hce.control.proto.CreateOptoutResponse
 */
export const CreateOptoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.CreateOptoutResponse",
  [],
);

