<template>
  <full-height-layout class="px-6 pt-6">
    <!-- Top -->
    <template v-slot:top>
      <div class="d-flex justify-space-between">
        <!-- Title & Valid As Of -->
        <div>
          <ce-title class="pb-7" big>{{ title }}</ce-title>
          <span aria-label="Valid as of" class="text-caption">
            {{ store.validAsOf }}
          </span>
        </div>

        <!-- Summary Boxes -->
        <div class="d-flex">
          <grid-impact-summary-box
            v-for="(summary, idx) of summaryBoxes"
            :key="summary.title"
            :class="{ 'mr-6': idx !== summaryBoxes.length - 1 }"
            :summary="summary"
            :is-loading="isLoadingStats"
          />
        </div>
      </div>
    </template>

    <!-- Content -->
    <ce-data-table
      :headers="headers"
      :table="dataTable"
      :filters="store.filters"
      :options="store.options"
      :visible-headers="visibleHeaders"
      :is-loading="store.isLoading"
      :server-items-length="store.serverItemsLength"
      :download-href="downloadHref"
      :additional-actions="additionalActions"
      :bg-color="tableBgColor"
      v-model:search="search"
      :searchHint
      :search-min-length="3"
      fixed-header
      sticky-first-column
      dense
      @new-filters="updateFilters"
      @reset-filters="updateFilters"
      @new-options="updateOptions"
      @new-visible-headers="updateVisibleHeaders"
      @search="handleNewSearch"
    >
      <template v-slot:left-of-search>
        <!-- Select inputs -->
        <div class="d-flex pr-4">
          <!-- Interval -->
          <v-select
            :model-value="store.period"
            class="flex-grow-0 mr-4"
            label="Interval"
            aria-label="Interval"
            :items="periodOptions"
            @update:model-value="updatePeriod"
          />

          <!-- Component Type -->
          <v-select
            :model-value="store.componentType"
            :items="store.componentSelectItems"
            label="Component"
            color="secondary"
            style="max-width: 12rem"
            @update:model-value="updateComponentType"
          />
        </div>
      </template>

      <!-- Has model params -->
      <template v-slot:left-of-actions>
        <div
          v-if="store.responseHasModelParams"
          class="d-flex justify-center"
          style="flex: 1"
        >
          <div class="d-flex align-center">
            <v-alert
              :color="colorHasModelParams"
              class="d-block mb-0"
              density="compact"
              dark
            >
              <div class="d-flex align-center">
                <v-icon color="white" class="d-block mr-4">
                  mdi-information
                </v-icon>
                <span class="d-block">{{ textHasModelParams }}</span>
              </div>
            </v-alert>
          </div>
        </div>
      </template>
    </ce-data-table>

    <!-- Model Maker -->
    <grid-impact-model-maker
      :key="store.componentType"
      v-model="isModelMakerOpen"
      :initial-model-params="modelParams"
      :is-loading="store.isLoading"
      @new-model-params="updateModelParams"
    />
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { mapActions } from 'pinia'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ORANGE_500, WHITE } from '@/constants/colors'
import { SUPPORTS_MODEL_MAKER } from '@/model/grid/impact'
import { DataTableAction, Header } from '@/model/tables/DataTable'
import { GridImpactDataTable } from '@/model/grid/ImpactDataTable'
import { useGridImpactsStore } from '@/stores/gridImpacts'
import CeTitle from '@/components/CeTitle.vue'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
import GridImpactSummaryBox, {
  type GridImpactSummary,
} from '@/components/analyze/GridImpactSummaryBox.vue'
import GridImpactModelMaker from '@/components/analyze/GridImpactModelMaker.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import { Format, MEGA_OPTIONS } from '@/utils/format'
import { formatDtWithZoneShort } from '@/utils/time'
import {
  DERImpactsStatsResponse,
  ModelMaker,
} from 'rfs/frontend/proto/analysis_pb'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'

type PeriodOption = { title: string; value: CalendarPeriod }

export default defineComponent({
  name: 'GridImpact',
  components: {
    CeTitle,
    GridImpactSummaryBox,
    CeDataTable,
    GridImpactModelMaker,
    FullHeightLayout,
  },
  setup() {
    const periodOptions: PeriodOption[] = [
      { title: 'Last 30 days', value: CalendarPeriod.MONTH },
      { title: 'Last 12 months', value: CalendarPeriod.YEAR },
    ]

    return {
      periodOptions,
      store: useGridImpactsStore(),
      tableBgColor: WHITE.hex,
      colorHasModelParams: ORANGE_500.hex,
      textHasModelParams: 'Modeled data is present.',
      searchHint: 'Enter whole or partial component name',
    }
  },
  data() {
    const store = useGridImpactsStore()

    return shallowReactive({
      isLoadingStats: false,
      isModelMakerOpen: false,
      derStats: new DERImpactsStatsResponse(),
      search: store.searchText,
    })
  },
  computed: {
    title(): string {
      if (this.$rittaConfig.gridImpact?.showAsBeta) {
        return 'Grid Components (Beta)'
      }
      return 'Grid Components'
    },
    summaryBoxes(): GridImpactSummary[] {
      const {
        peakLoad,
        peakLoadTime,
        evLoadAtPeak,
        evLoadAtPeakPercent,
        pvProductionAtPeak,
        pvProductionAtPeakPercent,
      } = this.derStats

      return [
        {
          title: 'Peak load',
          body: Format.fmtWatts(peakLoad, MEGA_OPTIONS) || TEXT_NO_VALUE,
          footer: formatDtWithZoneShort(peakLoadTime),
        },
        {
          title: 'EV load at peak',
          body: Format.fmtWatts(evLoadAtPeak, MEGA_OPTIONS) || TEXT_NO_VALUE,
          footer: Format.fmtPercent(evLoadAtPeakPercent),
        },
        {
          title: 'PV generation at peak',
          body:
            Format.fmtWatts(pvProductionAtPeak, MEGA_OPTIONS) || TEXT_NO_VALUE,
          footer: Format.fmtPercent(pvProductionAtPeakPercent),
        },
      ]
    },
    dataTable(): GridImpactDataTable {
      return {
        rows: this.store.rows,
        selectable: false,
        noDataText: this.store.isBeingFetched
          ? 'Data is being compiled - please check back in a few minutes'
          : undefined,
      }
    },
    showModelMakerBtn(): boolean {
      return SUPPORTS_MODEL_MAKER.includes(this.store.componentType)
    },
    additionalActions(): DataTableAction[] {
      return [
        {
          show: this.showModelMakerBtn,
          tooltip: 'Model Maker',
          icon: 'mdi-compass-outline',
          callback: () => (this.isModelMakerOpen = !this.isModelMakerOpen),
          callbackRestore: () => (this.isModelMakerOpen = false),
        },
      ]
    },
    downloadHref(): undefined | string {
      return this.store.csvDownloadUrl?.href ?? undefined
    },
    headers(): Header[] {
      return this.store.headers as Header[]
    },
    visibleHeaders() {
      return this.store.visibleHeaders
    },
    modelParams(): ModelMaker {
      return new ModelMaker(this.store.modelParams ?? undefined)
    },
  },
  watch: {
    'store.period': {
      immediate: true,
      handler: function () {
        this.fetchStats()
      },
    },
    showModelMakerBtn(newValue: boolean) {
      // Close the model maker side panel when switching to a resource type that
      // doesn't support the model maker. Ensures the panel is not left open
      // if the feature is unavailable to the resource type.
      if (!newValue && this.isModelMakerOpen) {
        this.isModelMakerOpen = false
      }
    },
  },
  created() {
    // Run an initial search if this is the first time the page is loaded
    if (this.store.isInitialState) {
      this.store.fetchTable()
    }
  },
  methods: {
    ...mapActions(useGridImpactsStore, [
      'updateComponentType',
      'updateFilters',
      'updateOptions',
      'updateVisibleHeaders',
      'updateModelParams',
      'updatePeriod',
      'updateSearchText',
    ]),
    handleNewSearch(): void {
      this.updateSearchText(this.search)
    },
    async fetchStats() {
      try {
        this.isLoadingStats = true

        this.derStats =
          await this.$services.analysisService.fetchDERImpactsStats({
            componentType: 'NONE', // Not used for statistics computation
            fixedInterval: this.store.period,
          })
      } catch (err) {
        console.error('GridImpact.fetchStats: %o', err)
        // When the stats fail to load, clear the stats
        this.derStats = new DERImpactsStatsResponse()
      } finally {
        this.isLoadingStats = false
      }
    },
  },
})
</script>

<style lang="scss" scoped>
section :deep(.v-data-table-rows-no-data) {
  // Because the table is so wide, the default centered text isn't visible without scrolling.
  text-align: left;
}
</style>
