<template>
  <div class="position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-if="!isLoading && !hasLoadingFailed">
      <!-- Header -->
      <resource-header :breadcrumbs="breadcrumbs" class="px-6 pb-10" />

      <!-- Charts -->
      <time-series-chart-group
        v-if="dataSource"
        :charts="charts"
        :data-source="dataSource"
        :interval="interval"
        @new-interval="handleNewInterval"
        class="px-6"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { Interval } from 'luxon'
import { intervalLast24Hours } from '@/utils/time'
import { ResourceType } from '@/constants/resourceType'
import { ITimeSeriesDataSource } from '@/model/charts'
import { getDisplayName } from '@/model/resource'
import {
  windChartDefinitions,
  newWindTimeSeriesDataSource,
} from '@/model/resource/wind/WindChartData'
import * as RouteNames from '@/router/routeNames'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ResourceHeader from '@/components/ResourceHeader.vue'
import { BreadcrumbItem } from '@/components/ResourceHeader'
import { TimeSeriesChartGroup } from '@/components/common'
import { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'WindSingle',
  props: {
    windId: {
      type: String,
      required: true,
    },
  },
  components: { CenteredSpinner, ResourceHeader, TimeSeriesChartGroup },
  setup() {
    return {
      charts: windChartDefinitions,
      dataSource: null as null | ITimeSeriesDataSource,
    }
  },
  data() {
    return {
      isLoading: false,
      wind: shallowRef<null | Resource>(null),
      hasLoadingFailed: false,
      interval: intervalLast24Hours(this.$observationTime()),
    }
  },
  computed: {
    breadcrumbs(): BreadcrumbItem[] {
      const title = this.wind ? getDisplayName(this.wind) : ''
      return [{ title: 'DERs', to: { name: RouteNames.DERS } }, { title }]
    },
  },
  watch: {
    windId: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.hasLoadingFailed = false
      this.wind = null
      this.interval = intervalLast24Hours(this.$observationTime())

      try {
        const wind = await this.$services.queryService.getResource(
          `${ResourceType.WIND}/${this.windId}`
        )

        this.wind = wind
        this.dataSource = newWindTimeSeriesDataSource(this.$services, wind.id)
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('WindSingle.fetchData: %o', err)
        this.$router.replace({ name: RouteNames.DERS })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
