<template>
  <section
    aria-label="Downline meters"
    class="position-relative"
    style="min-height: 18rem"
  >
    <!-- First Loading -->
    <centered-spinner v-if="isFirstLoading" />

    <!-- Content -->
    <ce-data-table
      v-else
      :headers
      :table
      :options
      :isLoading
      :downloadHref
      :bgColor
      :selected
      :show-pagination="false"
      show-select
      sticky
      dense
      @new-selected="updateSelected"
      @new-options="updateOptions"
    >
      <!-- Compare Chart -->
      <template v-slot:above-actions>
        <time-series-chart-group
          :charts="chartDefinitions"
          :data-source="chartDataSources"
          :interval
          hide-date-range-picker
          @new-interval="handleNewInterval"
        />
      </template>
    </ce-data-table>
  </section>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import type { Interval } from 'luxon'
import { GREY6 } from '@/constants/colors'
import { METER_SELECT_TOP } from '@/constants/transformerLoadDetails'
import { Timestamp } from '@/services/timestamp_pb'
import { AggregateDataSource, type ITimeSeriesDataSource } from '@/model/charts'
import {
  createHeaders,
  DownlineMetersDataTable,
  createDownlineMetersTableRows,
  MeterImpactTableResponse,
} from '@/model/grid/ResourceDownlineMetersDataTable'
import { downlineMetersChart } from '@/model/grid/ResourceImpactChartData'
import { getUnqualifiedId } from '@/model/resource'
import type {
  Options,
  NewOptionsContext,
  RowIDSet,
} from '@/model/tables/DataTable'
import { createDefaultOptions } from '@/model/tables/helper'
import { MeterStats, newMeterDataSource } from '@/model/transformer'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import { TimeSeriesChartGroup } from '@/components/common'
import CeDataTable from '@/components/common/CeDataTable.vue'
import type { Resource } from 'rfs/pb/resource_pb'

function createEmptyTable(): DownlineMetersDataTable {
  return { rows: [], selectable: true }
}

function createPristineOptions() {
  return createDefaultOptions('meanPower', 'descending')
}

export default defineComponent({
  name: 'ResourceImpactsTransformerMetersTable',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
  },
  emits: ['new-interval'],
  components: { CenteredSpinner, CeDataTable, TimeSeriesChartGroup },
  setup() {
    return {
      bgColor: GREY6.hex,
      chartDefinitions: [downlineMetersChart],
    }
  },
  data() {
    return shallowReactive({
      isFirstLoading: false,
      isLoading: false,
      headers: createHeaders(this.$dictionary, true),
      options: createPristineOptions(),
      meterStats: {} as MeterStats,
      response: new MeterImpactTableResponse(),
      // Selected meters. Initially, the top 5 meters by average power.
      selected: new Set() as RowIDSet,
      table: createEmptyTable(),
    })
  },
  watch: {
    resource: {
      immediate: true,
      handler: function () {
        this.fetchData({ isNewResouce: true })
      },
    },
    interval() {
      this.fetchData()
    },
  },
  computed: {
    downloadHref(): string {
      return this.response.exportUrl
    },
    chartDataSources(): ITimeSeriesDataSource {
      const meterData = this.selectedMeters.map(
        (itemMeterId) => this.meterDataSources[itemMeterId]
      )
      return new AggregateDataSource(...meterData)
    },
    /** Every meter resource has a corresponding data source for the comparison chart.
     * This caches the data sources for each meter so that they are not recreated on each selection.
     */
    meterDataSources(): Record<string, ITimeSeriesDataSource> {
      const { chartsService } = this.$services
      const interval = this.interval

      return this.allDownlineMeterIDs.reduce((record, id) => {
        record[id] = newMeterDataSource(
          this.$observationTime(),
          chartsService,
          id,
          interval
        )
        return record
      }, {} as Record<string, ITimeSeriesDataSource>)
    },
    dataSource() {
      const selectedMeterDataSources = this.selectedMeters.map(
        (itemMeterId) => this.meterDataSources[itemMeterId]
      )
      return new AggregateDataSource(...selectedMeterDataSources)
    },
    allDownlineMeterIDs(): string[] {
      return this.response.rows.map((item) => item.id)
    },
    selectedMeters(): string[] {
      return Array.from(this.selected)
    },
  },
  methods: {
    createNewTable(): DownlineMetersDataTable {
      const newTable = createEmptyTable()

      newTable.rows = createDownlineMetersTableRows(
        this.response,
        this.meterStats,
        this.options
      )

      return newTable
    },
    updateOptions(newOptions: Options, _ctx?: NewOptionsContext): void {
      this.options = newOptions
      this.table = this.createNewTable()
    },
    updateSelected(selectedItems: RowIDSet): void {
      this.selected = selectedItems
    },
    handleNewInterval(newInterval: Interval): void {
      this.$emit('new-interval', newInterval)
    },
    async fetchData(opts?: { isNewResouce: boolean }): Promise<void> {
      if (opts?.isNewResouce) {
        this.isFirstLoading = true

        // Reset state.
        this.response = new MeterImpactTableResponse()
        this.meterStats = {}
        this.selected = new Set()
        this.table = { rows: [] }
        this.options = createPristineOptions()
      } else {
        this.isLoading = false
      }

      try {
        // Fetch all rows only once. Since we add 2 local columns for
        // Transformers we have to sort the table locally.
        if (opts?.isNewResouce) {
          this.response =
            await this.$services.analysisService.downlineMetersTable({
              resource: this.resource.id,
              limit: 999_999, // ask for everything.
              offset: 0,
            })
        }

        // Meter stats.
        const { meterStats } =
          await this.$services.transformerDetailsService.transformerSeriesV2({
            id: getUnqualifiedId(this.resource.id),
            start: Timestamp.fromDateTime(this.interval.start),
            end: Timestamp.fromDateTime(this.interval.end),
          })
        this.meterStats = meterStats

        // Create new table.
        const newTable = this.createNewTable()

        // Select the top 5 transformers.
        if (opts?.isNewResouce) {
          this.selected = new Set(
            newTable.rows.slice(0, METER_SELECT_TOP).map((r) => r.id)
          )
        }

        this.table = newTable
      } catch (err) {
        console.error(
          'ResourceImpactsTransformerMetersTable.fetchData: %o',
          err
        )
      } finally {
        this.isLoading = false
        this.isFirstLoading = false
      }
    },
  },
})
</script>
