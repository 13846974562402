import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { Format } from '@/utils/format'
import {
  LABEL_CONTROLS_OFF,
  LABEL_ENVELOPES_ONLY,
  LABEL_MANUAL,
  LABEL_RECU_SCHEDULE,
  LABEL_TEMP_SCHEDULE,
} from '@/model/control/labels'
import { DeviceType, type Group } from 'rfs/control/proto/model_pb'
import { Policy, type Params } from 'rfs/control/proto/policy_pb'
import { DeviceHelper } from '../device/helper'

const { typeToResourceType } = DeviceHelper

/**
 * When the group is running in "Recurring Schedule" mode, is expected
 * that it has a schedule id available in the policy params.
 */
function getCurrentScheduleId(policyParams?: Params): undefined | bigint {
  return policyParams?.paramsOneof.case === 'automaticControlEvent'
    ? policyParams.paramsOneof.value.scheduleId
    : undefined
}

/**
 * Returns true when the group is running in "Envelopes only" mode.
 */
export function isEnvelopesOnly(policyParams?: Params): boolean {
  return Boolean(
    policyParams?.paramsOneof.case === 'automaticControlEvent' &&
      policyParams.paramsOneof.value.constraintsOnly
  )
}

export const GroupHelper = {
  getPolicyParams(params?: Params): undefined | string {
    if (!params) return undefined

    const formatter = Format.fmtWatts

    switch (params.paramsOneof.case) {
      // Peak Time Payback
      case 'peakTimePaybackEvent':
        return params.paramsOneof.value.maxPowerKw !== undefined
          ? `${params.paramsOneof.value.maxPowerKw} kW`
          : TEXT_NO_VALUE
      // Manual.
      case 'manualControlEvent':
        return formatter(params.paramsOneof.value.activePowerSetpoint)
      // Automated.
      case 'instantCurtailEvent':
      case 'instantDispatchEvent':
        return formatter(params.paramsOneof.value.watts)
      case 'scadaControlEvent':
        return formatter(params.paramsOneof.value.activePowerSetpoint)
      case 'automaticControlEvent':
        return (() => {
          // Recurring schedule
          if (params.paramsOneof.value.scheduleId !== undefined) {
            return Number(params.paramsOneof.value.scheduleId).toString()
          } else if (isEnvelopesOnly(params)) {
            // Envelopes only
            return TEXT_NO_VALUE
          } else {
            return undefined
          }
        })()
      default:
        return undefined
    }
  },

  getPolicyLabel(policy: Policy, policyParams?: Params): string {
    if (
      policy === Policy.AUTOMATIC_CONTROL_EVENT &&
      isEnvelopesOnly(policyParams)
    ) {
      return LABEL_ENVELOPES_ONLY
    }

    if (
      policy === Policy.AUTOMATIC_CONTROL_EVENT &&
      getCurrentScheduleId(policyParams) !== undefined
    ) {
      return LABEL_RECU_SCHEDULE
    }

    switch (policy) {
      case Policy.DEFAULT:
        return LABEL_CONTROLS_OFF
      case Policy.MANUAL_CONTROL_EVENT:
        return LABEL_MANUAL
      case Policy.INSTANT_CURTAIL_EVENT:
        return 'Instant Curtail Event'
      case Policy.INSTANT_DISPATCH_EVENT:
        return 'Instant Dispatch Event'
      case Policy.SCADA_CONTROL_EVENT:
        return 'SCADA Override'
      case Policy.DISPATCH_SCHEDULE_EVENT:
      case Policy.AUTOMATIC_CONTROL_EVENT:
        return LABEL_TEMP_SCHEDULE
      case Policy.PEAK_TIME_PAYBACK_EVENT:
        return 'Peak Time Payback Event'
      default:
        return 'Unspecified'
    }
  },

  isGroupOfBatteries(group: Group): boolean {
    return (
      group.deviceType === DeviceType.TESLA_POWERWALL_BATTERY ||
      group.deviceType === DeviceType.STEM_BATTERY ||
      group.deviceType === DeviceType.TRIMARK_BATTERY ||
      group.deviceType === DeviceType.EMULATE_VIRTUAL_BATTERY
    )
  },

  isGroupOfChargers(group: Group): boolean {
    return (
      group.deviceType === DeviceType.CHARGEPOINT_EV_CHARGER ||
      group.deviceType === DeviceType.FORD_CDS_CHARGER ||
      group.deviceType === DeviceType.ABB_EVSE
    )
  },

  isGroupOfV2g(group: Group): boolean {
    return group.deviceType === DeviceType.NUVVE_EVSE
  },

  getCurrentScheduleId,

  hasControlsOff(group: Group): boolean {
    return group.enabledPolicies.includes(Policy.DEFAULT)
  },

  hasManualControls(group: Group): boolean {
    return group.enabledPolicies.includes(Policy.MANUAL_CONTROL_EVENT)
  },

  hasAutomaticControls(group: undefined | null | Group): boolean {
    return !!group?.enabledPolicies.includes(Policy.AUTOMATIC_CONTROL_EVENT)
  },

  hasNegativePower(group: Group): boolean {
    return typeToResourceType(group.deviceType) === ResourceType.SOLAR_FARM
  },
}
