import { Interval } from 'luxon'

import { TimeSeries } from '@/services/charts.service'
import { ChartDefinition } from '@/types/charts'
import {
  ChartID,
  DataProvider,
  emptySeries,
  ITimeSeriesDataSource,
  NamedTimeSeries,
  TimeSeriesConfig,
  TimeSeriesMap,
} from './TimeSeriesDataSource'
import { intervalRoundEndOfDay } from '@/utils/time'
import { TimeSeriesResponse } from 'rfs/frontend/proto/tsdb_pb'

/**
 * The fixed data source exposes data over a fixed time period.
 * It does not use the tiling algorithm.
 * Make sure to add the prop: `disable-scroll` to the `<time-series-chart-group />`
 * component using the data source.
 */
export class FixedTimeDataSource implements ITimeSeriesDataSource {
  // The provider calls the service needed to fetch chart data
  private readonly provider: DataProvider
  // Each chart can show the series for multiple metrics
  private readonly configs: Map<ChartID, TimeSeriesConfig[]>

  private readonly cachedSeries: Map<TimeSeriesConfig, TimeSeries>

  private promise: null | Promise<TimeSeriesResponse> = null

  /**
   * Constructs the data source using the given interval and data provider.
   * The interval may or may not be the same as the chart's visible interval.
   */
  constructor(readonly interval: Interval, chartDataProvider: DataProvider) {
    this.provider = chartDataProvider
    this.configs = new Map()
    this.cachedSeries = new Map()
    // Make sure the end is midnight the next day, not 23:59:59.999
    this.interval = intervalRoundEndOfDay(interval)
  }

  /**
   * Set a series definition for the given chart. If the chart is not visible,
   * the chart will not be added to the configuration, so the data is not needlessly fetched.
   */
  addChartSeries(chart: ChartDefinition, series: TimeSeriesConfig): this {
    if (chart.visible === false) return this

    const seriesList = this.configs.get(chart.id) ?? []
    this.configs.set(chart.id, seriesList.concat(series))

    return this
  }

  async fetchTimeSeries(_: Interval): Promise<TimeSeriesMap> {
    // Once we request the interval, we're done
    if (this.cachedSeries.size !== 0) {
      return this.getTimeSeriesMap()
    }

    // We're in the middle of the request call, wait.
    if (this.promise) {
      await this.promise
      return this.getTimeSeriesMap()
    }

    // If not,
    const interval = this.interval
    const metrics = Array.from(this.configs.values()).flat()
    // Quick out if there are no metrics
    if (metrics.length === 0) return this.getTimeSeriesMap()

    // Fetch the data using the fixed interval
    try {
      this.promise = this.provider({ interval, metrics })
      const response = await this.promise

      // Put them in the cache by their metric
      for (const metric of metrics) {
        const newSeries = this.findSeriesFromResponse(metric, response)
        if (newSeries) this.cachedSeries.set(metric, newSeries)
      }
    } catch (err) {
      console.error('[FixedTimeDataSource] request failed: %o', err)
    }

    return this.getTimeSeriesMap()
  }

  getTimeSeriesMap(): TimeSeriesMap {
    const map = new Map() as TimeSeriesMap

    this.configs.forEach((configs, chart) => {
      map.set(
        chart,
        configs.map(
          (c): NamedTimeSeries => ({
            ...(this.cachedSeries.get(c) ?? emptySeries(c.metric)),
            ...c,
          })
        )
      )
    })
    return map
  }

  /**
   * For the given series configuration, return the time series that match.
   * Subclasses can override to customize the algorithm. The default is to match by metric
   * or resource & metric for configurations with a non-empty resource.
   */
  protected findSeriesFromResponse(
    config: TimeSeriesConfig,
    response: TimeSeriesResponse
  ): TimeSeries {
    const { metric, resource } = config
    // A missing resource matches any response resource
    // Or if a response resource matches the config resource
    for (const series of response.series) {
      if (
        metric === series.metric &&
        (resource == null || resource === series.resource)
      ) {
        return series
      }
    }
    return emptySeries(metric)
  }
}
