<template>
  <div class="d-flex position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-else>
      <component
        v-if="operationsComponent"
        :is="operationsComponent"
        v-bind="operationsProps"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import FeederOperations from '@/components/operations/FeederOperations.vue'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'ResourceOperations',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: { CenteredSpinner, FeederOperations },
  data() {
    return shallowReactive({
      isLoading: false,
      substation: null as null | Resource,
    })
  },
  computed: {
    resourceType(): ResourceType {
      return this.resource.type as ResourceType
    },
    operationsComponent(): undefined | string {
      switch (this.resourceType) {
        case ResourceType.FEEDER:
          return 'FeederOperations'
        default:
          return undefined
      }
    },
    operationsProps(): Record<string, any> {
      switch (this.resourceType) {
        case ResourceType.FEEDER:
          return {
            substation: this.substation,
            feeder: this.resource,
          }
        default:
          return {}
      }
    },
  },
  watch: {
    resource: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.substation = null

      if (this.resourceType !== ResourceType.FEEDER) return

      this.isLoading = true

      try {
        const unqualifiedId = this.resource?.upline?.substation

        if (!unqualifiedId) throw new Error('Feeder has no upline.substation')

        this.substation = await this.$services.queryService.getResource(
          `${ResourceType.SUBSTATION}/${unqualifiedId}`
        )
      } catch (err) {
        console.error('ResourceOperations.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
