export enum CommunicationStatusType {
  UNSPECIFIED = 0,
  GOOD = 1,
  UNREACHABLE = 2,
}

export function getCommunicationStatusLabel(
  type: CommunicationStatusType
): string {
  switch (type) {
    case CommunicationStatusType.GOOD:
      return 'Good'
    case CommunicationStatusType.UNREACHABLE:
      return 'Unreachable'
    default:
      return 'UNSPECIFIED'
  }
}

export type CommunicationStatusMap = Map<
  // Resource ID
  string,
  CommunicationStatusType
>
