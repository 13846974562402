import type { Chart, Point } from 'chart.js'
import { sortedIndexBy } from 'lodash-es'

const ZERO_POINT = { x: 0, y: 0 } as const

/**
 * Return the data point object for the active element in the first
 * data series.
 */
export function getActiveElementDataPoint(chart: Chart) {
  const activeElements = chart.getActiveElements()
  if (activeElements.length === 0) return null

  const { datasetIndex, index } = activeElements[0]
  return chart.data.datasets[datasetIndex].data[index] as Point
}

/**
 * Given a timestamp, probably from `getActiveElementDataPoint`,
 * make any elements matching that timestamp look like the user is hovering over them.
 * You need to call `chart.update()` for this to take effect.
 */
export function setActiveElementsFromData(chart: Chart, pt: Point) {
  // Find the index of the data point at this timestamp (in each dataset)
  const actives = chart.data.datasets
    .map((dataset, i) => {
      const data = dataset.data as Point[]
      return { datasetIndex: i, index: sortedIndexBy(data, pt, 'x') }
    })
    .filter(({ datasetIndex, index }) => {
      // Only exact matches to the timestamp should be active
      const data = chart.data.datasets[datasetIndex].data as Point[]
      return data[index]?.x === pt.x
    })
  // Sets active elements like a mouse is hovering over them
  chart.setActiveElements(actives)
  // Shows the tooltip for the same active elements (if enabled)
  chart.tooltip?.setActiveElements(actives, ZERO_POINT)
}

/**
 * Clear the hover indicators and hide the tooltip.
 * You need to call `chart.update()` for this to take effect.
 */
export function clearActiveElements(chart: Chart) {
  let needsUpdate = false

  if (chart.getActiveElements().length) {
    chart.setActiveElements([])
    needsUpdate = true
  }
  if (chart.tooltip?.getActiveElements().length) {
    chart.tooltip.setActiveElements([], ZERO_POINT)
    needsUpdate = true
  }
  return needsUpdate
}
