<template>
  <div class="position-relative" style="min-height: 9rem">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" :size="20" />

    <!-- Data table -->
    <v-data-table
      v-if="!isLoading && !didLoadingFail"
      :headers="dataTableHeaders"
      :items="dataTableItems"
      item-value="scope"
      class="bg-transparent"
      density="compact"
    >
      <!-- Hide Footer -->
      <template v-slot:bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DataTableHeader as VuetifyDataTableHeader } from '@/types/vuetify3'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import { SummaryStats } from 'rfs/frontend/proto/grid_pb'
import { NumberOrNull } from '@/types/charts'

type DataTableItem = {
  meters: string
  scope: string
  solarCapacity: string
  solarResources: string
}

type Info = {
  meterServed: NumberOrNull
  solarProjects: NumberOrNull
  solarCapacity: NumberOrNull
}

type SubstationAndFeederStats = {
  feeder: Info
  substation: Info
}

function newInfo(): Info {
  return { meterServed: null, solarProjects: null, solarCapacity: null }
}

function newStats(): SubstationAndFeederStats {
  return { feeder: newInfo(), substation: newInfo() }
}

function extractInfo(summary: SummaryStats): Info {
  const info = newInfo()

  /* Meters served */
  const meterCount = summary.meter?.resources
  if (meterCount !== undefined) {
    info.meterServed = meterCount
  }

  /* Solar projects */
  const solarCount = summary.solar?.resources
  if (solarCount !== undefined) {
    info.solarProjects = solarCount
  }

  /* Solar capacity */
  const solarCapacity = summary.solar?.ratedWattage
  if (solarCapacity !== undefined) {
    info.solarCapacity = solarCapacity
  }

  return info
}

function createDataTableItems(
  feederId: string,
  { feeder, substation }: SubstationAndFeederStats
): DataTableItem[] {
  const formatPercent = (num: number, denom: number) =>
    `${denom !== 0 ? ((100 * num) / denom).toFixed(0) : '0'}%`

  const feederItem: DataTableItem = {
    scope: feederId,
    meters: feeder.meterServed
      ? Format.fmtCount(feeder.meterServed)
      : TEXT_NO_VALUE,
    solarResources: feeder.solarProjects
      ? Format.fmtCount(feeder.solarProjects)
      : TEXT_NO_VALUE,
    solarCapacity: feeder.solarCapacity
      ? Format.fmtWatts(feeder.solarCapacity)
      : TEXT_NO_VALUE,
  }

  const substationItem: DataTableItem = {
    scope: 'vs Substation',
    meters:
      substation.meterServed && feeder.meterServed
        ? formatPercent(feeder.meterServed, substation.meterServed)
        : TEXT_NO_VALUE,
    solarResources:
      feeder.solarProjects && substation.solarProjects
        ? formatPercent(feeder.solarProjects, substation.solarProjects)
        : TEXT_NO_VALUE,
    solarCapacity:
      feeder.solarCapacity && substation.solarCapacity
        ? formatPercent(feeder.solarCapacity, substation.solarCapacity)
        : TEXT_NO_VALUE,
  }

  return [feederItem, substationItem]
}

export default defineComponent({
  name: 'FeederSummaryDataTable',
  props: {
    substationId: {
      type: String,
      required: true,
    },
    feederId: {
      type: String,
      required: true,
    },
  },
  components: {
    CenteredSpinner,
  },
  data() {
    return {
      isLoading: false,
      didLoadingFail: false,
      dataTableHeaders: [
        {
          title: '',
          sortable: false,
          value: 'scope',
        },
        {
          title: 'Meters',
          sortable: false,
          value: 'meters',
        },
        {
          title: 'Solar Projects',
          sortable: false,
          value: 'solarResources',
        },
        {
          title: 'Solar Capacity (AC)',
          sortable: false,
          value: 'solarCapacity',
        },
      ] as VuetifyDataTableHeader[],
      stats: null as null | SubstationAndFeederStats,
    }
  },
  computed: {
    dataTableItems(): DataTableItem[] {
      return this.stats ? createDataTableItems(this.feederId, this.stats) : []
    },
    /* this computed property exists only to be watched */
    computedProps(): [string, string] {
      return [this.substationId, this.feederId]
    },
  },
  watch: {
    computedProps: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.didLoadingFail = false
      this.stats = null

      try {
        this.stats = await this.fetchStats()
      } catch (err) {
        console.error('FeederSummaryDataTable.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    async fetchStats(): Promise<SubstationAndFeederStats> {
      const stats = newStats()

      try {
        const [feederSummary, substationSummary] = await Promise.all([
          this.$services.gridService.feederSummary(this.feederId),
          this.$services.gridService.substationSummary(this.substationId),
        ])

        stats.feeder = extractInfo(feederSummary)
        stats.substation = extractInfo(substationSummary)
      } catch (err) {
        console.error('FeederSummaryDataTable.fetchStats: %o', err)
      }

      return stats
    },
  },
})
</script>
