const { freeze } = Object
const { hasOwnProperty } = Object.prototype

type Writable<T> = {
  -readonly [K in keyof T]: T[K]
}

export type WritableValues<T> = {
  [K in keyof T]: Writable<T[K]>
}

/**
 * Readonly<T> makes an object's properties read-only.
 * ReadonlyValues<T> makes the values for each property read-only.
 */
export type ReadonlyValues<T> = {
  [K in keyof T]: Readonly<T[K]>
}

/**
 * Assign multiple new values to an object, maintaining type-safety.
 * If all the values are unchanged, the original object is returned
 */
export function assign<T extends object>(obj: T, values: Partial<T>): T {
  let newObj = obj
  for (const key in values) {
    if (hasOwnProperty.call(values, key)) {
      newObj = assoc(newObj, key, values[key]!)
    }
  }
  return newObj
}

/**
 * Assign a new value to an object using copy-on-write semantics.
 * If the value is unchanged, the original object is returned.
 */
export function assoc<T, K extends keyof T>(obj: T, key: K, val: T[K]): T {
  return obj[key] !== val ? { ...obj, [key]: val } : obj
}

/**
 * Freezes all properties of the given object.
 */
export function immutableValues<T extends object>(obj: T): ReadonlyValues<T> {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] != null) {
      freeze(obj[key])
    }
  }
  return obj
}
