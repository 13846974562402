import { RouteRecordRaw } from 'vue-router'

import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import MetersLeftPanel from '@/components/meters/MetersLeftPanel.vue'
import * as RouteNames from './routeNames'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export const TITLE_METERS = 'Meters'

export function meterRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  if (!config.monitor?.meters?.enabled) {
    return []
  }

  return [
    {
      path: '/meters',
      component: Home,
      children: [
        {
          path: '',
          name: RouteNames.METERS,
          meta: { tab: TAB, drawerLeftWide: true, pageTitle: TITLE_METERS },
          components: { leftPanel: MetersLeftPanel },
        },
      ],
    },
  ]
}
