<template>
  <div class="position-relative min-height-100">
    <centered-spinner v-if="isLoading" />

    <template v-else>
      <!-- Title -->
      <resource-header :breadcrumbs="breadcrumbs" class="px-6 pb-10" />

      <!-- Charts -->
      <time-series-chart-group
        v-if="dataSource"
        class="px-6"
        :charts="charts"
        :data-source="dataSource"
        :interval="interval"
        @new-interval="handleNewInterval"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { Interval } from 'luxon'
import { ResourceType } from '@/constants/resourceType'
import * as RouteNames from '@/router/routeNames'
import { getDisplayName } from '@/model/resource'
import { ITimeSeriesDataSource } from '@/model/charts'
import {
  hydroChartDefinitions,
  newHydroTimeSeriesDataSource,
} from '@/model/resource/hydro/HydroChartData'
import { intervalLast24Hours } from '@/utils/time'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ResourceHeader from '@/components/ResourceHeader.vue'
import { BreadcrumbItem } from '@/components/ResourceHeader'
import { TimeSeriesChartGroup } from '@/components/common'
import { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'HydroSingle',
  props: {
    hydroId: {
      type: String,
      required: true,
    },
  },
  components: { CenteredSpinner, ResourceHeader, TimeSeriesChartGroup },
  setup() {
    return {
      charts: hydroChartDefinitions,
      dataSource: null as null | ITimeSeriesDataSource,
    }
  },
  data() {
    return {
      isLoading: false,
      hydro: shallowRef<null | Resource>(null),
      interval: intervalLast24Hours(this.$observationTime()),
    }
  },
  computed: {
    hydroTitle(): string {
      return this.hydro ? getDisplayName(this.hydro) ?? '' : ''
    },
    breadcrumbs(): BreadcrumbItem[] {
      return [
        { title: 'DERs', to: { name: RouteNames.DERS } },
        { title: this.hydroTitle },
      ]
    },
  },
  watch: {
    hydroId: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.hydro = null
      this.dataSource = null
      this.interval = intervalLast24Hours(this.$observationTime())

      try {
        const hydro = await this.$services.queryService.getResource(
          `${ResourceType.HYDRO}/${this.hydroId}`
        )

        this.hydro = hydro
        this.dataSource = newHydroTimeSeriesDataSource(this.$services, hydro.id)
      } catch (err) {
        console.error('HydroSingle.fetchData: %o', err)
        this.$router.replace({ name: RouteNames.DERS })
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
