import { RittaConfig } from '@/config'
import { GRAY_COOL_100 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Metric } from '@/constants/metrics'
import {
  DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL,
  DEFAULT_GMAPS_TILT,
} from '@/model/map/constants'
import { loadGmaps } from '@/model/map/gmaps'
import {
  TimeSeries,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { InfoColumnAllProps } from '../InfoColumn'
import { ToggleMetric } from './FeederOperations.vue'
import { ResourcesTimeSeries } from './FeederOperationsMapCatalog'

/** * Creates the base GMaps Options to be used with Map Manager. */
function createOperationsMapOptions(
  config: Readonly<RittaConfig>
): google.maps.MapOptions {
  return {
    center: config.map.startingCoordinates,
    mapId: config.map.mapId,
    zoom: DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL,
    // Prevent zooming out too far so the feeder conductor lines are still visible.
    minZoom: 12,
    tilt: DEFAULT_GMAPS_TILT,
    // See: https://developers.google.com/maps/documentation/javascript/controls#DisablingDefaults
    disableDefaultUI: true,
    styles: [
      {
        featureType: 'all',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'landscape',
        stylers: [{ visibility: 'on' }, { color: GRAY_COOL_100.hex }],
      },
    ],
  }
}

export async function createOperationsMap(
  config: Readonly<RittaConfig>,
  el: HTMLElement
): Promise<google.maps.Map> {
  await loadGmaps(config)

  const options = createOperationsMapOptions(config)

  const map = new google.maps.Map(el, options)

  return map
}

/**
 * MetricTimeResourceData Represents a mapping of metrics to their time-stamps.
 * Each time-stamp is associated with resource IDs and their value at that time stamp.
 * Additionally, each metric includes minimum and maximum data points of
 * across all resources and time-stamps in that metric.
 *
 * This data structure is precomputed and used to update the map layers.
 * By arranging the data by time-stamps, we can quickly update
 * the map layers with the new data when the user interacts with the time slider.
 * Other approaches would require iterating over all resources and time-stamps,
 * using a binary search to find the correct time-stamp, and then updating the map layer.
 * Which requires more computation every time the user interacts with the time slider.
 * This approach is more efficient and allows for a smoother user experience.
 */
export type MetricTimeResourceData = {
  [metric: string]: {
    // Mapping of timestamps (in milliseconds) to resource-specific values.
    [timeStampMillis: number]: {
      [resourceId: string]: number
    }
    // Minimum value data point in the time series for this metric.
    min: TimeSeriesDataPoint
    // Maximum value data point in the time series for this metric.
    max: TimeSeriesDataPoint
  }
}

export function reduceTimeSeriesToObject(
  pre: MetricTimeResourceData,
  curr: TimeSeries
): MetricTimeResourceData {
  const { resource, metric, data, min, max } = curr
  for (const dp of data) {
    if (dp.y) {
      pre[metric] ??= {
        min: min ?? new TimeSeriesDataPoint(),
        max: max ?? new TimeSeriesDataPoint(),
      }
      pre[metric][dp.x] ??= {}
      pre[metric][dp.x][resource] = dp.y
      // Use the min and max of all data points
      // across all resources and time-stamps for the metric.
      const currMin = pre[metric].min.y
      if (currMin && min?.y && min?.y < currMin) {
        pre[metric].min = dp
      }
      const currMax = pre[metric].max.y
      if (currMax && max?.y && max?.y > currMax) {
        pre[metric].max = dp
      }
    }
  }
  return pre
}

export const voltageMetrics = [
  Metric.FORECAST_UPLINE_COND_VOLTAGE_A,
  Metric.FORECAST_UPLINE_COND_VOLTAGE_B,
  Metric.FORECAST_UPLINE_COND_VOLTAGE_C,
]

export const currentMetrics = [
  Metric.FORECAST_COND_CURRENT_A,
  Metric.FORECAST_COND_CURRENT_B,
  Metric.FORECAST_COND_CURRENT_C,
]

export function getLoadingValue(
  tsValues: MetricTimeResourceData,
  timeStampMillis: number,
  id: string
): number {
  const currents = getCurrentPhases(tsValues, timeStampMillis, id)

  return currents.length ? Math.max(...currents.filter(Boolean)) : 0
}

// get all voltages and subtract 1 to get the deviation from 1
// find the voltage deviation with the highest magnitude via absolute value
// which ever phase is the highest deviation, use that phase's original value
// max(|v_phaseA-1|, |v_phaseB-1|, |v_phaseC-1|)
// PRD: https://docs.google.com/document/d/1pjyVLzzltjn3uf99n-phgvXvCwVB1h9H7QPUG46H8zQ/edit#heading=h.vcefj7ltzjag
export function getVoltageValue(
  tsValues: MetricTimeResourceData,
  timeStampMillis: number,
  id: string
): number {
  const voltages = getVoltagePhases(tsValues, timeStampMillis, id)

  const deviations = voltages.map((voltage) =>
    voltage !== undefined ? Math.abs(voltage - 1) : 0
  )

  const maxDeviation = Math.max(...deviations)

  const maxDeviationIndex = deviations.indexOf(maxDeviation)

  return voltages[maxDeviationIndex] || 0
}

// Voltage upline/downline
//   - fuse
//   - recloser
//   - regulator
//   - switch
//   - transformer
// Voltage
//   - capacitor
// Current
//   - conductor

// Create a color scale
// scale a value between 0 and 1 to the colors array
export function colorScale(value: number) {
  const colors = [
    '#4B2362',
    '#5B2867',
    '#6C2B6D',
    '#7E2F70',
    '#8F3371',
    '#A0376F',
    '#B13C6C',
    '#C24168',
    '#D14A61',
    '#DC575C',
    '#E3685C',
    '#E77A62',
    '#E98C6B',
    '#EB9E76',
    '#EDB081',
  ]

  const index = Math.floor(value * (colors.length - 1))
  return colors[index]
}

export function getVoltagePhases(
  tsValues: MetricTimeResourceData,
  timeStampMillis: number,
  id: string
): number[] {
  return voltageMetrics.map(
    (metric) => tsValues[metric]?.[timeStampMillis]?.[id]
  )
}

export function getCurrentPhases(
  tsValues: MetricTimeResourceData,
  timeStampMillis: number,
  id: string
): number[] {
  return currentMetrics.map(
    (metric) => tsValues[metric]?.[timeStampMillis]?.[id]
  )
}

export function getInfoColumnProps(
  r: Resource,
  resourcesData: ResourcesTimeSeries
): InfoColumnAllProps {
  const id = r.id
  const { tsValues, timeStampMillis, metric } = resourcesData

  let phaseA, phaseB, phaseC
  const subtitle =
    metric === ToggleMetric.VOLTAGE ? 'Voltage (p.u.)' : 'Current'

  if (metric === ToggleMetric.VOLTAGE) {
    ;[phaseA, phaseB, phaseC] = getVoltagePhases(tsValues, timeStampMillis, id)
  } else if (metric === ToggleMetric.LOADING) {
    ;[phaseA, phaseB, phaseC] = getCurrentPhases(tsValues, timeStampMillis, id)
  }

  return {
    title: id,
    subtitle,
    subtitleBold: true,
    subtitleRight: true,
    items: [
      {
        label: 'Phase A',
        text: phaseA ? phaseA.toFixed(2) : TEXT_NO_VALUE,
      },
      {
        label: 'Phase B',
        text: phaseB ? phaseB.toFixed(2) : TEXT_NO_VALUE,
      },
      {
        label: 'Phase C',
        text: phaseC ? phaseC.toFixed(2) : TEXT_NO_VALUE,
      },
    ],
  }
}
