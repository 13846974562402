import { ChartDefinition, ChartType } from '@/types/charts'
import { STANDARD_COLORS } from './colorPalette'

export const METER_SELECT_TOP = 5
export const METER_SELECT_LIMIT = 10

export const transformerLoadCharts: ChartDefinition[] = [
  {
    id: 'transformer-load',
    type: ChartType.Load,
    title: '',
    additionalYAxis: {
      metric: 'conditions.power.apparent',
      type: ChartType.ApparentPower,
    },
    annotations: { threshold: { yValue: 1.0, label: 'threshold' } },
  },
  {
    id: 'transformer-voltage',
    type: ChartType.VoltagePerUnit,
    title: '',
    visible: 'requires-data',
    annotations: { voltageTolerance: { percentage: 5, nominal: 1.0 } },
  },
  {
    id: 'downline-meters',
    type: ChartType.Power,
    title: 'Downline Meters',
    seriesColors: STANDARD_COLORS,
  },
]

export const apparentPowerDurationChart: ChartDefinition = {
  id: 'apparent-power-duration',
  type: ChartType.LoadDuration,
  title: '',
  fileName: 'Apparent Power Duration',
  annotations: { threshold: { yValue: 0.0, label: 'kVA Rating' } },
  xAxis: { title: 'Percent of Time', range: [0, 1.0] },
}
