import { Timestamp } from '@/services/timestamp_pb'
import {
  createPromiseClient,
  type PromiseClient,
  type Transport,
} from '@connectrpc/connect'
import { ControlsData } from 'rfs/frontend/proto/controls_connect'
import { ScheduleTimeSeriesRequest } from 'rfs/frontend/proto/controls_pb'
import {
  TimeSeriesRequest,
  type TimeSeriesResponse,
} from 'rfs/frontend/proto/tsdb_pb'
import type { TimeSeriesFetch } from './charts.service'
import type { Waypoint } from 'rfs/control/proto/waypoints_pb'

export class ControlsDataService {
  private client: PromiseClient<typeof ControlsData>

  constructor(transport: Transport) {
    this.client = createPromiseClient(ControlsData, transport)
  }

  async fetchHistoricalSchedule(
    tsFetch: TimeSeriesFetch,
    groupId: string,
    scheduleId?: bigint
  ): Promise<TimeSeriesResponse> {
    const req = new ScheduleTimeSeriesRequest({
      groupId,
      scheduleId,
      metrics: tsFetch.metrics,
      start: Timestamp.fromDateTime(tsFetch.interval.start),
      end: Timestamp.fromDateTime(tsFetch.interval.end),
      resolution: tsFetch.resolution,
    })
    return this.client.historicalScheduleTimeSeries(req)
  }

  async fetchPlanSchedule(
    groupId: string,
    tsFetch: TimeSeriesFetch,
    waypoints?: Waypoint[],
    scheduleId?: bigint
  ) {
    return this.client.planScheduleTimeSeries(
      new ScheduleTimeSeriesRequest({
        groupId,
        scheduleId,
        metrics: tsFetch.metrics,
        start: Timestamp.fromDateTime(tsFetch.interval.start),
        end: Timestamp.fromDateTime(tsFetch.interval.end),
        resolution: tsFetch.resolution,
        // NOTE(rafael): not sending any "waypoints" means asking for
        // the current scheduled waypoints.
        ...(waypoints !== undefined && {
          waypointsList: { waypoints },
        }),
      })
    )
  }

  async fetchGroupTimeSeries(groupId: string, fetch: TimeSeriesFetch) {
    return this.client.groupTimeSeries(
      new TimeSeriesRequest({
        resource: groupId,
        start: Timestamp.fromDateTime(fetch.interval.start),
        end: Timestamp.fromDateTime(fetch.interval.end),
        metrics: fetch.metrics,
        resolution: fetch.resolution,
        aggregation: fetch.aggregation,
      })
    )
  }
}
