<template>
  <tr>
    <!-- Index -->
    <td class="pt-5" style="vertical-align: top">
      {{ index }}
    </td>

    <!-- Date -->
    <td v-if="date !== undefined" class="pt-2" style="vertical-align: top">
      <date-picker
        :value="date"
        :min="minDate?.toISODate()"
        :max="maxDate?.toISODate()"
        :error="Boolean(dateErrors.length)"
        :error-messages="dateErrors"
        autofocus
        @update:model-value="(v) => $emit('update:date', v)"
      />
    </td>

    <!-- Start time -->
    <td class="pt-2" style="vertical-align: top">
      <v-text-field
        type="time"
        :value="startTime"
        :error="Boolean(startTimeErrors.length)"
        :error-messages="startTimeErrors"
        :autofocus="date === undefined"
        @update:model-value="(v) => $emit('update:startTime', v)"
      />
    </td>

    <!-- End time -->
    <td class="pt-2" style="vertical-align: top">
      <v-text-field
        type="time"
        :value="endTime"
        :error="Boolean(endTimeErrors.length)"
        :error-messages="endTimeErrors"
        @update:model-value="(v) => $emit('update:endTime', v)"
      />
    </td>

    <!-- Target Value -->
    <td class="pt-2" style="vertical-align: top">
      <v-text-field
        type="number"
        :value="targetValue"
        :error="Boolean(targetValueErrors.length)"
        :error-messages="targetValueErrors"
        class="hide-arrows"
        color="secondary"
        @keydown="keydownHandler"
        @update:model-value="(v) => $emit('update:targetValue', v)"
      />
    </td>

    <!-- Action btns -->
    <td class="pt-3" style="vertical-align: top">
      <row-actions
        @click-cancel="$emit('cancel')"
        @click-save="$emit('save')"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import { FormFields, ValidationResult } from '@/model/forms/FormFields'
import {
  getKeydownInputValidation,
  type Metric,
} from '@/model/control/waypoint'
import RowActions from '@/components/control/RowActions.vue'
import DatePicker from '@/components/common/DatePicker.vue'

export interface ExpectedFormFields extends FormFields {
  date?: string
  startTime: string
  endTime: string
  targetValue: string
}

export type ExpectedValidation = ValidationResult<ExpectedFormFields>

export default defineComponent({
  name: 'AutomaticControlRowEdit',
  props: {
    metric: {
      type: Number as PropType<Metric>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: false,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    targetValue: {
      type: String,
      required: true,
    },
    validation: {
      type: Object as PropType<ExpectedValidation>,
      required: false,
    },
    minDate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
    maxDate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  emits: [
    'update:date',
    'update:startTime',
    'update:endTime',
    'update:targetValue',
    'cancel',
    'save',
  ],
  components: { RowActions, DatePicker },
  setup(props) {
    return { keydownHandler: getKeydownInputValidation(props.metric) }
  },
  computed: {
    dateErrors(): string[] {
      return this.validation?.date ?? []
    },
    startTimeErrors(): string[] {
      return this.validation?.startTime ?? []
    },
    endTimeErrors(): string[] {
      return this.validation?.endTime ?? []
    },
    targetValueErrors(): string[] {
      return this.validation?.targetValue ?? []
    },
  },
})
</script>
