import { Duration } from 'luxon'
import { TimeSeries } from '@/services/charts.service'

/**
 * The standard chart resolutions supported by RFS.
 */
export enum ChartResolution {
  Raw,
  OneMin,
  FiveMin,
  FifteenMin,
  ThirtyMin,
  OneHour,
  ThreeHour,
  OneDay,
  /**
   * A special value indicating the data is in multiple resolutions
   */
  Various = 0xff,
}

/**
 * A mapping of resolutions to `Duration` objects.
 */
export const resolutionDurations = new Map<ChartResolution, Duration>()
  .set(ChartResolution.Raw, Duration.fromObject({ seconds: 1 }))
  .set(ChartResolution.OneMin, Duration.fromObject({ minutes: 1 }))
  .set(ChartResolution.FiveMin, Duration.fromObject({ minutes: 5 }))
  .set(ChartResolution.FifteenMin, Duration.fromObject({ minutes: 15 }))
  .set(ChartResolution.ThirtyMin, Duration.fromObject({ minutes: 30 }))
  .set(ChartResolution.OneHour, Duration.fromObject({ hours: 1 }))
  .set(ChartResolution.ThreeHour, Duration.fromObject({ hours: 3 }))
  .set(ChartResolution.OneDay, Duration.fromObject({ days: 1 }))

const DAYS_HRS_MINS_SECS = ['days', 'hours', 'minutes', 'seconds'] as const

/**
 * Return the (best-effort) resolution for a given set of time series.
 * The resolution may be `Raw` if there is no data or there are conflicting
 * resolutions in the data sets.
 */
export function resolutionFromTimeSeries(
  data: Map<any, readonly TimeSeries[]>
): ChartResolution {
  const allResolutions = Array.from(data.values())
    .flat()
    .map((series) => {
      if (series.data.length < 2) return ChartResolution.Raw

      const [point1, point2] = series.data
      const duration = Duration.fromMillis(point2.x - point1.x)

      return resolutionFromDuration(duration.shiftTo(...DAYS_HRS_MINS_SECS))
    })

  if (allResolutions.length === 0) {
    return ChartResolution.Raw
  }
  // If any two resolutions differ, then the result is "Various"
  return allResolutions.reduce((acc, resolution) => {
    return acc === resolution ? acc : ChartResolution.Various
  })
}

function resolutionFromDuration(duration: Duration): ChartResolution {
  if (duration.days >= 1) return ChartResolution.OneDay
  if (duration.hours >= 3) return ChartResolution.ThreeHour
  if (duration.hours >= 1) return ChartResolution.OneHour
  if (duration.minutes >= 30) return ChartResolution.ThirtyMin
  if (duration.minutes >= 15) return ChartResolution.FifteenMin
  if (duration.minutes >= 5) return ChartResolution.FiveMin
  if (duration.minutes >= 1) return ChartResolution.OneMin
  if (duration.valueOf() === 0) return ChartResolution.Raw

  return ChartResolution.Raw
}
