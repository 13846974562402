export function getEnvironment(location: Location = window.location) {
  if (location.hostname.startsWith('staging')) {
    return 'staging' // staging.sqid.grid.dev
  } else if (location.hostname.startsWith('dev')) {
    return 'dev' // dev.sqid.grid.dev
  } else if (location.hostname.endsWith('.grid.dev')) {
    return 'sandbox' // capy-the-developer.sqid.grid.dev, capy-the-developer.sqid.sb.grid.dev
  } else {
    return 'production' // sqid.camus.app
  }
}
