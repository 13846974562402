import type { Component } from 'vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'
import { RittaConfig } from '@/config'
import { MapLayerId } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { MapManager } from '@/model/map'
import { Services } from '@/services'
import { MapLayerFeature, MapManagerLayer } from '@/model/map/types'
import { ControlPosition } from '@/model/map/gmaps'
import { meterEndpoint } from './MeterEndpoint'
import { distribution } from './Distribution'
import { EvCharger } from './EvCharger'
import { transformer } from './Transformer'
import { transmission } from './Transmission'
import { substation } from './Substation'
import { distributedSolar } from './DistributedSolar'
import { voltageMapping } from './VoltageMapping'
import { V2GBus } from './V2GBus'
import { UtilitySolar } from './UtilitySolar'
import { Wind } from './Wind'
import { DistributedBattery } from './DistributedBattery'
import { Hydro } from './Hydro'
import { Fleet } from './Fleet'
import { MethaneCapture } from './MethaneCapture'
import { UtilityBattery } from './UtilityBattery'
import * as Midline from './midlineDevices'
import { SensorElectrical } from './SensorElectric'

/** Additional props that map manager lays can use */
export interface ExtraProps {
  // TODO(andrew): Change this to InfoColumnAllProps so we can use 1 component for all
  infoWindow?: InfoWindowProps
}

/** Required props to allow the use of an info window for the map layer. */
export interface InfoWindowProps {
  /**
   * Compute the info column for the given map feature.
   */
  infoColumn: (f: MapLayerFeature) => InfoColumnAllProps

  /**
   * Use center of polygon to place infowindow
   */
  centerOfPoly?: boolean

  /**
   * If the InfoWindow does not require a Resource, set this flag.
   */
  ignoresResource?: boolean
}

export interface CustomControlProps {
  /**
   * Currently expects a Vue component constructor from which the HTML will
   * be used as the custom control content.
   */
  component: Component
  /**
   * NOTE: Do not use `google.maps.ControlPosition` because "google" may not
   * be available in the window yet since it's async loaded. Use our
   * own enum `ControlPosition`.
   */
  position: ControlPosition
}

export type FactoryCustomControlProps = (
  config: Readonly<RittaConfig>
) => null | CustomControlProps

export type FactoryMapManagerLayer = (
  config: Readonly<RittaConfig>,
  services: Services,
  mapManager: MapManager
) => MapManagerLayer

export interface ApplicationMapLayer {
  id: MapLayerId
  label: string
  icon: string
  infoWindow?: InfoWindowProps
  customControl?: FactoryCustomControlProps
  // TODO(rafael): make it required when all legacy layers ported to deckgl.
  mapManagerLayer?: FactoryMapManagerLayer
  /** Return a layer that can be used for highlights. */
  highlightLayer?: (features: MapLayerFeature[]) => MapManagerLayer
  // TODO(rafael): delete this option when all legacy layers ported to deckgl.
  legacyLayer?: any
}

export type MapLayerCatalog = Map<MapLayerId, ApplicationMapLayer>

/** ************************************
 * Map Manager Layers (deck.gl)
 * *********************************** */
const MeterEndpoint = meterEndpoint(MapLayerId.METER_ENDPOINT)
const Distribution = distribution(MapLayerId.DISTRIBUTION)
const Transformer = transformer(MapLayerId.TRANSFORMER)
const Transmission = transmission(MapLayerId.TRANSMISSION)
const Substation = substation(MapLayerId.SUBSTATION)
const DistributedSolar = distributedSolar(MapLayerId.DISTRIBUTED_SOLAR)
const VoltageMapping = voltageMapping(MapLayerId.VOLTAGE_MAPPING)

/**
 * List of available Map Layers to be used within the application.
 *
 * The order of the map manager layers here affects the order of them
 * being appended to deckgl.
 *
 * Example: VoltageMapping layer is the first one to be appended to deckgl so
 * it gets behind all the others.
 */
export const mapLayerCatalog: MapLayerCatalog = new Map()
  .set(VoltageMapping.id, VoltageMapping)
  .set(Distribution.id, Distribution)
  .set(Transmission.id, Transmission)
  .set(MeterEndpoint.id, MeterEndpoint)
  .set(DistributedBattery.id, DistributedBattery)
  .set(Transformer.id, Transformer)
  .set(SensorElectrical.id, SensorElectrical)
  .set(Midline.BreakerLayer.id, Midline.BreakerLayer)
  .set(Midline.CapacitorLayer.id, Midline.CapacitorLayer)
  .set(Midline.FuseLayer.id, Midline.FuseLayer)
  .set(Midline.RecloserLayer.id, Midline.RecloserLayer)
  .set(Midline.RegulatorLayer.id, Midline.RegulatorLayer)
  .set(Midline.SectionalizerLayer.id, Midline.SectionalizerLayer)
  .set(Midline.SwitchLayer.id, Midline.SwitchLayer)
  .set(DistributedSolar.id, DistributedSolar)
  .set(EvCharger.id, EvCharger)
  .set(UtilitySolar.id, UtilitySolar)
  .set(Wind.id, Wind)
  .set(Substation.id, Substation)
  .set(Hydro.id, Hydro)
  .set(V2GBus.id, V2GBus)
  .set(Fleet.id, Fleet)
  .set(MethaneCapture.id, MethaneCapture)
  .set(UtilityBattery.id, UtilityBattery)

export const resourceTypeToMapLayerId: {
  [key in ResourceType]: MapLayerId | null
} = {
  [ResourceType.BATTERY_DISTRIBUTED]: MapLayerId.DISTRIBUTED_BATTERY,
  [ResourceType.BATTERY_STATION]: MapLayerId.UTILITY_BATTERY,
  [ResourceType.BREAKER]: MapLayerId.BREAKER,
  [ResourceType.CALLOUT]: MapLayerId.DISTRIBUTION,
  [ResourceType.CAPACITOR]: MapLayerId.CAPACITOR,
  [ResourceType.SOLAR_DISTRIBUTED]: MapLayerId.DISTRIBUTED_SOLAR,
  [ResourceType.CONDUCTOR]: MapLayerId.DISTRIBUTION,
  [ResourceType.CHARGER]: MapLayerId.EV_CHARGER,
  [ResourceType.FUSE]: MapLayerId.FUSE,
  [ResourceType.VEHICLE]: MapLayerId.FLEET,
  [ResourceType.HYDRO]: MapLayerId.HYDRO,
  [ResourceType.METHANE]: MapLayerId.METHANE_CAPTURE,
  [ResourceType.METER_ELECTRICAL]: MapLayerId.METER_ENDPOINT,
  [ResourceType.RECLOSER]: MapLayerId.RECLOSER,
  [ResourceType.REGULATOR]: MapLayerId.REGULATOR,
  [ResourceType.SECTIONALIZER]: MapLayerId.SECTIONALIZER,
  [ResourceType.SENSOR_ELECTRICAL]: MapLayerId.SENSOR_ELECTRIC,
  [ResourceType.SOLAR_FARM]: MapLayerId.UTILITY_SOLAR,
  [ResourceType.SUBSTATION]: MapLayerId.SUBSTATION,
  [ResourceType.SWITCH]: MapLayerId.SWITCH,
  [ResourceType.TRANSFORMER]: MapLayerId.TRANSFORMER,
  [ResourceType.WIND]: MapLayerId.WIND,

  // No resource type for these layers
  // [TRANSMISSION]: MapLayerId.TRANSMISSION,
  // [V2G_BUS]: MapLayerId.V2G_BUS,
  // [VOLTAGE_MAPPING]: MapLayerId.VOLTAGE_MAPPING,

  //  Not yet implemented
  [ResourceType.BIOMASS]: null,
  [ResourceType.FEEDER]: null,
  [ResourceType.GENERATION]: null,
  [ResourceType.MICROGRID]: null,
  [ResourceType.POOL_DEVICE]: null,
  [ResourceType.PROVIDER]: null,
  [ResourceType.SENSOR_CONDUCTOR]: null,
  [ResourceType.SITE_CUSTOMER]: null,
  [ResourceType.SITE_UTILITY]: null,
  [ResourceType.SUBSCRIPTION_ELECTRICAL]: null,
  [ResourceType.ZONE_VOLTAGE]: null,
}
