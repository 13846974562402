<template>
  <div class="bg-grey-lighten-4 pa-4 position-relative" style="width: 17rem">
    <!-- Title -->
    <span v-if="summary.title" class="d-block text-subtitle-2">
      {{ summary.title }}
    </span>

    <!-- Last value -->
    <span v-if="summary.lastValue" class="d-block text-h4">
      {{ summary.lastValue }}
    </span>

    <!-- Info Column -->
    <info-column
      v-if="summary.infoColumnItems.length"
      :name="summary.title"
      subtitle="Calculated for chart range"
      :items="summary.infoColumnItems"
      class="pt-4"
      subtitle-bold
    />

    <!-- Refreshing -->
    <v-progress-linear
      v-if="isRefreshing"
      color="primary"
      location="bottom"
      indeterminate
      absolute
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { InfoColumnItem } from '@/components/InfoColumn'
import InfoColumn from '@/components/InfoColumn.vue'

export interface TelemetrySummary {
  title: string
  lastValue: string
  infoColumnItems: InfoColumnItem[]
}

export default defineComponent({
  name: 'TelemetrySummaryBox',
  props: {
    summary: {
      type: Object as PropType<TelemetrySummary>,
      required: true,
    },
    isRefreshing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { InfoColumn },
})
</script>
