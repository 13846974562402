// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file control/proto/policy.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Policy is an enum indexing all of the control policies that may be enabled on
 * any group.
 *
 * These policies should represent *states* of the control system, and not
 * *actions* (or "transitions" in state-machine parlance).
 *
 * NEXT TAG: 12
 *
 * @generated from enum ritta.partner.hce.control.proto.Policy
 */
export const Policy = /*@__PURE__*/ proto3.makeEnum(
  "ritta.partner.hce.control.proto.Policy",
  [
    {no: 0, name: "POLICY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 5, name: "POLICY_DEFAULT", localName: "DEFAULT"},
    {no: 10, name: "POLICY_MANUAL_CONTROL_EVENT", localName: "MANUAL_CONTROL_EVENT"},
    {no: 11, name: "POLICY_AUTOMATIC_CONTROL_EVENT", localName: "AUTOMATIC_CONTROL_EVENT"},
    {no: 1, name: "POLICY_CHARGEPOINT_DEFAULT", localName: "CHARGEPOINT_DEFAULT"},
    {no: 2, name: "POLICY_CHARGEPOINT_RESTRICTED", localName: "CHARGEPOINT_RESTRICTED"},
    {no: 3, name: "POLICY_TESLA_POWERWALL_DEFAULT", localName: "TESLA_POWERWALL_DEFAULT"},
    {no: 4, name: "POLICY_TESLA_POWERWALL_PEAK_EVENT", localName: "TESLA_POWERWALL_PEAK_EVENT"},
    {no: 6, name: "POLICY_PEAK_TIME_PAYBACK_EVENT", localName: "PEAK_TIME_PAYBACK_EVENT"},
    {no: 7, name: "POLICY_DISPATCH_SCHEDULE_EVENT", localName: "DISPATCH_SCHEDULE_EVENT"},
    {no: 8, name: "POLICY_INSTANT_DISPATCH_EVENT", localName: "INSTANT_DISPATCH_EVENT"},
    {no: 9, name: "POLICY_INSTANT_CURTAIL_EVENT", localName: "INSTANT_CURTAIL_EVENT"},
    {no: 12, name: "POLICY_SCADA_CONTROL_EVENT", localName: "SCADA_CONTROL_EVENT"},
  ],
);

/**
 * Params wraps parameters for configurable policies.  The messages and field
 * names in the oneof should match enum values in the Policy enum.
 *
 * NEXT TAG: 10
 *
 * @generated from message ritta.partner.hce.control.proto.Params
 */
export const Params = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params",
  () => [
    { no: 7, name: "manual_control_event", kind: "message", T: Params_ManualControlEvent, oneof: "params_oneof" },
    { no: 8, name: "automatic_control_event", kind: "message", T: Params_AutomaticControlEvent, oneof: "params_oneof" },
    { no: 1, name: "chargepoint_restricted", kind: "message", T: Params_ChargepointRestricted, oneof: "params_oneof" },
    { no: 2, name: "tesla_powerwall_peak_event", kind: "message", T: Params_TeslaPowerwallPeakEvent, oneof: "params_oneof" },
    { no: 3, name: "peak_time_payback_event", kind: "message", T: Params_PeakTimePaybackEvent, oneof: "params_oneof" },
    { no: 4, name: "dispatch_schedule_event", kind: "message", T: Params_DispatchScheduleEvent, oneof: "params_oneof" },
    { no: 5, name: "instant_dispatch_event", kind: "message", T: Params_InstantDispatchEvent, oneof: "params_oneof" },
    { no: 6, name: "instant_curtail_event", kind: "message", T: Params_InstantCurtailEvent, oneof: "params_oneof" },
    { no: 9, name: "scada_control_event", kind: "message", T: Params_ScadaControlEvent, oneof: "params_oneof" },
  ],
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Params.ChargepointRestricted
 */
export const Params_ChargepointRestricted = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.ChargepointRestricted",
  () => [
    { no: 1, name: "end_time", kind: "message", T: Timestamp },
    { no: 2, name: "port_limit_percent", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Params_ChargepointRestricted"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Params.TeslaPowerwallPeakEvent
 */
export const Params_TeslaPowerwallPeakEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.TeslaPowerwallPeakEvent",
  () => [
    { no: 1, name: "discharge_start_time", kind: "message", T: Timestamp },
    { no: 2, name: "discharge_end_time", kind: "message", T: Timestamp },
  ],
  {localName: "Params_TeslaPowerwallPeakEvent"},
);

/**
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.Params.PeakTimePaybackEvent
 */
export const Params_PeakTimePaybackEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.PeakTimePaybackEvent",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "max_power_kw", kind: "scalar", T: 2 /* ScalarType.FLOAT */, opt: true },
  ],
  {localName: "Params_PeakTimePaybackEvent"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Params.DispatchScheduleEvent
 */
export const Params_DispatchScheduleEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.DispatchScheduleEvent",
  () => [
    { no: 1, name: "dispatch_schedule", kind: "message", T: Params_DispatchSchedulePoint, repeated: true },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
  ],
  {localName: "Params_DispatchScheduleEvent"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Params.DispatchSchedulePoint
 */
export const Params_DispatchSchedulePoint = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.DispatchSchedulePoint",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "watts", kind: "scalar", T: 17 /* ScalarType.SINT32 */ },
  ],
  {localName: "Params_DispatchSchedulePoint"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.Params.InstantDispatchEvent
 */
export const Params_InstantDispatchEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.InstantDispatchEvent",
  () => [
    { no: 1, name: "watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Params_InstantDispatchEvent"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.Params.InstantCurtailEvent
 */
export const Params_InstantCurtailEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.InstantCurtailEvent",
  () => [
    { no: 1, name: "watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Params_InstantCurtailEvent"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.Params.ManualControlEvent
 */
export const Params_ManualControlEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.ManualControlEvent",
  () => [
    { no: 1, name: "active_power_setpoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Params_ManualControlEvent"},
);

/**
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.Params.AutomaticControlEvent
 */
export const Params_AutomaticControlEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.AutomaticControlEvent",
  () => [
    { no: 1, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 2, name: "ftm_der_config", kind: "message", T: Params_AutomaticControlEvent_FtmDerConfig, opt: true },
    { no: 3, name: "constraints_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "Params_AutomaticControlEvent"},
);

/**
 * @generated from message ritta.partner.hce.control.proto.Params.AutomaticControlEvent.FtmDerConfig
 */
export const Params_AutomaticControlEvent_FtmDerConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.AutomaticControlEvent.FtmDerConfig",
  () => [
    { no: 1, name: "net_load_buffer_watts", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "bess_ramp_limits_watts_per_minute", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "bess_min_soc", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "bess_max_soc", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "bess_max_power_consumed_watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 6, name: "bess_max_power_produced_watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
  ],
  {localName: "Params_AutomaticControlEvent_FtmDerConfig"},
);

/**
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.Params.ScadaControlEvent
 */
export const Params_ScadaControlEvent = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Params.ScadaControlEvent",
  () => [
    { no: 1, name: "active_power_setpoint", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Params_ScadaControlEvent"},
);

