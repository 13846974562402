<template>
  <v-chip
    :color="bgColor"
    density="comfortable"
    variant="flat"
    aria-label="Communication status"
    :style="{ border: `1px solid ${fontColor}` }"
  >
    <span class="text-body-2" :style="{ color: fontColor }">{{ label }}</span>
  </v-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { APPLE_50, APPLE_800, JAM_50, JAM_600 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import {
  CommunicationStatusType,
  getCommunicationStatusLabel,
  type CommunicationStatusMap,
} from '@/model/control/communicationStatus'

export default defineComponent({
  name: 'CommunicationStatusBadge',
  props: {
    /**
     * When it's a Group.
     */
    communicationStatusMap: {
      type: Map as PropType<CommunicationStatusMap>,
      required: false,
    },
    /**
     * When it's a Single Device.
     */
    communicationStatus: {
      type: Number as PropType<CommunicationStatusType>,
      required: false,
    },
  },
  computed: {
    label(): string {
      if (this.communicationStatusMap) {
        const goodOnes = Array.from(
          this.communicationStatusMap.entries()
        ).reduce((acc, [_resourceId, type]) => {
          if (type === CommunicationStatusType.GOOD) acc += 1
          return acc
        }, 0)

        return `${goodOnes}/${this.communicationStatusMap.size}`
      }

      return this.communicationStatus
        ? getCommunicationStatusLabel(this.communicationStatus)
        : TEXT_NO_VALUE
    },
    bgColor(): string {
      if (this.communicationStatusMap) {
        return APPLE_50.hex
      }

      return this.communicationStatus === CommunicationStatusType.GOOD
        ? APPLE_50.hex
        : JAM_50.hex
    },
    fontColor(): string {
      if (this.communicationStatusMap) {
        return APPLE_800.hex
      }

      return this.communicationStatus === CommunicationStatusType.GOOD
        ? APPLE_800.hex
        : JAM_600.hex
    },
  },
})
</script>
