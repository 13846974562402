import { DateTime } from 'luxon'
import type {
  Chart,
  ChartData,
  ChartDataset,
  FontSpec,
  GridLineOptions,
  Point,
  ScriptableScaleContext,
} from 'chart.js'
import { sortedIndexBy as _sortedIndexBy } from 'lodash-es'
import { ChartDefinition, NumberOrNull } from '@/types/charts'
import SyncPlugin, { type DefaultOptions } from './syncPlugin'
import { PersistPlugin, type PersistDefaultOptions } from './persistPlugin'
// Export all the active element functions
export * from './tooltip'

/** Our charts do not render vertical grid lines */
// TODO(andrew): make default in register.ts
export const XAXIS_GRID: Partial<GridLineOptions> = { drawOnChartArea: false }

/* * Creates an ID to be used as the charts group id. */
export function createChartGroupId(charts: ChartDefinition[]) {
  return charts.reduce((acc, chart) => acc + '|' + chart.id, '')
}

/** Return a configuration object for the SyncPlugin */
export function syncPluginOptions(opts: DefaultOptions) {
  return { plugins: { [SyncPlugin.id]: opts } } as const
}

/** Return a configuration object for the PersistPlugin */
export function persistPluginOptions(opts: PersistDefaultOptions) {
  return { plugins: { [PersistPlugin.id]: opts } } as const
}

/**
 * Returns a image of the chart encoded as an URL (base 64, png image).
 * It also adds a white background to the generated image.
 *
 * Docs:
 * https://www.chartjs.org/docs/latest/developers/api.html#tobase64image-type-quality
 * https://quickchart.io/documentation/chart-js/image-export/
 */
export function getChartAsDataUrl(chart: Chart): string {
  const { ctx, width, height } = chart

  // Save so we can restore later.
  ctx.save()

  // Add white background.
  ctx.globalCompositeOperation = 'destination-over'
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, width, height)

  // Capture the image.
  const dataUrl = chart.toBase64Image()

  // Restore the chart back to how it was before.
  ctx.restore()
  chart.update()

  return dataUrl
}

/**
 * Extracts a portion of data points based on start/end timestamps (milliseconds).
 *
 * Important: the list of data points must be already sorted by the timestamps
 * since this function uses binary search to do the job.
 */
export function getPartialData<T extends { x: number; y?: NumberOrNull }>({
  start,
  end,
  data,
}: {
  start: number
  end: number
  data: T[]
}): T[] {
  const createDp = (mills: number): T => ({ x: mills, y: 0 } as T)
  const getTimestamp = (dp: T) => dp.x

  return data.slice(
    _sortedIndexBy<T>(data, createDp(start), getTimestamp),
    _sortedIndexBy<T>(data, createDp(end + 1), getTimestamp)
  )
}

/**
 * Returns only the portion of the data sets that are currently visible
 * in the chart's "X" scale.
 */
export function getVisibleData(chart: Chart): ChartData {
  const { min, max } = chart.scales.x

  return {
    ...chart.data,
    datasets: chart.data.datasets.map((ds) => ({
      ...ds,
      data: getPartialData({
        start: min,
        end: max,
        data: ds.data as Point[],
      }),
    })),
  }
}

export function timeScaleTickFormat(millis: number): string {
  const dt = DateTime.fromMillis(millis)
  const isStartOfDay = dt.equals(dt.startOf('day'))
  return isStartOfDay
    ? dt.toLocaleString({ day: '2-digit', month: 'short' })
    : dt.toFormat('HH:mm')
}

export function timeScaleTickFont(
  ctxt: ScriptableScaleContext
): Partial<FontSpec> | undefined {
  if (ctxt.tick == null) return

  return ctxt.tick.major ? { weight: 600 } : undefined
}

export function createLinearGradient(chart: Chart): null | CanvasGradient {
  const chartArea = chart.chartArea

  if (!chartArea) return null

  return (
    chart.canvas
      .getContext('2d')
      ?.createLinearGradient(0, 0, 0, chartArea.height) ?? null
  )
}

export function createDiagonalPattern(color = 'black'): null | CanvasPattern {
  // create a 10x10 px canvas for the pattern's base shape
  const shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  // get the context for drawing
  const c = shape.getContext('2d')

  if (c === null) return null

  // draw 1st line of the shape
  c.strokeStyle = color
  c.beginPath()
  c.moveTo(2, 0)
  c.lineTo(10, 8)
  c.stroke()
  // draw 2nd line of the shape
  c.beginPath()
  c.moveTo(0, 8)
  c.lineTo(2, 10)
  c.stroke()
  // create the pattern from the shape
  return c.createPattern(shape, 'repeat')
}

export function edgePointsConfig(): Partial<ChartDataset<'line'>> {
  return {
    pointRadius: ({ dataIndex, dataset }) => {
      const data = dataset.data as Point[]
      const previousDp: undefined | Point = data[dataIndex - 1]
      const nextDp: undefined | Point = data[dataIndex + 1]

      const isStartOfSegment = previousDp?.y == null
      const isEndOfSegment = nextDp?.y == null

      if (isStartOfSegment || isEndOfSegment) {
        return 4
      } else {
        return undefined
      }
    },
  }
}

/** This scale ID is used for the chart's additional Y-Axis */
export const Y2_AXIS = 'y2'
