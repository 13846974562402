// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/tsdb.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DoubleValue, proto3, Timestamp } from "@bufbuild/protobuf";
import { Resolution } from "./resolution_pb.js";
import { GetResourcesRequest } from "./query_pb.js";

/**
 * TimeSeries contains data for a chart in a format that is designed to work well with
 * common chart libraries. It also provides properties which help the client in collecting
 * and rendering the chart efficiently.
 *
 * @generated from message ritta.frontend.proto.TimeSeries
 */
export const TimeSeries = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TimeSeries",
  () => [
    { no: 1, name: "metric", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "message", T: TimeSeries_DataPoint, repeated: true },
    { no: 3, name: "minY", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "maxY", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "meanY", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "min", kind: "message", T: TimeSeries_DataPoint },
    { no: 7, name: "max", kind: "message", T: TimeSeries_DataPoint },
    { no: 9, name: "calculation", kind: "enum", T: proto3.getEnumType(TimeSeries_Calculation) },
  ],
);

/**
 * An calculation across the requested resource is specified per-metric
 *
 * @generated from enum ritta.frontend.proto.TimeSeries.Calculation
 */
export const TimeSeries_Calculation = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.TimeSeries.Calculation",
  [
    {no: 0, name: "NONE"},
    {no: 1, name: "SUM"},
    {no: 2, name: "AVG"},
    {no: 3, name: "MIN"},
    {no: 4, name: "MAX"},
  ],
);

/**
 * This message structure aligns with a common internal format supported by Chart.js and ApexCharts.
 * See https://apexcharts.com/docs/series/
 * See https://www.chartjs.org/docs/latest/general/data-structures.html
 *
 * @generated from message ritta.frontend.proto.TimeSeries.DataPoint
 */
export const TimeSeries_DataPoint = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TimeSeries.DataPoint",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "y", kind: "message", T: DoubleValue },
  ],
  {localName: "TimeSeries_DataPoint"},
);

/**
 * @generated from message ritta.frontend.proto.TimeSeriesRequest
 */
export const TimeSeriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TimeSeriesRequest",
  () => [
    { no: 1, name: "resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 4, name: "metrics", kind: "message", T: TimeSeriesRequest_Frame, repeated: true },
    { no: 5, name: "resolution", kind: "enum", T: proto3.getEnumType(Resolution) },
    { no: 6, name: "aggregation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.TimeSeriesRequest.Frame
 */
export const TimeSeriesRequest_Frame = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TimeSeriesRequest.Frame",
  () => [
    { no: 1, name: "metric", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "unit", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "calculation", kind: "enum", T: proto3.getEnumType(TimeSeries_Calculation) },
  ],
  {localName: "TimeSeriesRequest_Frame"},
);

/**
 * @generated from message ritta.frontend.proto.TimeSeriesResponse
 */
export const TimeSeriesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TimeSeriesResponse",
  () => [
    { no: 1, name: "resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "aggregation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "series", kind: "message", T: TimeSeries, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.MultiTimeSeriesRequest
 */
export const MultiTimeSeriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.MultiTimeSeriesRequest",
  () => [
    { no: 1, name: "resources", kind: "message", T: GetResourcesRequest },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 4, name: "metrics", kind: "message", T: TimeSeriesRequest_Frame, repeated: true },
    { no: 5, name: "resolution", kind: "enum", T: proto3.getEnumType(Resolution) },
    { no: 6, name: "aggregation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

