<template>
  <h1 class="ma-0 pb-0" :class="big ? 'text-h5 font-weight-medium' : 'text-h6'">
    <slot />
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CeTitle',
  props: {
    big: {
      type: Boolean,
      required: false,
    },
  },
})
</script>
