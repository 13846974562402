<template>
  <div class="position-relative" style="min-height: 7.5rem">
    <centered-spinner v-if="isLoading && !impact" :size="20" />
    <ce-data-table
      v-else
      :headers="headers"
      :table="dataTable"
      :is-loading="isLoading"
      :options="options"
      :show-pagination="false"
      ultra-dense
      border-bottom
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import {
  createResourceImpactDataTable,
  headers,
} from '@/model/grid/ResourceImpactDataTable'
import { createDefaultOptions } from '@/model/tables/helper'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import type { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'

export default defineComponent({
  name: 'GridImpactResourceDownlineTable',
  props: {
    impact: {
      type: Object as PropType<ResourceImpactResponse>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CenteredSpinner, CeDataTable },
  setup() {
    return { headers, options: createDefaultOptions('id') }
  },
  computed: {
    dataTable() {
      return createResourceImpactDataTable(this.impact)
    },
  },
})
</script>
