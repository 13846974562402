<template>
  <div :style="{ position: 'relative', height: btnSize }">
    <button
      class="c-LandmarksControl gmaps-box-shadow"
      :style="{ position: 'absolute', bottom: compMargin, right: compMargin }"
      draggable="false"
      aria-label="Toggle landmarks"
      type="button"
      @click="toggleVisibility"
    >
      <v-icon :color="iconColor">{{ iconFile }}</v-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const GMAPS_ICON_GREY = 'rgb(102,102,102)'

const ICON_MARKERS_ON = 'mdi-label'
const ICON_MARKERS_OFF = 'mdi-label-off-outline'

export default defineComponent({
  name: 'LandmarksControl',
  setup() {
    return { btnSize: '48px', compMargin: '12px' }
  },
  data() {
    return {
      landmarksVisible: false,
    }
  },
  computed: {
    iconFile(): string {
      return this.landmarksVisible ? ICON_MARKERS_ON : ICON_MARKERS_OFF
    },
    iconColor(): string {
      return this.landmarksVisible ? 'primary' : GMAPS_ICON_GREY
    },
  },
  methods: {
    toggleVisibility() {
      this.landmarksVisible = !this.landmarksVisible
      this.$emit('change', this.landmarksVisible)
    },
  },
})
</script>

<style lang="scss">
.c-LandmarksControl {
  background: none padding-box rgb(255, 255, 255);
  height: 48px;
  width: 48px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;

  &:hover {
    background: none padding-box rgb(235, 235, 235);
  }
}
</style>
