<template>
  <v-tooltip content-class="ce__tooltip" location="top" v-bind="$attrs">
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <img
        v-if="type === 'info'"
        :src="infoIcon"
        width="16"
        height="16"
        :style="activatorStyle"
        v-bind="props"
        aria-describedby="infoTooltip"
      />
      <slot name="activator" v-bind="props" />
    </template>

    <!-- Tooltip content -->
    <div v-if="text" class="text-caption text-black" style="width: 12.5rem">
      <span>
        {{ text }}
      </span>
    </div>

    <!-- Default Slot -->
    <slot />
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import infoIcon from '@/assets/imgs/albert_ic_info--col.svg'

/**
 * This component is just a wrapper around Vuetify's VTooltip component.
 * It accepts the same props VTooltip accept.
 * It is possible because we are binding all attributes that `CeTooltip.vue`
 * receives directly into VTooltip.
 *
 * The basic structure of `CeTooltip.vue` is the same from VTooltip.
 * A slot named "activator" is component that activates the tooltip with
 * event `mouseover`.
 * A slot named "default" is the component that is rendered within the tooltip.
 *
 * More about VTooltip here:
 * https://vuetifyjs.com/en/components/tooltips/
 *
 * TODO(rafael): Currently the best option is to set the `top` prop so the
 * custom design I'm applying to the tooltip container will work properly.
 * I should make the layout more dynamic based on the other possible props
 * `bottom`, `left`, `right`.
 */
export default defineComponent({
  name: 'CeTooltip',
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    activatorStyle: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      infoIcon,
    }
  },
})
</script>

<style lang="scss">
/**
  This CSS class should be global and not wrapped within `.c-CeTooltip` class.
  Since the tooltip component is going to be attached to the bottom of
  Vuetify's <v-app> component, we need to expose this CSS outside the scope
  of `CeTooltip.vue` wrapper.
*/
.ce__tooltip {
  background-color: white !important;
  opacity: 1 !important;
  box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}
</style>
