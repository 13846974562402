// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/hosting.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum ritta.frontend.proto.Timespan
 */
export const Timespan = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Timespan",
  [
    {no: 0, name: "TODAY"},
    {no: 1, name: "YESTERDAY"},
    {no: 2, name: "THIS_WEEK"},
    {no: 3, name: "LAST_WEEK"},
    {no: 4, name: "LAST_24_HOURS"},
    {no: 5, name: "SEASONAL"},
    {no: 6, name: "LAST_90_DAYS"},
    {no: 7, name: "LAST_30_DAYS"},
    {no: 8, name: "LAST_180_DAYS"},
    {no: 9, name: "LAST_365_DAYS"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.VoltageDeviationsRequest
 */
export const VoltageDeviationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.VoltageDeviationsRequest",
  () => [
    { no: 1, name: "timespan", kind: "enum", T: proto3.getEnumType(Timespan) },
    { no: 2, name: "voltage_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "time_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "criteria" },
    { no: 4, name: "meter_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, oneof: "criteria" },
  ],
);

/**
 *
 * Response contains pairs of zone id : prevalence, where prevalence represents either:
 * - Given time_percent, % of meters recording voltage deviations >= voltage_percent
 * - Given meter_percent, % of time when voltage deviations are recorded <= -voltage_percent
 *
 * Few notes:
 * 1. Zones are typically evaluated once per week => expect data lagged by at most 1 week
 * 2. Missing data is ignored while evaluating zones and not counted towards being under- or over-voltage
 * 3. In our resources v4 model, id represents a path-like unique identifier, e.g. `feeder/NW131`
 * 4. Response contains all zone ids in both maps, regardless of whether deviations are observed
 * or not; if zone z1 experiences "no" over-voltage deviations, prevalence_over["z1"] = 0.0.
 * 5. As such, there is no constraint on a zone to be part of at most one of prevalence_over or
 * prevalence_under: a zone can experience over-voltage deviations 22% of the time,
 * and under-voltage deviations 3% of the time.
 *
 * Example request:
 * Request % meters recording ≥ 5% deviations for ≥10% timestamps in the last 3 months:
 * timespan = Timespan.LAST_3_MONTHS
 * voltage_percent=5.0
 * time_percent=10.0
 * Response has three feeders with 12.2%, 14.5% and 92.2% of meters recording over-voltage:
 * prevalence_over = {"feeder/f1":12.2, "feeder/f2":14.5, "feeder/f3":92.2, "feeder/f4":0.0}
 *
 * Example request:
 * Request % meters recording ≥ 4% deviations for ≥10% timestamps in the last 3 months:
 * timespan = Timespan.LAST_3_MONTHS
 * voltage_percent=4.0
 * time_percent=10.0
 * Response has four feeders with 12.2%, 14.5%, 92.2% and 33.0% of meters recording over-voltage:
 * prevalence_over = {"feeder/f1":12.2, "feeder/f2":14.5, "feeder/f3":92.2, "feeder/f4":33.0}
 * Notice that this request is more "relaxed" => the response contains more zones
 * (feeder/f4) with higher percent meters experiencing deviations.
 *
 * Example request:
 * Request % timestamps when ≥ 5% deviations are recorded for ≥10% meters in the last 3 months:
 * timespan = Timespan.LAST_3_MONTHS
 * voltage_percent=5.0
 * meter_percent=10.0
 * Response has three feeders with 12.2%, 14.5% and 92.2% of timestamps when over-voltages are recorded:
 * prevalence_over = {"feeder/f1":12.2, "feeder/f2":14.5, "feeder/f3":92.2, "feeder/f4":0.0}
 *
 * @generated from message ritta.frontend.proto.VoltageDeviationsResponse
 */
export const VoltageDeviationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.VoltageDeviationsResponse",
  () => [
    { no: 1, name: "start_time", kind: "message", T: Timestamp },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "prevalence_over", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
    { no: 4, name: "prevalence_under", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 1 /* ScalarType.DOUBLE */} },
    { no: 5, name: "debug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

