import { MapLayerId, RittaConfig } from '@/config/types'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { MapManagerLayer, TILE_SIZE_2X, ZOOM_OVERVIEW } from '@/model/map'
import { Services } from '@/services'
import { Format } from '@/utils/format'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'

import iconSensor from '@/assets/imgs/albert_ic_sensor--black.svg'

const TYPE = ResourceType.SENSOR_ELECTRICAL

function mapManagerLayer(
  _config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id: MapLayerId.SENSOR_ELECTRIC,
    minZoom: ZOOM_OVERVIEW,
    maxZoom: ZOOM_OVERVIEW,
    tileSize: TILE_SIZE_2X,
    pickable: true,
    getTileData: async (props) => {
      return services.tileService.getTileResources(TYPE, props)
    },
    renderSubLayers(props) {
      return getTileResponseLayers(props, {})
    },
  })
}

export const SensorElectrical: ApplicationMapLayer = {
  id: MapLayerId.SENSOR_ELECTRIC,
  label: 'Sensor',
  icon: iconSensor,
  infoWindow: {
    infoColumn: ({ resource }) => ({
      title: 'Sensor',
      subtitle: resource?.manufacture?.serial || TEXT_NO_VALUE,
      items: [
        {
          label: 'Manufacturer',
          text: resource?.manufacture?.brand ?? TEXT_NO_VALUE,
        },
        {
          label: 'Voltage',
          text: Format.fmtVolts(resource?.ratings?.voltage),
        },
        {
          label: 'Rated Capacity',
          text: Format.fmtApparentPower(resource?.ratings?.power?.apparent),
        },
      ],
    }),
  },
  mapManagerLayer,
}
