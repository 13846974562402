import { Code, Interceptor } from '@connectrpc/connect'
import { isAsyncIterable } from '@/services/interceptors/cache'
import { isConnectError } from '@/services/interceptors/refreshToken'
import { sentryCaptureMessage } from '@/utils/sentryCaptureMessage'
import { Control as IControlService } from 'rfs/control/proto/control_service_connect'
import { ControlsData as IControlsData } from 'rfs/frontend/proto/controls_connect'

const METHODS_TO_INTERCEPT: string[] = [
  // Used by "Recurring schedule".
  IControlService.methods.createSchedule.name,
  // Used by "Temporary schedule".
  IControlsData.methods.planScheduleTimeSeries.name,
  IControlService.methods.submitWaypoints.name,
  // Used by All modes.
  IControlService.methods.setGroupPolicy.name,
  // Used by "Operating envelopes".
  IControlService.methods.updateDevices.name,
]

export const ControlErrorInterceptor: Interceptor = (next) => async (req) => {
  // Pass free.
  if (isAsyncIterable(req.message)) return next(req)

  const reqMethodName = req.method.name

  // Skip.
  if (
    req.service.typeName !== IControlService.typeName ||
    !METHODS_TO_INTERCEPT.includes(reqMethodName)
  ) {
    return next(req)
  }

  try {
    return await next(req)
  } catch (err) {
    if (isConnectError(err)) {
      sentryCaptureMessage(
        `ControlService.${reqMethodName}: code "${Code[err.code]}", ${
          err.rawMessage
        }`,
        'error'
      )
    }

    throw err
  }
}
