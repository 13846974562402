import { Settings } from 'luxon'
import { RittaConfig } from './types'

const RFS_HOSTED_CONFIG = '/ritta.main/config.json'
const RFS_TOKENZ_ENDPOINT = '/ritta.main/tokenz'

function configureRfsEndpoint(config: RittaConfig): RittaConfig {
  if (import.meta.env.VITE_CAMUS_RFS_ENDPOINT) {
    console.debug(
      `Overriding "rfsEndpoint" to ${import.meta.env.VITE_CAMUS_RFS_ENDPOINT}`
    )
    config.rfsEndpoint = import.meta.env.VITE_CAMUS_RFS_ENDPOINT
  } else if (import.meta.env.VITE_IS_SANDBOX_HOT_RELOAD === 'true') {
    // Use the sandbox RFS instance. Since prod RFA's nginx does the proxying,
    // the vite dev server will do the proxying in sandbox mode.
    config.rfsEndpoint = location.origin
  } else if (!config.rfsEndpoint) {
    // Default to dev RFS in debug builds
    if (import.meta.env.DEV) {
      const partner = config.customer.shortName.toLowerCase()
      config.rfsEndpoint = `https://dev.${partner}.grid.dev`
    } else {
      config.rfsEndpoint = location.origin
    }
  }

  return config
}

function configureAuthAndRefreshToken(config: RittaConfig): RittaConfig {
  if (config.auth.type === 'gcp-iap' && !config.auth.refreshTokenUrl) {
    config.auth.refreshTokenUrl = config.rfsEndpoint + RFS_TOKENZ_ENDPOINT
  }

  return config
}

export async function loadConfig(): Promise<Readonly<RittaConfig>> {
  // NOTE: When running in development, we get the config from the Vite dev server
  const response = await fetch(RFS_HOSTED_CONFIG)
  const config = RittaConfig.fromJson(await response.json())

  configureRfsEndpoint(config)
  configureAuthAndRefreshToken(config)
  // Change Luxon's default time zone so dates are formatted in the customer's time zone
  Settings.defaultZone = config.customer.timeZone
  // Have Luxon validate objects so that we can avoid testing for null's all the time
  Settings.throwOnInvalid = true

  return config
}

// Update's luxon types so that accessors like `Interval#start` won't return `DateTime | null`.
declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true
  }
}

// TODO(rafael): I think we can/should delete this.
export { RittaConfig, MonitorConfig } from './types'
