import { MapLayerId, RittaConfig } from '@/config/types'
import { rgbaBlack } from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { MapManagerLayer } from '@/model/map'
import {
  TILE_SIZE,
  ZOOM_CONDUCTORS_HIGH,
  ZOOM_CONDUCTORS_LOW,
  ZOOM_CONDUCTORS_MED,
} from '@/model/map/constants'
import { ControlPosition } from '@/model/map/gmaps'
import {
  createPhaseColorRgbObject,
  getPhase,
  isSecondary,
  PHASE_LINE_WEIGHT,
  SECONDARY_LINE_WEIGHT,
} from '@/model/resource/conductor/phase'
import { Services } from '@/services'
import { useGridMapStore } from '@/stores/gridMap'
import DistributionCustomControl from '../customControls/DistributionCustomControl.vue'
import { ApplicationMapLayer } from './catalog'
import { DetailLevelsLayer, getTileResponseLayers } from './extensions'

import iconConductor from '@/assets/imgs/albert_ic_distribution--black.svg'

const NO_DASH = [0, 0] as [number, number]
const DASH = [3, 2] as [number, number]

export function distribution(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Distribution',
    icon: iconConductor,
    customControl: () => ({
      component: DistributionCustomControl,
      position: ControlPosition.RIGHT_BOTTOM,
    }),
    mapManagerLayer,
  }
}

function mapManagerLayer(
  config: Readonly<RittaConfig>,
  { tileService }: Services
): MapManagerLayer {
  const grid = useGridMapStore()
  const colors = createPhaseColorRgbObject(config)

  return new DetailLevelsLayer({
    id: MapLayerId.DISTRIBUTION,
    resourceType: ResourceType.CONDUCTOR,
    hasCallouts: grid.availableResourceTypes.has(ResourceType.CALLOUT),
    tileService,
    low: {
      minZoom: ZOOM_CONDUCTORS_LOW - 1,
      maxZoom: ZOOM_CONDUCTORS_LOW - 1,
      tileSize: TILE_SIZE,
    },
    medium: {
      minZoom: ZOOM_CONDUCTORS_MED - 1,
      maxZoom: ZOOM_CONDUCTORS_MED - 1,
      tileSize: TILE_SIZE,
    },
    high: {
      minZoom: ZOOM_CONDUCTORS_HIGH - 1,
      maxZoom: ZOOM_CONDUCTORS_HIGH - 1,
      tileSize: TILE_SIZE,
    },
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        lines: {
          widthUnits: 'pixels',
          getWidth: ({ resource }) => {
            if (isSecondary(resource)) {
              return SECONDARY_LINE_WEIGHT
            }
            return PHASE_LINE_WEIGHT[getPhase(resource)]
          },
          getColor: ({ resource }) => {
            return colors[getPhase(resource)] ?? rgbaBlack
          },
          getDashArray: ({ resource }) => {
            return resource.type === ResourceType.CALLOUT ? DASH : NO_DASH
          },
        },
      })
    },
  })
}
