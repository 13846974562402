<template>
  <div>
    <!-- Form -->
    <form
      class="d-flex align-center"
      role="search"
      style="width: 25rem"
      @submit.prevent="search"
    >
      <!-- Input -->
      <v-text-field
        :model-value="modelValue"
        class="pr-4"
        color="secondary"
        hide-details
        clearable
        @update:model-value="handleNewInputValue"
        @click:clear="search"
      />

      <!-- Submit -->
      <v-btn
        :loading="isLoading"
        :disabled="isLoading"
        type="submit"
        color="primary"
        elevation="0"
      >
        Search
      </v-btn>
    </form>

    <!-- Search hint -->
    <span data-test="s-hint" v-if="searchHint" class="text-caption">
      {{ searchHint }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DataTableSearch',
  props: {
    /** * v-model */
    modelValue: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    /** Defines the minimum string length to enable search. */
    minLength: {
      type: Number,
      required: false,
      default: 1,
    },
    searchHint: {
      type: String,
      required: false,
    },
  },
  methods: {
    handleNewInputValue(value: string | null): void {
      // Vuetify sends `null` when clearing the input field.
      // Don't allow it.
      this.$emit('update:model-value', value ?? '') // v-model
    },
    search(): void {
      // Allow when empty "".
      if (this.modelValue.length && this.modelValue.length < this.minLength) {
        return
      }

      this.$emit('search')
    },
  },
})
</script>
