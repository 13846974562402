// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/notification.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { FilterBy, OrderBy } from "./pagination_pb.js";

/**
 * @generated from message ritta.frontend.proto.Notification
 */
export const Notification = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.Notification",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "condition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "response", kind: "enum", T: proto3.getEnumType(Notification_Response) },
    { no: 4, name: "severity", kind: "enum", T: proto3.getEnumType(Notification_Severity) },
    { no: 5, name: "state", kind: "enum", T: proto3.getEnumType(Notification_State) },
    { no: 6, name: "created_at", kind: "message", T: Timestamp },
    { no: 7, name: "start_time", kind: "message", T: Timestamp },
    { no: 8, name: "end_time", kind: "message", T: Timestamp },
    { no: 9, name: "read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "downline_ders", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from enum ritta.frontend.proto.Notification.Response
 */
export const Notification_Response = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Notification.Response",
  [
    {no: 0, name: "RESPONSE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "RESPONSE_CONSTRAINT", localName: "CONSTRAINT"},
    {no: 2, name: "RESPONSE_ALERT", localName: "ALERT"},
  ],
);

/**
 * @generated from enum ritta.frontend.proto.Notification.Severity
 */
export const Notification_Severity = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Notification.Severity",
  [
    {no: 0, name: "SEVERITY_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SEVERITY_LOW", localName: "LOW"},
    {no: 2, name: "SEVERITY_MEDIUM", localName: "MEDIUM"},
    {no: 3, name: "SEVERITY_HIGH", localName: "HIGH"},
  ],
);

/**
 * @generated from enum ritta.frontend.proto.Notification.State
 */
export const Notification_State = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Notification.State",
  [
    {no: 0, name: "STATE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "STATE_FORECASTED", localName: "FORECASTED"},
    {no: 2, name: "STATE_ACTIVE", localName: "ACTIVE"},
    {no: 3, name: "STATE_INACTIVE", localName: "INACTIVE"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.ListNotificationsRequest
 */
export const ListNotificationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListNotificationsRequest",
  () => [
    { no: 1, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "order_by", kind: "message", T: OrderBy },
    { no: 5, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ListNotificationsResponse
 */
export const ListNotificationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListNotificationsResponse",
  () => [
    { no: 1, name: "notifications", kind: "message", T: Notification, repeated: true },
    { no: 2, name: "total_notifications", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GetNotificationRequest
 */
export const GetNotificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetNotificationRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.MarkAsReadRequest
 */
export const MarkAsReadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.MarkAsReadRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "read", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.MarkAsReadResponse
 */
export const MarkAsReadResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.MarkAsReadResponse",
  () => [
    { no: 1, name: "notificationIds", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

