import { createPromiseClient, PromiseClient } from '@connectrpc/connect'
import { createGrpcWebTransport } from '@connectrpc/connect-web'

import { AlertService } from 'rfs/frontend/proto/alert_connect'
import { NotificationService } from './notification.service'
import { GridAnalysis } from 'rfs/frontend/proto/analysis_connect'
import { ChartsService } from '@/services/charts.service'
import { GridService } from '@/services/grid.service'
import { Control as IControlService } from 'rfs/control/proto/control_service_connect'
import { Proxy as IProxyService } from 'rfs/device/proto/proxy_connect'
import { ForecastService } from './forecast.service'
import { GCSService } from './gcs.service'
import { getInterceptors } from './interceptors'
import { FileStorage } from 'rfs/frontend/proto/gcs_connect'
import { Hosting } from 'rfs/frontend/proto/hosting_connect'
import { TransformerDetails } from 'rfs/frontend/proto/transformer_details_connect'
import { CapacityService } from './capacity.service'
import { ControlsDataService } from './controlsData.service'
import { FleetService } from './fleet.service'
import { QueryService } from './query.service'
import { TileService } from './tile.service'
import { RittaConfig } from '@/config'

export type Services = {
  alertService: PromiseClient<typeof AlertService>
  notificationService: NotificationService
  analysisService: PromiseClient<typeof GridAnalysis>
  capacityService: CapacityService
  chartsService: ChartsService
  control: PromiseClient<typeof IControlService>
  controlsData: ControlsDataService
  proxy: PromiseClient<typeof IProxyService>
  fileStorage: PromiseClient<typeof FileStorage>
  fleetService: FleetService
  forecastService: ForecastService
  gcsService: GCSService
  gridService: GridService
  queryService: QueryService
  tileService: TileService
  transformerDetailsService: PromiseClient<typeof TransformerDetails>
  hostingService: PromiseClient<typeof Hosting>
}

export function newServices(
  isDevEnv: boolean,
  config: Readonly<RittaConfig>
): Services {
  // We use the grpc-web binary protocol for all services
  const transport = createGrpcWebTransport({
    // Requests will be made to <baseUrl>/<package>.<service>/method
    baseUrl: config.rfsEndpoint!,
    // Controls what the fetch client will do with credentials, such as Cookies.
    // The default value is "same-origin", which will not transmit Cookies in cross-origin requests.
    credentials: 'include',
    // Interceptors apply to all calls running through this transport.
    interceptors: getInterceptors(isDevEnv, config),
  })

  return {
    alertService: createPromiseClient(AlertService, transport),
    notificationService: new NotificationService(transport),
    analysisService: createPromiseClient(GridAnalysis, transport),
    capacityService: new CapacityService(transport),
    chartsService: new ChartsService(transport),
    control: createPromiseClient(IControlService, transport),
    controlsData: new ControlsDataService(transport),
    proxy: createPromiseClient(IProxyService, transport),
    fileStorage: createPromiseClient(FileStorage, transport),
    fleetService: new FleetService(transport),
    forecastService: new ForecastService(transport),
    gcsService: new GCSService(transport),
    gridService: new GridService(transport),
    queryService: new QueryService(transport),
    tileService: new TileService(transport),
    transformerDetailsService: createPromiseClient(
      TransformerDetails,
      transport
    ),
    hostingService: createPromiseClient(Hosting, transport),
  }
}
