<template>
  <div class="c-InfoBox pa-4 bg-grey-lighten-4 align-self-start">
    <!-- Header Metric -->
    <div class="text-subtitle-2 text-no-wrap">{{ metricType }}</div>
    <div class="text-h4 pb-5">{{ metricValue }}</div>

    <!-- Table Metrics -->
    <table width="100%" class="text-body-2">
      <thead class="font-weight-bold text-caption">
        <tr>
          <th></th>
          <th class="text-right">{{ ytdMax }}</th>
          <th class="text-right">{{ ytdMean }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in metricsData" :key="item.resource">
          <td class="text-left text-no-wrap">{{ item.resource }}</td>
          <td class="cell text-right">{{ item.maxY }}</td>
          <td class="cell text-right">{{ item.meanY }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { StatsData } from './AnalyzeChart'

export default defineComponent({
  name: 'InfoBox',
  props: {
    metricType: {
      type: String,
      required: true,
    },
    metricValue: {
      type: String,
      required: true,
    },
    metricsData: {
      type: Array as PropType<StatsData[]>,
      required: true,
    },
  },
  setup() {
    const ytdMax = 'YTD max'
    const ytdMean = 'YTD mean'

    return {
      ytdMax,
      ytdMean,
    }
  },
})
</script>
<style lang="scss">
.c-InfoBox {
  table {
    width: 270px;
    border-collapse: collapse;
    thead {
      color: #6c6c6c;
    }
    tr {
      line-height: 24px;
      border-bottom: 1px solid #dddddd;
      .cell {
        width: 108px;
      }
    }
  }
}
</style>
