import { Interval } from 'luxon'

import { TimeSeries } from '@/services/charts.service'
import { NamedTimeSeries, filterTimeSeriesWithin } from './TimeSeriesDataSource'

/** Starts with local because it is computeds */
export const METRIC_LOAD_DURATION = 'local.load.duration'

export interface PercentageValue {
  x: number
  y: number
}

export function computeDurationSeries(
  series: NamedTimeSeries,
  visibleInterval: Interval
): NamedTimeSeries {
  const [visibleSeries] = filterTimeSeriesWithin(visibleInterval, [series])

  return {
    ...series,
    metric: METRIC_LOAD_DURATION,
    data: computeDurationCurve(visibleSeries.data, 100),
  }
}

export function computeDurationCurve(
  apparentPowerSeries: TimeSeries['data'],
  maxLength = 100
): PercentageValue[] {
  const values = apparentPowerSeries.map((ts) => ts.y ?? 0)
  // Sort descending
  values.sort((a, b) => b - a)
  // percentAbove[i] represents the % of values >= values[i]
  // e.g. for values = [2.0, 1.8, 1.6, 1.4, 1.2], percentAbove = [20%, 40%, 60%, 80%, 100%]
  const len = values.length
  const percentAbove = values.map((_, i) => (i + 1) / len)

  if (len <= maxLength) {
    // Very few data points, consider all of them
    return values.map((v, i) => ({ x: percentAbove[i], y: v }))
  } else {
    const percentValues: PercentageValue[] = [
      { x: percentAbove[0], y: values[0] },
    ]
    let ctr = 1
    percentAbove.forEach((percent, i) => {
      const threshold = (1 / maxLength) * ctr
      if (percent >= threshold) {
        const v =
          (values[i] * (threshold - percentAbove[i - 1]) +
            values[i - 1] * (percent - threshold)) /
          (percent - percentAbove[i - 1])
        percentValues.push({ x: threshold, y: v })
        ctr += 1
      }
    })
    return percentValues
  }
}
