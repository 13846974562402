import { _TileLoadProps } from '@deck.gl/geo-layers'
import { MapLayerId, RittaConfig } from '@/config/types'
import { Services } from '@/services'
import { ResourceType } from '@/constants/resourceType'
import { ICON_SIZE } from '@/model/map'
import { MapManagerLayer } from '@/model/map/types'
import { SolarFarmHelper } from '@/model/resource/solar/farm/helper'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'
import iconUtilitySolarApp from '@/assets/imgs/albert_ic_solar_utility--black.svg'

const ID = MapLayerId.UTILITY_SOLAR

const ICON_OFFSET = [0, ICON_SIZE / 2] as [number, number]

function mapManagerLayer(
  _config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id: ID,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 12, // Larger tile size as there are fewer utility solar in a grid
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.SOLAR_FARM, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: {
          /* Move the icon aside so it doesn't cover the meter */
          getPixelOffset: ICON_OFFSET,
        },
      })
    },
  })
}

export const UtilitySolar: ApplicationMapLayer = {
  id: ID,
  label: 'Utility Solar',
  icon: iconUtilitySolarApp,
  infoWindow: {
    infoColumn: (f) => SolarFarmHelper.infoWindowInfoColumn(f.resource!),
  },
  mapManagerLayer,
}
