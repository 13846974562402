import { Layer, Color } from '@deck.gl/core'
import { Resource } from 'rfs/pb/resource_pb'
import { ResourceType } from '@/constants/resourceType'
import { IconLayer, PathLayer, ScatterplotLayer } from '@deck.gl/layers'
import {
  scalingIconProps,
  toPosition,
} from '@/components/maps/layers/extensions'
import {
  resourceIconsDull,
  resourceIconsGT,
  resourceIconsLX,
} from './FeederOperationsIcons'
import { GRAY_COOL_300 } from '@/constants/colors'
import {
  isSecondary,
  SECONDARY_LINE_WEIGHT,
  PHASE_LINE_WEIGHT,
  getPhase,
} from '@/model/resource/conductor/phase'
import {
  ICON_MAX_PIXELS,
  COMMON_SIZE_DIVISOR,
  COMMON_SIZE_DIVISOR_14,
  COMMON_SIZE_DIVISOR_13,
  ICON_OFFSET_LEFT,
  ICON_OFFSET_NONE,
  ICON_OFFSET_TOP,
  ICON_SIZE,
} from '@/model/map'
import { POINT_RADIUS_DEFAULT } from '../maps/layers/MeterEndpoint'
import { hexToRgbA } from '@/utils/colors'
import { LOADER_OPTIONS } from '../maps/layers/iconAtlas'
import {
  colorScale,
  getLoadingValue,
  getVoltageValue,
  MetricTimeResourceData,
} from './OperationsMapUtils'
import { ToggleMetric } from './FeederOperations.vue'

const GREY_FILL_COLOR = GRAY_COOL_300.rgba as Color

type OperationsMapCatalogMap = Map<
  ResourceType,
  (resourcesData: ResourcesTimeSeries) => Layer
>

// ResourcesTimeSeries is a wrapper for the resources and time series data
// this way we can update the resource's map layer with the time series data
// at a specific timestamp and metric

// Ideally the ResourcesTimeSeries {resources, timeSeries}
// should be used once to create the layer and then
// and CurrentIndex {timeStamp, metric} should be used to update the layer
// e.g., the slider will inc/dec the CurrentIndex and update the layer
// with the new time stamp and metric data across all resources
export type ResourcesTimeSeries = {
  metric: ToggleMetric
  timeStampMillis: number
  resources: Resource[]
  tsValues: MetricTimeResourceData
}

// Used for conductors that are line segments
function getColorRange(value: number, min: number, max: number): Color {
  if (!value || value === 0) {
    return GREY_FILL_COLOR
  }
  // TODO(Isaac): Remove this when current (amps)
  // per unit value is returned from RFS
  if (value > max) {
    value = max
  }
  const range = max - min
  const normalizedValue = (value - min) / range || 0

  const color = colorScale(normalizedValue)
  return hexToRgbA(color) || GREY_FILL_COLOR
}

const newIconLayer = (
  resourcesData: ResourcesTimeSeries,
  resourceType: ResourceType,
  iconOffset: [number, number] = [0, 0],
  commonSizeDivisor = COMMON_SIZE_DIVISOR_14
) => {
  const { resources, timeStampMillis, tsValues, metric } = resourcesData
  return new IconLayer({
    id: `${resourceType}-operations-layer`,
    data: resources ?? [],
    pickable: true,
    getIcon: (r: Resource) => {
      let icon = Object.fromEntries(resourceIconsDull)[resourceType]
      const value =
        metric === ToggleMetric.VOLTAGE
          ? getVoltageValue(tsValues, timeStampMillis, r.id)
          : 0
      const lowerLimit = 0.95
      const upperLimit = 1.05
      if (value && value < lowerLimit) {
        icon = Object.fromEntries(resourceIconsLX)[resourceType]
      } else if (value && value > upperLimit) {
        icon = Object.fromEntries(resourceIconsGT)[resourceType]
      }
      return {
        url: icon,
        width: ICON_SIZE,
        height: ICON_SIZE,
      }
    },
    loadOptions: LOADER_OPTIONS,
    getPosition: (r: Resource) => {
      const location = r.location?.point
      return location ? toPosition(location) : [0, 0]
    },
    ...scalingIconProps(iconOffset, commonSizeDivisor),
  })
}

const ConductorLayer = (resourcesData: ResourcesTimeSeries) => {
  const { resources, metric, timeStampMillis, tsValues } = resourcesData
  return new PathLayer({
    id: `${ResourceType.CONDUCTOR}-operations-layer`,
    data: resources ?? [],
    pickable: true,
    widthUnit: 'pixels',
    widthMaxPixels: 6,
    positionFormat: 'XY',
    getPath: (r: Resource) => r.location?.lineString.flatMap(toPosition) ?? [],
    getWidth: (r: Resource) => {
      if (isSecondary(r)) {
        return SECONDARY_LINE_WEIGHT * 10
      }
      return PHASE_LINE_WEIGHT[getPhase(r)] * 10
    },
    getColor: (r: Resource) => {
      // TODO(Isaac): Use the min and max values of all time-series data
      // for the individual conductor
      const min = 0
      const max = 1.05
      const value =
        metric === ToggleMetric.LOADING
          ? getLoadingValue(tsValues, timeStampMillis, r.id)
          : 0
      return getColorRange(value, min, max)
    },
  })
}

const PointLayer = (resourcesData: ResourcesTimeSeries) =>
  new ScatterplotLayer({
    id: `${ResourceType.METER_ELECTRICAL}-operations-layer`,
    data: resourcesData.resources ?? [],
    radiusUnits: 'common',
    radiusMaxPixels: ICON_MAX_PIXELS / 2,
    getRadius: (POINT_RADIUS_DEFAULT * 0.8) / COMMON_SIZE_DIVISOR,
    getFillColor: () => GREY_FILL_COLOR,
    getPosition: (r: Resource) => {
      const location = r.location?.point
      return location ? toPosition(location) : [0, 0]
    },
  })

const SubstationLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.SUBSTATION)

const SolarDistributedLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.SOLAR_DISTRIBUTED, ICON_OFFSET_TOP)

const TransformerLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(
    resourcesData,
    ResourceType.TRANSFORMER,
    ICON_OFFSET_NONE,
    COMMON_SIZE_DIVISOR
  )

const ChargerLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.CHARGER)

const BatteryDistributedLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(
    resourcesData,
    ResourceType.BATTERY_DISTRIBUTED,
    ICON_OFFSET_LEFT,
    COMMON_SIZE_DIVISOR_13
  )

const MeterEndpointLayer = (resourcesData: ResourcesTimeSeries) =>
  PointLayer(resourcesData)

const RecloserLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.RECLOSER, ICON_OFFSET_LEFT)

const SwitchLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.SWITCH)

const RegulatorLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.REGULATOR)

const FuseLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.FUSE)

const CapacitorLayer = (resourcesData: ResourcesTimeSeries) =>
  newIconLayer(resourcesData, ResourceType.CAPACITOR)

export const mapCatalogMap: OperationsMapCatalogMap = new Map()
  .set(ResourceType.CONDUCTOR, ConductorLayer)
  .set(ResourceType.TRANSFORMER, TransformerLayer)
  .set(ResourceType.RECLOSER, RecloserLayer)
  .set(ResourceType.SWITCH, SwitchLayer)
  .set(ResourceType.REGULATOR, RegulatorLayer)
  .set(ResourceType.FUSE, FuseLayer)
  .set(ResourceType.CAPACITOR, CapacitorLayer)
  .set(ResourceType.SUBSTATION, SubstationLayer)
  .set(ResourceType.METER_ELECTRICAL, MeterEndpointLayer)
  .set(ResourceType.CHARGER, ChargerLayer)
  .set(ResourceType.BATTERY_DISTRIBUTED, BatteryDistributedLayer)
  .set(ResourceType.SOLAR_DISTRIBUTED, SolarDistributedLayer)
