import { Interval } from 'luxon'
import { RittaConfig } from '@/config'
import { Services } from '@/services'
import {
  HistoricalContextChartDataProvider,
  Options,
} from './HistoricalContextChartDataProvider'
import { ForecastPeakLoadAnalyticsData } from './ForecastPeakLoadAnalyticsData'

export * from './ForecastQualityData'
export * from './HistoricalContextChartDataProvider'

export function newHistoricalContextChartDataProvider(
  services: Services,
  options: Options
): HistoricalContextChartDataProvider {
  return new ForecastPeakLoadAnalyticsData(services, options)
}

export function addForecastInterval(
  interval: Interval,
  config: Readonly<RittaConfig>
): Interval {
  const forecasts = config.monitor?.site?.forecasts
  if (forecasts == null || !forecasts.length) {
    return interval
  }
  if (!forecasts?.enabledAMI && !forecasts?.enabledPV) {
    return interval
  }
  // When forecasts are enabled, we need to extend the interval to include the forecast data
  return Interval.fromDateTimes(
    interval.start,
    interval.end.plus(forecasts.length.toMillis())
  )
}
