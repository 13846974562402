<template>
  <section class="d-flex flex-column" :class="{ 'h-100': !disabled }">
    <!-- Top -->
    <div v-if="$slots.top" style="flex-shrink: 0">
      <slot name="top"></slot>
    </div>

    <!-- Content -->
    <div v-if="!disabled" class="position-relative" style="flex: 1">
      <div class="position-absolute top-0 left-0 w-100 h-100">
        <slot></slot>
      </div>
    </div>

    <!-- Disabled -->
    <slot v-else></slot>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FullHeightLayout',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
</script>
