// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/config.proto (package ritta.frontend.config, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Duration, proto3 } from "@bufbuild/protobuf";
import { LatLng } from "../../pb/resource_pb.js";

/**
 * @generated from message ritta.frontend.config.RittaConfig
 */
export const RittaConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.RittaConfig",
  () => [
    { no: 1, name: "customer", kind: "message", T: Customer },
    { no: 2, name: "auth", kind: "message", T: Auth },
    { no: 3, name: "map", kind: "message", T: Map },
    { no: 4, name: "monitor", kind: "message", T: MonitorConfig },
    { no: 5, name: "analysis", kind: "message", T: AnalyzeConfig },
    { no: 6, name: "control", kind: "message", T: ControlConfig },
    { no: 13, name: "gridImpact", kind: "message", T: GridImpact },
    { no: 15, name: "resources", kind: "message", T: ResourceConfig },
    { no: 7, name: "dictionary", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "featureFlags", kind: "message", T: FeatureFlags },
    { no: 9, name: "initialRoute", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "rfsEndpoint", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "servingCollection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.config.Customer
 */
export const Customer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Customer",
  () => [
    { no: 1, name: "shortName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timeZone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "analyticsId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "userGuide", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "conductors", kind: "message", T: Customer_Conductors },
  ],
);

/**
 * @generated from message ritta.frontend.config.Customer.Conductors
 */
export const Customer_Conductors = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Customer.Conductors",
  () => [
    { no: 1, name: "two_phase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "three_phase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phase_a", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phase_b", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "phase_c", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Customer_Conductors"},
);

/**
 * @generated from message ritta.frontend.config.Auth
 */
export const Auth = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Auth",
  () => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refreshTokenUrl", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message ritta.frontend.config.Map
 */
export const Map = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Map",
  () => [
    { no: 1, name: "apiKey", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "mapId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "startingCoordinates", kind: "message", T: LatLng },
    { no: 4, name: "boundingArea", kind: "message", T: LatLng, repeated: true },
    { no: 5, name: "layerGroups", kind: "message", T: MapLayerGroup, repeated: true },
    { no: 6, name: "layerConfigs", kind: "message", T: Map_LayerConfigs },
  ],
);

/**
 * Specify map layers configs  
 *
 * @generated from message ritta.frontend.config.Map.LayerConfigs
 */
export const Map_LayerConfigs = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Map.LayerConfigs",
  () => [
    { no: 1, name: "transformer", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
  {localName: "Map_LayerConfigs"},
);

/**
 * Configures a group of map layers.
 *
 * NOTE: The group should exist within the catalog of map layer groups.
 * There the group recieves its customization (label, icon, etc).
 *
 * @generated from message ritta.frontend.config.MapLayerGroup
 */
export const MapLayerGroup = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MapLayerGroup",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "activeOnStart", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "layers", kind: "message", T: MapLayer, repeated: true },
  ],
);

/**
 * Configures a map layer.
 *
 * NOTE: The map layer should exist within the catalog of map layers.
 * There the map layer recieves its customization (label, icon, etc).
 *
 * @generated from message ritta.frontend.config.MapLayer
 */
export const MapLayer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MapLayer",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "activeOnStart", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "hideSwitch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * A message to store state for enabling a feature
 *
 * @generated from message ritta.frontend.config.Enabled
 */
export const Enabled = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.Enabled",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig
 */
export const MonitorConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig",
  () => [
    { no: 1, name: "site", kind: "message", T: MonitorConfig_Site },
    { no: 2, name: "ders", kind: "message", T: Enabled },
    { no: 3, name: "fleet", kind: "message", T: Enabled },
    { no: 4, name: "meters", kind: "message", T: Enabled },
    { no: 5, name: "substation", kind: "message", T: MonitorConfig_Substation },
    { no: 6, name: "transformer", kind: "message", T: Enabled },
    { no: 7, name: "telemetry", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "operations", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "notifications", kind: "message", T: Enabled },
  ],
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Site
 */
export const MonitorConfig_Site = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Site",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "onlyShowDetails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "hideCustomerInfo", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "voltageChart", kind: "message", T: Enabled },
    { no: 4, name: "storageChart", kind: "message", T: Enabled },
    { no: 5, name: "forecasts", kind: "message", T: MonitorConfig_Site_Forecasts },
  ],
  {localName: "MonitorConfig_Site"},
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Site.Forecasts
 */
export const MonitorConfig_Site_Forecasts = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Site.Forecasts",
  () => [
    { no: 1, name: "enabledPV", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "enabledAMI", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "length", kind: "message", T: Duration },
  ],
  {localName: "MonitorConfig_Site_Forecasts"},
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Substation
 */
export const MonitorConfig_Substation = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Substation",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "dataTable", kind: "message", T: MonitorConfig_Substation_DataTable },
  ],
  {localName: "MonitorConfig_Substation"},
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Substation.CustomColumn
 */
export const MonitorConfig_Substation_CustomColumn = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Substation.CustomColumn",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "width", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "filterOptions", kind: "message", T: MonitorConfig_Substation_CustomColumn_FilterOption, repeated: true },
  ],
  {localName: "MonitorConfig_Substation_CustomColumn"},
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Substation.CustomColumn.FilterOption
 */
export const MonitorConfig_Substation_CustomColumn_FilterOption = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Substation.CustomColumn.FilterOption",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "MonitorConfig_Substation_CustomColumn_FilterOption"},
);

/**
 * @generated from message ritta.frontend.config.MonitorConfig.Substation.DataTable
 */
export const MonitorConfig_Substation_DataTable = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.MonitorConfig.Substation.DataTable",
  () => [
    { no: 1, name: "noSubstationCharts", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "disableCompareFeeders", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "customColumn", kind: "message", T: MonitorConfig_Substation_CustomColumn },
  ],
  {localName: "MonitorConfig_Substation_DataTable"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig
 */
export const AnalyzeConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig",
  () => [
    { no: 2, name: "analyzeDashboard", kind: "message", T: AnalyzeConfig_AnalyzeDashboard },
    { no: 3, name: "forecastDashboard", kind: "message", T: AnalyzeConfig_ForecastDashboard },
    { no: 4, name: "forecastQuality", kind: "message", T: AnalyzeConfig_ForecastQuality },
    { no: 5, name: "supplyAndDemand", kind: "message", T: Enabled },
    { no: 6, name: "alerts", kind: "message", T: Enabled },
    { no: 7, name: "initialRoute", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "insights", kind: "message", T: AnalyzeConfig_Insights },
  ],
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.AnalyzeDashboard
 */
export const AnalyzeConfig_AnalyzeDashboard = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.AnalyzeDashboard",
  () => [
    { no: 1, name: "provider", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AnalyzeConfig_AnalyzeDashboard"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.ForecastDashboard
 */
export const AnalyzeConfig_ForecastDashboard = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.ForecastDashboard",
  () => [
    { no: 1, name: "netLoadChart", kind: "message", T: AnalyzeConfig_ForecastDashboard_NetLoadChart },
    { no: 2, name: "historicalContextChart", kind: "message", T: AnalyzeConfig_ForecastDashboard_HistoricalContextChart },
  ],
  {localName: "AnalyzeConfig_ForecastDashboard"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.ForecastDashboard.NetLoadChart
 */
export const AnalyzeConfig_ForecastDashboard_NetLoadChart = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.ForecastDashboard.NetLoadChart",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "forecastProduct", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "forecastVersion", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AnalyzeConfig_ForecastDashboard_NetLoadChart"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.ForecastDashboard.HistoricalContextChart
 */
export const AnalyzeConfig_ForecastDashboard_HistoricalContextChart = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.ForecastDashboard.HistoricalContextChart",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "numberOfYears", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "forecastDays", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "peakDescription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "products12CP", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 7, name: "version12CP", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "products1CP", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
    { no: 8, name: "version1CP", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AnalyzeConfig_ForecastDashboard_HistoricalContextChart"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.ForecastQuality
 */
export const AnalyzeConfig_ForecastQuality = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.ForecastQuality",
  () => [
    { no: 1, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "AnalyzeConfig_ForecastQuality"},
);

/**
 * @generated from message ritta.frontend.config.AnalyzeConfig.Insights
 */
export const AnalyzeConfig_Insights = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.AnalyzeConfig.Insights",
  () => [
    { no: 1, name: "ev", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "pv", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "loading", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "AnalyzeConfig_Insights"},
);

/**
 * @generated from message ritta.frontend.config.ControlConfig
 */
export const ControlConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ControlConfig",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "resourceOverviewChart", kind: "message", T: Enabled },
    { no: 3, name: "optoutConfig", kind: "message", T: ControlOptoutConfig },
    { no: 4, name: "includeHidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "operatingEnvelopes", kind: "message", T: Enabled },
    { no: 6, name: "device", kind: "message", T: ControlConfig_DeviceConfig },
    { no: 7, name: "communicationStatus", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.frontend.config.ControlConfig.DeviceConfig
 */
export const ControlConfig_DeviceConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ControlConfig.DeviceConfig",
  () => [
    { no: 1, name: "telemetry", kind: "message", T: ControlConfig_DeviceConfig_TelemetryConfig },
  ],
  {localName: "ControlConfig_DeviceConfig"},
);

/**
 * @generated from message ritta.frontend.config.ControlConfig.DeviceConfig.TelemetryConfig
 */
export const ControlConfig_DeviceConfig_TelemetryConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ControlConfig.DeviceConfig.TelemetryConfig",
  () => [
    { no: 1, name: "reactivePower", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ControlConfig_DeviceConfig_TelemetryConfig"},
);

/**
 * @generated from message ritta.frontend.config.GridImpact
 */
export const GridImpact = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.GridImpact",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "resourceTypes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "disableComponentDetails", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "showAsBeta", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message ritta.frontend.config.ResourceConfig
 */
export const ResourceConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ResourceConfig",
  () => [
    { no: 1, name: "transformer", kind: "message", T: ResourceConfig_Transformer },
  ],
);

/**
 * @generated from message ritta.frontend.config.ResourceConfig.Transformer
 */
export const ResourceConfig_Transformer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ResourceConfig.Transformer",
  () => [
    { no: 1, name: "equipmentLosses", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "secondaryLineLosses", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "ResourceConfig_Transformer"},
);

/**
 * @generated from message ritta.frontend.config.FeatureFlags
 */
export const FeatureFlags = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.FeatureFlags",
  () => [
    { no: 2, name: "voltageMapping", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "v2gBusEasterEgg", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "transmissionLayerGCS", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "feederOperationsDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.config.ControlOptoutConfig
 */
export const ControlOptoutConfig = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.config.ControlOptoutConfig",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "templateId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "hostname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "supportEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "emailFromName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "emailFromAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "partnerName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "emailTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

