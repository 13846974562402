import { MapLayerId, RittaConfig } from '@/config/types'
import { Services } from '@/services'
import { MapManagerLayer } from '@/model/map/types'
import { HydroHelper } from '@/model/resource/hydro/helper'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'
import iconHydroApp from '@/assets/imgs/albert_ic_hydro--black.svg'
import { ResourceType } from '@/constants/resourceType'

const ID = MapLayerId.HYDRO

function mapManagerLayer(
  _config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id: ID,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 12, // Larger tile size as there are fewer hydro resources in a grid
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.HYDRO, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {})
    },
  })
}

export const Hydro: ApplicationMapLayer = {
  id: ID,
  label: 'Hydro',
  icon: iconHydroApp,
  infoWindow: {
    infoColumn: (f) => HydroHelper.infoWindowInfoColumn(f.resource!),
  },
  mapManagerLayer,
}
