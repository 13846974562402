import { Interceptor } from '@connectrpc/connect'

/** The metadata key containing the URL of the page that made the request. */
const RFA_PAGE = 'x-camus-rfa-page'

/**
 * gRPC metadata is considered any custom headers on the request
 * @see https://grpc.io/docs/guides/concepts/#metadata
 */
export const MetadataInterceptor: Interceptor = (next) => async (req) => {
  req.header.set(RFA_PAGE, window.location.href)
  // Also a sentry marker or session ID?
  return next(req)
}
