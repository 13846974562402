<template>
  <div
    class="panel-transition"
    style="
      width: 100%;
      height: 100%;
      margin-left: 0;
      transition-property: width, margin-left;
    "
    :style="computedStyle"
  >
    <slot />
  </div>
</template>

<script lang="ts">
/**
 * CurrentMapExpand Component.
 * It will contract the map when the LeftPanel component is open.
 * It will expand the map when the LeftPanel component is closed.
 */

import { defineComponent, StyleValue } from 'vue'
import { mapState } from 'pinia'
import { useNavigationControlsStore } from '@/stores/navigationControls'

export default defineComponent({
  name: 'CurrentMapExpand',
  computed: {
    ...mapState(useNavigationControlsStore, [
      'drawerLeftWidth',
      'drawerLeftOpened',
      'isStreetViewVisible',
    ]),
    computedStyle(): StyleValue {
      const isMapSmaller = !this.isStreetViewVisible && this.drawerLeftOpened

      if (isMapSmaller) {
        return {
          width: `calc(100% - ${this.drawerLeftWidth}px)`,
          marginLeft: `${this.drawerLeftWidth}px`,
        }
      } else {
        return {}
      }
    },
  },
})
</script>
