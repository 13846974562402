import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import VoltageMappingLeftPanel from '@/components/others/VoltageMappingLeftPanel/VoltageMappingLeftPanel.vue'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export const TITLE_VOLTAGE_MAPPING = 'Voltage Deviation'

export function voltageMappingRoutes(
  config: Readonly<RittaConfig>
): RouteRecordRaw[] {
  // Check if Feature Flag `voltageMapping` is enabled
  if (config.featureFlags?.voltageMapping) {
    return [
      {
        path: '/voltagemapping',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.VOLTAGE_MAPPING,
            meta: {
              tab: TAB,
              resetMapCenterAndZoom: true,
              pageTitle: TITLE_VOLTAGE_MAPPING,
            },
            components: {
              leftPanel: VoltageMappingLeftPanel,
            },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
