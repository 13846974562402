<template>
  <v-menu offset="16">
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <div class="d-flex justify-end">
        <!-- Refreshing -->
        <div class="mr-2" style="width: 1.5rem; height: 1.5rem">
          <v-progress-circular
            v-if="isRefreshing"
            :size="20"
            color="primary"
            indeterminate
          />
        </div>

        <!-- Btn -->
        <v-btn
          elevation="0"
          :color="hasUnread ? 'red darken-1' : 'grey lighten-2'"
          :loading="isLoading"
          rounded
          variant="outlined"
          size="small"
          v-bind="props"
        >
          <!-- Icon -->
          <v-icon :color="hasUnread ? 'red darken-1' : 'black'" size="x-large">
            {{ hasUnread ? 'mdi-alert-circle' : 'mdi-alert-circle-outline' }}
          </v-icon>

          <!-- Label -->
          <span
            v-if="btnLabel"
            class="px-2 text-body-2 text-capitalize"
            :class="
              hasUnread
                ? 'red--text text--darken-1'
                : 'grey--text text--darken-2'
            "
          >
            {{ btnLabel }}
          </span>
        </v-btn>
      </div>
    </template>

    <!-- Content -->
    <div
      class="d-flex flex-column bg-white pa-4 overflow-y-auto elevation-5 rounded"
      style="width: 25.5rem; max-height: 42rem"
    >
      <!-- Loading -->
      <centered-spinner v-if="isLoading" :size="20" />

      <!--  -->
      <template v-else>
        <!-- Has Failed -->
        <alert-error v-if="hasLoadingFailed">{{ MSG_HAS_FAILED }}</alert-error>

        <!-- No Alerts -->
        <v-alert
          v-else-if="!alerts.length"
          color="grey"
          class="mb-0"
          dense
          dark
        >
          {{ msgNoActiveAlerts }}
        </v-alert>

        <!-- Alerts -->
        <template v-else>
          <!-- Title -->
          <span class="d-block text-subtitle-1 font-weight-bold pb-4">
            {{ title }}
          </span>

          <!-- Alerts -->
          <div style="flex: 1">
            <alerts-menu-item
              v-for="(a, idx) of alerts"
              :key="a.id"
              :alert="a"
              :class="{ 'mb-2': idx !== alerts.length - 1 }"
            />
          </div>
        </template>

        <!-- Link -->
        <router-link
          class="d-block text-right font-weight-medium pt-4"
          :to="link"
        >
          {{ linkText }}
        </router-link>
      </template>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'
import { MSG_HAS_FAILED } from '@/constants/alerts'
import * as RouteNames from '@/router/routeNames'
import { useAlertStore } from '@/stores/alert'
import AlertError from '@/components/common/AlertError.vue'
import AlertsMenuItem from '@/components/alerts/AlertsMenuItem.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import {
  Alert,
  State,
  AlertListRequest_Field as Field,
  AlertListRequest_FilterBy as FilterBy,
  AlertListRequest_OrderBy as OrderBy,
} from 'rfs/frontend/proto/alert_pb'
import { Value } from '@bufbuild/protobuf'

export default defineComponent({
  name: 'AlertsMenu',
  components: { AlertError, CenteredSpinner, AlertsMenuItem },
  setup() {
    const link: VueRouterLocation = { name: RouteNames.ALERTS }
    return {
      alertStore: useAlertStore(),
      MSG_HAS_FAILED,
      link,
      msgNoActiveAlerts: 'No active alerts',
      linkText: 'See all alerts',
      title: 'Active alerts',
    }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isRefreshing: false,
      hasLoadingFailed: false,
      alerts: [] as Alert[],
    })
  },
  computed: {
    btnLabel(): string {
      return this.alerts.length.toString()
    },
    hasUnread(): boolean {
      return this.alerts.some((a) => a.state === State.ACTIVE)
    },
  },
  created(): void {
    this.fetchData()
    this.alertStore.interval.subscribe(this.reload)
  },
  beforeUnmount(): void {
    this.alertStore.interval.unsubscribe(this.reload)
  },
  methods: {
    reload(): void {
      this.fetchData({ refresh: true })
    },
    async fetchData(
      opts: { refresh: boolean } = { refresh: false }
    ): Promise<void> {
      if (opts.refresh) {
        this.isRefreshing = true
      } else {
        this.isLoading = true
      }
      this.hasLoadingFailed = false

      try {
        const { alerts } = await this.$services.alertService.listAlerts({
          limit: 999,
          filterBy: [
            new FilterBy({
              field: Field.STATE,
              criteria: {
                case: 'equals',
                value: new Value({
                  kind: { case: 'numberValue', value: State.ACTIVE },
                }),
              },
            }),
          ],
          orderBy: [new OrderBy({ field: Field.START, descending: true })],
        })
        this.alerts = alerts
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('AlertsMenu.fetchData: %o', err)
      } finally {
        this.isLoading = false
        this.isRefreshing = false
      }
    },
  },
})
</script>
