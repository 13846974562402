import { Timespan } from 'rfs/frontend/proto/hosting_pb'

export enum PresenceBy {
  METERS = 'meters',
  TIME = 'time',
}

export const PeriodAssessed = [
  Timespan.LAST_90_DAYS,
  Timespan.LAST_180_DAYS,
  Timespan.LAST_365_DAYS,
]
