import {
  defineStore,
  DefineStoreOptions,
  _GettersTree,
  StateTree,
  StoreDefinition,
} from 'pinia'

type DeepReadonly<T> = {
  // Do not make functions readonly - it makes them un-callable
  readonly [P in keyof T]: T[P] extends Function ? T[P] : DeepReadonly<T[P]>
}

// prettier-ignore

/**
 * A replacement for `defineStore` which makes all state properties readonly
 * to prevent mutations outside of actions.
 */
export function defineImmutableStore<Id extends string, S extends StateTree = {}, G extends _GettersTree<S> = {}, A = {}>(
  id: Id,
  options: Omit<DefineStoreOptions<Id, S, G, A>, 'id'>
): StoreDefinition<Id, DeepReadonly<S>, G, A> {
  return defineStore(id, options)
}

declare module 'pinia' {
  // eslint-disable-next-line
  interface DefineStoreOptions<Id extends string, S extends StateTree, G, A> {
    /**
     * Give stores a chance to upgrade any persisted state.
     * The `currentState` includes all the state loaded from storage.
     */
    upgradeState?(currentState: S, initialState: S): void
  }
}
