import { DateTime } from 'luxon'
import type { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'
import type { PlainMessage } from '@bufbuild/protobuf'
import type { Services } from '@/services'
import { MetricCalculation, type TimeSeries } from '@/services/charts.service'
import { BUTTERCUP } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Metric } from '@/constants/metrics'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { Format } from '@/utils/format'
import type { InfoColumnItem } from '@/model/types'
import type { Header, Row, Options, DataTable } from '@/model/tables/DataTable'
import { ColumnType } from '@/model/tables/column'
import {
  type ITimeSeriesDataSource,
  type NamedTimeSeries,
} from '@/model/charts'
import {
  findMaxMinMeanY,
  ONE_TILE_SIZE,
  TimeSeriesDataSource,
  type TimeSeriesMap,
} from '@/model/charts/TimeSeriesDataSource'
import type { CommunicationStatusType } from '@/model/control/communicationStatus'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import {
  ChartType,
  type ChartDefinition,
  type NumberOrNull,
} from '@/types/charts'
import { TimeSeries_DataPoint as TimeSeriesDataPoint } from 'rfs/frontend/proto/tsdb_pb'
import type { Group } from 'rfs/control/proto/model_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { GroupHelper } from './group/helper'

export type CapacityStats = {
  commandedPowerAvg?: number
  observedPowerAvg?: number
  complianceAvg?: number
}

export const capacityChartDefinition: ChartDefinition = {
  id: 'device-capacity-chart',
  title: 'Active power',
  type: ChartType.Power,
}

export function newGroupCapacityDataSource(
  services: Services,
  group: Group
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource(
    async (request) =>
      services.controlsData.fetchGroupTimeSeries(group.id, {
        ...request,
        resolution: Resolution.FIFTEEN_MINUTE,
      }),
    DateTime.now(),
    ONE_TILE_SIZE
  )

  // Capacity: observed.
  ds.addChartSeries(capacityChartDefinition, {
    metric: Metric.COND_POWER_REAL,
    calculation: MetricCalculation.SUM,
    unit: GroupHelper.hasNegativePower(group) ? '-W' : 'W',
    config: { seriesName: 'Observed power', seriesColor: BUTTERCUP.hex },
  })

  return ds
}

// Get the most recent observed power data point
export function lastObservedPower(tsMap: TimeSeriesMap): string {
  const lastDP = tsMap
    .get(capacityChartDefinition.id)
    ?.find((s) => s.metric === Metric.COND_POWER_REAL)
    ?.data.at(-1)

  return lastDP?.y ? Format.fmtWatts(lastDP.y) : TEXT_NO_VALUE
}

export function summaryItems(stats?: CapacityStats): InfoColumnItem[] {
  const { commandedPowerAvg, observedPowerAvg, complianceAvg } = stats || {}
  return [
    {
      label: 'Observed power (while dispatched)',
      text: Format.fmtWatts(observedPowerAvg) || TEXT_NO_VALUE,
    },
    {
      label: 'Commanded power',
      text: Format.fmtWatts(commandedPowerAvg) || TEXT_NO_VALUE,
    },
    {
      label: 'Compliance',
      text: Format.fmtPercent(complianceAvg),
    },
  ]
}

export function getSummaryStats(tsMap: TimeSeriesMap): CapacityStats {
  const series = tsMap.get(capacityChartDefinition.id) || []
  // TODO(Isaac): Implement INTENTIONS_POWER_LIMITS_CONSUMING and the average compliance
  // calculation. Used by `ResourceType.CHARGER`
  const [observedPower, commandedPower] = getPowerData(series)

  if (!commandedPower.length) {
    return {}
  }

  const commandedPowerAvg = findMaxMinMeanY(commandedPower).meanY

  if (!observedPower.length) {
    return {
      commandedPowerAvg,
    }
  }

  const { observedPowerAvg } = getPowerStats(observedPower, commandedPower)

  // Compliance is calculated as the average of the entire observed power time series
  // divided by the average of the entire commanded power time series
  const complianceAvg = commandedPowerAvg
    ? (observedPowerAvg || 0) / commandedPowerAvg
    : 0

  return {
    commandedPowerAvg,
    observedPowerAvg,
    complianceAvg,
  }
}

export function getPowerData(
  series: readonly NamedTimeSeries[] | TimeSeries[],
  resourceId?: string
): [PlainMessage<TimeSeriesDataPoint>[], PlainMessage<TimeSeriesDataPoint>[]] {
  let observedPower: PlainMessage<TimeSeriesDataPoint>[] = []
  let commandedPower: PlainMessage<TimeSeriesDataPoint>[] = []

  for (const ts of series) {
    // If a device is specified, only get the data for that device.
    // Otherwise, the TimeSeries[] only has one resource.
    // TODO(Isaac): Implement INTENTIONS_POWER_LIMITS_CONSUMING and the average compliance
    // when there is data for `ResourceType.CHARGER`.
    if (!resourceId || ts.resource === resourceId) {
      if (ts.metric === Metric.COND_POWER_REAL) {
        observedPower = ts.data
      }
      if (ts.metric === Metric.INTENTIONS_POWER_REAL) {
        commandedPower = ts.data?.filter((dp) => (dp.y || 0) < 0)
      }
    }
  }

  return [observedPower, commandedPower]
}

function getObservedPowerWhileDispatchedAndCompliance(
  commandedData: PlainMessage<TimeSeriesDataPoint>[],
  observedData: PlainMessage<TimeSeriesDataPoint>[]
): [PlainMessage<TimeSeriesDataPoint>[], PlainMessage<TimeSeriesDataPoint>[]] {
  const observedPowerWhileDispatchedTS: PlainMessage<TimeSeriesDataPoint>[] = []
  const complianceTS: PlainMessage<TimeSeriesDataPoint>[] = []

  for (const commandedDP of commandedData) {
    // If commanded power is `0`, then there was no commanded power dispatched.
    if (!commandedDP.y) continue
    // Find the observed power data point when there was commanded power dispatched.
    const observedDP = observedData.find((dp) => dp.x === commandedDP.x)
    if (observedDP && observedDP.y) {
      observedPowerWhileDispatchedTS.push(observedDP)
      // Compliance (%) = observed power / commanded power
      const complianceRatio = observedDP.y / commandedDP.y
      complianceTS.push(
        new TimeSeriesDataPoint({ x: commandedDP.x, y: complianceRatio })
      )
    }
  }

  return [observedPowerWhileDispatchedTS, complianceTS]
}

// Compute the power stats:
// - observed power average while dispatched (kW)
// - commanded power average (kW)
// - compliance average (%) per time stamp
export function getPowerStats(
  observedPower: PlainMessage<TimeSeriesDataPoint>[],
  commandedPower: PlainMessage<TimeSeriesDataPoint>[]
): Partial<CapacityStats> {
  const [observedPowerWhileDispatchedTS, complianceTS] =
    getObservedPowerWhileDispatchedAndCompliance(commandedPower, observedPower)

  if (!observedPowerWhileDispatchedTS.length || !complianceTS.length) {
    return {}
  }

  const observedPowerAvg = findMaxMinMeanY(observedPowerWhileDispatchedTS).meanY
  const complianceAvg = findMaxMinMeanY(complianceTS).meanY

  return {
    observedPowerAvg,
    complianceAvg,
  }
}

export enum Columns {
  DEVICE_ID = 'DEVICE_ID',
  STATUS = 'STATUS',
  COMMUNICATIONS = 'COMMUNICATIONS',
  OBSERVED_POWER = 'OBSERVED_POWER',
  COMMANDED_POWER = 'COMMANDED_POWER',
  AVERAGE_COMPLIANCE = 'AVERAGE_COMPLIANCE',
}

// NOTE(rafael): values in milliseconds so we don't need to sort the rows
// ourselves. `CeDataTable.vue` sorts for us.
export interface CapacityRow extends Row {
  route: VueRouterLocation
  [Columns.DEVICE_ID]: string
  [Columns.STATUS]: OperatingEnvelopeStatus
  [Columns.COMMUNICATIONS]: CommunicationStatusType
  [Columns.OBSERVED_POWER]: NumberOrNull
  [Columns.COMMANDED_POWER]: NumberOrNull
  [Columns.AVERAGE_COMPLIANCE]: NumberOrNull
}

export type CapacityDataTable = DataTable<CapacityRow>

export type CustomOptions = Options<Columns>

export function createInitialOptions(): CustomOptions {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column: Columns.DEVICE_ID, descending: false },
  }
}

const headerDeviceID: Header<CapacityRow> = {
  title: 'Device ID',
  key: Columns.DEVICE_ID,
  sortable: true,
  routeFactory: (_config, r) => r.route,
}

const headerObservedPower: Header = {
  title: 'Observed power while dispatched (kW)',
  key: Columns.OBSERVED_POWER,
  align: 'end',
  sortable: true,
  valueType: ColumnType.KILO_W,
}

const headerCommandedPower: Header = {
  title: 'Commanded power (kW)',
  key: Columns.COMMANDED_POWER,
  align: 'end',
  sortable: true,
  valueType: ColumnType.KILO_W,
}

const headerAverageCompliance: Header = {
  title: 'Compliance (%)',
  key: Columns.AVERAGE_COMPLIANCE,
  align: 'end',
  sortable: true,
  valueType: ColumnType.PERCENT,
}

export function createHeaders(
  isOeEnabled: boolean,
  isCommunicationStatusEnabled: boolean
): Header[] {
  const headers: Header[] = [headerDeviceID]

  const headerStatus: Header = {
    // TODO(rafael): remove this conditional when Operating Envelopes is released.
    title: isOeEnabled ? 'Envelope Status' : 'Status',
    key: Columns.STATUS,
    sortable: false,
  }

  headers.push(headerStatus)

  if (isCommunicationStatusEnabled) {
    headers.push({
      title: 'Communications',
      key: Columns.COMMUNICATIONS,
      sortable: false,
    })
  }

  headers.push(
    headerObservedPower,
    headerCommandedPower,
    headerAverageCompliance
  )

  return headers
}
