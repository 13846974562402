<template>
  <div>
    <v-tooltip :disabled="!tooltip" location="bottom">
      <!-- V-Tooltip activator -->
      <template v-slot:activator="{ props }">
        <!-- Wrapper: Btn + Adjancent Block -->
        <div v-bind="props" class="d-flex gmaps-box-shadow">
          <!-- Main Btn -->
          <button
            @click="$emit('update:modelValue')"
            type="button"
            class="control-btn rounded rounded-be-0 rounded-te-0"
          >
            <img
              :src="icon"
              :alt="tooltip"
              :style="{ filter: modelValue ? 'url(#tint-primary)' : 'none' }"
              width="24px"
            />
          </button>

          <!-- Adjacent Block -->
          <v-menu :close-on-content-click="false" :offset="10">
            <!-- V-Menu activator -->
            <template v-slot:activator="{ props }">
              <div
                v-bind="props"
                data-test="adjacent-block"
                class="bg-white cursor-pointer d-flex align-center justify-center"
                style="
                  margin-left: 2px;
                  flex: 1;
                  width: 1.5rem;
                  border-top-right-radius: 0.25rem;
                  border-bottom-right-radius: 0.25rem;
                "
              >
                <!-- Small triangule made with svg -->
                <svg
                  fill="#000000"
                  viewBox="0 0 24 24"
                  width="18px"
                  height="18px"
                  style="transform: rotate(90deg)"
                  :style="{
                    filter: modelValue ? 'url(#tint-primary)' : 'none',
                  }"
                >
                  <path d="M8 5v14l11-7z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </div>
            </template>

            <!-- Dropdown: content will be injected here -->
            <div @click.stop>
              <slot name="dropdown" />
            </div>
          </v-menu>
        </div>
      </template>
      <!-- V-Tooltip text -->
      <span v-if="tooltip">{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
/*
 * Component for a group of controls.
 *
 * Slots:
 * - "dropdown" slot should be used for the dropdown content.
 *
 * Props:
 * - "value": works with the @input event (v-model directive).
 * - "icon": The icon url/path.
 * - "tooltip": the text for the tooltip.
 */
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
  },
})
</script>
