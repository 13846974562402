<template>
  <div>
    <!-- Title -->
    <ce-title class="pb-4">Groups</ce-title>

    <!-- Content -->
    <v-select
      :items="options"
      v-model="selectedOption"
      class="pb-4"
      style="max-width: 15rem"
    />

    <!-- Table -->
    <ce-data-table :headers :table :show-pagination="false">
      <!-- Policy Badge -->
      <template v-slot:[`item.policy`]="{ item: group }">
        <policy-badge
          :current-policy="group.currentPolicy"
          :current-policy-params="group.currentPolicyParams"
        />
      </template>

      <!-- Operating Envelope Badge -->
      <template v-slot:[`item.operatingEnvelope`]="{ item: group }">
        <operating-envelope-badge
          v-if="shouldShowOEBadge(group)"
          :status="getGroupCurrentEnvelopeStatus(group.id, devices)"
        />
        <template v-else>{{ TEXT_NO_VALUE }}</template>
      </template>
    </ce-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import type { DataTable } from '@/model/tables/DataTable'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import {
  OperatingEnvelopeStatus,
  getGroupCurrentEnvelopeStatus,
  isOEEnabled,
} from '@/model/control/operatingEnvelope'
import { DeviceHelper } from '@/model/control/device/helper'
import { createHeaders } from '@/model/control/group/GroupOverviewDataTable'
import CeTitle from '@/components/CeTitle.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import PolicyBadge from '@/components/control/PolicyBadge.vue'
import OperatingEnvelopeBadge from '@/components/control/OperatingEnvelopeBadge.vue'
import type { Device, Group } from 'rfs/control/proto/model_pb'

export const SHOW_ALL = 'Show all'

export default defineComponent({
  name: 'ControlOverviewGroups',
  props: {
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
    mapGroupResourceIds: {
      type: Map as PropType<Map<Group, string[]>>,
      required: true,
    },
  },
  components: {
    CeTitle,
    CeDataTable,
    PolicyBadge,
    OperatingEnvelopeBadge,
  },
  setup() {
    return {
      TEXT_NO_VALUE,
      OperatingEnvelopeStatus,
      getGroupCurrentEnvelopeStatus,
    }
  },
  data() {
    return shallowReactive({
      headers: createHeaders(
        isOEEnabled(this.$rittaConfig),
        this.mapGroupResourceIds
      ),
      selectedOption: SHOW_ALL,
    })
  },
  computed: {
    options(): string[] {
      return [
        SHOW_ALL,
        ...Array.from(
          new Set(
            this.groups.map((g) =>
              DeviceHelper.typeToCategoryName(g.deviceType)
            )
          )
        ).sort((a, b) => a.localeCompare(b)),
      ]
    },
    table(): DataTable<Group> {
      if (this.selectedOption === SHOW_ALL) {
        return { rows: this.groups }
      }

      return {
        rows: this.groups.filter(
          (g) =>
            DeviceHelper.typeToCategoryName(g.deviceType) ===
            this.selectedOption
        ),
      }
    },
  },
  methods: {
    shouldShowOEBadge(group: Group): boolean {
      return isOEEnabled(this.$rittaConfig) && group.supportsOes
    },
  },
})
</script>
