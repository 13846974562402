import { MapLayerId } from '@/config/types'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { getDisplayName } from '@/model/resource'
import { MapLayerFeature, MapManagerLayer } from '@/model/map/types'
import { InfoColumnAllProps } from '@/model/types'
import { GridLevel } from 'rfs/pb/resource_pb'
import { Services } from '@/services'
import { Format } from '@/utils/format'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'

import iconAppSubstation from '@/assets/imgs/albert_ic_substation--black.svg'

function getSubstationIcon(substation: MapLayerFeature) {
  const isTDsub = substation.resource?.grid?.level === GridLevel.TRANSMISSION
  return isTDsub ? ResourceType.SUBSTATION + '-TD' : ResourceType.SUBSTATION
}

function substationMapManagerLayer(
  id: MapLayerId,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 12, // Larger tile size as there are fewer substations in a grid
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.SUBSTATION, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: {
          getIcon: getSubstationIcon,
        },
      })
    },
  })
}

export function substation(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Substation',
    icon: iconAppSubstation,
    infoWindow: {
      infoColumn: (f) => getInfoColumnProps(f),
    },
    mapManagerLayer: (_config, services) =>
      substationMapManagerLayer(id, services),
  }
}

function getInfoColumnProps(f: MapLayerFeature): InfoColumnAllProps {
  return {
    title: getDisplayName(f.resource!) || TEXT_NO_VALUE,
    items: [
      {
        label: 'Serving meters',
        text: Format.fmtCount(f.properties?.downlineMeters) || TEXT_NO_VALUE,
      },
      {
        label: 'Solar capacity (AC)',
        text: Format.fmtWatts(f.properties?.solarCapacity) || TEXT_NO_VALUE,
      },
    ],
  }
}
