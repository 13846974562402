import { Chart as ChartJS, ChartOptions, FontSpec } from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'

import { BLACK } from '@/constants/colors'

interface ThresholdConfig {
  readonly yValue: number
  readonly label: string
}

const DOTS = [1, 1]

const FONT = { ...ChartJS.defaults.font, size: 10, weight: null } as FontSpec

/**
 * A threshold annotation is a line annotation that runs horizontally at a specific Y value.
 */
export function createThresholdAnnotation(
  config: ThresholdConfig
): ChartOptions {
  const threshold: AnnotationOptions = {
    type: 'line',
    scaleID: 'y',
    value: config.yValue,
    borderColor: BLACK.hex,
    borderWidth: 1,
    borderDash: DOTS,
    label: {
      content: config.label,
      display: true,
      position: 'end',
      yAdjust: 10,
      backgroundColor: 'transparent',
      color: BLACK.hex,
      font: FONT,
    },
  }
  return { plugins: { annotation: { annotations: { threshold } } } }
}
