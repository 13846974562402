import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'

import Home from '@/views/Home.vue'
import { createTransformerRoute } from '@/utils/router/create'
import { useGridImpactsStore } from '@/stores/gridImpacts'
import { ResourceType } from '@/constants/resourceType'

export const TAB = TabId.MONITOR

export const TITLE_TRANSFORMERS = 'Transformers'

export function transformerRoutes(
  config: Readonly<RittaConfig>
): RouteRecordRaw[] {
  if (config.monitor?.transformer?.enabled) {
    const meta = { tab: TAB, drawerLeftWide: true }

    return [
      {
        path: '/transformers',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.TRANSFORMERS,
            meta: {
              ...meta,
              resetMapCenterAndZoom: true,
              pageTitle: TITLE_TRANSFORMERS,
            },
            redirect: () => {
              const store = useGridImpactsStore()
              store.updateComponentType(ResourceType.TRANSFORMER)
              return { name: RouteNames.GRID_IMPACT }
            },
          },
          {
            path: ':transformerId',
            name: RouteNames.TRANSFORMER_DETAILS,
            meta: {
              ...meta,
              pageTitle: (params) => `Transformer: ${params.transformerId}`,
            },
            redirect: (to) => {
              const transformerID = to.params.transformerId
              const unqualifiedId =
                !transformerID || Array.isArray(transformerID)
                  ? undefined
                  : transformerID

              return unqualifiedId
                ? createTransformerRoute(unqualifiedId)
                : { name: RouteNames.TRANSFORMERS }
            },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
