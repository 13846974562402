import type { DateTime } from 'luxon'
import { PACIFIC } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import type { Services } from '@/services'
import { limitInterval } from '@/utils/time'
import {
  AggregateDataSource,
  TimeSeriesDataSource,
  type DataProvider,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import { createCutOffDate } from '@/model/control'
import {
  activePowerChartDefinition,
  reactivePowerChartDefinition,
  createActivePowerTimeSeriesConfig,
  createReactivePowerTimeSeriesConfig,
  socPercentageChartDefinition,
  socTimeSeriesConfig,
  transformerLoadingChartDefinition,
} from '@/model/control/telemetry'
import { transformerLoadingTimeSeriesConfigs } from '@/model/transformer'
import { createOperatingEnvelpeDataSource } from '@/model/control/operatingEnvelope'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import type { Group } from 'rfs/control/proto/model_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'

// NOTE(rafael): The resolution for device telemetry is raw.
export const DATA_POINT_AGE_LIMIT_MINUTES = 65

export function newDeviceTelemetryDataSource(
  services: Services,
  group: Group,
  resource: Resource,
  showSoc: boolean,
  showReactivePower: boolean,
  showOperatingEnvelope: boolean,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    request.interval = limitInterval(request.interval, getNow())
    return services.chartsService.fetchTimeSeries(resource.id, request)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow())
  )

  // Active Power.
  ds.addChartSeries(
    activePowerChartDefinition,
    createActivePowerTimeSeriesConfig(group)
  )

  // Reactive Power.
  if (showReactivePower) {
    ds.addChartSeries(
      reactivePowerChartDefinition,
      createReactivePowerTimeSeriesConfig()
    )
  }

  // SoC.
  if (showSoc) {
    ds.addChartSeries(socPercentageChartDefinition, socTimeSeriesConfig)
  }

  // Operating Envelope.
  if (showOperatingEnvelope) {
    return new AggregateDataSource(
      ds,
      createOperatingEnvelpeDataSource(
        services,
        [resource.id],
        activePowerChartDefinition,
        getNow
      )
    )
  }

  return ds
}

export function newTransformerDataSource(
  services: Services,
  transformerId: string,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    request.interval = limitInterval(request.interval, getNow())
    request.resolution = Resolution.ONE_HOUR
    return services.chartsService.fetchTimeSeries(transformerId, request)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow())
  )

  for (const seriesConfig of transformerLoadingTimeSeriesConfigs) {
    ds.addChartSeries(transformerLoadingChartDefinition, seriesConfig)
  }

  // The forecast data needs its own data source because it has a different end time,
  // and it doesn't need to be refreshed.
  // TODO(andrew): Use the config to set the forecast end time.
  const forecastDS = new TimeSeriesDataSource((request) => {
    request.aggregation = ResourceType.METER_ELECTRICAL
    request.resolution = Resolution.ONE_HOUR
    return services.chartsService.fetchTimeSeries(transformerId, request)
  }, getNow().plus({ hours: 24 }))

  forecastDS.addChartSeries(transformerLoadingChartDefinition, {
    metric: Metric.FORECAST_COMPUTED_POWER_APPARENT,
    config: {
      seriesName: 'Forecast',
      seriesColor: PACIFIC.hex,
      seriesLine: 'dashed',
    },
  })
  return new AggregateDataSource(ds, forecastDS)
}
