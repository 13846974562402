<template>
  <div
    role="region"
    aria-label="Search Box"
    class="position-relative"
    style="min-width: 12rem"
  >
    <!-- Search -->
    <form class="d-flex align-center" role="search" @submit.prevent="doSearch">
      <!-- Input -->
      <v-text-field
        v-model="searchText"
        v-model:focused="isInputFocused"
        base-color="grey-darken-1"
        color="secondary"
        :loading="isLoading ? 'primary' : undefined"
        prepend-inner-icon="mdi-magnify"
        hide-details
        clearable
        @click:clear="resetState"
      />
    </form>

    <!-- Results -->
    <div
      v-if="shouldShowResultsPanel"
      role="region"
      aria-label="Search results"
      class="d-flex flex-column overflow-y-auto bg-white gmaps-box-shadow rounded pt-6 pb-2"
      style="
        position: absolute;
        top: 4rem;
        right: 0;
        width: 40rem;
        min-height: 6rem;
        border: 1px solid var(--ce-border-color);
      "
      :style="{ maxHeight: `${$vuetify.display.height - 200}px` }"
      @mouseenter="() => (isMouseOverResults = true)"
      @mouseleave="() => (isMouseOverResults = false)"
    >
      <!-- Close btn -->
      <v-btn
        icon="mdi-close"
        size="x-small"
        elevation="0"
        style="position: absolute; top: 0.25rem; right: 0.25rem"
        @click="() => (isMouseOverResults = false)"
      />

      <!-- No Results -->
      <div
        v-if="noResults"
        class="d-flex align-center justify-center"
        style="flex: 1"
      >
        <span class="d-block text-grey">No results.</span>
      </div>

      <!-- Results -->
      <template v-else v-for="block of blocks">
        <search-results-block
          v-if="block.results.length"
          :key="block.title"
          :block
          class="pb-4"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import SearchResultsBlock from '@/components/navigation/SearchResultsBlock.vue'
import type { Block, LoadOptions } from '@/model/search/types'
import { searchDers } from '@/model/search/searchDers'
import { searchMeters } from '@/model/search/searchMeters'
import { searchSubstations } from '@/model/search/searchSubstations'
import { searchTransformers } from '@/model/search/searchTransformers'
import { searchAddresses } from '@/model/search/searchAddresses'

export default defineComponent({
  name: 'SearchBox',
  components: { SearchResultsBlock },
  setup() {
    return { maxResultsPerBlock: 5 }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isInputFocused: false,
      isMouseOverResults: false,
      searchText: '',
      blocks: null as null | Block[],
    })
  },
  computed: {
    noResults(): boolean {
      return this.blocks?.every((block) => block.results.length === 0) ?? true
    },
    shouldShowResultsPanel(): boolean {
      return (
        (this.isInputFocused || this.isMouseOverResults) &&
        this.blocks !== null &&
        !this.isLoading
      )
    },
  },
  methods: {
    resetState(): void {
      this.blocks = null
    },
    async doSearch(): Promise<void> {
      this.isLoading = true

      this.resetState()

      const options: LoadOptions = {
        config: this.$rittaConfig,
        services: this.$services,
        dictionary: this.$dictionary,
        currentRoute: this.$route,
        router: this.$router,
      }

      const newBlocks = await Promise.all(
        [
          searchDers,
          searchMeters,
          searchSubstations,
          searchTransformers,
          searchAddresses,
        ].map((fn) =>
          fn(this.searchText.trim(), this.maxResultsPerBlock, options)
        )
      )

      this.handleSingleResult(newBlocks)

      this.blocks = newBlocks

      this.isLoading = false
    },

    handleSingleResult(newBlocks: Block[]): void {
      const allResults = newBlocks.flatMap((block) => block.results)

      if (allResults.length === 1) {
        const singleResult = allResults[0]

        // Visit the link when avaialble.
        if (singleResult.title.link) {
          this.$router.push(singleResult.title.link)
        }

        // Run "onClick" when available.
        singleResult.title.onClick?.()
      }
    },
  },
})
</script>
