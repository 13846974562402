<template>
  <div
    class="chartjs-window-resizing"
    :style="{ height }"
    role="region"
    aria-label="Chart"
  >
    <bar-chart :data :options :aria-label="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { ChartData, ChartOptions } from 'chart.js'
import { Bar as BarChart } from 'vue-chartjs'
import type { Hex } from '@/constants/colors'
import type { HorizontalBarChartData } from '@/types/charts'

export default defineComponent({
  name: 'HorizontalBarChart',
  props: {
    horizontalBarData: {
      type: Object as PropType<HorizontalBarChartData>,
      required: true,
    },
    xAxisTitle: {
      type: String,
      required: true,
    },
    barColor: {
      type: String as PropType<Hex>,
      required: true,
    },
    /**
     * Specifies the height of the chart.
     * Accepts any valid CSS unit (e.g., 'px', '%', 'em').
     */
    height: {
      type: String,
      required: false,
      default: '15rem',
    },
    /** * Mostly for E2E tests */
    name: {
      type: String,
      required: false,
    },
  },
  components: { BarChart },
  computed: {
    data(): ChartData<'bar'> {
      return {
        labels: this.horizontalBarData.bars.map((bar) => bar.label),
        datasets: [
          {
            label: this.xAxisTitle,
            data: this.horizontalBarData.bars.map((bar) => bar.value),
            backgroundColor: this.barColor,
            barPercentage: 0.9,
            categoryPercentage: 1,
          },
        ],
      }
    },
    options(): ChartOptions<'bar'> {
      return {
        plugins: { legend: { display: false } },
        indexAxis: 'y',
        scales: {
          x: {
            title: { display: true, text: this.xAxisTitle },
            grid: { display: false },
            border: { display: false },
          },
          y: { grid: { display: false } },
        },
        // NOTE(rafael): below required so the tooltip works correctly for
        // the horizontal bar chart.
        interaction: {
          mode: 'index',
          axis: 'y',
          intersect: true,
        },
      }
    },
  },
})
</script>
