<template>
  <div class="c-ControlGroupDetailsSystemModeAuthorized">
    <!-- Loading -->
    <centered-spinner v-if="isLoadingGroup" />

    <!-- Group loaded -->
    <template
      v-if="!isLoadingGroup && !hasLoadingFailed && !isUnspecifiedPolicy"
    >
      <!-- View mode -->
      <template v-if="!isModeChangePolicy">
        <!-- Current Policy -->
        <span class="d-block text-caption">{{ currentPolicyLabel }}</span>
        <span data-test="groupChangePolicy__currentPolicy" class="d-block pb-4">
          {{ currentPolicy }}
        </span>

        <!-- Btn Change policy -->
        <flat-btn-small
          data-test="groupChangePolicy__btnSmallChangePolicy"
          :disabled="!isGroupAllowed"
          class="pb-4"
          @click="openModeChangePolicy"
        >
          {{ btnSmallChangePolicyText }}
        </flat-btn-small>
      </template>

      <!-- Change Policy mode -->
      <template v-else>
        <!-- Select Policy type -->
        <v-select
          v-model="policySelected"
          :items="policyOptions"
          :disabled="isSendingPolicy"
          :label="selectMenuLabel"
          :color="selectMenuColor"
          class="pb-4"
          style="width: 176px"
        />

        <!-- Btn Cancel policy change -->
        <flat-btn-small
          data-test="groupChangePolicy__btnCancelPolicyChange"
          v-if="!showPeakEventControls"
          :disabled="isSendingPolicy"
          @click="cancelPolicyChange"
        >
          {{ btnCancelChangePolicyText }}
        </flat-btn-small>

        <!-- Peak event controls -->
        <template v-if="showPeakEventControls">
          <peak-event-controls
            :initial-interval="generateInitialInterval()"
            :form-disabled="isSendingPolicy"
            :min-duration="minDuration"
            :max-duration="groupMaxDuration"
            :initial-power-setpoint="initialPowerSetpoint"
            @peak-event="handlePeakEvent"
          />

          <!-- Validation messages -->
          <v-alert
            v-if="isInvalidTimeRange"
            density="compact"
            type="info"
            class="mb-4"
            dark
          >
            {{ rangeIsInvalidText }}
          </v-alert>

          <v-alert
            v-if="isInvalidSetpoint"
            density="compact"
            type="info"
            class="mb-4"
            dark
          >
            {{ setpointIsInvalidText }}
          </v-alert>

          <!-- Action buttons -->
          <div class="d-flex align-center">
            <!-- Spacer -->
            <v-spacer></v-spacer>

            <!-- Btn Cancel policy change -->
            <flat-btn-small
              data-test="groupChangePolicy__btnCancelPolicyChange"
              class="pr-6"
              :disabled="isSendingPolicy"
              @click="cancelPolicyChange"
            >
              {{ btnCancelChangePolicyText }}
            </flat-btn-small>

            <!-- Btn Change policy -->
            <div>
              <v-btn
                data-test="groupChangePolicy__btnChangePolicy"
                :disabled="
                  !newPolicyInterval ||
                  isInvalidTimeRange ||
                  isInvalidSetpoint ||
                  isSendingPolicy
                "
                :loading="isSendingPolicy"
                :color="btnChangeColor"
                class="elevation-0"
                @click="openModalConfirmationToPeakEvent"
              >
                {{ btnChangeText }}
              </v-btn>
            </div>
          </div>
        </template>

        <!-- Modal for confirmation -->
        <control-group-details-system-mode-modal
          v-if="renderModal"
          :ref="REF_MODAL_CONFIRMATION"
          @confirm="changeGroupPolicy"
          @cancel="closeModalConfirmation"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { DateTime, Duration, Interval } from 'luxon'
import {
  GetGroupRequest,
  SetGroupPolicyRequest,
} from 'rfs/control/proto/control_service_pb'
import {
  Policy,
  Params,
  Params_PeakTimePaybackEvent as ParamsPeakTimePaybackEvent,
} from 'rfs/control/proto/policy_pb'
import { DeviceType } from 'rfs/control/proto/model_pb'
import { Timestamp } from '@/services/timestamp_pb'
import { OpenModalPayload } from './ControlGroupDetailsSystemModeModal'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ControlGroupDetailsSystemModeModal from '@/components/control/ControlGroupDetailsSystemModeModal.vue'
import FlatBtnSmall from '@/components/FlatBtnSmall.vue'
import PeakEventControls from './PeakEventControls.vue'
import { sentryCaptureMessage } from '@/utils/sentryCaptureMessage'

const POLICY_OPTION_DEFAULT = 'Default'
const POLICY_OPTION_PEAK_EVENT = 'Peak Event'
const POLICY_OPTION_RESTRICTED = 'Restricted'
const REF_MODAL_CONFIRMATION = 'modalConfirmation'

export default defineComponent({
  name: 'ControlGroupDetailsSystemModeAuthorized',
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  components: {
    CenteredSpinner,
    FlatBtnSmall,
    PeakEventControls,
    ControlGroupDetailsSystemModeModal,
  },
  setup() {
    return {
      // Arbitrary
      minDuration: Duration.fromObject({ minutes: 30 }),
    }
  },
  data() {
    return {
      POLICY_OPTION_DEFAULT,
      POLICY_OPTION_PEAK_EVENT,
      REF_MODAL_CONFIRMATION,
      currentPolicyLabel: 'Current policy',
      selectMenuLabel: 'Current policy',
      selectMenuColor: 'secondary',
      btnCancelChangePolicyText: 'Cancel policy change',
      btnSmallChangePolicyText: 'Change policy',
      btnChangeColor: 'primary',
      btnChangeText: 'Change',
      rangeIsInvalidText: 'The selected time range is invalid.',
      setpointIsInvalidText: 'The provided setpoint is invalid.',
      isLoadingGroup: false,
      hasLoadingFailed: false,
      isUnspecifiedPolicy: false,
      isGroupAllowed: false,
      isModeChangePolicy: false,
      isSendingPolicy: false,
      policyOptions: [
        POLICY_OPTION_DEFAULT,
        POLICY_OPTION_PEAK_EVENT,
      ] as string[],
      currentPolicy: undefined as undefined | string,
      policySelected: undefined as undefined | string,
      newPolicyInterval: undefined as Interval | undefined,
      renderModal: false,
      groupMaxDuration: undefined as Duration | undefined,
      needsPowerSetpoint: false,
      initialPowerSetpoint: undefined as undefined | number,
      newPowerSetpoint: undefined as undefined | number,
    }
  },
  created(): void {
    this.fetchGroup()
  },
  computed: {
    showPeakEventControls(): boolean {
      return (
        this.isModeChangePolicy &&
        this.currentPolicy === POLICY_OPTION_DEFAULT &&
        this.policySelected === POLICY_OPTION_PEAK_EVENT
      )
    },
    /**
     * When `tomorrow` any time range is valid.
     * When `today` the time range can possibly be in the past (invalid).
     */
    isInvalidTimeRange(): boolean {
      let isInvalid = false

      if (this.newPolicyInterval) {
        const now = DateTime.local()

        if (this.newPolicyInterval.start < now) {
          isInvalid = true
        } else if (this.newPolicyInterval.toDuration() < this.minDuration) {
          isInvalid = true
        } else if (!this.groupMaxDuration) {
          isInvalid = true
        } else if (
          this.newPolicyInterval.toDuration() > this.groupMaxDuration
        ) {
          isInvalid = true
        } else if (this.newPolicyInterval.start.hasSame(now, 'day')) {
          const availableInterval = Interval.fromDateTimes(
            now,
            now.endOf('day')
          )
          isInvalid = !availableInterval.contains(this.newPolicyInterval.start)
        }
      }

      return isInvalid
    },
    isInvalidSetpoint(): boolean {
      // Don't need power anyway
      if (!this.needsPowerSetpoint) {
        return false
      }

      return (
        this.newPowerSetpoint === undefined ||
        Number.isNaN(this.newPowerSetpoint) ||
        this.newPowerSetpoint < 0
      )
    },
  },
  watch: {
    groupId(): void {
      this.fetchGroup()
    },
    /**
     * Watch the select menu.
     * When `currentPolicy` is `Peak Event` and the user selects `Default`,
     * we should trigger the confirmation modal.
     */
    policySelected(newValue: string | undefined): void {
      if (
        this.currentPolicy === POLICY_OPTION_PEAK_EVENT &&
        newValue === POLICY_OPTION_DEFAULT
      ) {
        this.openConfirmationToDefault()
      }
    },
  },
  methods: {
    async fetchGroup(): Promise<void> {
      this.isLoadingGroup = true

      // Reset state.
      this.resetState()

      try {
        const req = new GetGroupRequest({ id: this.groupId })
        const group = await this.$services.control.getGroup(req)

        const enabledPolicies = group.enabledPolicies
        if (
          enabledPolicies.length &&
          enabledPolicies.includes(Policy.DEFAULT) &&
          enabledPolicies.includes(Policy.PEAK_TIME_PAYBACK_EVENT)
        ) {
          this.isGroupAllowed = true
        }

        const currentPolicy = group.currentPolicy

        if (currentPolicy === Policy.UNSPECIFIED) {
          this.isUnspecifiedPolicy = true
          return
        }

        switch (currentPolicy) {
          // Generic.
          case Policy.DEFAULT:
            this.currentPolicy = POLICY_OPTION_DEFAULT
            break
          case Policy.PEAK_TIME_PAYBACK_EVENT:
            this.currentPolicy = POLICY_OPTION_PEAK_EVENT
            break
          // Chargepoint.
          case Policy.CHARGEPOINT_DEFAULT:
            this.currentPolicy = POLICY_OPTION_DEFAULT
            break
          case Policy.CHARGEPOINT_RESTRICTED:
            this.currentPolicy = POLICY_OPTION_RESTRICTED
            break
          // Powerwall.
          case Policy.TESLA_POWERWALL_DEFAULT:
            this.currentPolicy = POLICY_OPTION_DEFAULT
            break
          case Policy.TESLA_POWERWALL_PEAK_EVENT:
            this.currentPolicy = POLICY_OPTION_PEAK_EVENT
            break
          default:
            throw new Error(`policy "${currentPolicy}" not expected`)
        }

        // These should really be enforced by the control server instead.
        this.groupMaxDuration =
          group.deviceType === DeviceType.TESLA_POWERWALL_BATTERY
            ? // Assumes a fully-charged battery at max discharge rate
              Duration.fromObject({ hours: 2, minutes: 10 })
            : // Arbitrary
              Duration.fromObject({ hours: 12 })

        this.needsPowerSetpoint =
          group.deviceType === DeviceType.CHARGEPOINT_EV_CHARGER

        if (this.needsPowerSetpoint) {
          this.newPowerSetpoint = this.initialPowerSetpoint = 5.0
        }
      } catch (err) {
        this.hasLoadingFailed = true
        console.error(
          'ControlGroupDetailsSystemModeAuthorized.fetchCurrentPolicy: %o',
          err
        )
      } finally {
        this.isLoadingGroup = false
      }
    },
    resetState(): void {
      this.isSendingPolicy = false
      this.hasLoadingFailed = false
      this.isUnspecifiedPolicy = false
      this.isGroupAllowed = false
      this.currentPolicy = undefined
      this.policySelected = undefined
      this.isModeChangePolicy = false
      this.newPolicyInterval = undefined
      this.groupMaxDuration = undefined
      this.needsPowerSetpoint = false
      this.initialPowerSetpoint = undefined
      this.newPowerSetpoint = undefined
    },
    openModeChangePolicy(): void {
      this.policySelected = this.currentPolicy
      this.isModeChangePolicy = true
    },
    cancelPolicyChange(): void {
      this.isModeChangePolicy = false

      /* Reset */
      this.policySelected = undefined
      this.newPolicyInterval = undefined
      this.newPowerSetpoint = this.initialPowerSetpoint
    },
    /* Default: "today" from "19:00" to "21:00" Montain Time. */
    generateInitialInterval(): Interval {
      const now = DateTime.local()
      return Interval.fromDateTimes(
        now.set({ hour: 19, minute: 0, second: 0, millisecond: 0 }),
        now.set({ hour: 21, minute: 0, second: 0, millisecond: 0 })
      )
    },
    handlePeakEvent(
      interval: Interval | undefined,
      powerSetpoint: number
    ): void {
      this.newPolicyInterval = interval
      this.newPowerSetpoint = powerSetpoint
    },
    async openModalConfirmationToPeakEvent(): Promise<void> {
      if (
        !this.newPolicyInterval ||
        this.isInvalidTimeRange ||
        this.isInvalidSetpoint
      ) {
        console.debug(
          'ControlGroupDetailsSystemModeAuthorized.openModalConfirmationToPeakEvent: form invalid'
        )
        return
      }

      this.renderModal = true
      await this.$nextTick()

      const startTimeString = this.newPolicyInterval.start.toLocaleString(
        DateTime.TIME_24_SIMPLE
      )
      const endTimeString = this.newPolicyInterval.end.toLocaleString({
        ...DateTime.TIME_24_SIMPLE,
        timeZoneName: 'short',
      })
      const now = DateTime.local()

      const dateString = `${this.newPolicyInterval.start.toLocaleString(
        DateTime.DATE_MED
      )} (${
        this.newPolicyInterval.start.hasSame(now, 'day') ? 'Today' : 'Tomorrow'
      })`

      // Open the confirmation modal.
      const payload: OpenModalPayload = {
        currentPolicy: this.currentPolicy!,
        newPolicy: this.policySelected!,
        message: `Policy will be in effect from ${startTimeString} to ${endTimeString} on ${dateString}`,
      }

      // I'm ignoring typescript because it can't know we're calling
      // a method on `ControlGroupDetailsSystemModeModal.vue` component.
      // @ts-ignore
      this.$refs[REF_MODAL_CONFIRMATION].openModal(payload)
    },
    async closeModalConfirmation(): Promise<void> {
      // When the user selects `Default` in <v-select> but then cancels
      // the change in the modal, we should revert the <v-select> option back
      // to `Peak Event` option.
      if (this.currentPolicy === POLICY_OPTION_PEAK_EVENT) {
        this.policySelected = POLICY_OPTION_PEAK_EVENT
      }

      this.renderModal = false
      await this.$nextTick()
    },
    async changeGroupPolicy(): Promise<void> {
      this.isSendingPolicy = true

      try {
        const req = new SetGroupPolicyRequest({ id: this.groupId })

        let newPolicy: Policy | undefined

        switch (this.policySelected) {
          case POLICY_OPTION_DEFAULT:
            newPolicy = Policy.DEFAULT
            break
          case POLICY_OPTION_PEAK_EVENT:
            newPolicy = Policy.PEAK_TIME_PAYBACK_EVENT
            break
          default:
            throw new Error('error selecting the new policy.')
        }
        req.policy = newPolicy

        // Peak Event params.
        if (newPolicy === Policy.PEAK_TIME_PAYBACK_EVENT) {
          if (!this.newPolicyInterval) {
            throw new Error('no interval available for peak event.')
          }

          req.params = new Params({
            paramsOneof: {
              case: 'peakTimePaybackEvent',
              value: {
                startTime: Timestamp.fromDateTime(this.newPolicyInterval.start),
                endTime: Timestamp.fromDateTime(this.newPolicyInterval.end),
              },
            },
          })

          if (this.needsPowerSetpoint) {
            if (this.isInvalidSetpoint) {
              throw new Error('invalid power setpoint for peak event.')
            }

            ;(
              req.params.paramsOneof.value as ParamsPeakTimePaybackEvent
            ).maxPowerKw = this.newPowerSetpoint
          }
        }

        await this.$services.control.setGroupPolicy(req)

        // Emit `success` so the snackbar in the parent component will show and
        // this component will reload.
        this.$emit('success')
      } catch (err) {
        sentryCaptureMessage(
          `'ControlGroupDetailsSystemModeAuthorized.changeGroupPolicy: error changing policy: ${
            (err as unknown as any).message
          }`,
          'error',
          { includeEmail: true, config: this.$rittaConfig }
        )
        console.error(
          'ControlGroupDetailsSystemModeAuthorized.changeGroupPolicy: %o',
          err
        )
        // Emit `error` so the snackbar in the parent component will show.
        this.$emit('error')
      } finally {
        this.isSendingPolicy = false
      }
    },
    async openConfirmationToDefault(): Promise<void> {
      const payload: OpenModalPayload = {
        currentPolicy: this.currentPolicy!,
        newPolicy: this.policySelected!,
        message: 'Policy will be in effect until a new peak event is called',
      }

      this.renderModal = true
      await this.$nextTick()

      // I'm ignoring typescript because it can't know we're calling
      // a method on `ControlGroupDetailsSystemModeModal.vue` component.
      // @ts-ignore
      this.$refs[REF_MODAL_CONFIRMATION].openModal(payload)
    },
  },
})
</script>
