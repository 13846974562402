import 'vuetify/styles'
import { createVuetify, ThemeDefinition } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { PRIMARY } from '@/constants/colors'

const camusLight: ThemeDefinition = {
  dark: false,
  colors: {
    primary: PRIMARY.hex,
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#BC2C0E',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
}

export default createVuetify({
  theme: { defaultTheme: 'camusLight', themes: { camusLight } },
  icons: { aliases, defaultSet: 'mdi', sets: { mdi } },
  defaults: {
    VAutocomplete: {
      density: 'compact',
      variant: 'outlined',
      hideDetails: true,
    },
    VRadioGroup: { density: 'compact', hideDetails: true },
    VSelect: { density: 'compact', variant: 'outlined', hideDetails: true },
    VTextField: { density: 'compact', variant: 'outlined', bgColor: 'white' },
  },
})
