import type { ResourceType } from '@/constants/resourceType'

export const ALERTS = 'alerts'
export const ANALYZE = 'analyze'
export const CONTROL_DEVICE_RESOURCE = 'controlDeviceResource'
export const CONTROL_DEVICE_RESOURCE_TELEMETRY =
  'controlDeviceResourceTelemetry'
export const CONTROL_DEVICE = 'controlDevice'
export const CONTROL_DEVICE_TELEMETRY = 'controlDeviceTelemetry'
export const CONTROL_DEVICE_PERFORMANCE = 'controlDevicePerformance'
export const CONTROL_DEVICE_HISTORY = 'controlDeviceHistory'
export const CONTROL_DEVICE_DETAILS = 'controlDeviceDetails'
export const CONTROL_GROUP_DETAILS = 'controlGroupDetails'
export const CONTROL_GROUP_HISTORY = 'controlGroupDetailsHistory'
export const CONTROL_GROUP_CONTROLS = 'controlGroupControls'
export const CONTROL_GROUP_PERFORMANCE = 'controlGroupPerformance'
export const CONTROL_OVERVIEW = 'controlOverview'
export const DERS = 'ders'
export const FLEET = 'fleet'
export const FORECAST = 'forecast'
export const FORECAST_QUALITY = 'forecastQuality'
export const GRID_IMPACT = 'gridImpact'
export const HYDRO_DETAILS = 'hydroDetails'
export const INSIGHTS = 'insights'
export const INSIGHTS_EV = 'insightsEv'
export const INSIGHTS_PV = 'insightsPv'
export const INSIGHTS_LOADING = 'insightsLoading'
export const METERS = 'meters'
export const NOTIFICATIONS = 'notifications'
export const NOTIFICATION_DETAILS = 'notificationDetails'
export const OVERVIEW = 'overview'
export const SITE = 'site'
export const SOLAR_ARRAY_DETAILS = 'solarArrayDetails'
export const SUBSTATIONS = 'substations'
export const SUPPLY_AND_DEMAND = 'supplyAndDemand'
export const TRANSFORMERS = 'transformers'
export const TRANSFORMER_DETAILS = 'transformerDetails'
export const VEHICLE = 'vehicle'
export const VOLTAGE_MAPPING = 'voltageMapping'
export const WIND_DETAILS = 'windDetails'

export function createAllResourcesRouteName(rt: ResourceType): string {
  return `all--${rt}`
}

export function createResourceDetailsRouteName(rt: ResourceType): string {
  return `details--${rt}`
}
