<template>
  <section class="d-flex flex-column pa-6">
    <ce-title big class="pb-12">Forecast Quality</ce-title>
    <time-series-chart-group
      v-if="showChart"
      :charts="charts"
      :data-source="dataSource"
      :interval="selectedInterval"
      @new-interval="handleNewInterval"
    />
    <template v-if="products12CP">
      <h3 class="pb-8">Coincident Peak Forecast Accuracy</h3>
      <div class="pb-6" style="width: 192px">
        <date-month-picker
          :max="forecastAccuracy.max"
          v-model="forecastAccuracy.month"
        />
      </div>
      <ce-data-table
        :headers
        :table
        :is-loading="forecastAccuracy.isLoading"
        :show-pagination="false"
        dense
      >
        <template v-slot:[`item.peakInForecastedWindow`]="{ value }">
          <v-icon v-if="value === 'Yes'" class="ml-8">mdi-check</v-icon>
          <v-icon v-else class="ml-8">mdi-close</v-icon>
        </template>
      </ce-data-table>
    </template>
  </section>
</template>

<script lang="ts">
import { Interval } from 'luxon'
import { defineComponent } from 'vue'

import CeTitle from '@/components/CeTitle.vue'
import {
  CeDataTable,
  DateMonthPicker,
  TimeSeriesChartGroup,
} from '@/components/common'
import {
  forecastRMSEChart,
  newForecastQualityDataSource,
} from '@/model/forecast'
import {
  Row,
  createForecastAccuracyRows,
  forecastAccuracyHeaders,
} from '@/model/forecast/ForecastAccuracyDataTable'
import { intervalLast7Days } from '@/utils/time'
import { DataTable } from '@/model/tables/DataTable'
import { PeakPeriod } from '@/services/forecast.service'
import { SystemPeakDemandResponse } from 'rfs/frontend/proto/forecast_pb'

export default defineComponent({
  name: 'ForecastQuality',
  components: {
    CeDataTable,
    CeTitle,
    DateMonthPicker,
    TimeSeriesChartGroup,
  },
  setup() {
    return {
      headers: forecastAccuracyHeaders(),
    }
  },
  data() {
    const now = this.$observationTime()
    return {
      charts: [forecastRMSEChart],
      dataSource: newForecastQualityDataSource(
        this.$rittaConfig,
        this.$services
      ),
      selectedInterval: intervalLast7Days(this.$observationTime()),
      // forecastAccuracyMax: now.minus({ months: 1 }), // last complete month
      // forecastAccuracyMonth: now.minus({ months: 1 }),
      // forecastAccuracyResponse: new SystemPeakDemandResponse(),
      // forecastAccuracyIsLoading: false,
      forecastAccuracy: {
        max: now.minus({ months: 1 }),
        month: now.minus({ months: 1 }),
        response: new SystemPeakDemandResponse(),
        isLoading: false,
      },
    }
  },
  computed: {
    showChart(): boolean {
      return !!this.$rittaConfig.analysis?.forecastQuality?.product
    },
    products12CP(): { [key: string]: string } | undefined {
      return this.$rittaConfig.analysis?.forecastDashboard
        ?.historicalContextChart?.products12CP
    },
    table(): DataTable<Row> {
      const { month, response } = this.forecastAccuracy
      return {
        // @ts-ignore because response is indeed a SystemPeakDemandResponse
        rows: createForecastAccuracyRows(month, response),
      }
    },
  },
  watch: {
    'forecastAccuracy.month': {
      immediate: true,
      handler: function () {
        this.fetchTableData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.selectedInterval = newInterval
    },
    async fetchTableData(): Promise<void> {
      if (!this.products12CP) return

      this.forecastAccuracy.isLoading = true
      // Clear the existing data so we don't show stale data while loading
      this.forecastAccuracy.response = new SystemPeakDemandResponse()

      try {
        this.forecastAccuracy.response =
          await this.$services.forecastService.getSystemPeakDemand(
            this.forecastAccuracy.month,
            PeakPeriod.TWELVE_CP,
            this.products12CP
          )
      } catch (error) {
        console.log('Error fetching forecast accuracy data:', error)
      } finally {
        this.forecastAccuracy.isLoading = false
      }
    },
  },
})
</script>
