<template>
  <v-autocomplete
    :model-value="fields.value"
    :items="fields.items"
    :menu-props="{ activatorProps: { 'aria-label': headerTitle } }"
    v-model:search="search"
    chips
    hide-no-data
    multiple
    @update:model-value="handleNewValue"
  >
    <template v-slot:chip="{ index }">
      <v-chip v-if="index === 0" bg-color="#e8e8e8" density="comfortable">
        <span>{{ formatChip() }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { FilterMultiSelect } from '@/model/tables/DataTable'

export default defineComponent({
  name: 'FilterMultiSelect',
  props: {
    fields: {
      type: Object as PropType<FilterMultiSelect>,
      required: true,
    },
    headerTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      search: '',
      isLoading: false,
    }
  },
  methods: {
    handleNewValue(v: string[]): void {
      const newFields: FilterMultiSelect = {
        ...this.fields,
        value: v,
      }
      this.$emit('new-fields', newFields)
      // Clear the autocomplete text since they've selected a value
      this.search = ''
    },
    formatChip(): string {
      if (this.fields.value.length === 1) {
        return this.fields.value[0]
      } else {
        return `${this.fields.value.length} selected`
      }
    },
  },
})
</script>
