<template>
  <side-sheet
    :title="title"
    :model-value="modelValue"
    @update:model-value="(newValue) => $emit('update:model-value', newValue)"
  >
    <div
      v-for="{ headerTitle, key, fields, props } of computedFilters"
      :key="key"
      :data-test="key"
      class="py-2 px-6"
      v-bind="props"
      role="group"
      :aria-label="headerTitle"
    >
      <span
        class="d-block text-caption font-weight-bold mb-4"
        :style="{
          color: subTitleColor,
          borderBottom: `1px solid ${subTitleBorder}`,
        }"
      >
        {{ headerTitle }}
      </span>

      <component
        :is="component(fields)"
        :fields="fields"
        :header-title="headerTitle"
        @new-fields="(f: FilterFields) => handleNewFields(key, f)"
      />
    </div>

    <!-- Reset -->
    <div class="d-flex px-6 pt-10">
      <button-segmented
        :disabled="isResetDisabled"
        icon="$reset"
        label="Reset"
        @click="() => emitNewFilters()"
      />
    </div>
  </side-sheet>
</template>

<script lang="ts">
import { defineComponent, Component, PropType } from 'vue'
import { GREY10, GREY13 } from '@/constants/colors'
import { Header, Filters, FilterFields } from '@/model/tables/DataTable'
import { hasAtLeastOneFilterValue } from '@/model/tables/helper'
import SideSheet from '@/components/navigation/SideSheet.vue'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import FilterMinMax from './FilterMinMax.vue'
import FilterDropDown from './FilterDropDown.vue'
import FilterDate from './FilterDate.vue'
import FilterResourceList from './FilterResourceList.vue'
import FilterMultiSelect from './FilterMultiSelect.vue'

type FilterItem = {
  key: string
  fields: FilterFields
  headerTitle: string
  props: Header['headerProps']
}

export default defineComponent({
  name: 'DataTableFilters',
  props: {
    /** * v-model */
    modelValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    filters: {
      type: Map as PropType<Filters>,
      required: true,
    },
  },
  components: {
    SideSheet,
    ButtonSegmented,
    FilterMinMax,
    FilterDropDown,
    FilterDate,
    FilterMultiSelect,
    FilterResourceList,
  },
  emits: ['update:model-value', 'new-filters'],
  setup() {
    return {
      title: 'Filters',
      subTitleColor: GREY10.hex,
      subTitleBorder: GREY13.hex,
    }
  },
  computed: {
    isResetDisabled(): boolean {
      const hasTouchedFilters = this.filters
        ? hasAtLeastOneFilterValue(this.filters)
        : false

      return !hasTouchedFilters
    },
    computedFilters(): FilterItem[] {
      return this.headers.reduce<FilterItem[]>((acc, header) => {
        const fields = this.filters.get(header.key)

        if (fields) {
          acc.push({
            fields,
            key: header.key,
            headerTitle: header.title,
            props: header.headerProps,
          })
        }

        return acc
      }, [])
    },
  },
  methods: {
    component(fields?: FilterFields): Component | null {
      switch (fields?.type) {
        case 'min-max':
          return FilterMinMax
        case 'dropdown':
          return FilterDropDown
        case 'date':
          return FilterDate
        case 'multiselect':
          return FilterMultiSelect
        case 'resources':
          return FilterResourceList
        default:
          return null
      }
    },
    handleNewFields(id: string, newFields: FilterFields): void {
      this.emitNewFilters(new Map(this.filters).set(id, newFields))
    },
    emitNewFilters(newFilters?: Filters): void {
      this.$emit('new-filters', newFilters)
    },
  },
})
</script>
