import { protoInt64, Duration, Timestamp } from '@bufbuild/protobuf'
import { DateTime } from 'luxon'

import type { Date as GDate } from 'rfs/pb/calendar_pb'

declare module '@bufbuild/protobuf' {
  interface Timestamp {
    /**
     * Return this Timestamp's value in milliseconds.
     */
    toMillis(): number
  }
  namespace Timestamp {
    /**
     * Create a Timestamp from milliseconds.
     * Sets both "seconds" and "nanos" fields.
     */
    export function fromMillis(ms: number): Timestamp
    /**
     * Create a Timestamp from a Luxon DateTime.
     * Sets only the "seconds" field.
     */
    export function fromDateTime(dt: DateTime): Timestamp
  }
  interface Duration {
    /**
     * Return this Duration's length in milliseconds.
     */
    toMillis(): number
  }
  namespace Duration {
    /**
     * Create a Duration from milliseconds.
     * Sets both "seconds" and "nanos" fields.
     */
    export function fromMillis(ms: number): Duration
  }
}

Timestamp.prototype.toMillis = function () {
  return Number(this.seconds) * 1000 + Math.ceil(this.nanos / 1000000)
}

/** Makes it possible to sort these in VDataTable; also good for debugging */
Timestamp.prototype.toString = function () {
  return this.toJsonString()
}

Timestamp.fromMillis = function (millis: number) {
  return new Timestamp({
    seconds: protoInt64.parse(Math.floor(millis / 1000)),
    nanos: (millis % 1000) * 1000000,
  })
}

Timestamp.fromDateTime = function (dt: DateTime) {
  return Timestamp.fromMillis(dt.toMillis())
}

Duration.prototype.toMillis = function () {
  return Number(this.seconds) * 1000 + Math.ceil(this.nanos / 1000000)
}

/**
 * Create a protobuf Duration from milliseconds.
 */
Duration.fromMillis = function (millis: number): Duration {
  return new Duration({
    seconds: protoInt64.parse(Math.floor(millis / 1000)),
    nanos: (millis % 1000) * 1000000,
  })
}

declare module 'luxon' {
  namespace DateTime {
    /**
     * Return a DateTime with year/month/day set based on the given Date.
     */
    export function fromGDate(date?: GDate): DateTime
  }
}

DateTime.fromGDate = function (date?: GDate): DateTime {
  if (date == null) {
    throw new Error('DateTime.fromGDate() with null GDate')
  }
  return DateTime.local(date.year, date.month, date.day)
}

export { Duration, Timestamp }
