<template>
  <div class="position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isFirstLoading" />

    <!--  -->
    <template v-else>
      <!-- Has Failed -->
      <alert-error v-if="hasLoadingFailed">{{ MSG_HAS_FAILED }}</alert-error>

      <!-- Data table -->
      <ce-data-table
        v-else-if="table"
        :headers
        :table
        :filters
        :options
        :visibleHeaders
        :serverItemsLength
        :isLoading
        dense
        v-model:search="search"
        :sticky-offset="TOP_BAR_HEIGHT"
        sticky
        :bgColor
        @new-filters="handleNewFilters"
        @reset-filters="handleResetFilters"
        @new-options="handleNewOptions"
        @new-visible-headers="handleNewVisibleHeaders"
        @search="handleNewSearch"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { WHITE } from '@/constants/colors'
import { MSG_HAS_FAILED } from '@/constants/alerts'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import { useAlertStore } from '@/stores/alert'
import { AlertHelper } from '@/model/alert/helper'
import { hasAtLeastOneFilterValue } from '@/model/tables/helper'
import { createInitialVisibleHeaders } from '@/model/tables/header'
import { VisibleHeaders } from '@/model/tables/DataTable'
import {
  AlertsDataTable,
  CustomFilters,
  CustomOptions,
  createAlertsTable,
  createInitialFilters,
  createPristineFilters,
  createRequest,
  headers,
  createInitialOptions,
} from '@/model/alert/AlertsDataTable'
import AlertError from '@/components/common/AlertError.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import { Alert } from 'rfs/frontend/proto/alert_pb'

type AlertsTableOrNull = null | AlertsDataTable

export default defineComponent({
  name: 'AlertsTable',
  props: {
    alertId: {
      type: String,
      required: false,
    },
  },
  components: { AlertError, CenteredSpinner, CeDataTable },
  setup() {
    return {
      TOP_BAR_HEIGHT,
      MSG_HAS_FAILED,
      headers,
      alertStore: useAlertStore(),
      bgColor: WHITE.hex,
      alerts: [] as Alert[],
      serverItemsLength: 0,
    }
  },
  data() {
    return shallowReactive({
      visibleHeaders: createInitialVisibleHeaders(headers), // TODO(rafael): persist it.
      isFirstLoading: false,
      isLoading: false,
      hasLoadingFailed: false,
      options: createInitialOptions(),
      search: '',
      filters: createInitialFilters(),
      table: null as AlertsTableOrNull,
    })
  },
  computed: {
    hasTouchedFilters(): boolean {
      return hasAtLeastOneFilterValue(this.filters)
    },
    qualifiedAlertId(): undefined | string {
      return AlertHelper.regenerateUnqualifiedId(this.alertId)
    },
    tableDependencies() {
      return [this.filters, this.options]
    },
  },
  watch: {
    tableDependencies: {
      immediate: true,
      handler: async function (): Promise<void> {
        await this.fetchData()
        this.generateDataTable()
      },
    },
    qualifiedAlertId() {
      this.generateDataTable()
    },
  },
  created(): void {
    this.alertStore.interval.subscribe(this.fetchData)
  },
  beforeUnmount(): void {
    this.alertStore.interval.unsubscribe(this.fetchData)
  },
  methods: {
    generateDataTable(): void {
      this.table = createAlertsTable(this.alerts, {
        hasFiltersOrSearch: this.hasTouchedFilters || Boolean(this.search),
        highlightedRowId: this.qualifiedAlertId,
      })
    },
    async handleNewSearch(): Promise<void> {
      await this.fetchData()
      this.generateDataTable()
    },
    handleNewFilters(newValue: CustomFilters): void {
      this.filters = newValue
    },
    handleResetFilters(): void {
      this.filters = createPristineFilters()
    },
    handleNewOptions(newValue: CustomOptions): void {
      this.options = newValue
    },
    handleNewVisibleHeaders(newValue: VisibleHeaders): void {
      this.visibleHeaders = newValue
    },
    async fetchData(): Promise<void> {
      if (this.table === null) {
        this.isFirstLoading = true
      } else {
        this.isLoading = true
      }

      this.hasLoadingFailed = false

      try {
        const { alerts, totalCount } =
          await this.$services.alertService.listAlerts(
            createRequest(this.options, this.filters, this.search)
          )

        this.alerts = alerts
        this.serverItemsLength = totalCount
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('AlertsTable.fetchData: %o', err)
      } finally {
        this.isFirstLoading = false
        this.isLoading = false
      }
    },
  },
})
</script>
