<template>
  <div class="c-VoltageMappingLeftPanel px-6">
    <!-- Title -->
    <ce-title class="pb-6">{{ title }}</ce-title>

    <!-- SubTitle -->
    <span class="d-block subtitle-1 font-weight-medium">
      {{ subTitle }}
    </span>

    <!-- Period assessed (months) -->
    <div class="mt-6">
      <ce-slider
        data-test="slider-months"
        v-model="months.value"
        :title="months.title"
        :tick-labels="months.tickLabels"
        :min="0"
        :max="2"
        :step="1"
      />
    </div>

    <!-- Magnitude -->
    <div class="mt-6">
      <ce-slider
        data-test="slider-magnitude"
        v-model="magnitudeDeviation.value"
        :title="magnitudeDeviation.title"
        :tick-labels="magnitudeDeviation.tickLabels"
        :min="4"
        :max="8"
        :step="1"
      />
    </div>

    <!-- Meter / Time -->
    <div class="mt-6">
      <ce-slider
        data-test="slider-meter-time"
        v-model="metersOrTime.value"
        :title="metersOrTime.title"
        :tick-labels="metersOrTime.tickLabels"
        :min="10"
        :max="90"
        :step="20"
      >
        <v-radio-group
          v-model="metersOrTime.selectedOption"
          color="primary"
          inline
        >
          <v-radio
            v-for="option in metersOrTime.radioOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            class="ml-4"
          ></v-radio>
        </v-radio-group>
      </ce-slider>
    </div>

    <!-- Map Legend -->
    <div class="legend mt-6">
      <color-legend
        :title="legend.title"
        :colors="legend.colors"
        :legend-labels="legend.labels"
        :width="25"
        :selected-color="voltageMapStore.selectedZone.voltage"
      >
        <!-- Tooltip -->
        <ce-tooltip type="info">
          <!-- Tooltip content -->
          <div
            class="text-caption text-black"
            style="width: 12.5rem"
            v-html="description"
          ></div>
        </ce-tooltip>
      </color-legend>
    </div>
  </div>
</template>

<script lang="ts">
import CeTooltip from '@/components/CeTooltip.vue'
import CeTitle from '@/components/CeTitle.vue'
import CeSlider from '@/components/others/CeSlider.vue'
import ColorLegend from '@/components/others/ColorLegend.vue'
import { MapLayerId } from '@/config/types'
import { HEATMAP } from '@/constants/colorPalette'
import { VoltageMappingColors } from '@/constants/colors'
import { PeriodAssessed, PresenceBy } from '@/constants/voltageMapping'
import { Timespan } from 'rfs/frontend/proto/hosting_pb'
import { useGridMapStore } from '@/stores/gridMap'
import { useVoltageMapStore, VoltageMappingProps } from '@/stores/voltageMap'
import { mapActions, mapStores } from 'pinia'
import { defineComponent } from 'vue'
import { TickLabels } from '@/types/vuetify3'

type RadioOptions = {
  label: string
  value: PresenceBy
}

interface SliderProps {
  title: string
  tickLabels: TickLabels
  value: number
}

interface SliderPropsWithRadio extends SliderProps {
  selectedOption: PresenceBy
  radioOptions: RadioOptions[]
}

interface VoltageMappingData {
  months: SliderProps
  magnitudeDeviation: SliderProps
  metersOrTime: SliderPropsWithRadio
  legend: {
    title: string
    colors: string[]
    labels: string[]
  }
  description: string
}

export default defineComponent({
  name: 'VoltageMappingLeftPanel',
  components: {
    CeTitle,
    CeSlider,
    CeTooltip,
    ColorLegend,
  },
  setup() {
    return {
      title: 'Voltage Mapping',
      subTitle: 'Zonal Deviation',
    }
  },
  data(): VoltageMappingData {
    return {
      months: {
        title: 'Period assessed (months)',
        tickLabels: { 0: '3', 1: '6', 2: '12' },
        value: 0,
      },

      magnitudeDeviation: {
        title: 'Magnitude deviation (±)',
        tickLabels: { 4: '4%', 5: '5%', 6: '6%', 7: '7%', 8: '8%' },
        value: 5,
      },

      metersOrTime: {
        title: 'Presence by',
        tickLabels: { 10: '10%', 30: '30%', 50: '50%', 70: '70%', 90: '90%' },
        value: 10,
        selectedOption: PresenceBy.METERS,
        radioOptions: [
          {
            label: 'Meters',
            value: PresenceBy.METERS,
          },
          {
            label: 'Time',
            value: PresenceBy.TIME,
          },
        ],
      },

      legend: {
        title: 'Amount of deviation',
        colors: HEATMAP.map((c) => c.rgb ?? VoltageMappingColors.fill.rgb),
        labels: ['-30%', '0%', '+30%'],
      },

      description: `The <i>Amount of deviation</i> visualized is determined by either the percentage of meters or time where deviation was detected during the specified lookback period (<i>Period assessed</i>), where every instance of voltage above or below the <i>Magnitude deviation</i> threshold was logged as a deviation.
      <br />
      For example, the default map depicts the amount of time that 10% of meters within a zone exhibited voltage deviations of ±5% in the past 3 months.`,
    }
  },
  created() {
    this.updateSwitches([{ id: MapLayerId.VOLTAGE_MAPPING, newValue: true }])
  },
  methods: {
    ...mapActions(useGridMapStore, ['updateSwitches']),
  },
  watch: {
    voltageDeviationProps: {
      immediate: true,
      handler(newProps: VoltageMappingProps) {
        this.voltageMapStore.getVoltageDeviations(newProps)
      },
    },
  },
  computed: {
    ...mapStores(useVoltageMapStore),
    mapMonthsSlider(): Timespan {
      const MONTHS_IDX = this.months.value
      return PeriodAssessed[MONTHS_IDX]
    },
    voltageDeviationProps(): VoltageMappingProps {
      return {
        timespan: this.mapMonthsSlider,
        magnitudeDeviation: this.magnitudeDeviation.value,
        metersOrTime: this.metersOrTime.value,
        metersOrTimeOption: this.metersOrTime.selectedOption,
      }
    },
  },
  beforeUnmount() {
    this.updateSwitches([{ id: MapLayerId.VOLTAGE_MAPPING, newValue: false }])
  },
})
</script>

<style lang="scss">
.c-VoltageMappingLeftPanel {
  > div {
    padding-bottom: 48px;
  }
  .v-input--radio-group--row,
  .v-input--radio-group__input {
    flex-wrap: nowrap;
  }
}
</style>
