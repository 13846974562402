import { MapLayerId, RittaConfig } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import {
  COMMON_SIZE_DIVISOR_14,
  ICON_OFFSET_RIGHT,
  TILE_SIZE,
  ZOOM_DEFAULT,
} from '@/model/map/constants'
import { ChargerHelper } from '@/model/resource/charger/helper'
import { Device_Access as DeviceAccess, Genesis } from 'rfs/pb/resource_pb'
import { Services } from '@/services'
import { Feature } from '@/services/tile.service'
import { ApplicationMapLayer } from './catalog'
import {
  CustomTileLayer,
  getTileResponseLayers,
  scalingIconProps,
} from './extensions'
import iconEvCharger from '@/assets/imgs/albert_ic_evse--black.svg'

const ID = MapLayerId.EV_CHARGER

function mapManagerLayer(_config: Readonly<RittaConfig>, services: Services) {
  return new CustomTileLayer({
    id: ID,
    minZoom: ZOOM_DEFAULT - 1,
    maxZoom: ZOOM_DEFAULT - 1,
    tileSize: TILE_SIZE,
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.CHARGER, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: {
          getIcon: getChargerIcon,
          ...scalingIconProps(ICON_OFFSET_RIGHT, COMMON_SIZE_DIVISOR_14),
        },
      })
    },
  })
}

export const EvCharger: ApplicationMapLayer = {
  id: ID,
  label: 'EVSE',
  icon: iconEvCharger,
  infoWindow: {
    infoColumn: (f) => ChargerHelper.infoWindowInfoColumn(f.resource!),
  },
  mapManagerLayer,
}

function getChargerIcon({ resource }: Feature) {
  // Test for detected chargers first because that is more important to note than public/private.
  if (resource.record?.genesis === Genesis.DETECTION) {
    return ResourceType.CHARGER + '-DETECTION'
  } else if (resource.device?.access === DeviceAccess.PUBLIC) {
    return ResourceType.CHARGER + '-PUBLIC'
  } else {
    return ResourceType.CHARGER
  }
}
