<template>
  <div class="c-ColorGradientLegend">
    <div
      class="d-flex align-center justify-space-around mr-4"
      :style="{
        width: '292px',
        height: '40px',
        border: `1px solid ${GRAY_COOL_200.hex}`,
        background: `rgba(225, 227, 231, 0.2)`, // GRAY_COOL_100 with 20% opacity
        backdropFilter: 'blur(10px)',
        borderRadius: '4px',
        color: 'black',
      }"
    >
      <span v-if="type === 'gradient' && minLabel" class="text-caption">{{
        minLabel
      }}</span>
      <span
        v-if="type === 'gradient'"
        :style="{
          width: '200px',
          height: '16px',
          background: `linear-gradient(to right, ${colors.join(', ')})`,
        }"
      ></span>
      <span v-if="type === 'gradient' && maxLabel" class="text-caption">{{
        maxLabel
      }}</span>

      <div v-if="type === 'discrete'" class="d-flex align-center">
        <span class="text-caption">{{ title }}</span>
        <span v-for="(color, index) in colors" :key="index" class="legend-item">
          <span
            :style="{
              width: '16px',
              height: '16px',
              background: color,
              margin: '0 8px',
            }"
          ></span>
          <span v-if="labels" class="text-caption">{{ labels[index] }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { GRAY_COOL_200 } from '@/constants/colors'

export default defineComponent({
  name: 'ColorGradientLegend',
  props: {
    title: {
      type: String,
      required: false,
    },
    colors: {
      type: Array as PropType<string[]>,
      required: true,
    },
    labels: {
      type: Array as PropType<string[]>,
      required: false,
    },
    minLabel: {
      type: String,
      required: false,
    },
    maxLabel: {
      type: String,
      required: false,
    },
    type: {
      type: String as PropType<'gradient' | 'discrete'>,
      required: true,
    },
  },
  setup() {
    return {
      GRAY_COOL_200,
    }
  },
})
</script>

<style lang="scss">
.c-ColorGradientLegend {
  .legend-item {
    display: flex;
    align-items: center;
  }
}
</style>
