<template>
  <v-select
    :model-value="fields.value"
    :items="fields.items"
    :menu-props="{ activatorProps: { 'aria-label': headerTitle } }"
    color="secondary"
    clearable
    @update:model-value="emitNewValue"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { FilterDropDown } from '@/model/tables/DataTable'

export default defineComponent({
  name: 'FilterDropDown',
  props: {
    fields: {
      type: Object as PropType<FilterDropDown>,
      required: true,
    },
    headerTitle: {
      type: String,
      required: false,
    },
  },
  methods: {
    emitNewValue(newValue: any): void {
      const newFields: FilterDropDown = {
        ...this.fields,
        value: newValue ?? null,
      }
      this.$emit('new-fields', newFields)
    },
  },
})
</script>
