<template>
  <div
    class="py-1 px-3 position-relative min-height-100 d-flex flex-column"
    style="border-right: 1px solid white"
    :style="{ backgroundColor }"
  >
    <!-- Title -->
    <span
      class="d-block text-caption font-weight-bold pb-2"
      style="white-space: nowrap"
      :style="{ color: titleColor }"
    >
      {{ title }}
    </span>

    <!-- Content -->
    <div class="position-relative" style="flex: 1">
      <!-- Loading -->
      <centered-spinner v-if="isLoading" :size="20" />

      <!-- Slot: default -->
      <slot v-else />
    </div>

    <!-- Refreshing -->
    <v-progress-linear
      v-if="isRefreshing"
      color="primary"
      location="bottom"
      indeterminate
      absolute
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BLACK, BLUE6, GREY10 } from '@/constants/colors'
import CenteredSpinner from '@/components/CenteredSpinner.vue'

export default defineComponent({
  name: 'StatusBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRefreshing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CenteredSpinner },
  setup() {
    return {
      backgroundColor: BLUE6.hex,
      titleColor: GREY10.hex,
      bodyColor: BLACK.hex,
    }
  },
})
</script>
