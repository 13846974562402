<template>
  <div class="c-TemporaryWaypointForm">
    <!-- Table -->
    <ce-data-table
      v-if="table.rows.length"
      :headers
      :table
      :bg-color="bgColor"
      :show-pagination="false"
      :sticky-offset="TOP_BAR_HEIGHT"
      sticky
      dense
      border-bottom
      class="pb-4"
    >
      <!-- Custom <th> content for the unit -->
      <template v-slot:[`header.${Columns.TARGET_VALUE}`]="{ column }">
        <div class="d-flex align-center">
          <span class="pr-1">{{ column.title }}</span>

          <!-- Tooltip -->
          <ce-tooltip
            v-if="targetValueTooltip"
            :text="targetValueTooltip"
            type="info"
          />
        </div>
      </template>

      <!-- Custom rows -->
      <template v-slot:item="{ item, index }">
        <automatic-control-row-edit
          v-if="indexOfWaypointToEdit === index && formFields"
          :index="index"
          :metric="metric"
          v-model:date="formFields.date"
          v-model:start-time="formFields.startTime"
          v-model:end-time="formFields.endTime"
          v-model:target-value="formFields.targetValue"
          :validation="formValidation"
          :min-date="now"
          :max-date="maxDate"
          @cancel="cancelEdit"
          @save="saveEditted"
        />
        <automatic-control-row
          v-else
          :row="item"
          :is-disabled="isSubmitting || isRowBeingEditted"
          @edit="() => handleClickEdit(index)"
          @delete="() => handleClickDelete(index)"
        />
      </template>
    </ce-data-table>

    <!-- Overlap errors -->
    <alert-error
      data-test="overlap"
      v-for="(err, index) of overlapErrors"
      :key="index"
      class="mb-2"
    >
      {{ err }}
    </alert-error>

    <!-- Server Failed -->
    <alert-error v-if="failedMsg" data-test="failedMsg" class="mb-2">
      {{ failedMsg }}
    </alert-error>

    <!-- Action buttons -->
    <form-actions
      ref="formActions"
      :metric
      :deviceType
      :isSubmitting
      :is-editting="isRowBeingEditted"
      :isTouched
      :hasEqualWaypoints
      :has-overlaps="Boolean(overlapErrors.length)"
      @add="addNewWaypoint"
      @cancel="$emit('cancel')"
      @submit="openSubmitConfirmDialog"
    >
      <!-- Operating Envelope -->
      <envelope-status-radio-group
        v-if="currentOEStatus !== undefined && showEnvelopeStatusForm"
        v-model="selectedRadioOption"
        :currentOEStatus
      />
    </form-actions>

    <!-- Modal Confirmation -->
    <dialog-confirm
      v-if="dialogState"
      :title="dialogState.title"
      :body="dialogState.body"
      @cancel="() => closeDialog('cancel')"
      @confirm="() => closeDialog('confirm')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { DateTime, Interval } from 'luxon'
import { Timestamp } from '@/services/timestamp_pb'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { NEUTRAL_50 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { TOP_BAR_HEIGHT } from '@/constants/topBar'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import {
  Metric,
  areWaypointsEqual,
  computeAllowedIntervalAndFilter,
  validateTemporaryOverlapping,
  sortByStartTime,
  getSubmitScheduleConfirmationMessage,
  confirmationTitle,
  changeRouteMessage,
  getScheduleSuccessMessage,
  submitTemporaryWaypointsInChunks,
} from '@/model/control/waypoint'
import {
  Columns,
  getHeaderTooltip,
  createAutomaticControlDataTable,
  createAutomaticControlHeaders,
} from '@/model/control/WaypointDataTable'
import { WaypointFormFields } from '@/model/control/waypointFormFields'
import AlertError from '@/components/common/AlertError.vue'
import CeTooltip from '@/components/CeTooltip.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import AutomaticControlRow from '@/components/control/AutomaticControlRow.vue'
import AutomaticControlRowEdit from '@/components/control/AutomaticControlRowEdit.vue'
import FormActions from '@/components/control/FormActions.vue'
import DialogConfirm from '@/components/common/DialogConfirm.vue'
import EnvelopeStatusRadioGroup, {
  RADIO_OPTION_ACTIVATE,
  RADIO_OPTION_RETAIN,
  type RadioOption,
} from '@/components/control/EnvelopeStatusRadioGroup.vue'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import { Policy, Params as PolicyParams } from 'rfs/control/proto/policy_pb'
import type { Device, DeviceType } from 'rfs/control/proto/model_pb'

type DialogProps = {
  title: string
  body: string
  confirmCb: Function
  cancelCb?: Function
}

const DEFAULT_ERR_MSG = 'Something went wrong.'

// NOTE(rafael): These are the metrics that this component supports for now.
export const validMetrics = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS,
  Metric.METRIC_SOC_PERCENT,
  Metric.METRIC_SOC_WATT_HOURS,
  Metric.METRIC_ACTIVE_POWER_WATTS,
]

export default defineComponent({
  name: 'TemporaryWaypointForm',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    futureInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    metric: {
      type: Number as PropType<Metric>,
      required: true,
      validator(value, _props) {
        return validMetrics.includes(value as Metric)
      },
    },
    deviceType: {
      type: Number as PropType<DeviceType>,
      required: true,
    },
    initialWaypoints: {
      type: Array as PropType<Waypoint[]>,
      required: false,
      default: () => [],
    },
    powerRating: {
      type: Number,
      required: false,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
  },
  components: {
    AlertError,
    CeTooltip,
    CeDataTable,
    AutomaticControlRow,
    AutomaticControlRowEdit,
    FormActions,
    DialogConfirm,
    EnvelopeStatusRadioGroup,
  },
  setup() {
    return {
      TOP_BAR_HEIGHT,
      TEXT_NO_VALUE,
      Columns,
      globalSnackbarStore: useGlobalSnackbar(),
      bgColor: NEUTRAL_50.hex,
    }
  },
  data() {
    const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
      this.futureInterval,
      this.initialWaypoints
    )

    return shallowReactive({
      isSubmitting: false,
      hasSubmittingFailed: false,
      failedMsg: null as null | string,
      selectedRadioOption: RADIO_OPTION_RETAIN as RadioOption,
      isAddingNewWaypoint: false,
      isTouched: false,
      indexOfWaypointToEdit: undefined as undefined | number,
      dialogState: null as null | DialogProps,
      allowedIntervalForWaypoints: allowedInterval,
      waypoints: filtered,
      //
      formFields: undefined as
        | undefined
        | ReturnType<typeof shallowReactive<WaypointFormFields>>,
      formValidation: undefined as
        | undefined
        | ReturnType<typeof WaypointFormFields.prototype.validate>,
    })
  },
  computed: {
    now(): DateTime {
      return this.futureInterval.start
    },
    minStart(): DateTime {
      return this.allowedIntervalForWaypoints.start
    },
    maxDate(): DateTime {
      return this.futureInterval.end
    },
    table() {
      return createAutomaticControlDataTable({
        metric: this.metric,
        waypoints: this.waypoints,
      })
    },
    headers() {
      return createAutomaticControlHeaders({ metric: this.metric })
    },
    targetValueTooltip() {
      return getHeaderTooltip(this.metric, this.powerRating)
    },
    overlapErrors(): string[] {
      return validateTemporaryOverlapping(this.waypoints)
    },
    hasEqualWaypoints(): boolean {
      return areWaypointsEqual(this.initialWaypoints, this.waypoints)
    },
    isRowBeingEditted(): boolean {
      return this.indexOfWaypointToEdit !== undefined
    },
    showEnvelopeStatusForm(): boolean {
      const cond1 = this.currentOEStatus !== undefined
      const cond2 = this.isAddingNewWaypoint && this.waypoints.length - 1
      const cond3 = !this.isAddingNewWaypoint && this.waypoints.length
      return Boolean(cond1 && (cond2 || cond3))
    },
  },
  watch: {
    initialWaypoints(): void {
      this.resetState()
    },
  },
  mounted(): void {
    this.focusBtnAddWaypoint()
  },
  methods: {
    shouldChangeRoute(next: Function): void {
      if (this.isTouched) {
        this.dialogState = {
          title: confirmationTitle,
          body: changeRouteMessage,
          cancelCb: () => next(false),
          confirmCb: () => next(),
        }
      } else {
        next()
      }
    },
    blockFetchLatestSchedule(): boolean {
      return this.isTouched || this.isRowBeingEditted
    },
    handleClickEdit(index: number): void {
      const waypoint = this.waypoints?.[index]

      if (!waypoint) throw new Error('waypoint expected')

      this.indexOfWaypointToEdit = index

      this.formFields = shallowReactive(
        new WaypointFormFields({
          waypoint,
          metric: this.metric,
          deviceType: this.deviceType,
          powerRating: this.powerRating,
          config: {
            getNow: () => this.now,
            getMinStart: () => this.minStart,
            getMaxEnd: () => this.maxDate,
          },
        })
      )

      this.formValidation = undefined
    },
    cancelEdit(): void {
      // When the user chooses to cancel the addition of a new Waypoint,
      // delete the new Waypoint from the list of Waypoints since
      // it's an empty Waypoint.
      if (this.isAddingNewWaypoint) {
        const indexOfLastWaypoint = this.waypoints.length - 1
        const newWaypoints = [...this.waypoints]
        newWaypoints.splice(indexOfLastWaypoint, 1)
        this.waypoints = newWaypoints
        this.isAddingNewWaypoint = false
      }

      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined

      this.focusBtnAddWaypoint()
    },
    saveEditted(): void {
      if (!this.formFields) {
        throw new Error(
          'TemporaryWaypointForm.saveEditted: unavailable formFields'
        )
      }

      const validation = this.formFields.validate()

      if (!validation.isValid) {
        this.formValidation = validation
        return
      }

      const edittedWaypoint = this.formFields.generateWaypoint()

      const newWaypoints = [...this.waypoints]

      if (this.indexOfWaypointToEdit === undefined) {
        throw new Error(
          'TemporaryWaypointForm.saveEditted: unexpected "undefined" index'
        )
      }

      newWaypoints[this.indexOfWaypointToEdit] = edittedWaypoint

      this.waypoints = newWaypoints
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
      this.isAddingNewWaypoint = false

      this.handleTouchedWaypoints()

      this.focusBtnAddWaypoint()
    },
    focusBtnAddWaypoint(): void {
      ;(this.$refs.formActions as any)?.focusBtnAdd?.()
    },
    addNewWaypoint(): void {
      this.isAddingNewWaypoint = true

      const pristineWaypoint = new Waypoint({ targetMetric: this.metric })

      const newWaypoints = [...this.waypoints, pristineWaypoint]

      this.waypoints = newWaypoints

      const lastIndex = newWaypoints.length - 1

      this.$nextTick(() => {
        this.handleClickEdit(lastIndex)
      })
    },
    handleClickDelete(index: number): void {
      const newWaypoints = [...this.waypoints]
      newWaypoints.splice(index, 1)
      this.waypoints = newWaypoints
      this.handleTouchedWaypoints()
    },
    async handleTouchedWaypoints(): Promise<void> {
      this.isTouched = true

      if (this.overlapErrors.length) return

      this.$emit('new-waypoints', sortByStartTime(this.waypoints))
    },
    resetState(): void {
      const { allowedInterval, filtered } = computeAllowedIntervalAndFilter(
        this.futureInterval,
        this.initialWaypoints
      )

      this.allowedIntervalForWaypoints = allowedInterval
      this.waypoints = filtered
      this.isTouched = false
      this.indexOfWaypointToEdit = undefined
      this.formFields = undefined
      this.formValidation = undefined
    },
    closeDialog(opt: 'cancel' | 'confirm'): void {
      if (opt === 'confirm') {
        this.dialogState?.confirmCb()
      } else if (opt === 'cancel') {
        this.dialogState?.cancelCb?.()
      } else {
        throw new Error('TemporaryWaypointForm.closeDialog: unexpected option')
      }

      this.dialogState = null
    },
    openSubmitConfirmDialog(): void {
      this.dialogState = {
        title: confirmationTitle,
        body: getSubmitScheduleConfirmationMessage({
          metric: this.metric,
          deviceType: this.deviceType,
          hasWaypoints: !!this.waypoints.length,
        }),
        confirmCb: this.submitNewTemporarySchedule,
      }
    },
    async submitNewTemporarySchedule(): Promise<void> {
      if (this.overlapErrors.length) return

      this.$emit('submitting', true)
      this.isSubmitting = true
      this.hasSubmittingFailed = false
      this.failedMsg = null

      try {
        // Operating Envelope.
        await this.submitOperatingEnvelope()

        // Chargers.
        if (this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS) {
          await submitTemporaryWaypointsInChunks(
            this.$services,
            this.groupId,
            this.initialWaypoints,
            this.waypoints
          )
        } else {
          // All other metrics.
          await this.$services.control.submitWaypoints({
            groupId: this.groupId,
            startTime: Timestamp.fromDateTime(
              this.allowedIntervalForWaypoints.start
            ),
            endTime: Timestamp.fromDateTime(
              this.allowedIntervalForWaypoints.end
            ),
            waypointsList: {
              waypoints: sortByStartTime(this.waypoints),
            },
          })
        }

        await this.$services.control.setGroupPolicy({
          policy: Policy.AUTOMATIC_CONTROL_EVENT,
          // NOTE: even blank, the server requires the params object to be submitted.
          params: new PolicyParams({
            paramsOneof: { case: 'automaticControlEvent', value: {} },
          }),
          ids: [this.groupId],
        })

        this.handleSubmitSuccess()
      } catch (err) {
        this.hasSubmittingFailed = true
        const failedMsg = (err as any).message || DEFAULT_ERR_MSG
        this.failedMsg = failedMsg
        this.globalSnackbarStore.openSnackbar(failedMsg, 'error')
        console.error(
          'TemporaryWaypointForm.submitNewTemporarySchedule: %o',
          err
        )
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
    async submitOperatingEnvelope(): Promise<void> {
      // Do nothing.
      if (
        this.currentOEStatus === undefined ||
        this.selectedRadioOption === RADIO_OPTION_RETAIN
      ) {
        return
      }

      if (!this.devices.length) throw new Error('no devices available')

      // Submit.
      await this.$services.control.updateDevices({
        updates: this.devices.map((d) => {
          return {
            deviceId: d.id,
            oeEnabled: this.selectedRadioOption === RADIO_OPTION_ACTIVATE,
          }
        }),
      })
    },
    handleSubmitSuccess(): void {
      this.globalSnackbarStore.openSnackbar(
        getScheduleSuccessMessage({
          metric: this.metric,
          deviceType: this.deviceType,
        }),
        'info'
      )
      this.$emit('success')
    },
  },
})
</script>
