import { groupBy } from 'lodash-es'
import { GoogleMapsOverlay } from '@deck.gl/google-maps'
import { Layer } from '@deck.gl/core'
import { ResourceType } from '@/constants/resourceType'
import { Resource } from 'rfs/pb/resource_pb'
import {
  ResourcesTimeSeries,
  mapCatalogMap,
} from './FeederOperationsMapCatalog'
import { makeSize } from '@/model/map/gmaps'
import type {
  InfoWindowHandler,
  LatLng,
  MapManagerOptions,
} from '@/model/map/types'

/**
 * OperationsMapManager is a class that manages the map layers for the operations feeder map.
 */
export class OperationsMapManager {
  private options: MapManagerOptions
  private infoWindow = {
    handler: <null | InfoWindowHandler>null,
    instance: <null | google.maps.InfoWindow>null,
  }

  private deckglGmapsOverlay = new GoogleMapsOverlay({
    onHover: (info) => {
      // Configure the mouse cursors.
      // The `object` property is non-null when hovering over a pickable object.
      const draggableCursor = info.object ? 'pointer' : 'grab'
      this.gmap.setOptions({ draggableCursor })
      // Info window.
      this.infoWindow.handler?.(
        info,
        (el, coordinate) => this.openInfoWindow(el, coordinate),
        () => this.closeInfoWindow()
      )
    },
  })

  private activeMapLayers: Map<ResourceType, Layer> = new Map()

  constructor(private gmap: google.maps.Map, options?: MapManagerOptions) {
    this.options = options ?? {}
    this.deckglGmapsOverlay.setMap(this.gmap)
  }

  get activeResourceTypes(): ResourceType[] {
    return Array.from(this.activeMapLayers.keys())
  }

  //  Create a map layer with the given resource type.
  private createMapLayer(
    resourceType: ResourceType,
    resourcesData: ResourcesTimeSeries
  ): Layer | null {
    if (!resourcesData.resources) return null

    const getLayer = mapCatalogMap.get(resourceType)
    if (getLayer) {
      return getLayer(resourcesData)
    } else {
      return null
    }
  }

  // Update the map layer with the given resource type.
  private refreshMapLayers() {
    this.deckglGmapsOverlay.setProps({
      layers: [...this.activeMapLayers.values()],
    })
  }

  //  Zoom to the bounds of the given resources.
  public zoomToResourcesBounds(resources: Resource[]) {
    const coordinates = resources.flatMap((r) =>
      (r.location?.lineString ?? []).map((ls) => new google.maps.LatLng(ls))
    )
    const bounds = new google.maps.LatLngBounds()
    for (const coord of coordinates) {
      bounds.extend(coord)
    }
    this.gmap.fitBounds(bounds)
  }

  public zoomToResource(resource: Resource) {
    const coordinates = resource.location?.point
    if (!coordinates) return
    this.gmap.setCenter(new google.maps.LatLng(coordinates))
  }

  public setLayers(allResourceData: ResourcesTimeSeries) {
    this.activeMapLayers = new Map()

    // Fix this to be iterated over the catalog
    // Group the resources by type
    const resourcesGroupedByType = groupBy(
      allResourceData.resources,
      (r) => r.type
    )
    const resourceTypeOrder: string[] = [...mapCatalogMap.keys()]
    const resourceTypesArray = Object.entries(resourcesGroupedByType).sort(
      ([a], [b]) => resourceTypeOrder.indexOf(a) - resourceTypeOrder.indexOf(b)
    )

    // Map over each type and create a layer for it (or ignore)
    resourceTypesArray.forEach(([resourceType, resourcesByType]) => {
      const resourcesData: ResourcesTimeSeries = {
        ...allResourceData,
        resources: resourcesByType,
      }
      const mapLayer = this.createMapLayer(
        resourceType as ResourceType,
        resourcesData
      )

      if (mapLayer) {
        this.activeMapLayers.set(resourceType as ResourceType, mapLayer)
      }
    })

    // Update deckgl
    this.refreshMapLayers()
  }

  /**
   * Sets up a handler function that manages a info window instance on the map.
   */
  setUpInfoWindow(handler: InfoWindowHandler): void {
    this.infoWindow.handler = handler
  }

  private openInfoWindow(el: HTMLElement, coordinate: LatLng): void {
    const infoWindow = new google.maps.InfoWindow({
      content: el,
      position: coordinate,
      pixelOffset: makeSize(this.options.infoWindowOffset),
      disableAutoPan: true,
    })

    infoWindow.open({ shouldFocus: false, map: this.gmap })

    this.infoWindow.instance = infoWindow
  }

  private closeInfoWindow(): void {
    if (this.infoWindow.instance) {
      this.infoWindow.instance.close()
      this.infoWindow.instance = null
    }
  }
}
