<template>
  <div class="c-RoundedBordersContainer">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * This component creates a wrapper for any value (text, HTML components,
 * Vue components) with rounded corners.
 *
 * Example:
 *
 * <rounded-borders-container>
 *  Your text here (or HTML component, or Vue component)
 * </rounded-borders-container>
 */
export default defineComponent({
  name: 'RoundedBordersContainer',
})
</script>

<style lang="scss">
.c-RoundedBordersContainer {
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0.75rem 1rem;
}
</style>
