import { PolygonLayer } from '@deck.gl/layers'

import { MapLayerId } from '@/config/types'
import { InfoColumnAllProps } from '@/components/InfoColumn'
import { HEATMAP, HEATMAP_OUTLINE } from '@/constants/colorPalette'
import { GREY5, VoltageMappingColors } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { MapManager } from '@/model/map'
import { MapLayerFeature, MapManagerLayer } from '@/model/map/types'
import { Services } from '@/services'
import { useVoltageMapStore } from '@/stores/voltageMap'
import { rgbStringToRaw } from '@/utils/colors'
import { Format } from '@/utils/format'
import { createFeederRoute } from '@/utils/router/create'
import { getUnqualifiedId } from '@/model/resource'
import {
  TileResponse,
  TileResponse_Feature as TileResponseFeature,
} from 'rfs/frontend/proto/tile_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, toPosition } from './extensions'

// Takes a voltage and maps it to a heatmap hex value
function voltageToHeatmap(v: number, overVoltage: boolean): number {
  // | 0 | 0 - 7.5% | 7.5 - 15% | 15 - 22.5% | 22.5 - 30% | +30%
  if (v === 0) {
    return 5
  }
  if (v <= 7.5) {
    return overVoltage ? 6 : 4
  }
  if (v <= 15) {
    return overVoltage ? 7 : 3
  }
  if (v <= 22.5) {
    return overVoltage ? 8 : 2
  }
  if (v < 30) {
    return overVoltage ? 9 : 1
  }
  if (v >= 30) {
    return overVoltage ? 10 : 0
  } else {
    return 5
  }
}

function updatePolygon(resourceId: string): number {
  const store = useVoltageMapStore()
  const { prevalenceOver, prevalenceUnder } = store
  const vOver = prevalenceOver?.[resourceId] ?? 0
  const vUnder = prevalenceUnder?.[resourceId] ?? 0
  const v = vOver > vUnder ? vOver : vUnder
  const overVoltage = vOver > vUnder
  return voltageToHeatmap(v, overVoltage)
}

function getPolygon(poly: MapLayerFeature): [number, number][] {
  const coordinates =
    poly.resource?.downline?.display?.[0].polygon.map(toPosition) ?? []
  return coordinates
}

function voltageMappingMapManagerLayer(
  id: MapLayerId,
  services: Services,
  mapManager: MapManager
): MapManagerLayer {
  const newUpdateTriggers = () => {
    const now = Date.now()
    return {
      getLineColor: now,
      getFillColor: now,
    }
  }
  const updateTriggers = () =>
    mapManager.updateLayers([{ layerId: id, newProps: newUpdateTriggers() }])

  const store = useVoltageMapStore()

  store.$subscribe(updateTriggers)
  return new CustomTileLayer({
    id,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 9,
    pickable: true,
    onHover(pickingInfo) {
      const zone: Resource = pickingInfo.object?.resource
      if (zone) {
        const id = getUnqualifiedId(zone.id)
        const fillColorIdx = updatePolygon(id)
        store.setSelectedZone({
          zoneId: id,
          voltage: HEATMAP[fillColorIdx].rgb ?? VoltageMappingColors.fill.rgb,
        })
      } else {
        store.setSelectedZone({
          zoneId: null,
          voltage: null,
        })
      }
    },
    getTileData: async (props) => {
      const reqZones = services.queryService.getResourcesByType(
        ResourceType.ZONE_VOLTAGE
      )
      const reqFeeders = services.tileService.getTileResources(
        ResourceType.FEEDER,
        props
      )
      const data = await Promise.all([reqZones, reqFeeders])

      // LPEA Only - If uses Feeders are empty use Zone Voltage
      if (data[1]?.layers?.length === 0) {
        const features = data[0].map((r) => ({ resource: r }))
        return new TileResponse({ layers: [{ features }] })
      } else {
        return data[1]
      }
    },
    renderSubLayers(props) {
      if (props.data == null) {
        return null
      }
      return new PolygonLayer(props as any, {
        id: `${id}--0`,
        data: props.data.layers[0].features.reduce((features, f) => {
          // change the order of the features so that the outline is on top
          // when a zone is selected
          const id = getUnqualifiedId(f.resource?.id ?? '')
          if (store.selectedZone?.zoneId === id) {
            features.push(f)
          } else {
            features.unshift(f)
          }
          return features
        }, [] as TileResponseFeature[]),
        pickable: true,
        filled: true,
        getPolygon,
        getElevation: (_) => 0,
        getLineColor: (d) => {
          const id = getUnqualifiedId(d.resource.id)
          const fillColorIdx = updatePolygon(id)
          const selectedZoneId = store.selectedZone?.zoneId
          const color =
            selectedZoneId === id
              ? GREY5.rgb
              : HEATMAP_OUTLINE[fillColorIdx].rgb
          return color
            ? rgbStringToRaw(color, 255)
            : VoltageMappingColors.fill.rgbRaw
        },
        getFillColor: (d) => {
          const id = getUnqualifiedId(d.resource.id)
          const fillColorIdx = updatePolygon(id)
          const color = HEATMAP[fillColorIdx].rgb
          return color
            ? rgbStringToRaw(color, 200)
            : VoltageMappingColors.outline
        },
        getLineWidth: (_) => 2,
        lineWidthUnits: 'pixels',
        updateTriggers: newUpdateTriggers(),
      })
    },
  })
}

export function voltageMapping(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'voltageMapping',
    icon: '',
    infoWindow: {
      infoColumn: (f) => getInfoColumnProps(f),
      centerOfPoly: true,
    },
    mapManagerLayer: (_config, services, mapManager) =>
      voltageMappingMapManagerLayer(id, services, mapManager),
  }
}

function getInfoColumnProps(f: MapLayerFeature): InfoColumnAllProps {
  const store = useVoltageMapStore()
  const feeder = f.resource!.upline?.feeder

  const vOver = store.prevalenceOver[getUnqualifiedId(f.resource!)]
  const vUnder = store.prevalenceUnder[getUnqualifiedId(f.resource!)]

  return {
    items: [
      {
        label: 'Feeder',
        text: feeder || TEXT_NO_VALUE,
        textLink: feeder ? createFeederRoute(feeder) : undefined,
      },
      {
        label: 'Over',
        text: Format.fmtPercent(vOver / 100),
      },
      {
        label: 'Under',
        text: Format.fmtPercent(vUnder / 100),
      },
    ],
  }
}
