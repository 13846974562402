<template>
  <insight-template
    :isLoading
    :analysisSections
    :interval
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import type { Interval } from 'luxon'
import { intervalLast7Days } from '@/utils/time'
import { Timestamp } from '@/services/timestamp_pb'
import {
  getPeakUtilizationHistogram,
  getTopPeakUtilization,
} from '@/model/insight/InsightChartData'
import InsightTemplate, {
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import type { HistogramChartData } from '@/types/charts'
import { ResourceType } from '@/constants/resourceType'

const EMPTY_HISTOGRAM: HistogramChartData = { binSize: 20, bins: [] }
const EMPTY_TOP: ReturnType<typeof getTopPeakUtilization> = {
  chart: { bars: [] },
  sideData: { items: [] },
}

export default defineComponent({
  name: 'InsightPv',
  components: { InsightTemplate },
  data() {
    return shallowReactive({
      isLoading: false,
      interval: intervalLast7Days(this.$observationTime()),
      peakUtilizationBySubstation: EMPTY_HISTOGRAM,
      peakUtilizationByFeeder: EMPTY_HISTOGRAM,
      peakUtilizationByTransformer: EMPTY_HISTOGRAM,
      topPeakUtilizationSubstation: EMPTY_TOP,
      topPeakUtilizationFeeder: EMPTY_TOP,
      topPeakUtilizationTransformer: EMPTY_TOP,
    })
  },
  computed: {
    analysisSections(): AnalysisSection[] {
      return [
        {
          title: 'Substation utilization',
          histogram: {
            title: 'Peak utilization by substation',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of substations',
            data: this.peakUtilizationBySubstation,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 substations)',
            xAxisTitle: 'Utilization (%)',
            data: this.topPeakUtilizationSubstation.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: this.topPeakUtilizationSubstation.sideData.items,
            },
          },
        },
        {
          title: 'Feeder utilization',
          histogram: {
            title: 'Peak utilization by feeder',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of feeders',
            data: this.peakUtilizationByFeeder,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 feeders)',
            xAxisTitle: 'Utilization (%)',
            data: this.topPeakUtilizationFeeder.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: this.topPeakUtilizationFeeder.sideData.items,
            },
          },
        },
        {
          title: 'Transformer utilization',
          histogram: {
            title: 'Peak utilization by transformer',
            xAxisTitle: 'Utilization rate at peak (%)',
            yAxisTitle: 'Number of transformers',
            data: this.peakUtilizationByTransformer,
          },
          horizontalBar: {
            title: 'Peak utilization (top 10 transformers)',
            xAxisTitle: 'Utilization (%)',
            data: this.topPeakUtilizationTransformer.chart,
            sideData: {
              title: 'EV load at peak (%)',
              items: this.topPeakUtilizationTransformer.sideData.items,
            },
          },
        },
      ]
    },
  },
  watch: {
    interval: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData() {
      this.isLoading = true

      this.peakUtilizationBySubstation = EMPTY_HISTOGRAM
      this.peakUtilizationByFeeder = EMPTY_HISTOGRAM
      this.peakUtilizationByTransformer = EMPTY_HISTOGRAM
      this.topPeakUtilizationSubstation = EMPTY_TOP
      this.topPeakUtilizationFeeder = EMPTY_TOP
      this.topPeakUtilizationTransformer = EMPTY_TOP

      try {
        const [
          substationGridImpactTable,
          feederGridImpactTable,
          transformerGridImpactTable,
        ] = await Promise.all(
          [
            ResourceType.SUBSTATION,
            ResourceType.FEEDER,
            ResourceType.TRANSFORMER,
          ].map((rt) =>
            this.$services.analysisService.fetchDERImpactsTable({
              componentType: rt,
              start: Timestamp.fromDateTime(this.interval.start),
              end: Timestamp.fromDateTime(this.interval.end),
              limit: 999_999_999,
              offset: 0,
            })
          )
        )

        this.peakUtilizationBySubstation = getPeakUtilizationHistogram(
          substationGridImpactTable
        )
        this.peakUtilizationByFeeder = getPeakUtilizationHistogram(
          feederGridImpactTable
        )
        this.peakUtilizationByTransformer = getPeakUtilizationHistogram(
          transformerGridImpactTable
        )
        this.topPeakUtilizationSubstation = getTopPeakUtilization(
          substationGridImpactTable
        )
        this.topPeakUtilizationFeeder = getTopPeakUtilization(
          feederGridImpactTable
        )
        this.topPeakUtilizationTransformer = getTopPeakUtilization(
          transformerGridImpactTable
        )
      } catch (err) {
        console.error('InsightLoading.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
