import { ResourceType } from '@/constants/resourceType'

// Dull icons
import iconBatteryDistributedDull from '@/assets/imgs/albert_ic_battery_distributed--dull.svg'
import iconBatteryUtilityDull from '@/assets/imgs/albert_ic_battery_utility--dull.svg'
// Note(Isaac): No `ResourceType.BIOGAS` yet
// import iconBiogasDull from '@/assets/imgs/albert_ic_biogas--dull.svg'
import iconBiomassDull from '@/assets/imgs/albert_ic_biomass--dull.svg'
import iconBreakerDull from '@/assets/imgs/albert_ic_breaker--dull.svg'
import iconCapacitorDull from '@/assets/imgs/albert_ic_capacitor--dull.svg'
import iconEBusDull from '@/assets/imgs/albert_ic_ebus--dull.svg'
import iconEvseDull from '@/assets/imgs/albert_ic_evse--dull.svg'
import iconFuseDull from '@/assets/imgs/albert_ic_fuse--dull.svg'
import iconHydroDull from '@/assets/imgs/albert_ic_hydro--dull.svg'
import iconRecloserDull from '@/assets/imgs/albert_ic_recloser--dull.svg'
import iconRegulatorDull from '@/assets/imgs/albert_ic_regulator--dull.svg'
import iconSectionalizerDull from '@/assets/imgs/albert_ic_sectionalizer--dull.svg'
import iconSensorDull from '@/assets/imgs/albert_ic_sensor--dull.svg'
import iconSolarDistributedDull from '@/assets/imgs/albert_ic_solar_distributed--dull.svg'
import iconSolarUtilityDull from '@/assets/imgs/albert_ic_solar_utility--dull.svg'
import iconSubstationDull from '@/assets/imgs/albert_ic_substation--dull.svg'
import iconSubstationTDDull from '@/assets/imgs/albert_ic_substation_td--dull.svg'
import iconSwitchDull from '@/assets/imgs/albert_ic_switch--dull.svg'
import iconTransformerDull from '@/assets/imgs/albert_ic_transformer--dull.svg'
import iconWindDull from '@/assets/imgs/albert_ic_wind--dull.svg'

// GT icons (over voltage) - Orange #DF6D27
import iconCapacitorGT from '@/assets/imgs/albert_ic_capacitor--gt.svg'
import iconFuseGT from '@/assets/imgs/albert_ic_fuse--gt.svg'
import iconRecloserGT from '@/assets/imgs/albert_ic_recloser--gt.svg'
import iconRegulatorGT from '@/assets/imgs/albert_ic_regulator--gt.svg'
import iconSwitchGT from '@/assets/imgs/albert_ic_switch--gt.svg'
import iconTransformerGT from '@/assets/imgs/albert_ic_transformer--gt.svg'

// LX icons (under voltage) - Purple #6F0CA0
import iconCapacitorLX from '@/assets/imgs/albert_ic_capacitor--lx.svg'
import iconFuseLX from '@/assets/imgs/albert_ic_fuse--lx.svg'
import iconRecloserLX from '@/assets/imgs/albert_ic_recloser--lx.svg'
import iconRegulatorLX from '@/assets/imgs/albert_ic_regulator--lx.svg'
import iconSwitchLX from '@/assets/imgs/albert_ic_switch--lx.svg'
import iconTransformerLX from '@/assets/imgs/albert_ic_transformer--lx.svg'

// These icons are used in the feeder operations map
// There are *--dull.svg, *--gt.svg, *--lx.svg
export const resourceIconsDull = [
  [ResourceType.BATTERY_DISTRIBUTED, iconBatteryDistributedDull],
  [ResourceType.BATTERY_STATION, iconBatteryUtilityDull],
  [ResourceType.BIOMASS, iconBiomassDull],
  // [ResourceType.BIOGAS, iconBiogasDull],
  [ResourceType.BREAKER, iconBreakerDull],
  [ResourceType.CAPACITOR, iconCapacitorDull],
  [ResourceType.VEHICLE, iconEBusDull],
  [ResourceType.CHARGER, iconEvseDull],
  [ResourceType.FUSE, iconFuseDull],
  [ResourceType.HYDRO, iconHydroDull],
  [ResourceType.RECLOSER, iconRecloserDull],
  [ResourceType.REGULATOR, iconRegulatorDull],
  // [ResourceType.RELAY, iconRelayDull],
  [ResourceType.SECTIONALIZER, iconSectionalizerDull],
  [ResourceType.SENSOR_ELECTRICAL, iconSensorDull],
  [ResourceType.SOLAR_DISTRIBUTED, iconSolarDistributedDull],
  [ResourceType.SOLAR_FARM, iconSolarUtilityDull],
  [ResourceType.SUBSTATION, iconSubstationDull],
  [ResourceType.SUBSTATION + '-TD', iconSubstationTDDull],
  [ResourceType.SWITCH, iconSwitchDull],
  [ResourceType.TRANSFORMER, iconTransformerDull],
  [ResourceType.WIND, iconWindDull],
]

export const resourceIconsGT = [
  [ResourceType.CAPACITOR, iconCapacitorGT],
  [ResourceType.FUSE, iconFuseGT],
  [ResourceType.RECLOSER, iconRecloserGT],
  [ResourceType.REGULATOR, iconRegulatorGT],
  [ResourceType.SWITCH, iconSwitchGT],
  [ResourceType.TRANSFORMER, iconTransformerGT],
]

export const resourceIconsLX = [
  [ResourceType.CAPACITOR, iconCapacitorLX],
  [ResourceType.FUSE, iconFuseLX],
  [ResourceType.RECLOSER, iconRecloserLX],
  [ResourceType.REGULATOR, iconRegulatorLX],
  [ResourceType.SWITCH, iconSwitchLX],
  [ResourceType.TRANSFORMER, iconTransformerLX],
]
