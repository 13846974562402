import * as RouteNames from '@/router/routeNames'
import { numberNoDecimals } from '@/utils/formatText'
import type { Header } from '@/model/tables/DataTable'
import { DeviceHelper } from '@/model/control/device/helper'
import { GroupHelper } from '@/model/control/group/helper'
import type { Group } from 'rfs/control/proto/model_pb'

export function createHeaders(
  isOeEnabled: boolean,
  mapGroupResourceIds: Map<Group, string[]>
): Header<Group>[] {
  const deviceCount = Array.from(mapGroupResourceIds.entries()).reduce(
    (acc, [group, resourceIds]) => {
      acc.set(group.id, resourceIds.length)
      return acc
    },
    new Map<string, number>()
  )

  return [
    {
      title: 'Name',
      key: 'displayName',
      routeFactory: (_config, group) => {
        return {
          name: RouteNames.CONTROL_GROUP_DETAILS,
          params: { groupId: group.id },
        }
      },
    },
    {
      title: 'Type',
      key: 'deviceType',
      value: (group) => DeviceHelper.typeToCategoryName(group.deviceType),
    },
    {
      title: 'Policy',
      key: 'policy',
      value: (group) =>
        GroupHelper.getPolicyLabel(
          group.currentPolicy,
          group.currentPolicyParams
        ),
    },
    ...(isOeEnabled
      ? [{ title: 'Envelope Status', key: 'operatingEnvelope' }]
      : []),
    {
      title: 'Devices',
      key: 'deviceCount',
      align: 'end',
      value: (group) => numberNoDecimals.format(deviceCount.get(group.id) ?? 0),
    },
  ]
}
