<template>
  <insight-template
    :isLoading
    :charts
    :dataSource
    :summaryBoxes
    :analysisSections
    :interval
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import type { Interval } from 'luxon'
import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Timestamp } from '@/services/timestamp_pb'
import { MEGA_OPTIONS, Format } from '@/utils/format'
import { formatDtWithZoneShort, intervalLast7Days } from '@/utils/time'
import { numberNoDecimals } from '@/utils/formatText'
import { TimeSeriesDataSource } from '@/model/charts'
import {
  chartChargingLoad,
  getMaxEvLoadByFeeder,
  getNumberOfEvsByFeeder,
  getTopFeedersByMaxEvLoad,
  getTopFeedersByNumberOfEvs,
  newEvInsightDataSource,
} from '@/model/insight/InsightChartData'
import {
  getChargerIds,
  getNumberOfEvs,
  getPeakEvLoad,
  getEvSystemPeak,
} from '@/model/insight/summaries'
import InsightTemplate, {
  type SummaryBox,
  type AnalysisSection,
} from '@/components/analyze/insights/InsightTemplate.vue'
import type { HistogramChartData, HorizontalBarChartData } from '@/types/charts'

const EMPTY_HISTOGRAM: HistogramChartData = { binSize: 20, bins: [] }
const EMPTY_HORIZONTAL: HorizontalBarChartData = { bars: [] }

export default defineComponent({
  name: 'InsightEv',
  components: { InsightTemplate },
  setup() {
    return { charts: [chartChargingLoad] }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      interval: intervalLast7Days(this.$observationTime()),
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      numberOfEvs: undefined as undefined | ReturnType<typeof getNumberOfEvs>,
      peakEvLoad: undefined as
        | undefined
        | Awaited<ReturnType<typeof getPeakEvLoad>>,
      systemPeak: undefined as undefined | ReturnType<typeof getEvSystemPeak>,
      numberOfEvsByFeeders: EMPTY_HISTOGRAM,
      topFeedersByNumberOfEvs: EMPTY_HORIZONTAL,
      maxEvLoadByFeeder: EMPTY_HISTOGRAM,
      topFeedersByMaxEvLoad: EMPTY_HORIZONTAL,
    })
  },
  computed: {
    summaryBoxes(): SummaryBox[] {
      return [
        {
          title: 'Number of EVs',
          mainValue:
            this.numberOfEvs?.total !== undefined
              ? numberNoDecimals.format(this.numberOfEvs.total)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Detected',
              text:
                this.numberOfEvs?.detected !== undefined
                  ? numberNoDecimals.format(this.numberOfEvs.detected)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Known',
              text:
                this.numberOfEvs?.known !== undefined
                  ? numberNoDecimals.format(this.numberOfEvs.known)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Installed capacity',
              text:
                this.numberOfEvs?.installedCapacity !== undefined
                  ? Format.fmtWatts(
                      this.numberOfEvs.installedCapacity,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          title: 'Peak EV load',
          mainValue:
            this.peakEvLoad?.peak !== undefined
              ? Format.fmtWatts(this.peakEvLoad.peak, MEGA_OPTIONS)
              : TEXT_NO_VALUE,
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.peakEvLoad?.timeOfPeak
                ? formatDtWithZoneShort(this.peakEvLoad.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'Detected',
              text:
                this.peakEvLoad?.peakOfDetected !== undefined
                  ? Format.fmtWatts(
                      this.peakEvLoad.peakOfDetected,
                      MEGA_OPTIONS
                    )
                  : TEXT_NO_VALUE,
            },
            {
              label: 'Known',
              text:
                this.peakEvLoad?.peakOfKnown !== undefined
                  ? Format.fmtWatts(this.peakEvLoad.peakOfKnown, MEGA_OPTIONS)
                  : TEXT_NO_VALUE,
            },
          ],
        },
        {
          title: 'System peak',
          mainValue: Format.fmtWatts(this.systemPeak?.total, MEGA_OPTIONS),
          infoColumnItems: [
            {
              label: 'Time of peak',
              text: this.systemPeak?.timeOfPeak
                ? formatDtWithZoneShort(this.systemPeak.timeOfPeak)
                : TEXT_NO_VALUE,
            },
            {
              label: 'EV load at peak',
              text:
                this.systemPeak?.evLoadAtPeak !== undefined
                  ? Format.fmtWatts(this.systemPeak.evLoadAtPeak, MEGA_OPTIONS)
                  : TEXT_NO_VALUE,
            },
            {
              label: 'EV load of peak',
              text:
                this.systemPeak?.evLoadAtPeakPercent !== undefined
                  ? Format.fmtPercent(this.systemPeak.evLoadAtPeakPercent)
                  : TEXT_NO_VALUE,
            },
          ],
        },
      ]
    },
    analysisSections(): AnalysisSection[] {
      return [
        {
          title: 'EV adoption',
          histogram: {
            title: 'Number of EVs by feeder',
            xAxisTitle: 'Number of EVs',
            yAxisTitle: 'Number of feeders',
            data: this.numberOfEvsByFeeders,
          },
          horizontalBar: {
            title: 'Number of EVs (top 10 feeders)',
            xAxisTitle: 'Number of EVs',
            data: this.topFeedersByNumberOfEvs,
          },
        },
        {
          title: 'EV load',
          histogram: {
            title: 'Max EV load by feeder',
            xAxisTitle: 'Max load (kW)',
            yAxisTitle: 'Number of feeders',
            data: this.maxEvLoadByFeeder,
          },
          horizontalBar: {
            title: 'Max EV load (top 10 feeders)',
            xAxisTitle: 'Load (kW)',
            data: this.topFeedersByMaxEvLoad,
          },
        },
      ]
    },
  },
  watch: {
    interval: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData() {
      this.isLoading = true

      this.dataSource = TimeSeriesDataSource.emptyDataSource()
      this.numberOfEvs = undefined
      this.peakEvLoad = undefined
      this.systemPeak = undefined
      this.numberOfEvsByFeeders = EMPTY_HISTOGRAM
      this.topFeedersByNumberOfEvs = EMPTY_HORIZONTAL
      this.maxEvLoadByFeeder = EMPTY_HISTOGRAM
      this.topFeedersByMaxEvLoad = EMPTY_HORIZONTAL

      try {
        const [feeders, chargers, gridImpactTable, gridImpactStats] =
          await Promise.all([
            this.$services.queryService.getResourcesByType(ResourceType.FEEDER),
            this.$services.queryService.getResourcesByType(
              ResourceType.CHARGER
            ),
            this.$services.analysisService.fetchDERImpactsTable({
              componentType: ResourceType.FEEDER,
              start: Timestamp.fromDateTime(this.interval.start),
              end: Timestamp.fromDateTime(this.interval.end),
              limit: 999_999_999,
              offset: 0,
            }),
            this.$services.analysisService.fetchDERImpactsStats({
              componentType: 'NONE',
              start: Timestamp.fromDateTime(this.interval.start),
              end: Timestamp.fromDateTime(this.interval.end),
            }),
          ])

        const { detected, known } = getChargerIds(chargers)

        this.peakEvLoad = await getPeakEvLoad(
          this.$services,
          this.interval,
          detected,
          known
        )

        this.dataSource = newEvInsightDataSource(
          this.$observationTime(),
          this.$services,
          this.interval,
          detected,
          known
        )

        this.numberOfEvs = getNumberOfEvs(chargers)

        this.systemPeak = getEvSystemPeak(gridImpactStats)

        this.numberOfEvsByFeeders = getNumberOfEvsByFeeder(feeders, chargers)

        this.topFeedersByNumberOfEvs = getTopFeedersByNumberOfEvs(
          feeders,
          chargers
        )

        this.maxEvLoadByFeeder = getMaxEvLoadByFeeder(gridImpactTable)

        this.topFeedersByMaxEvLoad = getTopFeedersByMaxEvLoad(gridImpactTable)
      } catch (err) {
        console.error('InsightEv.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
