import { MapLayerGroupId, MapLayerId } from '@/config/types'
import iconFleet from '@/assets/imgs/icon-trucks__black.svg'
import iconGrid from '@/assets/imgs/albert_ic_grid--black.svg'

export interface ApplicationMapLayerGroup {
  id: MapLayerGroupId
  label: string
  icon: string
}

export type MapLayerGroupCatalog = Map<
  MapLayerGroupId,
  ApplicationMapLayerGroup
>

const Fleet: ApplicationMapLayerGroup = {
  id: MapLayerGroupId.FLEET,
  label: 'Fleet',
  icon: iconFleet,
}

const Grid: ApplicationMapLayerGroup = {
  id: MapLayerGroupId.GRID,
  label: 'Grid',
  icon: iconGrid,
}

/** * List of available Map Layer Groups to be used within the application. */
export const mapLayerGroupCatalog: MapLayerGroupCatalog = new Map()
  .set(Fleet.id, Fleet)
  .set(Grid.id, Grid)

/**
 * Grouping of map layers in the CeControls menu.
 */
export enum LayerMenuGroup {
  /** Substations, Transformers, Meters, etc. */
  GRID,
  /** Dist. Solar, Dist. Battery, EVSE, etc. */
  METER_DER,
  /** Utility Solar, Utility Battery, Hydro, etc. */
  UTILITY_DER,
  /** Breakers, capacitors, fuses, reclosers, etc. */
  MIDLINE,
  /** EdgeZero, etc. */
  SENSORS,
  /** GeoJSON layers from Ritta File Storage */
  CUSTOM,
}

export const mapLayerMenuGroups = new Map<MapLayerId, LayerMenuGroup>()
  .set(MapLayerId.TRANSMISSION, LayerMenuGroup.GRID)
  .set(MapLayerId.SUBSTATION, LayerMenuGroup.GRID)
  .set(MapLayerId.DISTRIBUTION, LayerMenuGroup.GRID)
  .set(MapLayerId.TRANSFORMER, LayerMenuGroup.GRID)
  .set(MapLayerId.METER_ENDPOINT, LayerMenuGroup.GRID)

  .set(MapLayerId.DISTRIBUTED_BATTERY, LayerMenuGroup.METER_DER)
  .set(MapLayerId.DISTRIBUTED_SOLAR, LayerMenuGroup.METER_DER)
  .set(MapLayerId.EV_CHARGER, LayerMenuGroup.METER_DER)
  .set(MapLayerId.V2G_BUS, LayerMenuGroup.METER_DER)

  .set(MapLayerId.HYDRO, LayerMenuGroup.UTILITY_DER)
  .set(MapLayerId.METHANE_CAPTURE, LayerMenuGroup.UTILITY_DER)
  .set(MapLayerId.UTILITY_BATTERY, LayerMenuGroup.UTILITY_DER)
  .set(MapLayerId.UTILITY_SOLAR, LayerMenuGroup.UTILITY_DER)
  .set(MapLayerId.WIND, LayerMenuGroup.UTILITY_DER)

  .set(MapLayerId.BREAKER, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.CAPACITOR, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.FUSE, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.RECLOSER, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.REGULATOR, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.SECTIONALIZER, LayerMenuGroup.MIDLINE)
  .set(MapLayerId.SWITCH, LayerMenuGroup.MIDLINE)

  .set(MapLayerId.SENSOR_ELECTRIC, LayerMenuGroup.SENSORS)
