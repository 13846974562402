// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/transformer_details.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { LatLng, Ratings, Upline } from "../../pb/resource_pb.js";
import { TimeSeries, TimeSeriesRequest_Frame } from "./tsdb_pb.js";
import { CalendarPeriod } from "../../pb/calendar_pb.js";

/**
 * @generated from message ritta.frontend.proto.GetTransformerRequest
 */
export const GetTransformerRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetTransformerRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * transformer details or status NOT_FOUND if transformer is not found
 *
 * @generated from message ritta.frontend.proto.Transformer
 */
export const Transformer = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.Transformer",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "location", kind: "message", T: LatLng },
    { no: 3, name: "rating", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "feeder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "substation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phase_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "upline_reference", kind: "message", T: Upline },
  ],
);

/**
 * @generated from message ritta.frontend.proto.TransformerSeriesRequest
 */
export const TransformerSeriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "max_meter_details", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
  ],
);

/**
 * No longer used - see TransformerSeriesResponseV2 
 *
 * @generated from message ritta.frontend.proto.TransformerSeriesResponse
 */
export const TransformerSeriesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesResponse",
  [],
);

/**
 * @generated from message ritta.frontend.proto.TransformerSeriesRequestV2
 */
export const TransformerSeriesRequestV2 = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesRequestV2",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 4, name: "metrics", kind: "message", T: TimeSeriesRequest_Frame, repeated: true },
  ],
);

/**
 * This message replaces TransformerSeriesResponse to align with the new charts infrastructure
 *
 * @generated from message ritta.frontend.proto.TransformerSeriesResponseV2
 */
export const TransformerSeriesResponseV2 = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesResponseV2",
  () => [
    { no: 1, name: "transformer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "series", kind: "message", T: TimeSeries, repeated: true },
    { no: 4, name: "apparent_power_duration", kind: "message", T: TransformerSeriesResponseV2_PercentageValue, repeated: true },
    { no: 5, name: "load_factor_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "meter_stats", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TransformerSeriesResponseV2_MeterStats} },
    { no: 7, name: "overload_duration_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * Apparent power uses a chart showing power against % time
 * NOTE: percentage values are in the range [0.0, 1.0]
 *
 * @generated from message ritta.frontend.proto.TransformerSeriesResponseV2.PercentageValue
 */
export const TransformerSeriesResponseV2_PercentageValue = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesResponseV2.PercentageValue",
  () => [
    { no: 1, name: "percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "TransformerSeriesResponseV2_PercentageValue"},
);

/**
 * @generated from message ritta.frontend.proto.TransformerSeriesResponseV2.MeterStats
 */
export const TransformerSeriesResponseV2_MeterStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.TransformerSeriesResponseV2.MeterStats",
  () => [
    { no: 2, name: "minPower", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "maxPower", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "meanPower", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "TransformerSeriesResponseV2_MeterStats"},
);

/**
 * @generated from message ritta.frontend.proto.ComputeLoadRequest
 */
export const ComputeLoadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ComputeLoadRequest",
  () => [
    { no: 1, name: "feeder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "analyzeDate", kind: "message", T: Timestamp },
    { no: 3, name: "analyzeDays", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GenerateRequest
 */
export const GenerateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GenerateRequest",
  () => [
    { no: 1, name: "blobName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timespan", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GenerateResponse
 */
export const GenerateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GenerateResponse",
  () => [
    { no: 1, name: "cacheFilePath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The request is first validated using timespan
 * Next, the response is filtered using the FilterBy fields
 * Next, the response is sorted using OrderBy fields
 * Finally, the response is sliced using the limit and offset fields
 *
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadRequest
 */
export const ListTopTransformerLoadRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadRequest",
  () => [
    { no: 7, name: "timespan", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
    { no: 2, name: "limit", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "order_by", kind: "message", T: ListTopTransformerLoadRequest_OrderBy, repeated: true },
    { no: 5, name: "filter_by", kind: "message", T: ListTopTransformerLoadRequest_FilterBy, repeated: true },
    { no: 6, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from enum ritta.frontend.proto.ListTopTransformerLoadRequest.Field
 */
export const ListTopTransformerLoadRequest_Field = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.ListTopTransformerLoadRequest.Field",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "MAX_LOAD_PERCENT"},
    {no: 2, name: "LOAD_FACTOR"},
    {no: 3, name: "RATING"},
    {no: 4, name: "MAX_LOADING"},
    {no: 5, name: "FEEDER"},
    {no: 6, name: "SOLAR_COUNT"},
    {no: 7, name: "BATTERY_COUNT"},
    {no: 8, name: "CHARGER_COUNT"},
    {no: 9, name: "OVERLOAD_DURATION_PERCENT"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadRequest.OrderBy
 */
export const ListTopTransformerLoadRequest_OrderBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadRequest.OrderBy",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ListTopTransformerLoadRequest_Field) },
    { no: 2, name: "descending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "ListTopTransformerLoadRequest_OrderBy"},
);

/**
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadRequest.FilterBy
 */
export const ListTopTransformerLoadRequest_FilterBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadRequest.FilterBy",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ListTopTransformerLoadRequest_Field) },
    { no: 2, name: "min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 3, name: "max", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 4, name: "any_of", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "ListTopTransformerLoadRequest_FilterBy"},
);

/**
 * NOTE: "Top" in the message name is because legacy functionality support listing top 25 transformers only
 *
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadResponse
 */
export const ListTopTransformerLoadResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadResponse",
  () => [
    { no: 1, name: "transformer_load", kind: "message", T: ListTopTransformerLoadResponse_TransformerLoad, repeated: true },
    { no: 2, name: "end_time", kind: "message", T: Timestamp },
    { no: 3, name: "num_all_transformers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "export_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "filter_options", kind: "message", T: ListTopTransformerLoadRequest_FilterBy, repeated: true },
  ],
);

/**
 * Stores the number of resources and their combined ratings
 *
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadResponse.DownlineStats
 */
export const ListTopTransformerLoadResponse_DownlineStats = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadResponse.DownlineStats",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "ratings", kind: "message", T: Ratings },
  ],
  {localName: "ListTopTransformerLoadResponse_DownlineStats"},
);

/**
 * @generated from message ritta.frontend.proto.ListTopTransformerLoadResponse.TransformerLoad
 */
export const ListTopTransformerLoadResponse_TransformerLoad = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ListTopTransformerLoadResponse.TransformerLoad",
  () => [
    { no: 1, name: "transformer", kind: "message", T: Transformer },
    { no: 2, name: "max_power_apparent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "max_load_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "load_factor_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "downline_stats", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: ListTopTransformerLoadResponse_DownlineStats} },
    { no: 6, name: "overload_duration_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "ListTopTransformerLoadResponse_TransformerLoad"},
);

