<template>
  <resource-header :breadcrumbs="breadcrumbs" :items="items" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { createFeederRoute, createSubstationRoute } from '@/utils/router/create'
import { Resource } from 'rfs/pb/resource_pb'
import ResourceHeader from '@/components/ResourceHeader.vue'
import { BreadcrumbItem, ResourceHeaderItem } from '@/components/ResourceHeader'

export default defineComponent({
  name: 'SiteHeader',
  props: {
    siteId: {
      type: String,
      required: true,
    },
    siteMeter: {
      type: Object as PropType<Resource | null>,
      default: null,
    },
  },
  components: {
    ResourceHeader,
  },
  data() {
    return {
      labelSubstation: 'Substation:',
      labelFeeder: 'Feeder:',
    }
  },
  computed: {
    breadcrumbs(): BreadcrumbItem[] {
      return [{ title: `${this.$dictionary('Site')}: ${this.siteId}` }]
    },
    items(): ResourceHeaderItem[] {
      const items: ResourceHeaderItem[] = []

      // Substation
      const sHandle = this.siteMeter?.upline?.substation
      if (sHandle) {
        items.push({
          id: `${this.labelSubstation}-${sHandle}`,
          label: this.labelSubstation,
          content: [{ text: sHandle, to: createSubstationRoute(sHandle) }],
        })
      }

      // Feeder
      const feeder = this.siteMeter?.upline?.feeder
      if (feeder) {
        items.push({
          id: `${this.labelFeeder}-${feeder}`,
          label: this.labelFeeder,
          content: [{ text: feeder, to: createFeederRoute(feeder) }],
        })
      }

      return items
    },
  },
})
</script>
