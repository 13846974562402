<template>
  <v-select
    label="Month"
    :items="months"
    :model-value="modelValue.toFormat('yyyy-MM')"
    @update:model-value="handleUpdate"
  />
</template>

<script lang="ts">
import { DateTime } from 'luxon'
import { PropType } from 'vue'

const TITLE_FORMAT = 'MMMM yyyy'

export default {
  props: {
    modelValue: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    max: {
      type: Object as PropType<DateTime>,
      required: false,
      default: DateTime.local(),
    },
    numberOfMonths: {
      type: Number,
      required: false,
      default: 12,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    months(): { value: string; title: string }[] {
      return Array.from({ length: this.numberOfMonths }, (_, i) => {
        const date = this.max.minus({ months: i })
        return {
          value: date.toFormat('yyyy-MM'),
          title: date.toFormat(TITLE_FORMAT),
        }
      })
    },
  },
  methods: {
    handleUpdate(value: string) {
      this.$emit('update:modelValue', DateTime.fromFormat(value, 'yyyy-MM'))
    },
  },
}
</script>
