import { IconLayer } from '@deck.gl/layers'
import { InfoColumnAllProps } from '@/components/InfoColumn'
import { MapLayerId, RittaConfig } from '@/config/types'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { ALERT_THRESHOLD_PERCENT } from '@/constants/transformer'
import { formatPhase } from '@/model/resource/conductor/phase'
import { Services } from '@/services'
import {
  COMMON_SIZE_DIVISOR,
  ICON_MAX_PIXELS,
  TILE_SIZE,
  ZOOM_TRANSFORMERS,
} from '@/model/map/constants'
import { MapLayerFeature, MapManagerLayer } from '@/model/map'
import { Format } from '@/utils/format'
import { ApplicationMapLayer } from './catalog'
import {
  CustomTileLayer,
  getPosition,
  getTileResponseLayers,
} from './extensions'
import { getIconAtlas } from './iconAtlas'

import iconTransformer from '@/assets/imgs/albert_ic_transformer--black.svg'

function getIcon(feat: MapLayerFeature): string {
  if ((feat.properties?.maxLoadPercent ?? 0) > ALERT_THRESHOLD_PERCENT) {
    return ResourceType.TRANSFORMER + '-ALERT'
  } else {
    return ResourceType.TRANSFORMER
  }
}

function transformerMapManagerLayer(
  id: MapLayerId,
  config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  const resourceType =
    (config.map.layerConfigs?.[MapLayerId.TRANSFORMER]
      ?.resourceType as ResourceType) ?? ResourceType.TRANSFORMER

  return new CustomTileLayer({
    id,
    minZoom: ZOOM_TRANSFORMERS - 1,
    maxZoom: ZOOM_TRANSFORMERS - 1,
    tileSize: TILE_SIZE,
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(resourceType, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: {
          getIcon,
          /* Using the "common" size unit allows the icon to get smaller as the user zooms out. */
          sizeUnits: 'common',
          sizeMinPixels: ICON_MAX_PIXELS / 2,
          sizeMaxPixels: ICON_MAX_PIXELS,
          getSize: (ICON_MAX_PIXELS * 0.75) / COMMON_SIZE_DIVISOR,
        },
      })
    },
  })
}

function transformerHighlightLayer(
  features: MapLayerFeature[]
): MapManagerLayer {
  const [iconAtlas, iconMapping] = getIconAtlas()

  return new IconLayer({
    data: features,
    pickable: true,
    // @ts-ignore
    iconAtlas,
    iconMapping,
    getIcon,
    getPosition,
    getSize: ICON_MAX_PIXELS, // Fixed size icon for highlights
  })
}

export function transformer(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Transformer',
    icon: iconTransformer,
    infoWindow: {
      infoColumn: (f) => getInfoColumnProps(f),
    },
    mapManagerLayer: (config, services) =>
      transformerMapManagerLayer(id, config, services),
    highlightLayer: transformerHighlightLayer,
  }
}

function getInfoColumnProps(f: MapLayerFeature): InfoColumnAllProps {
  const power = f.resource?.ratings?.power
  const percent = f.properties?.maxLoadPercent

  return {
    title: f.resource?.gis?.element ?? TEXT_NO_VALUE,
    items: [
      {
        label: 'Phase',
        text: formatPhase(f.resource!) || TEXT_NO_VALUE,
      },
      {
        label: 'Rated capacity',
        text: Format.fmtApparentPower(power?.apparent) || TEXT_NO_VALUE,
      },
      {
        label: 'Utilization factor',
        text: Format.fmtPercent(percent),
      },
    ],
  }
}
