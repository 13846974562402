import { TEXT_NO_VALUE } from '@/constants/formatText'
import { InfoColumnAllProps, InfoColumnItem } from '@/model/types'
import { getUnqualifiedId } from '@/model/resource'
import { Device_Access as DeviceAccess, Resource } from 'rfs/pb/resource_pb'

export const ChargerHelper = {
  deviceAccessToString(r: Resource): string {
    switch (r.device?.access) {
      case DeviceAccess.PRIVATE:
        return 'Private'
      case DeviceAccess.PUBLIC:
        return 'Public'
      default:
        return TEXT_NO_VALUE
    }
  },

  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    return {
      title: r.title ?? TEXT_NO_VALUE,
      items: ChargerHelper.infoColumnItems(r),
    }
  },

  infoColumnItems(r: Resource): InfoColumnItem<any>[] {
    return [
      { label: 'ID', text: getUnqualifiedId(r) },
      { label: 'Level', text: r.level || TEXT_NO_VALUE },
      { label: 'Ports', text: r.device?.ports?.toString() || TEXT_NO_VALUE },
      { label: 'Type', text: this.deviceAccessToString(r) },
    ]
  },

  leftPanelItems(r: Resource): InfoColumnItem<any>[] {
    return [
      { label: 'Name', text: r.title },
      ...ChargerHelper.infoColumnItems(r),
    ]
  },
}
