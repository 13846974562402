import polylabel from 'polylabel'
import { LatLng } from 'rfs/pb/resource_pb'
import { MapLayerGroup, MapLayerId } from '@/config/types'

export function findCenterOfPolygon(coordinates: LatLng[]) {
  const [lat, lng] = polylabel([coordinates.map(({ lat, lng }) => [lat, lng])])
  return { lat, lng }
}

export function isMapLayerId(v: string): boolean {
  return Object.values(MapLayerId).includes(v as MapLayerId)
}

export function isMapLayerEnabled(
  mapLayerGroups: MapLayerGroup[],
  id: MapLayerId
): boolean {
  return (mapLayerGroups ?? []).some((lg) =>
    lg.layers.some((l) => l.id === id && !l.disabled)
  )
}

// List of element selectors we want to disable accidental/unwanted zoom.
const ZOOM_SELECTORS = [
  // Vue components.
  '.c-CeControls',
  '.c-LandmarksControl',
  '.c-MapSearchControl',
  '.c-MapTypeControl',
  '.c-BaseCustomControl',
  '.c-BtnExpandLeftPanel',
  // GMaps elements.
  'button.gm-svpc', // Street View button.
  '.gmnoprint', // Other action buttons.
  '.gm-style-iw', // Info Window.
  '.gm-style-iw-tc', // Info Window, upside-down triangle.
]
const SELECTOR_STRING = ZOOM_SELECTORS.join(', ')

function preventUnwantedZoom(event: Event) {
  if ((event.target as HTMLElement).closest(SELECTOR_STRING)) {
    event.preventDefault()
  }
}

export function addZoomPrevention(parent: HTMLElement) {
  parent.addEventListener('wheel', preventUnwantedZoom, {
    capture: true,
    passive: false,
  })
}

export function removeZoomPrevention(parent: HTMLElement) {
  parent.removeEventListener('wheel', preventUnwantedZoom)
}
