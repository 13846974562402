<template>
  <div
    class="d-flex flex-column bg-blue-grey-lighten-5 rounded pa-4"
    style="min-height: 7.75rem"
  >
    <!-- Name & Timestamp -->
    <div class="d-flex justify-space-between pb-2">
      <!-- Name -->
      <router-link
        :to="link"
        class="d-block text-subtitle-2 font-weight-medium"
      >
        {{ alert.name }}
      </router-link>

      <!-- Timestamp -->
      <span
        data-test="timestamp"
        class="d-block text-subtitle-2"
        style="font-weight: unset"
      >
        {{ startText }}
      </span>
    </div>

    <!-- Description -->
    <span
      data-test="description"
      class="d-block text-subtitle-2 flex-grow-1 flex-shrink-1 pb-2"
      style="font-weight: unset"
    >
      {{ descriptionText }}
    </span>

    <!-- Actions -->
    <div class="d-flex align-center">
      <!-- See details -->
      <router-link
        :to="link"
        class="d-block text-primary text-subtitle-2 text-decoration-none"
        style="font-weight: unset"
      >
        {{ linkText }}
      </router-link>

      <v-spacer />

      <!-- Acknowledge -->
      <!-- TODO(rafael): show button when "acknowledge" action available in the server -->
      <v-btn
        v-show="false"
        data-test="acknowledge"
        elevation="0"
        :color="btnColor"
        class="mr-2"
        outlined
        @click.stop="acknowledge"
      >
        <v-icon :color="iconColor">mdi-check-circle-outline</v-icon>
      </v-btn>

      <!-- Silence -->
      <!-- TODO(rafael): show button when "silence" action available in the server -->
      <v-btn
        v-show="false"
        data-test="silence"
        elevation="0"
        :color="btnColor"
        outlined
        @click.stop="silence"
      >
        <v-icon :color="iconColor">mdi-bell-off-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { createAlertDetailsRoute } from '@/utils/router/create'
import { Alert } from 'rfs/frontend/proto/alert_pb'
import { AlertHelper } from '@/model/alert/helper'

export default defineComponent({
  name: 'AlertsMenuItem',
  props: {
    alert: {
      type: Object as PropType<Alert>,
      required: true,
    },
  },
  setup(props) {
    return {
      link: createAlertDetailsRoute(props.alert),
      startText: AlertHelper.formatTimestamp(props.alert.start),
      descriptionText: props.alert.description ?? TEXT_NO_VALUE,
      linkText: 'See details',
      btnColor: 'blue lighten-4',
      iconColor: 'blue darken-4',
    }
  },
  methods: {
    acknowledge(): void {
      // TODO(rafael)
      console.debug('acknowledge!!!')
    },
    silence(): void {
      // TODO(rafael)
      console.debug('silence!!!')
    },
  },
})
</script>
