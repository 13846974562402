<template>
  <div class="position-relative" style="min-height: 9rem">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" :size="20" />

    <!-- Data table -->
    <v-data-table
      v-else
      :headers="feederHeaders"
      :items="feederData"
      item-value="feeder"
      class="px-2 pb-12 bg-transparent"
      density="compact"
    >
      <!-- Custom <td> content for feeder name -->
      <template v-slot:[`item.feeder`]="{ item }">
        <span class="font-weight-bold" v-if="item.feeder === 'Total'">
          Total
        </span>

        <router-link v-else :to="createFeederRoute(item.feeder)">
          {{ item.feeder }}
        </router-link>
      </template>

      <!-- Hide Footer -->
      <template v-slot:bottom></template>
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { DataTableHeader as VuetifyDataTableHeader } from '@/types/vuetify3'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { getUnqualifiedId } from '@/model/resource'
import { createResourceTelemetryRoute } from '@/utils/router/create'
import { Format } from '@/utils/format'
import { SummaryStats } from 'rfs/frontend/proto/grid_pb'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import type { Resource } from 'rfs/pb/resource_pb'

type FeederStats = {
  feeder: string
  meters: string
  solarResources: string
  solarCapacity: string
}

export default defineComponent({
  name: 'SubstationSummaryDataTable',
  props: {
    substation: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: { CenteredSpinner },
  setup() {
    const feederHeaders: VuetifyDataTableHeader[] = [
      {
        title: 'Feeder',
        align: 'start',
        sortable: true,
        value: 'feeder',
      },
      {
        title: 'Meters',
        align: 'start',
        sortable: true,
        value: 'meters',
      },
      // TODO(cody): investigate putting these together into a colspan.
      {
        title: 'Solar Projects',
        align: 'start',
        sortable: true,
        value: 'solarResources',
      },
      {
        title: 'Solar Capacity (AC)',
        align: 'start',
        sortable: false,
        value: 'solarCapacity',
      },
    ]

    return { feederHeaders }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      feederData: [] as FeederStats[],
      feederStats: {} as { [feeder: string]: SummaryStats },
      feeders: [] as Resource[],
      summaryStats: new SummaryStats(),
    })
  },
  computed: {
    substationUnqualifiedId(): string {
      return getUnqualifiedId(this.substation)
    },
  },
  watch: {
    substation: {
      immediate: true,
      handler: function (newValue: any) {
        this.fetchData()
      },
    },
    summaryStats(): void {
      this.refreshFeederData()
    },
  },
  methods: {
    createFeederRoute(id: string) {
      return createResourceTelemetryRoute(ResourceType.FEEDER, id)
    },
    resetState(): void {
      this.feederData = []
      this.feederStats = {}
      this.feeders = []
      this.summaryStats = new SummaryStats()
    },
    async fetchData(): Promise<void> {
      this.isLoading = true

      try {
        const [feeders, summaryStats] = await Promise.all([
          this.$services.queryService.getResourcesByUpline(
            { substation: this.substationUnqualifiedId },
            [ResourceType.FEEDER]
          ),
          this.$services.gridService.substationSummary(
            this.substationUnqualifiedId
          ),
        ])

        await Promise.all(feeders.map((f) => this.fetchFeederSummary(f)))

        this.feeders = feeders
        this.summaryStats = summaryStats
      } catch (err) {
        console.error('SubstationSummaryDataTable.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    async fetchFeederSummary(feeder: Resource): Promise<void> {
      try {
        const feederId = getUnqualifiedId(feeder)

        this.feederStats[feederId] =
          await this.$services.gridService.feederSummary(feederId)
      } catch (err) {
        console.error(
          'SubstationSummaryDataTable.fetchFeederSummary: unable to fetch feeder summary: %o',
          err
        )
      }
    },
    refreshFeederData(): void {
      const update: FeederStats[] = []

      if (this.summaryStats) {
        update.push(this.statsRow('Total', this.summaryStats))
      }

      for (const [feeder, stats] of Object.entries(this.feederStats).sort(
        (a, b) => a[0].localeCompare(b[0])
      )) {
        update.push(this.statsRow(feeder, stats))
      }
      this.feederData = update
    },
    statsRow(feeder: string, stats: SummaryStats): FeederStats {
      const formatCount = (resources?: number) =>
        resources ? Format.fmtCount(resources) : TEXT_NO_VALUE

      return {
        feeder,
        meters: formatCount(stats.meter?.resources),
        solarResources: formatCount(stats.solar?.resources),
        solarCapacity: ((wattage?: number) => {
          return Format.fmtWatts(wattage) ?? TEXT_NO_VALUE
        })(stats.solar?.ratedWattage),
      }
    },
  },
})
</script>
