<template>
  <div :style="{ position: 'relative', height: btnSize }">
    <v-btn-toggle
      v-show="!isStreetViewVisible"
      class="c-MapTypeControl gmaps-box-shadow"
      :style="{
        position: 'absolute',
        bottom: compMargin,
        right: `calc(3 * ${btnSize} + ${compMargin})`,
      }"
      selected-class="highlight"
      mandatory="force"
      @update:model-value="onChange"
    >
      <v-btn min-width="48" value="roadmap">
        <img :src="iconRoadmap" height="40" width="40" />
      </v-btn>
      <v-btn min-width="48" value="terrain">
        <img :src="iconContours" height="40" width="40" />
      </v-btn>
      <v-btn min-width="48" value="hybrid">
        <img :src="iconSatellite" height="40" width="40" />
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import mapTypeContoursIcon from '@/assets/imgs/mapstyle_contours.svg'
import mapTypeRoadmapIcon from '@/assets/imgs/mapstyle_roadmap.svg'
import mapTypeSatelliteIcon from '@/assets/imgs/mapstyle_satellite.svg'

export default defineComponent({
  name: 'MapTypeControl',
  setup() {
    return {
      iconRoadmap: mapTypeRoadmapIcon,
      iconContours: mapTypeContoursIcon,
      iconSatellite: mapTypeSatelliteIcon,
      btnSize: '48px',
      compMargin: '12px',
    }
  },
  computed: {
    // NOTE(rafael): when Street View is active, we should hide these controls
    // so it doesn't get displayed above Street View.
    ...mapState(useNavigationControlsStore, ['isStreetViewVisible']),
  },
  methods: {
    onChange(e: unknown) {
      this.$emit('change', e as google.maps.MapTypeId)
    },
  },
})
</script>

<style lang="scss">
.c-MapTypeControl.v-btn-toggle {
  // Required so the "outline" shows completely.
  overflow: visible !important;

  // We want a white background at full opacity (overriding vuetify defaults)
  > .v-btn.v-btn {
    background-color: white;
    opacity: 1;
    padding: 0 3px;
  }
  // A 2px highlight around the box
  > .v-btn.v-btn.highlight {
    outline: 2px solid var(--ce-primary-color);
    z-index: 1;
  }
  // For the highlighted (active) button, keep the white background
  > .v-btn.v-btn.highlight > .v-btn__overlay {
    display: none;
  }

  &:hover {
    background: none padding-box rgb(235, 235, 235);
  }
}
</style>
