import { RittaConfig } from '@/config'
import { ResourceType } from '@/constants/resourceType'
import { Services } from '@/services'
import { FactoryMapManagerLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'
import { MapLayerId } from '@/config/types'

interface ResourceMapLayerProps {
  id: MapLayerId
  resourceType: ResourceType
  minZoom: number
  tileSize: number
  pickable: boolean
}

/**
 * Returns a map manager layer creation function for a resource type.
 */
export function ResourceMapLayer(
  props: ResourceMapLayerProps
): FactoryMapManagerLayer {
  const { resourceType } = props

  return (_config: Readonly<RittaConfig>, services: Services) =>
    new CustomTileLayer({
      id: props.id,
      minZoom: props.minZoom - 1,
      maxZoom: props.minZoom - 1,
      tileSize: props.tileSize,
      pickable: props.pickable,
      getTileData: async (props) =>
        services.tileService.getTileResources(resourceType, props),
      renderSubLayers: (props) => getTileResponseLayers(props, {}),
    })
}
