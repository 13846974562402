import type { Chart, Plugin, Point } from 'chart.js'
import { merge } from 'lodash-es'
import { Y2_AXIS } from '@/utils/chartjs'

import {
  clearActiveElements,
  getActiveElementDataPoint,
  setActiveElementsFromData,
} from './tooltip'

/**
 * Chart.js
 */
type LabelSizes = {
  first: { width: number; height: number }
  last: { width: number; height: number }
  widest: { width: number; height: number }
  highest: { width: number; height: number }
  widths: number[]
  heights: number[]
}

/** Event data for hover sync events */
interface DetailHover {
  type: 'hover'
  source: string
  activeDataPoint: Point | null
}
/** Event data for pan & zoom sync events */
interface DetailPanZoom {
  type: 'pan/zoom'
  source: string
  hideTooltip: boolean
  xMin: number
  xMax: number
}

/** Event data for y axis width sync events */
interface DetailWidest {
  type: 'yaxis-width'
  /** the chart that emitted the event. */
  source: string
}

type Detail = DetailPanZoom | DetailHover | DetailWidest

export type DefaultOptions = {
  group: string
  /**
   * By default, when a second Y-axis exists in any of the charts within the
   * group, all other charts will get a right padding value to ensure that
   * the grid sizes are consistent across all charts. This option allows
   * this feature to be disabled.
   */
  disableY2AxisSync?: boolean
}

interface State {
  /** The event handler bound to a specific chart instance. */
  syncEventHandler?: (e: Event) => void
  hasGetLabelSizesOverwritten?: boolean
}

const SYNC_EVENT = 'ce-chart-sync'

/** The id of the x-scale that will be synchronized */
const X_SCALE = 'x'

// Keep private state in a weak map so we don't have to store data on the Chart instance.
const stateMap = new WeakMap() as WeakMap<Chart, State>

interface GroupState {
  /** The currently existant charts */
  currentActiveCharts: string[]
  /** The maximum width of the YAxis label among all charts. */
  widest?: number
  /** The maximum width of the Y2Axis among all charts. */
  y2Width?: number
}

// State shared between the charts within each group.
const groupMap = new Map<string, GroupState>()

const SyncPlugin: Plugin<any, DefaultOptions> = {
  id: 'camus/SyncPlugin',
  defaults: {
    /** The ID of the group. If blank, this plugin is disabled. */
    group: '',
  },
  start(chart, _, options) {
    console.debug('[SyncPlugin] start', chart.id, options.group)
    // Plugin is disabled if there is no group
    if (options.group === '') return

    const state = {
      syncEventHandler: syncEventHandler.bind(this, chart),
    }

    stateMap.set(chart, state)
    window.addEventListener(SYNC_EVENT, state.syncEventHandler)

    addChartIdToGroupState(options.group, chart.id)
  },
  stop(chart, _, options) {
    console.debug('[SyncPlugin] stop', chart.id, options.group)
    // Cleanup event handlers
    const state = stateMap.get(chart)

    if (state?.syncEventHandler) {
      window.removeEventListener(SYNC_EVENT, state.syncEventHandler)
      delete state.syncEventHandler
    }

    removeChartIdFromGroupState(options.group, chart.id)
  },
  /**
   * Used to sync the width of the y axis labels.
   */
  beforeLayout(chart, _args, opts) {
    const currentState = stateMap.get(chart)

    if (!currentState || currentState.hasGetLabelSizesOverwritten) return

    // @ts-ignore
    const original = chart.scales.y._getLabelSizes

    // @ts-ignore
    chart.scales.y._getLabelSizes = function () {
      // @ts-ignore
      const originalSizes = original.call(this) as LabelSizes

      const myWidest = originalSizes.widest.width
      const groupState = groupMap.get(opts.group)

      if (!groupState?.widest || myWidest > groupState.widest) {
        // Update the "widest" value for the other groups to use.
        updateGroupWidestYAxis(opts.group, chart.id, myWidest)

        // Tell the other charts about the new value.
        const detail: DetailWidest = {
          type: 'yaxis-width',
          source: chart.id,
        }
        window.dispatchEvent(new CustomEvent(SYNC_EVENT, { detail }))
      }

      originalSizes.widest.width = groupState?.widest ?? myWidest

      return originalSizes
    }

    currentState.hasGetLabelSizesOverwritten = true
  },
  afterLayout(chart, _args, opts) {
    // Capture the size of the secondary Y axis.
    if (!opts.disableY2AxisSync && chart.scales[Y2_AXIS]) {
      saveY2AxisWidth(opts.group, chart)
    }
  },
  beforeUpdate(chart, _args, opts) {
    // This method is called as part of any update before the chart is rendered.
    // Since new chart config is passed in on every update (via vue-chartjs),
    // we use this method to add the event handlers we need to track zoom/pan events.
    // The zoom plugin does not provide a separate API to access pan/zoom events.

    // When the user pans, fire an event that other charts observe
    if (chart.config.options?.plugins?.zoom?.pan) {
      chart.config.options.plugins.zoom.pan.onPan = onPanZoom
    }
    // When the user zooms, fire an event that other charts observe
    if (chart.config.options?.plugins?.zoom?.zoom) {
      chart.config.options.plugins.zoom.zoom.onZoom = onPanZoom
    }

    // Add padding right only on the charts with no secondary Y axis.
    if (!opts.disableY2AxisSync && !chart.scales[Y2_AXIS]) {
      addRightPadding(opts.group, chart)
    }
  },
  afterEvent(chart, args, options) {
    if (args.event.type === 'mousemove') {
      onMouseMove(chart, args.inChartArea, options.group)
    } else if (args.event.type === 'mouseout') {
      // Tell the other charts to hide their tooltips
      if (options.group) dispatchHover(chart, null)
    }
  },
}

/**
 * This function is called while the user is hovering over the chart.
 * When hovering outside the chart area, the tooltip is hidden.
 */
function onMouseMove(chart: Chart, inChartArea: boolean, group?: string) {
  if (!inChartArea) {
    // The mouse has left the chart area, so hide tooltip in this chart
    // and dispatch an event to other charts in the group.
    if (clearActiveElements(chart)) {
      chart.update('none')
      if (group) dispatchHover(chart, null)
    }
  } else if (group) {
    // Tell the other charts to set active elements, showing the tooltip
    dispatchHover(chart, getActiveElementDataPoint(chart))
  }
}

/**
 * Send a `hover` event to all charts in this group
 */
function dispatchHover(chart: Chart, activeDataPoint: Point | null) {
  const detail: Detail = {
    type: 'hover',
    source: chart.id,
    activeDataPoint,
  }
  window.dispatchEvent(new CustomEvent(SYNC_EVENT, { detail }))
}

/**
 * This function is called during a pan, while the user is dragging,
 * or a zoom, while the user is moving the scroll wheel. It dispatches a sync event
 * to other charts in the group so they can update their chart x-axis along with
 * the chart the user is moving.
 */
function onPanZoom(ctxt: { chart: Chart }) {
  const detail: Detail = {
    type: 'pan/zoom',
    source: ctxt.chart.id,
    hideTooltip: true,
    xMin: ctxt.chart.scales[X_SCALE].min,
    xMax: ctxt.chart.scales[X_SCALE].max,
  }
  window.dispatchEvent(new CustomEvent(SYNC_EVENT, { detail }))
}

/**
 * This function handles any sync event dispatched by the plugin.
 * It currently supports updating the x-axis during a pan or zoom,
 * and maintaining active elements for hover & tooltips.
 */
function syncEventHandler(chart: Chart, e: Event) {
  const event = e as CustomEvent<Detail>
  const { source, type } = event.detail

  if (type === 'pan/zoom') {
    // Hide the tooltip while charts are being panned
    if (event.detail.hideTooltip) {
      clearActiveElements(chart)
    }
    // Handle a pan/zoom sync event by updating the x-axis for all the charts
    // except the one that fired the event
    if (source === chart.id) return

    if (chart.options.scales?.[X_SCALE]) {
      chart.options.scales[X_SCALE].min = event.detail.xMin
      chart.options.scales[X_SCALE].max = event.detail.xMax
      chart.update('none') // 'none' means don't do any animation for the update
    }
  } else if (type === 'hover') {
    // Ignore my own hover sync event
    if (source === chart.id) return

    // Push the execution to the next tick.
    const { activeDataPoint } = event.detail
    setTimeout(() => {
      // The chart may have been unmounted before this code is called
      if (chart.canvas == null) return

      if (activeDataPoint) {
        setActiveElementsFromData(chart, activeDataPoint)
      } else {
        clearActiveElements(chart)
      }
      // The chart must be updated to re-render
      chart.update('none')
    }, 0)
  } else if (type === 'yaxis-width') {
    // Skip, is the same chart that emitted the event.
    if (event.detail.source === chart.id) return

    // Force the chart to update so "_getLabelSizes" is called again.
    chart.update('none')
  }
}

function addChartIdToGroupState(groupId: string, chartId: string): void {
  const newGroupState: GroupState = {
    ...groupMap.get(groupId),
    currentActiveCharts: [
      ...(groupMap.get(groupId)?.currentActiveCharts ?? []),
      chartId,
    ],
  }
  groupMap.set(groupId, newGroupState)
}

function removeChartIdFromGroupState(groupId: string, chartId: string): void {
  const groupState = groupMap.get(groupId)

  if (groupState) {
    groupState.currentActiveCharts = groupState.currentActiveCharts.filter(
      (id) => id !== chartId
    )

    // Delete the group state when no more active charts.
    if (groupState.currentActiveCharts.length === 0) {
      groupMap.delete(groupId)
    }
  }
}

function updateGroupWidestYAxis(
  groupId: string,
  chartId: string,
  newWidest: number
): void {
  const state = groupMap.get(groupId)
  if (state && state.currentActiveCharts.includes(chartId)) {
    state.widest = newWidest
  }
}

function saveY2AxisWidth(groupId: string, chart: Chart): void {
  const groupState = groupMap.get(groupId)

  if (!groupState || !chart.scales[Y2_AXIS]) return

  // The chart contains a secondary Y axis.
  const y2Width = chart.scales[Y2_AXIS].right - chart.scales[Y2_AXIS].left

  // Save.
  if (y2Width > (groupState.y2Width ?? 0)) {
    groupState.y2Width = y2Width
  }
}

function addRightPadding(groupId: string, chart: Chart): void {
  chart.config.options = merge(chart.config.options, {
    layout: { padding: { right: groupMap.get(groupId)?.y2Width } },
  })
}

export default SyncPlugin
