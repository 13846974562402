import { DateTime, Interval } from 'luxon'
import { Services } from '@/services'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import { getPowerRating } from '@/model/resource'
import type { DERImpactsStatsResponse } from 'rfs/frontend/proto/analysis_pb'
import { Genesis, type Resource } from 'rfs/pb/resource_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { MetricCalculation } from '@/services/charts.service'

export function getChargerIds(chargers: Resource[]): {
  detected: string[]
  known: string[]
} {
  return chargers.reduce<{
    detected: string[]
    known: string[]
  }>(
    (acc, { id, record }) => {
      const key = record?.genesis === Genesis.DETECTION ? 'detected' : 'known'
      acc[key].push(id)
      return acc
    },
    { detected: [], known: [] }
  )
}

export function getNumberOfEvs(chargers: Resource[]): {
  total: number
  detected: number
  known: number
  installedCapacity: number
} {
  const { detected, known } = getChargerIds(chargers)

  return {
    total: detected.length + known.length,
    detected: detected.length,
    known: known.length,
    installedCapacity: chargers.reduce<number>((acc, charger) => {
      return acc + (getPowerRating(charger) ?? 0)
    }, 0),
  }
}

export function getEvSystemPeak({
  peakLoad,
  peakLoadTime,
  evLoadAtPeak,
  evLoadAtPeakPercent,
}: DERImpactsStatsResponse): {
  total: number
  timeOfPeak: DateTime
  evLoadAtPeak: number
  evLoadAtPeakPercent: number
} {
  return {
    total: peakLoad,
    timeOfPeak: DateTime.fromMillis(peakLoadTime?.toMillis() ?? 0),
    evLoadAtPeak,
    evLoadAtPeakPercent,
  }
}

export function getPvSystemPeak({
  peakLoad,
  peakLoadTime,
  pvProductionAtPeak,
  pvProductionAtPeakPercent,
}: DERImpactsStatsResponse): {
  total: number
  timeOfPeak: DateTime
  pvProductionAtPeak: number
  pvProductionAtPeakPercent: number
} {
  return {
    total: peakLoad,
    timeOfPeak: DateTime.fromMillis(peakLoadTime?.toMillis() ?? 0),
    pvProductionAtPeak,
    pvProductionAtPeakPercent,
  }
}

export function getNumberOfPvs(distributedSolars: Resource[]): {
  total: number
  metered: number
  estimated: number
  installedCapacity: number
} {
  return {
    total: distributedSolars.length,
    metered: distributedSolars.length,
    estimated: 0,
    installedCapacity: distributedSolars.reduce<number>((acc, dSolar) => {
      return acc + (getPowerRating(dSolar) ?? 0)
    }, 0),
  }
}

export async function getPeakEvLoad(
  services: Services,
  interval: Interval,
  detected: string[],
  known: string[]
): Promise<{
  peak?: number
  timeOfPeak?: DateTime
  peakOfDetected?: number
  peakOfKnown?: number
}> {
  const result: {
    peak?: number
    timeOfPeak?: DateTime
    peakOfDetected?: number
    peakOfKnown?: number
  } = {}

  try {
    // Find when the EV Peak happened.
    const res1 = await services.chartsService.fetchTimeSeries(
      'aggregation/SYSTEM',
      {
        interval,
        metrics: [{ metric: Metric.COND_POWER_REAL }],
        aggregation: ResourceType.CHARGER,
        resolution: Resolution.FIFTEEN_MINUTE,
      }
    )

    if (!res1.series.length) throw new Error('unexpected no series available')

    const { max } = res1.series[0]

    if (!max) throw new Error('unexpected no "max"')

    result.peak = max.y
    result.timeOfPeak = DateTime.fromMillis(max.x)

    const constrainedInterval = Interval.fromDateTimes(
      result.timeOfPeak.minus({ minute: 30 }),
      result.timeOfPeak.plus({ minute: 30 })
    )

    const fetch = {
      interval: constrainedInterval,
      metrics: [
        { metric: Metric.COND_POWER_REAL, calculation: MetricCalculation.SUM },
      ],
      resolution: Resolution.FIFTEEN_MINUTE,
    }

    const [res2, res3] = await Promise.all([
      services.chartsService.fetchMultiTimeSeries(detected, fetch),
      services.chartsService.fetchMultiTimeSeries(known, fetch),
    ])

    result.peakOfDetected = res2.series?.[0]?.data.find((dp) => {
      return dp.x === max.x
    })?.y
    result.peakOfKnown = res3.series?.[0]?.data.find((dp) => {
      return dp.x === max.x
    })?.y
  } catch (err) {
    console.error('getPeakEvLoad: %o', err)
  }

  return result
}

export async function getPeakPvLoad(
  services: Services,
  interval: Interval,
  distributedSolarIds: string[]
): Promise<{
  peak?: number
  timeOfPeak?: DateTime
  peakOfMetered?: number
  peakOfEstimated?: number
}> {
  const result: {
    peak?: number
    timeOfPeak?: DateTime
    peakOfMetered?: number
    peakOfEstimated?: number
  } = {}

  try {
    // Find when the PV Peak happened.
    const res1 = await services.chartsService.fetchTimeSeries(
      'aggregation/SYSTEM',
      {
        interval,
        metrics: [{ metric: Metric.COND_POWER_REAL, unit: '-W' }],
        aggregation: ResourceType.SOLAR_DISTRIBUTED,
        resolution: Resolution.FIFTEEN_MINUTE,
      }
    )

    if (!res1.series.length) throw new Error('unexpected no series available')

    const { max } = res1.series[0]

    if (!max) throw new Error('unexpected no "max"')

    result.peak = max.y
    result.timeOfPeak = DateTime.fromMillis(max.x)

    const constrainedInterval = Interval.fromDateTimes(
      result.timeOfPeak.minus({ minute: 30 }),
      result.timeOfPeak.plus({ minute: 30 })
    )

    const res2 = await services.chartsService.fetchMultiTimeSeries(
      distributedSolarIds,
      {
        interval: constrainedInterval,
        metrics: [
          {
            metric: Metric.POWER_PRODUCED,
            calculation: MetricCalculation.SUM,
          },
        ],
        resolution: Resolution.FIFTEEN_MINUTE,
      }
    )

    result.peakOfMetered = res2.series?.[0]?.data.find((dp) => {
      return dp.x === max.x
    })?.y
  } catch (err) {
    console.error('getPeakPvLoad: %o', err)
  }

  return result
}
