import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import Alerts from '@/views/Alerts.vue'
import GridImpact from '@/components/analyze/GridImpact.vue'
import AnalyzeDashboard from '@/components/analyze/analyzeDashboard/AnalyzeDashboard.vue'
import ForecastDashboard from '@/components/analyze/forecast/ForecastDashboard.vue'
import ForecastQuality from '@/components/analyze/forecast/ForecastQuality.vue'
import Insights from '@/components/analyze/insights/Insights.vue'

export const TAB = TabId.ANALYZE

export const TITLE_SUPPLY_AND_DEMAND = 'Supply & Demand'
export const TITLE_ALERTS = 'Alerts'
export const TITLE_FORECAST = 'Forecast'
export const TITLE_FORECAST_QUALITY = 'Forecast Quality'
export const TITLE_GRID_COMPONENTS = 'Grid Components'
export const TITLE_INSIGHTS = 'Insights'

export function analyzeRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  const meta = { tab: TAB }

  const routes: RouteRecordRaw[] = []

  // Supply & Demand
  if (config.analysis?.supplyAndDemand?.enabled) {
    routes.push({
      path: '/analyze/supplyAndDemand',
      name: RouteNames.SUPPLY_AND_DEMAND,
      meta: { ...meta, pageTitle: TITLE_SUPPLY_AND_DEMAND },
      component: AnalyzeDashboard,
    })
  }

  // Alerts
  if (config.analysis?.alerts?.enabled) {
    routes.push({
      path: '/analyze/alerts/:alertId?',
      component: Alerts,
      name: RouteNames.ALERTS,
      meta: { ...meta, pageTitle: TITLE_ALERTS },
      props: true,
    })
  }

  // Forecast
  if (config.analysis?.forecastDashboard) {
    routes.push({
      path: '/analyze/forecast',
      name: RouteNames.FORECAST,
      meta: { ...meta, pageTitle: TITLE_FORECAST },
      component: ForecastDashboard,
    })
  }

  // Forecast Quality
  if (config.analysis?.forecastQuality) {
    routes.push({
      path: '/analyze/forecast/forecastQuality',
      name: RouteNames.FORECAST_QUALITY,
      meta: { ...meta, pageTitle: TITLE_FORECAST_QUALITY },
      component: ForecastQuality,
    })
  }

  // Grid Components
  if (config.gridImpact?.enabled) {
    routes.push({
      path: '/analyze/grid-impact',
      component: GridImpact,
      name: RouteNames.GRID_IMPACT,
      meta: { ...meta, pageTitle: TITLE_GRID_COMPONENTS },
    })
  }

  // Insights
  const isEvEnabled = !!config.analysis?.insights?.ev
  const isPvEnabled = !!config.analysis?.insights?.pv
  const isLoadingEnabled = !!config.analysis?.insights?.loading

  if (isEvEnabled || isPvEnabled || isLoadingEnabled) {
    routes.push(
      {
        path: '/analyze/insights',
        name: RouteNames.INSIGHTS,
        meta: { ...meta, pageTitle: TITLE_INSIGHTS },
        redirect: () => {
          if (isEvEnabled) return { name: RouteNames.INSIGHTS_EV }
          if (isPvEnabled) return { name: RouteNames.INSIGHTS_PV }
          return { name: RouteNames.INSIGHTS_LOADING }
        },
      },
      {
        path: '/analyze/insights/ev',
        component: Insights,
        name: RouteNames.INSIGHTS_EV,
        meta: { ...meta, pageTitle: `${TITLE_INSIGHTS} - EV` },
        redirect: isEvEnabled ? undefined : { name: RouteNames.INSIGHTS },
        children: [],
      },
      {
        path: '/analyze/insights/pv',
        component: Insights,
        name: RouteNames.INSIGHTS_PV,
        meta: { ...meta, pageTitle: `${TITLE_INSIGHTS} - PV` },
        redirect: isPvEnabled ? undefined : { name: RouteNames.INSIGHTS },
        children: [],
      },
      {
        path: '/analyze/insights/loading',
        component: Insights,
        name: RouteNames.INSIGHTS_LOADING,
        meta: { ...meta, pageTitle: `${TITLE_INSIGHTS} - Loading` },
        redirect: isLoadingEnabled ? undefined : { name: RouteNames.INSIGHTS },
        children: [],
      }
    )
  }

  if (routes.length) {
    const initialRoute = config.analysis?.initialRoute ?? routes[0].name
    const mainRoute = {
      path: '/analyze',
      name: RouteNames.ANALYZE,
      redirect: { name: initialRoute },
    }
    routes.unshift(mainRoute)
  }

  return routes
}
