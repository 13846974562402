import { IconLayer } from '@deck.gl/layers'
import { MapLayerId, RittaConfig } from '@/config/types'
import { Services } from '@/services'
import { ResourceType } from '@/constants/resourceType'
import { ICON_SIZE } from '@/model/map'
import { MapLayerFeature, MapManagerLayer } from '@/model/map/types'
import { ApplicationMapLayer } from './catalog'
import {
  CustomTileLayer,
  getPosition,
  getTileResponseLayers,
  IconLoadNoAlpha,
} from './extensions'
import {
  TileResponse,
  TileResponse_GeometryType as TileResponseGeometryType,
} from 'rfs/frontend/proto/tile_pb'
import { FleetVehicleHelper } from '@/model/fleet/vehicle/helper'
import iconFleetVehicleApp from '@/assets/imgs/icon-trucks__black.svg'
import iconFleetVehicleHighlighted from '@/assets/imgs/icon-trucks__col-bg.svg'
import { Resource } from 'rfs/pb/resource_pb'

const ID = MapLayerId.FLEET

// TODO(rafael): convert `FleetVehicle` to `Resource` in the server.
// TODO(rafael): use Tile service.
async function getTileData(
  services: Services
): Promise<ReturnType<Services['tileService']['getTileResources']>> {
  return services.fleetService.fetchVehicles().then(
    (fleetVehicles) =>
      ({
        layers: [
          {
            geometryType: TileResponseGeometryType.POINT,
            features: fleetVehicles.map((fv): Partial<MapLayerFeature> => {
              // @ts-ignore
              // NOTE(rafael): adding the type only for the route.
              fv.type = ResourceType.VEHICLE
              // TODO(rafael): update when RFS returns FleetVehicle as a Resource.
              return { resource: fv as unknown as Resource }
            }),
          },
        ],
      } as TileResponse)
  )
}

function highlightLayer(features: MapLayerFeature[]): MapManagerLayer {
  return new IconLayer<MapLayerFeature>({
    data: features,
    pickable: true,
    getSize: ICON_SIZE,
    getPosition,
    getIcon: (_obj) => ({
      url: iconFleetVehicleHighlighted,
      width: ICON_SIZE,
      height: ICON_SIZE,
    }),
    loadOptions: IconLoadNoAlpha,
  })
}

function mapManagerLayer(_config: Readonly<RittaConfig>, services: Services) {
  return new CustomTileLayer({
    id: ID,
    minZoom: 1,
    maxZoom: 1,
    tileSize: 2 ** 12, // Larger tile size as there are fewer fleet vehicles in a grid
    pickable: true,
    getTileData: async (_props) => getTileData(services),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {})
    },
  })
}

export const Fleet: ApplicationMapLayer = {
  id: ID,
  label: 'Fleet',
  icon: iconFleetVehicleApp,
  infoWindow: {
    infoColumn: (f) =>
      FleetVehicleHelper.infoWindowInfoColumn(f.resource as any),
  },
  mapManagerLayer,
  highlightLayer,
}
