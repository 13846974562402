<template>
  <div class="c-ControlGroupDetailsSystemModeNotAuthorized">
    <!-- Loading current policy -->
    <div v-if="isLoadingCurrentPolicy" class="text-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <template v-if="showContent">
      <!-- Labe -->
      <span class="d-block text-caption"> Current policy </span>

      <!-- Default -->
      <span class="d-block mb-4">
        {{ currentPolicy }}
      </span>

      <!-- Message -->
      <span class="text-body-2">
        You do not have the ability to change this policy
      </span>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { GetGroupRequest } from 'rfs/control/proto/control_service_pb'
import { Policy } from 'rfs/control/proto/policy_pb'

const POLICY_LABEL_DEFAULT = 'Default'
const POLICY_LABEL_PEAK_EVENT = 'Peak Event'
const POLICY_LABEL_RESTRICTED = 'Restricted'

export default defineComponent({
  name: 'ControlGroupDetailsSystemModeNotAuthorized',
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoadingCurrentPolicy: false,
      hasLoadingFailed: false,
      isUnspecifiedPolicy: false,
      currentPolicy: undefined as undefined | string,
    }
  },
  computed: {
    /**
     * Computed property that controls when the content should be displayed
     * to the user.
     */
    showContent(): boolean {
      return (
        !this.isLoadingCurrentPolicy &&
        !this.hasLoadingFailed &&
        !this.isUnspecifiedPolicy
      )
    },
  },
  watch: {
    groupId(): void {
      this.fetchCurrentPolicy()
    },
  },
  created(): void {
    this.fetchCurrentPolicy()
  },
  methods: {
    async fetchCurrentPolicy(): Promise<void> {
      // Reset state.
      this.hasLoadingFailed = false
      this.isLoadingCurrentPolicy = true
      this.isUnspecifiedPolicy = false
      this.currentPolicy = undefined

      try {
        const req = new GetGroupRequest({ id: this.groupId })
        const res = await this.$services.control.getGroup(req)

        const currentPolicy = res.currentPolicy

        if (currentPolicy === Policy.UNSPECIFIED) {
          this.isUnspecifiedPolicy = true
          console.debug(
            `ControlGroupDetailsSystemModeNotAuthorized.fetchCurrentPolicy: unspecified policy.`
          )
          return
        }

        switch (currentPolicy) {
          // Generic.
          case Policy.DEFAULT:
            this.currentPolicy = POLICY_LABEL_DEFAULT
            break
          case Policy.PEAK_TIME_PAYBACK_EVENT:
            this.currentPolicy = POLICY_LABEL_PEAK_EVENT
            break
          // Chargepoint.
          case Policy.CHARGEPOINT_DEFAULT:
            this.currentPolicy = POLICY_LABEL_DEFAULT
            break
          case Policy.CHARGEPOINT_RESTRICTED:
            this.currentPolicy = POLICY_LABEL_RESTRICTED
            break
          // Powerwall.
          case Policy.TESLA_POWERWALL_DEFAULT:
            this.currentPolicy = POLICY_LABEL_DEFAULT
            break
          case Policy.TESLA_POWERWALL_PEAK_EVENT:
            this.currentPolicy = POLICY_LABEL_PEAK_EVENT
            break
          default:
            throw new Error(`policy "${currentPolicy}" not expected`)
        }
      } catch (err) {
        this.hasLoadingFailed = true
        console.error(
          'ControlGroupDetailsSystemModeNotAuthorized.fetchCurrentPolicy: %o',
          err
        )
      } finally {
        this.isLoadingCurrentPolicy = false
      }
    },
  },
})
</script>

<style lang="scss">
.c-ControlGroupDetailsSystemModeNotAuthorized {
  width: 100%;
}
</style>
