// Messages for publishing proxy data to Pub/Sub consumers.

// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file device/proto/pubsub.proto (package ritta.device.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * Report a bundle of metrics for a given (resource, timestamp) tuple.
 *
 * NEXT TAG: 4
 *
 * @generated from message ritta.device.proto.ResourceMeasurements
 */
export const ResourceMeasurements = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.ResourceMeasurements",
  () => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "measurements", kind: "message", T: ResourceMeasurements_Measurement, repeated: true },
  ],
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.device.proto.ResourceMeasurements.Measurement
 */
export const ResourceMeasurements_Measurement = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.device.proto.ResourceMeasurements.Measurement",
  () => [
    { no: 1, name: "metric", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "ResourceMeasurements_Measurement"},
);

