<template>
  <div class="d-flex">
    <!-- Control Mode -->
    <status-box title="Control mode">
      <!-- Policy Badge -->
      <policy-badge
        v-if="currentPolicy !== undefined"
        :current-policy="currentPolicy"
        :current-policy-params="currentPolicyParams"
        class="mb-2"
      />

      <span v-else class="text-h6">-</span>
    </status-box>

    <!-- Operating Envelope -->
    <status-box v-if="currentOEStatus !== undefined" title="Envelope status">
      <!-- Operating Envelope badge -->
      <operating-envelope-badge :status="currentOEStatus" />
    </status-box>

    <!-- Communication status -->
    <status-box
      v-if="lastUpdate && (communicationStatusMap || communicationStatus)"
      :isRefreshing
      title="Communication status"
      style="min-height: 5.75rem"
    >
      <!-- Badge -->
      <communication-status-badge
        :communicationStatus
        :communicationStatusMap
        class="mb-2"
      />

      <!-- As Of -->
      <span class="d-block text-caption" :style="{ color: asOfFontColor }">
        {{ asOf }}
      </span>
    </status-box>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { DateTime } from 'luxon'
import { NEUTRAL_600 } from '@/constants/colors'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import StatusBox from '@/components/control/StatusBox.vue'
import PolicyBadge from '@/components/control/PolicyBadge.vue'
import OperatingEnvelopeBadge from '@/components/control/OperatingEnvelopeBadge.vue'
import CommunicationStatusBadge from '@/components/control/CommunicationStatusBadge.vue'
import type { Policy, Params } from 'rfs/control/proto/policy_pb'
import type {
  CommunicationStatusMap,
  CommunicationStatusType,
} from '@/model/control/communicationStatus'

export default defineComponent({
  name: 'StatusBoxes',
  props: {
    isRefreshing: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentPolicy: {
      type: Number as PropType<Policy>,
      required: false,
    },
    currentPolicyParams: {
      type: Object as PropType<Params>,
      required: false,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
    communicationStatusMap: {
      type: Map as PropType<CommunicationStatusMap>,
      required: false,
    },
    communicationStatus: {
      type: Number as PropType<CommunicationStatusType>,
      required: false,
    },
    lastUpdate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  components: {
    StatusBox,
    PolicyBadge,
    OperatingEnvelopeBadge,
    CommunicationStatusBadge,
  },
  setup() {
    return { asOfFontColor: NEUTRAL_600.hex }
  },
  data() {
    return shallowReactive({ asOf: '' })
  },
  watch: {
    lastUpdate: {
      immediate: true,
      handler: function () {
        this.updateAsOfString()
      },
    },
  },
  methods: {
    updateAsOfString(): void {
      if (!this.lastUpdate) return

      this.asOf = `As of ${this.lastUpdate.toLocaleString(
        DateTime.TIME_24_WITH_SHORT_OFFSET
      )}`
    },
  },
})
</script>
