// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/controls.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { ScheduleRequest_WayPoints } from "../../control/proto/control_service_pb.js";
import { TimeSeriesRequest_Frame, TimeSeriesResponse } from "./tsdb_pb.js";
import { Resolution } from "./resolution_pb.js";
import { Waypoint } from "../../control/proto/waypoints_pb.js";

/**
 * @generated from message ritta.frontend.proto.DeviceID
 */
export const DeviceID = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DeviceID",
  () => [
    { no: 1, name: "group", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "devices", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ScheduleTimeSeriesRequest
 */
export const ScheduleTimeSeriesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ScheduleTimeSeriesRequest",
  () => [
    { no: 1, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 3, name: "waypoints_list", kind: "message", T: ScheduleRequest_WayPoints },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
    { no: 6, name: "metrics", kind: "message", T: TimeSeriesRequest_Frame, repeated: true },
    { no: 7, name: "resolution", kind: "enum", T: proto3.getEnumType(Resolution) },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ScheduleTimeSeriesResponse
 */
export const ScheduleTimeSeriesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ScheduleTimeSeriesResponse",
  () => [
    { no: 1, name: "timeSeries", kind: "message", T: TimeSeriesResponse },
    { no: 2, name: "waypoints_list", kind: "message", T: Waypoint, repeated: true },
  ],
);

