import { RittaConfig } from '@/config'
import { CILANTRO, GREEN1, GREEN2, Hex, WALLABY } from '@/constants/colors'
import { STANDARD_COLORS, THREE_PHASE } from '@/constants/colorPalette'
import { DEFAULT_HEIGHT } from '@/constants/infiniteScrollChart'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import { Services } from '@/services'
import { MetricCalculation } from '@/services/charts.service'
import {
  AggregateDataSource,
  ITimeSeriesDataSource,
  MetricWithConfig,
  TimeSeriesConfig,
  TimeSeriesDataSource,
} from '@/model/charts'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { ChartType, ChartDefinition } from '@/types/charts'
import { Config_Phasing as ConfigPhasing, Resource } from 'rfs/pb/resource_pb'
import { getUnqualifiedId } from './index'

const PHASE_A = {
  seriesName: 'Phase A',
  seriesColor: THREE_PHASE[0].hex,
}
const PHASE_B = {
  seriesName: 'Phase B',
  seriesColor: THREE_PHASE[1].hex,
}
const PHASE_C = {
  seriesName: 'Phase C',
  seriesColor: THREE_PHASE[2].hex,
}

const activePowerChart: ChartDefinition = {
  id: 'active-power',
  title: 'Total active power',
  type: ChartType.Power,
  isAreaChart: true,
}

const apparentPowerChart: ChartDefinition = {
  id: 'apparent-power',
  title: 'Total apparent power',
  type: ChartType.ApparentPower,
  isAreaChart: true,
  visible: 'requires-data',
}

const reactivePowerChart: ChartDefinition = {
  id: 'reactive-power',
  title: 'Total reactive power',
  type: ChartType.ReactivePower,
  isAreaChart: true,
  visible: 'requires-data',
}

const powerFactorChart: ChartDefinition = {
  id: 'power-factor',
  title: 'Power factor',
  type: ChartType.Percentage,
  visible: 'requires-data',
}

const voltageChart: ChartDefinition = {
  id: 'voltage-chart',
  title: 'Voltage',
  type: ChartType.Voltage,
}

const currentChart: ChartDefinition = {
  id: 'current-chart',
  title: 'Current',
  type: ChartType.Current,
}

// TODO: neutral current chart
const neutralCurrentChart: ChartDefinition = {
  id: 'neutral-current',
  title: 'Neutral current',
  type: ChartType.Current,
  visible: 'requires-data',
}

export const telemetryChartDefinitions: ChartDefinition[] = [
  activePowerChart,
  apparentPowerChart,
  reactivePowerChart,
  powerFactorChart,
  voltageChart,
  currentChart,
  neutralCurrentChart,
].map((cd) => {
  cd.persistVisibility = true
  return cd
})

export const compareChartDefinition: ChartDefinition = {
  id: 'compare-chart',
  title: 'Compare loads',
  type: ChartType.Power,
  seriesColors: STANDARD_COLORS,
  height: DEFAULT_HEIGHT,
}

export function newRealPowerMetricWithConfig(
  seriesName: string,
  seriesColor: Hex = CILANTRO.hex
): MetricWithConfig {
  // "System Total" and "Selected Total" are a calculation
  const needsSum = seriesName.endsWith(' Total')
  return {
    metric: Metric.COND_POWER_REAL,
    calculation: needsSum ? MetricCalculation.SUM : undefined,
    config: { seriesName, seriesColor },
  }
}

export function newTimeSeriesDataSource(
  config: Readonly<RittaConfig>,
  services: Services,
  resource: Resource
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) => {
    return services.chartsService.fetchTimeSeries(resource.id, {
      ...request,
      resolution: Resolution.FIVE_MINUTE,
    })
  })

  // Active Power.
  ds.addChartSeries(
    activePowerChart,
    newRealPowerMetricWithConfig(getPowerSeriesName(resource))
  )
  // Apparent Power
  ds.addChartSeries(apparentPowerChart, {
    metric: Metric.COND_POWER_APPARENT,
    config: { seriesName: 'Apparent power', seriesColor: CILANTRO.hex },
  })
  // Reactive Power
  ds.addChartSeries(reactivePowerChart, {
    metric: Metric.COND_POWER_REACTIVE,
    config: { seriesName: 'Reactive power', seriesColor: CILANTRO.hex },
  })

  // Power Factor
  ds.addChartSeries(powerFactorChart, {
    metric: Metric.COND_POWER_FACTOR_A,
    config: PHASE_A,
  })
  ds.addChartSeries(powerFactorChart, {
    metric: Metric.COND_POWER_FACTOR_B,
    config: PHASE_B,
  })
  ds.addChartSeries(powerFactorChart, {
    metric: Metric.COND_POWER_FACTOR_C,
    config: PHASE_C,
  })

  // Voltage
  getVoltageMetrics(resource, ConfigPhasing.ABC).forEach((config) => {
    ds.addChartSeries(voltageChart, config)
  })

  // Current
  ds.addChartSeries(currentChart, {
    metric: Metric.COND_CURRENT_A,
    unit: 'A',
    config: PHASE_A,
  })
  ds.addChartSeries(currentChart, {
    metric: Metric.COND_CURRENT_B,
    unit: 'A',
    config: PHASE_B,
  })
  ds.addChartSeries(currentChart, {
    metric: Metric.COND_CURRENT_C,
    unit: 'A',
    config: PHASE_C,
  })
  ds.addChartSeries(neutralCurrentChart, {
    metric: Metric.COND_NEUTRAL_CURRENT,
    unit: 'A',
    config: { seriesName: 'Neutral current', seriesColor: CILANTRO.hex },
  })

  // The aggregate AMI data will be available if the Grid Impact feature is enabled
  // for resources of this type.
  if (config.gridImpact?.resourceTypes?.includes(resource.type)) {
    const ds2 = new TimeSeriesDataSource((request) => {
      request.aggregation = ResourceType.METER_ELECTRICAL
      return services.chartsService.fetchTimeSeries(resource.id, request)
    })
    ds2.addChartSeries(activePowerChart, {
      metric: Metric.COND_POWER_REAL,
      aggregation: ResourceType.METER_ELECTRICAL,
      config: { seriesName: 'Aggregated AMI', seriesColor: WALLABY.hex },
    })

    return new AggregateDataSource(ds, ds2)
  }

  return ds
}

const resolution = Resolution.FIFTEEN_MINUTE

export function newComparisonChartDataSource(rId: string, services: Services) {
  const ds = new TimeSeriesDataSource((request) => {
    // TODO(andrew): remove when we move to Bigtable
    request.resolution = resolution
    return services.chartsService.fetchTimeSeries(rId, request)
  })

  ds.addChartSeries(
    compareChartDefinition,
    newRealPowerMetricWithConfig(
      rId,
      '' as Hex // comparison chart uses custom colors
    )
  )

  return ds
}

export function newAggregateComparisonChartDataSource(
  services: Services,
  rIds: string[]
): TimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) =>
    services.chartsService.fetchTimeSeriesForMultiResources(rIds, {
      ...request,
      resolution,
    })
  )

  ds.addChartSeries(
    compareChartDefinition,
    newRealPowerMetricWithConfig('Selected Total', GREEN2.hex)
  )

  return ds
}

export function allSubstationsDataSource(
  substations: Resource[],
  services: Services
): TimeSeriesDataSource {
  const ids = substations.map((s) => s.id)
  const ds = new TimeSeriesDataSource((request) =>
    services.chartsService.fetchMultiTimeSeries(ids, {
      ...request,
      resolution,
    })
  )

  ds.addChartSeries(
    compareChartDefinition,
    newRealPowerMetricWithConfig('System Total')
  )

  return ds
}

/**
 * Return voltage metrics based on the resource's phasing.
 * If the resource is 3-phase, the metrics will be for each phase.
 * The `defaultPhase` is used when `config.phasing` is undefined.
 */
export function getVoltageMetrics(
  resource: Resource,
  defaultPhase: ConfigPhasing
): TimeSeriesConfig[] {
  const phasing = resource.config?.phasing ?? defaultPhase

  if (phasing === ConfigPhasing.ABC) {
    return [
      {
        metric: Metric.COND_VOLTAGE_A,
        config: PHASE_A,
      },
      {
        metric: Metric.COND_VOLTAGE_B,
        config: PHASE_B,
      },
      {
        metric: Metric.COND_VOLTAGE_C,
        config: PHASE_C,
      },
    ]
  } else {
    return [
      {
        metric: Metric.COND_VOLTAGE,
        config: { seriesName: 'Voltage', seriesColor: GREEN1.hex },
      },
    ]
  }
}

/**
 * If the resource has a sensor, the active power will come from the sensor.
 */
function getPowerSeriesName(resource: Resource): string {
  return resource.device?.sensor
    ? `Sensor (${getUnqualifiedId(resource.device.sensor)})`
    : 'SCADA'
}
