<template>
  <form class="c-ManualForm pl-12" @submit.prevent="submitManualSetpoint">
    <!-- Only when Operating Envelope enabled -->
    <p class="text-area pb-8" v-if="isOperatingEnvelopeEnabled">{{ oeText }}</p>

    <div class="input-area">
      <!-- Input -->
      <v-text-field
        type="number"
        step="0.1"
        :value="formFields.setpoint"
        :error="!!setpointErrors.length"
        :error-messages="setpointErrors"
        :hide-details="!setpointErrors.length"
        class="hide-arrows pr-6"
        color="secondary"
        style="max-width: 24rem"
        @keydown="restrictToFloatInput"
        @update:model-value="updateSetpoint"
      >
        <!-- Prepend -->
        <template v-slot:prepend>
          <span class="d-block pr-2" aria-label="Label">{{ prependText }}</span>

          <!-- Tooltip -->
          <div v-if="tooltipText" class="d-flex align-center pr-6">
            <ce-tooltip :text="tooltipText" type="info" />
          </div>
        </template>

        <!-- Append -->
        <template v-slot:append>
          <span aria-label="Unit">{{ appendText }}</span>
        </template>
      </v-text-field>
    </div>

    <div class="action-btns-area d-flex">
      <!-- Cancel -->
      <v-btn
        :disabled="isSubmitting"
        color="primary"
        class="mr-2"
        variant="outlined"
        @click="$emit('cancel')"
      >
        Cancel
      </v-btn>

      <!-- Submit -->
      <v-btn
        type="submit"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        color="primary"
        flat
      >
        {{ submitText }}
      </v-btn>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { restrictToFloatInput } from '@/utils/input'
import {
  Metric,
  manualFormSetpointLabel,
  manualFormLabel,
  manualFormTooltipText,
  manualFormSubmitLabel,
} from '@/model/control/waypoint'
import { BatteryManualForm, ChargerManualForm } from '@/model/control/manual'
import { ValidationResult } from '@/model/forms/FormFields'
import CeTooltip from '@/components/CeTooltip.vue'
import {
  Policy,
  Params,
  Params_ManualControlEvent as ParamsManualControlEvent,
} from 'rfs/control/proto/policy_pb'
import type { Device } from 'rfs/control/proto/model_pb'

// NOTE(rafael): These are the metrics that this component supports for now.
export const validMetrics = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS,
  Metric.METRIC_SOC_PERCENT,
  Metric.METRIC_SOC_WATT_HOURS,
  Metric.METRIC_ACTIVE_POWER_WATTS,
]

export default defineComponent({
  name: 'ManualForm',
  props: {
    metric: {
      type: Number as PropType<Metric>,
      required: true,
      validator(value, _props) {
        return validMetrics.includes(value as Metric)
      },
    },
    groupId: {
      type: String,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CeTooltip },
  setup() {
    const globalSnackbarStore = useGlobalSnackbar()

    return {
      restrictToFloatInput,
      globalSnackbarStore,
      oeText:
        'Operating envelopes will be unavailable for all DERs in the group, and cannot be activated at the single device level while the group is in this control mode.',
    }
  },
  data() {
    return {
      isSubmitting: false,
      formFields:
        this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
          ? new ChargerManualForm()
          : new BatteryManualForm(),
      formValidation: undefined as
        | undefined
        | ValidationResult<BatteryManualForm>,
    }
  },
  computed: {
    prependText(): string {
      return manualFormLabel(this.metric)
    },
    appendText(): string {
      return manualFormSetpointLabel(this.metric)
    },
    tooltipText(): null | string {
      return manualFormTooltipText(this.metric)
    },
    submitText(): string {
      return manualFormSubmitLabel(this.metric)
    },
    setpointErrors(): string[] {
      return this.formValidation?.setpoint ?? []
    },
  },
  methods: {
    updateSetpoint(newValue: null | string): void {
      this.formFields.setpoint = newValue ?? ''
    },
    async submitManualSetpoint(): Promise<void> {
      // First, validate input.
      this.formValidation = this.formFields.validate()
      if (!this.formValidation.isValid) return

      this.$emit('submitting', true)
      this.isSubmitting = true

      try {
        // Turn off all Operating ENvelopes.
        if (this.isOperatingEnvelopeEnabled) {
          if (!this.devices.length) throw new Error('no devices available')

          await this.$services.control.updateDevices({
            updates: this.devices.map((d) => {
              return { deviceId: d.id, oeEnabled: false }
            }),
          })
        }

        await this.$services.control.setGroupPolicy({
          policy: Policy.MANUAL_CONTROL_EVENT,
          params: new Params({
            paramsOneof: {
              case: 'manualControlEvent',
              value: new ParamsManualControlEvent({
                // NOTE(rafael): The user expects to be sending as "kW"
                // (kilo watts), but the server is expecting "w"
                // (watts).
                activePowerSetpoint: Number(this.formFields.setpoint) * 1_000,
              }),
            },
          }),
          ids: [this.groupId],
        })

        this.globalSnackbarStore.openSnackbar(
          'Manual mode successfully activated.',
          'info'
        )

        this.$emit('success')
      } catch (err) {
        this.globalSnackbarStore.openSnackbar(
          (err as any).message || 'Something went wrong. try again.',
          'error'
        )
        console.error('ManualForm.submitManualSetpoint: %o', err)
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
  },
})
</script>

<style lang="scss">
.c-ManualForm {
  display: grid;
  grid-template-columns: 1fr 0fr;
  grid-template-rows: auto auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  .text-area {
    grid-area: 1 / 1 / 2 / 2;
  }
  .input-area {
    grid-area: 2 / 1 / 3 / 2;
  }
  .action-btns-area {
    grid-area: 2 / 2 / 3 / 3;
  }
}
</style>
