<template>
  <section aria-label="Impacts">
    <!-- Resources Downline table -->
    <resource-impacts-summary-table :impact="impact" :is-loading="isLoading" />

    <!-- Charts -->
    <resource-impacts-charts
      :isLoading
      :resource
      :impact
      :interval
      @new-interval="handleNewInterval"
    />

    <!-- Divider -->
    <v-divider class="my-6" :color="NEUTRAL_200.hex" style="opacity: 1" />

    <!-- Transformer Downline Meters Table -->
    <resource-impacts-transformer-meters-table
      v-if="isTransformer"
      :resource
      :interval
      @new-interval="handleNewInterval"
    />

    <!-- Resource Downline Meters Table -->
    <resource-impacts-meters-table v-else :resource />
  </section>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, PropType } from 'vue'
import { mapState } from 'pinia'
import { Interval } from 'luxon'
import { NEUTRAL_200 } from '@/constants/colors'
import { Timestamp } from '@/services/timestamp_pb'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { useGridImpactsStore } from '@/stores/gridImpacts'
import { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { intervalLastPeriod, periodFromLastInterval } from '@/utils/time'
import { ResourceType } from '@/constants/resourceType'
import ResourceImpactsSummaryTable from './ResourceImpactsSummaryTable.vue'
import ResourceImpactsCharts from './ResourceImpactsCharts.vue'
import ResourceImpactsMetersTable from './ResourceImpactsMetersTable.vue'
import ResourceImpactsTransformerMetersTable from './ResourceImpactsTransformerMetersTable.vue'

const errNoImpactData = `AMI aggregations not available for this device
 because connectivity could not be established for the feeder,
 or because this device type is not part of the system connectivity model.`

export default defineComponent({
  name: 'GridImpactResource',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: {
    ResourceImpactsSummaryTable,
    ResourceImpactsCharts,
    ResourceImpactsMetersTable,
    ResourceImpactsTransformerMetersTable,
  },
  setup() {
    return { NEUTRAL_200 }
  },
  data() {
    // On first render, we copy the values from the Grid Store into local state.
    // Subsequent changes will be applied locally only,
    // without affecting the global store.
    const store = useGridImpactsStore()

    // The initial interval is based on the table's period (last 30 days or last 12 months).
    return shallowReactive({
      interval: intervalLastPeriod(store.period, this.$observationTime()),
      isLoading: false,
      impact: new ResourceImpactResponse(),
    })
  },
  computed: {
    ...mapState(useGridImpactsStore, ['period', 'modelParams']),
    isTransformer(): boolean {
      return this.resource.type === ResourceType.TRANSFORMER
    },
  },
  watch: {
    resource: {
      immediate: true,
      handler: function () {
        this.fetchData({ isNewResource: true })
      },
    },
    interval() {
      this.fetchData()
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
    async fetchData(opts?: { isNewResource: boolean }): Promise<void> {
      this.isLoading = true

      // Only reset the state when a new resource.
      // Keep the state untouched between param changes (model maker and interval).
      if (opts?.isNewResource) {
        this.impact = new ResourceImpactResponse()
      }

      try {
        this.impact = await this.$services.analysisService.resourceDERImpact({
          resource: this.resource.id,
          start: Timestamp.fromDateTime(this.interval.start),
          end: Timestamp.fromDateTime(this.interval.end),
          // If the interval matches a period, we can request it.
          fixedInterval: periodFromLastInterval(this.interval),
        })
      } catch (err: any) {
        if (err.message.includes('no impact data')) {
          useGlobalSnackbar().openSnackbar(errNoImpactData, 'info')
        } else {
          console.error('GridImpactResource.fetchData: %o', err)
        }
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
