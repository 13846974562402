// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/fleet.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Location, Vehicle } from "../../pb/resource_pb.js";

/**
 * A wrapper around ritta.pb.Vehicle that contains fleet-specific fields
 * NEXT TAG: 8
 *
 * @generated from message ritta.frontend.proto.FleetVehicle
 */
export const FleetVehicle = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.FleetVehicle",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "group_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "vehicle", kind: "message", T: Vehicle },
    { no: 3, name: "location", kind: "message", T: Location },
    { no: 4, name: "updated", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message ritta.frontend.proto.VehiclesRequest
 */
export const VehiclesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.VehiclesRequest",
  [],
);

/**
 * @generated from message ritta.frontend.proto.VehiclesResponse
 */
export const VehiclesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.VehiclesResponse",
  () => [
    { no: 1, name: "vehicles", kind: "message", T: FleetVehicle, repeated: true },
  ],
);

