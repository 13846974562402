<template>
  <div class="position-relative min-height-100 pb-12 px-6">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-else>
      <!-- Title and Meter Number -->
      <resource-header
        :breadcrumbs="breadcrumbs"
        :items="headerItems"
        class="pb-10"
      />

      <!-- Charts -->
      <utility-solar-charts
        v-if="utilitySolar"
        :utility-solar="utilitySolar"
        class="mb-12"
      />

      <!-- More Info -->
      <div
        data-test="more-info"
        v-if="solarArrayImage"
        class="d-flex pa-4"
        style="border: 1px solid lightgrey"
      >
        <div class="flex-grow-1 flex-shrink-1 pr-4" style="flex-basis: 0%">
          <!-- Solar Array -->
          <span class="d-block text-h6 font-weight-medium mb-4">
            {{ title }}
          </span>
        </div>

        <!-- Info -->
        <div class="flex-grow-1 flex-shrink-1" style="flex-basis: 0%">
          <info-column v-bind="infoColumnProps" />
        </div>

        <!-- Picture -->
        <figure class="flex-grow-1 flex-shrink-1 pl-6" style="flex-basis: 0%">
          <img
            v-if="solarArrayImage"
            :src="solarArrayImage"
            style="width: 100%; min-height: 15rem"
          />
        </figure>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { mapState } from 'pinia'
import * as RouteNames from '@/router/routeNames'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { getDisplayName } from '@/model/resource'
import { SolarFarmHelper } from '@/model/resource/solar/farm/helper'
import { Resource } from 'rfs/pb/resource_pb'
import ResourceHeader from '@/components/ResourceHeader.vue'
import { BreadcrumbItem, ResourceHeaderItem } from '@/components/ResourceHeader'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import InfoColumn from '@/components/InfoColumn.vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'
import { useCustomFiles } from '@/stores/customFiles'
import UtilitySolarCharts from './UtilitySolarCharts.vue'

export default defineComponent({
  name: 'SolarArraySingle',
  props: {
    utilitySolarId: {
      type: String,
      required: true,
    },
  },
  components: {
    CenteredSpinner,
    ResourceHeader,
    UtilitySolarCharts,
    InfoColumn,
  },
  data() {
    return shallowReactive({
      isLoading: false,
      utilitySolar: undefined as Resource | undefined,
    })
  },
  computed: {
    ...mapState(useCustomFiles, ['customImages']),
    breadcrumbs(): BreadcrumbItem[] {
      return [
        { title: 'DERs', to: { name: RouteNames.DERS } },
        { title: this.title ?? '' },
      ]
    },
    headerItems(): ResourceHeaderItem[] {
      const meterNumber = this.utilitySolar?.meter?.electrical
      return meterNumber
        ? [
            {
              id: 'meterId',
              label: 'Meter number:',
              content: [{ text: meterNumber }],
            },
          ]
        : []
    },
    title(): string | undefined {
      return this.utilitySolar ? getDisplayName(this.utilitySolar) : undefined
    },
    infoColumnProps(): InfoColumnAllProps {
      const { lat, lng } = this.utilitySolar?.location?.point || {}
      const location = lat && lng ? `${lat}, ${lng}` : TEXT_NO_VALUE
      const ratedOutput = this.utilitySolar
        ? SolarFarmHelper.formattedRatedOutput(this.utilitySolar)
        : TEXT_NO_VALUE

      return {
        items: [
          { label: 'Location', text: location },
          { label: 'Capacity (AC)', text: ratedOutput },
        ],
      }
    },
    solarArrayImage(): string | undefined {
      // The files are named using the resource ID
      const key = `${ResourceType.SOLAR_FARM}/${this.utilitySolarId}`

      return this.customImages.get(key)?.contentUrl
    },
  },
  watch: {
    utilitySolarId: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.utilitySolar = undefined

      try {
        this.utilitySolar = await this.$services.queryService.getResource(
          `${ResourceType.SOLAR_FARM}/${this.utilitySolarId}`
        )
      } catch (err) {
        this.$router.replace({ name: RouteNames.DERS })
        console.error('SolarArraySingle.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
