export enum DataTableColumn {
  ID = 'id',
  NAME = 'name',
  GROUP = 'group',
  UPDATED = 'updated',
}

export const DataTableColumnName = {
  [DataTableColumn.NAME]: 'Name',
  [DataTableColumn.GROUP]: 'Group',
  [DataTableColumn.UPDATED]: 'Updated',
}
