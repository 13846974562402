<template>
  <side-sheet
    :title="title"
    :model-value="modelValue"
    @update:model-value="(newValue) => $emit('update:model-value', newValue)"
  >
    <v-list
      class="c-DataTableColumnSelector"
      bg-color="transparent"
      collapse-icon=""
      expand-icon=""
    >
      <template v-for="item of items" :key="item.id">
        <!-- Group -->
        <template v-if="item.children">
          <v-list-group :value="item.id" :aria-label="item.title">
            <!-- Activator -->
            <template v-slot:activator="{ props }">
              <v-list-item
                role="listitem"
                v-bind="overwriteVListGroupActivatorProps(props, item)"
                :title="item.title"
                :ripple="false"
                min-height="32px"
                class="pl-0 py-0"
              >
                <template v-slot:prepend="{ isActive }">
                  <!-- Expand/Collapse & Checkbox -->
                  <div class="d-flex align-center">
                    <!-- Expand/Collapse -->
                    <div
                      class="d-flex align-center justify-center cursor-pointer"
                      style="width: 2.5rem; height: 2.5rem"
                      role="button"
                      :aria-expanded="isActive ? 'true' : 'false'"
                      @click="
                        (e) => {
                          // @ts-ignore - Expands/collapses the group list using the original callback fn.
                          props.onClick(e)
                        }
                      "
                    >
                      <div
                        :class="isActive ? 'arrow-down' : 'arrow-right'"
                      ></div>
                    </div>

                    <!-- Checkbox -->
                    <v-checkbox-btn
                      :color="NEUTRAL_600.hex"
                      :ripple="false"
                      :disabled="hasAtLeastOneNonRemovableChild(item)"
                      :indeterminate="
                        !hasAllChildrenSelected(item) &&
                        hasAtLeastOneSelectedChild(item)
                      "
                      :model-value="hasAtLeastOneSelectedChild(item)"
                    />
                  </div>
                </template>
              </v-list-item>
            </template>

            <!-- Each child item -->
            <v-list-item
              role="listitem"
              v-for="child of item.children"
              :key="child.id"
              :ripple="false"
              min-height="32px"
              class="py-0"
              :disabled="child.required"
            >
              <div
                data-test="activator"
                class="d-flex align-center cursor-pointer"
                @click="() => handleItemClick(child)"
              >
                <v-checkbox-btn
                  :ripple="false"
                  :color="NEUTRAL_600.hex"
                  :disabled="child.required"
                  :model-value="isItemSelected(child)"
                  style="flex: 0"
                />
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </div>
            </v-list-item>
          </v-list-group>

          <!-- Divider -->
          <v-divider class="my-4" :color="NEUTRAL_100.hex" style="opacity: 1" />
        </template>

        <!-- Header with no group -->
        <v-list-item
          v-else
          role="listitem"
          :title="item.title"
          :ripple="false"
          :disabled="item.required"
          @click="() => handleItemClick(item)"
        >
          <!-- Checkbox -->
          <template v-slot:prepend>
            <v-checkbox-btn
              :ripple="false"
              :color="NEUTRAL_600.hex"
              :disabled="item.required"
              :model-value="isItemSelected(item)"
            />
          </template>
        </v-list-item>
      </template>
    </v-list>
  </side-sheet>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { NEUTRAL_100, NEUTRAL_600 } from '@/constants/colors'
import type { Header, VisibleHeaders } from '@/model/tables/DataTable'
import SideSheet from '@/components/navigation/SideSheet.vue'

type Item = {
  id: string
  title: string
  required?: boolean
  children?: never
}
type GroupItem = { id: string; title: string; children: Item[] }

export default defineComponent({
  name: 'DataTableCustomSelector',
  props: {
    /** * v-model */
    modelValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    visibleHeaders: {
      type: Map as PropType<VisibleHeaders>,
      required: true,
    },
  },
  components: { SideSheet },
  emits: ['update:model-value', 'new-visible-headers'],
  setup() {
    return { NEUTRAL_100, NEUTRAL_600, title: 'Column Selector' }
  },
  computed: {
    items(): Array<Item | GroupItem> {
      return this.headers.reduce<Array<Item | GroupItem>>((acc, h) => {
        // Don't show headers that depend on another header.
        if (h.columnSelector?.dependentOn) {
          return acc
        }

        // Init.
        const item: Item = {
          id: h.key,
          title: h.title,
          required: h.columnSelector?.required,
        }

        const group = h.columnSelector?.group

        // Belongs to a group.
        if (group) {
          // Group may already exists.
          const groupItem = acc.find((item) => item.id === group)

          // Exists.
          if (groupItem) {
            groupItem.children?.push(item)
          } else {
            // Create the group.
            const newGroup: GroupItem = {
              id: group,
              title: group,
              children: [item],
            }
            acc.push(newGroup)
          }
        } else {
          // Doesn't belong a group.
          acc.push(item)
        }

        return acc
      }, [])
    },
  },
  methods: {
    /**
     * VListGroup (<v-list-group />) uses the "props" object in the "activator"
     * scoped slot to pass down some HTML attributes like CSS classes, id, etc,
     * and also a "click" function (event handler) to open/close the group.
     * We don't want that since we want to select/unselect the group.
     * The chevron icon is then used to open/close the group.
     */
    overwriteVListGroupActivatorProps(
      props: Record<string, unknown>,
      group: GroupItem
    ): any {
      const newProps = { ...props }
      delete newProps.onClick

      if (this.hasAtLeastOneNonRemovableChild(group)) {
        newProps.class = `${newProps.class} cursor-default disabled-opacity`
      } else {
        newProps.onClick = () =>
          this.handleGroupClick(group, !this.hasAtLeastOneSelectedChild(group))
      }

      return newProps
    },
    isItemSelected(item: Item): boolean {
      return !!this.visibleHeaders.get(item.id)
    },
    hasAtLeastOneSelectedChild(group: GroupItem): boolean {
      return group.children.some((c) => !!this.visibleHeaders.get(c.id))
    },
    hasAllChildrenSelected(group: GroupItem): boolean {
      return group.children.every((c) => !!this.visibleHeaders.get(c.id))
    },
    hasAtLeastOneNonRemovableChild(group: GroupItem): boolean {
      return group.children.some((c) => c.required)
    },
    handleGroupClick(group: GroupItem, newValue: boolean): void {
      const newMap = new Map(this.visibleHeaders)

      for (const c of group.children) {
        newMap.set(c.id, newValue)
      }

      this.emitNewVisibleHeaders(newMap)
    },
    handleItemClick(item: Item): void {
      if (item.required) return

      const newMap = new Map(this.visibleHeaders)

      if (newMap.has(item.id)) {
        const newValue = !newMap.get(item.id)
        newMap.set(item.id, newValue)
      }

      this.emitNewVisibleHeaders(newMap)
    },
    emitNewVisibleHeaders(newValue: VisibleHeaders): void {
      this.$emit('new-visible-headers', newValue)
    },
  },
})
</script>

<style lang="scss">
.c-DataTableColumnSelector {
  /** Avoids changing the background color */
  .v-list-item__overlay {
    display: none;
  }

  .cursor-default {
    cursor: default !important;
  }

  .disabled-opacity {
    opacity: 0.6 !important;
  }

  /*
  Removes the "CSS ellipsis" from the component.
  */
  .v-list-item-title {
    white-space: unset !important;
  }
}
</style>
