// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/query.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Constituting, Resource, Upline } from "../../pb/resource_pb.js";
import { FilterBy, FilterOptions, OrderBy } from "./pagination_pb.js";

/**
 * @generated from message ritta.frontend.proto.GetResourceRequest
 */
export const GetResourceRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetResourceRequest",
  () => [
    { no: 1, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GetResourceResponse
 */
export const GetResourceResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetResourceResponse",
  () => [
    { no: 1, name: "resource", kind: "message", T: Resource },
  ],
);

/**
 * Filtering by upline reference can also include one or more type filters.
 * This is useful when a resource has potentially thousands of downline children.
 *
 * @generated from message ritta.frontend.proto.ByUpline
 */
export const ByUpline = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ByUpline",
  () => [
    { no: 1, name: "upline", kind: "message", T: Upline },
    { no: 2, name: "types", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ByConstituting
 */
export const ByConstituting = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ByConstituting",
  () => [
    { no: 1, name: "constituting", kind: "message", T: Constituting },
  ],
);

/**
 * ByIds, for querying multiple resources by resource IDs
 * Useful for batching multiple resources in one req/res
 *
 * @generated from message ritta.frontend.proto.ByIds
 */
export const ByIds = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ByIds",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GetResourcesRequest
 */
export const GetResourcesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetResourcesRequest",
  () => [
    { no: 1, name: "site_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "group_key" },
    { no: 2, name: "resource_type", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "group_key" },
    { no: 3, name: "upline", kind: "message", T: ByUpline, oneof: "group_key" },
    { no: 4, name: "ids", kind: "message", T: ByIds, oneof: "group_key" },
    { no: 5, name: "pool_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "group_key" },
    { no: 6, name: "constituting", kind: "message", T: ByConstituting, oneof: "group_key" },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GetResourcesResponse
 */
export const GetResourcesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GetResourcesResponse",
  () => [
    { no: 1, name: "resources", kind: "message", T: Resource, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.SearchRequest
 */
export const SearchRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SearchRequest",
  () => [
    { no: 1, name: "scope", kind: "enum", T: proto3.getEnumType(SearchRequest_Scope) },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "order_by", kind: "message", T: OrderBy },
    { no: 6, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
  ],
);

/**
 * @generated from enum ritta.frontend.proto.SearchRequest.Scope
 */
export const SearchRequest_Scope = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.SearchRequest.Scope",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "METER"},
    {no: 2, name: "DER"},
    {no: 3, name: "TRANSFORMER"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.SearchResponse
 */
export const SearchResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SearchResponse",
  () => [
    { no: 1, name: "results", kind: "message", T: Resource, repeated: true },
    { no: 2, name: "next_offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "export_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "filter_options", kind: "message", T: FilterOptions, repeated: true },
  ],
);

