<template>
  <control-template
    :isLoading
    :isRefreshing
    :breadcrumbs
    :currentPolicy
    :currentPolicyParams
    :currentOEStatus
    :navBarItems
    :communicationStatusMap
    :lastUpdate
  >
    <!-- Scheduled Controls -->
    <template v-if="showScheduledControlsContent">
      <control-mode
        :ref="REF_CONTROL_MODE"
        v-if="showControlMode && group"
        :group
        :devices
        :resources
        :intervalBroadcaster
        :maxCapacityWatthours
        :currentOEStatus
        class="px-6"
        style="flex: 1"
        @policy-updated="fetchData"
      />

      <!-- Legacy controls -->
      <control-group-details-system-mode
        v-else-if="showPeakTimeControls"
        :groupId
        class="px-6"
      />
    </template>

    <!-- Performance -->
    <group-performance
      v-if="showPerformanceContent && group"
      :group
      :devices
      :constitutingResources
      :communicationStatusMap
      class="px-6"
    />

    <!-- History -->
    <history-data-table
      v-if="showHistoryContent && historyTable"
      :table="historyTable"
      class="px-6"
      :is-loading="isRefreshing"
    />
  </control-template>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { DateTime } from 'luxon'
import * as RouteNames from '@/router/routeNames'
import { ResourceType } from '@/constants/resourceType'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import { DeviceHelper } from '@/model/control/device/helper'
import {
  CommunicationStatusType,
  type CommunicationStatusMap,
} from '@/model/control/communicationStatus'
import { HistoryDataTable as Table } from '@/model/control/history'
import {
  getGroupCurrentEnvelopeStatus,
  isOEEnabled,
  OperatingEnvelopeStatus,
} from '@/model/control/operatingEnvelope'
import { createGroupHistoryDataTable } from '@/model/control/group/GroupHistoryDataTable'
import { GroupHelper } from '@/model/control/group/helper'
import type { NavBarItem } from '@/components/common/NavBar.vue'
import ControlTemplate from '@/components/control/ControlTemplate.vue'
import ControlMode from '@/components/control/ControlMode.vue'
import GroupPerformance from '@/components/control/group/GroupPerformance.vue'
import HistoryDataTable from '@/components/control/HistoryDataTable.vue'
import { BreadcrumbItem } from '@/components/ResourceHeader'
import ControlGroupDetailsSystemMode from '@/components/control/ControlGroupDetailsSystemMode.vue'
import { Device, Group } from 'rfs/control/proto/model_pb'
import { GroupLogEntry } from 'rfs/control/proto/log_pb'
import { Params, Policy } from 'rfs/control/proto/policy_pb'
import type { Resource } from 'rfs/pb/resource_pb'

/**
 * Vue 2 lacks intellisense support for component methods.
 */
interface ControlModeComponent {
  shouldChangeRoute: (next: Function) => void
}

const REF_CONTROL_MODE = 'refControlMode'

export default defineComponent({
  name: 'Group',
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  components: {
    ControlTemplate,
    ControlMode,
    GroupPerformance,
    HistoryDataTable,
    ControlGroupDetailsSystemMode,
  },
  setup() {
    return {
      REF_CONTROL_MODE,
      // NOTE(rafael): Increase the rate of updating the page and charts from
      // 15secs to 30secs because we're fetching individual device series
      // and aggregating them on the server, for the cases where
      // the group is a group of chargers (power waypoints).
      intervalBroadcaster: new IntervalBroadcaster(30_000),
    }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isRefreshing: false,
      hasLoadingFailed: false,
      group: null as null | Group,
      devices: [] as Device[],
      logs: [] as GroupLogEntry[],
      resources: [] as Resource[],
      maxCapacityWatthours: undefined as undefined | number,
      // List of all "constituting" Resources collected from all pool IDs.
      constitutingResources: [] as Resource[],
      communicationStatusMap: undefined as undefined | CommunicationStatusMap,
      lastUpdate: undefined as undefined | DateTime,
    })
  },
  computed: {
    currentPolicy(): Policy {
      return this.group?.currentPolicy ?? Policy.UNSPECIFIED
    },
    currentPolicyParams(): undefined | Params {
      return this.group?.currentPolicyParams
    },
    breadcrumbs(): BreadcrumbItem[] {
      return [
        {
          title: 'Groups',
          to: { name: RouteNames.CONTROL_OVERVIEW },
        },
        { title: this.group?.displayName ?? '' },
      ]
    },
    showControlMode(): boolean {
      return Boolean(
        this.group?.enabledPolicies.includes(Policy.MANUAL_CONTROL_EVENT) ||
          GroupHelper.hasAutomaticControls(this.group)
      )
    },
    showPeakTimeControls(): boolean {
      return Boolean(
        this.group?.enabledPolicies.includes(Policy.PEAK_TIME_PAYBACK_EVENT)
      )
    },
    navBarItemControls(): NavBarItem {
      const label = 'Controls'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_GROUP_CONTROLS,
          params: { groupId: this.groupId },
        },
        disabled: !this.showControlMode && !this.showPeakTimeControls,
      }
    },
    navBarItemPerformance(): NavBarItem {
      const label = 'Performance'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_GROUP_PERFORMANCE,
          params: { groupId: this.groupId },
        },
      }
    },
    navBarItemHistory(): NavBarItem {
      const label = 'History'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_GROUP_HISTORY,
          params: { groupId: this.groupId },
        },
      }
    },
    navBarItems(): NavBarItem[] {
      return [
        this.navBarItemControls,
        this.navBarItemPerformance,
        this.navBarItemHistory,
      ]
    },
    historyTable(): null | Table {
      return this.group
        ? createGroupHistoryDataTable(this.group, this.logs)
        : null
    },
    showScheduledControlsContent(): boolean {
      return this.$route.name === this.navBarItemControls.to.name
    },
    showPerformanceContent(): boolean {
      return this.$route.name === this.navBarItemPerformance.to.name
    },
    showHistoryContent(): boolean {
      return this.$route.name === this.navBarItemHistory.to.name
    },
    currentOEStatus(): undefined | OperatingEnvelopeStatus {
      return isOEEnabled(this.$rittaConfig) && this.group?.supportsOes
        ? getGroupCurrentEnvelopeStatus(this.groupId, this.devices)
        : undefined
    },
    isOECurrentlyActive(): boolean {
      return this.currentOEStatus === OperatingEnvelopeStatus.ACTIVE
    },
    isOEWidgetDisabled(): boolean {
      return (
        // NOTE(rafael): When "PARTIAL" because the switch button doesn't make sense.
        this.currentOEStatus === OperatingEnvelopeStatus.PARTIAL ||
        // TODO(rafael): What should we do when a "pool/" resource? (Virtual Battery group)
        this.devices.every((d) =>
          d.camusResourceId.includes(ResourceType.POOL_DEVICE)
        )
      )
    },
    isCommunicationStatusEnabled(): boolean {
      return !!this.$rittaConfig.control?.communicationStatus
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler: async function (): Promise<void> {
        this.fetchData()
      },
    },
    group() {
      // New group.
      this.setNewPageTitle()
    },
    $route() {
      this.guardControlRoute()
      // When tab changes.
      this.setNewPageTitle()
    },
  },
  beforeRouteLeave(_to, _from, next): void {
    const controlModeComponent = this.$refs[this.REF_CONTROL_MODE] as
      | undefined
      | ControlModeComponent

    if (controlModeComponent) {
      controlModeComponent.shouldChangeRoute(next)
    } else {
      next()
    }
  },
  created() {
    this.intervalBroadcaster.subscribe(this.reloadData)
    this.intervalBroadcaster.start()
  },
  beforeUnmount(): void {
    this.intervalBroadcaster.unsubscribe(this.reloadData)
    this.intervalBroadcaster.stop()
  },
  methods: {
    setNewPageTitle(): void {
      if (!this.group) return

      const tabLabel = ((): undefined | string => {
        if (this.showScheduledControlsContent) {
          return this.navBarItemControls.label
        } else if (this.showPerformanceContent) {
          return this.navBarItemPerformance.label
        } else if (this.showHistoryContent) {
          return this.navBarItemHistory.label
        } else {
          return undefined
        }
      })()

      const baseTitle = `Group: ${this.group.displayName}`

      useNavigationControlsStore().setPageTitle(
        tabLabel ? `${baseTitle} - ${tabLabel}` : baseTitle
      )
    },
    /**
     * If the user tries to visit the "control" route/tab pushes the user
     * to another route.
     */
    guardControlRoute(): void {
      if (
        this.navBarItemControls.disabled &&
        this.$route.name === this.navBarItemControls.to.name
      ) {
        this.$router.replace(this.navBarItemPerformance.to)
      }
    },
    reloadData(): void {
      this.fetchData({ refresh: true })
    },
    async fetchData({ refresh } = { refresh: false }): Promise<void> {
      if (refresh) {
        this.isRefreshing = true
      } else {
        this.isLoading = true
        this.group = null
        this.maxCapacityWatthours = undefined
        this.devices = []
        this.logs = []
        this.resources = []
        this.constitutingResources = []
        this.communicationStatusMap = undefined
        this.lastUpdate = undefined
      }

      this.hasLoadingFailed = false

      try {
        const [group, { devices }, { entries }] = await Promise.all([
          this.$services.control.getGroup({
            id: this.groupId,
          }),
          this.$services.control.listDevices({ groupId: this.groupId }),
          this.$services.control.groupHistory({
            id: this.groupId,
          }),
        ])

        this.group = group
        this.devices = devices
        this.logs = entries

        const resourceIds = this.devices.map((device) => device.camusResourceId)
        if (!this.isRefreshing && resourceIds.length) {
          this.resources = await this.$services.queryService.getResourcesByIds(
            resourceIds
          )
        }

        // When a Device is a "virtual" device and consists of multiple Resources.
        const poolIds = this.devices.reduce((acc, device) => {
          const poolId = DeviceHelper.getPoolId(device)
          if (poolId) acc.push(poolId)
          return acc
        }, [] as string[])

        if (!this.isRefreshing && poolIds.length) {
          this.constitutingResources = (
            await Promise.all(poolIds.map(this.fetchConstitutingResources))
          ).flat()
        }

        await this.fetchCommunicatonStatus()

        if (!this.isRefreshing) {
          this.guardControlRoute()
        }

        const requiresWatthours =
          !!this.group?.locationId && // Belongs to a Location.
          this.devices.length === 1 &&
          (this.devices[0].detailsOneof.case === 'stemBattery' ||
            this.devices[0].detailsOneof.case === 'trimarkBattery')

        if (requiresWatthours) {
          await this.fetchWatthours(this.devices[0].camusResourceId)
        }
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('Group.fetchData: %o', err)
        this.$router.replace({ name: RouteNames.CONTROL_OVERVIEW })
      } finally {
        this.isRefreshing = false
        this.isLoading = false
      }
    },
    async fetchWatthours(resourceId: string): Promise<void> {
      try {
        const resource = await this.$services.queryService.getResource(
          resourceId
        )
        this.maxCapacityWatthours = resource.ratings?.watthours
      } catch (err) {
        console.error('Group.fetchWatthours: %o', err)
        this.maxCapacityWatthours = undefined
      }
    },
    async fetchConstitutingResources(poolId: string): Promise<Resource[]> {
      try {
        return await this.$services.queryService.getResourcesByPoolID(poolId)
      } catch (err) {
        console.error(
          `Group.fetchConstitutingResources: failed for poolId "${poolId}"`,
          err
        )
        return []
      }
    },
    async fetchCommunicatonStatus(): Promise<void> {
      if (!this.isCommunicationStatusEnabled) return

      try {
        // TODO(rafael): fetch communication statuses, for now everyone is
        // considered "Good".
        this.communicationStatusMap = (() => {
          const resources = this.constitutingResources.length
            ? this.constitutingResources
            : this.resources

          return resources.reduce((acc, resource) => {
            acc.set(resource.id, CommunicationStatusType.GOOD)
            return acc
          }, new Map() as CommunicationStatusMap)
        })()
      } catch (err) {
        // TODO(rafael): When err, mark everyone as Unreachable.
        console.error('Group.fetchCommunicatonStatus: %o', err)
      }

      this.lastUpdate = DateTime.now()
    },
  },
})
</script>
