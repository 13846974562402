<template>
  <v-text-field
    type="date"
    v-bind="computedAttrs"
    :model-value="modelValue"
    @update:model-value="emitNewValue"
    @change="saveChangeEvt"
    @blur="customOnChange"
    @keyup.enter="customOnChange"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { cloneDeep } from 'lodash-es'
import { DateTime } from 'luxon'

/**
 * The objective of this component is to wrap VTextField (type="date")
 * and have control of when it emits the "change" event. The "change"
 * event will be emitted only when the user blurs the input or when
 * the user hits the return/enter key.
 */
export default defineComponent({
  name: 'DatePicker',
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String as PropType<null | string>,
      required: false,
      default: '',
    },
  },
  setup(props) {
    return {
      lastChangeValue: '',
      lastEmittedOnChangeValue: props.modelValue,
    }
  },
  computed: {
    computedAttrs() {
      const cloned = cloneDeep(this.$attrs)
      delete cloned.onChange
      return cloned
    },
    minISO() {
      return this.computedAttrs.min as undefined | string
    },
    maxISO() {
      return this.computedAttrs.max as undefined | string
    },
    minDt(): undefined | DateTime {
      if (!this.minISO) return undefined

      const dt = DateTime.fromISO(this.minISO)

      return dt.isValid ? dt : undefined
    },
    maxDt(): undefined | DateTime {
      if (!this.maxISO) return undefined

      const dt = DateTime.fromISO(this.maxISO)

      return dt.isValid ? dt : undefined
    },
  },
  methods: {
    emitNewValue(newValue: null | string): void {
      this.$emit('update:model-value', newValue)
    },
    saveChangeEvt(evt: Event): void {
      this.lastChangeValue = (evt.target as HTMLInputElement).value
    },
    customOnChange(): void {
      if (!this.lastChangeValue) return

      // The same "value" emitted before, skip.
      if (this.lastChangeValue === this.lastEmittedOnChangeValue) return

      // Invalid date, skip.
      const newDt = DateTime.fromISO(this.lastChangeValue)
      if (!newDt.isValid) return

      // Invalid, enforce the min validation.
      if (this.minISO && this.minDt && newDt < this.minDt) {
        // Patch the visible value.
        this.emitNewValue(this.minISO)
        // Patch the event.
        this.lastChangeValue = this.minISO
      }

      // Invalid, enforce the max validation.
      if (this.maxISO && this.maxDt && newDt > this.maxDt) {
        // Patch the visible value.
        this.emitNewValue(this.maxISO)
        // Patch the event.
        this.lastChangeValue = this.maxISO
      }

      // Continue.
      const originalOnChange = this.$attrs.onChange as undefined | Function
      originalOnChange?.(this.lastChangeValue)
      this.lastEmittedOnChangeValue = this.lastChangeValue
    },
  },
})
</script>
