import { shallowReactive } from 'vue'
import { Settings } from 'luxon'
import { defineImmutableStore } from './defineStore'
import {
  DersTable,
  CustomFilters,
  CustomOptions,
  createInitialFilters,
  createInitialOptions,
  DersTableColumns,
  headers,
} from '@/model/ders/DersDataTable'
import { convertFilters, convertOrderBy } from '@/model/tables/column'
import {
  createInitialVisibleHeaders,
  upgradeVisibleHeaders,
} from '@/model/tables/header'
import {
  VisibleHeaders,
  FilterMultiSelect,
  NewOptionsContext,
} from '@/model/tables/DataTable'
import { compare, valueToSelectItem } from '@/model/tables/filter'
import { TableResponse } from 'rfs/frontend/proto/grid_pb'
import { FilterOptions } from 'rfs/frontend/proto/pagination_pb'
import { assoc } from '@/utils/immutable'

export const useDersTableStore = defineImmutableStore('dersTable', {
  persist: { paths: ['visibleHeaders', 'options', 'filters', 'searchText'] },
  state: () => {
    return shallowReactive({
      visibleHeaders: createInitialVisibleHeaders(headers),
      options: createInitialOptions(),
      filters: createInitialFilters(),
      searchText: '',
      response: null as null | TableResponse,
      isLoading: false,
      hasLoadingFailed: false,
    })
  },
  getters: {
    serverItemsLength(): undefined | number {
      return this.response?.totalRows
    },
    isInitialState(): boolean {
      return this.response === null
    },
    table(): DersTable {
      return this.isInitialState
        ? { rows: [], noDataText: 'Loading DERs...' }
        : { rows: this.response?.rows ?? [], noDataText: 'No results' }
    },
    csvDownloadUrl(): undefined | URL {
      if (!this.response?.exportUrl) return undefined

      const url = new URL(this.response.exportUrl, this.config.rfsEndpoint)
      // The data includes timestamps so we need to pass up the local TZ
      url.searchParams.set('TZ', Settings.defaultZone.name)

      return url
    },
    downloadHref(): undefined | string {
      return this.csvDownloadUrl?.href ?? undefined
    },
  },
  actions: {
    updateVisibleHeaders(newValue: VisibleHeaders): void {
      this.visibleHeaders = newValue
    },
    updateOptions(newOptions: CustomOptions, ctx?: NewOptionsContext): void {
      this.options = newOptions
      if (ctx?.triggeredByFilter) return
      this.fetchData()
    },
    updateFilters(newFilters?: CustomFilters): void {
      this.filters = newFilters ?? createInitialFilters()
      this.fetchData()
    },
    updateSearchText(newSearch: string): void {
      if (this.searchText === newSearch) return

      this.searchText = newSearch
      // When a new search text, reset back to page 1.
      this.updateOptions(
        { ...this.options, page: 1 },
        { triggeredByFilter: true }
      )
      this.fetchData()
    },
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.hasLoadingFailed = false

      try {
        this.response = await this.services.gridService.dersTable({
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          limit: this.options.itemsPerPage,
          search: this.searchText,
          filterBy: convertFilters(this.filters),
          orderBy: convertOrderBy(this.options.orderBy),
          groupBy: [],
        })
        this.filters = updateSelectFilters(
          this.filters,
          this.response.filterOptions
        )
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('dersTable.fetchData: $o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
  upgradeState(currentState, initialState) {
    // TODO(rafael): upgrade filters.
    // TODO(rafael): upgrade options: when header doesn't exist anymore, it could still be in use in 'options.orderBy'.

    // Headers
    upgradeVisibleHeaders(
      currentState.visibleHeaders,
      initialState.visibleHeaders
    )
  },
})

function updateSelectFilters(
  filters: CustomFilters,
  options: FilterOptions[]
): CustomFilters {
  if (options.length === 0) return filters

  const newFilters = new Map(filters)
  for (const option of options) {
    const choices = option.options?.values ?? []

    if (choices.length === 0) {
      continue
    } else if (option.property === DersTableColumns.TYPE) {
      const filter = filters.get(DersTableColumns.TYPE) as FilterMultiSelect
      newFilters.set(
        DersTableColumns.TYPE,
        assoc(filter, 'items', choices.map(valueToSelectItem).sort(compare))
      )
    } else if (option.property === DersTableColumns.FEEDER) {
      const filter = filters.get(DersTableColumns.FEEDER) as FilterMultiSelect
      newFilters.set(
        DersTableColumns.FEEDER,
        assoc(filter, 'items', choices.map(valueToSelectItem).sort(compare))
      )
    }
  }
  return newFilters
}
