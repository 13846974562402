import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'
import { PartialMessage } from '@bufbuild/protobuf'
import { Query } from 'rfs/frontend/proto/query_connect'
import {
  ByConstituting,
  ByIds,
  ByUpline,
  GetResourcesRequest,
  SearchRequest,
  SearchRequest_Scope as SearchScope,
} from 'rfs/frontend/proto/query_pb'
import { FilterBy } from 'rfs/frontend/proto/pagination_pb'
import { Resource, Upline } from 'rfs/pb/resource_pb'
import { ResourceType } from '@/constants/resourceType'
import { SearchOptions } from '@/model/meter/MeterSearchDataTable'
import { isSite, makeId } from '@/model/resource'
import { optimizeResource } from './immutable'
// Export these so callers don't have a direct dependency on this RPC library
export { ListValue, Value } from '@bufbuild/protobuf'

export type SearchFilterBy = PartialMessage<FilterBy>

type SiteResources = [Resource, Resource[]]

export const NO_MORE_RESULTS = -1

export class QueryService {
  private client: PromiseClient<typeof Query>

  constructor(transport: Transport) {
    this.client = createPromiseClient(Query, transport)
  }

  public async getResource(
    resourceId: string,
    rsrcType?: ResourceType
  ): Promise<Resource> {
    if (rsrcType) {
      resourceId = makeId(rsrcType, resourceId)
    }
    const res = await this.client.getResource({ resourceId })
    const resource = res.resource
    if (resource === undefined) {
      throw new Error(
        `QueryService.getResource: no resource for resource id "${resourceId}"`
      )
    }
    return resource
  }

  public async getResources(
    groupKey: GetResourcesRequest['groupKey']
  ): Promise<Resource[]> {
    const res = await this.client.getResources({ groupKey })

    res.resources.forEach(optimizeResource)

    return res.resources
  }

  public async getResourcesByIds(resourceIds: string[]) {
    return this.getResources({
      case: 'ids',
      value: new ByIds({ ids: resourceIds }),
    })
  }

  public async getResourcesBySiteID(siteId: string): Promise<SiteResources> {
    const { resources } = await this.client.getResources(
      new GetResourcesRequest({ groupKey: { case: 'siteId', value: siteId } })
    )
    const index = resources.findIndex(isSite)
    if (index === -1) {
      throw new Error(`Site with ID "${siteId}" not found`)
    }
    // Freeze all the resources because they're immutable; Vue doesn't need to observe them
    resources.forEach(Object.freeze)
    // The site is the first object, and all other resources are the second
    return [resources[index], resources.filter((_, i) => i !== index)]
  }

  public async getResourcesByType(
    resourceType: ResourceType
  ): Promise<Array<Resource>> {
    const res = await this.client.getResources(
      new GetResourcesRequest({
        groupKey: { case: 'resourceType', value: resourceType },
      })
    )
    res.resources.forEach(optimizeResource)
    return res.resources
  }

  /**
   * Return resources that share a common upline resource (e.g. transformer, substation)
   * @param upline An upline reference to match. Multiple properties are supported.
   * @param types (Optional) Only return resources of matching these types.
   */
  public async getResourcesByUpline(
    upline: PartialMessage<Upline>,
    types?: ResourceType[]
  ): Promise<Array<Resource>> {
    const res = await this.client.getResources(
      new GetResourcesRequest({
        groupKey: { case: 'upline', value: new ByUpline({ upline, types }) },
      })
    )

    // Certain utilities don't use the upline field for their feeders
    // so we need to check the `constituting` field as well
    if (upline.feeder) {
      try {
        const res2 = await this.client.getResources(
          new GetResourcesRequest({
            groupKey: {
              case: 'constituting',
              value: new ByConstituting({
                constituting: { feeder: upline.feeder },
              }),
            },
          })
        )
        res.resources.push(...res2.resources)
      } catch (error) {
        console.error(
          `QueryService.getResourcesByUpline: error fetching 'constituting' resources by feeder: ${upline.feeder}`,
          error
        )
      }
    }
    // Freeze all the resources because they're immutable; Vue doesn't need to observe them
    res.resources.forEach(optimizeResource)

    return res.resources
  }

  public async getResourcesByPoolID(pool: string) {
    const res = await this.client.getResources(
      new GetResourcesRequest({
        groupKey: { case: 'constituting', value: { constituting: { pool } } },
      })
    )

    // Freeze all the resources because they're immutable; Vue doesn't need to observe them
    res.resources.forEach(optimizeResource)

    return res.resources
  }

  /**
   * Run a search for meters using the given text input.
   */
  public async searchForMeters(
    text: string,
    options: SearchOptions,
    filters?: SearchFilterBy[]
  ) {
    const { page, itemsPerPage, orderBy } = options
    const request = new SearchRequest({
      scope: SearchScope.METER,
      query: text,
      limit: itemsPerPage,
      offset: itemsPerPage * (page - 1),
      orderBy: { property: orderBy.column, descending: orderBy.descending },
      filterBy: filters,
    })
    const response = await this.client.searchResources(request)

    response.results.forEach(optimizeResource)
    return response
  }
}
