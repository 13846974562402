<template>
  <div v-if="filterChips.length" class="d-flex flex-wrap pb-4">
    <span
      data-test="chips-prefix"
      class="font-weight-bold text-caption pt-1 pr-2"
      :style="{ display: 'inline-flex', color: titleColor }"
    >
      Applied filters:
    </span>

    <!-- Chips -->
    <div
      data-test="chip"
      v-for="(filterChip, index) of filterChips"
      :key="filterChip.key"
      class="d-flex align-center mb-2 rounded-xl px-3"
      :class="{ 'mr-2': filterChips.length !== index + 1 }"
      style="min-height: 26px"
      v-bind="filterChip.headerProps"
      v-apply-default-chip-bg-color
    >
      <!-- Text -->
      <span class="d-block text-caption pr-2" :style="{ color: textColor }">
        {{ filterChip.headerTitle }} ({{ filterChip.params.join('; ') }})
      </span>

      <!-- Remove -->
      <v-icon
        class="cursor-pointer"
        size="16"
        :color="btnResetColor"
        @click="() => resetFilter(filterChip)"
      >
        $delete
      </v-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue'
import {
  NEUTRAL_100,
  NEUTRAL_600,
  NEUTRAL_700,
  GREY14,
} from '@/constants/colors'
import { Header, Filters, FilterMultiSelect } from '@/model/tables/DataTable'

type FilterChip = {
  key: string
  headerTitle: string
  headerProps: Header['headerProps']
  params: string[]
}

export default defineComponent({
  name: 'DataTableAppliedFilters',
  props: {
    headers: {
      type: Array as PropType<Header[]>,
      required: true,
    },
    filters: {
      type: Map as PropType<Filters>,
      required: true,
    },
  },
  emits: ['new-filters'],
  directives: {
    /**
     * Applies the default background-color for the chips.
     *
     * Use case:
     * "headerProps" may contain a CSS class that tints the header's background.
     * We use this color in the filters panel and we want to use it for the
     * chip background too. In these cases, don't overwrite the bg color.
     */
    'apply-default-chip-bg-color': {
      mounted: (el) => {
        const currentBg = window.getComputedStyle(el).backgroundColor
        if (currentBg === 'rgba(0, 0, 0, 0)') {
          el.style.backgroundColor = NEUTRAL_100.hex
        }
      },
    },
  },
  setup() {
    return {
      textColor: NEUTRAL_700.hex,
      titleColor: NEUTRAL_600.hex,
      btnResetColor: GREY14.hex,
    }
  },
  computed: {
    filterChips(): FilterChip[] {
      return this.headers.reduce<FilterChip[]>((acc, header) => {
        const fields = this.filters.get(header.key)

        if (!fields) return acc

        // Capture values.
        if (fields) {
          // Min-Max.
          if (
            fields.type === 'min-max' &&
            (fields.min.value !== null || fields.max.value !== null)
          ) {
            const min = fields.min.value
            const max = fields.max.value

            acc.push({
              key: header.key,
              headerTitle: header.title,
              headerProps: header.headerProps,
              params: (() => {
                const arr: string[] = []
                if (min !== null) arr.push(`Min: ${min.toString()}`)
                if (max !== null) arr.push(`Max: ${max.toString()}`)
                return arr
              })(),
            })
          }

          // Dropdown.
          if (fields.type === 'dropdown' && fields.value) {
            const option = fields.items.find((it) => it.value === fields.value)
            if (option) {
              acc.push({
                key: header.key,
                headerTitle: header.title,
                headerProps: header.headerProps,
                params: [option.title],
              })
            }
          }

          // Multiselect.
          if (fields.type === 'multiselect' && fields.value.length) {
            const options = fields.value.reduce<FilterMultiSelect['items']>(
              (acc, sv) => {
                const option = fields.items.find((it) => it.value === sv)
                if (option) acc.push(option)
                return acc
              },
              []
            )

            if (options.length) {
              acc.push({
                key: header.key,
                headerTitle: header.title,
                headerProps: header.headerProps,
                params: options
                  .map((o) => o.title)
                  .sort((a, b) => a.localeCompare(b)),
              })
            }
          }

          // Date.
          if (fields.type === 'date' && fields.value) {
            acc.push({
              key: header.key,
              headerTitle: header.title,
              headerProps: header.headerProps,
              params: [fields.value.toLocaleString()],
            })
          }

          // Resources.
          if (fields.type === 'resources' && fields.value.length) {
            acc.push({
              key: header.key,
              headerTitle: header.title,
              headerProps: header.headerProps,
              params: fields.value
                .map((r) => r.title)
                .sort((a, b) => a.localeCompare(b)),
            })
          }
        }

        return acc
      }, [])
    },
  },
  methods: {
    resetFilter(filterChip: FilterChip): void {
      const newFilters = new Map(this.filters)

      const oldFields = newFilters.get(filterChip.key)

      // Erase.
      if (oldFields) {
        if (oldFields.type === 'min-max') {
          newFilters.set(filterChip.key, {
            ...oldFields,
            min: { ...oldFields.min, value: null },
            max: { ...oldFields.max, value: null },
          })
        } else if (oldFields.type === 'dropdown' || oldFields.type === 'date') {
          newFilters.set(filterChip.key, { ...oldFields, value: null })
        } else if (
          oldFields.type === 'multiselect' ||
          oldFields.type === 'resources'
        ) {
          newFilters.set(filterChip.key, { ...oldFields, value: [] })
        }
      }

      this.$emit('new-filters', newFilters)
    },
  },
})
</script>
