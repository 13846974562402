import { Duration, Interval } from 'luxon'
import type { ChartOptions } from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'

export function createEveryOtherDayAnnotation(
  interval: Interval
): ChartOptions {
  return {
    plugins: {
      annotation: {
        annotations: Interval.fromDateTimes(
          interval.start.startOf('day'),
          interval.end
        )
          .splitBy(Duration.fromObject({ day: 1 }))
          .reduce<{ [key: string]: AnnotationOptions }>((acc, int) => {
            if (int.start.ordinal % 2 === 0) {
              acc[`${int.start.toISODate()}-every-other-day`] = {
                type: 'box',
                xMin: int.start.toMillis(),
                xMax: int.end.toMillis(),
                backgroundColor: 'rgba(211, 211, 211, 0.15)',
                borderWidth: 0,
                z: -999,
              }
            }

            return acc
          }, {}),
      },
    },
  }
}
