<template>
  <router-link
    v-if="computedAttrs.to"
    v-bind="computedAttrs"
    :to="computedAttrs.to"
  >
    <slot></slot>
  </router-link>
  <template v-else><slot></slot></template>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'

/**
 * The SafeRouterLink component wraps Vue Router's <router-link>, ensuring that
 * only valid routes are passed. If an invalid or non-existent route is
 * detected, it falls back to rendering its slot content
 * without the router-link.
 */
export default defineComponent({
  name: 'SafeRouterLink',
  inheritAttrs: false,
  computed: {
    computedAttrs() {
      const possibleRoute = this.$attrs.to as any

      if (
        (typeof possibleRoute === 'string' &&
          this.$router.hasRoute(possibleRoute)) ||
        (typeof possibleRoute === 'object' &&
          possibleRoute.name &&
          this.$router.hasRoute(possibleRoute.name))
      ) {
        return this.$attrs
      }

      const cloned = { ...this.$attrs }
      delete cloned.to
      return cloned
    },
  },
})
</script>
