<template>
  <v-alert type="error" density="compact" class="mb-0">
    <slot></slot>
  </v-alert>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'AlertError' })
</script>
