import { Timestamp } from '@bufbuild/protobuf'
import { DateTime } from 'luxon'

import { SystemPeakDemandResponse } from 'rfs/frontend/proto/forecast_pb'
import { SystemPeakDemandData } from '@/services/forecast.service'
import { Format } from '@/utils/format'
import { offsetNameShort } from '@/utils/time'
import { ColumnType } from '../tables/column'
import { Header, Row as BaseRow } from '../tables/DataTable'
import { formatPeakHours } from './HistoricalContextChartDataProvider'

const NUMBER_OF_ROWS = 5

export type Row = SystemPeakDemandData & BaseRow

export function forecastAccuracyHeaders(): Header[] {
  const timezoneShortString = offsetNameShort()

  return [
    {
      title: 'Peak day, observed',
      key: 'id',
    },
    {
      title: 'Peak classification, forecasted',
      key: 'monthlyPeak',
    },
    {
      title: 'Date',
      key: 'date',
      value: (item) => DateTime.fromGDate(item.date).toFormat('yyyy-MM-dd'),
      width: 128,
      sortable: false,
    },
    {
      title: 'Demand, observed (MW)',
      key: 'actualDemand',
      valueType: ColumnType.MEGA_W,
    },
    {
      title: 'Demand, forecasted (MW)',
      key: 'forecastDemand',
      valueType: ColumnType.MEGA_W,
    },
    {
      title: 'Demand, percent error (%)',
      key: 'percentErrorDemand',
      value: (item) => formatPercentError(item as SystemPeakDemandData),
      sortable: false,
    },
    {
      title: `Peak hour (${timezoneShortString}), observed`,
      key: 'peakHoursObserved',
      value: (item) => formatPeakHours(item.peakHoursObserved),
      width: 128,
      sortable: false,
    },
    {
      title: `Peak hour (${timezoneShortString}), forecasted`,
      key: 'peakHoursForecast',
      value: (item) => formatPeakHours(item.peakHoursForecast, 3),
      width: 128,
      sortable: false,
    },
    {
      title: 'Observed peak in forecasted peak window',
      key: 'peakInForecastedWindow',
      value: (item) =>
        formatPeakInForecastedWindow(item as SystemPeakDemandData),
      sortable: false,
    },
  ]
}

function formatPercentError(data: SystemPeakDemandData): string {
  const percentError =
    (data.actualDemand - data.forecastDemand) / data.actualDemand
  // Add a '+' sign so that the numbers align nicely
  const prefix = percentError > 0 ? '+' : ''

  return prefix + Format.fmtPercent(percentError)
}

function formatPeakInForecastedWindow(data: SystemPeakDemandData): string {
  const { peakHoursForecast, peakHoursObserved } = data

  if (peakHoursForecast.length === 0 || peakHoursObserved.length === 0) {
    return 'No'
  }

  const observedHour = DateTime.fromMillis(peakHoursObserved[0].toMillis())
  const peakHour = peakHoursForecast.find((hour: Timestamp) => {
    const forecastHour = DateTime.fromMillis(hour.toMillis())
    return forecastHour.hasSame(observedHour, 'hour')
  })

  return peakHour ? 'Yes' : 'No'
}

export function createForecastAccuracyRows(
  month: DateTime,
  response: SystemPeakDemandResponse
): Row[] {
  // Sort the data by actual demand so we can use the index as the id
  return response.data
    .filter((item) => DateTime.fromGDate(item.date).hasSame(month, 'month'))
    .sort((a, b) => b.actualDemand - a.actualDemand)
    .slice(1, NUMBER_OF_ROWS + 1)
    .map((item, i) => ({ id: (i + 1).toString(), ...item }))
}
