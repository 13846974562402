<template>
  <section
    class="py-1 px-3 position-relative"
    style="min-width: 12.5rem"
    :aria-label="summary.title"
    :style="{ 'background-color': bgColor }"
  >
    <span
      class="d-block text-caption font-weight-bold pb-1"
      :style="{ color: titleColor }"
    >
      {{ summary.title }}
    </span>

    <span class="d-block text-h6"> {{ summary.body }} </span>

    <span class="text-caption" :style="{ color: titleColor }">
      {{ summary.footer }}
    </span>

    <!-- Refreshing -->
    <v-progress-linear
      v-if="isLoading"
      color="primary"
      location="bottom"
      indeterminate
      absolute
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BLUE6, GREY10 } from '@/constants/colors'

export type GridImpactSummary = {
  title: string
  body: string
  footer: string
}

export default defineComponent({
  name: 'GridImpactSummaryBox',
  props: {
    summary: {
      type: Object as PropType<GridImpactSummary>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return { bgColor: BLUE6.hex, titleColor: GREY10.hex }
  },
})
</script>
