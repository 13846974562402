import { DateTime } from 'luxon'
import { FormFields } from '@/model/forms/FormFields'
import {
  Custom,
  FormPercent,
  Integer,
  PositiveFloat,
} from '@/utils/validation/decorators'
import { Validator, validators } from '@/utils/validation/validators'
import { ModelMaker } from 'rfs/frontend/proto/analysis_pb'

const validateCagr: Validator<ModelMakerFormFields> = (
  v,
  fieldName,
  formFields
) => {
  const floatValidation = validators[PositiveFloat.name](
    v,
    fieldName,
    formFields,
    undefined
  )

  const siblingField =
    fieldName === 'evCagr' ? formFields.pvCagr : formFields.evCagr

  if (siblingField && !v) {
    // Don't run the validation for this field in case the sibling adoption rate
    // field is being touched and this one is still empty.
    return true
  } else if (typeof floatValidation === 'string') {
    return floatValidation
  } else if (formFields.until && !formFields.evCagr && !formFields.pvCagr) {
    return 'At least one of the rate fields is required.'
  } else {
    // Valid.
    return true
  }
}

const validateUntil: Validator<ModelMakerFormFields> = (
  v,
  fieldName,
  formFields
) => {
  const integerValidation = validators[Integer.name](
    v,
    fieldName,
    formFields,
    undefined
  )

  const now = formFields.getNow()

  if (
    formFields.coincidence &&
    !v &&
    !formFields.evCagr &&
    !formFields.pvCagr
  ) {
    // When the user chooses to fill a "coincidence" value and no adoption
    // rate field is filled, skip the validation.
    return true
  } else if (typeof integerValidation === 'string') {
    return integerValidation
  } else if (Number(v) < now.year) {
    return 'Must be at least this year.'
  } else if (Number(v) > now.year + 50) {
    return 'Must be at max 50 years ahead.'
  } else {
    // Valid.
    return true
  }
}

export class ModelMakerFormFields extends FormFields {
  @Custom({ validator: validateCagr })
  evCagr: string

  @Custom({ validator: validateCagr })
  pvCagr: string

  @Custom({ validator: validateUntil })
  until: string

  @FormPercent
  coincidence: string

  getNow: () => DateTime

  constructor(initialModelMaker: ModelMaker, opts?: { now: DateTime }) {
    super()

    this.getNow = () => opts?.now ?? DateTime.now()

    const initialEvCagr =
      initialModelMaker.growthParams?.evAnnualGrowthRatePercent

    this.evCagr =
      initialEvCagr !== undefined ? (initialEvCagr * 100).toString() : ''

    const initialPvCagr =
      initialModelMaker.growthParams?.pvAnnualGrowthRatePercent

    this.pvCagr =
      initialPvCagr !== undefined ? (initialPvCagr * 100).toString() : ''

    const until = initialModelMaker.growthParams?.targetYear ?? 0
    this.until = until === 0 ? '' : until.toString()

    const coincidence = initialModelMaker.coincidenceFactor
    this.coincidence = coincidence === 0 ? '' : (coincidence * 100).toString()
  }

  isNoneFilled(): boolean {
    return !this.evCagr && !this.pvCagr && !this.until && !this.coincidence
  }

  preValidate(): string | undefined {
    return this.isNoneFilled()
      ? 'Either "adoption parameters" or "EV Charging session coincidence" must be filled.'
      : undefined
  }

  generateModelMaker(): ModelMaker {
    if (!this.validate().isValid) {
      throw new Error('the values must fist be valid')
    }

    return new ModelMaker({
      growthParams: {
        evAnnualGrowthRatePercent: Number(this.evCagr) / 100,
        pvAnnualGrowthRatePercent: Number(this.pvCagr) / 100,
        targetYear: Number(this.until),
      },
      coincidenceFactor: Number(this.coincidence) / 100,
    })
  }
}
