<template>
  <div
    class="d-inline-flex align-center"
    style="height: 40px !important"
    @click.stop
  >
    <v-checkbox
      :model-value="indeterminate || modelValue"
      :disabled="disabled"
      :indeterminate="indeterminate"
      hide-details
      style="flex-shrink: 0"
      @update:model-value="handleUpdate"
    />
    <v-menu v-if="items.length" location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn
          icon="$dropdown"
          density="compact"
          variant="text"
          class="ml-n3"
          v-bind="props"
        />
      </template>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.value"
          @click="() => handleSelect(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SelectMenuItem } from '@/model/tables/DataTable'

export default defineComponent({
  name: 'MenuCheckbox',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<SelectMenuItem[]>,
      default: () => [],
    },
  },
  methods: {
    handleUpdate(newValue: unknown) {
      this.$emit('update:model-value', Boolean(newValue))
    },
    handleSelect(item: SelectMenuItem) {
      this.$emit('select', item)
    },
  },
})
</script>
