import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'

import { Fleet } from 'rfs/frontend/proto/fleet_connect'
import { FleetVehicle, VehiclesRequest } from 'rfs/frontend/proto/fleet_pb'

export class FleetService {
  client: PromiseClient<typeof Fleet>

  constructor(transport: Transport) {
    this.client = createPromiseClient(Fleet, transport)
  }

  async fetchVehicles(): Promise<FleetVehicle[]> {
    const req = new VehiclesRequest()
    const res = await this.client.vehicles(req)
    return res.vehicles
  }
}
