import { DateTime } from 'luxon'
import { Header, Row, Options, DataTable } from '@/model/tables/DataTable'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Metric } from '@/model/control/waypoint'
import { GroupHelper } from '@/model/control/group/helper'
import { Format } from '@/utils/format'
import { formatDtWithZoneShort } from '@/utils/time'
import { NumberOrNull } from '@/types/charts'
import { InfoColumnGroup, InfoColumnItem } from '@/model/types'
import { SetPolicyEvent, SubmitWaypointsEvent } from 'rfs/control/proto/log_pb'
import { Policy, Params } from 'rfs/control/proto/policy_pb'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import { Timestamp } from '@/services/timestamp_pb'

export enum Columns {
  CREATED_AT = 'CREATED_AT',
  GROUP_NAME = 'GROUP_NAME',
  USER_EMAIL = 'USER_EMAIL',
  POLICY = 'POLICY',
  POLICY_PARAMS = 'POLICY_PARAMS',
}

// NOTE(rafael): values in milliseconds so we don't need to sort the rows
// ourselves. `CeDataTable.vue` sorts for us.
export interface LogRow extends Row {
  [Columns.CREATED_AT]: number // millis
  [Columns.GROUP_NAME]: string
  [Columns.USER_EMAIL]: string
  [Columns.POLICY]: string
  [Columns.POLICY_PARAMS]: string
  infoColumnGroup?: InfoColumnGroup
  scheduleId?: bigint
}

export type HistoryDataTable = DataTable<LogRow>

export type CustomOptions = Options<Columns>

const headerCreatedAt: Header<LogRow> = {
  title: 'Created At',
  key: Columns.CREATED_AT,
  sortable: true,
  value: (r) => formatDateTime(r[Columns.CREATED_AT]),
}

const headerName: Header = {
  title: 'Name',
  key: Columns.GROUP_NAME,
  sortable: true,
}

const headerUserEmail: Header = {
  title: 'User email',
  key: Columns.USER_EMAIL,
  sortable: true,
}

const headerPolicy: Header = {
  title: 'Policy',
  key: Columns.POLICY,
  sortable: true,
}

const headerPolicyParams: Header = {
  title: 'Params',
  key: Columns.POLICY_PARAMS,
  width: '20%',
  sortable: false,
}

export const headers: Header[] = [
  headerCreatedAt,
  headerName,
  headerUserEmail,
  headerPolicy,
  headerPolicyParams,
]

export function createInitialOptions(): CustomOptions {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column: Columns.CREATED_AT, descending: true },
  }
}

export function formatDateTime(v: undefined | NumberOrNull) {
  return v === null || v === undefined
    ? TEXT_NO_VALUE
    : formatDtWithZoneShort(DateTime.fromMillis(v))
}

export function createSetPolicyRow(
  createdAt: Timestamp,
  displayName: string,
  binary: Uint8Array,
  id?: string
): undefined | LogRow {
  const { eventId, principal, policy, params, policyStartTime, policyEndTime } =
    SetPolicyEvent.fromBinary(binary)

  const formattedParams = GroupHelper.getPolicyParams(params)

  if (
    // NOTE: "Controls off" has no params so we need to check if it's it.
    policy !== Policy.DEFAULT &&
    // NOTE: When there's nothing to display it means the "SetPolicy" log
    // is related to submitted waypoints. There is a log just
    // for the submitted waypoints. Discard this one.
    formattedParams === undefined
  ) {
    return undefined
  }

  const scheduleId = GroupHelper.getCurrentScheduleId(params)

  return {
    id: id !== undefined ? id : eventId,
    [Columns.CREATED_AT]: createdAt.toMillis(), // millis
    [Columns.GROUP_NAME]: displayName,
    [Columns.USER_EMAIL]: principal !== '' ? principal : TEXT_NO_VALUE,
    [Columns.POLICY]: GroupHelper.getPolicyLabel(policy, params),
    [Columns.POLICY_PARAMS]: formattedParams || TEXT_NO_VALUE,
    ...(scheduleId !== undefined && { scheduleId }),
    infoColumnGroup:
      policy === Policy.PEAK_TIME_PAYBACK_EVENT
        ? createPeakTimePaybackDetails(params, policyStartTime, policyEndTime)
        : undefined,
  }
}

export function createSubmitWaypointRow(
  createdAt: Timestamp,
  displayName: string,
  binary: Uint8Array
): LogRow {
  const { eventId, principal, waypoints, scheduleStartTime, scheduleEndTime } =
    SubmitWaypointsEvent.fromBinary(binary)

  return {
    id: eventId,
    infoColumnGroup: waypoints.length
      ? createWaypointsDetails(waypoints)
      : createErasedWaypoints(scheduleStartTime, scheduleEndTime),
    [Columns.CREATED_AT]: createdAt.toMillis(), // millis
    [Columns.GROUP_NAME]: displayName,
    [Columns.USER_EMAIL]: principal !== '' ? principal : TEXT_NO_VALUE,
    [Columns.POLICY]: GroupHelper.getPolicyLabel(
      Policy.DISPATCH_SCHEDULE_EVENT
    ),
    [Columns.POLICY_PARAMS]: TEXT_NO_VALUE,
  }
}

function createErasedWaypoints(
  start?: Timestamp,
  end?: Timestamp
): InfoColumnGroup {
  return [
    {
      id: '01',
      infoColumn: {
        name: 'All waypoints removed',
        subtitle: 'All waypoints in this interval were removed',
        subtitleBold: true,
        items: [
          { label: 'Start', text: formatDateTime(start?.toMillis()) },
          { label: 'End', text: formatDateTime(end?.toMillis()) },
        ],
      },
    },
  ]
}

function createWaypointsDetails(waypoints: Waypoint[]): InfoColumnGroup {
  return waypoints.map((w, idx) => {
    const items: InfoColumnItem[] = (() => {
      switch (w.targetMetric) {
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        case Metric.METRIC_ACTIVE_POWER_WATTS:
          return createTemporaryPowerWaypointItems(w, idx)
        case Metric.METRIC_SOC_PERCENT:
        case Metric.METRIC_SOC_WATT_HOURS:
          return createTemporarySoCWaypointItems(w, idx)
        default:
          return []
      }
    })()

    return {
      id: idx.toString(),
      infoColumn: {
        name:
          w.targetMetric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
            ? `Event ${idx}`
            : `Waypoint ${idx}`,
        items: [...items, { label: 'Priority', text: w.priority.toString() }],
      },
    }
  })
}

function createTemporarySoCWaypointItems(
  w: Waypoint,
  index: number
): InfoColumnItem[] {
  const setpointText = (() => {
    if (w.targetMetric === Metric.METRIC_SOC_PERCENT) {
      return Format.fmtPercentNoFraction(w.targetValue)
    } else if (w.targetMetric === Metric.METRIC_SOC_WATT_HOURS) {
      return Format.fmtEnergy(w.targetValue)
    } else {
      return TEXT_NO_VALUE
    }
  })()

  // NOTE: When "time" field exists, it's a legacy temporary waypoint.
  return w.time
    ? [
        { label: index.toString(), text: formatDateTime(w.time?.toMillis()) },
        { label: 'Setpoint', text: setpointText },
      ]
    : // "startTime" and "endTime".
      [
        { label: `Waypoint ${index}`, text: setpointText },
        { label: 'Start time', text: formatDateTime(w.startTime?.toMillis()) },
        { label: 'End time', text: formatDateTime(w.endTime?.toMillis()) },
      ]
}

function createTemporaryPowerWaypointItems(
  w: Waypoint,
  index: number
): InfoColumnItem[] {
  return [
    {
      label: `Event ${index}`,
      text: Format.fmtWatts(w.targetValue) || TEXT_NO_VALUE,
    },
    { label: 'Start time', text: formatDateTime(w.startTime?.toMillis()) },
    { label: 'End time', text: formatDateTime(w.endTime?.toMillis()) },
  ]
}

export function createRecurringWaypointsDetails(
  metric: Metric,
  waypoints: ScheduleWaypoint[]
): InfoColumnGroup {
  return waypoints.map((w, idx) => {
    const formattedValue = (() => {
      switch (metric) {
        case Metric.METRIC_SOC_PERCENT:
          return Format.fmtPercentNoFraction(w.value)
        case Metric.METRIC_SOC_WATT_HOURS:
          return Format.fmtEnergy(w.value)
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
          return Format.fmtWatts(w.value)
        default:
          return TEXT_NO_VALUE
      }
    })()

    const label =
      metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS
        ? `Event ${idx}`
        : `Waypoint ${idx}`

    const items: InfoColumnItem[] =
      // When no "startTime", legacy recurring waypoint.
      !w.startTime
        ? [
            { label: idx.toString(), text: w.scheduleTime },
            { label: 'Setpoint', text: formattedValue },
          ]
        : [
            { label, text: formattedValue },
            { label: 'Start time', text: w.startTime || TEXT_NO_VALUE },
            { label: 'End time', text: w.endTime || TEXT_NO_VALUE },
          ]

    return { id: idx.toString(), infoColumn: { items, name: label } }
  })
}

function createPeakTimePaybackDetails(
  params?: Params,
  start?: Timestamp,
  end?: Timestamp
): InfoColumnGroup {
  return [
    {
      id: 'peak-time-payback',
      infoColumn: {
        items: [
          {
            label: 'Setpoint',
            text: GroupHelper.getPolicyParams(params) || TEXT_NO_VALUE,
          },
          {
            label: 'Start time',
            text: formatDateTime(start?.toMillis()) || TEXT_NO_VALUE,
          },
          {
            label: 'End time',
            text: formatDateTime(end?.toMillis()) || TEXT_NO_VALUE,
          },
        ],
      },
    },
  ]
}
