import { DateTime } from 'luxon'

import { RittaConfig } from '@/config'
import { Services } from '@/services'
import { ChartType, ChartDefinition } from '@/types/charts'
import { LAGOON, MALIBU } from '@/constants/colors'
import { ITimeSeriesDataSource, TimeSeriesDataSource } from '@/model/charts'
import { ResourceGroup } from 'rfs/frontend/proto/capacity_pb'
import { Metric } from '@/constants/metrics'
import { CHART_SIZE_BIG } from '@/utils/charts'
import { TimeSeriesFetch } from '@/services/charts.service'

// Used to fetch all substations and aggregated as Net Load Forecast.
// It's a RegEx that is used in `ForecastDataRequest.resource_pattern.regex`.
const NET_LOAD_FORECAST_RESOURCE = '.*'

export const netLoadChartDefinition: ChartDefinition = {
  id: 'forecast-net-load-chart',
  title: 'Net load',
  type: ChartType.PowerMW,
  height: CHART_SIZE_BIG,
}

export function createNetLoadActualDataSource(
  services: Services,
  netLoadChartDefinition: ChartDefinition
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request: TimeSeriesFetch) => {
    return services.capacityService.fetchSupplyDemandTimeSeries(
      ResourceGroup.DEMAND,
      request.interval
    )
  })

  // Load (actual)
  ds.addChartSeries(netLoadChartDefinition, {
    resource: 'demand/net',
    metric: Metric.COND_POWER_REAL,
    unit: 'W',
    config: {
      seriesName: 'Load (actual)',
      seriesColor: MALIBU.hex,
    },
  })

  return ds
}

export function createNetLoadForecastDataSource(
  services: Services,
  netLoadChartDefinition: ChartDefinition,
  config: Readonly<RittaConfig>,
  endDate: DateTime
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) => {
    const product =
      config?.analysis?.forecastDashboard?.netLoadChart?.forecastProduct
    if (!product) throw new Error('No forecast product configured')

    return services.forecastService.getForecastData(
      NET_LOAD_FORECAST_RESOURCE,
      product,
      request
    )
  }, endDate)

  // Load (forecast)
  ds.addChartSeries(netLoadChartDefinition, {
    resource: NET_LOAD_FORECAST_RESOURCE,
    metric: Metric.FORECAST_COND_POWER_REAL,
    unit: 'W',
    config: {
      seriesName: 'Load (forecast)',
      seriesColor: LAGOON.hex,
      seriesLine: 'dashed',
    },
  })

  return ds
}
