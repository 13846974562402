<template>
  <div class="bg-grey-lighten-4 pa-4 position-relative" style="width: 21rem">
    <!-- Title -->
    <span class="d-block text-body-2 font-weight-medium pb-2">
      Observed power
    </span>

    <!-- Last value -->
    <span v-if="lastValue" class="d-block text-h4 pb-2">
      {{ lastValue }}
    </span>

    <!-- Info Column -->
    <info-column
      subtitle="Averages for chart range"
      :items="items"
      subtitle-bold
      subtitle-right
    />

    <!-- Refreshing -->
    <v-progress-linear
      v-if="isRefreshing"
      color="primary"
      location="bottom"
      indeterminate
      absolute
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import InfoColumn from '@/components/InfoColumn.vue'
import { InfoColumnItem } from '@/components/InfoColumn'

export default defineComponent({
  name: 'CapacitySummaryBox',
  props: {
    items: {
      type: Array as PropType<InfoColumnItem[]>,
      required: true,
    },
    lastValue: {
      type: String,
      required: false,
    },
    isRefreshing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { InfoColumn },
})
</script>
