import { ResourceType } from '@/constants/resourceType'
import { routeForResource } from '@/router/routeForResource'
import * as RouteNames from '@/router/routeNames'
import { getDisplayName } from '@/model/resource'
import type { LoadBlockFunction, Block, Result } from './types'

const TITLE = 'Substations & Feeders'

export const searchSubstations: LoadBlockFunction = async (
  searchText,
  maxResults,
  { services }
) => {
  const block: Block = { title: TITLE, results: [] }

  try {
    const [allSubstations, allFeeders] = await Promise.all([
      services.queryService.getResourcesByType(ResourceType.SUBSTATION),
      services.queryService.getResourcesByType(ResourceType.FEEDER),
    ])

    const searchLowerCase = searchText.toLowerCase()

    const substations = allSubstations.filter(
      (sub) => getDisplayName(sub).toLowerCase().search(searchLowerCase) !== -1
    )

    const feeders = allFeeders.filter(
      (fee) => getDisplayName(fee).toLowerCase().search(searchLowerCase) !== -1
    )

    for (const sub of substations) {
      const link = routeForResource(sub)

      if (!link) continue

      const item: Result = {
        title: { text: getDisplayName(sub), link },
        pairs: [{ label: 'Type', text: ResourceType.SUBSTATION }],
      }

      block.results.push(item)
    }

    for (const fee of feeders) {
      const link = routeForResource(fee)

      if (!link) continue

      const item: Result = {
        title: { text: getDisplayName(fee), link },
        pairs: [{ label: 'Type', text: ResourceType.FEEDER }],
      }

      block.results.push(item)
    }

    // See more.
    if (block.results.length > maxResults) {
      block.results = block.results.slice(0, maxResults)
      block.seeMore = { link: { name: RouteNames.SUBSTATIONS } }
    }
  } catch (err) {
    console.error('searchMeters: %o', err)
  }

  return block
}
