import { Interval } from 'luxon'
import * as RouteNames from '@/router/routeNames'
import { Services } from '@/services'
import {
  CapacityRow,
  Columns,
  CapacityDataTable,
  CapacityStats,
  getPowerStats,
  getPowerData,
} from '@/model/control/capacity'
import { getDisplayName, getUnqualifiedId } from '@/model/resource'
import { Device, Group } from 'rfs/control/proto/model_pb'
import { TimeSeries, TimeSeriesFetch } from '@/services/charts.service'
import {
  CommunicationStatusType,
  type CommunicationStatusMap,
} from '@/model/control/communicationStatus'
import { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import { findMaxMinMeanY } from '@/model/charts/TimeSeriesDataSource'
import { Metric } from '@/constants/metrics'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { Resource } from 'rfs/pb/resource_pb'

export function getDeviceStats(
  resourceId: string,
  series: TimeSeries[]
): CapacityStats {
  const [observedPower, commandedPower] = getPowerData(series, resourceId)

  if (!observedPower.length || !commandedPower.length) {
    return {}
  }

  const commandedPowerAvg = findMaxMinMeanY(commandedPower).meanY

  const { observedPowerAvg } = getPowerStats(observedPower, commandedPower)

  // Compliance is calculated as the average of the entire observed power time series
  // divided by the average of the entire commanded power time series
  const complianceAvg = commandedPowerAvg
    ? (observedPowerAvg || 0) / commandedPowerAvg
    : 0

  return {
    observedPowerAvg,
    commandedPowerAvg,
    complianceAvg,
  }
}

export function createGroupCapacityDataTable(
  group: Group,
  devices: Device[],
  deviceResources: Resource[],
  series: TimeSeries[],
  communicationStatusMap?: CommunicationStatusMap
): CapacityDataTable {
  const rows: CapacityRow[] = []

  if (deviceResources.length) {
    for (const resource of deviceResources) {
      const { commandedPowerAvg, observedPowerAvg, complianceAvg } =
        getDeviceStats(resource.id, series)

      rows.push({
        id: resource.id,
        route: {
          name: RouteNames.CONTROL_DEVICE_RESOURCE,
          params: { groupId: group.id, resourceId: resource.id },
        },
        [Columns.DEVICE_ID]: getDisplayName(resource),
        // TODO(rafael): What should we do for Resources that belong to a "pool/" Device?
        // The resources don't have a "oeEnabled" field, so we'd need to use the
        // "oeEnabled" field from the parent Device?
        [Columns.STATUS]: OperatingEnvelopeStatus.UNSPECIFIED,
        [Columns.COMMUNICATIONS]:
          communicationStatusMap?.get(resource.id) ??
          CommunicationStatusType.UNSPECIFIED,
        [Columns.OBSERVED_POWER]: observedPowerAvg ?? null,
        [Columns.COMMANDED_POWER]: commandedPowerAvg ?? null,
        [Columns.AVERAGE_COMPLIANCE]: complianceAvg ?? null,
      })
    }
  } else {
    for (const device of devices) {
      const { commandedPowerAvg, observedPowerAvg, complianceAvg } =
        getDeviceStats(device.camusResourceId, series)

      rows.push({
        id: device.id,
        route: {
          name: RouteNames.CONTROL_DEVICE,
          params: { groupId: group.id, deviceId: device.id },
        },
        [Columns.DEVICE_ID]: device.displayName || getUnqualifiedId(device.id),
        [Columns.STATUS]: device.oeEnabled
          ? OperatingEnvelopeStatus.ACTIVE
          : OperatingEnvelopeStatus.INACTIVE,
        [Columns.COMMUNICATIONS]:
          communicationStatusMap?.get(device.camusResourceId) ??
          CommunicationStatusType.UNSPECIFIED,
        [Columns.OBSERVED_POWER]: observedPowerAvg ?? null,
        [Columns.COMMANDED_POWER]: commandedPowerAvg ?? null,
        [Columns.AVERAGE_COMPLIANCE]: complianceAvg ?? null,
      })
    }
  }

  return { rows }
}

export async function getDevicesPerformancePowerData(
  services: Services,
  devices: Device[],
  deviceResources: Resource[],
  interval: Interval
): Promise<TimeSeries[]> {
  let series: TimeSeries[] = []

  const ids = deviceResources.length
    ? deviceResources.map((r) => r.id)
    : devices.map((r) => r.camusResourceId)

  const tsFetch: TimeSeriesFetch = {
    interval,
    metrics: [
      { metric: Metric.COND_POWER_REAL },
      { metric: Metric.INTENTIONS_POWER_REAL },
      { metric: Metric.INTENTIONS_POWER_LIMITS_CONSUMING },
    ],
    resolution: Resolution.FIVE_MINUTE,
  }

  try {
    const res = await services.chartsService.fetchTimeSeriesForMultiResources(
      ids,
      tsFetch
    )
    series = res.series
  } catch (err) {
    console.error('getDevicesPerformancePowerData: %p', err)
  }

  return series
}
