/**
 * Returns an iframe with all styles necessary for it to be hidden in the page.
 */
function createHiddenIframe(id: string, src: string): HTMLIFrameElement {
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', id)
  iframe.src = src
  iframe.style.width = '0'
  iframe.style.height = '0'
  iframe.style.border = 'none'
  iframe.style.visibility = 'hidden'
  iframe.style.opacity = '0'
  iframe.style.position = 'fixed'
  iframe.style.pointerEvents = 'none'
  return iframe
}

/** * Inserts a hidden iframe element in to the page's body. */
export function insertHiddenIframe(id: string, src: string): HTMLIFrameElement {
  const iframe = createHiddenIframe(id, src)
  document.getElementsByTagName('body')[0].appendChild(iframe)
  return iframe
}
