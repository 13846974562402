import { shallowReactive } from 'vue'
import type { Chart } from 'chart.js'
import { defineImmutableStore } from './defineStore'
import type { NamedTimeSeries } from '@/model/charts/TimeSeriesDataSource'

export const usePreferencesStore = defineImmutableStore('preferences', {
  persist: { paths: ['hiddenChartSeries'] },
  state: () => {
    return shallowReactive({
      hiddenChartSeries: new Set<string>(),
      // Whether the resource operations panel is in full view.
      resourceOperationsFullPanel: false,
    })
  },
  actions: {
    syncChartSeriesVisibility(opts: {
      chart: Chart
      chartId: string
      chartSeries: Readonly<NamedTimeSeries[]>
      isFirstRun?: boolean
    }): void {
      opts.chart.data.datasets.forEach((ds, dsIndex) => {
        const namedTimeSeries = opts.chartSeries.find(
          (cs) => cs.config.seriesName === ds.label
        )

        if (!namedTimeSeries) return

        const key = createChartKey(opts.chartId, namedTimeSeries)

        if (opts.isFirstRun) {
          // If the global state indicates this dataset should be hidden, apply
          // the hidden state to the dataset.
          if (this.hiddenChartSeries.has(key)) {
            const meta = opts.chart.getDatasetMeta(dsIndex)
            meta.hidden = true
          }
        } else {
          // Update the global visibility state based on current chart visibility.
          const newSet = new Set(this.hiddenChartSeries)

          if (opts.chart.isDatasetVisible(dsIndex)) {
            newSet.delete(key)
          } else {
            newSet.add(key)
          }

          this.hiddenChartSeries = newSet
        }
      })
    },
    toggleResourceOperationsFullPanel(): void {
      this.resourceOperationsFullPanel = !this.resourceOperationsFullPanel
    },
  },
})

function createChartKey(chartId: string, series: NamedTimeSeries): string {
  const elements: string[] = [chartId, series.metric]

  if (series.aggregation) {
    elements.push(`agg:${series.aggregation}`)
  }

  return elements.join('|')
}
