import { PathStyleExtension } from '@deck.gl/extensions'
import { FeatureCollection } from 'geojson'

import { MapLayerId, RittaConfig } from '@/config/types'
import { Services } from '@/services'
import {
  SpecialLines,
  TransmissionLineFeature,
  TransmissionLineThirdPartyFeature,
} from '@/services/types'
import { rgbRed, rgbaTransparent } from '@/constants/colors'
import { MapManagerLayer } from '@/model/map/types'
import { MultiGeoJsonLayer } from './extensions'
import { ApplicationMapLayer } from './catalog'

import iconTransmission from '@/assets/imgs/albert_ic_transmission--black.svg'

type TransmissionData = {
  transmissionLine: undefined | TransmissionLineFeature
  transmissionLineThirdParty: undefined | TransmissionLineThirdPartyFeature
}

const DEFAULT_LINE_COLOR = rgbRed

/** * Returns an object indexed by type of resource. */
async function getData(
  config: Readonly<RittaConfig>,
  services: Services
): Promise<TransmissionData> {
  const response: TransmissionData = {
    transmissionLine: undefined,
    transmissionLineThirdParty: undefined,
  }

  try {
    const path = config.featureFlags.transmissionLayerGCS

    if (!path) {
      throw new Error('getData: gcs path not available')
    }

    const lines = (await services.gcsService.fetchJSON(path)) as SpecialLines

    response.transmissionLine = lines.TransmissionLine
    response.transmissionLineThirdParty = lines.TransmissionLineThirdParty
  } catch (err) {
    console.error('[MapLayerTransmission]: %o', err)
  }

  return response
}

function transmissionMapManagerLayer(
  id: MapLayerId,
  config: Readonly<RittaConfig>,
  services: Services
): MapManagerLayer {
  const response = getData(config, services)

  const lineColor = DEFAULT_LINE_COLOR
  const lineWidthUnits = 'pixels'
  const getLineColor = () => lineColor

  return new MultiGeoJsonLayer({
    id,
    propsList: [
      // The next geojson layer is for the Transmission lines.
      {
        lineWidthUnits,
        data: response.then((data): FeatureCollection => {
          const features: FeatureCollection['features'] = data.transmissionLine
            ? [data.transmissionLine]
            : []
          return { type: 'FeatureCollection', features }
        }),
        getLineColor,
        getLineWidth: () => 7,
      },
      // The next two geojson layers are for the Third party Transmission lines.
      // The first one creates the _invisible_ but pickable geojson layer.
      {
        pickable: true,
        lineWidthUnits,
        data: response.then((data) => {
          const features: FeatureCollection['features'] =
            data.transmissionLineThirdParty
              ? [data.transmissionLineThirdParty]
              : []
          return { type: 'FeatureCollection', features }
        }),
        getLineWidth: () => 4,
        getLineColor: () => rgbaTransparent,
      },
      // the second one works as an aesthetic layer. It creates a double-line
      // unpickable geojson layer.
      {
        lineWidthUnits,
        data: response.then((data): FeatureCollection => {
          const feature = data.transmissionLineThirdParty

          const features: FeatureCollection['features'] = feature
            ? [
                // NOTE(rafael): adding an id for each Feature so they can be used by the `getOffset` accessor fn.
                { ...feature, id: 1 },
                { ...feature, id: 2 },
              ]
            : []

          return { type: 'FeatureCollection', features }
        }),
        getLineColor,
        getLineWidth: () => 2,
        // @ts-ignore - Used by `PathStyleExtension`.
        getOffset: (d) => (d.id === 1 ? 1 : -1),
        extensions: [new PathStyleExtension({ offset: true })],
      },
    ],
  })
}

export function transmission(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Transmission',
    icon: iconTransmission,
    infoWindow: {
      infoColumn: () => ({ subtitle: 'Third Party Transmission Line' }),
      ignoresResource: true,
    },
    mapManagerLayer: (config, services) =>
      transmissionMapManagerLayer(id, config, services),
  }
}
