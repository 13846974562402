import { DateTime } from 'luxon'
import { STANDARD_COLORS } from '@/constants/colorPalette'
import { Metric } from '@/constants/metrics'
import type { Services } from '@/services'
import { MetricCalculation } from '@/services/charts.service'
import { limitInterval } from '@/utils/time'
import {
  TimeSeriesDataSource,
  type ITimeSeriesDataSource,
} from '@/model/charts'
import { ONE_TILE_SIZE } from '@/model/charts/TimeSeriesDataSource'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import type { Group } from 'rfs/control/proto/model_pb'
import { type ChartDefinition, ChartType } from '@/types/charts'

export const overviewChartDefinition: ChartDefinition = {
  id: 'overview-chart',
  title: 'Controllable Resources',
  type: ChartType.Power,
  isAreaChart: true,
}

export function createOverviewDataSource(
  services: Services,
  group: Group,
  getNow: () => DateTime,
  randomNumber = 0
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource(
    async (request) =>
      services.controlsData.fetchGroupTimeSeries(group.id, {
        ...request,
        interval: limitInterval(request.interval, getNow()),
        resolution: Resolution.FIFTEEN_MINUTE,
      }),
    DateTime.now(),
    ONE_TILE_SIZE
  )

  ds.addChartSeries(overviewChartDefinition, {
    metric: Metric.COND_POWER_REAL,
    calculation: MetricCalculation.SUM,
    config: {
      seriesName: group.displayName,
      seriesColor: STANDARD_COLORS[randomNumber % STANDARD_COLORS.length].hex,
    },
  })

  return ds
}
