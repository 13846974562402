<template>
  <v-chip
    :color="bgColor"
    density="comfortable"
    variant="flat"
    aria-label="Operating Envelope status"
    :style="{ border: `1px solid ${fontColor}` }"
  >
    <span class="text-body-2" :style="{ color: fontColor }">{{ label }}</span>
  </v-chip>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { APPLE_50, APPLE_800, OCHRE_50, OCHRE_800 } from '@/constants/colors'
import {
  getOELabel,
  OperatingEnvelopeStatus,
} from '@/model/control/operatingEnvelope'

export default defineComponent({
  name: 'OperatingEnvelopeBadge',
  props: {
    status: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: true,
    },
  },
  computed: {
    label(): string {
      return getOELabel(this.status)
    },
    bgColor(): string {
      switch (this.status) {
        case OperatingEnvelopeStatus.ACTIVE:
        case OperatingEnvelopeStatus.PARTIAL:
          return APPLE_50.hex
        default:
          return OCHRE_50.hex
      }
    },
    fontColor(): string {
      switch (this.status) {
        case OperatingEnvelopeStatus.ACTIVE:
        case OperatingEnvelopeStatus.PARTIAL:
          return APPLE_800.hex
        default:
          return OCHRE_800.hex
      }
    },
  },
})
</script>
