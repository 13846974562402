import { Hex, RGB, RGBRaw } from '@/constants/colors'

/**
 * Formats the a string RGB into a returned array of RGB values
 * If and alpha transparency is provided it is append to the array
 * Standard RGBA css strings have alpha values from 0-1 or 0% - 100%
 * Array values use 0 - 255
 * @param {rgb} rgbColorString "rgba(234, 236, 204, 1)"
 * @param {number} alpha 0 - 255
 * @returns {rgbRaw} [234, 236, 204, 0]
 */
export function rgbStringToRaw(rgbColorString: RGB, alpha?: number): RGBRaw {
  const arr = rgbColorString
    .split(/[^0-9]/)
    .filter((v) => !!v)
    .map((v) => parseInt(v)) as RGBRaw
  if (alpha) arr[3] = alpha
  return arr
}

/**
 * Formats the a string RGB into a returned array of RGBA values
 * @param hex "#RRGGBB"
 * @param alpha 0 - 255
 * @returns
 */
export function hexToRgbA(hex: string, alpha = 255): RGBRaw | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result) {
    return [
      parseInt(result[1], 16), // Red
      parseInt(result[2], 16), // Green
      parseInt(result[3], 16), // Blue
      alpha, // Alpha, default is 255
    ]
  }
  return null
}

/**
 * Return a (hex) color with an opacity set to the given value.
 * @param color "#RRGGBB[AA]"
 * @param opacity 0 - 1.0
 * @returns The updated color string "#RRGGBBAA"
 */
export function colorWithOpacity(color: Hex, opacity: number): string {
  if (color.length === 9) {
    color = color.substring(0, 7) as Hex // Remove the existing opacity
  }
  return color + Math.floor(opacity * 0xff).toString(16)
}
