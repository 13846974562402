import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import { Router } from 'vue-router'
import { getEnvironment } from '@/utils/environment'

const INTERNAL_USER_DOMAIN = 'camus-infra.iam.gserviceaccount.com'

export function initSentry(
  app: ReturnType<typeof createApp>,
  customerShortName: string,
  router: Router,
  release: string
) {
  // https://docs.sentry.io/platforms/javascript/guides/vue/#vue-3
  Sentry.init({
    app,
    // Sentry Data Source Name (https://docs.sentry.io/product/sentry-basics/dsn-explainer/)
    dsn: 'https://7a26c274da954ed7a4f815d04b90d041@o418843.ingest.sentry.io/5324548',
    release,
    environment: getEnvironment(),
    logErrors: true,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tracingOptions: { trackComponents: true },
  })

  // key:value that will be sent as tags data with the event.
  Sentry.setTag('customer', customerShortName)
}

/**
 * Set the user context for Sentry.
 * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 */
export function setSentryUser(email: string) {
  // Do not waste Sentry on the feature probe user
  if (email.endsWith(INTERNAL_USER_DOMAIN)) {
    Sentry.close()
  } else {
    Sentry.setUser({ email })
  }
}
