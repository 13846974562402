import Cookies from 'js-cookie'
import { DateTime } from 'luxon'
import { RittaConfig } from '@/config'

const HTML_ID_V2G_BUS_EASTER_EGG = 'v2gBusEasterEgg'

export function isV2gBusEasterEggEnabled(
  config: Readonly<RittaConfig>
): boolean {
  return !!config.featureFlags.v2gBusEasterEgg
}

export function addV2gBusEasterEgg(): void {
  const newIframe = document.createElement('iframe')
  // @ts-ignore
  newIframe.style =
    'width:0;height:0;border:0;border:none;visibility:hidden;opacity:0;position:fixed;pointer-events:none;'
  newIframe.setAttribute('id', HTML_ID_V2G_BUS_EASTER_EGG)
  newIframe.setAttribute('width', '0')
  newIframe.setAttribute('height', '0')
  newIframe.setAttribute('frameborder', '0')
  newIframe.setAttribute(
    'allow',
    'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
  )
  newIframe.setAttribute(
    'src',
    'https://www.youtube.com/embed/zFHBfFAmcbc?autoplay=1&start=11&end=20&mute=0'
  )
  document.body.appendChild(newIframe)

  setV2gBusEasterEggCookie()
}

export function removeV2gBusEasterEgg(): void {
  const iframe = document.getElementById(HTML_ID_V2G_BUS_EASTER_EGG)

  if (iframe) {
    iframe.remove()
  }
}

export function isTodayFriday(): boolean {
  return DateTime.local().weekday === 5
}

export function hasV2gBusEasterEggActivatedBefore(): boolean {
  return !!Cookies.get(HTML_ID_V2G_BUS_EASTER_EGG)
}

function setV2gBusEasterEggCookie(): void {
  Cookies.set(HTML_ID_V2G_BUS_EASTER_EGG, 'true', {
    expires: 1, // 1 day.
  })
}
