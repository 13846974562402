<template>
  <div class="position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-if="!isLoading && alert">
      <!-- Name -->
      <ce-title class="pb-6">{{ alert.name }}</ce-title>

      <!-- Description -->
      <p class="body-1 pb-6">{{ alert.description }}</p>

      <!-- Silence -->
      <!-- TODO(rafael): show button when "silence" action available in the server -->
      <v-btn
        v-show="false"
        elevation="0"
        color="blue lighten-4"
        outlined
        @click.stop="silence"
      >
        <!-- Icon -->
        <v-icon color="blue darken-4">mdi-bell-off-outline</v-icon>
        <!-- Label -->
        <span class="blue--text text--darken-4 text-capitalize">
          {{ btnSilenceLabel }}
        </span>
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as RouteNames from '@/router/routeNames'
import { AlertHelper } from '@/model/alert/helper'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeTitle from '@/components/CeTitle.vue'
import { Alert } from 'rfs/frontend/proto/alert_pb'

export default defineComponent({
  name: 'AlertDetails',
  props: {
    alertId: {
      type: String,
      required: true,
    },
  },
  components: { CenteredSpinner, CeTitle },
  setup() {
    return {
      alerts: [] as Alert[],
      btnSilenceLabel: 'Silence alert',
    }
  },
  data() {
    return {
      isLoading: false,
      alert: null as null | Alert,
    }
  },
  watch: {
    alertId: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
  },
  methods: {
    silence(): void {
      // TODO(rafael)
      console.debug('silence')
    },
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.alert = null

      try {
        if (!this.alerts.length) {
          const { alerts } = await this.$services.alertService.listAlerts({
            // TODO(rafael): stop using `listAlerts` when endpoint to fetch
            // single alert available.
            limit: 9999,
          })
          this.alerts = alerts
        }

        const alert = this.alerts.find(
          (a) => a.id === AlertHelper.regenerateUnqualifiedId(this.alertId)
        )

        if (!alert) throw new Error(`Alert with id "${this.alertId}" not found`)

        this.alert = alert
      } catch (err) {
        this.$router.replace({ name: RouteNames.ALERTS })
        console.error('AlertDetails.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
