import { ResourceType } from '@/constants/resourceType'
import type { Header } from '@/model/tables/DataTable'
import {
  TransformerSeriesResponseV2_MeterStats as MeterStats,
  Transformer,
} from 'rfs/frontend/proto/transformer_details_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { Format } from '@/utils/format'
import { getUnqualifiedId } from '@/model/resource'
import { QueryService } from '@/services/query.service'

type Dictionary = (id: string) => string

type MeterStatsMap = Record<string, MeterStats>

export interface MeterTableItem {
  meter: Resource
  meterId: string
  siteId: string
  minPower?: number
  maxPower?: number
  meanPower?: number
}

const DOWNLINE_RESOURCES = [
  ResourceType.METER_ELECTRICAL,
  ResourceType.BATTERY_DISTRIBUTED,
  ResourceType.CHARGER,
  ResourceType.SOLAR_DISTRIBUTED,
]

/**
 * Fetch the resources downline of the given transformer.
 * Meters are fetched to populate the list of downline meters.
 * DERs are fetched to show which sites have DER installed.
 */
export async function fetchDownlineResources(
  transformer: Transformer,
  queryService: QueryService
): Promise<[Resource[], Resource[]]> {
  if (!transformer.uplineReference) {
    throw new Error('Missing transformer upline reference')
  }

  const downlineResources = await queryService.getResourcesByUpline(
    transformer.uplineReference,
    DOWNLINE_RESOURCES
  )
  const meters = downlineResources.filter(
    (r) => r.type === ResourceType.METER_ELECTRICAL
  )
  const ders = downlineResources.filter(
    (r) => r.type !== ResourceType.METER_ELECTRICAL
  )
  return [meters, ders]
}

/**
 * These headers bind to properties of a `MeterTableItem` object.
 * @see MeterTableItem
 */
export function meterTableHeaders(dictionary: Dictionary): readonly Header[] {
  return Object.freeze([
    { title: dictionary('Site ID'), key: 'siteId' },
    { title: 'Service Meter', key: 'meterId', format: getUnqualifiedId },
    { title: 'Peak (kW)', key: 'maxPower', format: Format.fmtWatts },
    { title: 'Average (kW)', key: 'meanPower', format: Format.fmtWatts },
    { title: 'DERs', key: 'siteDERs' },
  ])
}

/**
 * Combines the resources and the statistics to populate meter table items.
 */
export function meterTableItems(
  resources: Resource[],
  statistics: MeterStatsMap
): MeterTableItem[] {
  return resources.map((resource) => {
    const stats = statistics[resource.id]
    return {
      meter: resource,
      meterId: resource.id,
      siteId: resource.site?.id ?? '',
      minPower: stats?.minPower,
      maxPower: stats?.maxPower,
      meanPower: stats?.meanPower,
    }
  })
}

export function compareTableItemsBy(sortBy: keyof MeterTableItem) {
  return function (a: MeterTableItem, b: MeterTableItem): number {
    const left = a[sortBy]
    const right = b[sortBy]

    if (typeof left === 'number') {
      return left - (right as number)
    } else if (typeof left === 'string') {
      return left.localeCompare(right as string)
    } else {
      return left === right ? 0 : -1
    }
  }
}
