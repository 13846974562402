import { CILANTRO } from '@/constants/colors'
import { Metric } from '@/constants/metrics'
import { Services } from '@/services'
import { Resource } from 'rfs/pb/resource_pb'
import { ITimeSeriesDataSource, TimeSeriesDataSource } from '@/model/charts'
import { ChartType, ChartDefinition } from '@/types/charts'

const CHART_OUTPUT_TITLE = 'Output'

export const outputChartDefinition: ChartDefinition = {
  id: 'output-chart',
  title: CHART_OUTPUT_TITLE,
  type: ChartType.Power,
  isAreaChart: true,
}

export function newUtilitySolarOutputDataSource(
  services: Services,
  utilitySolar: Resource
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) =>
    services.chartsService.fetchTimeSeries(utilitySolar.id, request)
  )

  if (utilitySolar.meter?.electrical) {
    // Output (AMI).
    ds.addChartSeries(outputChartDefinition, {
      metric: Metric.POWER_PRODUCED,
      config: {
        seriesName: CHART_OUTPUT_TITLE,
        seriesColor: CILANTRO.hex,
      },
    })
  } else {
    // Output (SCADA).
    ds.addChartSeries(outputChartDefinition, {
      metric: Metric.COND_POWER_REAL,
      unit: '-W',
      config: {
        seriesName: CHART_OUTPUT_TITLE,
        seriesColor: CILANTRO.hex,
      },
    })
  }

  return ds
}
