import * as COLORS from './colors'

export type ColorPalette = readonly COLORS.Color[]

export const HEATMAP: ColorPalette = [
  // '#364B9A',
  COLORS.BLUE1,
  // '#4A7BB7',
  COLORS.BLUE2,
  // '#6EA6CD',
  COLORS.BLUE3,
  // '#98CAE1',
  COLORS.BLUE4,
  // '#C2E4EF',
  COLORS.BLUE5,
  // '#EAECCC',
  COLORS.YELLOW1,
  // '#FEDA8B',
  COLORS.YELLOW2,
  // '#FDB366',
  COLORS.YELLOW3,
  // '#F67E4B',
  COLORS.ORANGE1,
  // '#DD3D2D',
  COLORS.RED1,
  // '#A50026',
  COLORS.RED2,
]
export const HEATMAP_OUTLINE: ColorPalette = [
  COLORS.BLUE1_DARK,
  COLORS.BLUE2_DARK,
  COLORS.BLUE3_DARK,
  COLORS.BLUE4_DARK,
  COLORS.BLUE5_DARK,
  COLORS.YELLOW1_DARK,
  COLORS.YELLOW2_DARK,
  COLORS.YELLOW3_DARK,
  COLORS.ORANGE1_DARK,
  COLORS.RED1_DARK,
  COLORS.RED2_DARK,
]

/**
 * This palette includes all the common colors and black.
 * It's often used for charts with a variable number of series.
 */
export const STANDARD_COLORS: ColorPalette = [
  COLORS.BUTTERCUP,
  COLORS.VELVET,
  COLORS.JAFFA,
  COLORS.MALIBU,
  COLORS.CARDINAL,
  COLORS.WALLABY,
  COLORS.ELEPHANT,
  COLORS.PACIFIC,
  COLORS.BUBBLEGUM,
  COLORS.LAGOON,
  COLORS.DOLPHIN,
  COLORS.CILANTRO,
  COLORS.BLACK,
]

/**
 * US 3-phase wiring standard colors
 * https://www.graphicproducts.com/articles/wire-color-coding/
 */
export const THREE_PHASE: ColorPalette = [
  COLORS.BLACK,
  COLORS.CARDINAL,
  COLORS.PACIFIC,
]
