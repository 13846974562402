import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import FleetPanel from '@/components/others/FleetPanel.vue'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export const TITLE_FLEET = 'Fleet'

export function fleetRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  if (config.monitor?.fleet?.enabled) {
    return [
      {
        path: '/fleet',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.FLEET,
            meta: {
              tab: TAB,
              resetMapCenterAndZoom: true,
              pageTitle: TITLE_FLEET,
            },
            components: {
              leftPanel: FleetPanel,
            },
          },
          {
            path: ':vehicleId',
            name: RouteNames.VEHICLE,
            meta: { tab: TAB },
            components: {
              leftPanel: FleetPanel,
            },
            props: {
              leftPanel: true,
            },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
