<template>
  <v-menu transition="slide-y-transition" offset-y left>
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" elevation="0" icon variant="text">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </template>

    <!-- Menu -->
    <v-list>
      <v-list-item
        v-for="o of menuOptions"
        :key="o.text"
        @click="() => $emit(o.event)"
      >
        <v-list-item-title>{{ o.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ChartMenuOption } from './ChartMenu'

export default defineComponent({
  name: 'ChartMenu',
  props: {
    menuOptions: {
      type: Array as PropType<ChartMenuOption[]>,
      required: true,
    },
  },
})
</script>
