<template>
  <div class="position-relative min-height-100 pa-6">
    <!-- Loading -->
    <centered-spinner v-if="areTypesLoading" />

    <!-- Charts -->
    <time-series-chart-group
      v-else
      :charts="charts"
      :data-source="dataSource"
      :interval="selectedInterval"
      @new-interval="handleNewInterval"
      @new-time-series="handleNewTimeSeries"
    >
      <!-- InfoBox: Demand, Supply, Emissions -->
      <template
        v-for="item in [demand, supply, emissions]"
        :key="item.summary.metricType"
        v-slot:[`right-side-of-${item.chartId}`]
      >
        <info-box
          :data-test="`info-box_${item.chartId}`"
          :metric-type="item.summary.metricType"
          :metric-value="item.summary.metricValue"
          :metrics-data="item.summary.metricsData"
        />
      </template>

      <!-- Peaks Table: Carbon Free -->
      <template v-slot:right-side-of-carbon-free-chart>
        <hourly-daily-peak-table :peaks-data="carbonFree.summary.peaksData" />
      </template>
    </time-series-chart-group>
  </div>
</template>

<script lang="ts">
import { DateTime, Interval } from 'luxon'
import { defineComponent, shallowReactive } from 'vue'
import InfoBox from './InfoBox.vue'
import HourlyDailyPeakTable from './HourlyDailyPeakTable.vue'
import { PeaksData, StatsData } from './AnalyzeChart'
import {
  analyzeChartDefinitions,
  newSupplyDemandDataSource,
  timeSeriesTotalByTimeStamp,
  cacheKeyToDisplayName,
  CARBON_NEUTRAL_CACHE_KEY,
  DEMAND_NET_CACHE_KEY,
  newCarbonEmissionsTimeSeriesDataSource,
  updateEmissionsIntensityTooltip,
  demandChartDefinition,
  supplyChartDefinition,
  emissionsIntensityChartDefinition,
  updateCarbonSeries,
  SUPPLY_RESOURCES,
} from '../analyzeDashboard/AnalyzeChartData'
import { ResourceGroup } from 'rfs/frontend/proto/capacity_pb'
import { AggregateDataSource, ITimeSeriesDataSource } from '@/model/charts'
import { intervalLast7Days } from '@/utils/time'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import { TimeSeriesChartGroup } from '@/components/common'
import { TimeSeriesResponse } from 'rfs/frontend/proto/tsdb_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { TimeSeries, TimeSeriesFetch } from '@/services/charts.service'
import { Format, MEGA_OPTIONS, FmtOptions } from '@/utils/format'
import { Metric } from '@/constants/metrics'
import { ResourceType } from '@/constants/resourceType'
import {
  getLastDataPoint,
  NamedTimeSeries,
  TimeSeriesMap,
} from '@/model/charts/TimeSeriesDataSource'

type Summary = {
  metricType: string
  metricValue: string
  metricsData: StatsData[]
}

type CarbonFreeSummry = {
  peaksData: PeaksData[]
}

export default defineComponent({
  name: 'AnalyzeDashboard',
  components: {
    CenteredSpinner,
    InfoBox,
    HourlyDailyPeakTable,
    TimeSeriesChartGroup,
  },
  data() {
    const hasEmissionsData =
      !!this.$rittaConfig.analysis?.analyzeDashboard?.provider
    const charts = analyzeChartDefinitions(hasEmissionsData)
    // If there is forecast emissions data, show 24 hours more than the current time
    // otherwise, show the current time
    const selectedInterval = intervalLast7Days(
      hasEmissionsData
        ? this.$observationTime().plus({ hours: 24 })
        : this.$observationTime()
    )

    return shallowReactive({
      charts,
      hasEmissionsData,
      areTypesLoading: false,
      availableResources: new Set() as Set<ResourceType>,
      demand: {
        chartId: demandChartDefinition.id,
        summary: shallowReactive<Summary>({
          metricType: 'Net Demand',
          metricValue: '',
          metricsData: [],
        }),
      },
      supply: {
        chartId: supplyChartDefinition.id,
        summary: shallowReactive<Summary>({
          metricType: 'Supply',
          metricValue: '',
          metricsData: [],
        }),
      },
      emissions: {
        chartId: emissionsIntensityChartDefinition.id,
        summary: shallowReactive<Summary>({
          metricType: 'Emissions Intensity (kg CO₂e/MWh)',
          metricValue: '',
          metricsData: [],
        }),
      },
      carbonFree: {
        title: 'Carbon-Free Electricity',
        summary: {
          peaksData: [] as PeaksData[],
        } as CarbonFreeSummry,
      },
      selectedInterval,
    })
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.selectedInterval = newInterval
    },
    handleNewTimeSeries(newTS: TimeSeriesMap): void {
      const lastDPMap = getLastDataPoint(newTS)
      const lastDPDemand = lastDPMap.get(demandChartDefinition.id)
      const lastDPSupply = lastDPMap.get(supplyChartDefinition.id)
      const lastDPEmissionsIntensity = lastDPMap.get(
        emissionsIntensityChartDefinition.id
      )

      if (lastDPDemand) {
        this.demand.summary.metricValue = this.createMetricValue(
          lastDPDemand,
          Format.fmtWatts,
          MEGA_OPTIONS,
          DEMAND_NET_CACHE_KEY
        )
      }
      if (lastDPSupply) {
        this.supply.summary.metricValue = this.createMetricValue(
          lastDPSupply,
          Format.fmtWatts,
          MEGA_OPTIONS
        )
      }
      if (lastDPEmissionsIntensity) {
        this.emissions.summary.metricValue = this.createMetricValue(
          lastDPEmissionsIntensity,
          Format.fmtKgPerMWh,
          { sigFigs: 3 },
          Metric.COND_POWER_EMISSIONS
        )
      }
    },
    async fetchSummaryData(resourceGroup: ResourceGroup): Promise<void> {
      const now = DateTime.now()
      const ytd = Interval.fromDateTimes(now.startOf('year'), now)
      // Fetch summary data for the year to date
      try {
        const data =
          await this.$services.capacityService.fetchSupplyDemandTimeSeries(
            resourceGroup,
            ytd
          )

        const summary =
          resourceGroup === ResourceGroup.DEMAND
            ? this.demand.summary
            : this.supply.summary

        summary.metricsData = this.createMetricsData(
          data.series,
          Format.fmtWatts,
          MEGA_OPTIONS
        )
      } catch (err) {
        console.error('Error fetching summary data', err)
      }
    },
    async fetchEmissionsSummaryData() {
      const now = DateTime.now()
      const ytd = Interval.fromDateTimes(now.startOf('year'), now)
      const fetch: TimeSeriesFetch = {
        resolution: Resolution.ONE_HOUR,
        interval: ytd,
        metrics: [
          { metric: Metric.COND_POWER_EMISSIONS, unit: 'kg/MWh' },
          { metric: Metric.EMISSIONS_INTENSITY_CONSUMED_CO2E, unit: 'kg/MWh' },
        ],
      }
      // Fetch summary data for the year to date
      try {
        const data =
          await this.$services.capacityService.fetchEmissionsIntensityTimeSeries(
            this.$rittaConfig?.analysis?.analyzeDashboard?.provider ?? '',
            fetch
          )

        this.emissions.summary.metricsData = this.createMetricsData(
          data.series,
          Math.round,
          { sigFigs: 3 }
        )
      } catch (err) {
        console.error('Error fetching summary data', err)
      }
    },
    async fetchCarbonFreeSummaryData() {
      const now = DateTime.now()
      const ytd = Interval.fromDateTimes(now.startOf('year'), now)
      // Fetch summary data for the year to date
      const hourlyData =
        await this.$services.capacityService.fetchSupplyDemandTimeSeries(
          ResourceGroup.SUPPLY_CARBON_FREE,
          ytd,
          Resolution.ONE_HOUR
        )

      const dailyData =
        await this.$services.capacityService.fetchSupplyDemandTimeSeries(
          ResourceGroup.SUPPLY_CARBON_FREE,
          ytd,
          Resolution.ONE_DAY
        )

      this.carbonFree.summary.peaksData = this.createCarbonFreeSummaryData(
        updateCarbonSeries(hourlyData),
        updateCarbonSeries(dailyData)
      )
    },
    createMetricsData(
      series: TimeSeries[],
      formatter: Function,
      fmtOpts?: FmtOptions
    ): StatsData[] {
      const minMaxMeanData: StatsData[] = []
      for (const { resource, metric, minY, maxY, meanY } of series) {
        // if min, max, and mean are all 0, don't display the data
        if (minY === 0 && maxY === 0 && meanY === 0) {
          continue
        }
        minMaxMeanData.push({
          resource:
            // CARBON EMISSIONS INTENSITY uses metric not the resource: `provider/`
            cacheKeyToDisplayName[resource] ?? cacheKeyToDisplayName[metric],
          minY: formatter(minY, fmtOpts),
          maxY: formatter(maxY, fmtOpts),
          meanY: formatter(meanY, fmtOpts),
        })
      }
      // sort the data by legend name from cacheKeyToDisplayName
      const legendOrder = Object.values(cacheKeyToDisplayName)
      return minMaxMeanData.sort((a, b) => {
        return legendOrder.indexOf(a.resource) - legendOrder.indexOf(b.resource)
      })
    },
    createMetricValue(
      series: readonly NamedTimeSeries[],
      formatter: Function,
      fmtOpts?: FmtOptions,
      selectedMetric?: string
    ): string {
      const mostRecentData = []
      for (const { resource, metric, data } of series) {
        const lastDataPoint = data.at(-1)
        // push the last data point for selected resource or metric into mostRecentData
        // if the a selected metric is provided, only push the last data point
        // for the selected metric
        if (
          (selectedMetric &&
            lastDataPoint &&
            (resource === selectedMetric || metric === selectedMetric)) ||
          (!selectedMetric && lastDataPoint)
        ) {
          mostRecentData.push(lastDataPoint)
        }
      }
      // reduce mostRecentData into a total for each timestamp using a map
      const reducedValues = mostRecentData.reduce(
        timeSeriesTotalByTimeStamp,
        new Map<number, number>()
      )
      const mostRecentTimestamp = Math.max(...reducedValues.keys())
      const metricValue = reducedValues.get(mostRecentTimestamp) ?? 0

      return formatter(metricValue, fmtOpts)
    },
    createCarbonFreeSummaryData(
      hourlyData: TimeSeriesResponse,
      dailyData: TimeSeriesResponse
    ): PeaksData[] {
      const findMax = (data: TimeSeries[]) =>
        data.find((series) => series.resource === CARBON_NEUTRAL_CACHE_KEY)?.max

      const hourlyMax = findMax(hourlyData.series)
      const dailyMax = findMax(dailyData.series)

      const hourlyPeak: PeaksData = {
        peak: 'Hourly',
        date: hourlyMax?.x,
        percentage: hourlyMax?.y,
      }

      const dailyPeak: PeaksData = {
        peak: 'Daily',
        date: dailyMax?.x,
        percentage: dailyMax?.y,
      }

      return [hourlyPeak, dailyPeak]
    },
    async fetchResourceTypes(): Promise<void> {
      this.areTypesLoading = true
      try {
        this.availableResources =
          await this.$services.gridService.getResourceTypes(SUPPLY_RESOURCES)
      } catch (err) {
        console.error(
          'AnalyzeDashboard.fetchResourceTypes: fail to load resource types',
          err
        )
      } finally {
        this.areTypesLoading = false
      }
    },
  },
  computed: {
    emissionsIntensityDataSource(): ITimeSeriesDataSource {
      return newCarbonEmissionsTimeSeriesDataSource(
        this.$services,
        this.$rittaConfig?.analysis?.analyzeDashboard?.provider ?? ''
      )
    },
    dataSource(): ITimeSeriesDataSource {
      const ds = newSupplyDemandDataSource(
        this.$services,
        this.availableResources
      )
      if (this.hasEmissionsData) {
        return new AggregateDataSource(ds, this.emissionsIntensityDataSource)
      } else {
        return ds
      }
    },
  },
  created() {
    this.fetchResourceTypes()
    this.fetchSummaryData(ResourceGroup.DEMAND)
    this.fetchSummaryData(ResourceGroup.SUPPLY)
    this.fetchCarbonFreeSummaryData()
    if (this.hasEmissionsData) {
      this.fetchEmissionsSummaryData()
      updateEmissionsIntensityTooltip(this.$rittaConfig)
    }
  },
})
</script>
