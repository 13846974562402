import { MonitorConfig } from '@/config/types'
import {
  BUBBLEGUM,
  BUTTERCUP,
  CILANTRO,
  GREEN1,
  MALIBU,
  VELVET,
  WALLABY,
} from '@/constants/colors'
import { ChartDefinition, ChartType, SeriesConfig } from '@/types/charts'
import { Metric } from './metrics'

const TIMESERIES_COLORS = {
  [Metric.COND_POWER_REAL]: CILANTRO.hex,
  [Metric.SENSOR_SOLAR_POWER_REAL]: BUTTERCUP.hex,
  [Metric.SENSOR_SITE_POWER_REAL]: MALIBU.hex,
  [Metric.COND_CHARGE_LEVEL]: GREEN1.hex,
}

export type Options = {
  amiConsumptionConfig: SeriesConfig
  amiProductionConfig: SeriesConfig
  amiForecastConfig: SeriesConfig
  evChargerConfig: SeriesConfig
  batteryConfig: BatterySeriesConfig
  unmeteredProductionConfig: SeriesConfig
  unmeteredForecastConfig: SeriesConfig
  voltageConfig: SeriesConfig
}

export type BatterySeriesConfig = {
  [key in keyof typeof TIMESERIES_COLORS]: SeriesConfig
}

/**
 * The charts used for the detail area of the SiteLeftPanel.
 */
export const siteChartDefinitions = (
  config?: MonitorConfig
): ChartDefinition[] => [
  {
    id: 'site-p-and-c-chart',
    type: ChartType.Power,
    title: 'Production & Consumption',
    fileName: 'ProductionAndConsumption',
    height: 320,
    isAreaChart: true,
    persistVisibility: true,
  },
  {
    id: 'site-voltage-chart',
    type: ChartType.Voltage,
    title: 'Voltage',
    height: 320,
    visible: !!config?.site?.voltageChart?.enabled && 'requires-data',
    annotations: {
      voltageTolerance: { percentage: 5 },
    },
    persistVisibility: true,
  },
  {
    id: 'site-storage-chart',
    type: ChartType.Energy,
    title: 'Storage',
    visible: !!config?.site?.storageChart?.enabled && 'requires-data',
    persistVisibility: true,
  },
]

export const siteChartsDataProviderOptions: Options = {
  amiConsumptionConfig: {
    seriesName: 'Usage via AMI',
    seriesColor: WALLABY.hex,
  },
  amiProductionConfig: {
    seriesName: 'Generation via AMI',
    seriesColor: BUBBLEGUM.hex,
  },
  amiForecastConfig: {
    seriesName: 'Forecast AMI Usage',
    seriesColor: '#7F7655', // WALLABY but 50% brightness
    seriesLine: 'dashed',
  },
  unmeteredProductionConfig: {
    seriesName: 'PV Generation',
    seriesColor: BUBBLEGUM.hex,
  },
  unmeteredForecastConfig: {
    seriesName: 'Forecast PV Generation',
    seriesColor: BUBBLEGUM.hex,
    seriesLine: 'dashed',
  },
  evChargerConfig: {
    seriesName: 'EV Instant Power',
    seriesColor: VELVET.hex,
  },
  batteryConfig: {
    [Metric.SENSOR_SOLAR_POWER_REAL]: {
      seriesName: 'Solar Instant Power',
      seriesColor: TIMESERIES_COLORS[Metric.SENSOR_SOLAR_POWER_REAL],
    },
    [Metric.COND_POWER_REAL]: {
      seriesName: 'Battery Instant Power',
      seriesColor: TIMESERIES_COLORS[Metric.COND_POWER_REAL],
    },
    [Metric.SENSOR_SITE_POWER_REAL]: {
      seriesName: 'Site Instant Power ',
      seriesColor: TIMESERIES_COLORS[Metric.SENSOR_SITE_POWER_REAL],
    },
    [Metric.COND_CHARGE_LEVEL]: {
      seriesName: 'Battery Energy Remaining',
      seriesColor: TIMESERIES_COLORS[Metric.COND_CHARGE_LEVEL],
    },
  },
  voltageConfig: {
    seriesName: 'Voltage',
    seriesColor: GREEN1.hex,
  },
}
