import 'reflect-metadata/lite'
import type { Validator } from './validators'

export function Required(target: any, fieldName: string) {
  Reflect.defineMetadata(Required.name, true, target, fieldName)
}

export function RequiredWhenNotUndefined(target: any, fieldName: string) {
  Reflect.defineMetadata(RequiredWhenNotUndefined.name, true, target, fieldName)
}

export function ISODate(target: any, fieldName: string) {
  Reflect.defineMetadata(ISODate.name, true, target, fieldName)
}

export function ISOTime(target: any, fieldName: string) {
  Reflect.defineMetadata(ISOTime.name, true, target, fieldName)
}

export function QuarterHour(target: any, fieldName: string) {
  Reflect.defineMetadata(QuarterHour.name, true, target, fieldName)
}

export function Integer(target: any, fieldName: string) {
  Reflect.defineMetadata(Integer.name, true, target, fieldName)
}

export function Float(target: any, fieldName: string) {
  Reflect.defineMetadata(Float.name, true, target, fieldName)
}

export function PositiveFloat(target: any, fieldName: string) {
  Reflect.defineMetadata(PositiveFloat.name, true, target, fieldName)
}

export function FormPercent(target: any, fieldName: string) {
  Reflect.defineMetadata(FormPercent.name, true, target, fieldName)
}

export type CustomOptions = { validator: Validator }

export function Custom(opt: CustomOptions) {
  return function (target: any, propertyKey: string | symbol) {
    const existingValidators =
      Reflect.getMetadata(Custom.name, target, propertyKey) || []

    existingValidators.unshift(opt)

    Reflect.defineMetadata(Custom.name, existingValidators, target, propertyKey)
  }
}
