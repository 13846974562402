import { PlainMessage } from '@bufbuild/protobuf'
import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'
import { DateTime } from 'luxon'

import { Metric } from '@/constants/metrics'
import { Forecast2 } from 'rfs/frontend/proto/forecast_connect'
import {
  ForecastRequest,
  ForecastSliceType,
  PeakLoadAnalyticsRequest,
  SystemPeakDemand,
  SystemPeakDemandResponse,
} from 'rfs/frontend/proto/forecast_pb'
import { Timestamp } from '@/services/timestamp_pb'
import {
  TimeSeriesRequest,
  TimeSeriesResponse,
} from 'rfs/frontend/proto/tsdb_pb'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'
import { TimeSeriesFetch } from './charts.service'

// Use the same numeric values as `CalendarPeriod` so we don't have to write conversion functions
export enum PeakPeriod {
  ONE_CP = CalendarPeriod.YEAR,
  TWELVE_CP = CalendarPeriod.MONTH,
}

export type SystemPeakDemandData = PlainMessage<SystemPeakDemand> & {
  peakHoursForecast: Timestamp[]
  peakHoursObserved: Timestamp[]
}

export class ForecastService {
  private forecastClient: PromiseClient<typeof Forecast2>

  constructor(transport: Transport) {
    this.forecastClient = createPromiseClient(Forecast2, transport)
  }

  public getSystemPeakDemand(
    referenceDate: DateTime,
    period: PeakPeriod,
    productConfig: Record<string, string>
  ): Promise<SystemPeakDemandResponse> {
    const { year, month, day } = referenceDate

    return this.forecastClient.getSystemPeakDemand({
      referenceDate: { year, month, day },
      referencePeriod: period as unknown as CalendarPeriod,
      products: productConfig,
    })
  }

  public async getForecastQuality(
    model: string,
    fetch: TimeSeriesFetch
  ): Promise<TimeSeriesResponse> {
    const request = new ForecastRequest({
      product: model,
      timeSeriesReq: new TimeSeriesRequest({
        resource: model,
        start: Timestamp.fromDateTime(fetch.interval.start),
        end: Timestamp.fromDateTime(fetch.interval.end),
        metrics: fetch.metrics,
        resolution: fetch.resolution,
      }),
    })
    return this.forecastClient.getQualityTimeSeries(request)
  }

  /**
   * Fetch forecast data from megaservice for a given resource and forecast product.
   */
  public async getForecastData(
    resource: string,
    forecastProduct: string,
    fetch: TimeSeriesFetch
  ): Promise<TimeSeriesResponse> {
    const timeSeriesReq = new TimeSeriesRequest({
      resource,
      start: Timestamp.fromDateTime(fetch.interval.start),
      end: Timestamp.fromDateTime(fetch.interval.end),
      metrics: [{ metric: Metric.FORECAST_COND_POWER_REAL, unit: 'W' }],
    })
    const req = new ForecastRequest({
      timeSeriesReq,
      forecastSliceType: ForecastSliceType.BEST_AVAILABLE_SLICE,
      product: forecastProduct,
    })
    return this.forecastClient.getResourceForecastData(req)
  }

  public async getPeakLoadAnalytics(
    balancingArea: string,
    numberOfYears: number,
    numberOfHighestPeakDays: number,
    period: PeakPeriod,
    forecastProductId?: string,
    referenceDate = DateTime.local()
  ) {
    const { year, month, day } = referenceDate
    const request = new PeakLoadAnalyticsRequest({
      balancingArea,
      referenceDate: { year, month, day },
      referencePeriod: period as unknown as CalendarPeriod,
      numberOfYears,
      numberOfHighestPeakDays,
      forecastProductId,
    })
    return this.forecastClient.getPeakLoadAnalytics(request)
  }
}

export enum PeakDemandLabel {
  PEAK = 'PEAK',
  FORECAST = 'FORECAST',
  OBSERVED = 'OBSERVED',
}

export function populateLabels(data: SystemPeakDemand[]): SystemPeakDemand[] {
  const today = DateTime.local().startOf('day')

  return data.map((peakDemand, i) => {
    // The `peakDemand.date` is null on the first of the month/year since there's no complete
    // day in the month/year yet.
    if (i === 0) {
      peakDemand.label = PeakDemandLabel.PEAK
    } else if (DateTime.fromGDate(peakDemand.date) < today) {
      peakDemand.label = PeakDemandLabel.OBSERVED
    } else {
      peakDemand.label = PeakDemandLabel.FORECAST
    }
    return peakDemand
  })
}
