/**
 * A simple polyfill for the `Promise.withResolvers` method that is brand new
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/withResolvers
 */
export function withResolvers<T>(): PromiseWithResolvers<T> {
  if (Promise.withResolvers) {
    return Promise.withResolvers<T>()
  }
  const result = {} as PromiseWithResolvers<T>
  result.promise = new Promise((resolve, reject) => {
    result.resolve = resolve
    result.reject = reject
  })
  return result
}

/**
 * Create an AsyncIterable that returns the result of each promise as it is
 * resolved or rejected. This is similar to `Promise.race` but returns a new
 * Promise each time one is settled.
 */
export async function* raceAll<T>(input: Promise<T>[]) {
  const proxies = [] as PromiseWithResolvers<T>[]
  let next = 0

  for (const p of input) {
    // Create a proxy promise for each input promise
    proxies.push(withResolvers<T>())
    // When the input promise is settled, resolve or reject the next proxy promise
    p.then(
      (value) => proxies[next++].resolve(value),
      (reason) => proxies[next++].reject(reason)
    )
  }

  for (const p of proxies) {
    yield p.promise
  }
}
