import { DateTime } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Timestamp } from '@/services/timestamp_pb'
import { formatDtWithZoneShort } from '@/utils/time'
import { Alert, Severity, State } from 'rfs/frontend/proto/alert_pb'

const ALERT_ID_PREFIX = 'alert/instance/'

export const AlertHelper = {
  formatState(state: State): string {
    switch (state) {
      case State.INACTIVE:
        return 'Inactive'
      case State.ACTIVE:
        return 'Active'
      case State.SILENCED:
        return 'Silenced'
      default:
        throw new Error('AlertHelper.formatState: unexpected state')
    }
  },

  formatSeverity(severity: Severity): string {
    switch (severity) {
      case Severity.LOW:
        return 'Low'
      case Severity.MEDIUM:
        return 'Medium'
      case Severity.HIGH:
        return 'High'
      default:
        throw new Error('AlertHelper.formatSeverity: unexpected severity')
    }
  },

  formatTimestamp(ts?: Timestamp): string {
    return ts
      ? formatDtWithZoneShort(DateTime.fromMillis(ts.toMillis()))
      : TEXT_NO_VALUE
  },

  /** * Extracts the `id` part of the fully-qualified Alert ID. */
  getUnqualifiedId(alert: Alert): string {
    return alert.id.replace(ALERT_ID_PREFIX, '')
  },

  /** * Adds back the prefix of an Alert ID. */
  regenerateUnqualifiedId(unqualifiedId?: string): undefined | string {
    return unqualifiedId ? `${ALERT_ID_PREFIX}${unqualifiedId}` : undefined
  },
}
