<template>
  <div class="c-ForecastHistoricalContextChart">
    <centered-spinner v-if="isLoading" />

    <div style="height: 400px; padding-top: 1rem">
      <div style="position: absolute; top: -32px; right: 112px" hidden>
        <chart-menu :menu-options="menu.options" v-on="menu.eventHandlers" />
      </div>
      <bar-chart :data="newChartData" :options="newChartOptions" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { ChartData, ChartOptions, LegendItem, TooltipItem } from 'chart.js'
import { DateTime } from 'luxon'
import { Bar as BarChart } from 'vue-chartjs'

import {
  GREY4,
  MALIBU,
  LAGOON,
  YELLOW4,
  PACIFIC,
  ORANGE2,
  GREEN2,
  PINK1,
} from '@/constants/colors'
import { Shape } from '@/constants/shape'
import {
  newHistoricalContextChartDataProvider,
  Options,
} from '@/model/forecast'
import { Format, MEGA_OPTIONS } from '@/utils/format'
import { titleCase } from '@/utils/formatText'
import { hasValueFirstDataPointOnly } from '@/utils/charts'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import { ChartMenuOption } from '@/components/common/ChartMenu'
import ChartMenu from '@/components/common/ChartMenu.vue'
import {
  LineSeriesWithConfig,
  RangeBarsSeriesWithConfig,
  PointAnnotationsSeriesWithConfig,
} from '@/types/charts'
import { PeakPeriod } from '@/services/forecast.service'

enum EventName {
  DOWNLOAD_CSV = 'download-csv',
}

type EventHandler = () => void | Promise<void>

type ChartMenuPropsAndEventHandlers = {
  options: ChartMenuOption<EventName>[]
  eventHandlers: Record<EventName, EventHandler>
}

export default defineComponent({
  name: 'ForecastHistoricalContextChart',
  props: {
    balancingArea: {
      type: String,
      required: true,
    },
    year: {
      type: Number, // 2022
      required: false,
      default: DateTime.local().year,
    },
    monthOfYear: {
      type: Number, // 1-12
      required: false,
      default: DateTime.local().month,
    },
    dayOfMonth: {
      type: Number, // 1-28,29,30,31
      required: false,
      default: DateTime.local().day,
    },
    numberOfYears: {
      type: Number,
      required: false,
      default: 5,
      // NOTE(rafael): no limit on the RFS side.
      // Visually, 5 may work well. Update the validator as necessary.
      validator: (v: number) => v > 0 && v <= 5,
    },
    numberOfHighestPeakDaysPerMonthPerYear: {
      type: Number,
      required: false,
      default: 5,
      // NOTE(rafael): no limit on the RFS side.
      // Product spec is 5 right now. Update the validator as necessary.
      validator: (v: number) => v > 0 && v <= 5,
    },
    referencePeriod: {
      type: Number,
      required: false,
      default: PeakPeriod.TWELVE_CP,
    },
    productId: {
      type: String,
      required: false,
    },
  },
  components: {
    CenteredSpinner,
    BarChart,
    ChartMenu,
  },
  data() {
    const {
      balancingArea,
      year,
      monthOfYear,
      dayOfMonth,
      numberOfYears,
      numberOfHighestPeakDaysPerMonthPerYear,
    } = this

    const dataProviderOptions: Options = {
      balancingArea,
      year,
      monthOfYear,
      dayOfMonth,
      numberOfYears,
      numberOfHighestPeakDaysPerMonthPerYear,
      referencePeriod: this.referencePeriod,
      productId: this.productId,
      series: {
        actual: {
          name: 'Actual',
          color: MALIBU.hex,
        },
        forecast: {
          name: 'Forecast',
          color: LAGOON.hex,
          dash: 4,
        },
        minMax: {
          name: 'Historical Load',
          color: GREY4.hex,
        },
        highestPeaks: {
          colors: () => [
            YELLOW4.hex,
            PACIFIC.hex,
            ORANGE2.hex,
            GREEN2.hex,
            PINK1.hex,
          ],
          high: {
            label: 'High',
            shape: Shape.CIRCLE_OUTLINE,
          },
          peak: {
            label: 'Peak',
            shape: Shape.SQUARE,
          },
        },
      },
    }
    const referenceDate = DateTime.local(
      dataProviderOptions.year,
      dataProviderOptions.monthOfYear
    )

    return {
      dataProvider: newHistoricalContextChartDataProvider(
        this.$services,
        dataProviderOptions
      ),
      currentMonthString: titleCase(referenceDate.monthLong),
      isLoading: false,
      didLoadingFail: false,
      yAxisTitle: 'Daily Peak',
      legendYearGroup: 'Year',
      legendLoadGroup: 'Load This Month',
      // 12CP: Show the day of the month and the weekday this year
      // 1CP: Show the day of the year and the month day this year
      tooltipXAxisFormatter: (v: number) =>
        this.referencePeriod === PeakPeriod.TWELVE_CP
          ? `Day ${v} (${referenceDate.set({ day: v }).weekdayLong})`
          : `Day ${v} (${DateTime.fromObject({ ordinal: v }).monthLong} ${
              DateTime.fromObject({ ordinal: v }).day
            })`,
      lineSeries: [] as LineSeriesWithConfig,
      rangeBarsSeries: [] as RangeBarsSeriesWithConfig,
      pointAnnotationsSeries: [] as PointAnnotationsSeriesWithConfig,
      minLoadValue: undefined as number | undefined,
      maxLoadValue: undefined as number | undefined,
    }
  },
  computed: {
    newChartData(): ChartData<any, any> {
      const numDays = this.rangeBarsSeries[0]?.data.length ?? 30

      return {
        labels: Array.from(Array(numDays).keys()).map((x) => x + 1),
        datasets: [
          ...this.pointAnnotationsSeries.map((series) => {
            return {
              type: 'scatter',
              label: series.name,
              data: [
                // NOTE: This empty data point prevents the chart legend from
                // being color-filled. Without it, if the first data point
                // includes "shape: 'square'", the legend would be
                // color-filled because "square" triggers the
                // "element.point.backgroundColor" option.
                { x: 0, y: null },
                ...series.data.map((dp) => ({ x: dp.x, ...dp.y?.[0] })),
              ],
              parsing: { yAxisKey: 'value' },
              borderColor: series.color,
              borderWidth: 2,
            }
          }),
          ...this.lineSeries.map((series) => {
            return {
              type: 'line',
              label: series.name,
              data: series.data,
              backgroundColor: 'transparent',
              borderColor: series.color,
              borderDash: series.dash ? [4, 4] : undefined,
              borderWidth: 2,
              pointRadius: hasValueFirstDataPointOnly(series.data) ? 4 : 0,
            }
          }),
          ...this.rangeBarsSeries.map((series) => {
            return {
              label: series.name,
              data: series.data.map(({ y }) => [y?.min ?? NaN, y?.max ?? NaN]),
              backgroundColor: series.color,
            }
          }),
        ],
      }
    },
    newChartOptions(): ChartOptions<'bar'> {
      return {
        elements: {
          point: {
            // TODO: replace any with ScriptableContext<'bar'> & {raw: ???}
            pointStyle(ctx: any) {
              return ctx.raw.shape === 'square' ? 'rect' : 'circle'
            },
            backgroundColor(ctx: any) {
              const seriesColor = ctx.element.options?.borderColor
              return ctx.raw.shape === 'square' ? seriesColor : 'white'
            },
            radius(ctx: any) {
              return ctx.raw.shape === 'square' ? 5 : 4
            },
          },
        },
        plugins: {
          // TODO: Implement a custom HTML legend plugin (like VerticalChartLegend.vue)
          legend: {
            position: 'right',
            labels: { sort: this.legendSort },
          },
          tooltip: {
            enabled: true,
            position: 'fixedPosition',
            usePointStyle: true,
            itemSort: this.tooltipSort,
            callbacks: {
              title: (items) =>
                this.tooltipXAxisFormatter(items[0].dataIndex + 1),
              label: (item) => this.tooltipYAxisFormatter(item),
            },
          },
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              offset: false,
            },
            ...this.chartTicks,
          },
          y: {
            min: this.yAxisMin,
            ticks: {
              callback: (v) => Format.fmtWatts(v as number, MEGA_OPTIONS),
            },
            title: { display: true, text: this.yAxisTitle },
          },
        },
      }
    },
    chartTicks(): {} {
      return this.referencePeriod === PeakPeriod.TWELVE_CP
        ? {}
        : {
            ticks: {
              // On initial chart load the date is 0 (invalid), don't render a new label
              // If the value date is the first day of the month, show the month name
              // Otherwise, don't render a new label
              callback: (v: number) => {
                if (v === 0) return
                const dt = DateTime.fromObject({
                  ordinal: v,
                  year: DateTime.now().year,
                })
                if (dt.day === 1) {
                  return dt.monthLong
                }
              },
            },
          }
    },
    yAxisMin(): number | undefined {
      if (this.minLoadValue === undefined) return undefined

      // Calculate the order of magnitude of the number to determine the amount of zeros.
      const orderOfMagnitude = Math.floor(Math.log10(this.minLoadValue))
      const divisionFactor = Math.pow(10, orderOfMagnitude)

      // Subtract a bit from the number to ensure it falls into the lower "ten" when rounding.
      const adjustedNumber = this.minLoadValue - divisionFactor * 0.1

      // Calculate the new order of magnitude after subtraction.
      const newOrderOfMagnitude = Math.floor(Math.log10(adjustedNumber))

      // Rounds down to the next "ten" of zeros.
      const rounded =
        Math.floor(adjustedNumber / Math.pow(10, newOrderOfMagnitude)) *
        Math.pow(10, newOrderOfMagnitude)

      return rounded
    },
    menu(): ChartMenuPropsAndEventHandlers {
      return {
        options: [{ text: 'Download CSV', event: EventName.DOWNLOAD_CSV }],
        eventHandlers: { [EventName.DOWNLOAD_CSV]: this.downloadCSV },
      }
    },
    /* this computed property exists only to be watched */
    computedProps(): [string, number, number] {
      return [
        this.balancingArea,
        this.numberOfYears,
        this.numberOfHighestPeakDaysPerMonthPerYear,
      ]
    },
  },
  watch: {
    computedProps(): void {
      this.updateDataProvider()
      this.fetchData()
    },
  },
  created(): void {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.didLoadingFail = false
      this.resetChartData()

      try {
        await this.dataProvider.getTimeSeries()

        const {
          loadActualSeries,
          loadForecastSeries,
          minMaxSeries,
          highestPeaksSeries,
          minLoadValue,
          maxLoadValue,
        } = this.dataProvider

        // TODO(Isaac): Freeze the data so that it can't be mutated
        // Currently it is messing with vue3 proxying for data access
        // Object.freeze(minMaxSeries)
        // Object.freeze(highestPeaksSeries)

        this.lineSeries = [...loadActualSeries, ...loadForecastSeries]
        this.rangeBarsSeries = minMaxSeries
        this.pointAnnotationsSeries = highestPeaksSeries
        this.minLoadValue = minLoadValue
        this.maxLoadValue = maxLoadValue
      } catch (err) {
        this.didLoadingFail = true
        console.error('ForecastHistoricalContextChart.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    resetChartData(): void {
      this.lineSeries = []
      this.rangeBarsSeries = []
      this.pointAnnotationsSeries = []
      this.minLoadValue = undefined
      this.maxLoadValue = undefined
    },
    updateDataProvider(): void {
      this.dataProvider = this.dataProvider.clone({
        balancingArea: this.balancingArea,
        year: this.year,
        monthOfYear: this.monthOfYear,
        numberOfYears: this.numberOfYears,
        numberOfHighestPeakDaysPerMonthPerYear:
          this.numberOfHighestPeakDaysPerMonthPerYear,
      })
    },
    legendSort(a: LegendItem, b: LegendItem): number {
      return this.dataProvider.compareSeriesOrder(a.text, b.text)
    },
    tooltipSort(a: TooltipItem<'bar'>, b: TooltipItem<'bar'>): number {
      // Sort the point annotations after Actual, Forecast, Historical
      const aName = a.dataset.label ?? ''
      const bName = b.dataset.label ?? ''
      return this.dataProvider.compareSeriesOrder(aName, bName)
    },
    tooltipYAxisFormatter(item: TooltipItem<'bar'>): string | string[] {
      const label = item.dataset.label
      const value = this.dataProvider.formatDataValue(item.raw)

      if (Array.isArray(value)) {
        return [`Min: ${value[0]}`, `Max: ${value[1]}`]
      } else {
        return `${label}: ${value}`
      }
    },
    downloadCSV() {
      console.warn('TODO: this.dataProvider.downloadCSV()')
    },
  },
})
</script>

<style lang="scss">
.c-ForecastHistoricalContextChart {
  position: relative;
  min-height: 415px;
}
</style>
