import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import Notifications from '@/components/monitor/Notifications.vue'
import NotificationDetails from '@/components/monitor/NotificationDetails.vue'
import * as RouteNames from './routeNames'

export const TAB = TabId.MONITOR

export const TITLE_NOTIFICATIONS = 'Notifications'

export function notificationRoutes(
  config: Readonly<RittaConfig>
): RouteRecordRaw[] {
  if (!config.monitor?.notifications?.enabled) {
    return []
  }

  const meta = { tab: TAB }

  return [
    {
      path: '/notifications',
      name: RouteNames.NOTIFICATIONS,
      meta: {
        ...meta,
        pageTitle: TITLE_NOTIFICATIONS,
      },
      component: Notifications,
    },
    {
      path: '/notifications/:notificationId',
      name: RouteNames.NOTIFICATION_DETAILS,
      meta: {
        ...meta,
        pageTitle: (params) => `Notification: ${params.notificationId}`,
      },
      component: NotificationDetails,
      props: true,
    },
  ]
}
