// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file frontend/proto/gcs.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { EnumObjectsRequest, EnumObjectsResponse, GcsFileRequest, GcsFileResponse } from "./gcs_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.GcsService
 */
export const GcsService = {
  typeName: "ritta.frontend.proto.GcsService",
  methods: {
    /**
     * @generated from rpc ritta.frontend.proto.GcsService.GetFile
     */
    getFile: {
      name: "GetFile",
      I: GcsFileRequest,
      O: GcsFileResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service ritta.frontend.proto.FileStorage
 */
export const FileStorage = {
  typeName: "ritta.frontend.proto.FileStorage",
  methods: {
    /**
     * Return a list of objects for a given asset type.
     *
     * @generated from rpc ritta.frontend.proto.FileStorage.EnumObjects
     */
    enumObjects: {
      name: "EnumObjects",
      I: EnumObjectsRequest,
      O: EnumObjectsResponse,
      kind: MethodKind.Unary,
    },
  }
};

