<template>
  <div class="c-CenteredSpinner">
    <v-progress-circular :size="size" :color="color" indeterminate />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * Renders a spinner horizontally and vertically centered.
 *
 * It's required that its parent component contains `position: relative` in
 * its CSS.
 *
 * It's used to demonstrate a "loading" action for the user.
 *
 * It uses Vuetify VProgressCircular
 * More: https://vuetifyjs.com/en/components/progress-circular/
 *
 * Optional props:
 * - `size` controls the size of the spinner.
 * - `color` controls the color of the spinner.
 *
 * @example
 * ```
 * <centered-spinner :size="50" :color="'primary'"/>
 * ```
 */
export default defineComponent({
  name: 'CenteredSpinner',
  props: {
    size: {
      type: Number,
      required: false,
      default: 50,
    },
    color: {
      type: String,
      required: false,
      default: 'primary',
    },
  },
})
</script>

<style lang="scss">
.c-CenteredSpinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
