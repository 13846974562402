export enum ResourceType {
  BATTERY_DISTRIBUTED = 'battery/distributed',
  BATTERY_STATION = 'battery/station',
  BIOMASS = 'biomass',
  BREAKER = 'breaker',
  CALLOUT = 'callout',
  CAPACITOR = 'capacitor',
  CHARGER = 'charger',
  CONDUCTOR = 'conductor',
  FEEDER = 'feeder',
  FUSE = 'fuse',
  GENERATION = 'generation',
  HYDRO = 'hydro',
  METER_ELECTRICAL = 'meter/electrical',
  METHANE = 'methane',
  MICROGRID = 'microgrid',
  POOL_DEVICE = 'pool/device',
  PROVIDER = 'provider',
  RECLOSER = 'recloser',
  REGULATOR = 'regulator',
  SECTIONALIZER = 'sectionalizer',
  SENSOR_CONDUCTOR = 'sensor/conductor',
  SENSOR_ELECTRICAL = 'sensor/electrical',
  SITE_CUSTOMER = 'site/customer',
  SITE_UTILITY = 'site/utility',
  SOLAR_DISTRIBUTED = 'solar/distributed',
  SOLAR_FARM = 'solar/farm',
  SUBSCRIPTION_ELECTRICAL = 'subscription/electrical',
  SUBSTATION = 'substation',
  SWITCH = 'switch',
  TRANSFORMER = 'transformer',
  VEHICLE = 'vehicle',
  WIND = 'wind',
  ZONE_VOLTAGE = 'zone/voltage',
}

/**
 * Labels for showing a resource type in the UI
 */
export const ResourceTypeLabels: Record<ResourceType, string> = {
  [ResourceType.BATTERY_DISTRIBUTED]: 'Distributed Batteries',
  [ResourceType.BATTERY_STATION]: 'Utility Batteries',
  [ResourceType.BIOMASS]: 'Biomass',
  [ResourceType.BREAKER]: 'Breakers',
  [ResourceType.CALLOUT]: 'Device Leaders',
  [ResourceType.CAPACITOR]: 'Capacitors',
  [ResourceType.CHARGER]: 'EV Chargers',
  [ResourceType.CONDUCTOR]: 'Conductors',
  [ResourceType.FEEDER]: 'Feeders',
  [ResourceType.FUSE]: 'Fuses',
  [ResourceType.GENERATION]: 'Generation',
  [ResourceType.HYDRO]: 'Hydro',
  [ResourceType.METER_ELECTRICAL]: 'Meters',
  [ResourceType.METHANE]: 'Methane',
  [ResourceType.MICROGRID]: 'Microgrids',
  [ResourceType.POOL_DEVICE]: 'Device',
  [ResourceType.PROVIDER]: 'Providers',
  [ResourceType.RECLOSER]: 'Reclosers',
  [ResourceType.REGULATOR]: 'Regulators',
  [ResourceType.SECTIONALIZER]: 'Sectionalizers',
  [ResourceType.SENSOR_CONDUCTOR]: 'Sensors',
  [ResourceType.SENSOR_ELECTRICAL]: 'Sensors',
  [ResourceType.SITE_CUSTOMER]: 'Customer Sites',
  [ResourceType.SITE_UTILITY]: 'Utility Sites',
  [ResourceType.SOLAR_DISTRIBUTED]: 'Distributed PV',
  [ResourceType.SOLAR_FARM]: 'Utility PV',
  [ResourceType.SUBSCRIPTION_ELECTRICAL]: 'Subscriptions',
  [ResourceType.SUBSTATION]: 'Substations',
  [ResourceType.SWITCH]: 'Switches',
  [ResourceType.TRANSFORMER]: 'Transformers',
  [ResourceType.VEHICLE]: 'Vehicles',
  [ResourceType.WIND]: 'Wind',
  [ResourceType.ZONE_VOLTAGE]: 'Voltage Zones',
}
