// The color to series type can be found here:
// https://docs.google.com/spreadsheets/d/1AcmYso8xFSaXHILIwuu9y4QDQBOiymWzQLB10FzQLFg/edit?usp=sharing

export type Hex = `#${string}`
export type RGB =
  | `rgba(${number}, ${number}, ${number}, ${number})`
  | `rgb(${number}, ${number}, ${number})`
type HSL = `hsla(${number}, ${number}%, ${number}%, ${number})`
/** A color in the format of `[r, g, b, a?]` */
export type RGBRaw = [number, number, number] | [number, number, number, number]
export type RgbaColor = RGBRaw

export type Color = {
  hex: Hex
  rgb?: RGB
  rgba?: RgbaColor
  hsl?: HSL
}

export const PRIMARY: Color = {
  hex: '#0944bd',
}

export const WHITE: Color = {
  hex: '#ffffff',
  rgb: 'rgba(255, 255, 255, 1)',
  hsl: 'hsla(360, 100%, 100%, 1)',
}

export const BUTTERCUP: Color = {
  hex: '#f1bf15',
  rgb: 'rgba(241, 191, 21, 1)',
  rgba: [241, 191, 21, 255],
  hsl: 'hsla(46, 89%, 51%, 1)',
}

export const COPPER: Color = {
  hex: '#944616',
  rgb: 'rgba(148, 70, 22, 1)',
  rgba: [148, 70, 22, 255],
}

export const VELVET: Color = {
  hex: '#875692',
  rgb: 'rgba(135, 86, 146, 1)',
  rgba: [135, 86, 146, 255],
  hsl: 'hsla(289, 26%, 45%, 1)',
}

export const JAFFA: Color = {
  hex: '#ee7733',
  rgb: 'rgba(238, 119, 51, 1)',
  rgba: [238, 119, 51, 255],
  hsl: 'hsla(22, 85%, 57%, 1)',
}

export const MALIBU: Color = {
  hex: '#56b4e9',
  rgb: 'rgba(86, 180, 233, 1)',
  hsl: 'hsla(202, 77%, 63%, 1)',
}

export const CARDINAL: Color = {
  hex: '#d51c3c',
  rgb: 'rgba(213, 28, 60, 1)',
  hsl: 'hsla(350, 77%, 47%, 1)',
}

export const WALLABY: Color = {
  hex: '#c2b280',
  rgb: 'rgba(194, 178, 128, 1)',
  hsl: 'hsla(45, 35%, 63%, 1)',
}

export const ELEPHANT: Color = {
  hex: '#808080',
  rgb: 'rgba(128, 128, 128, 1)',
  hsl: 'hsla(0, 0%, 50%, 1)',
}

export const PACIFIC: Color = {
  hex: '#0088dd',
  rgb: 'rgba(0, 136, 221, 1)',
  rgba: [0, 136, 221, 255],
  hsl: 'hsla(203, 100%, 43%, 1)',
}

export const BUBBLEGUM: Color = {
  hex: '#e68fac',
  rgb: 'rgba(230, 143, 172, 1)',
  hsl: 'hsla(340, 64%, 73%, 1)',
}

export const LAGOON: Color = {
  hex: '#006d88',
  rgb: 'rgba(0, 109, 136, 1)',
  rgba: [0, 109, 136, 255],
  hsl: 'hsla(192, 100%, 27%, 1)',
}

export const DOLPHIN: Color = {
  hex: '#848fa2',
  rgb: 'rgba(132, 143, 162, 1)',
  rgba: [132, 143, 162, 255],
  hsl: 'hsla(218, 14%, 58%, 1)',
}

export const CILANTRO: Color = {
  hex: '#007d34',
  rgb: 'rgba(0, 125, 52, 1)',
  hsl: 'hsla(145, 100%, 25%, 1)',
}

export const GREY1: Color = {
  hex: '#B0B0B0',
  rgb: 'rgba(176, 176, 176, 1)',
  hsl: 'hsla(0, 0%, 69%, 1)',
}

export const GREY2: Color = {
  hex: '#c2c2c2',
}

export const GREY3: Color = {
  hex: '#d3d3d3',
}

export const GREY4: Color = {
  hex: '#ebebeb',
}

export const GREY5: Color = {
  hex: '#4B4B4B',
  rgb: 'rgb(75, 75, 75)',
}

export const GREY6: Color = {
  hex: '#f5f5f5',
  rgb: 'rgb(245, 245, 245)',
}

export const GREY7: Color = {
  hex: '#eeeeee',
}

export const GREY8: Color = {
  hex: '#838fa1',
}

export const GREY9: Color = {
  hex: '#bbbbbb',
  rgb: 'rgb(187, 187, 187)',
}

export const GREY10: Color = {
  hex: '#666666',
}

export const GREY11: Color = {
  hex: '#f0f1f3',
}

export const GREY12: Color = {
  hex: '#e1e3e7',
}

export const GREY13: Color = {
  hex: '#D4D4D4',
}

export const GREY14: Color = {
  hex: '#a2a2a2',
}

export const GREY15: Color = {
  hex: '#212121',
}

export const APPLE_50: Color = {
  hex: '#E6F4E2',
}

export const APPLE_600: Color = {
  hex: '#56A23F',
}

export const APPLE_800: Color = {
  hex: '#376728',
}

export const GRAY_COOL_700: Color = {
  hex: '#3F4550',
}

export const GRAY_COOL_50 = GREY11

export const GRAY_COOL_100 = GREY12

export const GRAY_COOL_200: Color = {
  hex: '#C3C7CF',
  rgb: 'rgb(195, 199, 207)',
  rgba: [195, 199, 207, 255],
}

export const GRAY_COOL_300: Color = {
  hex: '#A5ABB6',
  rgb: 'rgb(165, 171, 182)',
  rgba: [165, 171, 182, 255],
}

export const NEUTRAL_50 = GREY6

export const NEUTRAL_100: Color = {
  hex: '#E8E8E8',
}

export const NEUTRAL_200 = GREY13

export const NEUTRAL_600 = GREY10

export const NEUTRAL_700 = GREY5

export const GREEN1: Color = {
  hex: '#4caf50',
  rgb: 'rgba(76, 175, 80, 1)',
  hsl: 'hsla(122, 39%, 49%, 1)',
}

export const GREEN2: Color = {
  hex: '#016c88',
}

export const rgbaBlack: RgbaColor = [0, 0, 0, 255]

export const BLACK: Color = {
  hex: '#000000',
  rgb: 'rgba(0, 0, 0, 1)',
  rgba: rgbaBlack,
  hsl: 'hsla(0, 0%, 0%, 1)',
}

export const BLUE1: Color = {
  hex: '#364B9A',
  rgb: 'rgba(54, 75, 154, 1)',
  hsl: 'hsla(227, 48%, 41%, 1)',
}
export const BLUE2: Color = {
  hex: '#4A7BB7',
  rgb: 'rgba(74, 123, 183, 1)',
  hsl: 'hsla(213, 43%, 50%, 1)',
}
export const BLUE3: Color = {
  hex: '#6EA6CD',
  rgb: 'rgba(110, 166, 205, 1)',
  hsl: 'hsla(205, 49%, 62%, 1)',
}
export const BLUE4: Color = {
  hex: '#98CAE1',
  rgb: 'rgba(152, 202, 225, 1)',
  hsl: 'hsla(199, 55%, 74%, 1)',
}
export const BLUE5: Color = {
  hex: '#C2E4EF',
  rgb: 'rgba(194, 228, 239, 1)',
  hsl: 'hsla(195, 58%, 85%, 1)',
}
export const BLUE6: Color = {
  hex: '#E7ECF5',
}

export const YELLOW1: Color = {
  hex: '#EAECCC',
  rgb: 'rgba(234, 236, 204, 1)',
  hsl: 'hsla(64, 46%, 86%, 1)',
}
export const YELLOW2: Color = {
  hex: '#FEDA8B',
  rgb: 'rgba(254, 218, 139, 1)',
  hsl: 'hsla(41, 98%, 77%, 1)',
}
export const YELLOW3: Color = {
  hex: '#FDB366',
  rgb: 'rgba(253, 179, 102, 1)',
  hsl: 'hsla(31, 97%, 70%, 1)',
}

export const YELLOW4: Color = {
  hex: '#f1bf14',
}

export const YELLOW5: Color = {
  hex: '#f2c034',
}

export const ORANGE_CAMUS_ENERGY: Color = {
  hex: '#DF6D27',
}

export const ORANGE_500 = ORANGE_CAMUS_ENERGY

export const ORANGE1: Color = {
  hex: '#F67E4B',
  rgb: 'rgba(246, 126, 75, 1)',
  hsl: 'hsla(18, 90%, 62%, 1)',
}

export const ORANGE2: Color = {
  hex: '#ee7633',
}

export const RED1: Color = {
  hex: '#DD3D2D',
  rgb: 'rgba(221, 61, 45, 1)',
  hsl: 'hsla(5, 72%, 52%, 1)',
}

export const RED2: Color = {
  hex: '#A50026',
  rgb: 'rgba(165, 0, 38, 1)',
  hsl: 'hsla(346, 100%, 32%, 1)',
}

export const BLUE1_DARK: Color = {
  hex: '#1c274f',
  rgb: 'rgb(28, 39, 79)',
  hsl: 'hsla(227, 48%, 21%, 1)',
}

export const BLUE2_DARK: Color = {
  hex: '#2c496d',
  rgb: 'rgb(44, 73, 109)',
  hsl: 'hsla(213, 43%, 30%, 1)',
}

export const BLUE3_DARK: Color = {
  hex: '#3774a0',
  rgb: 'rgb(55, 116, 160)',
  hsl: 'hsla(205, 49%, 42%, 1)',
}

export const BLUE4_DARK: Color = {
  hex: '#49a1ca',
  rgb: 'rgb(73, 161, 202)',
  hsl: 'hsla(199, 55%, 54%, 1)',
}

export const BLUE5_DARK: Color = {
  hex: '#72c0da',
  rgb: 'rgb(114, 192, 218)',
  hsl: 'hsla(195, 58%, 65%, 1)',
}

export const YELLOW1_DARK: Color = {
  hex: '#cbd080',
  rgb: 'rgb(203, 208, 128)',
  hsl: 'hsla(64, 46%, 66%, 1)',
}

export const YELLOW2_DARK: Color = {
  hex: '#fdb926',
  rgb: 'rgb(253, 183, 38)',
  hsl: 'hsla(41, 98%, 57%, 1)',
}

export const YELLOW3_DARK: Color = {
  hex: '#fb8404',
  rgb: 'rgb(251, 132, 4)',
  hsl: 'hsla(31, 97%, 50%, 1)',
}

export const ORANGE1_DARK: Color = {
  hex: '#cb450b',
  rgb: 'rgb(203, 69, 11)',
  hsl: 'hsla(18, 90%, 42%, 1)',
}

export const RED1_DARK: Color = {
  hex: '#8c2117',
  rgb: 'rgb(140, 33, 23)',
  hsl: 'hsla(5, 72%, 32%, 1)',
}

export const RED2_DARK: Color = {
  hex: '#3d000e',
  rgb: 'rgb(61, 0, 14)',
  hsl: 'hsla(346, 100%, 12%, 1)',
}

export const PINK1: Color = {
  hex: '#e68fab',
}

export const PURPLE1: Color = {
  hex: '#865891',
}

export const BLUE_50 = BLUE6

export const BLUE_100: Color = {
  hex: '#cfd7e8',
}

export const BLUE_200: Color = {
  hex: '#9EB1D9',
}

export const BLUE_300: Color = {
  hex: '#6E8DCB',
}

export const BLUE_600: Color = {
  hex: '#0A3180',
}

export const rgbBlue: RgbaColor = [0, 0, 128]
export const rgbRed: RgbaColor = [204, 51, 17]
export const rgbaTransparent: RgbaColor = [0, 0, 0, 0]

export const VoltageMappingColors = {
  fill: {
    rgbRaw: [234, 236, 204, 200] as RGBRaw, // YELLOW1
    rgb: 'rgba(234, 236, 204, 1)', // YELLOW1
  },
  outline: [203, 208, 128, 255] as RGBRaw, // YELLOW1_DARK
}

export const BLUE_500: Color = { hex: '#0c3da0', rgba: [0x0c, 0x3d, 0xa0] }

export const JAM_50: Color = { hex: '#FABBD0' }
export const JAM_400: Color = { hex: '#bc0e48', rgba: [0xbc, 0x0e, 0x48] }
export const JAM_600: Color = { hex: '#840A32' }

export const OCHRE_50: Color = { hex: '#FAE5BB' }
export const OCHRE_500: Color = { hex: '#a06f0c', rgba: [0xa0, 0x6f, 0x0c] }
export const OCHRE_800: Color = { hex: '#4B3406' }

export const TEAL_50: Color = { hex: '#BBEFFA' }
export const TEAL_700: Color = { hex: '#085767' }

export const VIOLET_500: Color = { hex: '#6F0CA0' }

/**
 * This palette contains colors from an earlier set.
 * https://www.figma.com/file/T9TUhEAFAODHTwFCLVaPSd/Colors?type=design&node-id=0-1&mode=design&t=2ucLdvo5NrhdetN4-0
 */
export const ExtraPalette = {
  blue500: BLUE_500,
  gray100: { hex: '#e3e3e3', rgba: [0xe3, 0xe3, 0xe3] } as Color,
  jam400: JAM_400,
  ochre500: OCHRE_500,
}
