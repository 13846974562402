// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file frontend/proto/tsdb.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MultiTimeSeriesRequest, TimeSeriesRequest, TimeSeriesResponse } from "./tsdb_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.Charts
 */
export const Charts = {
  typeName: "ritta.frontend.proto.Charts",
  methods: {
    /**
     * Query for a time series for the given resource. A series will be returned for each metric specified in the request.
     *
     * @generated from rpc ritta.frontend.proto.Charts.TimeSeries
     */
    timeSeries: {
      name: "TimeSeries",
      I: TimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Query for time series for multiple resources.
     *
     * @generated from rpc ritta.frontend.proto.Charts.MultiTimeSeries
     */
    multiTimeSeries: {
      name: "MultiTimeSeries",
      I: MultiTimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get a time series that returns new values as they become available
     *
     * @generated from rpc ritta.frontend.proto.Charts.TimeSeriesStream
     */
    timeSeriesStream: {
      name: "TimeSeriesStream",
      I: TimeSeriesRequest,
      O: TimeSeriesResponse,
      kind: MethodKind.ServerStreaming,
    },
  }
};

