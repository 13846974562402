import { PlainMessage } from '@bufbuild/protobuf'
import { LocationAsRelativeRaw } from 'vue-router'
import type { RittaConfig } from '@/config'
import { ResourceType } from '@/constants/resourceType'
import * as RouteNames from '@/router/routeNames'
import {
  ResourceTab,
  getResourceType,
  getUnqualifiedId,
} from '@/model/resource'
import { AlertHelper } from '@/model/alert/helper'
import { Alert } from 'rfs/frontend/proto/alert_pb'
import { Resource } from 'rfs/pb/resource_pb'

/**
 * IMPORTANT: To prevent circular dependencies, DO NOT import anything from
 * '@/components/' or its subdirectories. Circular dependencies can lead
 * to maintenance challenges and hard-to-debug issues.
 */

export function createAlertDetailsRoute(alert: Alert): LocationAsRelativeRaw {
  return {
    name: RouteNames.ALERTS,
    params: { alertId: AlertHelper.getUnqualifiedId(alert) },
  }
}

export function createHydroRoute(hydro: Resource): LocationAsRelativeRaw {
  return {
    name: RouteNames.HYDRO_DETAILS,
    params: { hydroId: getUnqualifiedId(hydro) },
  }
}

export function createUtilitySolarRoute(
  utilitySolar: Resource
): LocationAsRelativeRaw {
  return {
    name: RouteNames.SOLAR_ARRAY_DETAILS,
    params: { utilitySolarId: getUnqualifiedId(utilitySolar) },
  }
}

export function createSiteRoute(
  r: Resource
): LocationAsRelativeRaw | undefined {
  const siteId = r.site?.id

  if (!siteId) {
    console.error(`createSiteRoute: "${r.id}" has no site.id`)
    return undefined
  }

  return { name: RouteNames.SITE, params: { siteId } }
}

export function createSubstationRoute(
  unqualifiedId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.createResourceDetailsRouteName(ResourceType.SUBSTATION),
    params: { unqualifiedId },
  }
}

export function createFeederRoute(
  unqualifiedId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.createResourceDetailsRouteName(ResourceType.FEEDER),
    params: { unqualifiedId },
  }
}

export function createTransformerRoute(
  unqualifiedId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.createResourceDetailsRouteName(ResourceType.TRANSFORMER),
    params: { unqualifiedId },
  }
}

export function createUplineParentRoute(parent: string): LocationAsRelativeRaw {
  const type = getResourceType(parent)
  if (!type) {
    return { name: RouteNames.OVERVIEW }
  }
  return {
    name: RouteNames.createResourceDetailsRouteName(type),
    params: {
      unqualifiedId: getUnqualifiedId(parent),
      tab: ResourceTab.TELEMETRY,
    },
  }
}

export function createResourceImpactsRoute(
  rt: ResourceType,
  rId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.createResourceDetailsRouteName(rt),
    params: {
      unqualifiedId: getUnqualifiedId(rId),
      tab: ResourceTab.IMPACTS,
    },
  }
}

/**
 * Route directly to the Telemetry tab of a resource.
 * Currently used on the "Substations" table for feeders and substations.
 */
export function createResourceTelemetryRoute(
  rt: ResourceType,
  rId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.createResourceDetailsRouteName(rt),
    params: {
      unqualifiedId: getUnqualifiedId(rId),
      tab: ResourceTab.TELEMETRY,
    },
  }
}

export function createGridImpactResourceRoute(
  config: Readonly<RittaConfig>,
  r?: PlainMessage<Resource>
): undefined | LocationAsRelativeRaw {
  if (!r) return undefined

  const rType = r.type as ResourceType

  return config.gridImpact?.resourceTypes?.includes(rType)
    ? createResourceImpactsRoute(rType, r.id)
    : undefined
}

export function createAllResourcesRoute(
  resource: Resource
): LocationAsRelativeRaw {
  const rt = resource.type as undefined | ResourceType

  // NOTE: Exception for Feeders. The user should be pushed to the
  // parent Substation details page.
  if (rt === ResourceType.FEEDER && resource.upline?.substation) {
    return createSubstationRoute(resource.upline.substation)
  } else if (rt === ResourceType.SUBSTATION || rt === ResourceType.FEEDER) {
    // NOTE: Since Feeders don't have a "all resources" page, use the Substations' one.
    return { name: RouteNames.SUBSTATIONS }
  } else if (rt) {
    // Default.
    return {
      name: RouteNames.createAllResourcesRouteName(rt),
    }
  } else {
    return { name: RouteNames.OVERVIEW }
  }
}

export function createNotificationRoute(
  notificationId: string
): LocationAsRelativeRaw {
  return {
    name: RouteNames.NOTIFICATION_DETAILS,
    params: { notificationId },
  }
}
