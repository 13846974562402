export const DEFAULT_DURATION = 15_000 // 15 secs.

export class IntervalBroadcaster {
  private id = 0
  private subscribers: Set<Function> = new Set()
  readonly duration: number

  constructor(ms: number = DEFAULT_DURATION) {
    this.duration = ms
  }

  private invokeCallbacks(): void {
    for (const cb of this.subscribers) {
      try {
        cb()
      } catch (err) {
        console.error('IntervalBroadcaster.invokeCallbacks: %o', err)
      }
    }
  }

  public subscribe(cb: Function) {
    this.subscribers.add(cb)
    return this
  }

  public unsubscribe(cb: Function) {
    this.subscribers.delete(cb)
    return this
  }

  public start() {
    if (!this.id) {
      this.id = setInterval(
        () => this.invokeCallbacks(),
        this.duration
      ) as unknown as number
    }
    return this
  }

  public stop() {
    clearInterval(this.id)
    this.id = 0
    return this
  }
}
