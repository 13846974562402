// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file control/proto/model.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Params, Policy } from "./policy_pb.js";
import { LatLng } from "../../pb/resource_pb.js";

/**
 * DeviceType is an enum indexing the various devices currently involved in the
 * "Control" interface.  As of this writing, the user interaction, and device
 * control, models are very type-specific: we only support groups of homogenous
 * device types, and the control policies (enabled on a group level) are very
 * specific to those types.  As a result, we need a common identifier for these
 * types.  Note that "type" is used here in the sense of the very specific
 * "ChargePoint EV Charger" and not the more generic "EV charger".
 *
 * NEXT TAG: 14
 *
 * @generated from enum ritta.partner.hce.control.proto.DeviceType
 */
export const DeviceType = /*@__PURE__*/ proto3.makeEnum(
  "ritta.partner.hce.control.proto.DeviceType",
  [
    {no: 0, name: "DEVICE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DEVICE_TYPE_CHARGEPOINT_EV_CHARGER", localName: "CHARGEPOINT_EV_CHARGER"},
    {no: 2, name: "DEVICE_TYPE_TESLA_POWERWALL_BATTERY", localName: "TESLA_POWERWALL_BATTERY"},
    {no: 3, name: "DEVICE_TYPE_HEILA_MICROGRID_CONTROLLER", localName: "HEILA_MICROGRID_CONTROLLER"},
    {no: 4, name: "DEVICE_TYPE_STEM_BATTERY", localName: "STEM_BATTERY"},
    {no: 5, name: "DEVICE_TYPE_SOLECTRIA_INVERTER", localName: "SOLECTRIA_INVERTER"},
    {no: 6, name: "DEVICE_TYPE_CHINT_INVERTER", localName: "CHINT_INVERTER"},
    {no: 7, name: "DEVICE_TYPE_TRIMARK_BATTERY", localName: "TRIMARK_BATTERY"},
    {no: 8, name: "DEVICE_TYPE_TRIMARK_INVERTER", localName: "TRIMARK_INVERTER"},
    {no: 9, name: "DEVICE_TYPE_FORD_CDS_CHARGER", localName: "FORD_CDS_CHARGER"},
    {no: 10, name: "DEVICE_TYPE_NUVVE_EVSE", localName: "NUVVE_EVSE"},
    {no: 11, name: "DEVICE_TYPE_EMULATE_VIRTUAL_BATTERY", localName: "EMULATE_VIRTUAL_BATTERY"},
    {no: 12, name: "DEVICE_TYPE_ABB_EVSE", localName: "ABB_EVSE"},
    {no: 13, name: "DEVICE_TYPE_GENERAC_INVERTER", localName: "GENERAC_INVERTER"},
  ],
);

/**
 * Group is a group of devices that are orchestrated and controlled together.
 *
 * NEXT TAG: 15
 *
 * @generated from message ritta.partner.hce.control.proto.Group
 */
export const Group = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Group",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enabled_policies", kind: "enum", T: proto3.getEnumType(Policy), repeated: true },
    { no: 4, name: "device_type", kind: "enum", T: proto3.getEnumType(DeviceType) },
    { no: 5, name: "device_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "current_policy", kind: "enum", T: proto3.getEnumType(Policy) },
    { no: 7, name: "location_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "current_policy_params", kind: "message", T: Params, opt: true },
    { no: 10, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "supports_oes", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "policy_end_time", kind: "message", T: Timestamp, opt: true },
    { no: 13, name: "next_policy", kind: "enum", T: proto3.getEnumType(Policy), opt: true },
    { no: 14, name: "next_policy_parameters", kind: "message", T: Params, opt: true },
  ],
);

/**
 * NEXT TAG: 5
 *
 * @generated from message ritta.partner.hce.control.proto.Location
 */
export const Location = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Location",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * Device is, conceptually, a single controllable device.  In the case of
 * ChargePoint EV Chargers, it describes a single charging station, which may
 * have multiple parts (each of which may technically be controlled
 * independently, per the ChargePoint API, but that level of flexibility is not
 * being represented here).  In the case of the Tesla Powerwall battery
 * installations, it describes an installation operating as a unit, even if it
 * includes multiple physical "Powerwall" devices (typically connected in
 * series).  In the case of the Heila Microgrid, it describes a single "Heila
 * Edge" device, which itself may be connected to any number of DERs and other
 * physical devices.
 *
 * NEXT TAG: 21
 *
 * @generated from message ritta.partner.hce.control.proto.Device
 */
export const Device = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "location", kind: "message", T: LatLng },
    { no: 5, name: "model", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "camus_resource_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "chargepoint_ev_charger", kind: "message", T: Device_ChargepointEvCharger, oneof: "details_oneof" },
    { no: 7, name: "tesla_powerwall_battery", kind: "message", T: Device_TeslaPowerwallBattery, oneof: "details_oneof" },
    { no: 8, name: "heila_microgrid_controller", kind: "message", T: Device_HeilaMicrogridController, oneof: "details_oneof" },
    { no: 9, name: "stem_battery", kind: "message", T: Device_StemBattery, oneof: "details_oneof" },
    { no: 10, name: "solectria_inverter", kind: "message", T: Device_SolectriaInverter, oneof: "details_oneof" },
    { no: 11, name: "chint_inverter", kind: "message", T: Device_ChintInverter, oneof: "details_oneof" },
    { no: 12, name: "trimark_battery", kind: "message", T: Device_TrimarkBattery, oneof: "details_oneof" },
    { no: 13, name: "trimark_inverter", kind: "message", T: Device_TrimarkInverter, oneof: "details_oneof" },
    { no: 14, name: "ford_cds_charger", kind: "message", T: Device_FordCDSCharger, oneof: "details_oneof" },
    { no: 16, name: "nuvve_evse", kind: "message", T: Device_NuvveEvse, oneof: "details_oneof" },
    { no: 18, name: "emulate_virtual_battery", kind: "message", T: Device_EmulateVirtualBattery, oneof: "details_oneof" },
    { no: 17, name: "device_type", kind: "enum", T: proto3.getEnumType(DeviceType) },
    { no: 19, name: "oe_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "resource_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ChargepointEvCharger contains details about a ChargePoint EV charging
 * station.  Note that, while the unit of control in the ChargePoint API is
 * actually the *port*, we are using the stations as the "device".
 *
 * NEXT TAG: 6
 *
 * @generated from message ritta.partner.hce.control.proto.Device.ChargepointEvCharger
 */
export const Device_ChargepointEvCharger = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.ChargepointEvCharger",
  () => [
    { no: 1, name: "station_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "Device_ChargepointEvCharger"},
);

/**
 * TeslaPowerwallBattery contains details about a Tesla Powerwall battery
 * "site", which is often actually an aggregation of multiple batteries
 * connected to each other (to increase capacity).
 *
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Device.TeslaPowerwallBattery
 */
export const Device_TeslaPowerwallBattery = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.TeslaPowerwallBattery",
  () => [
    { no: 1, name: "site_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "num_devices", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
  {localName: "Device_TeslaPowerwallBattery"},
);

/**
 * HeilaMicrogridController contains details about a single Heila Edge device.
 * These are also called "microgrid controllers" as they connect to multiple
 * DERs and can be layered.  Note that these are *not* the unit of control in
 * the API: rather, the entire community is controlled as a single unit. The
 * individual Heila Edge devices are represented as "devices" in the data
 * model here, however, in order to support surfacing the telemetry that we
 * receive (since that is at the granularity of the individual Edge device).
 *
 * NEXT TAG: 2
 *
 * @generated from message ritta.partner.hce.control.proto.Device.HeilaMicrogridController
 */
export const Device_HeilaMicrogridController = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.HeilaMicrogridController",
  [],
  {localName: "Device_HeilaMicrogridController"},
);

/**
 * NEXT TAG: 5
 *
 * @generated from message ritta.partner.hce.control.proto.Device.StemBattery
 */
export const Device_StemBattery = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.StemBattery",
  () => [
    { no: 2, name: "max_capacity_watthours", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "max_charge_watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "max_discharge_watts", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Device_StemBattery"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Device.SolectriaInverter
 */
export const Device_SolectriaInverter = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.SolectriaInverter",
  () => [
    { no: 1, name: "capacity_watts", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Device_SolectriaInverter"},
);

/**
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.Device.ChintInverter
 */
export const Device_ChintInverter = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.ChintInverter",
  () => [
    { no: 1, name: "capacity_watts", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
  {localName: "Device_ChintInverter"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Device.TrimarkBattery
 */
export const Device_TrimarkBattery = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.TrimarkBattery",
  () => [
    { no: 2, name: "max_capacity_watthours", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "Device_TrimarkBattery"},
);

/**
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Device.TrimarkInverter
 */
export const Device_TrimarkInverter = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.TrimarkInverter",
  [],
  {localName: "Device_TrimarkInverter"},
);

/**
 * The Ford Charging Data Services API breaks breaks a site into chargers and ports.
 *
 * To match ChargePoint, we aggregate all ports into a single charger entity
 * and report on that as a device.
 *
 * NEXT TAG: 3
 *
 * @generated from message ritta.partner.hce.control.proto.Device.FordCDSCharger
 */
export const Device_FordCDSCharger = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.FordCDSCharger",
  () => [
    { no: 1, name: "site_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ports", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
  {localName: "Device_FordCDSCharger"},
);

/**
 * This is a single Nuvve EVSE.
 * Right now we key off of a single EVSE, so if there are 2 chargers that'd be
 * 2 devices... but if's safe-ish because this deployment is 1:1:1
 * site:evse:vehicle. In the future, this may change and we should maybe have
 * the site be the toplevel ID, and the EVSE ports sit below it.
 *
 * NEXT TAG: 12
 *
 * @generated from message ritta.partner.hce.control.proto.Device.NuvveEvse
 */
export const Device_NuvveEvse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.NuvveEvse",
  [],
  {localName: "Device_NuvveEvse"},
);

/**
 * Intentionally has no fields, all additional device data should be
 * pulled from the resource collection as appropriate
 *
 * @generated from message ritta.partner.hce.control.proto.Device.EmulateVirtualBattery
 */
export const Device_EmulateVirtualBattery = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Device.EmulateVirtualBattery",
  [],
  {localName: "Device_EmulateVirtualBattery"},
);

/**
 * Data is the schema used by the data file used by the control server.
 *
 * NEXT TAG: 4
 *
 * @generated from message ritta.partner.hce.control.proto.Data
 */
export const Data = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Data",
  () => [
    { no: 1, name: "groups", kind: "message", T: Group, repeated: true },
    { no: 2, name: "devices", kind: "message", T: Device, repeated: true },
    { no: 3, name: "locations", kind: "message", T: Location, repeated: true },
  ],
);

