import { TEXT_NO_VALUE } from '@/constants/formatText'
import { routeForResource } from '@/router/routeForResource'
import * as RouteNames from '@/router/routeNames'
import { SearchTableColumn } from '@/model/meter/MeterSearchDataTable'
import { useMeterSearchStore } from '@/stores/meterSearch'
import type { LoadBlockFunction, Block, Result } from './types'

const TITLE = 'Meters'

export const searchMeters: LoadBlockFunction = async (
  searchText,
  maxResults,
  { services, dictionary }
) => {
  const block: Block = { title: TITLE, results: [] }

  try {
    const res = await services.queryService.searchForMeters(searchText, {
      page: 1,
      itemsPerPage: maxResults + 1,
      orderBy: { column: SearchTableColumn.METER, descending: false },
    })

    for (const result of res.results) {
      const item: Result = {
        title: {
          text: result.meter?.electrical ?? TEXT_NO_VALUE,
          link: routeForResource(result),
        },
        pairs: [
          {
            label: dictionary('Site'),
            text: result.site?.id ?? TEXT_NO_VALUE,
          },
          {
            label: dictionary('Subscription'),
            text: result.subscription?.electrical ?? TEXT_NO_VALUE,
          },
          {
            label: dictionary('Customer name'),
            text: result.billing?.party ?? TEXT_NO_VALUE,
          },
        ],
      }
      block.results.push(item)
    }

    // See more.
    if (block.results.length > maxResults) {
      block.results = block.results.slice(0, maxResults)
      block.seeMore = {
        link: { name: RouteNames.METERS },
        onClick: () => useMeterSearchStore().updateSearchText(searchText),
      }
    }
  } catch (err) {
    console.error('searchMeters: %o', err)
  }

  return block
}
