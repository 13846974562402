import { DateTime, Interval } from 'luxon'
import { Services } from '@/services'
import { Timestamp } from '@/services/timestamp_pb'
import { CILANTRO } from '@/constants/colors'
import { ControlMetric } from '@/constants/metrics'
import { limitInterval } from '@/utils/time'
import { createCutOffDate } from '@/model/control'
import { sortByStartTime } from '@/model/control/waypoint'
import { FixedTimeDataSource, ITimeSeriesDataSource } from '@/model/charts'
import {
  DataProvider,
  TimeSeriesConfig,
} from '@/model/charts/TimeSeriesDataSource'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import { convertCommandToDataPoints } from '@/model/control/proxy'
import {
  TimeSeriesResponse,
  TimeSeries as TsdbTimeSeries,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import { ChartDefinition, ChartType } from '@/types/charts'
import { DeviceSchedule } from 'rfs/device/proto/proxy_pb'
import { NOW_MARKER } from '@/utils/chartjs/annotations'

export const eventChartDefinition: ChartDefinition = {
  id: 'group-event-chart',
  title: 'Dispatch',
  type: ChartType.Power,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

const eventSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_BATTERY_DISPATCH_ACTUAL,
  config: {
    seriesName: 'Dispatch (historical)',
    seriesColor: CILANTRO.hex,
    seriesFill: 'origin',
    seriesLineWidth: 2,
  },
}

const eventFutureSeriesConfig: TimeSeriesConfig = {
  metric: 'fake.metric.events.future',
  config: {
    seriesName: 'Dispatch (scheduled)',
    seriesColor: CILANTRO.hex,
    seriesLine: 'dashed',
    seriesFill: 'diagonal',
  },
}

export function newGroupEventHistoricalDataSource(
  services: Services,
  resourceIds: string[],
  getNow: () => DateTime
): ITimeSeriesDataSource {
  // TODO(rafael): only a single v2g resource is expected for now.
  if (!resourceIds.length || resourceIds.length > 1) {
    throw new Error(
      'newGroupEventHistoricalDataSource: unexpected number of resources'
    )
  }

  const dataProvider: DataProvider = async (request) => {
    const now = getNow()

    // NOTE(rafael): By default, the data provider receives the "visible"
    // interval. Limiting the interval prevents the data provider from
    // fetching data in to the future (beyond "now"). For future data
    // we have the Dispatch (scheduled) time series.
    const limitedInterval = limitInterval(request.interval, now)

    const res = await services.proxy.deviceGetSchedule({
      deviceId: resourceIds[0],
      startTime: Timestamp.fromDateTime(request.interval.start),
      endTime: Timestamp.fromDateTime(request.interval.end),
    })

    return new TimeSeriesResponse({
      series: [
        new TsdbTimeSeries({
          metric: eventSeriesConfig.metric,
          data: convertDeviceScheduleToData(
            filterCommands(limitedInterval, res)
          ),
        }),
      ],
    })
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow())
  )

  // Events (Historical).
  ds.addChartSeries(eventChartDefinition, eventSeriesConfig)

  return ds
}

export function newGroupEventFutureDataSource(
  interval: Interval,
  deviceSchedule?: DeviceSchedule
): ITimeSeriesDataSource {
  const ds = new FixedTimeDataSource(
    interval,
    async () =>
      new TimeSeriesResponse({
        series: [
          new TsdbTimeSeries({
            metric: eventFutureSeriesConfig.metric,
            data: convertDeviceScheduleToData(
              filterCommands(interval, deviceSchedule ?? new DeviceSchedule())
            ),
          }),
        ],
      })
  )

  // Events (Future).
  ds.addChartSeries(eventChartDefinition, eventFutureSeriesConfig)

  return ds
}

/**
 * Creates a list of data points for the device schedule object.
 */
function convertDeviceScheduleToData(
  deviceSchedule: DeviceSchedule
): TsdbTimeSeries['data'] {
  return (
    // NOTE(rafael): order the commands chronologically so they don't
    // generate bizarre lines/areas on the chart.
    sortByStartTime(deviceSchedule.commands).reduce<TimeSeriesDataPoint[]>(
      (acc, command) => {
        return [...acc, ...convertCommandToDataPoints(command)]
      },
      []
    )
  )
}

/**
 * Only commands that have their startTime within given interval.
 */
function filterCommands(
  interval: Interval,
  deviceSchedule: DeviceSchedule
): DeviceSchedule {
  return new DeviceSchedule({
    ...deviceSchedule,
    commands: deviceSchedule.commands.filter((cmd) => {
      const cmdStart = cmd.startTime
      return (
        !cmdStart || interval.contains(DateTime.fromMillis(cmdStart.toMillis()))
      )
    }),
  })
}
