import type { DateTime, Interval } from 'luxon'
import {
  BLUE2,
  BUTTERCUP,
  DOLPHIN,
  JAFFA,
  LAGOON,
  MALIBU,
} from '@/constants/colors'
import { ControlMetric, PlanScheduleMetric } from '@/constants/metrics'
import type { Services } from '@/services'
import { NOW_MARKER } from '@/utils/chartjs/annotations'
import { limitInterval } from '@/utils/time'
import { FixedTimeDataSource, type ITimeSeriesDataSource } from '@/model/charts'
import { createCutOffDate } from '@/model/control'
import {
  ONE_TILE_SIZE,
  type DataProvider,
  type TimeSeriesConfig,
} from '@/model/charts/TimeSeriesDataSource'
import { TimeSeriesWithRefreshDataSource } from '@/model/charts/TimeSeriesWithRefreshDataSource'
import {
  type Metric,
  createWaypointFutureTimeSeriesConfig,
  createSocHistoricalTimeSeriesConfig,
  createSocFutureTimeSeriesConfig,
  createDispatchHistoricalTimeSeriesConfig,
  createDispatchFutureTimeSeriesConfig,
  removeWaypointMetric,
} from '@/model/control/waypoint'
import { type ChartDefinition, ChartType } from '@/types/charts'
import {
  TimeSeriesResponse,
  TimeSeries as TsdbTimeSeries,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import { ScheduleTimeSeriesResponse } from 'rfs/frontend/proto/controls_pb'
import type { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { DeviceType } from 'rfs/control/proto/model_pb'

const chart01ChartDefinition: ChartDefinition = {
  id: 'location-waypoint-chart-1',
  title: 'Waypoints',
  type: ChartType.Power,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

const chart02ChartDefinition: ChartDefinition = {
  id: 'location-waypoint-chart-2',
  title: 'Load',
  type: ChartType.Power,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

const chart03ChartDefinition: ChartDefinition = {
  id: 'location-waypoint-chart-3',
  title: 'Generation:Load',
  type: ChartType.Percentage,
  annotations: { timeMarkers: [NOW_MARKER], everyOtherDay: true },
}

export const locationWaypointCharts = [
  chart01ChartDefinition,
  chart02ChartDefinition,
  chart03ChartDefinition,
]

const socHistoricalTimeSeriesConfig = createSocHistoricalTimeSeriesConfig(
  'BESS SoC (historical)',
  { useEnergyFormatter: true }
)

const pvHistoricalTimeSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_PV_ACTUAL,
  config: {
    seriesName: 'PV (historical)',
    seriesColor: BUTTERCUP.hex,
  },
}

const pccHistoricalTimeSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_PCC_ACTUAL,
  config: {
    seriesName: 'PV + BESS dispatch (at PCC) (historical)',
    seriesColor: BLUE2.hex,
  },
}

const dispatchHistoricalSeriesConfig = createDispatchHistoricalTimeSeriesConfig(
  'BESS Dispatch (historical)'
)

const grossLoadHistoricalTimeSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_GROSS_LOAD_ACTUAL,
  config: {
    seriesName: 'Gross load (historical)',
    seriesColor: LAGOON.hex,
  },
}

const netLoadHistoricalTimeSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_NET_LOAD_ACTUAL,
  config: {
    seriesName: 'Net load (historical)',
    seriesColor: MALIBU.hex,
  },
}

const generationLoadRatioHistoricalTimeSeriesConfig: TimeSeriesConfig = {
  metric: ControlMetric.CONTROL_GENERATION_LOAD_RATIO_ACTUAL,
  config: {
    seriesName: 'Generation:Load (historical)',
    seriesColor: DOLPHIN.hex,
  },
}

export function newLocationWaypointHistoricalDataSource(
  services: Services,
  groupId: string,
  getNow: () => DateTime
): ITimeSeriesDataSource {
  const dataProvider: DataProvider = async (request) => {
    request.interval = limitInterval(request.interval, getNow())
    return services.controlsData.fetchHistoricalSchedule(request, groupId)
  }

  const ds = new TimeSeriesWithRefreshDataSource(
    dataProvider,
    createCutOffDate(getNow()),
    ONE_TILE_SIZE
  )

  // PV (Historical).
  ds.addChartSeries(chart01ChartDefinition, pvHistoricalTimeSeriesConfig)

  // PCC (Historical).
  ds.addChartSeries(chart01ChartDefinition, pccHistoricalTimeSeriesConfig)

  // Dispatch (Historical).
  ds.addChartSeries(chart01ChartDefinition, dispatchHistoricalSeriesConfig)

  // SoC (Historical).
  ds.addChartSeries(chart01ChartDefinition, socHistoricalTimeSeriesConfig)

  // Gross load (Historical).
  ds.addChartSeries(chart02ChartDefinition, grossLoadHistoricalTimeSeriesConfig)

  // Net load (Historical).
  ds.addChartSeries(chart02ChartDefinition, netLoadHistoricalTimeSeriesConfig)

  // Generation Load Ratio (Historical).
  ds.addChartSeries(
    chart03ChartDefinition,
    generationLoadRatioHistoricalTimeSeriesConfig
  )

  return ds
}

export function newLocationWaypointFutureDataSource(
  services: Services,
  interval: Interval,
  groupId: string,
  metric: Metric,
  deviceType: DeviceType,
  maxCapacityWatthours: number,
  schedule?: ScheduleTimeSeriesResponse,
  modifiedWaypoints?: Waypoint[]
): ITimeSeriesDataSource {
  const waypointSeriesConfig = createWaypointFutureTimeSeriesConfig({
    metric,
    deviceType,
  })

  const ds = new FixedTimeDataSource(interval, async (req) => {
    const res =
      schedule ??
      (await services.controlsData.fetchPlanSchedule(
        groupId,
        removeWaypointMetric(req),
        modifiedWaypoints
      ))

    res.timeSeries = res.timeSeries ?? new TimeSeriesResponse()

    const batterySoc = res.timeSeries.series.find(
      (series) => series.metric === PlanScheduleMetric.CONTROL_BATTERY_SOC
    )

    // Transform the list of Waypoints into a time series.
    if (batterySoc) {
      res.timeSeries.series.push(
        transformWaypointsToTsdbTimeSeries(
          waypointSeriesConfig.metric,
          res.waypointsList,
          maxCapacityWatthours,
          batterySoc
        )
      )
    }

    return res.timeSeries
  })

  // PV (forecast).
  ds.addChartSeries(chart01ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_PV_FORECAST,
    config: {
      seriesName: 'PV (forecast)',
      seriesColor: BUTTERCUP.hex,
      seriesLine: 'dashed',
    },
  })

  // PCC (forecast).
  ds.addChartSeries(chart01ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_PCC,
    config: {
      seriesName: 'PV + BESS dispatch (at PCC) (scheduled)',
      seriesColor: BLUE2.hex,
      seriesLine: 'dashed',
    },
  })

  // Dispatch (Scheduled).
  ds.addChartSeries(
    chart01ChartDefinition,
    createDispatchFutureTimeSeriesConfig('BESS Dispatch (scheduled)')
  )

  // SoC (Scheduled).
  ds.addChartSeries(
    chart01ChartDefinition,
    createSocFutureTimeSeriesConfig({
      seriesName: 'BESS SoC (scheduled)',
      useEnergyFormatter: true,
    })
  )

  // Waypoints (Scheduled).
  ds.addChartSeries(chart01ChartDefinition, waypointSeriesConfig)

  // Gross Load (forecast).
  ds.addChartSeries(chart02ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_GROSS_LOAD,
    config: {
      seriesName: 'Gross load (forecast)',
      seriesColor: LAGOON.hex,
      seriesLine: 'dashed',
    },
  })

  // Net Load (forecast).
  ds.addChartSeries(chart02ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_NET_LOAD,
    config: {
      seriesName: 'Net load (forecast)',
      seriesColor: MALIBU.hex,
      seriesLine: 'dashed',
    },
  })

  // Curtailed PV (forecast).
  ds.addChartSeries(chart02ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_CURTAILED_PV,
    config: {
      seriesName: 'Curtailed PV (forecast)',
      seriesColor: JAFFA.hex,
      seriesLine: 'dashed',
    },
  })

  // Generation:Load Ratio (forecast).
  ds.addChartSeries(chart03ChartDefinition, {
    metric: PlanScheduleMetric.CONTROL_GENERATION_LOAD_RATIO,
    config: {
      seriesName: 'Generation:Load (forecast)',
      seriesColor: DOLPHIN.hex,
      seriesLine: 'dashed',
    },
  })

  return ds
}

function transformWaypointsToTsdbTimeSeries(
  metric: string,
  waypoints: Waypoint[],
  maxCapacityWatthours: number,
  socSeries: TsdbTimeSeries
): TsdbTimeSeries {
  return new TsdbTimeSeries({
    metric,
    data: waypoints.flatMap((w) => {
      // Is expected that the scheduled SoC contains a value we can use
      // for the start value of the segment.
      const socAtStart = socSeries.data.find(
        (dp) => dp.x === w.startTime?.toMillis()
      )

      /**
       * The waypoint chart for Locations show kW values in the Y axis,
       * so we need to compute and use a kW value instead of
       * "targer_value" (%).
       * For Groups, the waypoint chart show % values.
       */
      const y =
        w.targetValue !== undefined
          ? w.targetValue * maxCapacityWatthours
          : undefined

      return [
        // "startTime" and "endTime".
        new TimeSeriesDataPoint({
          x: w.startTime?.toMillis(),
          y: socAtStart?.y ?? y,
        }),
        new TimeSeriesDataPoint({ x: w.endTime?.toMillis(), y }),
        // Divider data point.
        new TimeSeriesDataPoint({ x: w.endTime?.toMillis() ?? 0 + 1_000 }),
      ]
    }),
  })
}
