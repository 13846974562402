<template>
  <div role="region" :aria-label="block.title" class="px-2">
    <!-- Title -->
    <h6 class="d-block text-caption font-weight-medium ce-border-bottom">
      {{ block.title }}
    </h6>

    <!-- Results -->
    <ul v-if="block.results.length">
      <li v-for="r of block.results" :key="r.title.text" class="text-caption">
        <!-- Title -->
        <span
          class="text-subtitle-2 pr-2"
          :class="{
            'text-primary': !!r.title.onClick,
            'cursor-pointer': !!r.title.onClick,
          }"
          @click="r.title.onClick"
        >
          <router-link v-if="r.title.link" :to="r.title.link">
            {{ r.title.text }}
          </router-link>
          <template v-else>{{ r.title.text }}</template>
        </span>

        <!-- Sub title -->
        <span v-if="r.subTitle" class="ml-1">
          {{ r.subTitle }}
        </span>

        <span v-for="pair of r.pairs ?? []" :key="pair.text" class="mr-1">
          <b> {{ pair.label }}</b
          >:
          <router-link
            v-if="pair.link"
            :to="pair.link"
            class="text-weight-medium"
          >
            {{ pair.text }}
          </router-link>
          <template v-else>
            {{ pair.text }}
          </template>
        </span>
      </li>
    </ul>

    <!-- See more -->
    <router-link
      v-if="block.seeMore"
      :to="block.seeMore.link"
      @click="block.seeMore.onClick"
      class="text-caption font-weight-medium"
    >
      See more results...
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Block } from '@/model/search/types'

export default defineComponent({
  name: 'SearchResultsBlock',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },
})
</script>
