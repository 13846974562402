<template>
  <v-row no-gutters class="min-height-100">
    <!-- Left column -->
    <v-col cols="8" class="pa-6 ce-border-right">
      <ce-title big>{{ title }}</ce-title>
      <alerts-table :alertId />
    </v-col>

    <!-- Right column -->
    <v-col cols="4" class="bg-grey-lighten-4 pa-6">
      <alert-details v-if="alertId" :alertId />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CeTitle from '@/components/CeTitle.vue'
import AlertsTable from '@/components/alerts/AlertsTable.vue'
import AlertDetails from '@/components/alerts/AlertDetails.vue'

export default defineComponent({
  name: 'Alerts',
  props: {
    alertId: {
      type: String,
      required: false,
    },
  },
  components: { CeTitle, AlertsTable, AlertDetails },
  setup() {
    return { title: 'Alerts' }
  },
})
</script>
