import { shallowReactive } from 'vue'
import { defineImmutableStore } from '@/stores/defineStore'

interface State {
  isOpen: boolean
  text: string
  /**
   * Vuetify's VSnackbar `color` prop.
   *
   * https://vuetifyjs.com/en/api/v-snackbar/#props-color
   */
  color: string | undefined
}

export const useGlobalSnackbar = defineImmutableStore('globalSnackbar', {
  state: () => {
    return shallowReactive<State>({
      isOpen: false,
      text: '',
      color: undefined,
    })
  },
  actions: {
    openSnackbar(message: string, color?: 'info' | 'error') {
      this.text = message
      this.color = color
      this.isOpen = true
    },
    closeSnackbar() {
      this.isOpen = false

      // Avoids changing the color BEFORE the snackbar is completely hidden.
      setTimeout(() => {
        this.text = ''
        this.color = undefined
      }, 100)
    },
  },
})
