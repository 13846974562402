<template>
  <v-snackbar
    :model-value="isOpen"
    :color
    :timeout="10_000"
    @update:model-value="closeSnackbar"
  >
    <span>{{ text }}</span>

    <!-- Close btn -->
    <template v-slot:actions>
      <v-btn variant="text" @click="closeSnackbar"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'

export default defineComponent({
  name: 'GlobalSnackbar',
  computed: {
    ...mapState(useGlobalSnackbar, ['isOpen', 'color', 'text']),
  },
  methods: {
    ...mapActions(useGlobalSnackbar, ['closeSnackbar']),
  },
})
</script>
