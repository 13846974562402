<template>
  <time-series-chart-group
    :charts="[capacityChartDefinition]"
    :data-source="dataSource"
    :interval="interval"
    :max-end-time="maxEndTime"
    @new-interval="handleNewInterval"
    @new-time-series="handleNewTimeSeries"
  >
    <!-- Summary Box -->
    <template
      v-if="!hideSummaryBox"
      v-slot:[`right-side-of-${capacityChartDefinition.id}`]
    >
      <capacity-summary-box
        :last-value="lastObservedPower"
        :items="summaryItems"
      />
    </template>
  </time-series-chart-group>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime, Interval } from 'luxon'
import { ITimeSeriesDataSource, TimeSeriesMap } from '@/model/charts'
import { getTimeSeriesWithin } from '@/model/charts/TimeSeriesDataSource'
import {
  capacityChartDefinition,
  summaryItems,
  lastObservedPower,
  getSummaryStats,
} from '@/model/control/capacity'
import { TimeSeriesChartGroup } from '@/components/common'
import CapacitySummaryBox from '@/components/control/CapacitySummaryBox.vue'

export default defineComponent({
  name: 'CapacityChartsFixed',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    maxEndTime: {
      type: Object as PropType<undefined | DateTime>,
      required: true,
    },
    dataSource: {
      type: Object as PropType<ITimeSeriesDataSource>,
      required: true,
    },
    /**
     * Hides the summary box.
     * TODO(Isaac): Remove when there are Group and Location level stats.
     */
    hideSummaryBox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { TimeSeriesChartGroup, CapacitySummaryBox },
  setup() {
    return { capacityChartDefinition }
  },
  data() {
    return {
      lastObservedPower: '',
      summaryItems: summaryItems(),
    }
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.$emit('new-interval', newInterval)
      const sliceMap = getTimeSeriesWithin(newInterval, this.dataSource)
      const stats = getSummaryStats(sliceMap)
      if (!this.hideSummaryBox) {
        this.summaryItems = summaryItems(stats)
      }
    },
    handleNewTimeSeries(newMap: TimeSeriesMap): void {
      this.lastObservedPower = lastObservedPower(newMap)
      const sliceMap = getTimeSeriesWithin(this.interval, this.dataSource)
      const stats = getSummaryStats(sliceMap)
      if (!this.hideSummaryBox) {
        this.summaryItems = summaryItems(stats)
      }
    },
  },
})
</script>
