import { Value } from '@bufbuild/protobuf'
import { DateTime } from 'luxon'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { createAlertDetailsRoute } from '@/utils/router/create'
import {
  Header,
  Filters,
  DataTable,
  Row,
  FilterDropDown,
  FilterDate,
  Options,
} from '@/model/tables/DataTable'
import { SearchValue } from '@/model/tables/helper'
import { AlertHelper } from '@/model/alert/helper'
import {
  Alert,
  AlertListRequest,
  AlertListRequest_Field as Field,
  AlertListRequest_OrderBy as OrderBy,
  AlertListRequest_FilterBy as FilterBy,
  Severity,
  State,
} from 'rfs/frontend/proto/alert_pb'
import { Timestamp } from '@/services/timestamp_pb'

export enum Columns {
  NAME = 'NAME',
  SEVERITY = 'SEVERITY',
  START_TIME = 'START_TIME',
  END_TIME = 'END_TIME',
  STATE = 'STATE',
}

export type CustomFilters = Filters<Columns>

export type CustomOptions = Options<Columns>

interface AlertRow extends Row {
  alert: Alert
  [Columns.NAME]: string
  [Columns.SEVERITY]: string
  [Columns.START_TIME]: string
  [Columns.END_TIME]: string
  [Columns.STATE]: string
}

export interface AlertsDataTable extends DataTable<AlertRow> {}

const headerName: Header<AlertRow> = {
  title: 'Name',
  key: Columns.NAME,
  sortable: true,
  columnSelector: { required: true },
  routeFactory: (_config, r) => createAlertDetailsRoute(r.alert),
}

const headerSeverity: Header = {
  title: 'Severity',
  key: Columns.SEVERITY,
  sortable: true,
  width: '16%',
}

const headerStartTime: Header = {
  title: 'Start Time',
  key: Columns.START_TIME,
  sortable: true,
  width: '24%',
}

const headerEndTime: Header = {
  title: 'End Time',
  key: Columns.END_TIME,
  sortable: true,
  width: '24%',
}

const headerState: Header = {
  title: 'State',
  key: Columns.STATE,
  sortable: true,
  width: '16%',
}

export const headers: Header[] = [
  headerName,
  headerSeverity,
  headerStartTime,
  headerEndTime,
  headerState,
]

export function createInitialOptions(): CustomOptions {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column: Columns.START_TIME, descending: true },
  }
}

function newFilterSeverity(): FilterDropDown<Severity> {
  return {
    type: 'dropdown',
    items: [
      { title: AlertHelper.formatSeverity(Severity.LOW), value: Severity.LOW },
      {
        title: AlertHelper.formatSeverity(Severity.MEDIUM),
        value: Severity.MEDIUM,
      },
      {
        title: AlertHelper.formatSeverity(Severity.HIGH),
        value: Severity.HIGH,
      },
    ],
    value: null,
  }
}

function newFilterState(): FilterDropDown<State> {
  return {
    type: 'dropdown',
    items: [
      { title: AlertHelper.formatState(State.ACTIVE), value: State.ACTIVE },
      { title: AlertHelper.formatState(State.INACTIVE), value: State.INACTIVE },
      { title: AlertHelper.formatState(State.SILENCED), value: State.SILENCED },
    ],
    value: null,
  }
}

function newFilterStartTime(): FilterDate {
  return {
    type: 'date',
    label: 'Start Time',
    value: null,
  }
}

function newFilterEndTime(): FilterDate {
  return {
    type: 'date',
    label: 'End Time',
    value: null,
  }
}

export function createPristineFilters(): CustomFilters {
  return new Map()
    .set(Columns.SEVERITY, newFilterSeverity())
    .set(Columns.START_TIME, newFilterStartTime())
    .set(Columns.END_TIME, newFilterEndTime())
    .set(Columns.STATE, newFilterState())
}

export function createInitialFilters(): CustomFilters {
  const filters = createPristineFilters()
  filters.set(Columns.STATE, { ...newFilterState(), value: State.ACTIVE })
  return filters
}

export function createAlertsTable(
  alerts: Alert[],
  opts?: { hasFiltersOrSearch: boolean; highlightedRowId?: string }
): AlertsDataTable {
  const table: AlertsDataTable = {
    rows: alerts.map(createAlertRow),
    highlightedRowId: opts?.highlightedRowId,
  }

  if (opts?.hasFiltersOrSearch) {
    table.noDataText = 'No matching alerts found'
  }

  return table
}

function createAlertRow(alert: Alert): AlertRow {
  return {
    alert,
    id: alert.id,
    [Columns.NAME]: alert.name,
    [Columns.SEVERITY]: AlertHelper.formatSeverity(alert.severity),
    [Columns.START_TIME]: alert.start
      ? AlertHelper.formatTimestamp(alert.start)
      : TEXT_NO_VALUE,
    [Columns.END_TIME]: alert.end
      ? AlertHelper.formatTimestamp(alert.end)
      : TEXT_NO_VALUE,
    [Columns.STATE]: AlertHelper.formatState(alert.state),
  }
}

const COLUMN_TO_FIELD: Record<Columns, Field> = {
  [Columns.NAME]: Field.NAME,
  [Columns.SEVERITY]: Field.SEVERITY,
  [Columns.START_TIME]: Field.START,
  [Columns.END_TIME]: Field.END,
  [Columns.STATE]: Field.STATE,
}

export function createRequest(
  options: CustomOptions,
  filters: CustomFilters,
  search: SearchValue
): AlertListRequest {
  const { itemsPerPage, page } = options

  return new AlertListRequest({
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    orderBy: createOrderBy(options),
    filterBy: createFilterBy(filters, search),
  })
}

/** * NOTE(rafael): currently the alerts table orders by a single column. */
function createOrderBy(options: CustomOptions): OrderBy[] {
  const { orderBy } = options
  return [
    new OrderBy({
      field: COLUMN_TO_FIELD[orderBy.column],
      descending: orderBy.descending,
    }),
  ]
}

function createFilterBy(
  filters: CustomFilters,
  search: SearchValue
): FilterBy[] {
  const filterBy: FilterBy[] = []

  if (search !== null) {
    filterBy.push(createSearchFilter(search))
  }

  for (const [columnId, filterFields] of filters.entries()) {
    // Severity
    if (columnId === Columns.SEVERITY) {
      const filterValue =
        filterFields.type === 'dropdown'
          ? (filterFields as FilterDropDown<Severity>).value
          : null

      if (filterValue !== null) {
        filterBy.push(createSeverityFilter(filterValue))
      }
    }

    // Start time
    if (columnId === Columns.START_TIME) {
      const filterValue =
        filterFields.type === 'date' ? filterFields.value : null

      if (filterValue !== null) {
        filterBy.push(createStartFilter(filterValue))
      }
    }

    // End time
    if (columnId === Columns.END_TIME) {
      const filterValue =
        filterFields.type === 'date' ? filterFields.value : null

      if (filterValue !== null) {
        filterBy.push(createEndFilter(filterValue))
      }
    }

    // State
    if (columnId === Columns.STATE) {
      const filterValue =
        filterFields.type === 'dropdown'
          ? (filterFields as FilterDropDown<State>).value
          : null

      if (filterValue !== null) {
        filterBy.push(createStateFilter(filterValue))
      }
    }
  }

  return filterBy
}

function createSearchFilter(search: string): FilterBy {
  return new FilterBy({
    field: COLUMN_TO_FIELD[Columns.NAME],
    criteria: { case: 'contains', value: search },
  })
}

function createSeverityFilter(severity: Severity): FilterBy {
  return new FilterBy({
    field: COLUMN_TO_FIELD[Columns.SEVERITY],
    criteria: {
      case: 'equals',
      value: new Value({ kind: { case: 'numberValue', value: severity } }),
    },
  })
}

function createStartFilter(start: DateTime): FilterBy {
  return new FilterBy({
    field: COLUMN_TO_FIELD[Columns.START_TIME],
    criteria: {
      case: 'after',
      value: Timestamp.fromDateTime(start),
    },
  })
}

function createEndFilter(end: DateTime): FilterBy {
  return new FilterBy({
    field: COLUMN_TO_FIELD[Columns.END_TIME],
    criteria: {
      case: 'before',
      value: Timestamp.fromDateTime(end.endOf('day')),
    },
  })
}

function createStateFilter(state: State): FilterBy {
  return new FilterBy({
    field: COLUMN_TO_FIELD[Columns.STATE],
    criteria: {
      case: 'equals',
      value: new Value({ kind: { case: 'numberValue', value: state } }),
    },
  })
}
