<template>
  <telemetry-charts
    :charts
    :dataSource
    :interval
    :summaryBoxes
    :intervalBroadcaster
    @new-interval="handleNewInterval"
    @new-time-series="handleNewTimeSeries"
  />
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Interval } from 'luxon'
import { Format } from '@/utils/format'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import type { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { AggregateDataSource, TimeSeriesDataSource } from '@/model/charts'
import {
  ACTIVE_POWER_ID,
  ACTIVE_POWER_TITLE,
  activePowerChartDefinition,
  activePowerYAxisFormatter,
  emptySummary,
  getFirstTimeSeriesSummary,
  socPercentageChartDefinition,
  SOC_TITLE,
  percentageYAxisFormatter,
  SOC_ID,
  newInitialInterval,
  transformerLoadingChartDefinition,
  TRANSFORMER_LOADING_ID,
  TRANSFORMER_LOADING_TITLE,
  reactivePowerChartDefinition,
  REACTIVE_POWER_TITLE,
  reactivePowerYAxisFormatter,
  REACTIVE_POWER_ID,
  getRMSEfromTimeSeriesMap,
} from '@/model/control/telemetry'
import {
  DATA_POINT_AGE_LIMIT_MINUTES,
  newDeviceTelemetryDataSource,
  newTransformerDataSource,
} from '@/model/control/device/DeviceTelemetryChartData'
import { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import { addForecastInterval } from '@/model/forecast'
import { updateChartForResource } from '@/model/grid/ResourceImpactChartData'
import TelemetryCharts, {
  type TelemetrySummaryWithChartId,
} from '@/components/control/TelemetryCharts.vue'
import type { Group } from 'rfs/control/proto/model_pb'
import { Resource } from 'rfs/pb/resource_pb'
import type { ChartDefinition, NumberOrNull } from '@/types/charts'

type MaybeTransformer = undefined | Resource
type ShouldResetDataSource = [Group, Resource, MaybeTransformer]

export default defineComponent({
  name: 'DeviceTelemetryCharts',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    transformer: {
      type: Resource,
      required: false,
    },
    showStateOfCharge: {
      type: Boolean,
      required: false,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
    /**
     * Instructs component to refresh chart data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<IntervalBroadcaster>,
      required: false,
    },
  },
  components: { TelemetryCharts },
  data() {
    let interval = newInitialInterval(this.$observationTime())

    if (this.isOperatingEnvelopeEnabled) {
      interval = addForecastInterval(interval, this.$rittaConfig)
    }

    return {
      interval,
      dataSource: TimeSeriesDataSource.emptyDataSource(),
      activePower: emptySummary(),
      reactivePower: emptySummary(),
      soc: emptySummary(),
      transformerLoading: emptySummary(),
    }
  },
  computed: {
    showReactivePower(): boolean {
      const allowed =
        this.$rittaConfig.control?.device?.telemetry?.reactivePower ?? []
      return allowed.includes(this.resource.type)
    },
    charts(): ChartDefinition[] {
      const charts = [activePowerChartDefinition]

      if (this.showReactivePower) {
        charts.push(reactivePowerChartDefinition)
      }

      if (this.showStateOfCharge) {
        charts.push(socPercentageChartDefinition)
      }

      if (this.isOperatingEnvelopeEnabled && this.transformer) {
        charts.push(
          updateChartForResource(
            transformerLoadingChartDefinition,
            this.transformer
          )
        )
      }

      return charts
    },
    activePowerSummaryBox(): TelemetrySummaryWithChartId {
      const { last, minY, meanY, maxY } = this.activePower

      return {
        chartId: activePowerChartDefinition.id,
        title: ACTIVE_POWER_TITLE,
        lastValue:
          last?.y !== undefined
            ? activePowerYAxisFormatter(last.y)
            : TEXT_NO_VALUE,
        infoColumnItems: [
          { label: 'Min', text: activePowerYAxisFormatter(minY) },
          { label: 'Max', text: activePowerYAxisFormatter(maxY) },
          { label: 'Mean', text: activePowerYAxisFormatter(meanY) },
        ],
      }
    },
    reactivePowerSummaryBox(): TelemetrySummaryWithChartId {
      const { last, minY, meanY, maxY } = this.reactivePower

      return {
        chartId: reactivePowerChartDefinition.id,
        title: REACTIVE_POWER_TITLE,
        lastValue:
          last?.y !== undefined
            ? reactivePowerYAxisFormatter(last.y)
            : TEXT_NO_VALUE,
        infoColumnItems: [
          { label: 'Min', text: reactivePowerYAxisFormatter(minY) },
          { label: 'Max', text: reactivePowerYAxisFormatter(maxY) },
          { label: 'Mean', text: reactivePowerYAxisFormatter(meanY) },
        ],
      }
    },
    socSummaryBox(): TelemetrySummaryWithChartId {
      const { last, minY, meanY, maxY } = this.soc

      return {
        chartId: socPercentageChartDefinition.id,
        title: SOC_TITLE,
        lastValue:
          last?.y !== undefined
            ? percentageYAxisFormatter(last.y)
            : TEXT_NO_VALUE,
        infoColumnItems: [
          { label: 'Min', text: percentageYAxisFormatter(minY) },
          { label: 'Max', text: percentageYAxisFormatter(maxY) },
          { label: 'Mean', text: percentageYAxisFormatter(meanY) },
        ],
      }
    },
    transformerLoadingSummaryBox(): TelemetrySummaryWithChartId {
      const { last, minY, meanY, maxY, rmse } = this.transformerLoading

      const formatter = (v?: NumberOrNull) =>
        Format.fmtApparentPower(v) || TEXT_NO_VALUE

      return {
        chartId: transformerLoadingChartDefinition.id,
        title: TRANSFORMER_LOADING_TITLE,
        lastValue: formatter(last?.y),
        infoColumnItems: [
          { label: 'Min', text: formatter(minY) },
          { label: 'Max', text: formatter(maxY) },
          { label: 'Mean', text: formatter(meanY) },
          {
            label: 'Forecast RMSE',
            text: formatter(rmse),
          },
        ],
      }
    },
    summaryBoxes(): TelemetrySummaryWithChartId[] {
      const boxes = [this.activePowerSummaryBox, this.reactivePowerSummaryBox]

      if (this.showStateOfCharge) {
        boxes.push(this.socSummaryBox)
      }

      if (this.isOperatingEnvelopeEnabled) {
        boxes.push(this.transformerLoadingSummaryBox)
      }

      return boxes
    },
    /* this computed property exists only to be watched */
    shouldResetDataSource() {
      return [this.group, this.resource, this.transformer]
    },
  },
  watch: {
    shouldResetDataSource: {
      immediate: true,
      handler: function (
        newValue: ShouldResetDataSource,
        oldValue: undefined | ShouldResetDataSource
      ) {
        if (
          newValue[0].id !== oldValue?.[0].id || // diff group
          newValue[1].id !== oldValue?.[1].id || // diff resource
          newValue[2]?.id !== oldValue?.[2]?.id // diff transformer
        ) {
          this.resetDataSource()
        }
      },
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
      this.getDataForSummaryBoxes()
    },
    handleNewTimeSeries(): void {
      this.getDataForSummaryBoxes()
    },
    getDataForSummaryBoxes(): void {
      const now = this.$observationTime()
      const newMap = this.dataSource.getTimeSeriesMap()

      this.activePower = getFirstTimeSeriesSummary(
        ACTIVE_POWER_ID,
        newMap,
        this.interval,
        now,
        DATA_POINT_AGE_LIMIT_MINUTES
      )

      // Reactive power.
      this.reactivePower = getFirstTimeSeriesSummary(
        REACTIVE_POWER_ID,
        newMap,
        this.interval,
        now,
        DATA_POINT_AGE_LIMIT_MINUTES
      )

      if (this.showStateOfCharge) {
        this.soc = getFirstTimeSeriesSummary(
          SOC_ID,
          newMap,
          this.interval,
          now,
          DATA_POINT_AGE_LIMIT_MINUTES
        )
      }

      if (this.isOperatingEnvelopeEnabled) {
        this.transformerLoading = getFirstTimeSeriesSummary(
          TRANSFORMER_LOADING_ID,
          newMap,
          this.interval,
          now,
          DATA_POINT_AGE_LIMIT_MINUTES
        )
        // TODO(Isaac): Add charger data to the chart time series map.
        this.transformerLoading.rmse = getRMSEfromTimeSeriesMap(
          newMap,
          this.interval
        )
      }
    },
    resetDataSource(): void {
      const showOperatingEnvelope =
        this.isOperatingEnvelopeEnabled &&
        this.currentOEStatus === OperatingEnvelopeStatus.ACTIVE

      const dsTelemetry = newDeviceTelemetryDataSource(
        this.$services,
        this.group,
        this.resource,
        this.showStateOfCharge,
        this.showReactivePower,
        showOperatingEnvelope,
        this.$observationTime
      )

      if (this.isOperatingEnvelopeEnabled && this.transformer) {
        this.dataSource = new AggregateDataSource(
          dsTelemetry,
          newTransformerDataSource(
            this.$services,
            this.transformer.id,
            this.$observationTime
          )
        )
      } else {
        this.dataSource = dsTelemetry
      }
    },
  },
})
</script>
