// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/forecast.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { TimeSeriesRequest } from "./tsdb_pb.js";
import { CalendarPeriod, Date } from "../../pb/calendar_pb.js";

/**
 * See megaservice/proto/forecast_data_service.proto -> ForecastDataRequest.forecast_slice
 *
 * @generated from enum ritta.frontend.proto.ForecastSliceType
 */
export const ForecastSliceType = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.ForecastSliceType",
  [
    {no: 0, name: "BEST_AVAILABLE_SLICE"},
    {no: 1, name: "FORECAST_RUNTIME_RANGE_SLICE"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.CohortsResponse
 */
export const CohortsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.CohortsResponse",
  () => [
    { no: 1, name: "cohorts", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * Extends TimeSeriesRequest to add the forecast product (model) to use
 *
 * @generated from message ritta.frontend.proto.ForecastRequest
 */
export const ForecastRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ForecastRequest",
  () => [
    { no: 1, name: "timeSeriesReq", kind: "message", T: TimeSeriesRequest },
    { no: 2, name: "product", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "forecastSliceType", kind: "enum", T: proto3.getEnumType(ForecastSliceType) },
  ],
);

/**
 * Proxied to the MS, only difference is that it uses int32 vs int64 used by MS
 * Peak Day by Month or Year
 * Generalization of the GetPeakLoadMonthAnalytics RPC to accommodate both Month and Year analysis
 *
 * @generated from message ritta.frontend.proto.PeakLoadAnalyticsRequest
 */
export const PeakLoadAnalyticsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.PeakLoadAnalyticsRequest",
  () => [
    { no: 1, name: "balancing_area", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "reference_date", kind: "message", T: Date },
    { no: 3, name: "reference_period", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
    { no: 4, name: "number_of_years", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "number_of_highest_peak_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "forecast_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * The peak value of a day in the specified year
 *
 * @generated from message ritta.frontend.proto.PeakLoadValue
 */
export const PeakLoadValue = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.PeakLoadValue",
  () => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "peak_load_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "rank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.PeakLoadAnalytics
 */
export const PeakLoadAnalytics = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.PeakLoadAnalytics",
  () => [
    { no: 1, name: "date", kind: "message", T: Date },
    { no: 2, name: "min_peak_load_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "max_peak_load_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "top_previous", kind: "message", T: PeakLoadValue, repeated: true },
    { no: 5, name: "actual_peak_load_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "forecast_peak_load_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.PeakLoadAnalyticsResponse
 */
export const PeakLoadAnalyticsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.PeakLoadAnalyticsResponse",
  () => [
    { no: 1, name: "values", kind: "message", T: PeakLoadAnalytics, repeated: true },
    { no: 2, name: "reference_period", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
  ],
);

/**
 * @generated from message ritta.frontend.proto.SystemPeakRequest
 */
export const SystemPeakRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakRequest",
  () => [
    { no: 1, name: "products", kind: "message", T: SystemPeakRequest_Products },
    { no: 2, name: "reference_date", kind: "message", T: Date },
    { no: 3, name: "reference_period", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
  ],
);

/**
 * The three forecast products
 *
 * @generated from message ritta.frontend.proto.SystemPeakRequest.Products
 */
export const SystemPeakRequest_Products = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakRequest.Products",
  () => [
    { no: 1, name: "hour", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "megawatt", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "likelihood", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SystemPeakRequest_Products"},
);

/**
 * @generated from message ritta.frontend.proto.SystemPeakDemand
 */
export const SystemPeakDemand = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakDemand",
  () => [
    { no: 1, name: "label", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "message", T: Date },
    { no: 3, name: "peak_hours_forecast", kind: "message", T: Timestamp, repeated: true },
    { no: 7, name: "peak_hours_observed", kind: "message", T: Timestamp, repeated: true },
    { no: 4, name: "forecast_demand", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "actual_demand", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "monthly_peak", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.SystemPeakDemandDetail
 */
export const SystemPeakDemandDetail = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakDemandDetail",
  () => [
    { no: 1, name: "ranked_data", kind: "message", T: SystemPeakDemandDetail_HourlyData, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.SystemPeakDemandDetail.HourlyData
 */
export const SystemPeakDemandDetail_HourlyData = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakDemandDetail.HourlyData",
  () => [
    { no: 1, name: "hour", kind: "message", T: Timestamp },
    { no: 2, name: "forecast_demand", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "percent_of_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "watts_diff_from_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "SystemPeakDemandDetail_HourlyData"},
);

/**
 * @generated from message ritta.frontend.proto.SystemPeakDemandResponse
 */
export const SystemPeakDemandResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SystemPeakDemandResponse",
  () => [
    { no: 1, name: "data", kind: "message", T: SystemPeakDemand, repeated: true },
    { no: 2, name: "details", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: SystemPeakDemandDetail} },
  ],
);

