<template>
  <v-btn
    ref="vBtn"
    :aria-selected="isSelected ? true : undefined"
    v-bind="$attrs"
    :color
    class="px-2"
    style="min-width: 48px"
    :style="{
      backgroundColor,
      borderColor,
      height: $attrs.size === 'default' ? undefined : '40px',
    }"
    variant="outlined"
    v-ripple="{ class: 'text-primary' }"
    @vue:mounted="captureGroup"
  >
    <v-icon
      v-if="computedIcon"
      size="24"
      :color="iconColor"
      :style="{ transform: isResetIcon ? 'rotateY(180deg)' : 'none' }"
    >
      {{ computedIcon }}
    </v-icon>

    <span
      v-if="label"
      class="font-weight-regular text-none pl-2 pr-1"
      :style="{ color: fontColor }"
    >
      {{ label }}
    </span>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import {
  BLUE_50,
  BLUE_100,
  BLUE_200,
  BLUE_300,
  BLUE_600,
  GREY1,
  GREY15,
  PRIMARY,
} from '@/constants/colors'
import type { GroupItemProvide } from '@/types/vuetify3'

export default defineComponent({
  name: 'ButtonSegmented',
  props: {
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  setup() {
    return { BLUE_50, BLUE_100, GREY1 }
  },
  data() {
    return shallowReactive({
      group: null as null | GroupItemProvide,
    })
  },
  computed: {
    isFirst() {
      return this.group?.isFirst.value
    },
    isLast() {
      return this.group?.isLast.value
    },
    isMiddle() {
      return !this.isFirst && !this.isLast
    },
    isSelected() {
      return this.group?.isSelected.value
    },
    isNextSelected(): boolean {
      const self = this.group?.group.items.value.find(
        (item) => item.id === this.group?.id
      )

      const selfIndex = self
        ? this.group?.group.items.value.indexOf(self) ?? -1
        : -1

      if (selfIndex === -1) return false

      const nextIndex = selfIndex + 1

      const nextItem = this.group?.group.items.value[nextIndex]

      return this.group?.group.isSelected(nextItem?.id ?? -1) ?? false
    },
    color() {
      if (this.$attrs.disabled) {
        return 'grey-darken-4'
      } else if (this.isSelected) {
        return BLUE_600.hex
      } else {
        return BLUE_100.hex
      }
    },
    iconColor() {
      if (this.$attrs.disabled) {
        return 'grey-darken-4'
      } else if (this.isSelected) {
        return BLUE_600.hex
      } else {
        return 'primary'
      }
    },
    fontColor() {
      if (this.$attrs.disabled) {
        return GREY15.hex
      } else if (this.isSelected) {
        return BLUE_600.hex
      } else {
        return PRIMARY.hex
      }
    },
    backgroundColor() {
      if (this.$attrs.disabled) {
        return GREY1.hex
      } else if (this.isSelected) {
        return BLUE_200.hex
      } else {
        return BLUE_50.hex
      }
    },
    borderColor() {
      if (this.$attrs.disabled) {
        return 'grey'
      } else if (this.isSelected) {
        return BLUE_300.hex
      } else {
        return BLUE_100.hex
      }
    },
    borderInlineEnd() {
      if (this.isNextSelected) {
        return ''
      }
      return `1px solid ${this.borderColor}`
    },
    borderInlineStart() {
      if (this.isSelected && (this.isMiddle || this.isLast)) {
        return `1px solid ${this.borderColor}`
      }
      return ''
    },
    isResetIcon(): boolean {
      return this.icon === '$reset'
    },
    computedIcon(): string | undefined {
      return this.isResetIcon ? 'mdi-refresh' : this.icon
    },
  },
  methods: {
    captureGroup() {
      const group = (
        this.$refs.vBtn as unknown as undefined | { group?: GroupItemProvide }
      )?.group

      if (group) {
        this.group = group
      }
    },
  },
})
</script>

<style lang="scss" scoped>
// Force border inline to show for the selected button.
.v-btn-group {
  .v-btn:not(:last-child) {
    border-inline-end: v-bind(borderInlineEnd) !important;
  }
  .v-btn:not(:first-child) {
    border-inline-start: v-bind(borderInlineStart) !important;
  }
}
</style>
