// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/analysis.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { CalendarPeriod } from "../../pb/calendar_pb.js";
import { FilterBy, OrderBy } from "./pagination_pb.js";
import { Resource } from "../../pb/resource_pb.js";

/**
 * @generated from message ritta.frontend.proto.DERImpactsRequest
 */
export const DERImpactsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DERImpactsRequest",
  () => [
    { no: 1, name: "component_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "start", kind: "message", T: Timestamp },
    { no: 7, name: "end", kind: "message", T: Timestamp },
    { no: 9, name: "fixed_interval", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "order_by", kind: "message", T: OrderBy },
    { no: 5, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
    { no: 8, name: "model_params", kind: "message", T: ModelMaker },
    { no: 10, name: "search", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ModelMaker
 */
export const ModelMaker = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ModelMaker",
  () => [
    { no: 1, name: "growth_params", kind: "message", T: ModelMaker_AdoptionGrowthParams },
    { no: 2, name: "coincidence_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ModelMaker.AdoptionGrowthParams
 */
export const ModelMaker_AdoptionGrowthParams = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ModelMaker.AdoptionGrowthParams",
  () => [
    { no: 5, name: "ev_annual_growth_rate_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "target_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "pv_annual_growth_rate_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
  {localName: "ModelMaker_AdoptionGrowthParams"},
);

/**
 * @generated from message ritta.frontend.proto.DERImpactsData
 */
export const DERImpactsData = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DERImpactsData",
  () => [
    { no: 1, name: "peak_time", kind: "message", T: Timestamp },
    { no: 2, name: "peak_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 41, name: "peak_load_apparent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "utilization_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "avg_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "load_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 40, name: "time_overloaded_frac", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "num_evs", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "num_evs_charging_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "frac_evs_charging_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 9, name: "ev_adoption_frac", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 38, name: "ev_capacity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 10, name: "ev_load_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 11, name: "ev_load_frac_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 12, name: "max_num_evs_charging", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 13, name: "avg_num_evs_charging", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 14, name: "max_ev_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "avg_ev_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 16, name: "ev_load_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 21, name: "min_time", kind: "message", T: Timestamp },
    { no: 22, name: "min_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 23, name: "min_utilization_rate", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 24, name: "num_pvs", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 25, name: "num_pvs_producing_at_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 26, name: "frac_pvs_producing_at_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 27, name: "pv_adoption_frac", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 39, name: "pv_capacity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 28, name: "pv_production_at_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 29, name: "pv_production_gross_at_min", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 30, name: "max_num_pvs_producing", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 31, name: "avg_num_pvs_producing", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 32, name: "max_pv_production", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 33, name: "avg_pv_production", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 34, name: "pv_production_factor", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 35, name: "num_bess", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 36, name: "bess_adoption_frac", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 37, name: "bess_capacity", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * This has specific values to enable straightforward column binding
 *
 * @generated from message ritta.frontend.proto.DERImpactsRow
 */
export const DERImpactsRow = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DERImpactsRow",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource", kind: "message", T: Resource },
    { no: 3, name: "meters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "residential_frac_meters", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "rating", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "data", kind: "message", T: DERImpactsData },
    { no: 7, name: "modeled", kind: "message", T: DERImpactsData },
  ],
);

/**
 * @generated from message ritta.frontend.proto.DERImpactsTableResponse
 */
export const DERImpactsTableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DERImpactsTableResponse",
  () => [
    { no: 1, name: "rows", kind: "message", T: DERImpactsRow, repeated: true },
    { no: 2, name: "total_rows", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "export_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "request_start", kind: "message", T: Timestamp },
    { no: 5, name: "request_end", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message ritta.frontend.proto.DERImpactsStatsResponse
 */
export const DERImpactsStatsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DERImpactsStatsResponse",
  () => [
    { no: 1, name: "peak_load", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "peak_load_time", kind: "message", T: Timestamp },
    { no: 3, name: "ev_load_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "ev_load_at_peak_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "pv_production_at_peak", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "pv_production_at_peak_percent", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ResourceImpactRequest
 */
export const ResourceImpactRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ResourceImpactRequest",
  () => [
    { no: 1, name: "resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "component_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "start", kind: "message", T: Timestamp },
    { no: 4, name: "end", kind: "message", T: Timestamp },
    { no: 6, name: "fixed_interval", kind: "enum", T: proto3.getEnumType(CalendarPeriod) },
    { no: 5, name: "model_params", kind: "message", T: ModelMaker },
  ],
);

/**
 * @generated from message ritta.frontend.proto.ResourceImpactResponse
 */
export const ResourceImpactResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.ResourceImpactResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "resource", kind: "message", T: Resource },
    { no: 3, name: "meters", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "data", kind: "message", T: DERImpactsData },
    { no: 7, name: "modeled", kind: "message", T: DERImpactsData },
  ],
);

/**
 * @generated from message ritta.frontend.proto.DownlineMetersTableRequest
 */
export const DownlineMetersTableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.DownlineMetersTableRequest",
  () => [
    { no: 1, name: "resource", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "order_by", kind: "message", T: OrderBy },
    { no: 5, name: "filter_by", kind: "message", T: FilterBy, repeated: true },
  ],
);

