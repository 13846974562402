<template>
  <control-template
    :isLoading
    :isRefreshing
    :breadcrumbs
    :navBarItems
    :currentPolicy
    :currentPolicyParams
    :currentOEStatus
    :communicationStatus
    :lastUpdate
  >
    <!-- Control Mode -->
    <control-mode-template
      v-if="device && options.length && currentOEStatus !== undefined"
      v-model="selectedOption"
      :options
      :isLoading
      :isSubmitting
      class="px-6"
      style="min-height: 5rem"
    >
      <!-- Forms -->
      <!-- Envelope Status -->
      <template v-slot:[Option.ENVELOPE_STATUS]>
        <envelope-status-form
          :devices="[device]"
          :currentOEStatus
          is-single-device
          @cancel="cancelOption"
          @submitting="handleFormSubmitting"
          @success="fetchData"
        />
      </template>
    </control-mode-template>

    <!-- Telemetry -->
    <device-telemetry
      v-if="showTelemetryContent && group && device && resource"
      :group
      :device
      :resource
      :isOperatingEnvelopeEnabled
      :currentOEStatus
      :intervalBroadcaster
      style="flex: 1"
    />
  </control-template>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { DateTime } from 'luxon'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import * as RouteNames from '@/router/routeNames'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import {
  Option,
  optionEnvelopeStatus,
  type SelectItem,
} from '@/model/control/controlMode'
import { CommunicationStatusType } from '@/model/control/communicationStatus'
import { getUnqualifiedId } from '@/model/resource'
import {
  getDeviceCurrentEnvelopeStatus,
  isOEEnabled,
  OperatingEnvelopeStatus,
} from '@/model/control/operatingEnvelope'
import type { NavBarItem } from '@/components/common/NavBar.vue'
import ControlTemplate from '@/components/control/ControlTemplate.vue'
import DeviceTelemetry from '@/components/control/device/DeviceTelemetry.vue'
import type { BreadcrumbItem } from '@/components/ResourceHeader'
import ControlModeTemplate from '@/components/control/ControlModeTemplate.vue'
import EnvelopeStatusForm from '@/components/control/EnvelopeStatusForm.vue'
import type { Device, Group } from 'rfs/control/proto/model_pb'
import { type Params, Policy } from 'rfs/control/proto/policy_pb'
import type { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'Device',
  props: {
    groupId: {
      type: String,
      required: true,
    },
    deviceId: {
      type: String,
      required: true,
    },
  },
  components: {
    ControlTemplate,
    DeviceTelemetry,
    ControlModeTemplate,
    EnvelopeStatusForm,
  },
  setup() {
    return { Option, intervalBroadcaster: new IntervalBroadcaster() }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isSubmitting: false,
      isRefreshing: false,
      selectedOption: null as null | Option,
      group: null as null | Group,
      device: null as null | Device,
      resource: null as null | Resource,
      communicationStatus: undefined as undefined | CommunicationStatusType,
      lastUpdate: undefined as undefined | DateTime,
    })
  },
  computed: {
    currentPolicy(): Policy {
      return this.group?.currentPolicy ?? Policy.UNSPECIFIED
    },
    currentPolicyParams(): undefined | Params {
      return this.group?.currentPolicyParams
    },
    isOperatingEnvelopeEnabled(): boolean {
      return isOEEnabled(this.$rittaConfig) && !!this.group?.supportsOes
    },
    currentOEStatus(): undefined | OperatingEnvelopeStatus {
      return this.isOperatingEnvelopeEnabled
        ? getDeviceCurrentEnvelopeStatus(this.device)
        : undefined
    },
    operatingEnvelopeOption(): SelectItem {
      return {
        ...optionEnvelopeStatus,
        // The user should only be authorized to change the OE status when the
        // group  is operating under "Envelopes only", "Temporary Schedule",
        // or "Recurring Schedule".
        props: {
          disabled: this.currentPolicy !== Policy.AUTOMATIC_CONTROL_EVENT,
        },
      }
    },
    options(): SelectItem[] {
      return this.isOperatingEnvelopeEnabled
        ? [this.operatingEnvelopeOption]
        : []
    },
    breadcrumbs(): BreadcrumbItem[] {
      return [
        {
          title: 'Groups',
          to: { name: RouteNames.CONTROL_OVERVIEW },
        },
        {
          title: this.group?.displayName ?? '',
          to: {
            name: RouteNames.CONTROL_GROUP_DETAILS,
            params: { groupId: this.groupId },
          },
        },
        {
          title:
            this.device?.displayName || getUnqualifiedId(this.device?.id ?? ''),
        },
      ]
    },
    navBarItemTelemetry(): NavBarItem {
      const label = 'Telemetry'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_DEVICE_TELEMETRY,
          params: { groupId: this.groupId, deviceId: this.deviceId },
        },
      }
    },
    navBarItemPerformance(): NavBarItem {
      const label = 'Performance'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_DEVICE_PERFORMANCE,
          params: { groupId: this.groupId, deviceId: this.deviceId },
        },
        // TODO(Isaac): Enable when performance is available
        // for LPEA: Groups/Nuvve EVSE: Durango SD 9-R/La Plata (D) #01
        // https://dev.lpea.grid.dev/#/control/group/nuvve-evse/device/charger%2Fnuvve-cd38e120-db49-4f30-977d-dd9816b54d24/performance
        disabled: true,
      }
    },
    navBarItemHistory(): NavBarItem {
      const label = 'History'
      return {
        label,
        id: label,
        to: {
          name: RouteNames.CONTROL_DEVICE_HISTORY,
          params: { groupId: this.groupId, deviceId: this.deviceId },
        },
        disabled: true,
      }
    },
    navBarItems(): NavBarItem[] {
      return [
        this.navBarItemTelemetry,
        this.navBarItemPerformance,
        this.navBarItemHistory,
      ]
    },
    showTelemetryContent(): boolean {
      return this.$route.name === this.navBarItemTelemetry.to.name
    },
    showPerformanceContent(): boolean {
      return this.$route.name === this.navBarItemPerformance.to.name
    },
    showEventsContent(): boolean {
      return this.$route.name === this.navBarItemHistory.to.name
    },
    isCommunicationStatusEnabled(): boolean {
      return !!this.$rittaConfig.control?.communicationStatus
    },
    /* this computed property exists only to be watched */
    computedProps(): [string, string] {
      return [this.groupId, this.deviceId]
    },
  },
  watch: {
    computedProps: {
      immediate: true,
      handler: function (): void {
        this.fetchData()
      },
    },
    operatingEnvelopeOption(newValue: SelectItem): void {
      // Closes the form if the Group's policy changes while the form is open.
      if (
        this.selectedOption === Option.ENVELOPE_STATUS &&
        newValue.props?.disabled
      ) {
        this.cancelOption()
      }
    },
    device(): void {
      // New device.
      this.setNewPageTitle()
    },
    $route(): void {
      // When tabs change.
      this.setNewPageTitle()
    },
  },
  created() {
    this.intervalBroadcaster.subscribe(this.refreshData)
    this.intervalBroadcaster.start()
  },
  beforeUnmount(): void {
    this.intervalBroadcaster.unsubscribe(this.refreshData)
    this.intervalBroadcaster.stop()
  },
  methods: {
    setNewPageTitle(): void {
      if (!this.device) return

      const tabLabel = ((): undefined | string => {
        if (this.showTelemetryContent) {
          return this.navBarItemTelemetry.label
        } else if (this.showPerformanceContent) {
          return this.navBarItemPerformance.label
        } else {
          return undefined
        }
      })()

      const baseTitle = `Device: ${this.device.displayName || this.device.id}`

      useNavigationControlsStore().setPageTitle(
        tabLabel ? `${baseTitle} - ${tabLabel}` : baseTitle
      )
    },
    cancelOption(): void {
      this.selectedOption = null
    },
    handleFormSubmitting(newValue: boolean): void {
      this.isSubmitting = newValue
    },
    async refreshData(): Promise<void> {
      this.fetchData({ refresh: true })
    },
    async fetchData({ refresh } = { refresh: false }): Promise<void> {
      if (refresh) {
        this.isRefreshing = true
      } else {
        this.isLoading = true
        this.group = null
        this.device = null
        this.resource = null
        this.communicationStatus = undefined
        this.lastUpdate = undefined
      }

      try {
        const [group, device] = await Promise.all([
          this.$services.control.getGroup({
            id: this.groupId,
          }),
          this.$services.control.getDevice({
            groupId: this.groupId,
            id: this.deviceId,
          }),
        ])

        this.group = group
        this.device = device

        const resourceId = this.device.camusResourceId

        if (!resourceId) {
          throw new Error('device has no resource ID')
        }

        this.resource = await this.$services.queryService.getResource(
          resourceId
        )

        await this.fetchCommunicationStatus()
      } catch (err) {
        console.error('Device.fetchData: %o', err)
        this.$router.replace({
          name: RouteNames.CONTROL_GROUP_CONTROLS,
          params: { groupId: this.groupId },
        })
      } finally {
        this.isLoading = false
        this.isRefreshing = false
      }
    },
    async fetchCommunicationStatus(): Promise<void> {
      if (!this.isCommunicationStatusEnabled) return

      try {
        // TODO(rafael): fetch real data.
        await new Promise((resolve) => setTimeout(resolve, 1_500))
        this.communicationStatus = CommunicationStatusType.GOOD
      } catch (err) {
        this.communicationStatus = CommunicationStatusType.UNREACHABLE
        console.error('Device.fetchCommunicationStatus: %o', err)
      }

      this.lastUpdate = DateTime.now()
    },
  },
})
</script>
