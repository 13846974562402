// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/alert.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp, Value } from "@bufbuild/protobuf";

/**
 * @generated from enum ritta.frontend.proto.Severity
 */
export const Severity = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.Severity",
  [
    {no: 0, name: "LOW"},
    {no: 1, name: "MEDIUM"},
    {no: 2, name: "HIGH"},
  ],
);

/**
 * @generated from enum ritta.frontend.proto.State
 */
export const State = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.State",
  [
    {no: 0, name: "INACTIVE"},
    {no: 1, name: "ACTIVE"},
    {no: 2, name: "SILENCED"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.Alert
 */
export const Alert = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.Alert",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "enum", T: proto3.getEnumType(State) },
    { no: 5, name: "severity", kind: "enum", T: proto3.getEnumType(Severity) },
    { no: 6, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "start", kind: "message", T: Timestamp },
    { no: 8, name: "end", kind: "message", T: Timestamp },
  ],
);

/**
 * Request a list of all alerts within the specified time range. Response will be aggregated
 *
 * @generated from message ritta.frontend.proto.AlertListRequest
 */
export const AlertListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.AlertListRequest",
  () => [
    { no: 1, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "filter_by", kind: "message", T: AlertListRequest_FilterBy, repeated: true },
    { no: 4, name: "order_by", kind: "message", T: AlertListRequest_OrderBy, repeated: true },
  ],
);

/**
 * @generated from enum ritta.frontend.proto.AlertListRequest.Field
 */
export const AlertListRequest_Field = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.AlertListRequest.Field",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "NAME"},
    {no: 2, name: "DESCRIPTION"},
    {no: 3, name: "STATE"},
    {no: 4, name: "SEVERITY"},
    {no: 5, name: "TYPE"},
    {no: 6, name: "START"},
    {no: 7, name: "END"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.AlertListRequest.FilterBy
 */
export const AlertListRequest_FilterBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.AlertListRequest.FilterBy",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(AlertListRequest_Field) },
    { no: 2, name: "equals", kind: "message", T: Value, oneof: "criteria" },
    { no: 3, name: "contains", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "criteria" },
    { no: 4, name: "before", kind: "message", T: Timestamp, oneof: "criteria" },
    { no: 5, name: "after", kind: "message", T: Timestamp, oneof: "criteria" },
  ],
  {localName: "AlertListRequest_FilterBy"},
);

/**
 * @generated from message ritta.frontend.proto.AlertListRequest.OrderBy
 */
export const AlertListRequest_OrderBy = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.AlertListRequest.OrderBy",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(AlertListRequest_Field) },
    { no: 2, name: "descending", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
  {localName: "AlertListRequest_OrderBy"},
);

/**
 * Response to an AlertListRequest
 *
 * @generated from message ritta.frontend.proto.AlertListResponse
 */
export const AlertListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.AlertListResponse",
  () => [
    { no: 1, name: "alerts", kind: "message", T: Alert, repeated: true },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "offset", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

