import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import SiteLeftPanel from '@/components/SiteLeftPanel.vue'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export function siteRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  if (config.monitor?.site?.enabled) {
    return [
      {
        path: '/site',
        component: Home,
        children: [
          {
            path: ':siteId',
            name: RouteNames.SITE,
            meta: {
              tab: TAB,
              drawerLeftWide: !config.monitor.site.onlyShowDetails,
              pageTitle: (params) => `Site: ${params.siteId}`,
            },
            components: {
              leftPanel: SiteLeftPanel,
            },
            props: {
              leftPanel: true,
            },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
