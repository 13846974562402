<template>
  <base-custom-control class="c-DistributionCustomControl">
    <v-menu
      open-on-hover
      location="top"
      open-delay="0"
      close-delay="0"
      offset="24"
    >
      <!-- Activator -->
      <template v-slot:activator="{ props }">
        <div
          class="d-flex justify-center align-center min-height-100"
          v-bind="props"
        >
          <img :src="iconLegend" width="24px" height="24px" />
        </div>
      </template>

      <!-- Floating menu -->
      <div class="c-DistributionCustomControl__legend">
        <!-- Title -->
        <h2 class="text-subtitle-2 ma-0 pb-2">{{ title }}</h2>

        <!-- Markers & Labels -->
        <ul>
          <li
            v-for="(l, idx) of legends"
            :key="l.label"
            class="d-flex align-center"
            :class="{ 'pb-2': idx + 1 !== legends.length }"
          >
            <!-- Marker -->
            <span
              class="circle-filled"
              :style="{ '--circle-color': l.color }"
              aria-label="marker"
              aria-hidden="true"
            >
              <!--  -->
            </span>

            <!-- Label -->
            <span class="text-body-2" aria-label="label">{{ l.label }}</span>
          </li>
        </ul>
      </div>
    </v-menu>
  </base-custom-control>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { createConductorLegends } from '@/model/resource/conductor/phase'
import BaseCustomControl from '@/components/maps/customControls/BaseCustomControl.vue'
import iconLegend from '@/assets/imgs/albert_ic_legend.svg'

export default defineComponent({
  name: 'DistributionCustomControl',
  components: { BaseCustomControl },
  setup() {
    return { iconLegend, title: 'Conductors' }
  },
  data() {
    return { legends: createConductorLegends(this.$rittaConfig) }
  },
})
</script>

<style lang="scss">
.c-DistributionCustomControl {
  img {
    opacity: 0.9;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }

  &__legend {
    background-color: #ffffff;
    padding: 0.75rem;

    span.circle-filled {
      margin-right: 0.5rem;
      background-color: var(--circle-color);
      border-radius: 50%;
      display: block;
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}
</style>
