import { Message } from '@bufbuild/protobuf'
import { isObjectLike } from 'lodash-es'
import { DateTime, Interval } from 'luxon'

const MARKER = '__class__'

/**
 * Support objects that have a custom prototype, such as Map and Set.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#the_replacer_parameter
 */
export function replaceWithType(this: any, key: string, value: any): any {
  // Some values don't get serialized (e.g. RFS responses)
  if (this[key] instanceof Message) {
    return undefined
  }
  if (value instanceof Map) {
    // Serialize the map as an array of its entries
    return { [MARKER]: 'Map', entries: Array.from(value.entries()) }
  }
  if (value instanceof Set) {
    return { [MARKER]: 'Set', entries: Array.from(value) }
  }
  if (this[key] instanceof DateTime) {
    return { [MARKER]: 'DateTime', iso: value } // Value is from DateTime.toJSON()
  }
  if (this[key] instanceof Interval) {
    return { [MARKER]: 'Interval', iso: value.toISO() }
  }
  return value
}

export function reviveWithType(_key: string, value: any): any {
  // Ignore values that we haven't marked for special processing
  if (!isObjectLike(value) || !(MARKER in value)) {
    return value
  }
  if (value[MARKER] === 'Map') {
    return new Map(value.entries)
  }
  if (value[MARKER] === 'Set') {
    return new Set(value.entries)
  }
  if (value[MARKER] === 'DateTime') {
    return DateTime.fromISO(value.iso)
  }
  if (value[MARKER] === 'Interval') {
    return Interval.fromISO(value.iso)
  }
  return value
}
