import { DateTime, Interval } from 'luxon'
import { App, markRaw, Plugin } from 'vue'
import { RittaConfig } from '@/config'
import { FixedTimeDataSource, TimeSeriesDataSource } from '@/model/charts'
import type { Services } from '@/services'
import { Resource } from 'rfs/pb/resource_pb'
import { TimeSeries } from 'rfs/frontend/proto/tsdb_pb'

export type DictionaryUtil = (value: string) => string

// Declaration of the global plugins available in every `.vue` components.
// Using `@vue/runtime-core` because `vue` doesn't work since pinia upgrade.
declare module 'vue' {
  interface ComponentCustomProperties {
    $rittaConfig: Readonly<RittaConfig>
    $dictionary: DictionaryUtil
    $observationTime: () => DateTime
    $services: Services
  }
}

// Performance Optimizations:
// Disable reactivity for types that are immutable

markRaw(DateTime.prototype)
markRaw(Interval.prototype)

markRaw(Resource.prototype)
markRaw(TimeSeries.prototype)

markRaw(FixedTimeDataSource.prototype)
markRaw(TimeSeriesDataSource.prototype)

/**
 * Instantiates a Vue plugin that injects the config into the global namespace as `$rittaConfig`.
 *
 * @param config the RittaConfig object to be injected
 */
export function newConfigPlugin(config: Readonly<RittaConfig>): Plugin {
  return {
    install: (app: App) => {
      app.config.globalProperties.$rittaConfig = config
    },
  }
}

export function createDictionaryUtil(config: Readonly<RittaConfig>) {
  const dict: RittaConfig['dictionary'] = config.dictionary ?? {}
  return (WORD_KEY: string): string => dict[WORD_KEY] ?? WORD_KEY
}

export function newDictionaryPlugin(config: Readonly<RittaConfig>): Plugin {
  return {
    install: async (app: App) => {
      app.config.globalProperties.$dictionary = createDictionaryUtil(config)
    },
  }
}

/**
 * Instantiates a Vue plugin that injects a fixed or regular clock into the
 * global namespace as $observationTime.
 *
 * @param fixedTime ISO 8601 string or empty for regular clock
 */
export function newObservationTimePlugin(fixedTime: string): Plugin {
  return {
    install: (app: App) => {
      if (fixedTime) {
        app.config.globalProperties.$observationTime = () =>
          DateTime.fromISO(fixedTime)
      } else {
        app.config.globalProperties.$observationTime = () => DateTime.local()
      }
    },
  }
}

export function newServicePlugin(services: Services): Plugin {
  return {
    install: (app: App) => {
      app.config.globalProperties.$services = services
    },
  }
}
