import type { Chart, Plugin } from 'chart.js'
import type { NamedTimeSeries } from '@/model/charts/TimeSeriesDataSource'
import { usePreferencesStore } from '@/stores/preferences'

interface State {
  initialPreferencesApplied: boolean
}

export type PersistDefaultOptions = {
  chartId: string
  // The datasets configuration.
  chartSeries: Readonly<NamedTimeSeries[]>
}

// Maintain private state in a weak map to avoid storing data directly on the Chart instance.
const stateMap = new WeakMap() as WeakMap<Chart, State>

export const PersistPlugin: Plugin<any, PersistDefaultOptions> = {
  id: 'camus/PersistPlugin',
  defaults: {
    chartId: '',
    chartSeries: [],
  },
  start(chart, _, _options) {
    // Initialize the chart state within the WeakMap.
    stateMap.set(chart, { initialPreferencesApplied: false })
  },
  beforeUpdate(chart, _args, { chartId, chartSeries }) {
    const state = stateMap.get(chart)

    // Exit early if there is no state or datasets to process.
    if (!state || !chart.data.datasets.length) return

    const globalStore = usePreferencesStore()

    // Apply user preferences on the initial load.
    if (!state.initialPreferencesApplied) {
      globalStore.syncChartSeriesVisibility({
        chart,
        chartId,
        chartSeries,
        isFirstRun: true,
      })
      state.initialPreferencesApplied = true
    } else {
      // On subsequent updates, sync the dataset visibility with the global state.
      globalStore.syncChartSeriesVisibility({
        chart,
        chartId,
        chartSeries,
      })
    }
  },
}
