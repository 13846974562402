// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file control/proto/waypoints.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Metric } from "../../device/proto/proxy_pb.js";

/**
 * NEXT TAG: 12
 *
 * @generated from message ritta.partner.hce.control.proto.Waypoint
 */
export const Waypoint = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.partner.hce.control.proto.Waypoint",
  () => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "watt_hours", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "target_metric", kind: "enum", T: proto3.getEnumType(Metric), opt: true },
    { no: 5, name: "target_value", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 6, name: "start_time", kind: "message", T: Timestamp },
    { no: 7, name: "end_time", kind: "message", T: Timestamp },
    { no: 8, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 9, name: "schedule_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 10, name: "schedule_waypoint_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
    { no: 11, name: "event_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

