import { RouteRecordRaw, RouteMeta } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import { createFeederRoute, createSubstationRoute } from '@/utils/router/create'
import Home from '@/views/Home.vue'
import SubstationsLeftPanel from '@/components/substations/SubstationsLeftPanel.vue'

export const TAB = TabId.MONITOR

export const TITLE_SUBSTATIONS = 'Substations'

export function substationRoutes(
  config: Readonly<RittaConfig>
): RouteRecordRaw[] {
  if (config.monitor?.substation?.enabled) {
    const baseMeta: RouteMeta = {
      tab: TAB,
      drawerLeftWide: true,
    }

    return [
      {
        path: '/substations',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.SUBSTATIONS,
            meta: {
              ...baseMeta,
              resetMapCenterAndZoom: true,
              pageTitle: TITLE_SUBSTATIONS,
            },
            components: { leftPanel: SubstationsLeftPanel },
          },
          // NOTE: Legacy route.
          {
            path: ':substationId',
            redirect: (to) => {
              const substationId = to.params.substationId
              const unqualifiedId =
                !substationId || Array.isArray(substationId)
                  ? undefined
                  : substationId

              return unqualifiedId
                ? createSubstationRoute(unqualifiedId)
                : { name: RouteNames.SUBSTATIONS }
            },
          },
          // NOTE: Legacy route.
          {
            path: ':substationId/feeders/:feederId',
            redirect: (to) => {
              const feederId = to.params.feederId
              const unqualifiedId =
                !feederId || Array.isArray(feederId) ? undefined : feederId

              return unqualifiedId
                ? createFeederRoute(unqualifiedId)
                : { name: RouteNames.SUBSTATIONS }
            },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
