import { DateTime, Interval } from 'luxon'
import { Timestamp } from '@/services/timestamp_pb'
import { FormFields } from '@/model/forms/FormFields'
import {
  CUT_OFF,
  Metric,
  formatSoc,
  formatPower,
  formatEnergy,
} from '@/model/control/waypoint'
import { ALLOWED_COMMAND_DURATION } from '@/model/control/proxy'
import { Format } from '@/utils/format'
import {
  Custom,
  QuarterHour,
  ISODate,
  ISOTime,
  Float,
  FormPercent,
  Required,
  RequiredWhenNotUndefined,
} from '@/utils/validation/decorators'
import { Validator, validators } from '@/utils/validation/validators'
import {
  createDateTimeFromISOTime,
  intervalToday,
  formatToISOTime,
  formatToISODate,
} from '@/utils/time'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import { ScheduleWaypoint } from 'rfs/control/proto/control_service_pb'
import { DeviceScheduleCommand } from 'rfs/device/proto/proxy_pb'
import { DeviceType } from 'rfs/control/proto/model_pb'

// NOTE: Not all metrics require a quarter hour for "startTime" and "endTime".
export const quarterHourRequired: Metric[] = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS, // Charger.
  Metric.METRIC_SOC_PERCENT, // Battery - SoC.
  Metric.METRIC_SOC_WATT_HOURS, // Battery - watt-hour.
]

const validateDate: Validator<WaypointFormFields> = (
  v,
  fieldName,
  formFields
) => {
  // It's expected that we get a valid ISO date before we run the custom validation.
  if (
    !v ||
    typeof validators[ISODate.name](v, fieldName, formFields, undefined) ===
      'string'
  ) {
    return true
  }

  const dateDt = DateTime.fromISO(v)

  const now = formFields.getNow()

  const maxDt = formFields.getMaxEnd()

  if (dateDt.endOf('day') < intervalToday(now).start) {
    return 'Before Today.'
  } else if (dateDt.endOf('day') > maxDt) {
    return `Must be < ${Interval.fromDateTimes(now, maxDt)
      .toDuration()
      .toFormat('d')} days out.`
  } else {
    return true
  }
}

const validateStartTime: Validator<WaypointFormFields> = (
  v,
  fieldName,
  formFields
) => {
  if (
    // Depends on "date" only if it's a temporary waypoint.
    // If "date" is not valid do not run the "startTime" custom validation.
    formFields.date === undefined ||
    typeof validateDate(formFields.date, 'date', formFields, undefined) ===
      'string' ||
    // It's expected that we get a valid ISO time before we run "startTime" custom validation.
    !v ||
    typeof validators[ISOTime.name](v, fieldName, formFields, undefined) ===
      'string'
  ) {
    return true
  }

  const metric = formFields.getMetric()

  // Quarter hour requirement for some metrics.
  if (quarterHourRequired.includes(metric)) {
    const result = validators[QuarterHour.name](
      v,
      fieldName,
      formFields,
      undefined
    )

    if (typeof result === 'string') return result
  }

  const [hour, minutes] = v.split(':')

  const startDt = DateTime.fromISO(formFields.date).set({
    hour: Number(hour),
    minute: Number(minutes),
  })

  const now = formFields.getNow()

  const minStart = formFields.getMinStart()

  if (startDt <= now.plus(CUT_OFF)) {
    return 'Before NOW.'
  } else if (startDt <= minStart) {
    // When there's a Waypoint currently running. Min start time
    // is going to be the "endTime" of the Waypoint currently running.
    return 'Before allowed start time.'
  } else {
    return true
  }
}

const validateEndTime: Validator<WaypointFormFields> = (
  v,
  fieldName,
  formFields
) => {
  if (
    // For temporary waypoints, this validation depends on "date" being valid first.
    typeof validateDate(formFields.date, 'date', formFields, undefined) ===
      'string' ||
    // Depends on "startTime" being valid first.
    !formFields.startTime ||
    typeof validators[ISOTime.name](
      formFields.startTime,
      'startTime',
      formFields,
      undefined
    ) === 'string' ||
    typeof validateStartTime(
      formFields.startTime,
      'startTime',
      formFields,
      undefined
    ) === 'string' ||
    // It's expected that we get a valid ISO time before we run the custom validation.
    !v ||
    typeof validators[ISOTime.name](v, fieldName, formFields, undefined) ===
      'string'
  ) {
    return true
  }

  const metric = formFields.getMetric()

  // Quarter hour requirement for some metrics.
  if (quarterHourRequired.includes(metric)) {
    const result = validators[QuarterHour.name](
      v,
      fieldName,
      formFields,
      undefined
    )

    if (typeof result === 'string') return result
  }

  // When there's no "date" field, it's Recurring schedule.
  if (formFields.date === undefined) {
    // Works as an anchor.
    const dt = DateTime.now()

    // Start.
    const startDt = createDateTimeFromISOTime(formFields.startTime, dt)

    // End.
    const endDt = createDateTimeFromISOTime(formFields.endTime, dt)

    if (!startDt || !endDt) return true

    if (endDt < startDt) {
      return 'Before start time.'
    } else if (endDt.equals(startDt)) {
      return 'Equal to start time.'
    } else {
      return true
    }
  } else {
    // Date.
    const dateDt = DateTime.fromISO(formFields.date)

    // Start.
    const [startHour, startMinutes] = formFields.startTime.split(':')
    const startDt = dateDt.set({
      hour: Number(startHour),
      minute: Number(startMinutes),
    })

    // End.
    const [endHour, endMinutes] = v.split(':')
    const endDt = dateDt.set({
      hour: Number(endHour),
      minute: Number(endMinutes),
    })

    const now = formFields.getNow()

    if (endDt <= now.plus(CUT_OFF)) {
      return 'Before NOW.'
    } else if (endDt < startDt) {
      return 'Before start time.'
    } else if (endDt.equals(startDt)) {
      return 'Equal to start time.'
    } else if (
      // Nuvve EVSE (LPEA)
      formFields.getMetric() === Metric.METRIC_ACTIVE_POWER_WATTS &&
      formFields.getDeviceType() === DeviceType.NUVVE_EVSE &&
      Interval.fromDateTimes(startDt, endDt).toDuration() >
        ALLOWED_COMMAND_DURATION
    ) {
      return `Nuvve API does not allow a schedule to exceed ${ALLOWED_COMMAND_DURATION.toFormat(
        'h'
      )} hours.`
    } else {
      return true
    }
  }
}

/**
 * Each metric requires it's own validation logic.
 */
const validateTargetValue: Validator<WaypointFormFields> = (
  v,
  fieldName,
  formFields
) => {
  const metric = formFields.getMetric()

  if (
    // Charger.
    metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS ||
    // Battery - emulate.
    metric === Metric.METRIC_SOC_WATT_HOURS
  ) {
    const floatValidation = validators[Float.name](
      v,
      fieldName,
      formFields,
      undefined
    )

    if (typeof floatValidation === 'string') {
      return floatValidation
    }

    const powerAsNum = Number(v)

    return powerAsNum < 0 ? 'Must be above 0.' : true
  } else if (metric === Metric.METRIC_SOC_PERCENT) {
    // Battery.
    return validators[FormPercent.name](v, fieldName, formFields, undefined)
  } else if (metric === Metric.METRIC_ACTIVE_POWER_WATTS) {
    // Nuvve EVSE (LPEA)

    // If it's falsy don't run the custom validation yet.
    if (!v) return true

    const floatValidation = validators[Float.name](
      v,
      fieldName,
      formFields,
      undefined
    )

    if (typeof floatValidation === 'string') return floatValidation

    const powerAsNum = Number(v)

    const powerRating = formFields.getPowerRating()

    if (
      formFields.getDeviceType() === DeviceType.NUVVE_EVSE &&
      powerAsNum === 0
    ) {
      return 'Nuvve API will not accept a 0 kW setpoint.'
    } else if (
      powerRating !== undefined &&
      // kW to watts
      (powerAsNum * 1_000 < -powerRating || powerAsNum * 1_000 > powerRating)
    ) {
      return `Setpoint cannot exceed ± ${Format.fmtWatts(powerRating)}.`
    } else {
      return true
    }
  } else {
    throw new Error(`unexpected "Metric": "${metric}"`)
  }
}

export class WaypointFormFields extends FormFields {
  @RequiredWhenNotUndefined
  @ISODate
  @Custom({ validator: validateDate })
  date?: string

  @Required
  @ISOTime
  @Custom({ validator: validateStartTime })
  startTime: string

  @Required
  @ISOTime
  @Custom({ validator: validateEndTime })
  endTime: string

  @Required
  @Custom({ validator: validateTargetValue })
  targetValue: string

  getMetric: () => Metric
  getDeviceType: () => DeviceType
  getPowerRating: () => undefined | number
  getNow: () => DateTime
  getMinStart: () => DateTime
  getMaxEnd: () => DateTime

  constructor(opts: {
    metric: Metric
    deviceType: DeviceType
    powerRating?: number
    waypoint?: Waypoint
    recurringWaypoint?: ScheduleWaypoint
    deviceScheduleCommand?: DeviceScheduleCommand
    config: {
      getNow: () => DateTime
      getMinStart: () => DateTime
      getMaxEnd: () => DateTime
    }
  }) {
    super()

    const instance =
      opts.waypoint || opts.recurringWaypoint || opts.deviceScheduleCommand

    if (!instance) throw new Error('at least one is expected')

    this.date = getDate(instance)
    this.startTime = getStartTime(instance)
    this.endTime = getEndTime(instance)
    this.targetValue = getTargetValue(opts.metric, instance)

    this.getMetric = () => opts.metric
    this.getDeviceType = () => opts.deviceType
    this.getPowerRating = () => opts.powerRating
    this.getNow = opts.config.getNow
    this.getMinStart = opts.config.getMinStart
    this.getMaxEnd = opts.config.getMaxEnd
  }

  /**
   * Re-creates the temporary waypoint from the input field values.
   */
  generateWaypoint(): Waypoint {
    if (!this.validate().isValid) {
      throw new Error('the values must fist be valid')
    }

    const targetMetric = this.getMetric()

    const waypoint = new Waypoint({ targetMetric })

    const dateDt = DateTime.fromISO(this.date ?? '')

    const [startHours, startMinutes] = this.startTime.split(':')
    const [endHours, endMinutes] = this.endTime.split(':')

    const startDt = dateDt.set({
      hour: Number(startHours),
      minute: Number(startMinutes),
    })
    waypoint.startTime = Timestamp.fromDateTime(startDt)

    const endDt = dateDt.set({
      hour: Number(endHours),
      minute: Number(endMinutes),
    })

    waypoint.endTime = Timestamp.fromDateTime(endDt)

    waypoint.targetValue = (() => {
      switch (targetMetric) {
        case Metric.METRIC_SOC_PERCENT:
          return Number(this.targetValue) / 100
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        case Metric.METRIC_SOC_WATT_HOURS:
        case Metric.METRIC_ACTIVE_POWER_WATTS:
          return Number(this.targetValue) * 1000 // kW -> watts
        default:
          throw new Error('unexpected metric')
      }
    })()

    return waypoint
  }

  /**
   * Re-creates the recurring waypoint from the input field values.
   */
  generateRecurringWaypoint(): ScheduleWaypoint {
    if (!this.validate().isValid) {
      throw new Error('the values must fist be valid')
    }

    const metric = this.getMetric()

    const value = (() => {
      switch (metric) {
        case Metric.METRIC_SOC_PERCENT:
          return Number(this.targetValue) / 100
        case Metric.METRIC_MAX_CHARGE_POWER_WATTS:
        case Metric.METRIC_SOC_WATT_HOURS:
        case Metric.METRIC_ACTIVE_POWER_WATTS:
          return Number(this.targetValue) * 1_000 // convert from kW to watts.
        default:
          throw new Error('unexpected metric')
      }
    })()

    return new ScheduleWaypoint({
      startTime: this.startTime,
      endTime: this.endTime,
      value,
    })
  }

  /**
   * Re-creates the temporary event from the input field values.
   */
  generateEvent(): DeviceScheduleCommand {
    if (!this.validate().isValid) {
      throw new Error('the values must fist be valid')
    }

    const command = new DeviceScheduleCommand({
      metric: this.getMetric(),
    })

    const dateDt = DateTime.fromISO(this.date ?? '')

    const [startHours, startMinutes] = this.startTime.split(':')
    const [endHours, endMinutes] = this.endTime.split(':')

    const startDt = dateDt.set({
      hour: Number(startHours),
      minute: Number(startMinutes),
    })
    command.startTime = Timestamp.fromDateTime(startDt)

    const endDt = dateDt.set({
      hour: Number(endHours),
      minute: Number(endMinutes),
    })

    command.endTime = Timestamp.fromDateTime(endDt)

    command.value = Number(this.targetValue) * 1000 // kW -> watts

    return command
  }
}

/**
 * Grabs the date from the instance and formats for the input field.
 */
function getDate(
  w?: Waypoint | ScheduleWaypoint | DeviceScheduleCommand
): undefined | string {
  if (w instanceof Waypoint || w instanceof DeviceScheduleCommand) {
    return w.startTime ? formatToISODate(w.startTime) : ''
  } else {
    // Recurring waypoints don't have a "date" field.
    return undefined
  }
}

/**
 * Grabs the start time from the instance and formats for the input field.
 */
function getStartTime(
  w?: Waypoint | ScheduleWaypoint | DeviceScheduleCommand
): string {
  if (w instanceof ScheduleWaypoint) {
    return w.startTime // Already in ISO Time format.
  } else if (
    (w instanceof Waypoint || w instanceof DeviceScheduleCommand) &&
    w.startTime
  ) {
    return formatToISOTime(w.startTime)
  } else {
    return ''
  }
}

/**
 * Grabs the end time from the instance and formats for the input field.
 */
function getEndTime(
  w?: Waypoint | ScheduleWaypoint | DeviceScheduleCommand
): string {
  if (w instanceof ScheduleWaypoint) {
    return w.endTime // Already in ISO Time format.
  } else if (
    (w instanceof Waypoint || w instanceof DeviceScheduleCommand) &&
    w.endTime
  ) {
    return formatToISOTime(w.endTime)
  } else {
    return ''
  }
}

/**
 * Grabs the target value from the instance and formats for the input field.
 */
function getTargetValue(
  metric: Metric,
  w?: Waypoint | ScheduleWaypoint | DeviceScheduleCommand
): string {
  const value =
    (w as Waypoint).targetValue ??
    (w as ScheduleWaypoint | DeviceScheduleCommand).value

  switch (metric) {
    case Metric.METRIC_MAX_CHARGE_POWER_WATTS: // Chargers.
    case Metric.METRIC_ACTIVE_POWER_WATTS: // Nuvve EVSE (LPEA) and Battery (VEC).
      return formatPower(value)
    case Metric.METRIC_SOC_PERCENT: // Battery - SoC.
      return formatSoc(value)
    case Metric.METRIC_SOC_WATT_HOURS: // Battery - watt-hour.
      return formatEnergy(value)
    default:
      throw new Error('unexpected metric')
  }
}
