import { NamedString, Resource } from 'rfs/pb/resource_pb'

const EMPTY_ARRAY = Object.freeze([] as any[]) as any[]

/**
 * This function makes the following optimizations to a `Resource`:
 * 1. Assign a single empty array instance to all empty array values.
 * 2. Freeze the object so Vue will not attach observation code.
 * Almost every resource has 9 empty arrays, and since we allocate thousands of resource
 * instances as part of rendering map layers and tables, we can save a great deal of memory
 * consumption by using a single array object for these empty values.
 * 3. Modify `NamedString[]` values to support indexing by name in addition to number.
 */
export function optimizeResource(r: Resource): Resource {
  // Guard against a recursive call
  if (Object.isFrozen(r)) return r
  // For performance, this looks at known array properties,
  // instead of iterating over all keys and looking for arrays.
  if (r.aggregators?.length === 0) {
    r.aggregators = EMPTY_ARRAY
  }
  if (r.field?.length === 0) {
    r.field = EMPTY_ARRAY
  }
  if (r.programs?.length === 0) {
    r.programs = EMPTY_ARRAY
  }
  if (r.reference?.length === 0) {
    r.reference = EMPTY_ARRAY
  }
  if (r.unknown?.length === 0) {
    r.unknown = EMPTY_ARRAY
  }
  if (r.zone?.length === 0) {
    r.zone = EMPTY_ARRAY
  }

  if (r.location != null) {
    const location = r.location

    if (location.census?.length === 0) {
      location.census = EMPTY_ARRAY
    }
    if (location.lineString?.length === 0) {
      location.lineString = EMPTY_ARRAY
    }
    if (location.polygon?.length === 0) {
      location.polygon = EMPTY_ARRAY
    }
  }

  // Enable directly getting properties by name
  if (r.reference !== EMPTY_ARRAY) {
    r.reference = proxyNamedStrings(r.reference)
  }
  if (r.zone !== EMPTY_ARRAY) {
    r.zone = proxyNamedStrings(r.zone)
  }
  if (r.circuit?.classifier != null) {
    r.circuit.classifier = proxyNamedStrings(r.circuit.classifier)
  }

  // Freeze the resource because it's immutable
  Object.freeze(r)

  return r
}

function proxyNamedStrings(target: NamedString[]) {
  return new Proxy(target, {
    get: getNamedString,
    has: (target, prop) => target.some((ns) => ns.name === prop),
  })
}

function getNamedString(target: NamedString[], prop: string | symbol) {
  const ns = target.find((ns) => ns.name === prop)
  // If no match, let the object handle it; it's probably an array index.
  return ns ? ns.value : Reflect.get(target, prop)
}
