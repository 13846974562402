// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file frontend/proto/analysis.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { DERImpactsRequest, DERImpactsStatsResponse, DERImpactsTableResponse, DownlineMetersTableRequest, ResourceImpactRequest, ResourceImpactResponse } from "./analysis_pb.js";
import { MethodKind } from "@bufbuild/protobuf";
import { DownlineResponse } from "./grid_pb.js";

/**
 * @generated from service ritta.frontend.proto.GridAnalysis
 */
export const GridAnalysis = {
  typeName: "ritta.frontend.proto.GridAnalysis",
  methods: {
    /**
     * RPC for the table
     *
     * @generated from rpc ritta.frontend.proto.GridAnalysis.FetchDERImpactsTable
     */
    fetchDERImpactsTable: {
      name: "FetchDERImpactsTable",
      I: DERImpactsRequest,
      O: DERImpactsTableResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RPC for the statistics
     *
     * @generated from rpc ritta.frontend.proto.GridAnalysis.FetchDERImpactsStats
     */
    fetchDERImpactsStats: {
      name: "FetchDERImpactsStats",
      I: DERImpactsRequest,
      O: DERImpactsStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieve data for the grid impact details page
     *
     * @generated from rpc ritta.frontend.proto.GridAnalysis.ResourceDERImpact
     */
    resourceDERImpact: {
      name: "ResourceDERImpact",
      I: ResourceImpactRequest,
      O: ResourceImpactResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.frontend.proto.GridAnalysis.DownlineMetersTable
     */
    downlineMetersTable: {
      name: "DownlineMetersTable",
      I: DownlineMetersTableRequest,
      O: DownlineResponse,
      kind: MethodKind.Unary,
    },
  }
};

