<template>
  <info-column style="width: 16rem" v-bind="computedInfoColumnProps" />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import InfoColumn from '@/components/InfoColumn.vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'

export default defineComponent({
  name: 'InfoWindow',
  props: {
    infoColumnProps: {
      type: Object as PropType<InfoColumnAllProps>,
      required: true,
    },
  },
  components: { InfoColumn },
  computed: {
    computedInfoColumnProps(): InfoColumnAllProps {
      return { ...this.infoColumnProps, name: 'Map marker info window' }
    },
  },
})
</script>
