<template>
  <div class="c-SiteCustomerTab">
    <!-- Service subscription -->
    <info-column
      :title="serviceSubscription.title"
      :items="serviceSubscription.items"
      class="pb-6"
    />
    <ce-divider v-if="showServiceAddress || showBillingAddress" />

    <!-- Service address -->
    <template v-if="showServiceAddress">
      <info-column
        :title="serviceAddress.title"
        :subtitle="serviceAddress.subtitle"
        class="pb-6"
      />
      <ce-divider v-if="showBillingAddress" />
    </template>

    <!-- Billing address -->
    <info-column
      v-if="showBillingAddress"
      :title="billingAddress.title"
      :subtitle="billingAddress.subtitle"
      class="pb-6"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { formatPhoneNumber, formatText } from '@/utils/formatText'
import { Resource } from 'rfs/pb/resource_pb'
import InfoColumn from '@/components/InfoColumn.vue'
import CeDivider from '@/components/others/CeDivider.vue'
import { InfoColumnAllProps } from '@/components/InfoColumn'

export default defineComponent({
  name: 'SiteCustomerTab',
  props: {
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    otherResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: { InfoColumn, CeDivider },
  computed: {
    subscriptionResource(): Resource | undefined {
      return this.otherResources.find(
        (r) => r.type === ResourceType.SUBSCRIPTION_ELECTRICAL
      )
    },
    serviceSubscription(): InfoColumnAllProps {
      const phone = this.subscriptionResource?.phone
      const infoProps = {
        title: 'Service subscription',
        items: [
          {
            label: this.$dictionary('Subscription'),
            text: formatText(
              this.subscriptionResource?.subscription?.electrical
            ),
          },
          {
            label: 'Customer',
            text: formatText(this.subscriptionResource?.billing?.party),
          },
          {
            label: 'Home phone',
            text: formatPhoneNumber(phone?.home || phone?.number),
          },
          {
            label: 'Rate type',
            text: formatText(this.subscriptionResource?.billing?.rate),
          },
        ],
      }

      if (this.$rittaConfig.monitor?.site?.hideCustomerInfo) {
        // Remove 'Customer' and 'Home phone'
        infoProps.items.splice(1, 2)
      }

      return infoProps
    },
    serviceAddress(): InfoColumnAllProps {
      return {
        title: 'Service address',
        subtitle: formatText(this.siteResource?.location?.address),
      }
    },
    showServiceAddress(): boolean {
      return this.serviceAddress.subtitle !== TEXT_NO_VALUE
    },
    billingAddress(): InfoColumnAllProps {
      return {
        title: 'Billing address',
        subtitle: formatText(this.subscriptionResource?.billing?.address),
      }
    },
    showBillingAddress(): boolean {
      return this.billingAddress.subtitle !== TEXT_NO_VALUE
    },
  },
})
</script>
