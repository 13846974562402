import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'
import { NotificationService as Service } from 'rfs/frontend/proto/notification_connect'
import {
  Notification,
  GetNotificationRequest,
  ListNotificationsRequest,
  ListNotificationsResponse,
  MarkAsReadRequest,
  MarkAsReadResponse,
} from 'rfs/frontend/proto/notification_pb'

const EVENT_MARK_AS_RED = 'NOTIFICATION_SERVICE_MARK_AS_READ'

/**
 * Waits for a specified event or resolves when the abort signal is triggered.
 * It will always resolve successfully, even in case of an abort,
 * ensuring that the calling code can proceed without errors.
 */
function waitForEventOrResolve(
  eventName: string,
  signal: AbortSignal
): Promise<void> {
  return new Promise<void>((resolve) => {
    const eventHandler = () => {
      window.removeEventListener(eventName, eventHandler)
      signal.removeEventListener('abort', abortHandler)
      resolve()
    }

    const abortHandler = () => {
      window.removeEventListener(eventName, eventHandler)
      signal.removeEventListener('abort', abortHandler)
      resolve()
    }

    window.addEventListener(eventName, eventHandler)
    signal.addEventListener('abort', abortHandler)
  })
}

// TODO(rafael): delete this class when "streamNotifications" and
// "streamNotification" endpoints implemented in RFS.
export class NotificationService {
  private notificationClient: PromiseClient<typeof Service>

  constructor(transport: Transport) {
    this.notificationClient = createPromiseClient(Service, transport)
  }

  async markAsRead(req: MarkAsReadRequest): Promise<MarkAsReadResponse> {
    const res = await this.notificationClient.markAsRead(req)

    // NOTE(rafael): When the user touches a notification, emit an event
    // for the "stream" methods, so they fetch and deliver up to date
    // data.
    window.dispatchEvent(new Event(EVENT_MARK_AS_RED))

    return res
  }

  // NOTE(rafael): simulates a stream of notifications. It's going
  // to fetch data and wait for the next event.
  async *streamNotifications(
    req: ListNotificationsRequest,
    opts: { signal: AbortSignal }
  ): AsyncIterable<ListNotificationsResponse> {
    while (true) {
      // Abort.
      if (opts.signal.aborted) return

      // Fetch.
      const response = await this.notificationClient.listNotifications(req, {
        signal: opts.signal,
      })
      yield response

      // Wait.
      await waitForEventOrResolve(EVENT_MARK_AS_RED, opts.signal)
    }
  }

  // NOTE(rafael): simulates a stream of a single notification. It's going
  // to fetch data and wait for the next event.
  async *streamNotification(
    req: GetNotificationRequest,
    opts: { signal: AbortSignal }
  ): AsyncIterable<Notification> {
    while (true) {
      // Abort.
      if (opts.signal.aborted) return

      // Fetch.
      const response = await this.notificationClient.getNotification(req, {
        signal: opts.signal,
      })
      yield response

      // Wait.
      await waitForEventOrResolve(EVENT_MARK_AS_RED, opts.signal)
    }
  }
}
