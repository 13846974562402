<template>
  <time-series-chart-group
    :charts
    :dataSource
    :interval
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { Interval } from 'luxon'
import { intervalLast24Hours } from '@/utils/time'
import { AggregateDataSource } from '@/model/charts'
import {
  overviewChartDefinition,
  createOverviewDataSource,
} from '@/model/control/OverviewChartData'
import { TimeSeriesChartGroup } from '@/components/common'
import type { Group } from 'rfs/control/proto/model_pb'

export default defineComponent({
  name: 'OverviewChart',
  props: {
    groups: {
      type: Array as PropType<Group[]>,
      required: true,
    },
  },
  components: { TimeSeriesChartGroup },
  setup() {
    return { charts: [overviewChartDefinition] }
  },
  data() {
    return shallowReactive({
      interval: intervalLast24Hours(this.$observationTime()),
    })
  },
  computed: {
    dataSource() {
      return new AggregateDataSource(
        ...[...this.groups]
          // Sort in alphabetical order.
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          // Create data source.
          .map((group, index) => {
            return createOverviewDataSource(
              this.$services,
              group,
              this.$observationTime,
              index
            )
          })
      )
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
  },
})
</script>
