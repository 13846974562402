import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

import { RittaConfig } from '@/config'
import { setSentryUser } from '@/plugins/sentry'

export type GcpIapIdentityResponse = {
  cookie_name: string
  /**
   * The value looks like: securetoken.google.com/hce-mimo:james@camus.energy
   */
  email: string
  gcp_resource_id: string
  gcp_resource_project_number: number
  gcp_resource_type: string
  sub: string
}

const DEV_ENV_EMAIL = 'someone@camus.energy'

const vouchEmailCache = new Map<string, string>()

async function getUserEmailFromVouch(): Promise<string> {
  const cookieValue = Cookies.get('VouchCookie')

  if (!cookieValue) throw new Error('cookie not available')

  const cachedEmail = vouchEmailCache.get(cookieValue)

  if (cachedEmail) {
    return cachedEmail
  }

  const claims = jwtDecode<Record<string, string>>(cookieValue)
  const email = claims['username']

  if (!email) throw new Error('cookie is empty')

  vouchEmailCache.set(cookieValue, email)

  return email
}

let gcpIapEmailCache: null | string = null

async function getUserEmailFromGcpIap(): Promise<string> {
  if (gcpIapEmailCache) return gcpIapEmailCache

  const resp = await fetch('/?gcp-iap-mode=IDENTITY', {
    credentials: 'include',
  })
  const data: GcpIapIdentityResponse = await resp.json()

  const parts = data.email.split(':')

  if (parts.length !== 2) throw new Error('unexpected string format')

  const email = parts[1]

  gcpIapEmailCache = email
  setSentryUser(gcpIapEmailCache)

  return email
}

export const CeUser = {
  async getUserEmail(config: Readonly<RittaConfig>): Promise<string> {
    if (!import.meta.env.PROD) {
      return DEV_ENV_EMAIL
    } else if (config.auth.type === 'vouch') {
      return getUserEmailFromVouch()
    } else if (config.auth.type === 'gcp-iap') {
      return getUserEmailFromGcpIap()
    } else {
      throw new Error('unexpected auth mode')
    }
  },

  /** A version of getUserEmail that doesn't throw, for use in `main.ts`. */
  async getUserEmailNoThrow(config: Readonly<RittaConfig>): Promise<string> {
    try {
      return await this.getUserEmail(config)
    } catch (e) {
      console.error('[CeUser] getUserEmail failed:', e)
      return ''
    }
  },
}
