import { DateTime, Interval } from 'luxon'
import {
  EventName,
  EventCallbacks,
  ITimeSeriesDataSource,
  TimeSeriesMap,
} from './TimeSeriesDataSource'

/**
 * This data source combines multiple data sources into a single logical data source.
 * If you are creating charts that include series data from multiple different resources,
 * this data source can combine those data into a single time series map.
 */
export class AggregateDataSource implements ITimeSeriesDataSource {
  private readonly dataSources: ITimeSeriesDataSource[] = []

  constructor(...dataSources: ITimeSeriesDataSource[]) {
    for (const ds of dataSources) {
      // If any of these are already an Aggregate, extact the inner data sources
      if (ds instanceof AggregateDataSource) {
        this.dataSources.push(...ds.dataSources)
      } else {
        this.dataSources.push(ds)
      }
    }
  }

  /**
   * Create a new data source by combining this object's data sources
   * with the provided additional sources.
   */
  concat(...dataSources: ITimeSeriesDataSource[]): AggregateDataSource {
    if (dataSources.length === 0) {
      return this
    }
    return new AggregateDataSource(...this.dataSources.concat(dataSources))
  }

  /**
   * Fetches the time series of each child data source in parallel.
   * Right now, any failure causes the entire fetch to fail.
   */
  async fetchTimeSeries(visibleInterval: Interval): Promise<TimeSeriesMap> {
    const fetches = this.dataSources.map((ds) => {
      return ds.fetchTimeSeries(visibleInterval)
    })
    // Use `allSettled` so a data source that throws won't fail all data sources
    await Promise.allSettled(fetches)

    return this.getTimeSeriesMap()
  }

  getTimeSeriesMap(): TimeSeriesMap {
    return this.dataSources
      .map((ds) => ds.getTimeSeriesMap())
      .reduce(mergeTimeSeriesMaps, new Map() as TimeSeriesMap)
  }

  subscribe<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void {
    for (const ds of this.dataSources) {
      ds.subscribe?.(eventName, cb)
    }
  }

  unsubscribe<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void {
    for (const ds of this.dataSources) {
      ds.unsubscribe?.(eventName, cb)
    }
  }

  async updateFromCutOffDate(newEndTime: DateTime): Promise<void> {
    for (const ds of this.dataSources) {
      ds.updateFromCutOffDate?.(newEndTime)
    }
  }
}

/**
 * Combine the two time series maps so that each chart definition has all the
 * time-series from both source and target.
 */
function mergeTimeSeriesMaps(target: TimeSeriesMap, source: TimeSeriesMap) {
  source.forEach((value, key) => {
    if (target.has(key)) {
      target.set(key, target.get(key)!.concat(value))
    } else {
      target.set(key, value)
    }
  })
  return target
}
