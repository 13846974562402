import type { ChartOptions } from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'
import { colorWithOpacity } from '@/utils/colors'
import type { HighlightRange } from '@/types/charts'

export function createHighlightRangeAnnotations(
  highlightRanges: HighlightRange[]
): ChartOptions {
  return {
    plugins: {
      annotation: {
        annotations: highlightRanges.reduce((acc, highlightRange, idx) => {
          acc[`highlightRange-${idx}`] = {
            type: 'box',
            xMin: highlightRange.x1,
            xMax: highlightRange.x2,
            backgroundColor: colorWithOpacity(highlightRange.color, 0.2),
            borderWidth: 0,
            z: -1,
          }

          return acc
        }, {} as { [key: string]: Partial<AnnotationOptions> }),
      },
    },
  }
}
