<template>
  <time-series-chart-group
    :charts="chartDefinitions"
    :data-source="dataSource"
    :interval="interval"
    @new-interval="handleNewInterval"
    @new-time-series="handleNewTimeSeries"
  >
    <!-- Chart selector -->
    <template v-slot:[`after-title-of-${firstChart.id}`]>
      <v-radio-group v-model="selectedRadio" class="pa-0 ma-0 flex-row" inline>
        <v-radio
          v-for="option in radioOpts"
          :key="option"
          :label="option"
          :value="option"
          color="secondary"
          class="mr-2"
        />
      </v-radio-group>
    </template>

    <!-- Summary Boxes -->
    <template
      v-for="(summary, index) of summaryBoxes"
      :key="index"
      v-slot:[`right-side-of-${summary.chartId}`]
    >
      <div
        class="pa-4 position-relative"
        :style="{ width: '300px', backgroundColor: NEUTRAL_100.hex }"
      >
        <info-column v-bind="summary" subtitle-bold />

        <!-- Loading -->
        <v-progress-linear
          v-if="isLoading"
          color="primary"
          location="bottom"
          indeterminate
          absolute
        />
      </div>
    </template>
  </time-series-chart-group>
</template>

<script lang="ts">
import { defineComponent, PropType, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { NEUTRAL_100 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import {
  deviceLoadingChart,
  loadDurationChart,
  derDemandAtPeakLoad,
  derGenerationAtMinLoadChart,
  voltageViolationsChart,
  newResourceImpactDataSource,
  updateChartForResource,
  updateChartWithPeakLoad,
  updateChartWithMinLoad,
  getVoltageViolationStats,
} from '@/model/grid/ResourceImpactChartData'
import { TimeSeriesChartGroup } from '@/components/common'
import InfoColumn from '@/components/InfoColumn.vue'
import type { InfoColumnAllProps } from '@/components/InfoColumn'
import type { Resource } from 'rfs/pb/resource_pb'
import type { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'
import type { ChartDefinition, NumberOrNull } from '@/types/charts'
import { TimeSeriesMap } from '@/model/charts/TimeSeriesDataSource'

type SummaryBox = InfoColumnAllProps & { chartId: string }

const RADIO_OPT_01 = 'Time series'
const RADIO_OPT_02 = 'Load duration'

export default defineComponent({
  name: 'GridImpactResourceCharts',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    impact: {
      type: Object as PropType<ResourceImpactResponse>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { TimeSeriesChartGroup, InfoColumn },
  setup() {
    return { NEUTRAL_100, radioOpts: [RADIO_OPT_01, RADIO_OPT_02] }
  },
  data() {
    return shallowReactive({
      selectedRadio: RADIO_OPT_01,
      violationStats: {
        maxOverVoltage: null as NumberOrNull,
        maxUnderVoltage: null as NumberOrNull,
      },
    })
  },
  computed: {
    showLoadDurationChart(): boolean {
      return this.selectedRadio === RADIO_OPT_02
    },
    firstChart(): ChartDefinition {
      const chartDef = this.showLoadDurationChart
        ? loadDurationChart
        : deviceLoadingChart
      return updateChartForResource(chartDef, this.resource)
    },
    chartDefinitions(): ChartDefinition[] {
      const charts = [
        this.firstChart,
        updateChartWithPeakLoad(derDemandAtPeakLoad, this.impact),
        updateChartWithMinLoad(derGenerationAtMinLoadChart, this.impact),
      ]
      if (this.$rittaConfig.monitor?.site?.voltageChart?.enabled) {
        charts.push(voltageViolationsChart)
      }
      return charts
    },
    dataSource() {
      return newResourceImpactDataSource(this.resource, this.$services)
    },
    summaryBoxes(): SummaryBox[] {
      const data = this.impact?.data
      const { maxOverVoltage, maxUnderVoltage } = this.violationStats

      return [
        {
          chartId: this.firstChart.id,
          subtitle: 'Loading',
          items: [
            {
              label: 'Peak load',
              text: Format.fmtApparentPower(data?.peakLoadApparent),
            },
            {
              label: 'Time overloaded',
              text: Format.fmtPercent(data?.timeOverloadedFrac),
            },
            {
              label: 'Utilization factor',
              text: Format.fmtPercent(data?.utilizationRate),
            },
          ],
        },
        {
          chartId: derDemandAtPeakLoad.id,
          subtitle: derDemandAtPeakLoad.title,
          items: [
            { label: 'Peak load', text: Format.fmtWatts(data?.peakLoad) },
            { label: 'BESS charging', text: TEXT_NO_VALUE },
            { label: 'EV load', text: Format.fmtWatts(data?.evLoadAtPeak) },
          ],
        },
        {
          chartId: derGenerationAtMinLoadChart.id,
          subtitle: derGenerationAtMinLoadChart.title,
          items: [
            { label: 'Min net load', text: Format.fmtWatts(data?.minLoad) },
            { label: 'BESS discharge', text: TEXT_NO_VALUE },
            {
              label: 'PV generation',
              text: Format.fmtWatts(data?.pvProductionAtMin),
            },
          ],
        },
        {
          chartId: voltageViolationsChart.id,
          subtitle: 'Max meters in violation',
          items: [
            {
              label: 'Over voltage',
              text: Format.fmtCount(maxOverVoltage),
            },
            {
              label: 'Under voltage',
              text: Format.fmtCount(maxUnderVoltage),
            },
          ],
        },
      ]
    },
  },
  methods: {
    // The interval state should be on the parent component so the parent
    // component can use it to fetch Impact data.
    handleNewInterval(newInterval: Interval): void {
      this.violationStats = getVoltageViolationStats(
        newInterval,
        this.dataSource
      )
      this.$emit('new-interval', newInterval)
    },
    handleNewTimeSeries(_: TimeSeriesMap): void {
      this.violationStats = getVoltageViolationStats(
        this.interval,
        this.dataSource
      )
    },
  },
})
</script>
