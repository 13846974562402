<template>
  <time-series-chart-group
    :charts="charts"
    :data-source="dataSource"
    :interval="interval"
    @new-interval="handleNewInterval"
  />
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Interval } from 'luxon'
import { ITimeSeriesDataSource } from '@/model/charts'
import { intervalLast7Days } from '@/utils/time'
import { TimeSeriesChartGroup } from '@/components/common'
import {
  outputChartDefinition,
  newUtilitySolarOutputDataSource,
} from '@/model/resource/solar/farm/UtilitySolarChartData'
import { Resource } from 'rfs/pb/resource_pb'

export default defineComponent({
  name: 'UtilitySolarCharts',
  props: {
    utilitySolar: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: { TimeSeriesChartGroup },
  setup() {
    return { charts: [outputChartDefinition] }
  },
  data() {
    return { interval: intervalLast7Days(this.$observationTime()) }
  },
  computed: {
    dataSource(): ITimeSeriesDataSource {
      return newUtilitySolarOutputDataSource(this.$services, this.utilitySolar)
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
  },
})
</script>
