import { ConnectError } from '@connectrpc/connect'
import { PlainMessage } from '@bufbuild/protobuf'

import {
  EnumObjectsResponse,
  StorageObject,
  StorageObject_Type as StorageObjectType,
} from 'rfs/frontend/proto/gcs_pb'
import { defineImmutableStore } from './defineStore'

type CustomImages = Map<string, PlainMessage<StorageObject>>

export interface CustomMapLayer {
  id: string
  title: string
  icon: string
  url: string
}

export interface CustomFilesState {
  customImages: CustomImages
  customMapLayers: CustomMapLayer[]
}

const IMAGE_FOLDER = 'Image/'
const IMAGE_MIMETYPE = /image\/(gif|jpe?g|png|svg\+xml)$/i
const GEOJSON_MIMETYPE = 'application/geo+json'

export const useCustomFiles = defineImmutableStore('customFiles', {
  state(): CustomFilesState {
    return {
      customImages: new Map(),
      customMapLayers: [],
    }
  },
  getters: {
    logoUrl(): string | undefined {
      return this.customImages.get('logo')?.contentUrl
    },
  },
  actions: {
    async loadCustomFiles() {
      try {
        const response = await this.services.fileStorage.enumObjects({
          objectType: StorageObjectType.ALL,
          baseUrl: this.config.rfsEndpoint,
        })

        // Images are storage objects with an image MIME type (e.g. image/png)
        this.customImages = response.objects
          .filter(isImageFile)
          .reduce(toCustomImages, new Map())

        // Map layers are GeoJSON files
        this.customMapLayers = extractMapLayers(response)
      } catch (err) {
        if (err instanceof ConnectError) {
          console.info('RFS instance does not have FileStorage enabled')
        } else {
          console.error('Failed enumerating FileStorage', err)
        }
      }
    },
  },
})

function isImageFile(file: StorageObject) {
  return (
    file.name.startsWith(IMAGE_FOLDER) && IMAGE_MIMETYPE.test(file.contentType)
  )
}

// Make the file name simple so we can reference it from code using a consistent identifier
// (e.g. "Image/logo.png" becomes "logo")
function toCustomImages(acc: CustomImages, file: StorageObject) {
  // Remove the folder name
  let index = file.name.indexOf('/')
  if (index !== -1) file.name = file.name.substring(index + 1)
  // Remove the file extension so we can match files without worrying about file type
  index = file.name.lastIndexOf('.')
  if (index !== -1) file.name = file.name.substring(0, index)

  return acc.set(file.name, file)
}

function extractMapLayers(response: EnumObjectsResponse): CustomMapLayer[] {
  return response.objects
    .filter((file) => file.contentType === GEOJSON_MIMETYPE)
    .map((file) => ({
      id: file.name,
      title: file.title,
      icon: file.icon,
      url: file.contentUrl,
    }))
}

/** Functions exported only for unit tests */
export const Test = {
  isImageFile,
  toCustomImages,
}
