import type { RittaConfig } from '@/config'
import {
  Config_Phasing as ConfigPhasing,
  GridLevel,
  Resource,
} from 'rfs/pb/resource_pb'
import {
  type Color,
  type RGBRaw,
  BLACK,
  BUTTERCUP,
  COPPER,
  DOLPHIN,
  JAFFA,
  VELVET,
  PACIFIC,
  LAGOON,
  rgbaBlack,
} from '@/constants/colors'
import { hexToRgbA } from '@/utils/colors'

const DEFAULT_PHASE_COLOR: Record<ConfigPhasing, Color> = {
  /** * Single phase */
  [ConfigPhasing.A]: LAGOON,
  [ConfigPhasing.B]: JAFFA,
  [ConfigPhasing.C]: PACIFIC,
  /** * Two phase */
  [ConfigPhasing.AB]: VELVET,
  [ConfigPhasing.AC]: VELVET,
  [ConfigPhasing.BC]: VELVET,
  /** * Single phase to neutral */
  [ConfigPhasing.AN]: COPPER,
  [ConfigPhasing.BN]: JAFFA,
  [ConfigPhasing.CN]: BUTTERCUP,
  /** * Three phase */
  [ConfigPhasing.ABC]: DOLPHIN,
  /** * unspecified */
  [ConfigPhasing.UNSPECIFIED]: BLACK,
} as const

export const PHASE_LINE_WEIGHT: Record<ConfigPhasing, number> = {
  /** * Single phase */
  [ConfigPhasing.A]: 2,
  [ConfigPhasing.B]: 2,
  [ConfigPhasing.C]: 2,
  /** * Single phase to neutral */
  [ConfigPhasing.AN]: 2,
  [ConfigPhasing.BN]: 2,
  [ConfigPhasing.CN]: 2,
  /** * Two phase */
  [ConfigPhasing.AB]: 3,
  [ConfigPhasing.AC]: 3,
  [ConfigPhasing.BC]: 3,
  /** * Three phase */
  [ConfigPhasing.ABC]: 4,
  /** * unspecified */
  [ConfigPhasing.UNSPECIFIED]: 2,
} as const

/** Secondary conductors are thinner than primary */
export const SECONDARY_LINE_WEIGHT = 1

type ConductorLegend = {
  color: string
  label: string
}

export function createConductorLegends(
  config?: Readonly<RittaConfig>
): ConductorLegend[] {
  const { twoPhase, threePhase, phaseA, phaseB, phaseC } =
    config?.customer.conductors ?? {}

  return [
    {
      color: threePhase ?? DEFAULT_PHASE_COLOR[ConfigPhasing.ABC].hex,
      label: '3-phase',
    },
    {
      // NOTE(rafael): using "AB" but it could also be "AC" or "BC".
      color: twoPhase ?? DEFAULT_PHASE_COLOR[ConfigPhasing.AB].hex,
      label: '2-phase',
    },
    {
      color: phaseA ?? DEFAULT_PHASE_COLOR[ConfigPhasing.A].hex,
      label: 'Phase A',
    },
    {
      color: phaseB ?? DEFAULT_PHASE_COLOR[ConfigPhasing.B].hex,
      label: 'Phase B',
    },
    {
      color: phaseC ?? DEFAULT_PHASE_COLOR[ConfigPhasing.C].hex,
      label: 'Phase C',
    },
  ]
}

export function createPhaseColorRgbObject(
  config: Readonly<RittaConfig>
): Record<ConfigPhasing, null | undefined | RGBRaw> {
  const { twoPhase, threePhase, phaseA, phaseB, phaseC } =
    config?.customer.conductors ?? {}

  return {
    /** * Single phase */
    [ConfigPhasing.A]: phaseA
      ? hexToRgbA(phaseA)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.A].rgba,
    [ConfigPhasing.B]: phaseB
      ? hexToRgbA(phaseB)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.B].rgba,
    [ConfigPhasing.C]: phaseC
      ? hexToRgbA(phaseC)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.C].rgba,
    /** * Single phase to neutral */
    [ConfigPhasing.AN]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.AN].rgba,
    [ConfigPhasing.BN]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.BN].rgba,
    [ConfigPhasing.CN]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.CN].rgba,
    /** * Two phase */
    [ConfigPhasing.AB]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.AB].rgba,
    [ConfigPhasing.AC]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.AC].rgba,
    [ConfigPhasing.BC]: twoPhase
      ? hexToRgbA(twoPhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.BC].rgba,
    /** * Three phase */
    [ConfigPhasing.ABC]: threePhase
      ? hexToRgbA(threePhase)
      : DEFAULT_PHASE_COLOR[ConfigPhasing.ABC].rgba,
    /** * unspecified */
    [ConfigPhasing.UNSPECIFIED]: rgbaBlack,
  }
}

export function getPhase(r: Resource): ConfigPhasing {
  return (
    r.config?.phasing || r.grid?.config?.phasing || ConfigPhasing.UNSPECIFIED
  )
}

export function formatPhase(r: Resource | undefined): string {
  const config = r?.config ?? r?.grid?.config
  return config?.phasing ? ConfigPhasing[config.phasing] : ''
}

export function isSecondary(r: Resource): boolean {
  return r.grid?.level === GridLevel.DISTRIBUTION_SECONDARY
}
