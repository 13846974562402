import { ColumnType } from '@/model/tables/column'
import type { Header, DataTable, Options } from '@/model/tables/DataTable'
import { MeterStats } from '@/model/transformer'
import { createSiteRoute } from '@/utils/router/create'
import {
  DownlineResponse,
  type DownlineResponse_TableRow as ResponseTableRow,
} from 'rfs/frontend/proto/grid_pb'
import type { Resource } from 'rfs/pb/resource_pb'

export const MeterImpactTableResponse = DownlineResponse

export type ResourceDownlineMetersRow = ResponseTableRow

export interface MeterImpactDataTable
  extends DataTable<ResourceDownlineMetersRow> {}

export type DownlineMetersRow = {
  id: string
  resource: Resource
  ratingsBattery: number
  ratingsCharger: number
  ratingsSolar: number
  maxPower?: number
  meanPower?: number
}

export interface DownlineMetersDataTable extends DataTable<DownlineMetersRow> {}

type Dictionary = (id: string) => string

export function createHeaders(
  dictionary: Dictionary,
  isTransformer: boolean = false
): Header[] {
  const headers: Header<ResourceDownlineMetersRow | DownlineMetersRow>[] = [
    {
      title: dictionary('Site ID'),
      key: 'resource.site.id',
      routeFactory(_config, row) {
        const r = row.resource
        return r ? createSiteRoute(r) : undefined
      },
    },
    {
      title: dictionary('Service meter'),
      key: 'resource.meter.electrical',
    },
    {
      title: 'Peak Power (kW)',
      key: 'maxPower',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'Average (kW)',
      key: 'meanPower',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'BESS (kW)',
      key: 'ratingsBattery',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'EVSE (kW)',
      key: 'ratingsCharger',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
    {
      title: 'PV (kW)',
      key: 'ratingsSolar',
      valueType: ColumnType.KILO_W,
      align: 'end',
    },
  ]

  const transformerHeaders = ['maxPower', 'meanPower']

  // Remove transformer headers if not a transformer
  return isTransformer
    ? headers
    : headers.filter((h) => !transformerHeaders.includes(h.key))
}

export function createDownlineMetersTableRows(
  response: DownlineResponse,
  meterStats: MeterStats,
  options: Options
): DownlineMetersRow[] {
  const rows: DownlineMetersRow[] = []

  for (const row of response.rows) {
    if (!row.resource) continue

    const { resource, ratingsBattery, ratingsCharger, ratingsSolar } = row

    const { maxPower, meanPower } = meterStats[resource.id] ?? {}

    rows.push({
      id: resource.id,
      resource,
      ratingsBattery,
      ratingsCharger,
      ratingsSolar,
      maxPower,
      meanPower,
    })
  }

  // Sort.
  const { column, descending } = options.orderBy

  rows.sort((a, b) => {
    const valueA = a[column as keyof DownlineMetersRow]
    const valueB = b[column as keyof DownlineMetersRow]

    // When column being sorted has string value.
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return descending
        ? valueB.localeCompare(valueA)
        : valueA.localeCompare(valueB)
    }

    // When column being sorted has numerical value.
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return descending ? valueB - valueA : valueA - valueB
    }

    return 0
  })

  return rows
}
