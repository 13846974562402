<template>
  <section class="position-relative min-height-100" aria-label="Control Mode">
    <!-- Loading -->
    <centered-spinner v-if="isLoadingPermission || isLoading" />

    <!-- Content -->
    <template v-else>
      <!-- Loading failed -->
      <alert-try-again
        v-if="msgTryAgain"
        :msg="msgTryAgain"
        class="mb-12"
        @try-again="handleTryAgain"
      />

      <!-- Controls -->
      <div
        class="d-flex position-relative px-4 py-5 mb-12"
        :style="{ backgroundColor }"
      >
        <!-- Submitting loading bar -->
        <v-progress-linear
          v-if="isSubmitting"
          color="primary"
          indeterminate
          absolute
        />

        <!-- Control mode selector -->
        <div class="pr-8" style="min-width: 17.5rem">
          <v-select
            :modelValue
            :items="options"
            :label="SELECT_LABEL"
            :disabled="isSelectDisabled"
            :menu-props="{ maxHeight: 'none' }"
            color="secondary"
            @update:model-value="
              (newValue: Option) => $emit('update:model-value', newValue)
            "
          >
            <template v-slot:item="all">
              <!-- Divider -->
              <v-divider
                v-if="shouldShowDivider(all.item)"
                class="my-4"
                :color="dividerColor"
                style="opacity: 1"
              />

              <v-list-item role="option" v-bind:="all.props" />
            </template>
          </v-select>
        </div>

        <!-- Forms -->
        <div style="flex: 1">
          <div class="d-flex align-center min-height-100">
            <!-- Unauthorized User -->
            <span v-if="!isAuthorizedUser" class="d-block">
              {{ notAuthorizedUserMsg }}
            </span>

            <!-- Authorized user -->
            <div v-else class="w-100">
              <slot v-if="modelValue" :name="modelValue"> </slot>
            </div>
          </div>
        </div>
      </div>

      <!-- Default slot -->
      <slot />
    </template>
  </section>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { NEUTRAL_100, NEUTRAL_50 } from '@/constants/colors'
import {
  SELECT_LABEL,
  Option,
  type SelectItem,
} from '@/model/control/controlMode'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import AlertTryAgain from '@/components/common/AlertTryAgain.vue'

export default defineComponent({
  name: 'ControlModeTemplate',
  props: {
    /* v-model */
    modelValue: {
      type: [null, String] as PropType<null | Option>,
      required: true,
    },
    options: {
      type: Array as PropType<SelectItem[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false,
    },
    msgLoadingFailed: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['try-again', 'update:model-value'],
  components: { CenteredSpinner, AlertTryAgain },
  setup() {
    return {
      SELECT_LABEL,
      Option,
      backgroundColor: NEUTRAL_50.hex,
      dividerColor: NEUTRAL_100.hex,
      msgFailedLoadingPermission:
        'Something happened while checking permission.',
      notAuthorizedUserMsg: "You don't have permission to change control mode.",
    }
  },
  data() {
    return shallowReactive({
      isLoadingPermission: false,
      hasLoadingPermissionFailed: false,
      isAuthorizedUser: false,
    })
  },
  computed: {
    msgTryAgain(): string {
      const msgToDisplay = this.hasLoadingPermissionFailed
        ? this.msgFailedLoadingPermission
        : this.msgLoadingFailed

      if (msgToDisplay) {
        return `${msgToDisplay} [${this.$observationTime().toUTC()}]`
      } else {
        return ''
      }
    },
    hasAllOptionsDisabled(): boolean {
      return this.options.every((opt) => !!opt.props?.disabled)
    },
    isSelectDisabled(): boolean {
      return (
        !this.isAuthorizedUser ||
        this.isSubmitting ||
        this.hasAllOptionsDisabled
      )
    },
  },
  created() {
    this.fetchPermission()
  },
  methods: {
    shouldShowDivider(item: SelectItem): boolean {
      // Never add a divider when there's only one available option.
      if (this.options.length === 1) return false

      return item.value === Option.ENVELOPE_STATUS
    },
    handleTryAgain(): void {
      if (this.hasLoadingPermissionFailed) {
        this.fetchPermission()
      } else {
        this.$emit('try-again')
      }
    },
    async fetchPermission(): Promise<void> {
      this.isLoadingPermission = true
      this.hasLoadingPermissionFailed = false

      try {
        const { value } = await this.$services.control.canSetGroupPolicy({})
        this.isAuthorizedUser = value
      } catch (err) {
        this.hasLoadingPermissionFailed = true
        console.error('ControlModeTemplate.fetchPermission: %o', err)
      } finally {
        this.isLoadingPermission = false
      }
    },
  },
})
</script>
