import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import {
  Columns,
  LogRow,
  HistoryDataTable,
  formatDateTime,
  createSetPolicyRow,
  createSubmitWaypointRow,
} from '@/model/control/history'
import { GroupHelper } from '@/model/control/group/helper'
import { sortByStartTime } from '@/model/control/waypoint'
import { InfoColumnGroup } from '@/model/types'
import {
  GroupLogEntry,
  LogDeviceScheduleEvent,
  SetPolicyEvent,
  SubmitWaypointsEvent,
} from 'rfs/control/proto/log_pb'
import { Group } from 'rfs/control/proto/model_pb'
import { Policy } from 'rfs/control/proto/policy_pb'
import { DeviceScheduleCommand } from 'rfs/device/proto/proxy_pb'
import { Timestamp } from '@/services/timestamp_pb'

export function createGroupHistoryDataTable(
  group: Group,
  logs: GroupLogEntry[]
): HistoryDataTable {
  const rows: LogRow[] = []

  for (const log of logs) {
    const createdAt = log.time
    if (!createdAt) continue

    const { typeUrl, value } = log.payload ?? {}
    if (!typeUrl || !value) continue

    if (typeUrl.includes(SetPolicyEvent.name)) {
      const r = createSetPolicyRow(createdAt, group.displayName, value)
      if (r) rows.push(r)
    } else if (typeUrl.includes(SubmitWaypointsEvent.name)) {
      rows.push(createSubmitWaypointRow(createdAt, group.displayName, value))
    } else if (typeUrl.includes(LogDeviceScheduleEvent.name)) {
      rows.push(createDeviceScheduleRow(createdAt, group.displayName, value))
    } else {
      console.debug('createGroupHistoryDataTable: "typeUrl" not expected')
      continue
    }
  }

  return { rows }
}

function createDeviceScheduleRow(
  createdAt: Timestamp,
  displayName: string,
  binary: Uint8Array
): LogRow {
  const { eventId, principal, request } =
    LogDeviceScheduleEvent.fromBinary(binary)

  const events = (request.case === 'sendSchedule' ? request.value : null)
    ?.commands

  const sortedEvents = events ? sortByStartTime(events) : null

  return {
    id: eventId,
    ...(sortedEvents && { infoColumnGroup: createEventsDetails(sortedEvents) }),
    [Columns.CREATED_AT]: createdAt.toMillis(), // millis
    [Columns.GROUP_NAME]: displayName,
    [Columns.USER_EMAIL]: principal !== '' ? principal : TEXT_NO_VALUE,
    [Columns.POLICY]:
      request.case === 'sendSchedule'
        ? GroupHelper.getPolicyLabel(Policy.AUTOMATIC_CONTROL_EVENT)
        : 'Events deleted',
    [Columns.POLICY_PARAMS]: TEXT_NO_VALUE,
  }
}

function createEventsDetails(events: DeviceScheduleCommand[]): InfoColumnGroup {
  return events.map((event, idx) => {
    return {
      id: idx.toString(),
      infoColumn: {
        items: [
          {
            label: `Event ${idx}`,
            text: Format.fmtWatts(event.value) || TEXT_NO_VALUE,
          },
          {
            label: 'Start time',
            text: formatDateTime(event.startTime?.toMillis()),
          },
          {
            label: 'End time',
            text: formatDateTime(event.endTime?.toMillis()),
          },
        ],
      },
    }
  })
}
