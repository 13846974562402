<template>
  <div class="d-flex flex-column position-relative min-height-100 pa-6">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <div v-else class="d-flex flex-column" style="flex: 1">
      <!-- Breadcrumbs -->
      <resource-header :breadcrumbs small stand-out-last-item class="pb-6" />

      <!-- Summary -->
      <section
        class="d-flex py-6 rounded-lg mb-6"
        :style="{ border: `1px solid ${NEUTRAL_200.hex}` }"
      >
        <!-- Title -->
        <div class="px-6" style="width: 19.5rem">
          <ce-title v-if="computedTitle">{{ computedTitle }}</ce-title>
        </div>
        <!-- Description -->
        <div class="px-6" style="flex: 1">
          <p v-if="computedDescription" class="text-body-1">
            {{ computedDescription }}
          </p>
        </div>
        <!-- Mark as Read -->
        <div class="d-flex justify-end px-6" style="width: 28.5rem">
          <button-segmented
            :loading="isTogglingReadState"
            :icon="
              notification?.read
                ? 'mdi-bell-ring-outline'
                : 'mdi-check-circle-outline'
            "
            :label="notification?.read ? 'Mark as unread' : 'Mark as read'"
            @click="toggleReadState"
          />
        </div>
      </section>

      <!-- Body -->
      <section class="d-flex" style="flex: 1">
        <!-- Details -->
        <div class="pr-6 ce-border-right" style="width: 19.5rem">
          <p class="text-subtitle-1 font-weight-medium">Details</p>
          <info-column-group :group="details" />
        </div>

        <!-- Charts -->
        <div v-if="showChartAndDers" class="px-6" style="flex: 1">
          <notification-charts v-if="notification" :notification :resource />
        </div>

        <!-- DERs -->
        <div
          v-if="showChartAndDers"
          class="pl-6 ce-border-left"
          style="width: 28.5rem"
        >
          <notification-ders v-if="notification" :notification />
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { DateTime } from 'luxon'
import { NEUTRAL_200 } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import * as RouteNames from '@/router/routeNames'
import { routeForResource } from '@/router/routeForResource'
import { createFeederRoute, createSubstationRoute } from '@/utils/router/create'
import {
  getResponseLabel,
  getSeverityLabel,
  getStateLabel,
  getNotificationDescription,
  getNotificationTitle,
  getThingAffected,
} from '@/model/notification/formatting'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ResourceHeader from '@/components/ResourceHeader.vue'
import CeTitle from '@/components/CeTitle.vue'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import NotificationCharts from '@/components/monitor/NotificationCharts.vue'
import NotificationDers from '@/components/monitor/NotificationDers.vue'
import type { BreadcrumbItem } from '@/components/ResourceHeader'
import InfoColumnGroup from '@/components/InfoColumnGroup.vue'
import type { Group } from '@/components/InfoColumnGroup'
import { ResourceType } from '@/constants/resourceType'
import {
  GetNotificationRequest,
  MarkAsReadRequest,
  Notification,
} from 'rfs/frontend/proto/notification_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { formatDt } from '@/utils/time'

export default defineComponent({
  name: 'NotificationDetails',
  props: {
    notificationId: {
      type: String,
      required: true,
    },
  },
  components: {
    CenteredSpinner,
    ResourceHeader,
    CeTitle,
    ButtonSegmented,
    InfoColumnGroup,
    NotificationCharts,
    NotificationDers,
  },
  setup() {
    return { NEUTRAL_200, abortController: new AbortController() }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      isTogglingReadState: false,
      notification: undefined as undefined | Notification,
      resource: undefined as undefined | Resource,
    })
  },
  computed: {
    breadcrumbs(): BreadcrumbItem[] {
      return [
        {
          title: 'Notifications',
          to: { name: RouteNames.NOTIFICATIONS },
        },
        { title: getResponseLabel(this.notification?.response) },
      ]
    },
    computedTitle() {
      return getNotificationTitle(this.notification)
    },
    computedDescription() {
      return getNotificationDescription(this.notification)
    },
    details(): Group {
      const timezoneSuffix = DateTime.now().toFormat('(ZZZZ)')

      // 1
      const group: Group = [
        {
          id: '01',
          infoColumn: {
            subtitle: '',
            items: [
              {
                label: 'Condition',
                text: this.notification?.condition ?? TEXT_NO_VALUE,
              },
              {
                label: 'Response',
                text: getResponseLabel(this.notification?.response),
              },
              {
                label: 'State',
                text: getStateLabel(this.notification?.state),
              },
              {
                label: `Start time ${timezoneSuffix}`,
                text: formatDt(this.notification?.startTime),
              },
              {
                label: `End time ${timezoneSuffix}`,
                text: formatDt(this.notification?.endTime),
              },
            ],
          },
        },
      ]

      if (this.resource) {
        const substationUnqualifiedId = this.resource?.upline?.substation
        const feederUnqualifiedId = this.resource?.upline?.feeder

        // 2
        group.push({
          id: '02',
          infoColumn: {
            subtitle: '',
            items: [
              {
                label: 'Substation',
                text: substationUnqualifiedId ?? TEXT_NO_VALUE,
                textLink: substationUnqualifiedId
                  ? createSubstationRoute(substationUnqualifiedId)
                  : undefined,
              },
              {
                label: 'Feeder',
                text: feederUnqualifiedId ?? TEXT_NO_VALUE,
                textLink: feederUnqualifiedId
                  ? createFeederRoute(feederUnqualifiedId)
                  : undefined,
              },
              {
                label: 'Resource',
                text: getThingAffected(this.notification),
                textLink: this.resource
                  ? routeForResource(this.resource)
                  : undefined,
              },
            ],
          },
        })
      }

      // 3
      group.push({
        id: '03',
        infoColumn: {
          subtitle: '',
          items: [
            {
              label: 'Created (EDT)',
              text: formatDt(this.notification?.createdAt),
            },
            {
              label: 'Severity',
              text: getSeverityLabel(this.notification?.severity),
            },
          ],
        },
      })

      return group
    },
    showChartAndDers(): boolean {
      return this.resource?.type === ResourceType.TRANSFORMER
    },
  },
  watch: {
    notificationId: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
  },
  beforeUnmount(): void {
    this.abortController.abort()
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.notification = undefined
      this.resource = undefined

      try {
        const iter = this.$services.notificationService.streamNotification(
          new GetNotificationRequest({
            id: this.notificationId,
          }),
          { signal: this.abortController.signal }
        )

        for await (const response of iter) {
          this.notification = response

          // Fetch resource when available.
          if (this.notification.resourceId) {
            await this.fetchResource(this.notification.resourceId)
          }

          this.isLoading = false
        }
      } catch (err) {
        console.error('NotificationDetails.fetchData: %o', err)
        this.$router.replace({ name: RouteNames.NOTIFICATIONS })
      } finally {
        this.isLoading = false
      }
    },
    async fetchResource(resourceId: string): Promise<void> {
      if (this.resource?.id === resourceId) return

      try {
        this.resource = await this.$services.queryService.getResource(
          resourceId
        )
      } catch (err) {
        console.error('NotificationDetails.fetchResource: %o', err)
      }
    },
    async toggleReadState(): Promise<void> {
      if (!this.notification) return

      this.isTogglingReadState = true

      try {
        await this.$services.notificationService.markAsRead(
          new MarkAsReadRequest({
            ids: [this.notification.id],
            read: !this.notification.read,
          })
        )
      } catch (err) {
        console.error('NotiticationDetails.toggleReadState: %o', err)
      } finally {
        this.isTogglingReadState = false
      }
    },
  },
})
</script>
