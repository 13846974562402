<template>
  <div class="position-relative min-height-100">
    <!-- Title -->
    <ce-title class="px-6 pb-6">{{ title }}</ce-title>

    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Table -->
    <v-data-table
      v-else
      class="bg-transparent text-caption"
      :headers="headers"
      :items="dataTableItems"
      :row-props="createRowProps"
      v-model:options="dataTableOptions"
      :items-per-page="-1"
      density="compact"
      select-strategy="single"
      @click:row="handleRowClick"
    >
      <!-- this is a 'dynamic slot' of v-data-table,
      and allows us to customize the rendering of the
      'updated' column-->
      <template v-slot:[`item.updated`]="{ item }">
        {{ dateString(item[DataTableColumn.UPDATED]) }}
      </template>

      <!-- NOTE(rafael): hides the footer -->
      <template v-slot:bottom />
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions } from 'pinia'
import { useGridMapStore } from '@/stores/gridMap'
import { DataTableHeader } from '@/types/vuetify3'
import { Timestamp } from '@/services/timestamp_pb'
import { DataTableColumn, DataTableColumnName } from '@/constants/fleetVehicle'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import { FleetVehicleHelper } from '@/model/fleet/vehicle/helper'
import { FleetVehicle } from 'rfs/frontend/proto/fleet_pb'
import * as RouteNames from '@/router/routeNames'
import CeTitle from '@/components/CeTitle.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import { MapLayerGroupId, MapLayerId } from '@/config/types'
import { Resource } from 'rfs/pb/resource_pb'

type DataTableItem = {
  [DataTableColumn.ID]: string
  [DataTableColumn.NAME]: string
  [DataTableColumn.GROUP]: string
  [DataTableColumn.UPDATED]: Timestamp | undefined
}

function sortUpdated(a?: Timestamp, b?: Timestamp) {
  return (b?.toMillis() ?? 0) - (a?.toMillis() ?? 0)
}

export default defineComponent({
  name: 'FleetPanel',
  props: {
    vehicleId: {
      type: String,
      required: false,
    },
  },
  components: { CeTitle, CenteredSpinner },
  setup() {
    const headers: DataTableHeader[] = [
      {
        title: DataTableColumnName[DataTableColumn.NAME],
        key: DataTableColumn.NAME,
        maxWidth: '100px',
      },
      {
        title: DataTableColumnName[DataTableColumn.GROUP],
        key: DataTableColumn.GROUP,
        maxWidth: '100px',
      },
      {
        title: DataTableColumnName[DataTableColumn.UPDATED],
        key: DataTableColumn.UPDATED,
        minWidth: '130px',
        sort: sortUpdated,
      },
    ]

    return {
      DataTableColumn,
      title: 'Fleet',
      headers,
      dateString: FleetVehicleHelper.formatUpdated,
      gridMapStore: useGridMapStore(),
    }
  },
  data() {
    return {
      isLoading: false,
      fleetVehicle: null as FleetVehicle | null,
      dataTableOptions: {}, // NOTE(rafael): required so VDataTable can save its
      // "options" state and don't reset the table sorting when changing routes.
      allFleetVehicles: [] as FleetVehicle[],
    }
  },
  computed: {
    dataTableItems(): DataTableItem[] {
      return (this.allFleetVehicles as FleetVehicle[]).map((fv) => {
        return {
          [DataTableColumn.ID]: fv.id,
          [DataTableColumn.NAME]: fv.name,
          [DataTableColumn.GROUP]: fv.groupName,
          [DataTableColumn.UPDATED]: fv.updated,
        }
      })
    },
  },
  watch: {
    vehicleId: {
      immediate: true,
      handler: function () {
        this.findFleetVehicle()
      },
    },
    fleetVehicle(): void {
      this.handleMapInteractions()
    },
  },
  methods: {
    ...mapActions(useGridMapStore, ['updateSwitches']),
    // When the user clicks one of the vehicles, we should highlight the row
    // with a white background-clor.
    createRowProps(row: { item: DataTableItem }): { [key: string]: any } {
      return row.item.id === this.vehicleId ? { class: 'bg-white' } : {}
    },
    async findFleetVehicle(): Promise<void> {
      this.isLoading = true
      this.fleetVehicle = null

      try {
        if (!this.allFleetVehicles.length) {
          this.allFleetVehicles =
            await this.$services.fleetService.fetchVehicles()
        }

        const fleetVehicle = this.allFleetVehicles.find(
          (fv) => fv.id === this.vehicleId
        )

        const store = useNavigationControlsStore()

        if (!fleetVehicle) {
          store.setPageTitle('Fleet')
          throw new Error('fleet vehicle not found')
        } else {
          store.setPageTitle(`Fleet: ${fleetVehicle.name}`)
        }

        this.fleetVehicle = fleetVehicle
      } catch (err) {
        console.error('FleetPanel.findFleetVehicle: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    handleRowClick(_evt: any, row: { item: DataTableItem }) {
      this.$router.push({
        name: RouteNames.VEHICLE,
        params: { vehicleId: row.item.id },
      })
    },
    handleMapInteractions(): void {
      if (!this.fleetVehicle) {
        this.gridMapStore.clearHighlight()
        this.gridMapStore.clearFocus()
        return
      }

      this.gridMapStore.highlight(MapLayerId.FLEET, [
        // TODO(rafael): update when RFS returns FleetVehicle as a Resource.
        { resource: this.fleetVehicle as unknown as Resource },
      ])

      const location = this.fleetVehicle.location?.point

      if (location) {
        this.gridMapStore.focusLocations({
          locations: [location],
          zoom: 20,
        })
      }
    },
  },
  beforeRouteLeave(_to, _from, next): void {
    this.gridMapStore.clearHighlight()
    this.gridMapStore.clearFocus()
    next()
  },
  created(): void {
    // Turn on the markers.
    this.updateSwitches([
      { id: MapLayerGroupId.FLEET, newValue: true },
      { id: MapLayerId.FLEET, newValue: true },
    ])
  },
})
</script>
