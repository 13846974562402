import { PlainMessage } from '@bufbuild/protobuf'
import { DateTime, Interval } from 'luxon'
import { mergeDataPoints } from '@/utils/charts'
import {
  TimeSeriesResponse,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'
import {
  TimeSeries,
  TimeSeriesFetch,
  TimeSeriesMetric,
} from '@/services/charts.service'
import { ChartDefinition, SeriesConfig } from '@/types/charts'
import { tileIntervalsBefore } from './tiles'

const { MIN_VALUE, MAX_VALUE } = Number

export enum EventName {
  IS_REFRESHING = 'isRefreshing',
  HAS_REFRESHED_SUCCESSFULLY = 'hasRefreshedSuccessfully',
}

export type EventCallbacks = {
  [EventName.IS_REFRESHING]: (newValue: boolean) => void
  [EventName.HAS_REFRESHED_SUCCESSFULLY]: () => void
}

/**
 * A generic interface for interacting with arbitrary data source implementations.
 * Some implementations:
 * - TimeSeriesDataSource: standard data source supporting tile-based requests
 * - FixedTimeDataSource: data source supporting only a single timespan
 */
export interface ITimeSeriesDataSource {
  fetchTimeSeries(visibleInterval: Interval): Promise<TimeSeriesMap>
  getTimeSeriesMap(): TimeSeriesMap
  updateFromCutOffDate?(newEndTime: DateTime): Promise<void>
  subscribe?<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void
  unsubscribe?<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void
}

/**
 * Time series data can be provided by any service or component that can provide
 * a `TimeSeriesResponse`.
 */
export type DataProvider = (f: TimeSeriesFetch) => Promise<TimeSeriesResponse>

export type MetricWithConfig = TimeSeriesMetric & { config: SeriesConfig }

export interface TimeSeriesConfig extends TimeSeriesMetric {
  config: SeriesConfig
  resource?: string
  aggregation?: string
}

export interface NamedTimeSeries extends TimeSeries {
  config: SeriesConfig
  aggregation?: string
}
export type ChartID = string
export type TimeSeriesMap = Map<ChartID, readonly NamedTimeSeries[]>

export const DEFAULT_TILE_SIZE = 4 // Four week retrievals
export const ONE_TILE_SIZE = 1 // One week retrievals

/**
 * The TimeSeriesDataSource manages chart requests for time series data for a specific resource.
 *
 * NOTE(andrewg): When a chart needs to show series from multiple resources,
 * use `AggregateDataSource` class.
 */
export class TimeSeriesDataSource implements ITimeSeriesDataSource {
  // The provider calls the service needed to fetch chart data
  protected readonly provider: DataProvider
  // Each chart can show the series for multiple metrics
  protected readonly configs: Map<ChartID, TimeSeriesConfig[]>

  private tiles: Iterator<Interval>

  protected cacheRange: Interval
  protected cachedSeries: Map<TimeSeriesConfig, TimeSeries>

  constructor(
    chartDataProvider: DataProvider,
    maxDate = DateTime.now(),
    tileSize = DEFAULT_TILE_SIZE
  ) {
    this.provider = chartDataProvider
    this.configs = new Map()

    this.tiles = tileIntervalsBefore(tileSize, maxDate)

    // The cache starts out with an empty time range
    this.cacheRange = Interval.fromDateTimes(maxDate, maxDate)
    this.cachedSeries = new Map()
  }

  /**
   * Return a DataSource implementation that has no data.
   * This is useful for cases where the resource for the data is not available.
   */
  static emptyDataSource(): ITimeSeriesDataSource {
    return new TimeSeriesDataSource(
      async () => new TimeSeriesResponse({ resource: '', series: [] })
    )
  }

  /**
   * Set a series definition for the given chart. If the chart is not visible,
   * the chart will not be added to the configuration, so the data is not needlessly fetched.
   */
  addChartSeries(chart: ChartDefinition, series: TimeSeriesConfig): this {
    if (chart.visible === false) return this

    const seriesList = this.configs.get(chart.id) ?? []
    this.configs.set(chart.id, seriesList.concat(series))

    return this
  }

  async fetchTimeSeries(visibleInterval: Interval): Promise<TimeSeriesMap> {
    // 1. Is the data already in the cache?
    if (this.cacheRange.engulfs(visibleInterval)) {
      // If yes, return the full data set
      return this.getTimeSeriesMap()
    }
    // If not,
    const metrics = Array.from(this.configs.values()).flat()
    // Quick out if there are no metrics
    if (metrics.length === 0) return this.getTimeSeriesMap()

    // 2. Enumerate all the tiles needed to fill [start, end)
    const intervals = this.getNextTiles(visibleInterval)

    // 3. Make requests for those tiles
    const response = await this.callProvider(intervals, metrics)

    // 4. Put them in the cache by prepending the new data into the current data
    for (const metric of metrics) {
      const curSeries = this.cachedSeries.get(metric)
      // Get the response data that corresponds to this series config
      const newSeries = this.findSeriesFromResponse(metric, response)
      // Only update the cache if we have new data
      if (newSeries.length === 0) continue
      // If we have cached data, append it because it comes after the new data
      if (curSeries != null) newSeries.push(curSeries)

      this.cachedSeries.set(metric, combineSeries(newSeries))
    }

    // The cache now has the newly-fetched tile + the previous range.
    // Union the visible range because it may extend past the end of the first tile.
    this.cacheRange = intervals
      .reduce((acc, interval) => acc.union(interval), this.cacheRange)
      .union(visibleInterval)

    // 5. Return the full data set
    return this.getTimeSeriesMap()
  }

  getTimeSeriesMap(): TimeSeriesMap {
    const map = new Map() as TimeSeriesMap

    this.configs.forEach((configs, chart) => {
      map.set(
        chart,
        configs.map(
          (c): NamedTimeSeries => ({
            ...(this.cachedSeries.get(c) ?? emptySeries(c.metric)),
            ...c,
          })
        )
      )
    })
    return map
  }

  protected async callProvider(
    intervals: Interval[],
    metrics: TimeSeriesConfig[]
  ): Promise<TimeSeriesResponse> {
    // Make a single time range so we can do one RPC for the data
    const interval = intervals.reduce((acc, interval) => acc.union(interval))

    try {
      return await this.provider({ interval, metrics })
    } catch (error) {
      console.error('[TimeSeriesDataSource] request failed', error)
      // TODO: should we retry?
    }

    return new TimeSeriesResponse()
  }

  /**
   * For the given series configuration, return all the response time series that match.
   * Subclasses can override to customize the algorithm. The default is to match by metric
   * or resource & metric for configurations with a non-empty resource.
   */
  protected findSeriesFromResponse(
    config: TimeSeriesConfig,
    response: TimeSeriesResponse
  ): TimeSeries[] {
    const { metric, resource } = config

    return response.series.filter((series) => {
      // A missing resource matches any response resource
      // Or if a response resource matches the config resource
      if (resource == null || resource === series.resource) {
        return metric === series.metric
      }
      return false
    })
  }

  protected getNextTiles(visibleInterval: Interval) {
    let nextTile = this.tiles.next() as IteratorYieldResult<Interval>
    const intervals: Interval[] = [nextTile.value]
    // We can stop when a tile starts before the visible interval start
    while (nextTile.value.start > visibleInterval.start) {
      nextTile = this.tiles.next() as IteratorYieldResult<Interval>
      intervals.push(nextTile.value)
    }
    console.debug('[TSDataSource]', intervals.join('-->'))
    // Reverse the list so the oldest tile is first
    return intervals.reverse()
  }
}

/**
 * Return the time series data for each chart in the data source,
 * but only data points within the given time interval.
 */
export function getTimeSeriesWithin(
  interval: Interval,
  ds: ITimeSeriesDataSource
): TimeSeriesMap {
  const seriesMap = ds.getTimeSeriesMap()
  for (const [chartID, series] of seriesMap) {
    seriesMap.set(chartID, filterTimeSeriesWithin(interval, series))
  }
  return seriesMap
}

export function getLastDataPoint(ts: TimeSeriesMap): TimeSeriesMap {
  const newMap: TimeSeriesMap = new Map()

  for (const [chartID, timeSeries] of ts) {
    const newSeries = timeSeries.map((series) => {
      const data = series.data.at(-1)
      if (data) {
        return createTimeSeriesStats(series, [data])
      }
      return series
    })
    newMap.set(chartID, newSeries)
  }

  return newMap
}

/**
 * Filter the time series data points to only those within the given interval.
 * This is useful for showing intervals of data on a the visible chart.
 * @param interval The time interval to filter the data points to.
 * @param timeSeries The time series data to filter.
 * @returns A new array of time series data points that are within the interval.
 */
export function filterTimeSeriesWithin(
  interval: Interval,
  timeSeries: readonly NamedTimeSeries[]
): NamedTimeSeries[] {
  const start = interval.start.toMillis()
  const end = interval.end.toMillis()

  return timeSeries.map((series) => {
    // TODO(Isaac): Use `getPartialData` instead of `filter` for performance
    const data = series.data.filter((dp) => dp.x > start && dp.x <= end)

    // If there are no data points in the interval, return an empty series
    if (data.length === 0) return emptySeries(series.metric) as NamedTimeSeries
    // Otherwise, return the series with the filtered data
    return createTimeSeriesStats(series, data)
  })
}

type TimeSeriesStats = {
  min: PlainMessage<TimeSeriesDataPoint>
  max: PlainMessage<TimeSeriesDataPoint>
  meanY: number
}

export function findMaxMinMeanY(
  data: PlainMessage<TimeSeriesDataPoint>[]
): TimeSeriesStats {
  return data.reduce(
    (acc, dp, idx, arr) => {
      // max
      if ((dp.y || 0) > (acc.max.y || 0)) acc.max = dp
      // min
      if ((dp.y || 0) < (acc.min.y || 0)) acc.min = dp
      // meanY
      acc.meanY += dp.y || 0
      if (idx === arr.length - 1) acc.meanY /= arr.length
      return acc
    },
    {
      min: data[0],
      max: data[0],
      meanY: 0,
    }
  )
}

export function createTimeSeriesStats(
  series: NamedTimeSeries,
  data: PlainMessage<TimeSeriesDataPoint>[]
): NamedTimeSeries {
  if (data.length === 0) return series
  const { max, min, meanY } = findMaxMinMeanY(data)
  return {
    ...series,
    data,
    min,
    max,
    meanY,
    ...(min.y && { minY: min?.y }),
    ...(max.y && { maxY: max?.y }),
  }
}

// Not for use outside package
export function combineSeries(allSeries: TimeSeries[]): TimeSeries {
  const allData = allSeries.map((s) => s.data)

  const combined = allSeries.reduce((combined, s) => {
    combined.metric = s.metric
    combined.resource = s.resource
    combined.calculation = s.calculation

    if (combined.min == null || combined.min.y! > (s.min?.y ?? MAX_VALUE)) {
      combined.min = s.min
      combined.minY = s.min?.y ?? NaN // to indicate no value
    }
    if (combined.max == null || combined.max.y! < (s.max?.y ?? MIN_VALUE)) {
      combined.max = s.max
      combined.maxY = s.max?.y ?? NaN // to indicate no value
    }
    return combined
  }, {} as TimeSeries)

  combined.data = mergeDataPoints(allData)
  combined.meanY = computeMean(allSeries, combined.data.length)

  return combined
}

function computeMean(allSeries: TimeSeries[], totalLen: number): number {
  // Using the total number of items, compute weighted values for each mean
  // and add them up.
  const weightedMean = (s: TimeSeries) => s.meanY * (s.data.length / totalLen)

  return allSeries.reduce((acc, s) => acc + weightedMean(s), 0)
}

// Not for use outside the package
export function emptySeries(metric: string): TimeSeries {
  return {
    metric,
    resource: '',
    data: [],
    minY: 0,
    maxY: 0,
    meanY: 0,
    calculation: 0,
  }
}

export const Test = { combineSeries, computeMean }
