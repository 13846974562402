<template>
  <full-height-layout class="px-6 pt-6">
    <!-- Top -->
    <template v-slot:top>
      <ce-title big>Notifications</ce-title>
    </template>

    <!-- Loading -->
    <centered-spinner v-if="isInitialState" />

    <!-- Data table -->
    <ce-data-table
      v-if="!isInitialState && !hasLoadingFailed"
      :isLoading
      :headers
      :table
      v-model:search="search"
      :options
      :filters
      :visibleHeaders
      :bgColor
      fixed-header
      dense
      @new-visible-headers="updateVisibleHeaders"
      @new-options="updateOptions"
      @new-filters="updateFilters"
      @search="handleNewSearch"
    />
  </full-height-layout>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { mapActions, mapState } from 'pinia'
import { WHITE } from '@/constants/colors'
import { useNotificationsStore } from '@/stores/notifications'
import { createHeaders } from '@/model/notification/NotificationsDataTable'
import FullHeightLayout from '@/components/common/FullHeightLayout.vue'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeTitle from '@/components/CeTitle.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'

export default defineComponent({
  name: 'Notifications',
  components: {
    CeTitle,
    CenteredSpinner,
    CeDataTable,
    FullHeightLayout,
  },
  setup() {
    return { headers: createHeaders(), bgColor: WHITE.hex }
  },
  data() {
    return shallowReactive({ search: '' })
  },
  computed: {
    ...mapState(useNotificationsStore, [
      'visibleHeaders',
      'options',
      'filters',
      'table',
      'isInitialState',
      'isLoading',
      'hasLoadingFailed',
      // TODO(rafael): When real notifications start flowing through RFA,
      // add "serverItemsLength" to the table props. Not including it
      // allows the table to handle option changes locally.
      'serverItemsLength',
    ]),
  },
  created(): void {
    if (this.isInitialState) this.fetchTable()
  },
  beforeUnmount(): void {
    this.flushAbortController()
  },
  methods: {
    ...mapActions(useNotificationsStore, [
      'updateVisibleHeaders',
      'updateOptions',
      'updateFilters',
      'updateSearchText',
      'fetchTable',
      'flushAbortController',
    ]),
    handleNewSearch(): void {
      this.updateSearchText(this.search)
    },
  },
})
</script>
