<template>
  <div
    class="position-relative chartjs-window-resizing py-1"
    :style="{ height: `${chartHeight}px` }"
  >
    <!-- Loading -->
    <centered-spinner v-if="isLoading" :size="20" />

    <template v-else>
      <!-- No data -->
      <div
        v-if="hasNoData"
        class="d-flex align-center justify-center"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      >
        {{ noDataText }}
      </div>

      <!-- Chart -->
      <line-chart v-else :data="chartData" :options="chartOptions" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  ScriptableContext,
  Point,
} from 'chart.js'
import { Line as LineChart } from 'vue-chartjs'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { BUBBLEGUM, CILANTRO, GREY1 } from '@/constants/colors'
import { colorWithOpacity } from '@/utils/colors'
import { splitPositiveNegative } from '@/utils/charts'
import { createLinearGradient } from '@/utils/chartjs'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import {
  TimeSeries as TsdbTimeSeries,
  TimeSeries_DataPoint as TimeSeriesDataPoint,
} from 'rfs/frontend/proto/tsdb_pb'

export default defineComponent({
  name: 'SparklineChart',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeSeries: {
      type: Object as PropType<undefined | TsdbTimeSeries>,
      required: false,
    },
    chartHeight: {
      type: Number,
      required: false,
      default: 40,
    },
  },
  components: { LineChart, CenteredSpinner },
  setup() {
    return { noDataText: TEXT_NO_VALUE }
  },
  computed: {
    timeSeriesData(): TimeSeriesDataPoint[] {
      return this.timeSeries?.data ?? []
    },
    hasNoData(): boolean {
      return !this.timeSeriesData.length
    },
    chartData(): ChartData<'line'> {
      const { positive, negative } = splitPositiveNegative(this.timeSeriesData)

      return {
        datasets: [positive, negative].map(
          (dpList, index): ChartDataset<'line'> => {
            const isPositiveSeries = index === 0
            const color = isPositiveSeries ? CILANTRO.hex : BUBBLEGUM.hex

            return {
              data: dpList as Point[],
              borderColor: color,
              borderWidth: 1,
              fill: true,
              backgroundColor: ({ chart }: ScriptableContext<'line'>) => {
                const gradient = createLinearGradient(chart)

                if (!gradient) return color

                gradient.addColorStop(
                  isPositiveSeries ? 0 : 1,
                  colorWithOpacity(color, 0.5)
                )
                gradient.addColorStop(
                  isPositiveSeries ? 1 : 0,
                  colorWithOpacity(color, 0.2)
                )

                return gradient
              },
            }
          }
        ),
      }
    },
    chartOptions(): ChartOptions<'line'> {
      const showNothing = {
        ticks: { display: false },
        grid: { drawOnChartArea: false, drawTicks: false },
        border: { display: false },
      }

      const minY = this.timeSeries?.min?.y ?? this.timeSeries?.minY ?? 0
      const maxY = this.timeSeries?.max?.y ?? this.timeSeries?.maxY ?? 0

      return {
        events: [],
        elements: { point: { radius: 0, hoverRadius: 0 } },
        scales: {
          x: { ...showNothing, type: 'time' },
          y: {
            ...showNothing,
            min: Number.isFinite(minY) ? Math.min(-1, minY) : undefined,
            max: Number.isFinite(maxY) ? Math.max(1, maxY) : undefined,
          },
        },
        plugins: {
          annotation: {
            annotations: {
              zero: {
                type: 'line',
                scaleID: 'y',
                value: 0,
                borderColor: GREY1.hex,
                borderWidth: 1,
                borderDash: [4, 4],
              },
            },
          },
          legend: { display: false },
          tooltip: { enabled: false },
        },
      }
    },
  },
})
</script>
