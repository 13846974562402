<template>
  <div>
    <!-- Summary Table -->
    <component
      v-if="summaryTableComponent"
      :is="summaryTableComponent"
      v-bind="summaryTableProps"
    />

    <time-series-chart-group
      :charts="charts"
      :data-source="dataSource"
      :interval="interval"
      @new-interval="handleNewInterval"
    />
  </div>
</template>

<script lang="ts">
import { Interval } from 'luxon'
import { defineComponent, type PropType } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import { TimeSeriesChartGroup } from '@/components/common'
import FeederSummaryDataTable from '@/components/substations/FeederSummaryDataTable.vue'
import SubstationSummaryDataTable from '@/components/substations/SubstationSummaryDataTable.vue'
import { ITimeSeriesDataSource } from '@/model/charts'
import {
  newTimeSeriesDataSource,
  telemetryChartDefinitions,
} from '@/model/resource/TelemetryChartData'
import { getUnqualifiedId } from '@/model/resource'
import type { Resource } from 'rfs/pb/resource_pb'
import { intervalLast7Days } from '@/utils/time'

export default defineComponent({
  name: 'ResourceTelemetry',
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
  components: {
    FeederSummaryDataTable,
    SubstationSummaryDataTable,
    TimeSeriesChartGroup,
  },
  setup() {
    return { charts: telemetryChartDefinitions }
  },
  data() {
    return {
      interval: intervalLast7Days(this.$observationTime()),
    }
  },
  computed: {
    resourceType(): ResourceType {
      return this.resource.type as ResourceType
    },
    unqualifiedId(): string {
      return getUnqualifiedId(this.resource)
    },
    dataSource(): ITimeSeriesDataSource {
      return newTimeSeriesDataSource(
        this.$rittaConfig,
        this.$services,
        this.resource
      )
    },
    summaryTableComponent(): undefined | string {
      switch (this.resourceType) {
        case ResourceType.FEEDER:
          return 'FeederSummaryDataTable'
        case ResourceType.SUBSTATION:
          return 'SubstationSummaryDataTable'
        default:
          return undefined
      }
    },
    summaryTableProps(): Record<string, any> {
      switch (this.resourceType) {
        case ResourceType.FEEDER:
          return {
            substationId: this.resource.upline?.substation,
            feederId: this.unqualifiedId,
          }
        case ResourceType.SUBSTATION:
          return { substation: this.resource }
        default:
          return {}
      }
    },
  },
  methods: {
    handleNewInterval(newInterval: Interval): void {
      this.interval = newInterval
    },
  },
})
</script>
