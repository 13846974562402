import { DateTime, Interval } from 'luxon'

import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Shape } from '@/constants/shape'
import { Services } from '@/services'
import { PeakPeriod } from '@/services/forecast.service'
import { Timestamp } from '@/services/timestamp_pb'
import {
  LineSeriesWithConfig,
  RangeBarsSeriesWithConfig,
  PointAnnotationsSeriesWithConfig,
  BasePointAnnotationYValue,
} from '@/types/charts'
import { Format, MEGA_OPTIONS } from '@/utils/format'

export type Options = {
  balancingArea: string
  year: number // 2022
  monthOfYear: number // 1-12
  dayOfMonth: number // 1-28,29,30,31
  numberOfYears: number // 5
  numberOfHighestPeakDaysPerMonthPerYear: number // 5
  referencePeriod: PeakPeriod
  productId?: string
  series: {
    actual: {
      name: string
      color: string // hex
    }
    forecast: {
      name: string
      color: string // hex
      dash: number
    }
    minMax: {
      name: string
      color: string // hex
    }
    highestPeaks: {
      /**
       * NOTE(rafael): Colors will repeat if the number of colors returned
       * by this function is lower than the `numberOfYears`.
       */
      colors: () => string[]
      high: {
        label: string
        shape: Shape
      }
      peak: {
        label: string
        shape: Shape
      }
    }
  }
}

/**
 * Base class for historical chart data. There are two subclasses, one for each of
 * the two RFS endpoints.
 */
export abstract class HistoricalContextChartDataProvider {
  readonly services: Services
  readonly options: Options

  private colors: string[] // hex

  loadActualSeries: LineSeriesWithConfig
  loadForecastSeries: LineSeriesWithConfig
  minMaxSeries: RangeBarsSeriesWithConfig
  highestPeaksSeries: PointAnnotationsSeriesWithConfig
  minLoadValue: number | undefined
  maxLoadValue: number | undefined

  constructor(services: Services, options: Options) {
    this.services = services
    this.options = options
    this.colors = this.updateColorList()
    this.loadActualSeries = this.newLoadActualSeries()
    this.loadForecastSeries = this.newLoadForecastSeries()
    this.minMaxSeries = this.newMinMaxSeries()
    this.highestPeaksSeries = this.newHighestPeaksSeries()
  }

  public abstract clone(
    newOptions: Partial<Options>
  ): HistoricalContextChartDataProvider

  /**
   * Subclasses override to call the appropriate RFS service
   */
  public abstract getTimeSeries(): Promise<void>

  protected updateColorList(): string[] {
    return this.options.series.highestPeaks.colors()
  }

  protected newLoadActualSeries(): LineSeriesWithConfig {
    return [
      {
        name: this.options.series.actual.name,
        color: this.options.series.actual.color,
        data: [],
      },
    ]
  }

  protected newLoadForecastSeries(): LineSeriesWithConfig {
    return [
      {
        name: this.options.series.forecast.name,
        color: this.options.series.forecast.color,
        dash: this.options.series.forecast.dash,
        data: [],
      },
    ]
  }

  protected newMinMaxSeries(): RangeBarsSeriesWithConfig {
    return [
      {
        name: this.options.series.minMax.name,
        color: this.options.series.minMax.color,
        data: [],
      },
    ]
  }

  protected getNextAvailableColor(): string {
    if (!this.colors.length) {
      this.colors = this.updateColorList()
    }
    return this.colors.pop()!
  }

  protected newHighestPeaksSeries(): PointAnnotationsSeriesWithConfig {
    const series = []

    for (let i = 1; i <= this.options.numberOfYears; i++) {
      series.push({
        name: (this.options.year - i).toString(),
        color: this.getNextAvailableColor(),
        data: [],
      })
    }

    return series
  }

  public compareSeriesOrder(name1: string, name2: string): number {
    const { actual, forecast, minMax } = this.options.series
    const order = [
      forecast.name,
      actual.name,
      minMax.name,
      ...this.highestPeaksSeries.map((s) => s.name),
    ]

    return order.indexOf(name1) - order.indexOf(name2)
  }

  public formatDataValue(rawValue: unknown): string | string[] {
    if (rawValue == null || typeof rawValue !== 'object') {
      return TEXT_NO_VALUE
    }
    // Is it a RangeBarsDataPoint?
    if (Array.isArray(rawValue)) {
      const [min, max] = rawValue
      return [formatMegaWatts(min), formatMegaWatts(max)]
    }
    // Is it a PointAnnotationsDataPoint
    if ('shape' in rawValue) {
      const y = rawValue as BasePointAnnotationYValue
      return y.label
        ? `${formatMegaWatts(y.value)} (${y.label})`
        : formatMegaWatts(y.value)
    }
    if ('y' in rawValue) {
      return formatMegaWatts(rawValue.y as number)
    }
    return TEXT_NO_VALUE
  }
}

function formatMegaWatts(v: number) {
  return Format.fmtWatts(v, MEGA_OPTIONS)
}

export function formatPeakHours(peakHours: Timestamp[], limit = 1): string {
  if (limit < peakHours.length) {
    peakHours = peakHours.slice(0, limit)
  }
  // The reduce below needs the timestamps sorted
  peakHours.sort((a, b) => a.toMillis() - b.toMillis())

  // Reduce the hours into a list of intervals
  const intervals = peakHours.reduce((acc, peakHour) => {
    // By default, the interval will be one hour from the given time
    const start = DateTime.fromMillis(peakHour.toMillis())
    const interval = Interval.fromDateTimes(start, start.plus({ hours: 1 }))
    const prevInterval = acc[acc.length - 1] as Interval | undefined

    // If the start interval is the same as the end of the last interval,
    if (prevInterval && start.equals(prevInterval.end)) {
      // combine the two intervals
      acc[acc.length - 1] = prevInterval.union(interval)
    } else {
      // Just add this interval to the list
      acc.push(interval)
    }
    return acc
  }, [] as Interval[])

  return intervals
    .map((interval) => `[${interval.toFormat('HH:mm')})`)
    .join(', ')
}
