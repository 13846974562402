import {
  Comment,
  createVNode,
  render,
  Text,
  type AppContext,
  type Component,
  type Slot,
  type VNode,
} from 'vue'

/**
 * Creates and mounts a Vue component resembling Vue 2 API.
 *
 * "props": Used to apply props and listeners.
 * "appContext": required if you want the same plugins from the current app.
 *
 * Links:
 * - https://github.com/vuejs/core/issues/1802
 * - https://github.com/vuejs/core/issues/1802#issuecomment-695346597
 * - https://github.com/pearofducks/mount-vue-component/blob/3be99b5cf49dbfe803566003d7597132ed4c909c/index.js
 */
export function customMount(
  component: Component,
  { props, appContext }: { props?: {}; appContext?: AppContext } = {}
) {
  const container = document.createElement('div')

  const node = createVNode(component, props)

  if (appContext) {
    node.appContext = appContext
  }

  render(node, container)

  const $destroy = () => render(null, container)

  return { $el: container, $destroy }
}

/**
 * https://github.com/vuejs/core/issues/4733#issuecomment-1024816095
 */
export function hasSlotContent(
  slot: Slot | undefined,
  slotProps = {}
): boolean {
  if (!slot) return false

  return slot(slotProps).some((vnode: VNode) => {
    if (vnode.type === Comment) return false

    if (Array.isArray(vnode.children) && !vnode.children.length) return false

    return (
      vnode.type !== Text ||
      (typeof vnode.children === 'string' && vnode.children.trim() !== '')
    )
  })
}
