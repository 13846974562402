/* eslint-disable camelcase */
import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from '@connectrpc/connect'
import { PlainMessage } from '@bufbuild/protobuf'
import { chunk } from 'lodash-es'
import { Interval } from 'luxon'
import { ResourceType } from '@/constants/resourceType'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { Charts as ChartsProto } from 'rfs/frontend/proto/tsdb_connect'
import {
  MultiTimeSeriesRequest,
  TimeSeries as TimeSeriesMessage,
  TimeSeriesRequest,
  TimeSeriesResponse,
  TimeSeries_Calculation as MetricCalculation,
} from 'rfs/frontend/proto/tsdb_pb'
import { Timestamp } from './timestamp_pb'
import { raceAll } from '@/utils/async'

export { MetricCalculation }

export type TimeSeries = PlainMessage<TimeSeriesMessage>

export interface TimeSeriesFetch {
  interval: Interval
  metrics: Array<TimeSeriesMetric>
  resolution?: Resolution
  /** The time series is an aggregation of downline resource data. */
  aggregation?: ResourceType
}

/** When fetching a stream, there must be a way to cancel the stream. */
export interface TimeSeriesFetchStream extends TimeSeriesFetch {
  signal: AbortSignal
}

type Unit = 'W' | 'Wh' | 'V' | 'Vpu' | 'A' | 'Apu' | 'F' | 'C' | 'kg/MWh'

export interface TimeSeriesMetric {
  metric: string // TODO(andrew): make an enum of known metrics
  /**
   * - Watts (W)
   * - Negate Watts (-W)
   * - Watt-hours (Wh)
   * - Volts (V)
   * - Volts per-unit (Vpu)
   * - Amps (A)
   * - Fahrenheit (F)
   * - Celsius (C)
   */
  unit?: Unit | `-${Unit}`
  /**
   * Instead of a series per resource, run a calculation on the data at each timestamp.
   */
  calculation?: MetricCalculation
}

export class ChartsService {
  private readonly chartsClient: PromiseClient<typeof ChartsProto>

  constructor(transport: Transport) {
    this.chartsClient = createPromiseClient(ChartsProto, transport)
  }

  public async fetchTimeSeries(
    resourceId: string,
    fetch: TimeSeriesFetch
  ): Promise<TimeSeriesResponse> {
    const request = new TimeSeriesRequest({
      resource: resourceId,
      start: Timestamp.fromDateTime(fetch.interval.start),
      end: Timestamp.fromDateTime(fetch.interval.end),
      metrics: fetch.metrics,
      resolution: fetch.resolution,
      aggregation: fetch.aggregation,
    })
    return this.chartsClient.timeSeries(request)
  }

  public async fetchMultiTimeSeries(
    resourceIds: string[],
    fetch: TimeSeriesFetch
  ) {
    return this.fetchTimeSeriesForMultiResources(resourceIds, fetch)
  }

  public async fetchTimeSeriesForMultiResources(
    resourceIds: string[],
    fetch: TimeSeriesFetch
  ): Promise<TimeSeriesResponse> {
    const request = new MultiTimeSeriesRequest({
      resources: {
        groupKey: { case: 'ids', value: { ids: resourceIds } },
      },
      start: Timestamp.fromDateTime(fetch.interval.start),
      end: Timestamp.fromDateTime(fetch.interval.end),
      metrics: fetch.metrics,
      resolution: fetch.resolution,
      aggregation: fetch.aggregation,
    })
    return this.chartsClient.multiTimeSeries(request)
  }

  public fetchMultiTimeSeriesInChunks(
    resourceIds: string[],
    fetch: TimeSeriesFetch,
    chunkSize = 500
  ): AsyncIterable<TimeSeriesResponse> {
    const responses = chunk(resourceIds, chunkSize)
      .map((ids) => {
        return new MultiTimeSeriesRequest({
          resources: {
            groupKey: { case: 'ids', value: { ids } },
          },
          start: Timestamp.fromDateTime(fetch.interval.start),
          end: Timestamp.fromDateTime(fetch.interval.end),
          metrics: fetch.metrics,
          resolution: fetch.resolution,
          aggregation: fetch.aggregation,
        })
      })
      .map((request) => this.chartsClient.multiTimeSeries(request))
    // Yield each response as it comes in
    return raceAll(responses)
  }

  public streamTimeSeries(
    resourceId: string,
    fetch: TimeSeriesFetchStream
  ): AsyncIterable<TimeSeriesResponse> {
    const signal = fetch.signal
    const request = new TimeSeriesRequest({
      resource: resourceId,
      start: Timestamp.fromDateTime(fetch.interval.start),
      end: Timestamp.fromDateTime(fetch.interval.end),
      metrics: fetch.metrics,
      resolution: fetch.resolution,
      aggregation: fetch.aggregation,
    })
    return this.chartsClient.timeSeriesStream(request, { signal })
  }
}
