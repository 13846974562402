import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { createTransformerRoute } from '@/utils/router/create'
import * as RouteNames from '@/router/routeNames'
import { useGridImpactsStore } from '@/stores/gridImpacts'
import { getUnqualifiedId } from '@/model/resource'
import { CalendarPeriod } from 'rfs/pb/calendar_pb'
import type { LoadBlockFunction, Block, Result } from './types'
import { DERImpactsRequest } from 'rfs/frontend/proto/analysis_pb'

const TITLE = 'Transformers'

export const searchTransformers: LoadBlockFunction = async (
  searchText,
  maxResults,
  { services }
) => {
  const block: Block = { title: TITLE, results: [] }

  try {
    const res = await services.analysisService.fetchDERImpactsTable(
      new DERImpactsRequest({
        componentType: ResourceType.TRANSFORMER,
        fixedInterval: CalendarPeriod.MONTH,
        limit: maxResults + 1,
        offset: 0,
        orderBy: { property: 'id', descending: false },
        search: searchText,
      })
    )

    for (const row of res.rows) {
      if (!row.resource) continue

      const unqualifiedId = getUnqualifiedId(row.resource.id)
      const item: Result = {
        title: {
          text: unqualifiedId,
          link: createTransformerRoute(unqualifiedId),
        },
        pairs: [
          {
            label: 'Substation',
            text: row.resource.upline?.substation ?? TEXT_NO_VALUE,
          },
          {
            label: 'Feeder',
            text: row.resource.upline?.feeder ?? TEXT_NO_VALUE,
          },
        ],
      }
      block.results.push(item)
    }

    // See more.
    if (block.results.length > maxResults) {
      block.results = block.results.slice(0, maxResults)
      block.seeMore = {
        link: { name: RouteNames.GRID_IMPACT },
        onClick: () => {
          const store = useGridImpactsStore()
          store.updateComponentType(ResourceType.TRANSFORMER)
          store.updateSearchText(searchText)
        },
      }
    }
  } catch (err) {
    console.error('searchTransformers: %o', err)
  }

  return block
}
