<template>
  <div
    class="d-flex flex-column rounded pa-4"
    :style="{ backgroundColor: GRAY_COOL_50.hex, minHeight: '12.75rem' }"
  >
    <!-- Title & Timestamp -->
    <div class="pb-2">
      <!-- Title -->
      <h1 class="d-block text-subtitle-2 font-weight-medium">
        {{ getNotificationTitle(notification) }}
      </h1>

      <!-- Timestamp -->
      <span
        aria-label="Timestamp"
        class="d-block text-subtitle-2"
        style="font-weight: unset"
        :style="{ color: NEUTRAL_600.hex }"
      >
        {{ formatTime(notification) }}
      </span>
    </div>

    <!-- Description -->
    <span
      aria-label="Description"
      class="d-block text-subtitle-2 flex-grow-1 flex-shrink-1 pb-2"
      style="font-weight: unset"
    >
      {{ getNotificationDescription(notification) }}
    </span>

    <!-- Actions -->
    <div class="d-flex align-center">
      <!-- See details -->
      <router-link
        :to="createNotificationRoute(notification.id)"
        class="d-block text-primary text-subtitle-2 text-decoration-none"
        style="font-weight: unset"
      >
        See details
      </router-link>

      <v-spacer />

      <!-- Mark as Read -->
      <button-segmented
        :loading="isLoading"
        :icon="
          notification.read
            ? 'mdi-bell-ring-outline'
            : 'mdi-check-circle-outline'
        "
        @click.stop="toggleReadState"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { DateTime } from 'luxon'
import { GRAY_COOL_50, NEUTRAL_600 } from '@/constants/colors'
import { createNotificationRoute } from '@/utils/router/create'
import {
  getNotificationTitle,
  getNotificationDescription,
} from '@/model/notification/formatting'
import {
  MarkAsReadRequest,
  type Notification,
} from 'rfs/frontend/proto/notification_pb'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import { formatDt } from '@/utils/time'

export default defineComponent({
  name: 'NotificationMenuItem',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  components: { ButtonSegmented },
  setup() {
    return {
      GRAY_COOL_50,
      NEUTRAL_600,
      createNotificationRoute,
      getNotificationTitle,
      getNotificationDescription,
    }
  },
  data() {
    return shallowReactive({ isLoading: false })
  },
  methods: {
    formatTime(notification: Notification): string {
      const suffix = DateTime.now().toFormat('(ZZZZ)')
      return `${formatDt(notification.startTime)} ${suffix}`
    },
    async toggleReadState(): Promise<void> {
      this.isLoading = true

      try {
        await this.$services.notificationService.markAsRead(
          new MarkAsReadRequest({
            ids: [this.notification.id],
            read: !this.notification.read,
          })
        )
      } catch (err) {
        console.error('NotificationMenuItem.toggleReadState: %o', err)
      } finally {
        this.isLoading = false
      }
    },
  },
})
</script>
