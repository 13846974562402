import { TEXT_NO_VALUE } from '@/constants/formatText'
import { Format } from '@/utils/format'
import { InfoColumnAllProps } from '@/model/types'
import { Resource } from 'rfs/pb/resource_pb'
import { getDisplayName } from '..'

export const HydroHelper = {
  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    const wattageRaw = r.ratings?.wattage

    return {
      title: getDisplayName(r),
      items: [
        {
          label: 'Wattage',
          text:
            wattageRaw !== undefined
              ? Format.fmtWatts(wattageRaw)
              : TEXT_NO_VALUE,
        },
      ],
    }
  },
}
