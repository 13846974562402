import { get } from 'lodash-es'
import { DateTime } from 'luxon'

import { MonitorConfig, RittaConfig } from '@/config'
import { Services } from '@/services'
import { ResourceType } from '@/constants/resourceType'
import { ColumnType } from '@/model/tables/column'
import {
  DataTable,
  Header,
  Row,
  RowIDSet,
  RowWithChildTable,
  Filters,
  FilterFieldsMinMax,
  FilterMultiSelect,
} from '@/model/tables/DataTable'
import {
  NO_MATCHING_RECORDS_FOUND,
  IS_LOADING,
  SearchValue,
  addKwSuffix,
  filterByMinMax,
  hasAtLeastOneFilterValue,
  headerTimeSeries,
  headerTimeSeriesMax,
  headerTimeSeriesMin,
  isFilterMinMax,
  newFilterFieldsMinMax,
  TimeSeriesColumns,
  ITimeSeriesColumns,
  MapTimeSeriesValue,
  MapTimeSeries,
  PlaceholdersMinMax,
  transformToMapTimeSeriesValue,
  hasFilterValue,
} from '@/model/tables/helper'
import { SubstationHelper } from '@/model/resource/substation/helper'
import { intervalLast24Hours } from '@/utils/time'
import { SummaryStats, SummaryStatsAll } from 'rfs/frontend/proto/grid_pb'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { Resource } from 'rfs/pb/resource_pb'
import { NumberOrNull } from '@/types/charts'
import { createResourceTelemetryRoute } from '@/utils/router/create'
import { getDisplayName, getUnqualifiedId } from '../index'

export enum Columns {
  NAME = 'NAME',
  METERS_SERVED = 'METERS_SERVED',
  SOLAR_CAPACITY = 'SOLAR_CAPACITY',
}

type AllColumns = Columns | TimeSeriesColumns

export type CustomPlaceholdersMinMax = PlaceholdersMinMax<AllColumns>

interface StatsColumns {
  [Columns.METERS_SERVED]: NumberOrNull
  [Columns.SOLAR_CAPACITY]: NumberOrNull
}

interface SharedColumns extends ITimeSeriesColumns, StatsColumns {
  [Columns.NAME]: string
}

export { SummaryStatsAll }

export interface FeederRow extends Row, SharedColumns {
  feeder: Resource
  substation: Resource
}

export interface SubstationRow
  extends RowWithChildTable<FeederDataTable>,
    SharedColumns {
  substation: Resource
  feeders: Resource[]
}

interface FeederDataTable extends DataTable<FeederRow> {}

export interface SubstationDataTable extends DataTable<SubstationRow> {}

export type SubstationOrFeederRow = SubstationRow | FeederRow

export type CustomFilters = Filters<AllColumns>

const headerName: Header<SubstationOrFeederRow> = {
  title: 'Name',
  key: Columns.NAME,
  sortable: true,
  columnSelector: { required: true },
  routeFactory: (_config, row) => {
    const r = isFeederRow(row) ? row.feeder : row.substation
    return createResourceTelemetryRoute(r.type as ResourceType, r.id)
  },
}

const headerMetersServed: Header = {
  title: 'Meters Served',
  key: Columns.METERS_SERVED,
  valueType: ColumnType.INTEGER,
  sortable: true,
  align: 'end',
  width: '12%',
}

const headerSolarCapacity: Header = {
  title: 'Solar Capacity (AC) (kW)',
  key: Columns.SOLAR_CAPACITY,
  valueType: ColumnType.KILO_W,
  sortable: true,
  align: 'end',
  width: '18%',
}

const defaultHeaders: Header[] = [
  headerName,
  headerMetersServed,
  headerSolarCapacity,
  headerTimeSeriesMin('Min Load (kW)', ColumnType.KILO_W),
  headerTimeSeriesMax('Max Load (kW)', ColumnType.KILO_W),
  headerTimeSeries,
]

export function createHeaders(config?: MonitorConfig): Header[] {
  let headers = defaultHeaders
  // A custom column, if it exists, goes after the "Name" column
  if (config?.substation?.dataTable?.customColumn) {
    headers = defaultHeaders.slice()
    headers.splice(1, 0, config.substation.dataTable.customColumn)
  }
  return headers
}

export function createPlaceholders({
  statistics,
}: SummaryStatsAll): CustomPlaceholdersMinMax {
  let mMin: number = 0
  let mMax: number = 0
  let sMin: number = 0
  let sMax: number = 0

  for (const stat of Object.values(statistics)) {
    const mCount = stat.meter?.resources
    const sOutput = stat.solar?.ratedWattage

    mMin = Math.min(mMin, mCount ?? 0)
    mMax = Math.max(mMax, mCount ?? 0)
    sMin = Math.min(sMin, sOutput ?? 0)
    sMax = Math.max(sMax, sOutput ?? 0)
  }

  return new Map()
    .set(Columns.METERS_SERVED, { min: mMin, max: mMax })
    .set(Columns.SOLAR_CAPACITY, { min: sMin, max: sMax })
}

/**
 * Fills the current filters with placeholder values for each column of the table.
 */
export function fillFiltersWithPlaceholders(
  filters: CustomFilters,
  placeholders: CustomPlaceholdersMinMax
): CustomFilters {
  return Array.from(filters.entries()).reduce<CustomFilters>(
    (acc, [columnId, filter]) => {
      const ph = placeholders.get(columnId)

      if (ph && isFilterMinMax(filter)) {
        const newFilter: FilterFieldsMinMax = { ...filter }

        if (
          columnId === Columns.SOLAR_CAPACITY ||
          columnId === TimeSeriesColumns.TIME_SERIES_MIN ||
          columnId === TimeSeriesColumns.TIME_SERIES_MAX
        ) {
          newFilter.min.placeholder = SubstationHelper.formatLoad(
            ph.min ?? null
          )
          newFilter.max.placeholder = SubstationHelper.formatLoad(
            ph.max ?? null
          )
        } else {
          newFilter.min.placeholder = ph.min?.toString()
          newFilter.max.placeholder = ph.max?.toString()
        }

        acc.set(columnId, newFilter)
      } else {
        acc.set(columnId, filter)
      }

      return acc
    },
    new Map()
  )
}

const MULTIPLIER_KW = 1_000

export function createNewFilters(config?: MonitorConfig): CustomFilters {
  const filters = new Map()

  if (config?.substation?.dataTable?.customColumn) {
    const column = config.substation.dataTable.customColumn

    const filter: FilterMultiSelect = {
      type: 'multiselect',
      items: column.filterOptions,
      value: [],
    }

    filters.set(column.key, filter)
  }
  return filters
    .set(Columns.METERS_SERVED, newFilterFieldsMinMax())
    .set(
      Columns.SOLAR_CAPACITY,
      addKwSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    )
    .set(
      TimeSeriesColumns.TIME_SERIES_MIN,
      addKwSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    )
    .set(
      TimeSeriesColumns.TIME_SERIES_MAX,
      addKwSuffix(newFilterFieldsMinMax(MULTIPLIER_KW))
    )
}

export function isFeederRow(row: SubstationOrFeederRow): row is FeederRow {
  return !!(row as FeederRow).feeder
}

export function filterBySearch(
  row: SubstationOrFeederRow,
  search: SearchValue
): boolean {
  if (!search) return true

  const searchLowerCase = search.toLowerCase()

  const rowNameMatches =
    row[Columns.NAME].toLowerCase().search(searchLowerCase) !== -1

  const substatioTitleMatches =
    row.substation.title.toLowerCase().search(searchLowerCase) !== -1

  return rowNameMatches || substatioTitleMatches
}

export async function fetchTimeSeries<R extends SubstationOrFeederRow>(
  services: Services,
  now: DateTime,
  row: R,
  onLoading: (row: R, isLoading: boolean) => void
): Promise<MapTimeSeriesValue> {
  onLoading(row, true)

  const empty: MapTimeSeriesValue = { timeSeries: null, min: null, max: null }

  try {
    const { series } = await services.chartsService.fetchTimeSeries(row.id, {
      interval: intervalLast24Hours(now),
      metrics: [{ metric: 'conditions.power.real', unit: 'W' }],
      resolution: Resolution.FIFTEEN_MINUTE,
    })

    return series.length ? transformToMapTimeSeriesValue(series[0]) : empty
  } catch (err) {
    console.error(`fetchTimeSeries: "${row.id}"`, err)
    return empty
  } finally {
    onLoading(row, false)
  }
}

function createSharedColumns(
  id: Resource['id'],
  allStats: SummaryStatsAll,
  mapTimeSeries: MapTimeSeries
): StatsColumns & ITimeSeriesColumns {
  const mapTimeSeriesValue = mapTimeSeries.get(id)
  const isTimeSeriesLoading = mapTimeSeriesValue === IS_LOADING
  const stats = allStats.statistics[id] as SummaryStats | undefined

  return {
    [Columns.METERS_SERVED]: stats?.meter?.resources ?? null,
    [Columns.SOLAR_CAPACITY]: stats?.solar?.ratedWattage ?? null,
    [TimeSeriesColumns.TIME_SERIES_MIN]: isTimeSeriesLoading
      ? IS_LOADING
      : mapTimeSeriesValue?.min ?? null,
    [TimeSeriesColumns.TIME_SERIES_MAX]: isTimeSeriesLoading
      ? IS_LOADING
      : mapTimeSeriesValue?.max ?? null,
    [TimeSeriesColumns.TIME_SERIES]: isTimeSeriesLoading
      ? IS_LOADING
      : mapTimeSeriesValue?.timeSeries ?? null,
  }
}

function createFeederTable(
  config: Readonly<RittaConfig>,
  substation: Resource,
  feeders: Resource[],
  feederStats: SummaryStatsAll,
  mapTimeSeries: MapTimeSeries,
  search: SearchValue,
  filters: CustomFilters
): FeederDataTable {
  const allRows = feeders.map((f) =>
    createFeederRow(f, substation, feederStats, mapTimeSeries)
  )

  const hasActiveFilters = search || hasAtLeastOneFilterValue(filters)

  return {
    rows: hasActiveFilters
      ? allRows.filter((r) =>
          rowMatchesSearchAndOtherFilters(r, search, filters)
        )
      : allRows,
    noDataText: hasActiveFilters
      ? NO_MATCHING_RECORDS_FOUND
      : 'No feeders data available',
    selectable: !config.monitor?.substation?.dataTable?.disableCompareFeeders,
  }
}

function createSubstationRow(
  config: Readonly<RittaConfig>,
  s: Resource,
  feeders: Resource[],
  stats: SummaryStatsAll,
  mapTimeSeries: MapTimeSeries,
  search: SearchValue,
  filters: CustomFilters
): SubstationRow {
  const { id } = s
  return {
    id,
    [Columns.NAME]: getDisplayName(s),
    substation: s,
    feeders,
    ...createSharedColumns(id, stats, mapTimeSeries),
    childTable: createFeederTable(
      config,
      s,
      feeders,
      stats,
      mapTimeSeries,
      search,
      filters
    ),
  }
}

function createFeederRow(
  f: Resource,
  substation: Resource,
  stats: SummaryStatsAll,
  mapTimeSeries: MapTimeSeries
): FeederRow {
  const { id } = f
  return {
    id,
    [Columns.NAME]: getDisplayName(f),
    feeder: f,
    substation,
    ...createSharedColumns(id, stats, mapTimeSeries),
  }
}

/** * Returns only Feeders that belong to the Substation. */
function filterFeeders(substation: Resource, feeders: Resource[]): Resource[] {
  return feeders.filter(
    (f) => f.upline?.substation === getUnqualifiedId(substation)
  )
}

export function filterAndMarkWithShowAsGroup(
  rows: SubstationRow[],
  search: SearchValue,
  filters: CustomFilters
): SubstationRow[] {
  return rows.reduce<SubstationRow[]>((acc, r) => {
    const matchesFilters = rowMatchesSearchAndOtherFilters(r, search, filters)
    const hasFeederRows = !!r.childTable.rows.length

    if (matchesFilters || hasFeederRows) {
      const newR: SubstationRow = {
        ...r,
        showAsGroup: !matchesFilters && hasFeederRows,
      }
      acc.push(newR)
    }

    return acc
  }, [])
}

export function createSubstationDataTable(
  config: Readonly<RittaConfig>,
  allSubstations: Resource[],
  allFeeders: Resource[],
  allStats: SummaryStatsAll,
  mapTimeSeries: MapTimeSeries,
  search: SearchValue,
  filters: CustomFilters
): SubstationDataTable {
  const rows = allSubstations.map((s) =>
    createSubstationRow(
      config,
      s,
      filterFeeders(s, allFeeders),
      allStats,
      mapTimeSeries,
      search,
      filters
    )
  )

  const hasActiveFilters = search || hasAtLeastOneFilterValue(filters)

  return {
    rows: hasActiveFilters
      ? filterAndMarkWithShowAsGroup(rows, search, filters)
      : rows,
    noDataText: hasActiveFilters
      ? NO_MATCHING_RECORDS_FOUND
      : 'No substations data available',
    selectable: true,
    selectMenu: [
      { text: 'Substations', value: ResourceType.SUBSTATION },
      { text: 'Feeders', value: ResourceType.FEEDER },
    ],
  }
}

export function rowMatchesFilters(row: Row, filters: CustomFilters): boolean {
  return Array.from(filters.entries())
    .filter(([_, filterField]) => hasFilterValue(filterField))
    .every(([columnId, filterFields]) => {
      // The columnId may be a path to the value in the row object
      const columnValue = get(row, columnId) as any

      // Allow "is-loading" to pass free.
      if (columnValue === IS_LOADING) {
        return true
      }

      switch (filterFields.type) {
        case 'min-max':
          return filterByMinMax(columnValue, filterFields)
        case 'dropdown':
          return filterFields.value === columnValue
        case 'multiselect':
          return filterFields.value.some((v) => v === columnValue)
        default:
          console.warn('filtering not implemented for', filterFields)
          return true
      }
    })
}

function rowMatchesSearchAndOtherFilters(
  r: SubstationOrFeederRow,
  search: SearchValue,
  filters: CustomFilters
): boolean {
  return filterBySearch(r, search) && rowMatchesFilters(r, filters)
}

/** * Expand all substation rows that contain at least one child row. */
export function expandSubstationRows(table: SubstationDataTable): RowIDSet {
  return table.rows.reduce<RowIDSet>((acc, r) => {
    if (r.childTable.rows.length) acc.add(r.id)
    return acc
  }, new Set())
}
