<template>
  <div class="c-BtnExpandLeftPanel">
    <v-tooltip bottom>
      <!-- Btn -->
      <template v-slot:activator="{ props }">
        <button
          v-bind="props"
          type="button"
          class="bg-white gmaps-box-shadow"
          style="border-radius: 0.25rem; width: 3rem; height: 3rem"
          @click="toggleDrawerLeftOpened"
        >
          <v-icon color="primary">
            {{ drawerLeftOpened ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
          </v-icon>
        </button>
      </template>

      <!-- Tooltip content -->
      <span>
        {{ drawerLeftOpened ? 'Collapse left panel' : 'Expand left panel' }}
      </span>
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { useNavigationControlsStore } from '@/stores/navigationControls'

export default defineComponent({
  name: 'BtnExpandLeftPanel',
  computed: {
    ...mapState(useNavigationControlsStore, ['drawerLeftOpened']),
  },
  methods: {
    ...mapActions(useNavigationControlsStore, ['toggleDrawerLeftOpened']),
  },
})
</script>
