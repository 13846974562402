import { InfoColumnAllProps } from '@/components/InfoColumn'
import { MapLayerId } from '@/config/types'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import { Services } from '@/services'
import { ICON_OFFSET_TOP, TILE_SIZE, ZOOM_DEFAULT } from '@/model/map/constants'
import { MapLayerFeature, MapManagerLayer } from '@/model/map/types'
import { getPowerRating, getPowerRatingDC } from '@/model/resource'
import { Format } from '@/utils/format'
import { ApplicationMapLayer } from './catalog'
import {
  CustomTileLayer,
  getTileResponseLayers,
  scalingIconProps,
} from './extensions'

import iconDistributedSolar from '@/assets/imgs/albert_ic_solar_distributed--black.svg'

function distributedSolarMapManagerLayer(
  id: MapLayerId,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id,
    minZoom: ZOOM_DEFAULT - 1,
    maxZoom: ZOOM_DEFAULT - 1,
    tileSize: TILE_SIZE,
    pickable: true,
    getTileData: async (props) =>
      services.tileService.getTileResources(
        ResourceType.SOLAR_DISTRIBUTED,
        props
      ),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: scalingIconProps(ICON_OFFSET_TOP),
      })
    },
  })
}

export function distributedSolar(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Distributed Solar',
    icon: iconDistributedSolar,
    infoWindow: {
      infoColumn: (f) => infoWindowInfoColumn(f),
    },
    mapManagerLayer: (_config, services) =>
      distributedSolarMapManagerLayer(id, services),
  }
}

function infoWindowInfoColumn(f: MapLayerFeature): InfoColumnAllProps {
  const r = f.resource!
  const siteId = r.site?.id

  return {
    title: siteId ? `Site: ${siteId}` : TEXT_NO_VALUE,
    items: [
      {
        label: 'Capacity (AC)',
        text: Format.fmtWatts(getPowerRating(r)) || TEXT_NO_VALUE,
      },
      {
        label: 'Capacity (DC)',
        text: Format.fmtWatts(getPowerRatingDC(r)) || TEXT_NO_VALUE,
      },
    ],
  }
}
