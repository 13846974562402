import { DateTime } from 'luxon'

import { FleetVehicle } from 'rfs/frontend/proto/fleet_pb'
import { InfoColumnAllProps } from '@/model/types'
import { Timestamp } from '@/services/timestamp_pb'

const UNKNOWN_STRING = 'unknown'

export const FleetVehicleHelper = {
  formatUpdated(updated?: Timestamp): string {
    return updated === undefined
      ? UNKNOWN_STRING
      : DateTime.fromMillis(updated.toMillis()).toRelative() || UNKNOWN_STRING
  },

  infoWindowInfoColumn(f: FleetVehicle): InfoColumnAllProps {
    return {
      title: f.name,
      items: [
        {
          label: 'Group',
          text: f.groupName,
        },
        {
          label: 'Last Updated',
          text: this.formatUpdated(f.updated),
        },
      ],
    }
  },
}
