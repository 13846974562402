import { TEXT_NO_VALUE } from '@/constants/formatText'
import { routeForResource } from '@/router/routeForResource'
import * as RouteNames from '@/router/routeNames'
import { convertOrderBy } from '@/model/tables/column'
import { createInitialOptions as dersCreateInitialOptions } from '@/model/ders/DersDataTable'
import { useDersTableStore } from '@/stores/dersTable'
import type { LoadBlockFunction, Block, Result } from './types'

const TITLE = 'DERs'

export const searchDers: LoadBlockFunction = async (
  searchText,
  maxResults,
  { services, dictionary }
) => {
  const block: Block = { title: TITLE, results: [] }

  try {
    const res = await services.gridService.dersTable({
      offset: 0,
      limit: maxResults + 1,
      search: searchText,
      filterBy: [],
      orderBy: convertOrderBy(dersCreateInitialOptions().orderBy),
      groupBy: [],
    })

    // Create results.
    for (const row of res.rows) {
      const resource = row.resource

      if (!resource) continue

      const item: Result = {
        title: { text: resource.title, link: routeForResource(resource) },
        pairs: [
          { label: 'Type', text: row.extendedType },
          {
            label: 'Feeder',
            text: resource.upline?.feeder ?? TEXT_NO_VALUE,
          },
          {
            label: dictionary('Site'),
            text: resource.site?.id ?? TEXT_NO_VALUE,
          },
        ],
      }
      block.results.push(item)
    }

    // See more.
    if (block.results.length > maxResults) {
      block.results = block.results.slice(0, maxResults)
      block.seeMore = {
        link: { name: RouteNames.DERS },
        onClick: () => useDersTableStore().updateSearchText(searchText),
      }
    }
  } catch (err) {
    console.error('searchDers: %o', err)
  }

  return block
}
