import { Format } from '@/utils/format'
import { InfoColumnAllProps } from '@/model/types'
import { Resource } from 'rfs/pb/resource_pb'
import { TEXT_NO_VALUE } from '@/constants/formatText'

export const MethaneHelper = {
  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    const productionRaw = r.ratings?.wattage

    return {
      title: r.title,
      items: [
        {
          label: 'Production',
          text:
            productionRaw !== undefined
              ? Format.fmtWatts(productionRaw)
              : TEXT_NO_VALUE,
        },
      ],
    }
  },
}
