<template>
  <div class="c-FilterMinMax d-inline-flex">
    <v-text-field
      :model-value="minValue"
      :label="fields.min.label"
      :placeholder="fields.min.placeholder"
      type="number"
      class="hide-arrows small-label mr-1"
      color="secondary"
      clearable
      hide-details
      @change="(v: Event) => handleNewValue('min', v)"
      @click:clear="handleNewValue('min', null)"
    />
    <v-text-field
      :model-value="maxValue"
      :label="fields.max.label"
      :placeholder="fields.max.placeholder"
      type="number"
      class="hide-arrows small-label ml-1"
      color="secondary"
      clearable
      hide-details
      @change="(v: Event) => handleNewValue('max', v)"
      @click:clear="handleNewValue('max', null)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import type {
  FilterFieldsMinMax,
  InputNumberField,
} from '@/model/tables/DataTable'

export default defineComponent({
  name: 'FilterMinMax',
  props: {
    fields: {
      type: Object as PropType<FilterFieldsMinMax>,
      required: true,
    },
  },
  computed: {
    minValue(): string {
      return this.fields.min.value?.toString() ?? ''
    },
    maxValue(): string {
      return this.fields.max.value?.toString() ?? ''
    },
  },
  methods: {
    handleNewValue(id: 'min' | 'max', evt: null | Event): void {
      const newValue = (evt?.target as HTMLInputElement)?.value
      const newField: InputNumberField = {
        ...this.fields[id],
        value: newValue ? parseFloat(newValue) : null,
      }
      const newFields: FilterFieldsMinMax = {
        ...this.fields,
        [id]: newField,
      }
      this.$emit('new-fields', newFields)
    },
  },
})
</script>
