<template>
  <section
    role="region"
    :aria-label="name"
    class="c-InfoColumn"
    :style="{ '--border-color': NEUTRAL_200.hex }"
  >
    <!-- Title -->
    <div
      v-if="title"
      data-test="infoColumn__title"
      class="c-InfoColumn__title"
      role="heading"
    >
      {{ title }}
    </div>
    <!-- Sub-title -->
    <div
      v-if="subtitle"
      data-test="infoColumn__subtitle"
      class="c-InfoColumn__subtitle"
      :class="{
        'flex-row-reverse': subtitleRight,
        'text-caption': subtitleBold,
        'font-weight-bold': subtitleBold,
      }"
      :style="{ color: subtitleBold ? subtitleColor : undefined }"
    >
      {{ subtitle }}
    </div>

    <!-- Loop Lines -->
    <dl
      v-for="item in items"
      :key="item.label"
      :aria-label="item.label"
      :style="{ color: item.hexColor ? item.hexColor : undefined }"
      class="justify-space-between"
    >
      <!-- Label -->
      <dt class="d-flex align-center pr-8">
        {{ item.label }}

        <!-- Tooltip -->
        <ce-tooltip
          v-if="item.tooltipEnabled"
          type="info"
          :text="item.tooltipText"
        />
      </dt>

      <!-- Content -->
      <dd>
        <!-- Link -->
        <router-link v-if="item.textLink" :to="item.textLink">
          {{ item.text }}
        </router-link>
        <!-- Text -->
        <template v-else>
          {{ item.text }}
        </template>
      </dd>
    </dl>

    <!-- Link Bottom -->
    <component
      v-if="linkBottom"
      :is="linkBottom.disabled ? 'span' : 'router-link'"
      :to="linkBottom.disabled ? undefined : linkBottom.to"
      :class="{ 'text-grey': linkBottom.disabled }"
      class="c-InfoColumn__link-bottom text-decoration-none"
    >
      {{ linkBottom.text }}
    </component>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { GREY10, NEUTRAL_200 } from '@/constants/colors'
import CeTooltip from '@/components/CeTooltip.vue'
import type { InfoColumnItem, LinkBottom } from './InfoColumn'

/**
 * InfoColumn component.
 *
 * Props
 * - `title`: The first item of the column.
 *
 * - `subtitle`: The second item of the column. Regular text.
 *
 * - `items`: Array of `InfoColumnItem` objects. Each item should contain a
 *   `label` and a `text` properties. The `label` is displayed on the left side
 *    of the line and `text` is displayed on the right of the line.
 *    Each `item` accepts 4 optional properties:
 *      - `textLink`: Vue Router link object, transforming the text in to a
 *        <a> tag.
 *      - `tooltipEnabled`: Activates a tooltip.
 *      - `tooltipIcon`:  The path to the icon `svg`. The icon will be the
 *        activator of the tooltip when `mouseover`.
 *      = `tooltipText`: The text that will be inside the tooltip container.
 *
 * - `linkBottom`: controls a <router-link> displayed at the bottom.
 *
 * NOTE: No props are required to allow versatility.
 *
 * Example:
 *
 * <info-column
 *   title="Title"
 *   subtitle="Subtitle"
 *   :items="infoColumnItems"
 *   :link-bottom="{ text: 'Go to overview', to: { name: 'overview' } }"
 * />
 */
export default defineComponent({
  name: 'InfoColumn',
  props: {
    name: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    subtitleBold: {
      type: Boolean,
      required: false,
      default: false,
    },
    subtitleRight: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array as PropType<InfoColumnItem[]>,
      required: false,
    },
    linkBottom: {
      type: Object as PropType<LinkBottom>,
      required: false,
    },
  },
  components: {
    CeTooltip,
  },
  setup() {
    return { NEUTRAL_200, subtitleColor: GREY10.hex }
  },
})
</script>

<style lang="scss">
.c-InfoColumn {
  &__title,
  &__subtitle,
  dl {
    display: flex;
    align-items: center;
    border-bottom: thin solid var(--border-color);
  }

  &__subtitle,
  dl,
  &__link-bottom {
    font-size: 0.875rem;
    min-height: 1.5rem;
  }

  &__title {
    font-size: 1rem;
    padding: 0 0 1.5rem;
  }

  dt {
    // Label > Tooltip icon.
    img {
      display: block;
      padding-left: 0.25rem;
    }
  }
}
</style>
