import {
  createPinia,
  MutationType,
  StateTree,
  Store,
  SubscriptionCallbackMutation,
} from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import { createDictionaryUtil, type DictionaryUtil } from '@/plugins/vue'
import { Services } from '@/services'
import { RittaConfig } from '@/config'
import { replaceWithType, reviveWithType } from '@/utils/json'
import { Analytics } from '@/utils/analytics'
import { Filters } from '@/model/tables/DataTable'
import { hasFilterValue } from '@/model/tables/helper'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    services: Services
    config: Readonly<RittaConfig>
    dictionary: DictionaryUtil
  }
}

const ONCE = { once: true }

/**
 * Create the pinia global and install a plugin that provides `services`
 * to all stores.
 */
export function newPinia(config: Readonly<RittaConfig>, services: Services) {
  const pinia = createPinia()
  // Initialize the perist plugin with our custom serialization
  const persistPlugin = createPersistedState({
    debug: import.meta.env.DEV,
    storage: window.localStorage,
    serializer: {
      serialize: (val) => JSON.stringify(val, replaceWithType),
      deserialize: (text) => JSON.parse(text, reviveWithType),
    },
    beforeRestore({ options, store }) {
      // Subscribe to the mutation so we can run upgrade code
      if (options.upgradeState) {
        store.$subscribe(doUpgradeState(options.upgradeState, store), ONCE)
      }
    },
  })
  pinia.use(persistPlugin)

  pinia.use(function ConfigPlugin() {
    return { config }
  })

  pinia.use(function ServicesPlugin() {
    return { services }
  })

  pinia.use(function DictionaryPlugin() {
    return { dictionary: createDictionaryUtil(config) }
  })

  // For debug builds, log all the actions to the console.
  if (import.meta.env.DEV && import.meta.env.MODE !== 'test') {
    pinia.use(function DebugPlugin(context) {
      context.store.$onAction(({ store, name, args }) =>
        console.debug(`[Action] ${store.$id}Store.${name}`, args)
      )
    })
  }

  if (config.customer?.analyticsId) {
    pinia.use(function AnalyticsPlugin(context) {
      // TODO: find a home for these action handlers
      context.store.$onAction(({ name, args }) => {
        if (name === 'updateFilters') {
          const filters = args[0] as Filters | null
          // Map over the args to get the list of filters with values
          const fields = Array.from(filters?.entries() || [])
            .filter(([_, field]) => hasFilterValue(field))
            .map(([key]) => key)
          if (fields.length) Analytics.logTableFilter(fields)
        }
      })
    })
  }

  return pinia
}

/** Returns a subscription that invokes `upgradeState` on a patch mutation. */
function doUpgradeState<S extends StateTree>(
  upgradeState: (current: S, saved: Partial<S>) => void,
  store: Store<string, S>
) {
  // Copy the previous state which has all the defaults set
  const prevState = Object.assign({}, store.$state)

  return (mutation: SubscriptionCallbackMutation<S>, state: S) => {
    if (mutation.type === MutationType.patchObject) {
      upgradeState(state, prevState)
    }
  }
}
