import { DateTime } from 'luxon'
import {
  Required,
  RequiredWhenNotUndefined,
  ISODate,
  ISOTime,
  Integer,
  QuarterHour,
  Float,
  FormPercent,
  Custom,
  CustomOptions,
  PositiveFloat,
} from './decorators'

/**
 * Validators return `true` when no problem with the value or
 * an error message.
 */
export type Validator<T = any, U = any> = (
  value: undefined | null | string,
  fieldName: string,
  target: T,
  decoratorParams: U
) => true | string

export const requiredErr = 'Required.'

const requiredValidator: Validator = (v) => (!v ? requiredErr : true)

const requiredWhenNotUndefinedValidator: Validator = (
  v,
  fieldName,
  formFields,
  params
) => {
  return v === undefined
    ? true
    : requiredValidator(v, fieldName, formFields, params)
}

export const isoDateErr = 'Not valid ISO Date.'

const isoDateValidator: Validator = (v) => {
  if (!v) {
    return true
  } else if (
    // Passing only the year ("2024") or year and month ("2024-12") to Luxon.DateTime is considered valid!
    v.split('-').length !== 3
  ) {
    return isoDateErr
  } else if (!DateTime.fromISO(v).isValid) {
    return isoDateErr
  } else {
    return true
  }
}

export const isoTimeErr = 'Not valid ISO Time.'

const isoTimeValidator: Validator = (v) => {
  if (!v) return true

  const [hours, minutes] = v.split(':')

  if (
    !hours ||
    !minutes ||
    Number.isNaN(Number(hours)) ||
    Number.isNaN(Number(minutes))
  ) {
    return isoTimeErr
  }

  const dt = DateTime.fromObject({
    hour: Number(hours),
    minute: Number(minutes),
  })

  return !dt.isValid ? isoTimeErr : true
}

export const quarterHourErr = 'Minutes should be :00, :15, :30 or :45.'
/**
 * It's expected that the value is a valid ISOTime.
 */
const quarterHourValidator: Validator = (v, fieldName, formFields, params) => {
  if (!v) {
    return true
  }

  if (typeof isoTimeValidator(v, fieldName, formFields, params) === 'string') {
    return true
  }

  const [_hours, minutes] = v.split(':')

  return Number(minutes) % 15 !== 0 ? quarterHourErr : true
}

export const integerErr = 'Must be an integer value.'

const integerValidator: Validator = (v) => {
  if (!v) {
    return true
  } else if (Number.isNaN(Number(v)) || !Number.isInteger(Number(v))) {
    return integerErr
  } else {
    return true
  }
}

export const floatErr = 'Must be a float value.'

const floatValidator: Validator = (v) => {
  if (!v) {
    return true
  } else if (Number.isNaN(Number(v)) || !Number.isFinite(Number(v))) {
    return floatErr
  } else {
    return true
  }
}

export const positiveFloatErr = 'Must be a positive value.'

const positiveFloatValidator: Validator = (v) => {
  if (!v) {
    return true
  } else if (
    Number.isNaN(Number(v)) ||
    !Number.isFinite(Number(v)) ||
    Number(v) < 0
  ) {
    return positiveFloatErr
  } else {
    return true
  }
}

export const formPercentErr = 'Must be between 0 and 100.'

const formPercentValidator: Validator = (v) => {
  const num = Number(v)
  if (!v) {
    return true
  } else if (Number.isNaN(num) || num < 0 || num > 100) {
    return formPercentErr
  } else {
    return true
  }
}

const customValidator: Validator<any, CustomOptions> = (
  v,
  fieldName,
  formFields,
  opt
) => {
  try {
    const result = opt.validator(v, fieldName, formFields, opt)
    return result
  } catch (err) {
    const errMsg = 'Something went wrong with validation'
    console.error(
      `customValidator: ${errMsg} of field name "${fieldName}":`,
      err
    )
    return errMsg
  }
}

/**
 * Is expected that all decorators contain a validator function.
 */
export const validators: Record<string, Validator> = {
  [Required.name]: requiredValidator,
  [RequiredWhenNotUndefined.name]: requiredWhenNotUndefinedValidator,
  [ISODate.name]: isoDateValidator,
  [ISOTime.name]: isoTimeValidator,
  [QuarterHour.name]: quarterHourValidator,
  [Integer.name]: integerValidator,
  [Float.name]: floatValidator,
  [PositiveFloat.name]: positiveFloatValidator,
  [FormPercent.name]: formPercentValidator,
  [Custom.name]: customValidator,
}
