<template>
  <nav
    class="d-flex"
    role="tablist"
    aria-label="Page tabs"
    :style="{ backgroundColor }"
  >
    <div
      v-for="ni of navBarItems"
      :key="ni.id"
      :data-test="ni.id"
      class="align-center justify-center px-4 py-3"
      style="border-right: 1px solid white"
      :style="{
        backgroundColor: isNavBarItemSelected(ni)
          ? selectedBackgroundColor
          : undefined,
      }"
      role="tab"
      :aria-selected="isNavBarItemSelected(ni)"
      :aria-disabled="ni.disabled"
    >
      <!-- NavBarItem Selected -->
      <span v-if="isNavBarItemSelected(ni)" class="font-weight-medium">
        {{ ni.label }}
      </span>
      <!-- NavBarItem Disabled -->
      <span v-else-if="ni.disabled" class="text-grey">
        {{ ni.label }}
      </span>
      <!-- Link -->
      <router-link v-else :to="ni.to">{{ ni.label }}</router-link>
    </div>

    <!-- Right Link -->
    <template v-if="rightLink">
      <v-spacer />
      <router-link :to="rightLink.to" class="px-4 py-3">
        {{ rightLink.label }}
      </router-link>
    </template>
  </nav>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { LocationAsRelativeRaw } from 'vue-router'
import { isEqual } from 'lodash-es'
import { GREY11, GRAY_COOL_100, GRAY_COOL_200 } from '@/constants/colors'

export type NavBarItem = {
  id: string
  label: string
  to: LocationAsRelativeRaw
  disabled?: boolean
}

export type RightLink = {
  label: string
  to: LocationAsRelativeRaw
}

export default defineComponent({
  name: 'NavBar',
  props: {
    navBarItems: {
      type: Array as PropType<NavBarItem[]>,
      required: true,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    rightLink: {
      type: Object as PropType<RightLink>,
      required: false,
    },
  },
  computed: {
    backgroundColor() {
      return this.dark ? GRAY_COOL_100.hex : GREY11.hex
    },
    selectedBackgroundColor() {
      return this.dark ? GRAY_COOL_200.hex : GRAY_COOL_100.hex
    },
  },
  methods: {
    isNavBarItemSelected(ni: NavBarItem): boolean {
      return (
        ni.to.name === this.$route.name &&
        isEqual(ni.to.params ?? {}, this.$route.params)
      )
    },
  },
})
</script>
