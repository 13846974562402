<template>
  <div :style="componentStyle">
    <!-- Default slot -->
    <slot />

    <!-- Scoped slots -->
    <div
      v-if="$slots[SLOT_COLUMN_LEFT] || $slots[SLOT_COLUMN_RIGHT]"
      style="height: 100%; display: flex"
    >
      <!-- Left column -->
      <div
        data-test="left-column"
        v-if="$slots[SLOT_COLUMN_LEFT]"
        :style="leftColumnStyle"
      >
        <slot :name="SLOT_COLUMN_LEFT" />
      </div>

      <!-- Right column -->
      <div
        data-test="right-column"
        v-if="$slots[SLOT_COLUMN_RIGHT]"
        style="flex: 1; padding: 0 1.5rem"
      >
        <slot :name="SLOT_COLUMN_RIGHT" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from 'vue'
import {
  DRAWER_LEFT_WIDTH_NARROW,
  DRAWER_LEFT_WIDTH_WIDE,
} from '@/stores/navigationControls'

export const SLOT_COLUMN_LEFT = 'column-left'
export const SLOT_COLUMN_RIGHT = 'column-right'

export default defineComponent({
  name: 'WideLeftPanel',
  setup() {
    const componentStyle: StyleValue = {
      height: '100%',
      width: `${DRAWER_LEFT_WIDTH_WIDE}px`,
    }

    const leftColumnStyle: StyleValue = {
      width: `${DRAWER_LEFT_WIDTH_NARROW}px`,
    }

    return {
      SLOT_COLUMN_LEFT,
      SLOT_COLUMN_RIGHT,
      componentStyle,
      leftColumnStyle,
    }
  },
})
</script>
