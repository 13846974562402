import {
  Interceptor,
  ConnectError,
  Code,
  UnaryRequest,
  UnaryResponse,
  StreamResponse,
} from '@connectrpc/connect'
import { AnyMessage } from '@bufbuild/protobuf'
import { insertHiddenIframe } from '@/utils/iframe'

/** * Copy/paste from '@connectrpc/connect' since it's not exported. */
type AnyFn = (req: UnaryRequest) => Promise<UnaryResponse | StreamResponse>

/** * Number of tries before giving up. */
export const MAX_ATTEMPTS = 10

/** * Time we want to wait between each try, in milliseconds. */
export const WAIT_NEXT_ATTEMPT_MILLIS = 1000 // 1 sec

/**
 * Tries refreshing the authentication token based on a simple logic: visit
 * a URL protected by our Auth Guard. When the user is already logged in by
 * the provider (e.g. Google), the auth token is going to be generated
 * and appended to the cookies back again.
 *
 * How it works: a hidden IFrame element tries to visit said URL.
 * When the token available again the request is going to succeed.
 *
 * TODO(rafael): implement something for when the user not logged in anymore.
 * Note for later: when the user _is_ logged in with Google, simple assets
 * behind the Google Cloud IAP are going to be reachable (e.g. favicons)
 * but RFS won't be reachable;
 * when the user is not logged in even simple assets won't be reachable.
 * In this occasion instead of appending a hidden Iframe do the following:
 * open a new tab with the provider authentication. Something like this:
 * https://cloud.google.com/iap/docs/sessions-howto#ajax_requests
 */
export async function sessionRefreshManager(
  refreshTokenUrl: string,
  next: AnyFn,
  req: UnaryRequest
): Promise<UnaryResponse<AnyMessage> | StreamResponse<AnyMessage>> {
  let _attempts = 0

  return new Promise((resolve, reject) => {
    const iframe = insertHiddenIframe('refreshTokenIframe', refreshTokenUrl)

    const clear = () => {
      iframe.remove()
      clearInterval(interval)
    }

    const interval = setInterval(() => {
      next(req)
        .then((response) => {
          clear()
          // token is working again, return.
          resolve(response)
        })
        .catch((err) => {
          _attempts = _attempts + 1

          if (_attempts >= MAX_ATTEMPTS) {
            clear()
            // Stop trying, reject.
            reject(ConnectError.from(err))
          }
        })
    }, WAIT_NEXT_ATTEMPT_MILLIS)
  })
}

export function isOldToken(err: ConnectError): boolean {
  return err.code === Code.Unauthenticated
}

export function isConnectError(err: any): err is ConnectError {
  return err instanceof ConnectError
}

export function factoryRefreshTokenInterceptor(
  refreshTokenUrl: string
): Interceptor {
  let refreshTokenPromise: null | Promise<any> = null

  return function RefreshTokenInterceptor(next) {
    return async (req) => {
      try {
        return await next(req)
      } catch (err) {
        if (!isConnectError(err) || !isOldToken(err)) throw err

        let imTheOwner = false

        // All failed requests should wait for the same promise.
        // The first failed request that gets here creates the promise.
        if (refreshTokenPromise === null) {
          imTheOwner = true
          refreshTokenPromise = sessionRefreshManager(
            refreshTokenUrl,
            next,
            req as UnaryRequest
          )
        }

        // The moment of truth.
        try {
          const refreshTokenResponse = await refreshTokenPromise
          return imTheOwner ? refreshTokenResponse : next(req)
        } catch {
          // Okay, no luck, throw the first error.
          throw err
        } finally {
          // Clear the hidden state.
          refreshTokenPromise = null
        }
      }
    }
  }
}
