import { IconLayer } from '@deck.gl/layers'
import { UpdateParameters } from '@deck.gl/core'
import { ApplicationMapLayer } from './catalog'
import { MapLayerId, RittaConfig } from '@/config/types'
import { ICON_SIZE, MapManager } from '@/model/map'
import { MapLayerFeature } from '@/model/map/types'
import iconV2GBusApp from '@/assets/imgs/albert_ic_ebus--black.svg'
import iconV2GBusMap from '@/assets/imgs/albert_ic_ebus--halo.svg'
import { getPosition } from './extensions'
import {
  isV2gBusEasterEggEnabled,
  isTodayFriday,
  hasV2gBusEasterEggActivatedBefore,
  addV2gBusEasterEgg,
} from '@/utils/easterEggs/v2gBus'
import { Services } from '@/services'

const ID = MapLayerId.V2G_BUS

function mapManagerLayer(
  config: Readonly<RittaConfig>,
  services: Services,
  _mapManager: MapManager
) {
  /**
   * NOTE(rafael): I'm extending the icon layer so we can trigger the easter egg
   * every time "visible: true".
   */
  class ExtendedIconLayer<DataT> extends IconLayer<DataT> {
    static layerName = 'ExtendedIconLayer'

    updateEasterEgg(isVisible: boolean): void {
      if (
        isVisible &&
        isV2gBusEasterEggEnabled(config) &&
        isTodayFriday() &&
        !hasV2gBusEasterEggActivatedBefore()
      ) {
        addV2gBusEasterEgg()
      }
    }

    updateState(params: UpdateParameters<this>): void {
      // Extension.
      this.updateEasterEgg(Boolean(params.props.visible))

      // Normal execution path.
      super.updateState(params)
    }
  }

  async function fetchV2g(): Promise<Partial<MapLayerFeature>[]> {
    return [
      {
        resource: await services.queryService.getResource(
          'charger/nuvve-cd38e120-db49-4f30-977d-dd9816b54d24'
        ),
      },
    ]
  }

  return new ExtendedIconLayer<MapLayerFeature>({
    id: ID,
    data: fetchV2g(),
    getSize: ICON_SIZE,
    getPosition,
    getIcon: (_obj) => {
      return { url: iconV2GBusMap, width: ICON_SIZE, height: ICON_SIZE }
    },
    loadOptions: {
      imagebitmap: {
        premultiplyAlpha: 'none',
        // NOTE(rafael): it works with any numbers.
        // https://github.com/visgl/deck.gl/issues/5158#issuecomment-731369250
        resizeWidth: 999,
        resizeHeight: 999,
      },
    },
  })
}

export const V2GBus: ApplicationMapLayer = {
  id: ID,
  label: 'V2G bus',
  icon: iconV2GBusApp,
  mapManagerLayer,
}
