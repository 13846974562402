<template>
  <control-mode-template
    v-model="selectedOption"
    :options
    :isLoading
    :isSubmitting
    :msgLoadingFailed
    @try-again="fetchData"
  >
    <!-- Forms -->
    <!-- Controls off -->
    <template v-slot:[Option.CONTROLS_OFF]>
      <controls-off-form
        :isOperatingEnvelopeEnabled
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Controls off -->
    <template v-slot:[Option.ENVELOPES_ONLY]>
      <envelopes-only-form
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Manual -->
    <template v-slot:[Option.MANUAL]>
      <manual-form
        :isOperatingEnvelopeEnabled
        :metric="computedMetric"
        :group-id="group.id"
        :devices
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Recurring Schedule (Waypoint) -->
    <template v-slot:[Option.RECURRING_SCHEDULE]>
      <recurring-waypoint-form
        :ref="REF_FORM"
        :group-id="group.id"
        :metric="computedMetric"
        :futureInterval
        :deviceType
        :powerRating
        :devices
        :currentOEStatus
        :initial-waypoints="
          recurringSchedule ? recurringSchedule.waypoints : undefined
        "
        @new-waypoints="handleNewWaypoints"
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Temporary Schedule -->
    <template v-slot:[Option.TEMPORARY_SCHEDULE]>
      <!-- Event -->
      <template v-if="useEventControls">
        <group-event-temporary-schedule-form
          v-if="latestDeviceSchedule !== null"
          :ref="REF_FORM"
          :futureInterval
          :deviceType
          :powerRating
          :initial-device-schedule="latestDeviceSchedule"
          @new-device-schedule="handleNewDeviceSchedule"
          @cancel="cancelOption"
          @submitting="handleFormSubmitting"
          @success="handleFormSuccess"
        />
      </template>

      <!-- Waypoint -->
      <temporary-waypoint-form
        v-else
        :ref="REF_FORM"
        :group-id="group.id"
        :metric="computedMetric"
        :futureInterval
        :deviceType
        :powerRating
        :devices
        :currentOEStatus
        :initial-waypoints="
          latestSchedule ? latestSchedule.waypointsList : undefined
        "
        @new-waypoints="handleNewWaypoints"
        @cancel="cancelOption"
        @submitting="handleFormSubmitting"
        @success="handleFormSuccess"
      />
    </template>

    <!-- Charts -->
    <waypoint-charts
      :isOperatingEnvelopeEnabled
      :useEventControls
      :maxCapacityWatthours
      :group
      :devices
      :futureInterval
      :metric="computedMetric"
      :schedule="chartSchedule"
      :modified-waypoints="chartModifiedWaypoints"
      :future-device-schedule="chartDeviceSchedule"
      :intervalBroadcaster
      :chartInterval
      @new-chart-interval="handleNewChartInterval"
    />
  </control-mode-template>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { PlanScheduleMetric } from '@/constants/metrics'
import { Timestamp } from '@/services/timestamp_pb'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { GroupHelper, isEnvelopesOnly } from '@/model/control/group/helper'
import { fetchSimulatedManualModeSeries } from '@/model/control/group/GroupWaypointChartData'
import {
  Option,
  optionEnvelopesOnly,
  optionControlsOff,
  optionManual,
  optionRecurringSchedule,
  optionTemporarySchedule,
  type SelectItem,
} from '@/model/control/controlMode'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import { updateIntervalToShowLatestEvent } from '@/model/control/proxy'
import { getPowerRating } from '@/model/resource'
import {
  Metric,
  convertScheduleWaypointsToWaypoints,
  getMaxAllowedDateTime,
  updateIntervalToShowLatestWaypoint,
} from '@/model/control/waypoint'
import ControlsOffForm from '@/components/control/ControlsOffForm.vue'
import EnvelopesOnlyForm from '@/components/control/EnvelopesOnlyForm.vue'
import ControlModeTemplate from '@/components/control/ControlModeTemplate.vue'
import ManualForm from '@/components/control/ManualForm.vue'
import WaypointCharts from '@/components/control/WaypointCharts.vue'
import GroupEventTemporaryScheduleForm from '@/components/control/group/GroupEventTemporaryScheduleForm.vue'
import TemporaryWaypointForm from '@/components/control/TemporaryWaypointForm.vue'
import RecurringWaypointForm from '@/components/control/RecurringWaypointForm.vue'
import type { Device, DeviceType, Group } from 'rfs/control/proto/model_pb'
import type { DeviceSchedule } from 'rfs/device/proto/proxy_pb'
import type { Schedule } from 'rfs/control/proto/control_service_pb'
import { ScheduleTimeSeriesResponse } from 'rfs/frontend/proto/controls_pb'
import { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { Resource } from 'rfs/pb/resource_pb'
import { Policy } from 'rfs/control/proto/policy_pb'

/**
 * Vue 2 lacks intellisense support for component methods.
 */
interface ScheduleFormComponent {
  shouldChangeRoute: (next: Function) => void
  blockFetchLatestSchedule: () => boolean
}

export default defineComponent({
  name: 'ControlMode',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    resources: {
      type: Array as PropType<Resource[]>,
      required: false,
      default: () => [],
    },
    /**
     * This value should only be used for Groups that belong to
     * Locations.
     *
     * The only way to transform user input in SoC (state of charge, %) to
     * watt hours is using this value below to do the math.
     *
     * This transformation is require only for the charts.
     */
    maxCapacityWatthours: {
      type: Number,
      required: false,
    },
    /**
     * Instructs components to refresh their data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<IntervalBroadcaster>,
      required: false,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
  },
  components: {
    ControlModeTemplate,
    ControlsOffForm,
    EnvelopesOnlyForm,
    ManualForm,
    WaypointCharts,
    GroupEventTemporaryScheduleForm,
    TemporaryWaypointForm,
    RecurringWaypointForm,
  },
  setup() {
    return { Option }
  },
  data() {
    const now = this.$observationTime()

    return shallowReactive({
      now,
      REF_FORM: 'REF_FORM',
      // NOTE(rafael): the chart interval should be here so this component can
      // manipulate the interval when the planned schedule gets modified.
      chartInterval: Interval.fromDateTimes(
        now.minus({ hours: 24 }),
        now.endOf('day').plus({ hours: 24 }) // End of tomorrow.
      ),
      selectedOption: null as null | Option,
      isLoading: false,
      hasLoadingFailed: false,
      msgLoadingFailed: '',
      isSubmitting: false,
      groupSupportedMetrics: null as null | Metric[],
      latestDeviceSchedule: null as null | DeviceSchedule,
      modifiedDeviceSchedule: null as null | DeviceSchedule,
      latestSchedule: null as null | ScheduleTimeSeriesResponse,
      modifiedWaypoints: null as null | Waypoint[],
      recurringSchedule: null as null | Schedule,
      /**
       * These waypoints are used only by the chart to create a visual
       * simulation of what a recurring schedule would look like
       * in the future.
       */
      recurringWaypointsAsWaypoints: undefined as undefined | Waypoint[],
      /**
       * The power rating is used for "targetValue" validation and the table's
       * header tooltip.
       */
      powerRating: undefined as undefined | number,
    })
  },
  computed: {
    deviceType(): DeviceType {
      return this.group.deviceType
    },
    useEventControls(): boolean {
      return GroupHelper.isGroupOfV2g(this.group)
    },
    computedMetric(): Metric {
      // TODO(rafael): Nuvve group returns no supported metric yet.
      if (this.useEventControls) {
        return Metric.METRIC_ACTIVE_POWER_WATTS
      } else if (this.groupSupportedMetrics?.length) {
        // NOTE: Use the first metric available.
        return this.groupSupportedMetrics[0]
      } else {
        return Metric.METRIC_UNSPECIFIED
      }
    },
    hasAnyScheduleOptionAvailable(): boolean {
      return this.options.some(
        (opt) =>
          opt.value === Option.TEMPORARY_SCHEDULE ||
          opt.value === Option.RECURRING_SCHEDULE
      )
    },
    optionControlsOff(): SelectItem {
      return {
        ...optionControlsOff,
        props: {
          disabled: this.group.currentPolicy === Policy.DEFAULT,
        },
      }
    },
    optionEnvelopesOnly(): SelectItem {
      return {
        ...optionEnvelopesOnly,
        props: {
          disabled: isEnvelopesOnly(this.group.currentPolicyParams),
        },
      }
    },
    isOperatingEnvelopeEnabled(): boolean {
      return this.currentOEStatus !== undefined
    },
    options(): SelectItem[] {
      const items: SelectItem[] = []

      if (GroupHelper.hasControlsOff(this.group)) {
        items.push(this.optionControlsOff)
      }

      if (this.isOperatingEnvelopeEnabled) {
        items.push(this.optionEnvelopesOnly)
      }

      if (GroupHelper.hasManualControls(this.group)) {
        items.push(optionManual)
      }

      if (
        !this.useEventControls &&
        GroupHelper.hasAutomaticControls(this.group)
      ) {
        items.push(optionRecurringSchedule)
      }

      if (GroupHelper.hasAutomaticControls(this.group)) {
        items.push(optionTemporarySchedule)
      }

      return items
    },
    futureInterval(): Interval {
      return Interval.fromDateTimes(this.now, getMaxAllowedDateTime(this.now))
    },
    /**
     * On initial render, supply the chart with the latest device schedule.
     * Once the user modifies the temporary schedule form, update the
     * chart with the modified device schedule. If the fetch request
     * fails, do not supply any data to the chart component.
     */
    chartDeviceSchedule(): undefined | DeviceSchedule {
      return (
        this.modifiedDeviceSchedule || this.latestDeviceSchedule || undefined
      )
    },
    chartSchedule(): undefined | ScheduleTimeSeriesResponse {
      if (this.useEventControls || this.chartModifiedWaypoints) {
        return undefined
      } else if (this.latestSchedule) {
        return this.latestSchedule
      } else {
        return undefined
      }
    },
    chartModifiedWaypoints(): undefined | Waypoint[] {
      // The user touched one of the forms.
      if (this.modifiedWaypoints) {
        return this.modifiedWaypoints
      } else if (this.scheduleId) {
        // Recurring Schedule.
        return this.recurringWaypointsAsWaypoints
      } else {
        return undefined
      }
    },
    scheduleId(): undefined | bigint {
      return GroupHelper.getCurrentScheduleId(this.group.currentPolicyParams)
    },
  },
  watch: {
    group(newValue: Group, oldValue: Group) {
      if (newValue.id !== oldValue.id) {
        this.fetchData()
      }
    },
    now(): void {
      this.fetchData({ refresh: true })
    },
    selectedOption(): void {
      this.resetModifiedState()
    },
  },
  created() {
    this.intervalBroadcaster?.subscribe(this.updateNow)
    this.fetchData()
  },
  beforeUnmount(): void {
    this.intervalBroadcaster?.unsubscribe(this.updateNow)
  },
  methods: {
    updateNow(): void {
      this.now = this.$observationTime()
    },
    getForm() {
      return this.$refs[this.REF_FORM] as undefined | ScheduleFormComponent
    },
    /**
     * Allows the Schedule Form component to prompt the user
     * for confirmation before changing routes.
     */
    shouldChangeRoute(next: Function): void {
      const formComponent = this.getForm()

      if (formComponent) {
        formComponent.shouldChangeRoute(next)
      } else {
        next()
      }
    },
    handleNewChartInterval(newInterval: Interval): void {
      this.chartInterval = newInterval
    },
    cancelOption(): void {
      this.selectedOption = null
    },
    resetModifiedState(): void {
      this.modifiedDeviceSchedule = null
      this.modifiedWaypoints = null
    },
    handleFormSubmitting(newValue: boolean): void {
      this.isSubmitting = newValue
    },
    handleFormSuccess(): void {
      this.$emit('policy-updated')
    },
    async handleNewDeviceSchedule(
      newDeviceSchedule: DeviceSchedule
    ): Promise<void> {
      this.modifiedDeviceSchedule = newDeviceSchedule
      this.chartInterval = updateIntervalToShowLatestEvent(
        this.chartInterval,
        newDeviceSchedule
      )
    },
    handleNewWaypoints(newWaypoints: Waypoint[]): void {
      this.modifiedWaypoints = newWaypoints

      // TODO(rafael): Update the chart interval when power waypoints.
      this.chartInterval = updateIntervalToShowLatestWaypoint(
        this.chartInterval,
        newWaypoints
      )
    },
    resetState(): void {
      this.selectedOption = null
      this.hasLoadingFailed = false
      this.msgLoadingFailed = ''
      this.groupSupportedMetrics = null
      this.latestDeviceSchedule = null
      this.latestSchedule = null
      this.recurringSchedule = null
      this.recurringWaypointsAsWaypoints = undefined
      this.resetModifiedState()
    },
    /** * Each group has a list of supported metrics. */
    async fetchMetrics(): Promise<void> {
      const { supportedMetrics } =
        await this.$services.control.getGroupSupportedMetricTypes({
          id: this.group.id,
        })
      this.groupSupportedMetrics = supportedMetrics
    },
    async findPowerRating(): Promise<void> {
      // We can only discovver the power rating in groups that contain
      // a single Device (single Resource).
      if (this.devices.length !== 1) return

      // We only need "powerRating" for the following metric.
      if (this.computedMetric !== Metric.METRIC_ACTIVE_POWER_WATTS) return

      try {
        const device = this.devices[0]
        const resourceId = device.camusResourceId

        if (!resourceId) {
          throw new Error(`device "${device.id}" has no resource ID`)
        }

        const resource = await this.$services.queryService.getResource(
          resourceId
        )

        this.powerRating = getPowerRating(resource)
      } catch (err) {
        console.error('ControlMode.findPowerRating: %o', err)
      }
    },
    async fetchData({ refresh } = { refresh: false }): Promise<void> {
      if (refresh) {
        if (!this.shouldRefreshData()) return
      } else {
        this.isLoading = true
        this.resetState()
      }

      try {
        if (!refresh) {
          await this.fetchMetrics()
          await this.findPowerRating()
        }

        // NOTE: For the cases where the group has no automatic
        // controls just skip fetching schedule data.
        if (!this.hasAnyScheduleOptionAvailable) return

        if (this.useEventControls) {
          await this.fetchLatestDeviceSchedule()
        } else if (this.scheduleId) {
          await this.fetchRecurringSchedule()
        } else if (isEnvelopesOnly(this.group.currentPolicyParams)) {
          // When group running in "Envelopes only", use an empty
          // response so nothing is displayed on the charts.
          this.latestSchedule = new ScheduleTimeSeriesResponse()
        } else if (this.group.currentPolicy === Policy.MANUAL_CONTROL_EVENT) {
          this.latestSchedule = await fetchSimulatedManualModeSeries(
            this.$services,
            this.group,
            this.computedMetric,
            this.$observationTime()
          )
        } else {
          await this.fetchLatestSchedule()
        }
      } catch (err) {
        this.hasLoadingFailed = true
        this.msgLoadingFailed =
          (err as any).message ??
          (refresh
            ? 'Something went wrong when refreshing the data.'
            : 'Something went wrong fetching the initial data.')
        console.error('ControlMode.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    /**
     * If the user is already editing a schedule, avoid
     * disrupting the user experience with new data.
     */
    shouldRefreshData(): boolean {
      const formComponent = this.getForm()

      return (
        !formComponent ||
        !(formComponent && formComponent.blockFetchLatestSchedule())
      )
    },
    /**
     * When "Event" controls, fetches the latest device schedule.
     */
    async fetchLatestDeviceSchedule(): Promise<void> {
      // TODO(rafael): only a single device is expected for now.
      if (!this.devices.length || this.devices.length > 1) {
        throw new Error('unexpected number of devices')
      }

      this.latestDeviceSchedule = await this.$services.proxy.deviceGetSchedule({
        deviceId: this.devices[0].id,
        startTime: Timestamp.fromDateTime(this.futureInterval.start),
        endTime: Timestamp.fromDateTime(this.futureInterval.end),
      })
    },
    async fetchLatestSchedule(): Promise<void> {
      this.latestSchedule = await this.$services.controlsData.fetchPlanSchedule(
        this.group.id,
        {
          interval: this.futureInterval,
          // Ask for all plan scheule metrics.
          metrics: Object.values(PlanScheduleMetric).map((m) => ({
            metric: m,
          })),
        }
        // NOTE(rafael): not sending any "waypoints" means asking for
        // the current scheduled waypoints.
      )
    },
    async fetchRecurringSchedule(): Promise<void> {
      if (!this.scheduleId) throw new Error('"scheduleId" is required')

      const { schedule } = await this.$services.control.getSchedule({
        id: this.scheduleId,
      })

      if (!schedule) {
        throw new Error(
          `recurring schedule not found for scheduleId "${this.scheduleId}"`
        )
      }

      this.recurringSchedule = schedule

      this.recurringWaypointsAsWaypoints = convertScheduleWaypointsToWaypoints(
        this.recurringSchedule.waypoints,
        this.futureInterval,
        this.recurringSchedule.metric
      )
    },
  },
})
</script>
