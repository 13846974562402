// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file frontend/proto/alert.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AlertListRequest, AlertListResponse } from "./alert_pb.js";
import { Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.AlertService
 */
export const AlertService = {
  typeName: "ritta.frontend.proto.AlertService",
  methods: {
    /**
     * Returns a list of alerts with filtering and sorting
     *
     * @generated from rpc ritta.frontend.proto.AlertService.ListAlerts
     */
    listAlerts: {
      name: "ListAlerts",
      I: AlertListRequest,
      O: AlertListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * This RPC is used only by KCEC's Alerts page and is not enabled for them currently
     *
     * @generated from rpc ritta.frontend.proto.AlertService.AlertsV2
     * @deprecated
     */
    alertsV2: {
      name: "AlertsV2",
      I: Empty,
      O: AlertListResponse,
      kind: MethodKind.Unary,
    },
  }
};

