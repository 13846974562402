import { InfoColumnAllProps } from '@/components/InfoColumn'
import { MapLayerId } from '@/config/types'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { ResourceType } from '@/constants/resourceType'
import {
  MapLayerFeature,
  TILE_SIZE,
  ZOOM_CONDUCTORS_MED,
  ZOOM_TRANSFORMERS,
} from '@/model/map'
import { getUnqualifiedId } from '@/model/resource'
import { formatPhase } from '@/model/resource/conductor/phase'
import { Format } from '@/utils/format'
import { ApplicationMapLayer } from './catalog'
import { ResourceMapLayer } from './ResourceMapLayer'

import iconBreaker from '@/assets/imgs/albert_ic_breaker--black.svg'
import iconCapacitor from '@/assets/imgs/albert_ic_capacitor--black.svg'
import iconFuse from '@/assets/imgs/albert_ic_fuse--black.svg'
import iconRecloser from '@/assets/imgs/albert_ic_recloser--black.svg'
import iconRegulator from '@/assets/imgs/albert_ic_regulator--black.svg'
import iconSectionalizer from '@/assets/imgs/albert_ic_sectionalizer--black.svg'
import iconSwitch from '@/assets/imgs/albert_ic_switch--black.svg'

export const BreakerLayer: ApplicationMapLayer = {
  id: MapLayerId.BREAKER,
  label: 'Breaker',
  icon: iconBreaker,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.BREAKER,
    resourceType: ResourceType.BREAKER,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Breaker'),
  },
}

export const CapacitorLayer: ApplicationMapLayer = {
  id: MapLayerId.CAPACITOR,
  label: 'Capacitor',
  icon: iconCapacitor,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.CAPACITOR,
    resourceType: ResourceType.CAPACITOR,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Capacitor'),
  },
}

export const FuseLayer: ApplicationMapLayer = {
  id: MapLayerId.FUSE,
  label: 'Fuse',
  icon: iconFuse,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.FUSE,
    resourceType: ResourceType.FUSE,
    minZoom: ZOOM_TRANSFORMERS,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Fuse'),
  },
}

export const RecloserLayer: ApplicationMapLayer = {
  id: MapLayerId.RECLOSER,
  label: 'Recloser',
  icon: iconRecloser,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.RECLOSER,
    resourceType: ResourceType.RECLOSER,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Recloser'),
  },
}

export const RegulatorLayer: ApplicationMapLayer = {
  id: MapLayerId.REGULATOR,
  label: 'Regulator',
  icon: iconRegulator,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.REGULATOR,
    resourceType: ResourceType.REGULATOR,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Regulator'),
  },
}

export const SectionalizerLayer: ApplicationMapLayer = {
  id: MapLayerId.SECTIONALIZER,
  label: 'Sectionalizer',
  icon: iconSectionalizer,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.SECTIONALIZER,
    resourceType: ResourceType.SECTIONALIZER,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Sectionalizer'),
  },
}

export const SwitchLayer: ApplicationMapLayer = {
  id: MapLayerId.SWITCH,
  label: 'Switch',
  icon: iconSwitch,
  mapManagerLayer: ResourceMapLayer({
    id: MapLayerId.SWITCH,
    resourceType: ResourceType.SWITCH,
    minZoom: ZOOM_CONDUCTORS_MED,
    tileSize: TILE_SIZE,
    pickable: true,
  }),
  infoWindow: {
    infoColumn: (f) => getInfoColumnProps(f, 'Switch'),
  },
}

function getInfoColumnProps(
  { resource }: MapLayerFeature,
  title: string
): InfoColumnAllProps {
  const isRecloser = resource!.type === ResourceType.RECLOSER

  return {
    title,
    subtitle: getUnqualifiedId(resource!),
    items: [
      {
        label: isRecloser ? 'Continuous rating' : 'Rating',
        text: Format.fmtAmps(resource!.ratings?.current),
      },
      {
        label: 'Phasing',
        text: formatPhase(resource!) || TEXT_NO_VALUE,
      },
    ],
  }
}
