<template>
  <div class="d-flex flex-column pt-6 position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-else>
      <!-- Breadcrumbs and Summary Boxes -->
      <v-row justify-sm="end" class="flex-grow-0 px-6 mb-13">
        <!-- Breadcrumbs -->
        <v-col sm="12" md="6">
          <resource-header :breadcrumbs small stand-out-last-item />
        </v-col>

        <!-- Upper Right -->
        <v-col
          data-test="upper-right"
          sm="12"
          md="6"
          class="d-flex justify-end"
        >
          <status-boxes
            :isRefreshing
            :currentPolicy
            :currentPolicyParams
            :currentOEStatus
            :communicationStatusMap
            :communicationStatus
            :lastUpdate
          />
        </v-col>
      </v-row>

      <!-- Nav Bar -->
      <nav-bar :navBarItems class="mx-6 mb-12" />

      <!-- Body Content slot -->
      <slot />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { DateTime } from 'luxon'
import type { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import type {
  CommunicationStatusMap,
  CommunicationStatusType,
} from '@/model/control/communicationStatus'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ResourceHeader from '@/components/ResourceHeader.vue'
import type { BreadcrumbItem } from '@/components/ResourceHeader'
import NavBar, { type NavBarItem } from '@/components/common/NavBar.vue'
import StatusBoxes from '@/components/control/StatusBoxes.vue'
import type { Policy, Params } from 'rfs/control/proto/policy_pb'

export default defineComponent({
  name: 'ControlTemplate',
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbItem[]>,
      required: false,
      default: () => [],
    },
    navBarItems: {
      type: Array as PropType<NavBarItem[]>,
      required: false,
      default: () => [],
    },
    currentPolicy: {
      type: Number as PropType<Policy>,
      required: false,
    },
    currentPolicyParams: {
      type: Object as PropType<Params>,
      required: false,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRefreshing: {
      type: Boolean,
      required: false,
      default: false,
    },
    communicationStatusMap: {
      type: Map as PropType<CommunicationStatusMap>,
      required: false,
    },
    communicationStatus: {
      type: Number as PropType<CommunicationStatusType>,
      required: false,
    },
    lastUpdate: {
      type: Object as PropType<DateTime>,
      required: false,
    },
  },
  components: { CenteredSpinner, ResourceHeader, NavBar, StatusBoxes },
})
</script>
