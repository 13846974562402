import { TEXT_NO_VALUE } from '@/constants/formatText'
import { InfoColumnAllProps } from '@/model/types'
import { Resource } from 'rfs/pb/resource_pb'
import { getDisplayName, getPowerRating } from '../..'
import { Format, MEGA_OPTIONS } from '@/utils/format'

export const BatteryStationHelper = {
  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    return {
      title: 'Utility Battery',
      items: [
        {
          label: 'Name',
          text: getDisplayName(r) ?? TEXT_NO_VALUE,
        },
        {
          label: 'Capacity, power',
          text: this.getPowerRating(r) ?? TEXT_NO_VALUE,
        },
        {
          label: 'Capacity, energy',
          text: this.getEnergyRating(r) ?? TEXT_NO_VALUE,
        },
      ],
    }
  },
  getPowerRating(r: Resource): string {
    return Format.fmtWatts(getPowerRating(r), MEGA_OPTIONS)
  },
  getEnergyRating(r: Resource): string {
    return Format.fmtEnergy(r.ratings?.watthours, MEGA_OPTIONS)
  },
}
