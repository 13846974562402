// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file pb/calendar.proto (package ritta.pb, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * A `CalendarPeriod` represents the abstract concept of a time period that has
 * a canonical start. Grammatically, "the start of the current
 * `CalendarPeriod`." All calendar times begin at midnight UTC.
 *
 * CAMUS: Copied from https://github.com/googleapis/googleapis/blob/master/google/type/calendar_period.proto
 *
 * @generated from enum ritta.pb.CalendarPeriod
 */
export const CalendarPeriod = /*@__PURE__*/ proto3.makeEnum(
  "ritta.pb.CalendarPeriod",
  [
    {no: 0, name: "CALENDAR_PERIOD_UNSPECIFIED"},
    {no: 1, name: "DAY"},
    {no: 2, name: "WEEK"},
    {no: 3, name: "FORTNIGHT"},
    {no: 4, name: "MONTH"},
    {no: 5, name: "QUARTER"},
    {no: 6, name: "HALF"},
    {no: 7, name: "YEAR"},
  ],
);

/**
 * Represents a whole or partial calendar date, such as a birthday. The time of
 * day and time zone are either specified elsewhere or are insignificant. The
 * date is relative to the Gregorian Calendar. This can represent one of the
 * following:
 *
 * * A full date, with non-zero year, month, and day values
 * * A month and day value, with a zero year, such as an anniversary
 * * A year on its own, with zero month and day values
 * * A year and month value, with a zero day, such as a credit card expiration
 * date
 *
 * CAMUS: Copied from https://github.com/googleapis/googleapis/blob/master/google/type/date.proto
 *
 * @generated from message ritta.pb.Date
 */
export const Date = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.pb.Date",
  () => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

