import { TEXT_NO_VALUE } from '@/constants/formatText'

/**
 * Returns the same string when the string is not empty.
 *
 * When the string is empty (or `undefined`), returns the constant for
 * "no value" string.
 */
export function formatText(value?: string): string {
  return value !== undefined && value !== '' ? value : TEXT_NO_VALUE
}

export function titleCase(value: string): string {
  return value
    .split(' ')
    .map((w) => `${w[0].toUpperCase()}${w.substring(1)}`)
    .join(' ')
}

/**
 * Make a phone number look a little nicer.
 * @see https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 */
export function formatPhoneNumber(phone: string | undefined): string {
  if (!phone) {
    return TEXT_NO_VALUE
  }
  const groups = phone.match(/^(\d{3})(\d{3})(\d{4})$/)

  return groups ? `(${groups[1]}) ${groups[2]}-${groups[3]}` : phone
}

export const numberNoDecimals = new Intl.NumberFormat('en-US', {
  useGrouping: true,
  maximumFractionDigits: 0,
})
