<template>
  <div class="c-InfoColumnGroup">
    <info-column
      v-for="(item, idx) of group"
      :key="item.id"
      v-bind="item.infoColumn"
      :class="{ 'pb-6': idx + 1 !== group.length }"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import InfoColumn from '@/components/InfoColumn.vue'
import { Group } from '@/components/InfoColumnGroup'

export default defineComponent({
  name: 'InfoColumnGroup',
  props: {
    group: {
      type: Array as PropType<Group>,
      required: true,
    },
  },
  components: { InfoColumn },
})
</script>
