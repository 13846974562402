import { Filters, Header, VisibleHeaders } from './DataTable'
import type { DictionaryUtil } from '@/plugins/vue'

export function updateHeaderLabels(
  dict: DictionaryUtil,
  headers: Header[]
): Header[] {
  return headers.map((header) => ({ ...header, title: dict(header.title) }))
}

export function createInitialVisibleHeaders(headers: Header[]): VisibleHeaders {
  return headers.reduce<VisibleHeaders>((acc, h) => {
    if (!h.columnSelector?.dependentOn) acc.set(h.key, true)
    return acc
  }, new Map())
}

function isHeaderVisible(
  header: Header,
  headers: Header[],
  selectedMap: VisibleHeaders
): boolean {
  // Depends on another header.
  if (header.columnSelector?.dependentOn) {
    const theOtherHeader = headers.find(
      (h) => h.key === header.columnSelector?.dependentOn
    )
    return theOtherHeader
      ? isHeaderVisible(theOtherHeader, headers, selectedMap)
      : false
  } else {
    // Depends only in itself.
    return !!selectedMap.get(header.key)
  }
}

export function filterVisibleHeaders(
  headers: Header[],
  visibleHeaders: VisibleHeaders
): Header[] {
  return headers.reduce<Header[]>((acc, h) => {
    if (isHeaderVisible(h, headers, visibleHeaders)) {
      acc.push(h)
    }
    return acc
  }, [])
}

type ExpectedClassProps = undefined | string | string[]

export function applyStickyCssClass(headers: Header[]): Header[] {
  const newHeaders = [...headers]

  // Sticky first column.
  newHeaders[0] = {
    ...newHeaders[0],
    headerProps: (() => {
      const newClass = ['stick-to-the-left--header']

      const currentClass: ExpectedClassProps = newHeaders[0]?.headerProps?.class

      if (Array.isArray(currentClass)) {
        // Move the classes to tne new array.
        currentClass.forEach((c) => newClass.unshift(c))
      } else if (currentClass) {
        newClass.unshift(currentClass)
      }

      return { class: newClass }
    })(),
    cellProps: (() => {
      const newClass = ['stick-to-the-left--cell']

      const currentClass: ExpectedClassProps = newHeaders[0]?.cellProps?.class

      if (Array.isArray(currentClass)) {
        currentClass.forEach((c) => newClass.unshift(c))
      } else if (currentClass) {
        newClass.unshift(currentClass)
      }

      return { class: newClass }
    })(),
  }

  return newHeaders
}

export function upgradeVisibleHeaders(
  currentVisibleHeaders: VisibleHeaders,
  initialVisibleHeaders: VisibleHeaders
): void {
  // 1. The current visible headers map may be missing some newly added headers.
  for (const [key, _] of initialVisibleHeaders) {
    // New header detected, start with it as selected.
    if (!currentVisibleHeaders.has(key)) {
      currentVisibleHeaders.set(key, true)
    }
  }

  // 2. The current visible headers map may have some removed headers.
  for (const [key, _] of currentVisibleHeaders) {
    // Old header detected, remove it from the map.
    if (!initialVisibleHeaders.has(key)) {
      currentVisibleHeaders.delete(key)
    }
  }
}

export function upgradeFilters(
  currentFilters: Filters,
  initialFilters: Filters
): void {
  // Add missing filters to current filters
  for (const [key, filter] of initialFilters) {
    if (!currentFilters.has(key)) {
      currentFilters.set(key, filter)
    }
  }

  // Remove old filters from current filters.
  for (const [key, _] of currentFilters) {
    if (!initialFilters.has(key)) {
      currentFilters.delete(key)
    }
  }
}
