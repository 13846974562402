import { Loader } from '@googlemaps/js-api-loader'
import { RittaConfig } from '@/config'
import {
  DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL,
  DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
  DEFAULT_GMAPS_TILT,
} from './constants'
import { assoc } from '@/utils/immutable'

/**
 * Returns the API Key for GMaps based on the environment or the customer's
 * config.
 */
function getApiKey(config: Readonly<RittaConfig>): string {
  const apiKey = import.meta.env.PROD
    ? config.map.apiKey
    : (import.meta.env.VITE_GMAPS_API_KEY as string | undefined)

  if (!apiKey) throw new Error('no api key available')

  return apiKey
}

/** * Creates the base GMaps Options to be used with Map Manager. */
function createOptions(config: Readonly<RittaConfig>): google.maps.MapOptions {
  return {
    center: config.map.startingCoordinates,
    mapId: config.map.mapId,
    zoom: DEFAULT_GMAPS_INITIAL_ZOOM_LEVEL,
    minZoom: DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
    tilt: DEFAULT_GMAPS_TILT,
    clickableIcons: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: true,
  }
}

export async function loadGmaps(config: Readonly<RittaConfig>): Promise<void> {
  await new Loader({
    apiKey: getApiKey(config),
    version: 'quarterly',
    language: 'en-US',
  }).importLibrary('maps')
}

export async function createGmap(
  config: Readonly<RittaConfig>,
  el: HTMLElement
): Promise<google.maps.Map> {
  await loadGmaps(config)

  const options = createOptions(config)

  return new google.maps.Map(el, options)
}

export async function createGeocoder() {
  const lib = await google.maps.importLibrary('geocoding')

  return new (lib as google.maps.GeocodingLibrary).Geocoder()
}

export async function createAutocompleteService() {
  const lib = await google.maps.importLibrary('places')

  return new (lib as google.maps.PlacesLibrary).AutocompleteService()
}

export function makeSize(size?: { width: number; height: number }) {
  return size ? new google.maps.Size(size.width, size.height) : undefined
}

/**
 * For map styles that have a default `{ "visibility": "off" }`,
 * this function will return the style with visibility toggled "on" or "off".
 */
export function setMapStyleVisibility(
  style: google.maps.MapTypeStyle,
  show: boolean
): google.maps.MapTypeStyle {
  const visibility = { visibility: show ? 'on' : 'off' }
  const index = style.stylers.findIndex((s: any) => s.visibility === 'off')
  const stylers = style.stylers.map((s: any, i) =>
    i === index ? visibility : s
  )
  return assoc(style, 'stylers', stylers)
}

export enum ControlPosition {
  TOP_LEFT = 1.0,
  TOP_CENTER = 2.0,
  TOP_RIGHT = 3.0,
  LEFT_CENTER = 4.0,
  LEFT_TOP = 5.0,
  LEFT_BOTTOM = 6.0,
  RIGHT_TOP = 7.0,
  RIGHT_CENTER = 8.0,
  RIGHT_BOTTOM = 9.0,
  BOTTOM_LEFT = 10.0,
  BOTTOM_CENTER = 11.0,
  BOTTOM_RIGHT = 12.0,
}
