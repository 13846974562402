<template>
  <v-menu offset="12">
    <!-- Activator -->
    <template v-slot:activator="{ props }">
      <div class="cursor-pointer pa-3" v-bind="props">
        <v-badge v-if="unreadCount" color="error" :content="unreadCount">
          <v-icon icon="mdi-alert-circle" />
        </v-badge>
        <v-icon v-else icon="mdi-alert-circle" />
      </div>
    </template>

    <!-- Content -->
    <div
      class="d-flex flex-column bg-white pa-4 overflow-y-auto elevation-5 rounded"
      style="width: 25.5rem; max-height: 42rem"
    >
      <!-- No Notifications -->
      <v-alert
        v-if="!notifications.length"
        color="grey"
        class="mb-0"
        dense
        dark
      >
        No active notifications
      </v-alert>

      <!-- Notifications -->
      <template v-else>
        <!-- Title -->
        <span class="d-block text-subtitle-1 font-weight-bold pb-4">
          Current notifications (i.e. unread)
        </span>

        <!-- Items -->
        <notification-menu-item
          v-for="(notification, idx) of notifications"
          :key="notification.id"
          :class="{ 'mb-2': idx !== notifications.length - 1 }"
          :notification
        />
      </template>

      <!-- Link -->
      <router-link
        class="d-block text-right font-weight-medium pt-4"
        :to="link"
      >
        {{ linkText }}
      </router-link>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, shallowReactive } from 'vue'
import { LocationAsRelativeRaw as VueRouterLocation } from 'vue-router'
import * as RouteNames from '@/router/routeNames'
import { createFiltersForNotificationsMenu } from '@/model/notification/NotificationsDataTable'
import NotificationMenuItem from '@/components/monitor/NotificationMenuItem.vue'
import {
  ListNotificationsRequest,
  type Notification,
} from 'rfs/frontend/proto/notification_pb'
import { createDefaultOptions } from '@/model/tables/helper'
import { convertFilters, convertOrderBy } from '@/model/tables/column'

export default defineComponent({
  name: 'NotificationsMenu',
  components: { NotificationMenuItem },
  setup() {
    const link: VueRouterLocation = { name: RouteNames.NOTIFICATIONS }
    return {
      link,
      linkText: 'See all notifications',
      abortController: new AbortController(),
    }
  },
  data() {
    return shallowReactive({
      hasLoadingFailed: false,
      isRefreshing: false,
      notifications: [] as Notification[],
    })
  },
  computed: {
    unreadCount(): undefined | string {
      return this.notifications.length
        ? this.notifications.length.toString()
        : undefined
    },
  },
  created(): void {
    this.fetchData()
  },
  beforeUnmount(): void {
    this.abortController.abort()
  },
  methods: {
    async fetchData(): Promise<void> {
      try {
        const iter = this.$services.notificationService.streamNotifications(
          new ListNotificationsRequest({
            offset: 0,
            limit: 999,
            filterBy: convertFilters(createFiltersForNotificationsMenu()),
            orderBy: convertOrderBy(
              createDefaultOptions('startTime', 'descending').orderBy
            ),
          }),
          { signal: this.abortController.signal }
        )

        for await (const response of iter) {
          // TODO(rafael): don't filter when real notifications from RFS.
          this.notifications = response.notifications.filter(
            (notification) => !notification.read
          )
        }
      } catch (err) {
        this.hasLoadingFailed = true
        console.error('NotificationsMenu.fetchData: %o', err)
      }
    },
  },
})
</script>
