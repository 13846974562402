// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/capacity.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Resolution } from "./resolution_pb.js";

/**
 * @generated from enum ritta.frontend.proto.ResourceGroup
 */
export const ResourceGroup = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.ResourceGroup",
  [
    {no: 0, name: "UNKNOWN_RESOURCE_GROUP"},
    {no: 1, name: "ALL"},
    {no: 2, name: "SUPPLY"},
    {no: 3, name: "DEMAND"},
    {no: 4, name: "SUPPLY_CARBON_FREE"},
    {no: 5, name: "TEST"},
  ],
);

/**
 * @generated from message ritta.frontend.proto.SupplyDemandRequest
 */
export const SupplyDemandRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.SupplyDemandRequest",
  () => [
    { no: 1, name: "resourceGroup", kind: "enum", T: proto3.getEnumType(ResourceGroup) },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 9, name: "resolution", kind: "enum", T: proto3.getEnumType(Resolution) },
  ],
);

