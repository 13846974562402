<template>
  <div>
    <form
      aria-label="Envelope Status Form"
      class="d-flex"
      @submit.prevent="openModal"
    >
      <v-radio-group v-model="radioOptionSelected" class="py-1">
        <v-radio
          v-for="option in radioGroupOptions"
          :key="option"
          :label="generateRadioLabel(option)"
          :value="option"
          :disabled="isOptionDisabled(option)"
        />
      </v-radio-group>

      <!-- Action buttons -->
      <div>
        <!-- Cancel -->
        <v-btn
          :disabled="isSubmitting"
          color="primary"
          class="mr-2"
          variant="outlined"
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>

        <!-- Submit -->
        <v-btn
          type="submit"
          :disabled="isSubmitting || radioOptionSelected === undefined"
          :loading="isSubmitting"
          color="primary"
          flat
        >
          Apply
        </v-btn>
      </div>
    </form>

    <!-- Modal Confirmation -->
    <dialog-confirm
      v-if="isModalOpened"
      :is-loading="isSubmitting"
      :title="modalTitle"
      :body="modalText"
      @cancel="closeModal"
      @confirm="submitOperatingEnvelope"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'
import { OperatingEnvelopeStatus } from '@/model/control/operatingEnvelope'
import DialogConfirm from '@/components/common/DialogConfirm.vue'
import type { Device } from 'rfs/control/proto/model_pb'

const RADIO_OPTION_ACTIVATE = 'ACTIVATE' as const
const RADIO_OPTION_DEACTIVATE = 'DEACTIVATE' as const

const radioGroupOptions = [
  RADIO_OPTION_ACTIVATE,
  RADIO_OPTION_DEACTIVATE,
] as const

type RadioOption = (typeof radioGroupOptions)[number]

export default defineComponent({
  name: 'EnvelopeStatusForm',
  props: {
    devices: {
      type: Array as PropType<Device[]>,
      required: true,
    },
    currentOEStatus: {
      type: Number as PropType<OperatingEnvelopeStatus>,
      required: true,
    },
    /**
     * When the component being used in a Single Device page.
     */
    isSingleDevice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['cancel', 'submitting', 'success'],
  components: { DialogConfirm },
  setup() {
    return { radioGroupOptions, globalSnackbarStore: useGlobalSnackbar() }
  },
  data() {
    return shallowReactive({
      radioOptionSelected: undefined as undefined | RadioOption,
      isSubmitting: false,
      isModalOpened: false,
    })
  },
  computed: {
    activateLabel(): string {
      return this.isSingleDevice
        ? 'Activate operating envelope for the device.'
        : 'Activate operating envelope for the entire group.'
    },
    deactivateLabel(): string {
      return this.isSingleDevice
        ? 'Deactivate operating envelope for the device.'
        : 'Deactivate operating envelope for the entire group.'
    },
    modalTitle(): string {
      if (!this.radioOptionSelected) return ''

      return this.radioOptionSelected === RADIO_OPTION_ACTIVATE
        ? 'Activate envelope?'
        : 'Deactivate envelope?'
    },
    modalText(): string {
      if (!this.radioOptionSelected) return ''

      const texts = {
        [RADIO_OPTION_ACTIVATE]: this.isSingleDevice
          ? 'This will immediately activate the operating envelope for the device.'
          : 'This will immediately activate the operating envelope for all devices in the group.',
        [RADIO_OPTION_DEACTIVATE]: this.isSingleDevice
          ? 'This will immediately deactivate the operating envelope for the device.'
          : 'This will immediately deactivate the operating envelope for all devices in the group.',
      }

      return texts[this.radioOptionSelected] || ''
    },
  },
  methods: {
    generateRadioLabel(option: RadioOption): string {
      return option === RADIO_OPTION_ACTIVATE
        ? this.activateLabel
        : this.deactivateLabel
    },
    isOptionDisabled(option: RadioOption): boolean {
      if (
        // When "Partial/Mixed", allow both options.
        this.currentOEStatus === OperatingEnvelopeStatus.PARTIAL ||
        (option === RADIO_OPTION_ACTIVATE &&
          this.currentOEStatus === OperatingEnvelopeStatus.INACTIVE) ||
        (option === RADIO_OPTION_DEACTIVATE &&
          this.currentOEStatus === OperatingEnvelopeStatus.ACTIVE)
      ) {
        return false
      }

      return true
    },
    openModal(): void {
      this.isModalOpened = true
    },
    closeModal(): void {
      this.isModalOpened = false
    },
    async submitOperatingEnvelope(): Promise<void> {
      this.$emit('submitting', true)
      this.isSubmitting = true

      try {
        if (!this.devices.length) throw new Error('no devices available')

        await this.$services.control.updateDevices({
          updates: this.devices.map((d) => {
            return {
              deviceId: d.id,
              oeEnabled: this.radioOptionSelected === RADIO_OPTION_ACTIVATE,
            }
          }),
        })

        const msg =
          this.radioOptionSelected === RADIO_OPTION_ACTIVATE
            ? 'Operating envelope successfully activated.'
            : 'Operating envelope successfully deactivated.'

        this.globalSnackbarStore.openSnackbar(msg, 'info')

        this.closeModal()

        this.$emit('success')
      } catch (err) {
        this.globalSnackbarStore.openSnackbar(
          (err as any).message || 'Something went wrong. try again.',
          'error'
        )
        console.error('EnvelopeStatusForm.submitOperatingEnvelope: %o', err)
      } finally {
        this.$emit('submitting', false)
        this.isSubmitting = false
      }
    },
  },
})
</script>
