import { DateTime, Interval } from 'luxon'
import {
  DEFAULT_TILE_SIZE,
  DataProvider,
  TimeSeriesDataSource,
  TimeSeriesMap,
  TimeSeriesConfig,
  combineSeries,
  emptySeries,
  EventName,
  EventCallbacks,
  NamedTimeSeries,
} from './TimeSeriesDataSource'
import { TimeSeries } from '@/services/charts.service'

export class TimeSeriesWithRefreshDataSource extends TimeSeriesDataSource {
  private cutOffDate: DateTime
  private cacheRangeCutOff: Interval
  private cachedCutOffSeries: Map<TimeSeriesConfig, TimeSeries> = new Map()
  private events: { [K in EventName]: EventCallbacks[K][] } = {
    [EventName.IS_REFRESHING]: [],
    [EventName.HAS_REFRESHED_SUCCESSFULLY]: [],
  }

  constructor(
    chartDataProvider: DataProvider,
    cutOffDate = DateTime.now(),
    tileSize = DEFAULT_TILE_SIZE
  ) {
    super(chartDataProvider, cutOffDate, tileSize)
    this.cutOffDate = cutOffDate
    this.cacheRangeCutOff = Interval.fromDateTimes(cutOffDate, cutOffDate)
  }

  private async fetchBeforeCutOffDateAndCache(
    metrics: TimeSeriesConfig[],
    visibleInterval: Interval
  ): Promise<void> {
    // 1a. Enumerate all the tiles needed to fill [start, end).
    const tiles = this.getNextTiles(visibleInterval)
    // 1b. Adjust the tiles so they go until the cut-off date.
    const trimmedTiles = trimTiles(tiles, this.cutOffDate)

    // 2. Make requests for those tiles.
    const response = await this.callProvider(trimmedTiles, metrics)

    // 4. Put them in the cache by prepending the new data into the current data.
    for (const metric of metrics) {
      const curSeries = this.cachedSeries.get(metric)
      // Get the response data that corresponds to this series config.
      const newSeries = this.findSeriesFromResponse(metric, response)
      // If we have cached data, append it because it comes after the new data.
      if (curSeries) newSeries.push(curSeries)

      this.cachedSeries.set(metric, combineSeries(newSeries))
    }

    // 5. The cache now has the newly-fetched tile + the previous range
    this.cacheRange = trimmedTiles.reduce(
      (acc, interval) => acc.union(interval),
      this.cacheRange
    )
  }

  private async fetchFromCutOffDateAndCache(
    metrics: TimeSeriesConfig[],
    cutOffInterval: Interval
  ): Promise<void> {
    const cutOffResponse = await this.provider({
      interval: cutOffInterval,
      metrics,
    })

    // Cache series and interval.
    for (const metric of metrics) {
      const newSeries = this.findSeriesFromResponse(metric, cutOffResponse)
      this.cachedCutOffSeries.set(metric, combineSeries(newSeries))
    }
    this.cacheRange = this.cacheRange.union(cutOffInterval)
    this.cacheRangeCutOff = cutOffInterval
  }

  async fetchTimeSeries(visibleInterval: Interval): Promise<TimeSeriesMap> {
    // 1. Is the data already in the cache?
    if (this.cacheRange.engulfs(visibleInterval)) {
      // If yes, return the full data set
      return this.getTimeSeriesMap()
    }

    // If not,
    const metrics = Array.from(this.configs.values()).flat()
    // Quick out if there are no metrics
    if (metrics.length === 0) return this.getTimeSeriesMap()

    const promises: Promise<void>[] = [
      this.fetchBeforeCutOffDateAndCache(metrics, visibleInterval),
    ]

    if (
      visibleInterval.contains(this.cutOffDate) ||
      visibleInterval.start > this.cutOffDate
    ) {
      const cutOffInterval = Interval.fromDateTimes(
        this.cutOffDate,
        visibleInterval.end
      )

      // What's on the cache is not enough.
      if (!this.cacheRangeCutOff.engulfs(cutOffInterval)) {
        // Refresh the cache.
        promises.push(
          this.fetchFromCutOffDateAndCache(metrics, cutOffInterval).catch(
            (err) =>
              console.error(
                ` [TimeSeriesWithRefreshDataSource]: interval "${cutOffInterval}"`,
                err
              )
          )
        )
      }
    }

    await Promise.all(promises)

    // 5. Return the full data set
    return this.getTimeSeriesMap()
  }

  getTimeSeriesMap(): TimeSeriesMap {
    const map = new Map() as TimeSeriesMap

    this.configs.forEach((configs, chart) => {
      map.set(
        chart,
        configs.map((c): NamedTimeSeries => {
          const beforeCutOffTimeSeries =
            this.cachedSeries.get(c) ?? emptySeries(c.metric)
          const cutOffTimeSeries =
            this.cachedCutOffSeries.get(c) ?? emptySeries(c.metric)

          return {
            ...combineSeries([beforeCutOffTimeSeries, cutOffTimeSeries]),
            ...c,
          }
        })
      )
    })

    return map
  }

  async updateFromCutOffDate(newEndTime: DateTime): Promise<void> {
    // Quick out if there are no metrics
    const metrics = Array.from(this.configs.values()).flat()
    if (metrics.length === 0) return

    // Broadcast.
    this.publish(EventName.IS_REFRESHING, true)

    const cutOffInterval = Interval.fromDateTimes(this.cutOffDate, newEndTime)

    try {
      // Fetch.
      await this.fetchFromCutOffDateAndCache(metrics, cutOffInterval)

      // Broadcast.
      this.publish(EventName.HAS_REFRESHED_SUCCESSFULLY, undefined)
    } catch (err) {
      console.error(
        ` [TimeSeriesWithRefreshDataSource]: interval "${cutOffInterval}"`,
        err
      )
    } finally {
      // Broadcast.
      this.publish(EventName.IS_REFRESHING, false)
    }
  }

  subscribe<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void {
    this.events[eventName].push(cb)
  }

  unsubscribe<K extends EventName>(eventName: K, cb: EventCallbacks[K]): void {
    if (this.events[eventName]) {
      const cbIndex = this.events[eventName].indexOf(cb)
      if (cbIndex !== -1) {
        this.events[eventName].splice(cbIndex, 1)
      }
    }
  }

  publish<K extends EventName>(
    eventName: K,
    data: Parameters<EventCallbacks[K]>[0]
  ): void {
    if (this.events[eventName]) {
      for (const cb of this.events[eventName]) {
        if (data !== undefined) {
          cb(data)
        } else {
          ;(cb as () => void)()
        }
      }
    }
  }
}

/**
 * Trims intervals that pass the cut-off date.
 */
function trimTiles(intervals: Interval[], cutOffDate: DateTime): Interval[] {
  const newIntervals: Interval[] = []

  for (const interval of intervals) {
    // Discard the interval that is entirely after the cut-off date.
    if (interval.start > cutOffDate) continue

    newIntervals.push(
      interval.contains(cutOffDate)
        ? // The interval needs to be adjusted/trimmed.
          Interval.fromDateTimes(interval.start, cutOffDate)
        : interval
    )
  }

  return newIntervals
}
