import { Services } from '@/services'
import { MapLayerId, RittaConfig } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { MapLayerFeature } from '@/model/map'
import { getPowerRating } from '@/model/resource'
import { InfoColumnAllProps } from '@/model/types'
import { Format } from '@/utils/format'
import {
  COMMON_SIZE_DIVISOR_13,
  DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
  ICON_OFFSET_LEFT,
} from '@/model/map/constants'
import { ApplicationMapLayer } from './catalog'
import {
  CustomTileLayer,
  getTileResponseLayers,
  scalingIconProps,
} from './extensions'

import iconDistributedBatteryApp from '@/assets/imgs/albert_ic_battery_distributed--black.svg'

const ID = MapLayerId.DISTRIBUTED_BATTERY

function mapManagerLayer(_config: Readonly<RittaConfig>, services: Services) {
  return new CustomTileLayer({
    id: ID,
    minZoom: DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
    maxZoom: DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
    tileSize: 2 ** 12, // Larger tile size as there are fewer distrib. batteries in a grid
    pickable: true,
    getTileData: async (props) => {
      return services.tileService.getTileResources(
        ResourceType.BATTERY_DISTRIBUTED,
        props
      )
    },
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        icons: scalingIconProps(ICON_OFFSET_LEFT, COMMON_SIZE_DIVISOR_13),
      })
    },
  })
}

export const DistributedBattery: ApplicationMapLayer = {
  id: ID,
  label: 'Distributed Battery',
  icon: iconDistributedBatteryApp,
  infoWindow: {
    infoColumn: (f) => infoWindowInfoColumn(f),
  },
  mapManagerLayer,
}

function infoWindowInfoColumn(f: MapLayerFeature): InfoColumnAllProps {
  const r = f.resource!
  const siteId = r.site?.id

  return {
    title: siteId ? `Site: ${siteId}` : TEXT_NO_VALUE,
    items: [
      {
        label: 'Power Capacity',
        text: Format.fmtWatts(getPowerRating(r)) || TEXT_NO_VALUE,
      },
      {
        label: 'Energy Capacity',
        text: Format.fmtEnergy(r.ratings?.watthours) || TEXT_NO_VALUE,
      },
    ],
  }
}
