import { Services } from '@/services'
import { MapLayerId, RittaConfig } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL } from '@/model/map/constants'
import { BatteryStationHelper } from '@/model/resource/battery/station/helper'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'

import iconUtilityBatteryApp from '@/assets/imgs/albert_ic_battery_utility--black.svg'

const ID = MapLayerId.UTILITY_BATTERY

function mapManagerLayer(_config: Readonly<RittaConfig>, services: Services) {
  return new CustomTileLayer({
    id: ID,
    minZoom: DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
    maxZoom: DEFAULT_GMAPS_MINIMUM_ZOOM_LEVEL,
    tileSize: 2 ** 12, // Larger tile size as there are fewer station batteries in a grid
    pickable: true,
    getTileData: async (props) => {
      return services.tileService.getTileResources(
        ResourceType.BATTERY_STATION,
        props
      )
    },
    renderSubLayers(props) {
      return getTileResponseLayers(props, {})
    },
  })
}

export const UtilityBattery: ApplicationMapLayer = {
  id: ID,
  label: 'Utility Battery',
  icon: iconUtilityBatteryApp,
  infoWindow: {
    infoColumn: (f) => BatteryStationHelper.infoWindowInfoColumn(f.resource!),
  },
  mapManagerLayer,
}
