import { ResourceType } from '@/constants/resourceType'
import { getResourceType } from '@/model/resource'
import { Device, DeviceType } from 'rfs/control/proto/model_pb'

export const DeviceHelper = {
  typeToCategoryName(type: DeviceType, config?: { plural: boolean }): string {
    const { plural } = config ?? {}

    switch (type) {
      case DeviceType.CHARGEPOINT_EV_CHARGER:
      case DeviceType.FORD_CDS_CHARGER:
      case DeviceType.ABB_EVSE:
        return plural ? 'Charging stations' : 'Charging station'
      case DeviceType.NUVVE_EVSE:
        return plural ? 'V2G buses' : 'V2G bus'
      case DeviceType.HEILA_MICROGRID_CONTROLLER:
        return plural ? 'Microgrids' : 'Microgrid'
      case DeviceType.TESLA_POWERWALL_BATTERY:
      case DeviceType.EMULATE_VIRTUAL_BATTERY:
      case DeviceType.STEM_BATTERY:
      case DeviceType.TRIMARK_BATTERY:
        return plural ? 'Batteries' : 'Storage'
      case DeviceType.SOLECTRIA_INVERTER:
      case DeviceType.CHINT_INVERTER:
      case DeviceType.TRIMARK_INVERTER:
        return 'Generation'
      default:
        return 'Unknown DeviceType'
    }
  },

  typeToResourceType(type: DeviceType): undefined | ResourceType {
    switch (type) {
      case DeviceType.CHARGEPOINT_EV_CHARGER:
      case DeviceType.FORD_CDS_CHARGER:
      case DeviceType.ABB_EVSE:
        return ResourceType.CHARGER
      case DeviceType.HEILA_MICROGRID_CONTROLLER:
        return ResourceType.MICROGRID
      case DeviceType.TESLA_POWERWALL_BATTERY:
        return ResourceType.BATTERY_DISTRIBUTED
      case DeviceType.EMULATE_VIRTUAL_BATTERY:
        return ResourceType.POOL_DEVICE
      case DeviceType.STEM_BATTERY:
      case DeviceType.TRIMARK_BATTERY:
        return ResourceType.BATTERY_STATION
      case DeviceType.SOLECTRIA_INVERTER:
      case DeviceType.CHINT_INVERTER:
      case DeviceType.TRIMARK_INVERTER:
        return ResourceType.SOLAR_FARM
      // Note: LPEA's V2G bus (NUVVE_EVSE) is `charger/nuvve-12345` but using `ResourceType.VEHICLE`
      // to differentiate it from other `ResourceType.CHARGER`
      case DeviceType.NUVVE_EVSE:
        return ResourceType.VEHICLE
      case DeviceType.UNSPECIFIED:
      default:
        return undefined
    }
  },

  isBattery(device: Device): boolean {
    return (
      device.detailsOneof.case === 'teslaPowerwallBattery' ||
      device.detailsOneof.case === 'stemBattery' ||
      device.detailsOneof.case === 'trimarkBattery'
    )
  },

  isV2g(device: Device): boolean {
    return device.detailsOneof.case === 'nuvveEvse'
  },

  getPoolId(device: Device): undefined | string {
    return getResourceType(device.camusResourceId) === ResourceType.POOL_DEVICE
      ? device.camusResourceId
      : undefined
  },
}
