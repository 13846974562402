// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file control/proto/control_service.proto (package ritta.partner.hce.control.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateOptoutRequest, CreateOptoutResponse, CreateScheduleRequest, CreateScheduleResponse, DeleteScheduleRequest, GetDeviceRequest, GetGroupRequest, GetGroupSupportedMetricTypesRequest, GetGroupSupportedMetricTypesResponse, GetScheduleRequest, GetScheduleResponse, GroupHistoryRequest, GroupHistoryResponse, HistoricalScheduleRequest, HistoricalScheduleResponse, ListDevicesRequest, ListDevicesResponse, ListGroupsRequest, ListGroupsResponse, ListSchedulesRequest, ListSchedulesResponse, ScheduleRequest, ScheduleResponse, SetGroupPolicyRequest, SubmitWaypointsResponse, UpdateDevicesRequest, UpdateDevicesResponse } from "./control_service_pb.js";
import { Device, Group } from "./model_pb.js";
import { BoolValue, Empty, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.partner.hce.control.proto.Control
 */
export const Control = {
  typeName: "ritta.partner.hce.control.proto.Control",
  methods: {
    /**
     * @generated from rpc ritta.partner.hce.control.proto.Control.GetGroup
     */
    getGroup: {
      name: "GetGroup",
      I: GetGroupRequest,
      O: Group,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the list of valid waypoint metric types for this group
     * This is independent of the GetGroup request because the Group cannot contain
     * references to Metrics without a circular reference, and the GetGroup response
     * cannot be evolved easily
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.GetGroupSupportedMetricTypes
     */
    getGroupSupportedMetricTypes: {
      name: "GetGroupSupportedMetricTypes",
      I: GetGroupSupportedMetricTypesRequest,
      O: GetGroupSupportedMetricTypesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.partner.hce.control.proto.Control.ListGroups
     */
    listGroups: {
      name: "ListGroups",
      I: ListGroupsRequest,
      O: ListGroupsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * CanSetGroupPolicy returns `true` if and only if the currently authenticated
     * user is authorized to call `SetGroupPolicy`.  This is intended to be used
     * to provide UI/UX hints on the application that indicate if the signed-in
     * user may or may not issue control commands.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.CanSetGroupPolicy
     */
    canSetGroupPolicy: {
      name: "CanSetGroupPolicy",
      I: Empty,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * SetGroupPolicy notifies the control server of an intend to change the
     * being enforced for a particular group.  Only one policy may be in force at
     * any given time for each group.  The only feedback is simply whether or not
     * the command was successfully received; as a result, the response message
     * is `google.protobuf.Empty`, and clients should simply check for RPC errors.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.SetGroupPolicy
     */
    setGroupPolicy: {
      name: "SetGroupPolicy",
      I: SetGroupPolicyRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * GroupHistory returns a log of all commands that have been issued against
     * a particular control group.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.GroupHistory
     */
    groupHistory: {
      name: "GroupHistory",
      I: GroupHistoryRequest,
      O: GroupHistoryResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.partner.hce.control.proto.Control.GetDevice
     */
    getDevice: {
      name: "GetDevice",
      I: GetDeviceRequest,
      O: Device,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc ritta.partner.hce.control.proto.Control.ListDevices
     */
    listDevices: {
      name: "ListDevices",
      I: ListDevicesRequest,
      O: ListDevicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Allows updating group membership and operating envelope (OE) enabled
     * All other fields for the Device model are pulled from the resource collection
     * NOTE: Does not validate that the device exists in the current resource collection,
     * if it does not, then this has no practical effect (until the resource does appear)
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.UpdateDevices
     */
    updateDevices: {
      name: "UpdateDevices",
      I: UpdateDevicesRequest,
      O: UpdateDevicesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * UpdateDataModel triggers an update where the server will look for the latest
     * data file in GCS and update its internal data model with changes.
     * NOTE: This is currently not intended for programatic use. This is for us to
     * force a data model refresh on the control server if HCE requests it.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.UpdateDataModel
     */
    updateDataModel: {
      name: "UpdateDataModel",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * HistoricalScheduleData returns actual and forecasted series from the requested
     * time span. It also returns waypoints that were submitted in that time span.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.HistoricalSchedule
     */
    historicalSchedule: {
      name: "HistoricalSchedule",
      I: HistoricalScheduleRequest,
      O: HistoricalScheduleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * PlanSchedule takes a ScheduleRequest, runs the day ahead scheduling algorithm
     * with any waypoints provided and returns what would happen in the requested time
     * with the requested waypoints. If no waypoints are included in the request then the last
     * submitted waypoints are used. Also returns forecasts for the requested time.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.PlanSchedule
     */
    planSchedule: {
      name: "PlanSchedule",
      I: ScheduleRequest,
      O: ScheduleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * SubmitWaypoints finalizes a set of waypoints for a given time range. If any other waypoints
     * were submitted in the time range then they are deleted and overwritten with the waypoints in this request.
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.SubmitWaypoints
     */
    submitWaypoints: {
      name: "SubmitWaypoints",
      I: ScheduleRequest,
      O: SubmitWaypointsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get a specific schedule
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.GetSchedule
     */
    getSchedule: {
      name: "GetSchedule",
      I: GetScheduleRequest,
      O: GetScheduleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Lists all schedules for the given group id
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.ListSchedules
     */
    listSchedules: {
      name: "ListSchedules",
      I: ListSchedulesRequest,
      O: ListSchedulesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a new schedule, but does not enable it.  To do that, use
     * SetGroupPolicy with an appropraite AutomaticControlEvent
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.CreateSchedule
     */
    createSchedule: {
      name: "CreateSchedule",
      I: CreateScheduleRequest,
      O: CreateScheduleResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Deletes a specific schedule if it exists, and is not currently in use
     * by the group
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.DeleteSchedule
     */
    deleteSchedule: {
      name: "DeleteSchedule",
      I: DeleteScheduleRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Creates an opt out that prevents a list of devices from being controlled during an event.
     * If the event does not exist, or the devices are not a member of the event's group
     * then this call has no effect (but is not an error)
     *
     * @generated from rpc ritta.partner.hce.control.proto.Control.CreateOptout
     */
    createOptout: {
      name: "CreateOptout",
      I: CreateOptoutRequest,
      O: CreateOptoutResponse,
      kind: MethodKind.Unary,
    },
  }
};

