<template>
  <div class="c-TimeRangeSlider">
    <v-range-slider
      :model-value="range"
      :min="min"
      :max="max"
      :step="step"
      :ticks="tickLabels"
      :tick-size="tickSize"
      show-ticks="always"
      track-size="2"
      track-color="gray"
      track-fill-color="primary"
      thumb-size="12"
      thumb-color="primary"
      density="compact"
      :disabled="disabled"
      hide-details
      @update:model-value="captureNewRange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime, Interval } from 'luxon'
import { TickLabels } from '@/types/vuetify3'

function isValidInterval(interval: Interval | undefined): boolean {
  return !!interval && interval.start.hasSame(interval.end, 'day')
}

function transformTimeTo10MinuteBlocks(datetime: DateTime): number {
  return datetime.equals(datetime.endOf('day'))
    ? 144
    : datetime.hour * 6 + Math.round(Math.floor(datetime.minute / 10))
}

function transform10MinuteBlocksToHour(blocks: number): number {
  return Math.round((blocks * 10) / 60)
}

export default defineComponent({
  name: 'TimeRangeSlider',
  props: {
    /* v-model */
    modelValue: {
      type: Object as PropType<Interval>,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const ERROR_INVALID_INTERVAL =
      'TimeRangeSlider: `interval` must have equal `start` and `end` dates'

    if (!isValidInterval(this.modelValue)) {
      throw new Error(ERROR_INVALID_INTERVAL)
    }

    const range: number[] = [
      transformTimeTo10MinuteBlocks(this.modelValue.start),
      transformTimeTo10MinuteBlocks(this.modelValue.end),
    ]

    const tickLabels: TickLabels = {
      0: '00:00',
      36: '06:00',
      72: '12:00',
      108: '18:00',
      143: '00:00',
    }

    return {
      ERROR_INVALID_INTERVAL,
      range,
      tickLabels,
      min: 0,
      max: 144 /* 10 minute blocks in a day */,
      step: 1 /* Each step represents 10 minutes */,
      tickSize: 0,
    }
  },
  watch: {
    modelValue(newInterval: Interval): void {
      if (!isValidInterval(this.modelValue)) {
        throw new Error(this.ERROR_INVALID_INTERVAL)
      }

      const newRange = [
        transformTimeTo10MinuteBlocks(newInterval.start),
        transformTimeTo10MinuteBlocks(newInterval.end),
      ]

      /* Only capture new interval when different one */
      if (newRange[0] !== this.range[0] || newRange[1] !== this.range[1]) {
        this.range = newRange
      }
    },
    range(newRange: number[]) {
      const startHour = transform10MinuteBlocksToHour(newRange[0])
      const startMinute = (newRange[0] - startHour * 6) * 10
      const newStart = this.modelValue.start.set({
        hour: startHour,
        minute: startMinute,
        second: 0,
        millisecond: 0,
      })

      /* end of day */
      let newEnd: DateTime
      if (newRange[1] === 144) {
        newEnd = this.modelValue.end.set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        })
      } else {
        const endHour = transform10MinuteBlocksToHour(newRange[0])
        const endMinute = (newRange[1] - endHour * 6) * 10
        newEnd = this.modelValue.end.set({
          hour: endHour,
          minute: endMinute,
          second: 0,
          millisecond: 0,
        })
      }
      this.$emit('update:model-value', Interval.fromDateTimes(newStart, newEnd))
    },
  },
  methods: {
    captureNewRange(newRange: number[]): void {
      if (
        /* User selected equal start and end times, do nothing */
        newRange[0] === newRange[1] ||
        /* `v-model` updated, do nothing */
        (newRange[0] === this.range[0] && newRange[1] === this.range[1])
      ) {
        return
      }
      this.range = newRange
    },
  },
})
</script>

<style lang="scss">
.c-TimeRangeSlider {
  .v-slider-track__tick--first {
    transform: translateX(-1rem);
  }
}
</style>
