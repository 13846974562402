<template>
  <time-series-chart-group
    :charts
    :dataSource
    :interval="chartInterval"
    :max-end-time="futureInterval.end"
    :now="futureInterval.start"
    :intervalBroadcaster
    @new-interval="handleNewChartInterval"
    @new-time-series="handleNewTimeSeries"
  >
    <!-- Summary Box -->
    <template
      v-for="summary of summaryBoxes"
      :key="summary.title"
      v-slot:[`right-side-of-${summary.chartId}`]
    >
      <telemetry-summary-box :summary :isRefreshing />
    </template>
  </time-series-chart-group>
</template>

<script lang="ts">
import { defineComponent, type PropType, shallowReactive } from 'vue'
import { Interval } from 'luxon'
import { isEqual } from 'lodash-es'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import { IntervalBroadcaster } from '@/utils/time/IntervalBroadcaster'
import { limitInterval } from '@/utils/time'
import {
  AggregateDataSource,
  TimeSeriesDataSource,
  type ITimeSeriesDataSource,
  type TimeSeriesMap,
} from '@/model/charts'
import { chartFormattersByChartType } from '@/model/charts/formatters'
import { EventName } from '@/model/charts/TimeSeriesDataSource'
import { DATA_POINT_AGE_LIMIT_MINUTES } from '@/model/control/device/DeviceTelemetryChartData'
import { Metric } from '@/model/control/waypoint'
import {
  eventChartDefinition,
  newGroupEventHistoricalDataSource,
  newGroupEventFutureDataSource,
} from '@/model/control/group/GroupEventChartData'
import {
  dispatchChartDefinition,
  waypointChartDefinition,
  newGroupWaypointHistoricalDataSource,
  newGroupWaypointFutureDataSource,
  powerWaypointChartDefinition,
  newGroupTelemetryDataSource,
  newGroupPowerWaypointFutureDataSource,
  wattHourWaypointChartDefinition,
  newWattHourFutureDataSource,
  newWattHourHistoricalDataSource,
} from '@/model/control/group/GroupWaypointChartData'
import { createOperatingEnvelpeDataSource } from '@/model/control/operatingEnvelope'
import { getFirstTimeSeriesSummary } from '@/model/control/telemetry'
import {
  locationWaypointCharts,
  newLocationWaypointHistoricalDataSource,
  newLocationWaypointFutureDataSource,
} from '@/model/control/location/LocationWaypointChartData'
import { TimeSeriesChartGroup } from '@/components/common'
import TelemetrySummaryBox, {
  TelemetrySummary,
} from '@/components/control/TelemetrySummaryBox.vue'
import type { ScheduleTimeSeriesResponse } from 'rfs/frontend/proto/controls_pb'
import type { Waypoint } from 'rfs/control/proto/waypoints_pb'
import type { ChartDefinition, NumberOrNull } from '@/types/charts'
import type { Device, DeviceType, Group } from 'rfs/control/proto/model_pb'
import type { DeviceSchedule } from 'rfs/device/proto/proxy_pb'

type MaybeSchedule = undefined | ScheduleTimeSeriesResponse
type MaybeModifiedWaypoints = undefined | Waypoint[]
type MaybeDeviceSchedule = undefined | DeviceSchedule
type ShouldResetFutureDataSource = [
  string,
  Interval,
  MaybeSchedule,
  MaybeModifiedWaypoints,
  MaybeDeviceSchedule
]

export interface SummaryWithChartId extends TelemetrySummary {
  chartId: string
}

// NOTE(rafael): These are the metrics that this component supports for now.
export const validMetrics = [
  Metric.METRIC_MAX_CHARGE_POWER_WATTS,
  Metric.METRIC_SOC_PERCENT,
  Metric.METRIC_SOC_WATT_HOURS,
  Metric.METRIC_ACTIVE_POWER_WATTS,
]

export default defineComponent({
  name: 'WaypointCharts',
  props: {
    group: {
      type: Object as PropType<Group>,
      required: true,
    },
    metric: {
      type: Number as PropType<Metric>,
      required: true,
      validator(value, _props) {
        return validMetrics.includes(value as Metric)
      },
    },
    devices: {
      type: Array as PropType<Device[]>,
      required: false,
      default: () => [],
    },
    chartInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    futureInterval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    schedule: {
      type: Object as PropType<MaybeSchedule>,
      required: false,
    },
    modifiedWaypoints: {
      type: Array as PropType<MaybeModifiedWaypoints>,
      required: false,
    },
    isOperatingEnvelopeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specific for LPEA's V2G Nuvve.
     */
    useEventControls: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Specific for LPEA's V2G Nuvve.
     */
    futureDeviceSchedule: {
      type: Object as PropType<DeviceSchedule>,
      required: false,
    },
    /**
     * When the component is dealing with a Location this value is expected
     * so the "target_value" (%) can be converted to kW.
     */
    maxCapacityWatthours: {
      type: Number as PropType<number>,
      required: false,
    },
    /**
     * Instructs component to refresh chart data, signaled by parent component.
     */
    intervalBroadcaster: {
      type: Object as PropType<IntervalBroadcaster>,
      required: false,
    },
  },
  components: { TimeSeriesChartGroup, TelemetrySummaryBox },
  data() {
    return shallowReactive({
      historicalDataSource: TimeSeriesDataSource.emptyDataSource(),
      futureDataSource: TimeSeriesDataSource.emptyDataSource(),
      isRefreshing: false,
      latestTimeSeriesMap: new Map() as TimeSeriesMap,
    })
  },
  computed: {
    groupId(): string {
      return this.group.id
    },
    deviceType(): DeviceType {
      return this.group.deviceType
    },
    useLocationSpecific(): boolean {
      return (
        this.maxCapacityWatthours !== undefined &&
        this.metric === Metric.METRIC_SOC_PERCENT
      )
    },
    charts(): ChartDefinition[] {
      if (this.useEventControls) {
        return [eventChartDefinition]
      } else if (this.useLocationSpecific) {
        return locationWaypointCharts
      } else if (this.metric === Metric.METRIC_SOC_PERCENT) {
        return [waypointChartDefinition, dispatchChartDefinition]
      } else if (this.metric === Metric.METRIC_ACTIVE_POWER_WATTS) {
        return [dispatchChartDefinition, waypointChartDefinition]
      } else if (this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS) {
        return [powerWaypointChartDefinition]
      } else if (this.metric === Metric.METRIC_SOC_WATT_HOURS) {
        return [wattHourWaypointChartDefinition, dispatchChartDefinition]
      } else {
        return []
      }
    },
    dataSource(): ITimeSeriesDataSource {
      return new AggregateDataSource(
        this.historicalDataSource,
        this.futureDataSource
      )
    },
    summaryBoxes(): SummaryWithChartId[] {
      // NOTE(rafael): don't add summary boxes for this case.
      if (this.useLocationSpecific) return []

      // For each chart, create a summary box.
      return this.charts.reduce((acc, chart) => {
        const items = [
          { label: 'Min', text: TEXT_NO_VALUE },
          { label: 'Max', text: TEXT_NO_VALUE },
          { label: 'Mean', text: TEXT_NO_VALUE },
        ]

        const summary: SummaryWithChartId = {
          chartId: chart.id,
          title: TEXT_NO_VALUE,
          lastValue: TEXT_NO_VALUE,
          infoColumnItems: items,
        }

        // Fill the title.
        const firstTimeSeries = this.latestTimeSeriesMap.get(chart.id)?.[0]
        if (firstTimeSeries) {
          summary.title = firstTimeSeries.config.seriesName
        }

        const now = this.futureInterval.start
        const { last, minY, maxY, meanY } = getFirstTimeSeriesSummary(
          chart.id,
          this.latestTimeSeriesMap,
          limitInterval(this.chartInterval, now),
          now,
          DATA_POINT_AGE_LIMIT_MINUTES
        )

        const toFormat = (v: undefined | NumberOrNull) =>
          v != null
            ? chartFormattersByChartType[chart.type].yaxis(v)
            : TEXT_NO_VALUE

        // Fill the numerical values.
        summary.lastValue = toFormat(last?.y)
        items[0].text = toFormat(minY)
        items[1].text = toFormat(maxY)
        items[2].text = toFormat(meanY)

        acc.push(summary)

        return acc
      }, [] as SummaryWithChartId[])
    },
    /* this computed property exists only to be watched */
    shouldResetFutureDataSource(): ShouldResetFutureDataSource {
      return [
        this.groupId,
        this.futureInterval,
        this.schedule,
        this.modifiedWaypoints,
        this.futureDeviceSchedule,
      ]
    },
  },
  watch: {
    groupId(): void {
      this.resetHistoricalDataSource()
    },
    shouldResetFutureDataSource(
      newValue: ShouldResetFutureDataSource,
      oldValue: ShouldResetFutureDataSource
    ): void {
      if (
        newValue[0] !== oldValue[0] || // diff group
        newValue[1] !== oldValue[1] || // diff future interval
        !isEqual(newValue[2], oldValue[2]) || // diff Schedule
        !isEqual(newValue[3], oldValue[3]) || // diff waypoints
        !isEqual(newValue[4], oldValue[4]) // diff DeviceSchedule (Events)(LPEA)
      ) {
        this.resetFutureDataSource()
      }
    },
    dataSource: {
      immediate: true,
      handler: function (
        newValue: ITimeSeriesDataSource,
        oldValue: undefined | ITimeSeriesDataSource
      ): void {
        if (oldValue) this.unsubscribeFromDataSource(oldValue)
        this.subscribeToDataSource(newValue)
      },
    },
  },
  created(): void {
    this.resetHistoricalDataSource()
    this.resetFutureDataSource()
  },
  methods: {
    // NOTE(rafael): the interval state should be on the parent component
    // so the parent component can control the visible interval.
    handleNewChartInterval(newInterval: Interval): void {
      this.$emit('new-chart-interval', newInterval)
    },
    handleNewTimeSeries(newMap: TimeSeriesMap): void {
      this.latestTimeSeriesMap = newMap
    },
    updateIsRefreshing(newValue: boolean): void {
      this.isRefreshing = newValue
    },
    subscribeToDataSource(dataSource: ITimeSeriesDataSource): void {
      dataSource.subscribe?.(EventName.IS_REFRESHING, this.updateIsRefreshing)
    },
    unsubscribeFromDataSource(dataSource: ITimeSeriesDataSource): void {
      dataSource.unsubscribe?.(EventName.IS_REFRESHING, this.updateIsRefreshing)
    },
    resetHistoricalDataSource(): void {
      if (this.useEventControls) {
        this.historicalDataSource = new AggregateDataSource(
          newGroupTelemetryDataSource(
            eventChartDefinition,
            this.$services,
            this.group,
            this.$observationTime
          ),
          newGroupEventHistoricalDataSource(
            this.$services,
            this.devices.map((d) => d.camusResourceId),
            this.$observationTime
          )
        )
      } else if (this.useLocationSpecific) {
        // TODO(rafael): add "Operating Envelope" time series.
        this.historicalDataSource = newLocationWaypointHistoricalDataSource(
          this.$services,
          this.groupId,
          this.$observationTime
        )
      } else if (this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS) {
        const ds = newGroupTelemetryDataSource(
          powerWaypointChartDefinition,
          this.$services,
          this.group,
          this.$observationTime
        )

        // Only Devices that have currently active Operating Envelopes.
        const resourceIds = this.devices
          .filter((d) => d.oeEnabled)
          .map((d) => d.camusResourceId)

        if (this.isOperatingEnvelopeEnabled && resourceIds.length) {
          this.historicalDataSource = new AggregateDataSource(
            ds,
            createOperatingEnvelpeDataSource(
              this.$services,
              resourceIds,
              powerWaypointChartDefinition,
              this.$observationTime
            )
          )
        } else {
          this.historicalDataSource = ds
        }
      } else if (this.metric === Metric.METRIC_SOC_WATT_HOURS) {
        // TODO(rafael): add "Operating Envelope" time series.
        this.historicalDataSource = newWattHourHistoricalDataSource(
          this.$services,
          this.group,
          this.$observationTime
        )
      } else {
        // TODO(rafael): add "Operating Envelope" time series.
        this.historicalDataSource = newGroupWaypointHistoricalDataSource(
          this.$services,
          this.group,
          this.$observationTime
        )
      }
    },
    resetFutureDataSource(): void {
      if (this.useEventControls) {
        this.futureDataSource = newGroupEventFutureDataSource(
          this.futureInterval,
          this.futureDeviceSchedule
        )
      } else if (this.useLocationSpecific && this.maxCapacityWatthours) {
        this.futureDataSource = newLocationWaypointFutureDataSource(
          this.$services,
          this.futureInterval,
          this.groupId,
          this.metric,
          this.deviceType,
          this.maxCapacityWatthours,
          this.schedule,
          this.modifiedWaypoints
        )
      } else if (this.metric === Metric.METRIC_MAX_CHARGE_POWER_WATTS) {
        this.futureDataSource = newGroupPowerWaypointFutureDataSource(
          this.$services,
          this.futureInterval,
          this.group.id,
          this.metric,
          this.deviceType,
          this.devices.length,
          this.schedule,
          this.modifiedWaypoints
        )
      } else if (this.metric === Metric.METRIC_SOC_WATT_HOURS) {
        this.futureDataSource = newWattHourFutureDataSource(
          this.$services,
          this.futureInterval,
          this.groupId,
          this.metric,
          this.deviceType,
          this.schedule,
          this.modifiedWaypoints
        )
      } else {
        this.futureDataSource = newGroupWaypointFutureDataSource(
          this.$services,
          this.futureInterval,
          this.groupId,
          this.metric,
          this.deviceType,
          this.schedule,
          this.modifiedWaypoints
        )
      }
    },
  },
})
</script>
