<template>
  <div class="c-ControlGroupDetailsSystemModeModal">
    <!--
      NOTE: Do not remove the `persistent` props.
      This will force the user to choose between CANCEL or CONFIRM action.
     -->
    <v-dialog
      v-model="isModalOpened"
      max-width="400"
      persistent
      content-class="c-ControlGroupDetailsSystemModeModal__dialog"
    >
      <v-card class="pa-4">
        <span class="text-h6 pt-4 px-6 pb-2">
          Confirm policy change from {{ currentPolicy }} to {{ newPolicy }}?
        </span>

        <span class="text-black text-subtitle-2 font-weight-regular px-6">
          {{ message }}
        </span>

        <div class="d-flex px-6 pt-6 pb-2">
          <v-spacer></v-spacer>

          <v-btn
            data-test="modal__cancelBtn"
            color="primary"
            variant="outlined"
            class="mr-2"
            @click="handleClickCancel"
          >
            Edit
          </v-btn>

          <v-btn
            data-test="modal__confirmBtn"
            color="primary"
            class="elevation-0"
            @click="handleClickConfirm"
          >
            Confirm
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { OpenModalPayload } from './ControlGroupDetailsSystemModeModal'

export default defineComponent({
  name: 'ControlGroupDetailsSystemModeModal',
  data: () => ({
    isModalOpened: false,
    /**
     * The following 3 values will come from the parent component calling
     * this component.
     */
    currentPolicy: undefined as string | undefined,
    newPolicy: undefined as string | undefined,
    message: undefined as string | undefined,
  }),
  methods: {
    /** This method should only be called by the parent component. */
    openModal(payload: OpenModalPayload): void {
      // Capture the payload.
      this.currentPolicy = payload.currentPolicy
      this.newPolicy = payload.newPolicy
      this.message = payload.message

      // Show modal.
      this.isModalOpened = true
    },
    /** Resets the state of the component. */
    resetModalState(): void {
      // Reset the component's state.
      this.currentPolicy = undefined
      this.newPolicy = undefined
      this.message = undefined

      // Close modal.
      this.isModalOpened = false
    },
    /** Closes the modal and emits the `cancel` event to the parent component. */
    handleClickCancel(): void {
      this.resetModalState()
      this.$emit('cancel')
    },
    /**
     * Closes the modal and emits the `confirm` event to the parent component.
     */
    handleClickConfirm(): void {
      this.resetModalState()
      this.$emit('confirm')
    },
  },
})
</script>

<style lang="scss">
.c-ControlGroupDetailsSystemModeModal {
  /**
   * Fix word breaking in the title.  For more information, see:
   * https://github.com/vuetifyjs/vuetify/issues/9130.
   *
   * The dialog component exists outside of the parent component's DOM tree, so
   * a dedicated class is used to target the element (instead of relying on the
   * child relationship with the "component div" class).
   */
  &__dialog .v-card__title {
    word-break: normal;
  }
}
</style>
