<template>
  <div class="c-DateRangePicker d-inline-flex">
    <date-picker
      label="From"
      v-model="startDate"
      class="mr-4"
      hide-details
      :min="minStartDate"
      :max="maxStartDate"
      @change="updateInterval"
    />
    <date-picker
      label="To"
      v-model="endDate"
      class="mr-4"
      hide-details
      :min="minEndDate"
      :max="maxEndDate"
      @change="updateInterval"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Interval, DurationObjectUnits, DateTime } from 'luxon'
import DatePicker from '@/components/common/DatePicker.vue'

const MIN_DATE = '2020-01-01'

export default defineComponent({
  name: 'DateRangePicker',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
    maxLength: {
      type: Object as PropType<DurationObjectUnits>,
    },
    maxEndTime: {
      type: Object as PropType<undefined | DateTime>,
      required: false,
    },
  },
  emits: ['interval'],
  components: { DatePicker },
  data() {
    const { start, end } = this.interval
    return {
      startDate: start.toISODate(),
      endDate: end.toISODate(),
    }
  },
  watch: {
    interval(newInterval: Interval) {
      this.startDate = newInterval.start.toISODate()
      this.endDate = newInterval.end.toISODate()
    },
  },
  computed: {
    minStartDate(): string {
      return MIN_DATE
    },
    maxStartDate(): string {
      return this.endDate ? this.endDate : this.maxEndDate
    },
    minEndDate(): string {
      return this.startDate ? this.startDate : ''
    },
    maxEndDate(): string {
      if (this.maxEndTime) {
        return this.maxEndTime.toISODate()
      } else if (this.maxLength) {
        return this.interval.start.plus(this.maxLength).toISODate()
      } else {
        return this.$observationTime().toISODate()
      }
    },
    datesToDateTimes(): [DateTime, DateTime] | [] {
      if (!this.startDate || !this.endDate) {
        return []
      }

      const d1 = DateTime.fromISO(this.startDate)
      const d2 = DateTime.fromISO(this.endDate)

      return [d1.startOf('day'), d2.endOf('day')]
    },
  },
  methods: {
    updateInterval() {
      const [startDate, endDate] = this.datesToDateTimes
      if (!startDate || !endDate) {
        // Missing date
      } else if (startDate < endDate) {
        // Notify listeners of the new date range
        const updatedInterval = Interval.fromDateTimes(startDate, endDate)
        this.$emit('interval', updatedInterval)
      } else {
        // Invalid date range - reset the state
        this.startDate = this.interval.start.toISODate()
        this.endDate = this.interval.end.toISODate()
      }
    },
  },
})
</script>
