import { shallowReactive } from 'vue'
import { defineImmutableStore } from './defineStore'
import { convertFilters, convertOrderBy } from '@/model/tables/column'
import type {
  Filters,
  NewOptionsContext,
  Options,
  VisibleHeaders,
} from '@/model/tables/DataTable'
import {
  createInitialVisibleHeaders,
  upgradeVisibleHeaders,
} from '@/model/tables/header'
import { createDefaultOptions } from '@/model/tables/helper'
import {
  createHeaders,
  createPristineFilters,
  type NotificationsDataTable,
} from '@/model/notification/NotificationsDataTable'
import {
  ListNotificationsRequest,
  type ListNotificationsResponse,
} from 'rfs/frontend/proto/notification_pb'

export const useNotificationsStore = defineImmutableStore('notifications', {
  persist: { paths: ['visibleHeaders', 'options', 'filters', 'searchText'] },
  state: () => {
    return shallowReactive({
      visibleHeaders: createInitialVisibleHeaders(createHeaders()),
      filters: createPristineFilters(),
      options: createDefaultOptions('startTime', 'descending'),
      searchText: '',
      response: null as null | ListNotificationsResponse,
      isLoading: false,
      hasLoadingFailed: false,
      abortController: new AbortController(),
    })
  },
  getters: {
    table(): NotificationsDataTable {
      return {
        rows: this.response?.notifications ?? [],
        noDataText: 'No notifications found',
      }
    },
    serverItemsLength(): number {
      return this.response?.totalNotifications ?? 0
    },
    isInitialState(): boolean {
      return this.response === null
    },
  },
  actions: {
    updateVisibleHeaders(newValue: VisibleHeaders) {
      this.visibleHeaders = newValue
    },
    /** Action called when the user changes a page or sort order */
    updateOptions(newOptions: Options, ctx?: NewOptionsContext) {
      this.options = newOptions
      if (ctx?.triggeredByFilter) return
      this.fetchTable({ flush: true })
    },
    /** Action called when the user changes one or more filters */
    updateFilters(newFilters?: Filters) {
      this.filters = newFilters ?? createPristineFilters()
      this.fetchTable({ flush: true })
    },
    updateSearchText(newSearch: string): void {
      if (this.searchText === newSearch) return

      this.searchText = newSearch
      // When a new search text, reset back to page 1.
      this.updateOptions(
        { ...this.options, page: 1 },
        { triggeredByFilter: true }
      )
      this.fetchTable({ flush: true })
    },
    flushAbortController() {
      this.abortController.abort()
      this.abortController = new AbortController()
    },
    async fetchTable(opts?: { flush: boolean }) {
      if (opts?.flush) this.flushAbortController()

      if (this.isInitialState) {
        this.isLoading = true
      }

      this.hasLoadingFailed = false

      try {
        const iter = this.services.notificationService.streamNotifications(
          new ListNotificationsRequest({
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            search: this.searchText,
            filterBy: convertFilters(this.filters),
            orderBy: convertOrderBy(this.options.orderBy),
          }),
          { signal: this.abortController.signal }
        )

        for await (const response of iter) {
          this.response = response
          this.isLoading = false
        }
      } catch (err) {
        this.hasLoadingFailed = true
      } finally {
        this.isLoading = false
      }
    },
  },
  upgradeState(currentState, initialState) {
    // TODO(rafael): upgrade filters.
    // TODO(rafael): upgrade options: when header doesn't exist anymore, it could still be in use in 'options.orderBy'.

    // Headers
    upgradeVisibleHeaders(
      currentState.visibleHeaders,
      initialState.visibleHeaders
    )
  },
})
