import { LocationAsRelativeRaw } from 'vue-router'
import * as Routes from '@/router/routeNames'
import { Resource } from 'rfs/pb/resource_pb'
import { ResourceType } from '@/constants/resourceType'
import { getUnqualifiedId } from '@/model/resource'
import {
  createHydroRoute,
  createResourceImpactsRoute,
  createSiteRoute,
  createTransformerRoute,
  createUplineParentRoute,
  createUtilitySolarRoute,
} from '@/utils/router/create'

/**
 * Return a Vue Router location for the given resource.
 */
export function routeForResource(
  r: Resource
): LocationAsRelativeRaw | undefined {
  switch (r.type) {
    case ResourceType.CHARGER:
    case ResourceType.BATTERY_DISTRIBUTED:
    case ResourceType.BATTERY_STATION:
    case ResourceType.METER_ELECTRICAL:
    case ResourceType.SOLAR_DISTRIBUTED:
      return r.site?.id ? createSiteRoute(r) : undefined
    case ResourceType.HYDRO:
      return createHydroRoute(r)
    case ResourceType.TRANSFORMER:
      return createTransformerRoute(getUnqualifiedId(r))
    case ResourceType.SOLAR_FARM:
      return createUtilitySolarRoute(r)
    case ResourceType.SENSOR_ELECTRICAL:
      return r.upline?.parent
        ? createUplineParentRoute(r.upline.parent)
        : undefined
    case ResourceType.VEHICLE:
      return { name: Routes.VEHICLE, params: { vehicleId: r.id } }
    case ResourceType.WIND:
      return {
        name: Routes.WIND_DETAILS,
        params: { windId: getUnqualifiedId(r) },
      }
    default:
      // Route to the resource details page, "Impacts" tab.
      return createResourceImpactsRoute(r.type as ResourceType, r.id)
  }
}
