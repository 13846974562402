import { ColorPalette } from '@/constants/colorPalette'
import { Hex } from '@/constants/colors'
import { Shape } from '@/constants/shape'

/**
 * The archetype of charts supported for infinite scroll charts.
 */
export enum ChartType {
  Power, // line chart, watts
  PowerMW, // line chart, watts
  ReactivePower, // line chart, Kilovolt-amperes reactive
  Voltage, // line chart, volts
  VoltagePerUnit, // line chart, Vpu
  Current, // line chart, amps
  Energy, // bar chart, watt/hrs
  EnergyLine, // line chart, watt/hrs
  EnergyLinePercentage, // line chart, 0-100%
  Percentage, // line chart, 0-100%
  Clouds, // line chart, 0-100%
  Load, // line chart, percentage
  LoadDuration, // line chart, Percentage (x axis) with Amp (y axis)
  ApparentPower, // line chart, volt-amps
  Temperature, // line chart, °F
  CarbonFree, // bar chart, 0-100%
  CarbonEmissions, // line chart, kg/MWh
  Errors, // line chart, RMSE
  Violations, // bar chart, whole numbers
}

/**
 * These are groups of ChartTypes used to determine configuration for rendering the charts.
 */

export const CHART_TYPES = {
  BAR_CHARTS: [ChartType.CarbonFree, ChartType.Energy, ChartType.Violations],
}

export type TimeMarker = { xValue: number; label: string }

export type HighlightRange = { x1: number; x2: number; color: Hex }

/**
 * Specifies how an time series chart should be configured.
 */
export interface ChartDefinition {
  id: string
  type: ChartType
  title: string
  fileName?: string
  tooltip?: string
  /** Specify a custom height; the default is 260px. */
  height?: number | `${number}%`
  visible?: boolean | 'requires-data'
  /** Annotations */
  annotations?: {
    /** Voltage tolerance is a y-axis range annotation of nominal voltage +/- percent */
    voltageTolerance?: { percentage: number; nominal?: number }
    /** A threshold is a horizontal dotted line across the grid */
    threshold?: { yValue: number; label: string }
    /** Adds a grey background color to every other day of the chart */
    everyOtherDay?: boolean
    /** Adds a vertical line on the X-axis with a label */
    timeMarkers?: TimeMarker[]
    /** Creates a vertical box on the X-axis */
    highlightRanges?: HighlightRange[]
  }
  /** The chart may have an additional Y-Axis scale positioned on the right */
  additionalYAxis?: {
    /** The metric is used to link this Axis to time series data */
    metric: string
    type: ChartType
    /**
     * When this is set, the tick values are aligned to the primary Y-Axis ticks
     * using this multiplier, including the minimum and maximum Y values.
     */
    multiplier?: number
  }
  /**
   * Override the series-specific colors with these.
   * The colors are cycled through if there are more series than colors.
   * @see SeriesConfig.seriesColor
   */
  seriesColors?: ColorPalette
  /**
   * Override the default line chart to be an area chart.
   * This does not affect bar charts (e.g. Energy Charts).
   */
  isAreaChart?: boolean
  /**
   * Used for stacked bar charts, add the stacked property to scales X & Y
   */
  isStackedBar?: boolean

  yAxis?: {
    title?: string
    /** Used to limit the max Y. Useful for stacked bar charts that are percentages. */
    max?: number
    /** Used to limit the min Y. */
    min?: number
    /** Expect the axis to have at least this range. */
    suggested?: { max: number; min: number }
  }
  xAxis?: {
    /** Title used for the tooltip */
    title: string
    /** How to parse the data into {x,y} objects */
    parsing?: { xAxisKey: string; yAxisKey: string }
    /** The axis min & max is derived from the data if this is undefined. */
    range?: [number, number]
  }
  /**
   * When enabled, the visibility state of each dataset in the chart will be
   * persisted in the browser between sessions.
   */
  persistVisibility?: boolean
}

/**
 * Visual configuration for the chart.
 * The series name is used for the tooltip and/or legend.
 */
export type SeriesConfig = {
  seriesName: string
  /** If the seriesColor is not specified, the chart definition colors are used */
  seriesColor: Hex | ''
  backgroundGradient?: boolean
  /** Some series use a custom rendering */
  seriesLine?: 'dashed' | 'thin-dashed'
  /** Used for the thickness of the line charts in px. Default is `1` */
  seriesLineWidth?: number
  /**
   * Enables the display of edge points at the start and end of line segments.
   */
  seriesLineEdgePoints?: boolean
  steppedLine?: boolean | 'before' | 'after' | 'middle'
  seriesType?: 'scatter'
  /**
   * - 'next' and 'prev': Fill to the next data line using the series color.
   */
  seriesFill?: 'next' | 'prev' | 'origin' | 'diagonal'
  /**
   * Overrides the chart Y Axis formatter in the tooltip.
   */
  formatter?: ChartFormatters['yaxis']
  /**
   * The series won't appear in the chart but will show in the tooltip.
   */
  isGhostSeries?: boolean
  /**
   * The minimum length (in pixels) of a bar in a stacked bar chart.
   * Useful for showing that the bar values are `0` and
   * not missing data.
   */
  minBarLength?: number
}

/**
 * [millis, value]
 */
export type DataPoint = [number, number]

export type NumberOrNull = number | null

export type DataPointWithNull = [number, NumberOrNull]

export type LineSeriesDataPoint = { x: number; y: NumberOrNull }
export type LineSeriesWithConfig = Array<
  BaseLineSeriesWithConfig<LineSeriesDataPoint>
>

export type RangeBarsDataPoint = {
  x: number
  y: { min: number; max: number } | null
}
export type RangeBarsSeriesWithConfig = Array<
  BaseRangeBarsSeriesWithConfig<RangeBarsDataPoint>
>

// TODO(andrew): combine BasePointAnnotationYValue and PointAnnotationsDataPoint
export type BasePointAnnotationYValue = {
  value: number
  shape: Shape
  label?: string
}
export type PointAnnotationsDataPoint = {
  x: number
  y: BasePointAnnotationYValue[] | null
}
export type PointAnnotationsSeriesWithConfig = Array<
  BasePointAnnotationsSeriesWithConfig<PointAnnotationsDataPoint>
>

export interface BaseChartSeries<T> {
  name: string
  color: string
  data: T[]
}

export interface DashConfig {
  dash?: number
}

export interface BaseChartSeriesWithConfig<T>
  extends BaseChartSeries<T>,
    DashConfig {}

export interface BaseLineSeriesWithConfig<T>
  extends BaseChartSeries<T>,
    DashConfig {}

export interface BaseRangeBarsSeriesWithConfig<T> extends BaseChartSeries<T> {}

export interface BasePointAnnotationsSeriesWithConfig<T>
  extends BaseChartSeries<T> {}

export interface ChartFormatters {
  yaxis: (val: number, opts?: any) => string
  xaxis?: (val: number, opts?: any) => string
}

export type HistogramChartData = {
  binSize: number
  bins: number[]
}

export type HorizontalBarChartData = {
  bars: Array<{ label: string; value: number }>
}
