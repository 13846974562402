import { PlainMessage } from '@bufbuild/protobuf'
import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { ColumnType, newFilterField } from '@/model/tables/column'
import {
  Header,
  DataTable,
  Filters,
  Options,
  Row,
} from '@/model/tables/DataTable'
import { TableResponse_TableRow as TableResponseTableRow } from 'rfs/frontend/proto/grid_pb'
import { newFilterMultiSelect } from '../tables/helper'

export enum DersTableColumns {
  NAME = 'resource.title',
  TYPE = 'extendedType',
  FEEDER = 'resource.upline.feeder',
  SITE = 'resource.site.id',
  PRODUCTION_METER = 'resource.meter.electrical',
  CAPACITY = 'resource.ratings.wattage',
  TIME_SERIES_MIN = 'timeSeries.minY',
  TIME_SERIES_MAX = 'timeSeries.maxY',
  TIME_SERIES_CHART = 'timeSeries',
}

type AllColumns = DersTableColumns

export type CustomOptions = Options<AllColumns>

export type CustomFilters = Filters<AllColumns>

type DersTableRow = Row & PlainMessage<TableResponseTableRow>

export interface DersTable extends DataTable<DersTableRow> {}

const capacityHeader: Header = {
  title: 'Capacity (AC) (kW)',
  key: DersTableColumns.CAPACITY,
  align: 'end',
  valueType: ColumnType.KILO_W,
}

const minPowerHeader: Header = {
  title: 'Min power (kW)',
  key: DersTableColumns.TIME_SERIES_MIN,
  align: 'end',
  width: '11%',
  valueType: ColumnType.KILO_W,
}

const maxPowerHeader: Header = {
  title: 'Max power (kW)',
  key: DersTableColumns.TIME_SERIES_MAX,
  align: 'end',
  width: '11%',
  valueType: ColumnType.KILO_W,
}

const timeSeriesHeader: Header = {
  title: 'Last 24 hours',
  key: DersTableColumns.TIME_SERIES_CHART,
  align: 'center',
  width: '18%',
  valueType: ColumnType.SPARKLINE,
}

export const headers: Header[] = [
  {
    title: 'Name',
    key: DersTableColumns.NAME,
    valueType: ColumnType.RESOURCE_ROUTE_LINK,
    columnSelector: { required: true },
  },
  { title: 'Type', key: DersTableColumns.TYPE },
  { title: 'Feeder', key: DersTableColumns.FEEDER },
  { title: 'Site', key: DersTableColumns.SITE },
  { title: 'Production meter', key: DersTableColumns.PRODUCTION_METER },
  capacityHeader,
  minPowerHeader,
  maxPowerHeader,
  timeSeriesHeader,
]

export function createInitialOptions(): CustomOptions {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column: DersTableColumns.NAME, descending: false },
  }
}

export function createInitialFilters(): CustomFilters {
  const map: CustomFilters = new Map()

  map.set(DersTableColumns.TYPE, newFilterMultiSelect())
  map.set(DersTableColumns.FEEDER, newFilterMultiSelect())

  const capacityFilter = newFilterField(capacityHeader.valueType)
  if (capacityFilter) {
    map.set(DersTableColumns.CAPACITY, capacityFilter)
  }

  const minFilter = newFilterField(minPowerHeader.valueType)
  if (minFilter) {
    map.set(DersTableColumns.TIME_SERIES_MIN, minFilter)
  }

  const maxFilter = newFilterField(maxPowerHeader.valueType)
  if (maxFilter) {
    map.set(DersTableColumns.TIME_SERIES_MAX, maxFilter)
  }
  return map
}
