<template>
  <v-container class="d-flex flex-column min-height-100">
    <ce-title big class="pb-12 pl-6">Insights</ce-title>

    <!-- Nav Bar -->
    <nav-bar :navBarItems :rightLink class="mx-6 mb-12" />

    <!-- Content -->
    <div class="d-flex flex-column" style="flex: 1">
      <!-- EV -->
      <insight-ev v-if="showEvContent" style="flex: 1" />

      <!-- PV -->
      <insight-pv v-if="showPvContent" style="flex: 1" />

      <!-- Loading -->
      <insight-loading v-if="showLoadingContent" style="flex: 1" />
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import * as RouteNames from '@/router/routeNames'
import CeTitle from '@/components/CeTitle.vue'
import NavBar, {
  type NavBarItem,
  RightLink,
} from '@/components/common/NavBar.vue'
import InsightEv from '@/components/analyze/insights/InsightEv.vue'
import InsightPv from '@/components/analyze/insights/InsightPv.vue'
import InsightLoading from '@/components/analyze/insights/InsightLoading.vue'

export default defineComponent({
  name: 'Insights',
  components: { CeTitle, NavBar, InsightEv, InsightPv, InsightLoading },
  setup() {
    const rightLink: RightLink = {
      label: 'Grid Components',
      to: { name: RouteNames.GRID_IMPACT },
    }

    return { rightLink }
  },
  computed: {
    navBarItemEv(): NavBarItem {
      return {
        label: 'EV',
        id: 'EV',
        to: { name: RouteNames.INSIGHTS_EV },
        disabled: !this.$rittaConfig.analysis?.insights?.ev,
      }
    },
    navBarItemPv(): NavBarItem {
      return {
        label: 'PV',
        id: 'PV',
        to: { name: RouteNames.INSIGHTS_PV },
        disabled: !this.$rittaConfig.analysis?.insights?.pv,
      }
    },
    navBarItemLoading(): NavBarItem {
      return {
        label: 'Loading',
        id: 'Loading',
        to: { name: RouteNames.INSIGHTS_LOADING },
        disabled: !this.$rittaConfig.analysis?.insights?.loading,
      }
    },
    navBarItems(): NavBarItem[] {
      return [this.navBarItemEv, this.navBarItemPv, this.navBarItemLoading]
    },
    showEvContent(): boolean {
      return this.$route.name === this.navBarItemEv.to.name
    },
    showPvContent(): boolean {
      return this.$route.name === this.navBarItemPv.to.name
    },
    showLoadingContent(): boolean {
      return this.$route.name === this.navBarItemLoading.to.name
    },
  },
})
</script>
