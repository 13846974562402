import { Value } from '@bufbuild/protobuf'

type SelectItem<T = any> = { title: string; value: T }

/**
 * Convert a protobuf Value to an item for a FilterDropDown or FilterMultiSelect.
 */
export function valueToSelectItem(value: Value): SelectItem {
  switch (value.kind.case) {
    case 'structValue':
      return {
        title: valueToString(value.kind.value.fields['title']),
        value: value.kind.value.fields['value']?.kind.value, // Raw value
      }
    default:
      return { title: valueToString(value), value: value.kind.value }
  }
}

function valueToString(val: Value | undefined): string {
  if (val?.kind.case === undefined) return ''

  switch (val.kind.case) {
    case 'numberValue':
      return val.kind.value.toLocaleString()
    case 'stringValue':
      return val.kind.value
    case 'boolValue':
      return val.kind.value ? 'Yes' : 'No'
    default:
      throw new Error('Unsupported value kind:' + val.kind.case)
  }
}

/** Compare function for sorting the menu items */
export function compare(a: SelectItem, b: SelectItem) {
  return a.title.localeCompare(b.title)
}
