import type { PlainMessage } from '@bufbuild/protobuf'
import type {
  ChartArea,
  LineOptions,
  ChartDataset,
  Scale,
  ScaleOptions,
  ScriptableContext,
} from 'chart.js'
import {
  max as _max,
  mean as _mean,
  min as _min,
  range as _range,
  sampleSize as _sampleSize,
  sortBy as _sortBy,
  unionBy as _unionBy,
} from 'lodash-es'
import { BLACK, Hex } from '@/constants/colors'
import { ColorPalette } from '@/constants/colorPalette'
import { Direction } from '@/constants/infiniteScrollChart'
import {
  CHART_TYPES,
  LineSeriesDataPoint,
  DataPointWithNull,
  ChartDefinition,
  ChartFormatters,
  ChartType,
  SeriesConfig,
  NumberOrNull,
} from '@/types/charts'
import { chartFormattersByChartType } from '@/model/charts/formatters'
import { colorWithOpacity } from '@/utils/colors'
import { createDiagonalPattern, createLinearGradient } from '@/utils/chartjs'
import { TimeSeries_DataPoint as TimeSeriesDataPoint } from 'rfs/frontend/proto/tsdb_pb'

export * from './resolution'

/**
 * Big chart size: 400px
 */
export const CHART_SIZE_BIG = 400

/**
 * The earliest a chart can be zoomed out
 */
export const MIN_X_DATE = new Date('2020-01-01').getTime()

/**
 * Return the vertical "title" text next to the Y-Axis labels based on the type of chart.
 */
export function yAxisTitle(chartType: ChartType): string {
  switch (chartType) {
    case ChartType.Power:
      return 'Power'
    case ChartType.PowerMW:
      return 'Power(MW)'
    case ChartType.ReactivePower:
      return 'Reactive Power'
    case ChartType.Voltage:
      return 'Voltage'
    case ChartType.VoltagePerUnit:
      return 'Voltage (p.u.)'
    case ChartType.Current:
      return 'Current'
    case ChartType.Energy:
    case ChartType.EnergyLine:
    case ChartType.EnergyLinePercentage:
      return 'State of Charge'
    case ChartType.Percentage:
      return ''
    case ChartType.Clouds:
      return 'Cloud Cover'
    case ChartType.Load:
      return 'Load'
    case ChartType.ApparentPower:
    case ChartType.LoadDuration:
      return 'Apparent Power'
    case ChartType.Temperature:
      return 'Temperature'
    case ChartType.CarbonFree:
      return 'Proportion (%)'
    case ChartType.CarbonEmissions:
      return 'Emissions Intensity (kg CO₂e/MWh)'
    case ChartType.Errors:
      return 'Errors'
    case ChartType.Violations:
      return 'Violations'
  }
}

/**
 * Convert the additional axis in a chart definition into ChartJS options.
 */
export function additionalYAxisOptions(
  axis: Required<ChartDefinition>['additionalYAxis']
): ScaleOptions<'linear'> {
  const formatter = chartFormattersByChartType[axis.type]
  const multiplier = axis.multiplier
  return {
    type: 'linear',
    position: 'right',
    ticks: standardYAxisTicks(formatter),
    afterBuildTicks(y2Axis: Scale) {
      if (multiplier == null) return
      // Use the multiplier to make the 2 axis tick marks line up
      const yAxis = y2Axis.chart.scales['y']
      y2Axis.min = yAxis.min * multiplier
      y2Axis.max = yAxis.max * multiplier
      y2Axis.ticks.forEach((tick, index) => {
        if (yAxis.ticks[index] == null) return
        tick.value = yAxis.ticks[index].value * multiplier
        tick.label = formatter.yaxis(tick.value)
      })
    },
  }
}

/**
 * Return the chart ticks configuration using the supplied formatter.
 * The maximum number of ticks is 6.
 */
export function standardYAxisTicks(
  formatter: ChartFormatters
): ScaleOptions<'linear'>['ticks'] {
  return {
    callback: (v) => formatter.yaxis(v as number),
    maxTicksLimit: 6,
  }
}

/**
 * Return the color for a series using either the configuration,
 * or using a color palette and index.
 */
export function getSeriesColor(
  config: SeriesConfig,
  seriesColors: ColorPalette | undefined,
  seriesIndex: number
): Hex {
  if (config.seriesColor) {
    return config.seriesColor
  } else if (seriesColors) {
    // Use index mod length so that we rotate through the colors
    return seriesColors[seriesIndex % seriesColors.length].hex
  } else {
    return BLACK.hex
  }
}

export function getSeriesFillColor(
  chartDef: ChartDefinition,
  color: Hex
): string {
  const isBarChart = CHART_TYPES.BAR_CHARTS.includes(chartDef.type)

  if (chartDef.isAreaChart) {
    return colorWithOpacity(color, 0.3)
  }
  if (isBarChart || chartDef.isStackedBar) {
    return colorWithOpacity(color, 0.85)
  }
  // If it's `undefined` ChartJS will default to grey
  return 'transparent'
}

export function getSeriesFillOption(
  config: SeriesConfig
): Partial<LineOptions> | null {
  const seriesColor = config.seriesColor || BLACK.hex
  if (config.seriesFill === 'next') {
    return { fill: '+1', backgroundColor: colorWithOpacity(seriesColor, 0.3) }
  }
  if (config.seriesFill === 'prev') {
    return { fill: '-1', backgroundColor: colorWithOpacity(seriesColor, 0.3) }
  }
  if (config.seriesFill === 'origin') {
    return {
      fill: 'origin',
      backgroundColor: colorWithOpacity(seriesColor, 0.3),
    }
  }
  if (config.seriesFill === 'diagonal') {
    const pattern = createDiagonalPattern(seriesColor)
    return pattern ? { fill: 'origin', backgroundColor: pattern } : null
  }
  return null
}

export function getSeriesBackgroundColor(
  config: SeriesConfig,
  seriesColor: Hex
): Partial<ChartDataset<'line'>> | null {
  if (config.backgroundGradient) {
    return {
      // NOTE: do not overwrite existing "seriesFill" configuration.
      ...(config.seriesFill ? null : { fill: true }),
      backgroundColor: ({ chart }: ScriptableContext<'line'>) => {
        const gradient = createLinearGradient(chart)

        if (!gradient) return seriesColor

        gradient.addColorStop(0, colorWithOpacity(seriesColor, 0.5))
        gradient.addColorStop(1, colorWithOpacity(seriesColor, 0.1))

        return gradient
      },
    }
  }

  return null
}

/**
 * Returns the lowest-valued number in a list of series's data.
 */
export function findMin(
  seriesDataList: Array<DataPointWithNull[] | LineSeriesDataPoint[]>
): number | undefined {
  let min: number | undefined

  for (const seriesData of seriesDataList) {
    for (const dp of seriesData) {
      const value = Array.isArray(dp) ? dp[1] : dp.y

      if (
        value !== null &&
        !Number.isNaN(value) &&
        (min === undefined || value < min)
      ) {
        min = value
      }
    }
  }

  return min
}

/**
 * Returns the mean number in a list of series's data.
 */
export function findMean(
  seriesDataList: Array<DataPointWithNull[] | LineSeriesDataPoint[]>
): number | undefined {
  let totalValue: number | undefined
  let numberOfValues: number | undefined

  for (const seriesData of seriesDataList) {
    for (const dp of seriesData) {
      const value = Array.isArray(dp) ? dp[1] : dp.y

      if (value !== null && !Number.isNaN(value)) {
        totalValue = totalValue === undefined ? value : totalValue + value
        numberOfValues = numberOfValues === undefined ? 1 : numberOfValues + 1
      }
    }
  }

  return totalValue !== undefined && numberOfValues !== undefined
    ? totalValue / numberOfValues
    : undefined
}

/**
 * Returns the highest-valued number in a list of series's data.
 */
export function findMax(
  seriesDataList: Array<DataPointWithNull[] | LineSeriesDataPoint[]>
): number | undefined {
  let max: number | undefined

  for (const seriesData of seriesDataList) {
    for (const dp of seriesData) {
      const value = Array.isArray(dp) ? dp[1] : dp.y

      if (
        value !== null &&
        !Number.isNaN(value) &&
        (max === undefined || value > max)
      ) {
        max = value
      }
    }
  }

  return max
}

/**
 * Splits a single array of data points into two arrays of data points,
 * keeping the same timestamp between them.
 */
export function splitPositiveNegative(data: TimeSeriesDataPoint[]): {
  positive: TimeSeriesDataPoint[]
  negative: TimeSeriesDataPoint[]
} {
  const positive: TimeSeriesDataPoint[] = []
  const negative: TimeSeriesDataPoint[] = []

  for (const d of data) {
    if (Number(d.y) >= 0) {
      positive.push(d)
      negative.push(new TimeSeriesDataPoint({ x: d.x }))
    } else {
      negative.push(d)
      positive.push(new TimeSeriesDataPoint({ x: d.x }))
    }
  }

  return { positive, negative }
}

// TODO(will): use the nominal voltage as supplied by the grid
// as this is pretty janky.
export function voltageNominal(voltages: DataPointWithNull[]): number {
  // TODO(will): we're mapping over the whole array, but only taking a
  // few samples…take first `n` points instead?
  const samples = _sampleSize(voltages, 5).map((d) => d[1] as number)
  const meanVoltage = _mean(samples)

  if (120.0 * 0.8 <= meanVoltage && meanVoltage < 120.0 * 1.2) {
    return 120.0
  } else if (240.0 * 0.8 <= meanVoltage && meanVoltage < 240.0 * 1.2) {
    return 240.0
  } else if (360.0 * 0.8 <= meanVoltage && meanVoltage < 360.0 * 1.2) {
    return 360.0
  }

  console.debug("voltageNominal: unknown nominal voltage, returning '0'")

  return 0
}

export const isWheelEvent = (event: Event): event is WheelEvent =>
  'deltaX' in event || 'deltaY' in event

/**
 * For a wheel event, determine the direction of movement.
 */
export function getScrollDirection(event: Event): Direction {
  if (!isWheelEvent(event)) throw new Error('Event is not a WheelEvent type.')

  const scrollHorizontal = Math.abs(event.deltaX)
  const scrollVertical = Math.abs(event.deltaY)

  if (scrollHorizontal > scrollVertical) {
    if (Math.sign(event.deltaX) === 1) {
      return Direction.BACKWARD
    }
    if (Math.sign(event.deltaX) === -1) {
      return Direction.FORWARD
    }
  } else if (scrollHorizontal < scrollVertical) {
    if (Math.sign(event.deltaY) === 1) {
      return Direction.DOWN
    }
    if (Math.sign(event.deltaY) === -1) {
      return Direction.UP
    }
  }
  return Direction.NONE
}

/**
 * Is the given {x,y} coordinate inside the chart rectangle?
 */
export function isPointInRect(x: number, y: number, rect: ChartArea) {
  return x > rect.left && x < rect.right && y > rect.top && y < rect.bottom
}

interface Stats {
  minY: NumberOrNull
  meanY: NumberOrNull
  maxY: NumberOrNull
}

export function computeMinMaxMean(
  points: PlainMessage<TimeSeriesDataPoint>[]
): Stats {
  const summary: Stats = { minY: null, maxY: null, meanY: null }

  points.forEach((pt) => {
    if (pt.y == null) return
    summary.minY = Math.min(summary.minY ?? Number.MAX_VALUE, pt.y)
    summary.maxY = Math.max(summary.maxY ?? Number.MIN_VALUE, pt.y)
    summary.meanY = (summary.meanY ?? 0) + pt.y
  })
  // The `meanY` is the sum of all points, so divide by length to get the mean
  if (summary.meanY) summary.meanY /= points.length

  return summary
}

export function hasValueFirstDataPointOnly(
  datapoints: LineSeriesDataPoint[]
): boolean {
  return (
    datapoints[0]?.y !== null &&
    datapoints.every((dp, i) => (i === 0 ? dp.y != null : dp.y == null))
  )
}

type DataPoints = Array<PlainMessage<TimeSeriesDataPoint>>
type ListOfDataPoints = Array<DataPoints>

/**
 * This function combines multiple lists of data points, each assumed to be
 * sorted by their timestamps, into one consolidated list.
 *
 * It utilizes a merge sort-like strategy for the combination, ensuring optimal
 * time complexity. The merge operation is specifically designed to take
 * advantage of the pre-sorted nature of the input lists.
 */
export function mergeDataPoints(
  listOfDataPoints: ListOfDataPoints
): DataPoints {
  if (listOfDataPoints.length === 0) return []
  if (listOfDataPoints.length === 1) return listOfDataPoints[0]

  const mergeTwoLists = (list1: DataPoints, list2: DataPoints): DataPoints => {
    const merged: DataPoints = []
    let i = 0
    let j = 0

    while (i < list1.length && j < list2.length) {
      if (list1[i].x < list2[j].x) {
        merged.push(list1[i++])
      } else {
        merged.push(list2[j++])
      }
    }

    // Adds the remaining elements of each list, if any.
    while (i < list1.length) {
      merged.push(list1[i++])
    }
    while (j < list2.length) {
      merged.push(list2[j++])
    }

    return merged
  }

  // Applies the merging approach iteratively.
  let mergedList = listOfDataPoints[0]
  for (let i = 1; i < listOfDataPoints.length; i++) {
    mergedList = mergeTwoLists(mergedList, listOfDataPoints[i])
  }

  return mergedList
}
