<template>
  <tr>
    <!-- Index -->
    <td>{{ row[Columns.INDEX] }}</td>

    <!-- Date -->
    <td v-if="row[Columns.DATE] !== undefined">{{ row[Columns.DATE] }}</td>

    <!-- Start time -->
    <td>{{ row[Columns.START_TIME] }}</td>

    <!-- End time -->
    <td>{{ row[Columns.END_TIME] }}</td>

    <!-- Target Value -->
    <td>{{ row[Columns.TARGET_VALUE] }}</td>

    <!-- Action btns -->
    <td>
      <row-actions
        @click-edit="$emit('edit')"
        @click-delete="$emit('delete')"
        :is-disabled="isDisabled"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  Columns,
  type AutomaticControlRow,
} from '@/model/control/WaypointDataTable'
import RowActions from '@/components/control/RowActions.vue'

export default defineComponent({
  name: 'AutomaticControlRow',
  props: {
    row: {
      type: Object as PropType<AutomaticControlRow>,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { RowActions },
  setup() {
    return { Columns }
  },
})
</script>
