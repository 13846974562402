<template>
  <div
    class="chartjs-window-resizing"
    :style="{ height }"
    role="region"
    aria-label="Chart"
  >
    <bar-chart :data :options :aria-label="name" />
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { ChartData, ChartOptions } from 'chart.js'
import { Bar as BarChart } from 'vue-chartjs'
import type { Hex } from '@/constants/colors'
import type { HistogramChartData } from '@/types/charts'

export default defineComponent({
  name: 'HistogramChart',
  props: {
    histogramData: {
      type: Object as PropType<HistogramChartData>,
      required: true,
    },
    xAxisTitle: {
      type: String,
      required: true,
    },
    yAxisTitle: {
      type: String,
      required: true,
    },
    barColor: {
      type: String as PropType<Hex>,
      required: true,
    },
    /**
     * Specifies the height of the chart.
     * Accepts any valid CSS unit (e.g., 'px', '%', 'em').
     */
    height: {
      type: String,
      required: false,
      default: '15rem',
    },
    /** * Mostly for E2E tests */
    name: {
      type: String,
      required: false,
    },
  },
  components: { BarChart },
  computed: {
    binLabels(): string[] {
      const labels: string[] = []

      for (let i = 0; i < this.histogramData.bins.length; i++) {
        labels.push(
          `${i * this.histogramData.binSize}-${
            this.histogramData.binSize * (i + 1)
          }`
        )
      }

      return labels
    },
    data(): ChartData<'bar'> {
      return {
        labels: this.binLabels,
        datasets: [
          {
            label: this.yAxisTitle,
            data: this.histogramData.bins,
            backgroundColor: this.barColor,
            barPercentage: 0.99,
            categoryPercentage: 1,
          },
        ],
      }
    },
    options(): ChartOptions<'bar'> {
      return {
        plugins: {
          legend: { display: false },
          tooltip: { callbacks: { title: () => this.xAxisTitle } },
        },
        scales: {
          x: {
            title: { display: true, text: this.xAxisTitle },
            grid: { display: false },
          },
          y: {
            beginAtZero: true,
            title: { display: true, text: this.yAxisTitle },
          },
        },
      }
    },
  },
})
</script>
