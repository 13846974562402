import { insertHiddenIframe } from '@/utils/iframe'

/**
 * Adds a hidden Iframe with the IAP (Google) session refresher page.
 *
 * The session refresher page refreshes the user's auth token every
 * 2700 seconds (45 minutes), diminishing the chances of an old
 * token.
 *
 * More:
 * https://cloud.google.com/iap/docs/external-identity-sessions#loading_the_session_refresher
 */
export function addGcpIapSessionRefresherIframe(): void {
  const iframeId = 'gcpIapSessionRefresher'
  const src = '/?gcp-iap-mode=SESSION_REFRESHER'
  insertHiddenIframe(iframeId, src)
}
