import { FormFields } from '@/model/forms/FormFields'
import { Float, PositiveFloat, Required } from '@/utils/validation/decorators'

export class ChargerManualForm extends FormFields {
  @Required
  @PositiveFloat
  setpoint: string = ''
}

export class BatteryManualForm extends FormFields {
  @Required
  @Float
  setpoint: string = ''
}
