import { RouteRecordRaw } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import * as RouteNames from './routeNames'
import { getResourceTypeDisplayName } from '@/model/resource'
import DersLeftPanel from '@/components/ders/DersLeftPanel.vue'
import SolarArraySingle from '@/components/ders/SolarArraySingle.vue'
import HydroSingle from '@/components/ders/HydroSingle.vue'
import WindSingle from '@/components/ders/WindSingle.vue'
import Home from '@/views/Home.vue'

export const TAB = TabId.MONITOR

export const TITLE_DERS = 'DERs'

export function dersRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  const tab = TAB
  const drawerLeftWide = true
  const meta = { tab, drawerLeftWide }

  if (config.monitor?.ders?.enabled) {
    return [
      {
        path: '/ders',
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.DERS,
            meta: {
              ...meta,
              resetMapCenterAndZoom: true,
              pageTitle: TITLE_DERS,
            },
            components: { leftPanel: DersLeftPanel },
          },
          {
            path: 'hydro/:hydroId',
            name: RouteNames.HYDRO_DETAILS,
            meta: {
              ...meta,
              pageTitle: (params) => {
                const rtLabel = getResourceTypeDisplayName(ResourceType.HYDRO)
                return `${rtLabel}: ${params.hydroId}`
              },
            },
            components: { leftPanel: HydroSingle },
            props: { leftPanel: true },
          },
          {
            path: 'utilitysolar/:utilitySolarId',
            name: RouteNames.SOLAR_ARRAY_DETAILS,
            meta: {
              ...meta,
              pageTitle: (params) => {
                const rtLabel = getResourceTypeDisplayName(
                  ResourceType.SOLAR_FARM
                )
                return `${rtLabel}: ${params.utilitySolarId}`
              },
            },
            components: { leftPanel: SolarArraySingle },
            props: { leftPanel: true },
          },
          {
            path: 'wind/:windId',
            name: RouteNames.WIND_DETAILS,
            meta: {
              ...meta,
              pageTitle: (params) => {
                const rtLabel = getResourceTypeDisplayName(ResourceType.WIND)
                return `${rtLabel}: ${params.windId}`
              },
            },
            components: { leftPanel: WindSingle },
            props: { leftPanel: true },
          },
        ],
      },
    ]
  } else {
    return []
  }
}
