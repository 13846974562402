<template>
  <section
    class="d-flex flex-column position-relative min-height-100 px-6 pb-4"
  >
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Content -->
    <template v-else>
      <!-- Header -->
      <resource-header
        v-if="!resourceOperationsFullPanel"
        :breadcrumbs="breadcrumbs"
        :items="headerItems"
        small
        stand-out-last-item
        horizontal-items
        class="pb-10"
      />

      <!-- Tabs -->
      <nav-bar
        v-if="!resourceOperationsFullPanel"
        :nav-bar-items="navBarItems"
        class="mb-6"
        dark
      />

      <!-- Tab: Telemetry -->
      <resource-telemetry
        v-if="showTelemetry && resource"
        :resource="resource"
      />

      <!-- Tab: Operations -->
      <resource-operations
        v-if="showOperations && resource"
        :resource="resource"
        style="flex: 1"
      />

      <!-- Tab: Impacts -->
      <resource-impacts
        v-if="showGridImpact && resource"
        :resource="resource"
      />
    </template>
  </section>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { defineComponent, shallowReactive } from 'vue'
import type {
  LocationAsRelativeRaw as VueRouterLocation,
  RouteLocationNormalized,
} from 'vue-router'

import { ResourceType, ResourceTypeLabels } from '@/constants/resourceType'
import { createAllResourcesRoute } from '@/utils/router/create'
import { type LatLng, Resource } from 'rfs/pb/resource_pb'
import { usePreferencesStore } from '@/stores/preferences'
import { useGridMapStore } from '@/stores/gridMap'
import { useNavigationControlsStore } from '@/stores/navigationControls'
import * as RouteNames from '@/router/routeNames'
import {
  createHeaderItems,
  getDisplayName,
  getResourceTabLabel,
  getResourceTypeDisplayName,
  getUnqualifiedId,
  hasResourceTabEnabled,
  makeId,
  ResourceTab,
} from '@/model/resource'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import ResourceHeader from '@/components/ResourceHeader.vue'
import NavBar, { type NavBarItem } from '@/components/common/NavBar.vue'
import type {
  BreadcrumbItem,
  ResourceHeaderItem,
} from '@/components/ResourceHeader'
import ResourceImpacts from './ResourceImpacts.vue'
import ResourceTelemetry from './ResourceTelemetry.vue'
import ResourceOperations from './ResourceOperations.vue'

export default defineComponent({
  name: 'ResourceLeftPanel',
  props: {
    unqualifiedId: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: false,
    },
  },
  components: {
    CenteredSpinner,
    ResourceHeader,
    NavBar,
    ResourceImpacts,
    ResourceTelemetry,
    ResourceOperations,
  },
  setup() {
    return { gridMapStore: useGridMapStore() }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      resource: null as null | Resource,
      breadcrumbs: [] as BreadcrumbItem[],
      headerItems: [] as ResourceHeaderItem[],
      navBarItems: [] as NavBarItem[],
    })
  },
  computed: {
    ...mapState(usePreferencesStore, ['resourceOperationsFullPanel']),
    showTelemetry(): boolean {
      return this.tab === ResourceTab.TELEMETRY
    },
    showOperations(): boolean {
      return this.tab === ResourceTab.OPERATIONS
    },
    showGridImpact(): boolean {
      return this.tab === ResourceTab.IMPACTS
    },
  },
  watch: {
    unqualifiedId: {
      immediate: true,
      handler: function () {
        this.fetchData()
      },
    },
    resource() {
      this.setNewPageTitle()
      this.handleMapInteractions()
    },
    tab() {
      this.setNewPageTitle()
    },
  },
  /** * Guards against undesired routes. */
  beforeRouteEnter(to, _from, next): void {
    next((vm) => {
      // NOTE: When first visiting this page with either no specified tab or a
      // disabled tab, we set this temporary title to ensure the
      // browser history displays something meaningful.
      const rt: undefined | ResourceType = to.meta.resourceType
      const rtLabel = rt ? `${getResourceTypeDisplayName(rt)}: ` : ''
      const unqualifiedId = to.params.unqualifiedId
      useNavigationControlsStore().setPageTitle(`${rtLabel}${unqualifiedId}`)

      // @ts-ignore
      const nextRoute = vm.getNextRoute(to)
      if (nextRoute) vm.$router.replace(nextRoute)
    })
  },
  /** * Guards against undesired routes. */
  beforeRouteUpdate(to, _from, next): void {
    const nextRoute = this.getNextRoute(to)
    nextRoute ? next(nextRoute) : next()
  },
  beforeUnmount(): void {
    this.gridMapStore.clearFocus()
  },
  methods: {
    ...mapActions(usePreferencesStore, ['toggleResourceOperationsFullPanel']),
    setNewPageTitle(): void {
      if (!this.resource) return

      const rtLabel = getResourceTypeDisplayName(
        this.resource.type as ResourceType
      )

      const tabLabel = this.tab
        ? getResourceTabLabel(this.tab as ResourceTab)
        : undefined

      const baseTitle = `${rtLabel}: ${getDisplayName(this.resource)}`
      const newTitle = tabLabel ? `${baseTitle} - ${tabLabel}` : baseTitle

      useNavigationControlsStore().setPageTitle(newTitle)
    },
    createNavBarItems(resource: Resource): NavBarItem[] {
      const rt = resource.type as ResourceType
      const unqualifiedId = getUnqualifiedId(resource)

      return Object.values(ResourceTab)
        .map((rTab) => ({
          id: rTab,
          label: getResourceTabLabel(rTab),
          to: {
            name: RouteNames.createResourceDetailsRouteName(rt),
            params: { unqualifiedId, tab: rTab },
          },
          disabled: !hasResourceTabEnabled(rTab, resource, this.$rittaConfig),
        }))
        .filter((item) => !item.disabled)
    },
    createBreadcrumbs(resource: Resource): BreadcrumbItem[] {
      return [
        {
          title: ResourceTypeLabels[resource.type as ResourceType],
          to: createAllResourcesRoute(resource),
        },
        { title: getDisplayName(resource) },
      ]
    },
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.resource = null
      this.breadcrumbs = []
      this.headerItems = []
      this.navBarItems = []

      try {
        const rt = this.$route.meta.resourceType

        if (!rt) throw new Error('no resource type available')

        const resource = await this.$services.queryService.getResource(
          `${rt}/${this.unqualifiedId}`
        )

        this.resource = resource
        this.breadcrumbs = this.createBreadcrumbs(resource)
        this.headerItems = createHeaderItems(resource)
        this.navBarItems = this.createNavBarItems(resource)
      } catch (err) {
        console.error('ResourceLeftPanel.fetchData: %o', err)
        this.$router.replace(
          createAllResourcesRoute(
            new Resource({ type: this.$route.meta.resourceType })
          )
        )
      } finally {
        this.isLoading = false
      }
    },
    getNextRoute(to: RouteLocationNormalized): undefined | VueRouterLocation {
      const nextResourceType = to.meta.resourceType
      const nextTabValue = to.params.tab

      const nextNavBarItems = (() => {
        const nextUnqualifiedId = to.params.unqualifiedId

        // If the route is to the same resource, return the current nav bar items.
        if (nextUnqualifiedId === this.unqualifiedId && this.resource != null) {
          return this.navBarItems
        }
        if (
          !nextResourceType ||
          Array.isArray(nextTabValue) ||
          Array.isArray(nextUnqualifiedId)
        ) {
          return []
        }

        const nextResource = new Resource({
          type: nextResourceType,
          id: makeId(nextResourceType, nextUnqualifiedId),
        })
        return this.createNavBarItems(nextResource)
      })()

      const expectedSelectedTabExists = nextNavBarItems.find(
        (item) => item.id === nextTabValue
      )

      // The tab the user wants to visit exists and is not disabled? No need
      // to redirect.
      if (expectedSelectedTabExists && !expectedSelectedTabExists.disabled) {
        return undefined
      }
      // Make an exception for the telemetry tab, since the resource may have an
      // attached sensor, but the resource hasn't been fetched yet.
      if (nextTabValue === ResourceTab.TELEMETRY) {
        return undefined
      }

      // Try pushing the user to the first enabled tab.
      const firstEnabledTab = nextNavBarItems.find((item) => !item.disabled)

      if (firstEnabledTab) return firstEnabledTab.to

      // Last resort, push the user to the "all resources" route.
      return createAllResourcesRoute(new Resource({ type: nextResourceType }))
    },
    async getResourceLocation(resource: Resource): Promise<undefined | LatLng> {
      let location: undefined | LatLng

      try {
        // NOTE: Exception for Feeders. The map should move to the
        // parent Substation location.
        if (resource.type === ResourceType.FEEDER) {
          const unqualifiedId = resource.upline?.substation
          if (!unqualifiedId) throw new Error('Feeder has no upline.substation')
          const substation = await this.$services.queryService.getResource(
            `${ResourceType.SUBSTATION}/${unqualifiedId}`
          )
          location = substation.location?.point
        } else {
          // Default.
          location = resource.location?.point
        }
      } catch (err) {
        console.error('ResourceLeftPanel.getResourceLocation: %o', err)
      }

      return location
    },
    async handleMapInteractions(): Promise<void> {
      if (!this.resource) return

      const location = await this.getResourceLocation(this.resource)

      if (location) {
        this.gridMapStore.focusLocations({
          locations: [location],
          showRedPins: true,
          zoom: 16,
        })
      } else {
        this.gridMapStore.clearFocus()
      }
    },
  },
})
</script>
