// @generated by protoc-gen-connect-es v1.5.0 with parameter "target=js+dts"
// @generated from file frontend/proto/transformer_details.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ComputeLoadRequest, GenerateRequest, GenerateResponse, GetTransformerRequest, ListTopTransformerLoadRequest, ListTopTransformerLoadResponse, Transformer, TransformerSeriesRequest, TransformerSeriesRequestV2, TransformerSeriesResponse, TransformerSeriesResponseV2 } from "./transformer_details_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service ritta.frontend.proto.TransformerDetails
 */
export const TransformerDetails = {
  typeName: "ritta.frontend.proto.TransformerDetails",
  methods: {
    /**
     * for a single distribution transformer
     *
     * @generated from rpc ritta.frontend.proto.TransformerDetails.GetTransformer
     */
    getTransformer: {
      name: "GetTransformer",
      I: GetTransformerRequest,
      O: Transformer,
      kind: MethodKind.Unary,
    },
    /**
     * for a single transformer, return power & voltage chart data
     *
     * @generated from rpc ritta.frontend.proto.TransformerDetails.TransformerSeries
     * @deprecated
     */
    transformerSeries: {
      name: "TransformerSeries",
      I: TransformerSeriesRequest,
      O: TransformerSeriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * for a single transformer, return time series data for power & voltage
     *
     * @generated from rpc ritta.frontend.proto.TransformerDetails.TransformerSeriesV2
     */
    transformerSeriesV2: {
      name: "TransformerSeriesV2",
      I: TransformerSeriesRequestV2,
      O: TransformerSeriesResponseV2,
      kind: MethodKind.Unary,
    },
    /**
     * for list of top loaded distribution transformers
     *
     * @generated from rpc ritta.frontend.proto.TransformerDetails.ListTopTransformerLoad
     */
    listTopTransformerLoad: {
      name: "ListTopTransformerLoad",
      I: ListTopTransformerLoadRequest,
      O: ListTopTransformerLoadResponse,
      kind: MethodKind.Unary,
    },
  }
};

/**
 * @generated from service ritta.frontend.proto.TransformerLoadCache
 */
export const TransformerLoadCache = {
  typeName: "ritta.frontend.proto.TransformerLoadCache",
  methods: {
    /**
     * For the given transformers, compute the transformer load values using the AMI data from
     * the transformers' meters. For testing purposes only.
     *
     * @generated from rpc ritta.frontend.proto.TransformerLoadCache.ComputeTransformerLoad
     */
    computeTransformerLoad: {
      name: "ComputeTransformerLoad",
      I: ComputeLoadRequest,
      O: ListTopTransformerLoadResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Compute the complete transformer load for all transformers and save the results in GCS.
     *
     * @generated from rpc ritta.frontend.proto.TransformerLoadCache.GenerateTransformerLoadCache
     */
    generateTransformerLoadCache: {
      name: "GenerateTransformerLoadCache",
      I: GenerateRequest,
      O: GenerateResponse,
      kind: MethodKind.Unary,
    },
  }
};

