import { DateTime, Duration } from 'luxon'

/** * Adds some space between "now" and the end of the chart. */
export const PADDING = Duration.fromObject({ minutes: 15 })

/**
 * Generates a cut-off DateTime object set to 90 minutes before the given 'now'.
 *
 * This ensures that any data points occurring within the last 90 minutes are
 * not considered, as they may still be volatile or not fully settled.
 *
 * NOTE: The duration should be bigger than the resolution used in the chart.
 */
export function createCutOffDate(now: DateTime): DateTime {
  return now.minus({ minute: 90 })
}
