import { TEXT_NO_VALUE } from '@/constants/formatText'
import type { Header, DataTable, Row } from '@/model/tables/DataTable'
import { Format } from '@/utils/format'
import type { ResourceImpactResponse } from 'rfs/frontend/proto/analysis_pb'

interface GridImpactResourceRow extends Row {
  downlineLabel: string
  meters: string
  bess: string
  dpv: string
  ev: string
}

export const headers: Header[] = [
  {
    title: 'Downline',
    key: 'downlineLabel',
    sortable: false,
  },
  {
    title: 'Meters',
    key: 'meters',
    align: 'end',
    sortable: false,
  },
  {
    title: 'BESS',
    key: 'bess',
    align: 'end',
    sortable: false,
  },
  {
    title: 'DPV',
    key: 'dpv',
    align: 'end',
    sortable: false,
  },
  {
    title: 'EV',
    key: 'ev',
    align: 'end',
    sortable: false,
  },
]

export function createResourceImpactDataTable(
  impact?: ResourceImpactResponse
): DataTable<GridImpactResourceRow> {
  return {
    rows: [
      {
        id: 'count',
        downlineLabel: 'Count',
        meters: Format.fmtCount(impact?.meters) || TEXT_NO_VALUE,
        bess: Format.fmtCount(impact?.data?.numBess) || TEXT_NO_VALUE,
        dpv: Format.fmtCount(impact?.data?.numPvs) || TEXT_NO_VALUE,
        ev: Format.fmtCount(impact?.data?.numEvs) || TEXT_NO_VALUE,
      },
      {
        id: 'ratedCapacity',
        downlineLabel: 'Rated capacity',
        meters: TEXT_NO_VALUE,
        bess: Format.fmtWatts(impact?.data?.bessCapacity) || TEXT_NO_VALUE,
        dpv: Format.fmtWatts(impact?.data?.pvCapacity) || TEXT_NO_VALUE,
        ev: Format.fmtWatts(impact?.data?.evCapacity) || TEXT_NO_VALUE,
      },
    ],
  }
}
