import type { ChartData, Point } from 'chart.js'
import { stringify } from 'csv-stringify/browser/esm/sync'
import { downloadBlob } from '@/utils/download'
import { NumberOrNull } from '@/types/charts'

type ColumnName = string
type CellValue = string | number | null
export type CsvRow = { [key: ColumnName]: CellValue }

/** * Forces the browser to download a CSV file. */
export async function downloadCsv(
  rows: CsvRow[],
  fileName: string
): Promise<void> {
  return downloadBlob(
    new Blob([stringify(rows, { header: true })], {
      type: 'text/csv;charset=utf-8',
    }),
    `${fileName}.csv`
  )
}

export type CsvColumn<TDataPoint = any> = {
  columnName: string
  datapoints: TDataPoint[]
  getDpTimestamp: (datapoint: TDataPoint) => number
  getDpValue: (datapoint: TDataPoint) => CellValue
}

/**
 * Maps the values of each data point of each column to the same timestamp row,
 * creating a table indexed by the timestamps.
 *
 * NOTE: When no value available for the respective timestamp, the column's cell
 * is going to be displayed as empty.
 */
export function createCsvRowsFromTimeSeriesData(opts: {
  timestampHeader: string
  timestampFormatter: (timestamp: number) => string
  columns: CsvColumn[]
}): CsvRow[] {
  // +------------------+---------------+------+
  // | TIMESTAMP_HEADER |  <ColumnName> |  ... |
  // +------------------+---------------+------+
  // | <Timestamp>      |  <CellVakue>  |  ... |
  // | ...              | ...           |  ... |
  // +------------------+---------------+------+

  type Timestamp = number // milliseconds
  type Column = Map<ColumnName, CellValue>
  type Row = Map<Timestamp, Column>

  // Collect the column names and check for duplicated ones.
  const allColumnNames: ColumnName[] = []
  for (const c of opts.columns) {
    if (allColumnNames.includes(c.columnName)) {
      throw new Error(
        'createCsvRowsFromTimeSeriesData: multiple columns with same name'
      )
    }
    allColumnNames.push(c.columnName)
  }

  const mapRow: Row = new Map()

  // Collect values.
  for (const column of opts.columns) {
    for (const datapoint of column.datapoints) {
      const timestamp = column.getDpTimestamp(datapoint)
      const value = column.getDpValue(datapoint)

      const mapColumn = mapRow.get(timestamp)

      if (mapColumn === undefined) {
        const newMapColumn: Column = new Map()
        newMapColumn.set(column.columnName, column.getDpValue(datapoint))
        mapRow.set(timestamp, newMapColumn)
      } else {
        mapColumn.set(column.columnName, value)
      }
    }
  }

  // Create rows.
  const rows: CsvRow[] = []

  const sortedByTimestamp = Array.from(mapRow.entries()).sort((a, b) => {
    const aTimestamp = a[0]
    const bTimestamp = b[0]
    return aTimestamp < bTimestamp ? -1 : aTimestamp > bTimestamp ? 1 : 0
  })

  for (const [timestamp, mapColumn] of sortedByTimestamp) {
    // Initialize the row.
    const row: CsvRow = {
      [opts.timestampHeader]: opts.timestampFormatter(timestamp),
    }

    // Get values for each column, using the column name as index.
    for (const columnName of allColumnNames) {
      const cellValue = mapColumn.get(columnName)
      row[columnName] = cellValue ?? null
    }

    rows.push(row)
  }

  return rows
}

export function createCsvRowsFromSimpleChartData(
  chartData: ChartData,
  opts: { xAxisColumnHeader: string }
): CsvRow[] {
  // +----------------------+----------------+------+
  // | X_AXIS_COLUMN_HEADER |   SERIES NAME  |  ... |
  // +----------------------+----------------+------+
  // | X Axis value         |  Y Axis value  |  ... |
  // | ...                  | ...            |  ... |
  // +----------------------+----------------+------+

  const xMap = new Map<NumberOrNull, CsvRow>()

  const createSeriesName = (ds: ChartData['datasets'][number], index: number) =>
    ds.label ?? `Serios ${index}`

  const createEmptyRow = (xValue: number) => {
    return chartData.datasets.reduce<CsvRow>(
      (acc, ds, index) => {
        acc[createSeriesName(ds, index)] = null
        return acc
      },
      { [opts.xAxisColumnHeader]: xValue }
    )
  }

  chartData.datasets.forEach((ds, index) => {
    const seriesName = createSeriesName(ds, index)

    ds.data.forEach((point) => {
      point = point as Point
      const existingRow = xMap.get(point.x)
      if (existingRow) {
        existingRow[seriesName] = point.y
      } else {
        xMap.set(point.x, { ...createEmptyRow(point.x), [seriesName]: point.y })
      }
    })
  })

  return Array.from(xMap.values())
}
