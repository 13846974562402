export enum CursorType {
  DEFAULT = 'move',
  DRAG = 'drag',
  SCROLL_BACKWARD = 'scroll-backward',
  SCROLL_FORWARD = 'scroll-forward',
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
}

export enum Direction {
  BACKWARD = 'Backward',
  FORWARD = 'Forward',
  UP = 'Up',
  DOWN = 'Down',
  NONE = 'None',
}

export const DEFAULT_HEIGHT = 260

/** The amount of time in milliseconds to wait for the user to finish mouse wheel movement */
export const ZOOM_DEBOUNCE = 450
