import { ChartOptions } from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'

import { GREY2 } from '@/constants/colors'
import { NamedTimeSeries } from '@/model/charts/TimeSeriesDataSource'
import { colorWithOpacity } from '@/utils/colors'

interface VoltageToleranceConfig {
  readonly percentage: number
  /** Overrides the nominal voltage computed from the time series */
  readonly nominal?: number
}

/**
 * Generate the voltage tolerance annotation configuration based on the series data.
 * The tolerance percentage may be specified in the chart configuration.
 * @param config Voltage tolerance parameters
 * @returns ChartJS chart options for the voltage annotation
 */
export function createVoltageAnnotation(
  config: VoltageToleranceConfig,
  timeSeries: readonly NamedTimeSeries[]
): ChartOptions {
  const { nominal, percentage } = config
  // If this is 3-phase, we may need multiple annotations
  const voltages = timeSeries.reduce((acc, ts) => {
    const vNominal = nominal ?? voltageNominal(ts.meanY)
    // A zero voltage means no (or corrupted) data, so no annotation
    return vNominal ? acc.add(vNominal) : acc
  }, new Set<number>())

  if (voltages.size === 0) return {}

  const annotations = Array.from(voltages.values()).map((vNominal) => {
    const tolerance = vNominal * (percentage / 100)
    const voltageBox: AnnotationOptions = {
      type: 'box',
      backgroundColor: colorWithOpacity(GREY2.hex, 0.25),
      borderWidth: 0,
      yMin: vNominal - tolerance,
      yMax: vNominal + tolerance,
      label: {
        content: `± ${percentage}%`,
        display: true,
        position: { x: 'end', y: 'start' },
        yAdjust: -20, // Move it above the annotation box
      },
    }
    return voltageBox
  })

  const vMin = Math.min(...voltages)
  const vMax = Math.max(...voltages)
  const padding = (percentage * 2) / 100

  return {
    plugins: { annotation: { annotations } },
    // The tolerance requires extending the YAxis to create padding.
    // The data might have a min/max value outside the tolerance padding.
    scales: {
      y: {
        min: vMin - padding * vMin,
        max: vMax + padding * vMax,
        ticks: { count: 3 * annotations.length + 2, maxTicksLimit: 11 },
      },
    },
  }
}

const nominalVoltages = [120, 208, 240, 277, 480]

/**
 * Return the nominal voltage given a specific or mean voltage value.
 */
export function voltageNominal(meanVoltage: number): number {
  // Use a comparison +/- 10% to find the nominal voltage range
  const voltage = nominalVoltages.find(
    (r) => meanVoltage >= r * 0.9 && meanVoltage <= r * 1.1
  )
  return voltage ?? 0
}
