export function downloadBlob(blob: Blob, fileNameAndExt: string): void {
  const url = window.URL || window.webkitURL
  const link = url.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = fileNameAndExt
  a.href = link
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
