import { OPTION_ITEMS_PER_PAGE } from '@/constants/table'
import { DataOptions as VuetityDataTableOptions } from '@/types/vuetify3'
import {
  FilterFields,
  FilterFieldsMinMax,
  FilterMultiSelect,
  Filters,
  Header,
  Options,
  Row,
  RowIDSet,
  RowWithChildTable,
  DataTable,
} from './DataTable'
import { NumberOrNull } from '@/types/charts'
import { TimeSeries as TsdbTimeSeries } from 'rfs/frontend/proto/tsdb_pb'
import { ColumnType } from './column'

export const NO_MATCHING_RECORDS_FOUND = 'No matching records found'

export const IS_LOADING = 'is-loading'

type IsFieldLoading = typeof IS_LOADING

export type SearchValue = null | string

export type TimeSeries = {
  timeSeries: null | TsdbTimeSeries
  min: NumberOrNull
  max: NumberOrNull
}

export enum TimeSeriesColumns {
  TIME_SERIES_MIN = 'TIME_SERIES_MIN',
  TIME_SERIES_MAX = 'TIME_SERIES_MAX',
  TIME_SERIES = 'TIME_SERIES',
}

export interface ITimeSeriesColumns {
  [TimeSeriesColumns.TIME_SERIES_MIN]: IsFieldLoading | TimeSeries['min']
  [TimeSeriesColumns.TIME_SERIES_MAX]: IsFieldLoading | TimeSeries['max']
  [TimeSeriesColumns.TIME_SERIES]: IsFieldLoading | TimeSeries['timeSeries']
}

export type MapTimeSeriesValue = IsFieldLoading | TimeSeries
export type MapTimeSeries<MapIndex extends string = string> = Map<
  MapIndex,
  MapTimeSeriesValue
>

export function isTsdbTimeSeries(
  v: NumberOrNull | TsdbTimeSeries
): v is TsdbTimeSeries {
  return (
    v !== null &&
    Object.prototype.hasOwnProperty.call(v, 'metric') &&
    Object.prototype.hasOwnProperty.call(v, 'data')
  )
}

export function transformToMapTimeSeriesValue(
  series: TsdbTimeSeries
): TimeSeries {
  const { min, max } = series

  const minY = min?.y ?? series.minY ?? null
  const maxY = max?.y ?? series.maxY ?? null

  return {
    timeSeries: series,
    min: Number.isFinite(minY) ? minY : null,
    max: Number.isFinite(maxY) ? maxY : null,
  }
}

type PlaceholderMinMaxValues = { min?: number; max?: number }
export type PlaceholdersMinMax<Columns extends string = string> = Map<
  Columns | TimeSeriesColumns,
  PlaceholderMinMaxValues
>

/**
 * Fills the existing Placeholders Map with the time series placeholder values.
 */
export function fillPlaceholdersWithTimeSeries<T extends string = string>(
  placeholders: PlaceholdersMinMax<T>,
  mapTimeSeries: MapTimeSeries
): PlaceholdersMinMax<T> {
  let minMin: undefined | number
  let minMax: undefined | number
  let maxMin: undefined | number
  let maxMax: undefined | number

  for (const [_, series] of mapTimeSeries.entries()) {
    if (series === IS_LOADING) {
      continue
    }

    const { min, max } = series

    if (min !== null) {
      minMin = minMin === undefined ? min : Math.min(minMin, min)
      minMax = minMax === undefined ? min : Math.max(minMax, min)
    }

    if (max !== null) {
      maxMin = maxMin === undefined ? max : Math.min(maxMin, max)
      maxMax = maxMax === undefined ? max : Math.max(maxMax, max)
    }
  }

  return new Map(placeholders)
    .set(TimeSeriesColumns.TIME_SERIES_MAX, { min: maxMin, max: maxMax })
    .set(TimeSeriesColumns.TIME_SERIES_MIN, { min: minMin, max: minMax })
}

/**
 * Only returns `true` when `value` respects min and max contained in the
 * min and max fields OR no filter fields available.
 *
 * When `value` is null, the function always returns `false`.
 */
export function filterByMinMax(
  value: NumberOrNull,
  { min, max, multiplier }: FilterFieldsMinMax
): boolean {
  const applyMultiplier = (v: NumberOrNull) =>
    multiplier !== undefined && v !== null ? v * multiplier : v

  const minValue = applyMultiplier(min.value)
  const maxValue = applyMultiplier(max.value)

  if (minValue !== null || maxValue !== null) {
    if (value !== null) {
      const isBetweenMinMax =
        minValue !== null &&
        maxValue !== null &&
        value >= minValue &&
        value <= maxValue

      const isLessThanOrEqualToMax =
        minValue === null && maxValue !== null && value <= maxValue

      const isGreaterThanOrEqualToMin =
        minValue !== null && maxValue === null && value >= minValue

      return (
        isBetweenMinMax || isLessThanOrEqualToMax || isGreaterThanOrEqualToMin
      )
    }

    return false
  }

  return true
}

/**
 * Tests if one of the filters has been set by the user.
 */
export function hasAtLeastOneFilterValue(filters: Filters): boolean {
  return Array.from(filters.values()).some(hasFilterValue)
}

/**
 * Create default options: page 1, max 25 rows, sorted by `column`.
 */
export function createDefaultOptions(
  column: string,
  order?: 'descending'
): Options {
  return {
    page: 1,
    itemsPerPage: OPTION_ITEMS_PER_PAGE[0],
    orderBy: { column, descending: order === 'descending' },
  }
}

export function createVuetifyOptions(
  options: Options
): VuetityDataTableOptions {
  const { page, itemsPerPage, orderBy } = options
  return {
    page,
    itemsPerPage,
    sortBy: [
      { key: orderBy.column, order: orderBy.descending ? 'desc' : 'asc' },
    ],
    groupBy: [],
  }
}

export function createDataTableOptions(
  headers: Header[],
  options: VuetityDataTableOptions
): Options {
  const { page, itemsPerPage, sortBy } = options
  return {
    page,
    itemsPerPage,
    orderBy: {
      column: sortBy.length ? sortBy[0].key : headers[0].key,
      descending: Boolean(sortBy.length && sortBy[0].order === 'desc'),
    },
  }
}

export const MULTIPLIER_KW = 1_000
export const MULTIPLIER_MW = 1_000_000
export const MULTIPLIER_PERCENT = 0.01

export function newFilterFieldsMinMax(multiplier?: number): FilterFieldsMinMax {
  return {
    type: 'min-max',
    min: { label: 'Min', value: null },
    max: { label: 'Max', value: null },
    multiplier,
  }
}

function addSuffixToFieldsMinMax(
  f: FilterFieldsMinMax,
  suffix: string
): FilterFieldsMinMax {
  f.min.label = `${f.min.label} ${suffix}`
  f.max.label = `${f.max.label} ${suffix}`
  return f
}

export function addKwSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(kW)')
}

export function addMwSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(MW)')
}

export function addKwhSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(kWh)')
}

export function addMwhSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(MWh)')
}

export function addKvaSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(kVA)')
}

export function addPercentageSuffix(f: FilterFieldsMinMax): FilterFieldsMinMax {
  return addSuffixToFieldsMinMax(f, '(%)')
}

export function newFilterMultiSelect(): FilterMultiSelect {
  return { type: 'multiselect', items: [], value: [] }
}

/**
 * Determines if the given filter state has a non-empty value.
 */
export function hasFilterValue(f: FilterFields): boolean {
  switch (f.type) {
    case 'date':
      return f.value != null
    case 'dropdown':
      return f.value != null
    case 'min-max':
      return f.max.value != null || f.min.value != null
    case 'multiselect':
      return f.value.length !== 0
    case 'resources':
      return f.value.length !== 0
  }
}

export function isFilterMinMax(
  filter: FilterFields
): filter is FilterFieldsMinMax {
  return filter.type === 'min-max'
}

export function headerTimeSeriesMin(title: string, type: ColumnType): Header {
  return {
    title,
    key: TimeSeriesColumns.TIME_SERIES_MIN,
    valueType: type,
    sortable: true,
    align: 'end',
    width: '12%',
  }
}

export function headerTimeSeriesMax(title: string, type: ColumnType): Header {
  return {
    title,
    key: TimeSeriesColumns.TIME_SERIES_MAX,
    valueType: type,
    sortable: true,
    align: 'end',
    width: '12%',
  }
}

export const headerTimeSeries: Header = {
  title: 'Last 24 hours',
  key: TimeSeriesColumns.TIME_SERIES,
  align: 'center',
  width: '18%',
  cellProps: { class: 'font-weight-medium' },
  sortable: false,
}

type AnyRow = Row | RowWithChildTable<DataTable<AnyRow>>

/**
 * Return all row IDs in the table, including rows in any child tables.
 */
export function allRowIDs(table: DataTable<AnyRow>): RowIDSet {
  const addIDs = (ids: RowIDSet, row: AnyRow): RowIDSet => {
    // If there are children, add their IDs.
    if ('childTable' in row && row.childTable.rows.length) {
      row.childTable.rows.reduce(addIDs, ids)
    }
    return ids.add(row.id)
  }

  return table.rows.reduce(addIDs, new Set())
}

/**
 * Return row IDs of child rows in the table. Only the first level
 * of children are returned.
 */
export function allChildRowIDs(table: DataTable<AnyRow>): RowIDSet {
  const addChildIDs = (ids: RowIDSet, row: AnyRow): RowIDSet => {
    // If there are children, add their IDs.
    if ('childTable' in row) {
      row.childTable.rows.forEach((r) => ids.add(r.id))
    }
    return ids
  }

  return table.rows.reduce(addChildIDs, new Set())
}
