import { PlainMessage } from '@bufbuild/protobuf'

import { ResourceType } from '@/constants/resourceType'
import {
  Header,
  DataTable,
  Filters,
  Options,
  Row,
} from '@/model/tables/DataTable'
import {
  ListTopTransformerLoadRequest_Field as Field,
  ListTopTransformerLoadResponse_TransformerLoad as TransformerLoad,
} from 'rfs/frontend/proto/transformer_details_pb'
import { ColumnType } from '../tables/column'
import {
  createFeederRoute,
  createTransformerRoute,
} from '@/utils/router/create'

export type CustomFilters = Filters<string>

export type CustomOptions = Options<string>

export type TransformerRow = PlainMessage<TransformerLoad> & Row

export interface TransformersDataTable extends DataTable<TransformerRow> {}

const baseHeaders: Header[] = [
  <Header<TransformerRow>>{
    title: 'Transformer number',
    key: 'transformer.id',
    filterable: false,
    sortable: false,
    columnSelector: { required: true },
    routeFactory: (_config, r) => createTransformerRoute(r.transformer!.id),
  },
  <Header<TransformerRow>>{
    title: 'Feeder',
    key: 'transformer.feeder',
    sortable: false,
    routeFactory: (_config, r) => {
      const id = r.transformer?.feeder
      return id ? createFeederRoute(id) : undefined
    },
  },
  {
    title: 'Rating (kVA)',
    key: 'transformer.rating',
    valueType: ColumnType.KILO_VA,
    align: 'end',
  },
  {
    title: 'Load factor (%)',
    key: 'loadFactorPercent',
    valueType: ColumnType.PERCENT,
    align: 'end',
  },
  {
    title: 'Utilization factor (%)',
    key: 'maxLoadPercent',
    valueType: ColumnType.PERCENT,
    align: 'end',
  },
  {
    title: 'Time overloaded (%)',
    key: 'overloadDurationPercent',
    valueType: ColumnType.PERCENT,
    align: 'end',
  },
]

const solarDistHeader: Header = {
  title: 'BTM PV',
  key: `downlineStats.${ResourceType.SOLAR_DISTRIBUTED}.count`,
  valueType: ColumnType.INTEGER,
  align: 'end',
}
const batteryDistHeader: Header = {
  title: 'BTM BESS',
  key: `downlineStats.${ResourceType.BATTERY_DISTRIBUTED}.count`,
  valueType: ColumnType.INTEGER,
  align: 'end',
}
const chargerHeader: Header = {
  title: 'EVSE',
  key: `downlineStats.${ResourceType.CHARGER}.count`,
  valueType: ColumnType.INTEGER,
  align: 'end',
}

export const columnFieldMap = new Map<string, Field>()
  .set('maxLoadPercent', Field.MAX_LOAD_PERCENT)
  .set('loadFactorPercent', Field.LOAD_FACTOR)
  .set('overloadDurationPercent', Field.OVERLOAD_DURATION_PERCENT)
  .set('maxPowerApparent', Field.MAX_LOADING)
  .set('transformer.rating', Field.RATING)
  .set('transformer.feeder', Field.FEEDER)
  .set(
    `downlineStats.${ResourceType.SOLAR_DISTRIBUTED}.count`,
    Field.SOLAR_COUNT
  )
  .set(
    `downlineStats.${ResourceType.BATTERY_DISTRIBUTED}.count`,
    Field.BATTERY_COUNT
  )
  .set(`downlineStats.${ResourceType.CHARGER}.count`, Field.CHARGER_COUNT)

/**
 * Determine which DER columns to display based on the available resources.
 */
export function transformersDataTableHeaders(resources: Set<ResourceType>) {
  const headers = baseHeaders.slice()

  if (resources.has(ResourceType.SOLAR_DISTRIBUTED)) {
    headers.push(solarDistHeader)
  }
  if (resources.has(ResourceType.BATTERY_DISTRIBUTED)) {
    headers.push(batteryDistHeader)
  }
  if (resources.has(ResourceType.CHARGER)) {
    headers.push(chargerHeader)
  }

  return headers
}

declare module 'rfs/frontend/proto/transformer_details_pb' {
  /** Make it possible for TransformerLoad objects to be used as Table Rows */
  interface ListTopTransformerLoadResponse_TransformerLoad {
    get id(): string
  }
}

Object.defineProperty(TransformerLoad.prototype, 'id', {
  configurable: false,
  get(this: TransformerLoad): string {
    return this.transformer?.id ?? ''
  },
})
