<template>
  <div class="d-flex">
    <!-- Tint filter -->
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="d-none">
      <defs>
        <filter id="tint-primary">
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0353
                    0 0 0 0 0.2667
                    0 0 0 0 0.7412
                    0 0 0 1 0"
          />
        </filter>
      </defs>
    </svg>

    <template v-for="(group, groupIndex) of groups" :key="group.id">
      <!-- Contains multiple layers -->
      <split-control-btn
        v-if="useSplitBtn(group)"
        :class="{ 'mr-2': groups.length !== groupIndex + 1 }"
        :tooltip="group.tooltip"
        :model-value="group.isActive"
        :icon="group.icon"
        @update:model-value="() => toggleVisibility(group.id)"
      >
        <!-- Entire list -->
        <template v-slot:dropdown>
          <v-list
            class="elevation-3 pa-0 rounded"
            :class="{ 'overflow-y-auto': isOverflowYEnabled }"
            :max-height="computedVListMaxHeight"
          >
            <!-- Sub groups -->
            <template
              v-for="(subgroup, subgroupIndex) of group.subgroups"
              :key="`${group.id}--${subgroupIndex}--0`"
            >
              <v-list
                v-if="subgroup"
                data-testid="menu-subgroup"
                :selected="subgroup.idOfActiveOnes"
                :items="subgroup.items"
                item-title="text"
                item-value="id"
                @click:select="handleItemClicked"
                color="primary"
                select-strategy="independent"
              >
                <template v-slot:prepend="{ isActive, item }">
                  <!-- Icon -->
                  <img
                    :src="item.icon"
                    :alt="item.text"
                    class="d-block mr-4"
                    :style="{
                      filter: item.props.disabled
                        ? 'brightness(0%) contrast(0.1)'
                        : isActive
                        ? 'url(#tint-primary)'
                        : 'none',
                    }"
                    width="24px"
                  />
                </template>
              </v-list>

              <!-- Show divider if the next subgroup has items -->
              <v-divider
                v-if="group.subgroups[subgroupIndex + 1]?.items?.length"
              />
            </template>
          </v-list>
        </template>
      </split-control-btn>

      <!-- Contains single layer -->
      <div
        v-else
        data-test="control-btn"
        :class="{ 'mr-2': groups.length !== groupIndex + 1 }"
        :key="group.id"
      >
        <v-tooltip :disabled="!group.tooltip" location="bottom">
          <template v-slot:activator="{ props }">
            <button
              v-bind="props"
              type="button"
              class="control-btn gmaps-box-shadow rounded"
              @click="() => toggleGroupAndLayersVisibility(group)"
            >
              <img
                :src="group.icon"
                :alt="group.tooltip"
                :style="{
                  filter: group.isActive ? 'url(#tint-primary)' : 'none',
                }"
                width="24px"
              />
            </button>
          </template>

          <span v-if="group.tooltip">{{ group.tooltip }}</span>
        </v-tooltip>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapActions } from 'pinia'
import { MapLayerGroupId, MapLayerId } from '@/config/types'
import { useCustomFiles } from '@/stores/customFiles'
import { useGridMapStore, UpdateSwitches } from '@/stores/gridMap'
import SplitControlBtn from '@/components/others/shared/SplitControlBtn.vue'
import { mapLayerCatalog } from '@/components/maps/layers/catalog'
import { Analytics } from '@/utils/analytics'
import { ControlListItem, GroupSchema } from './CeControls'
import {
  mapLayerGroupCatalog,
  mapLayerMenuGroups,
} from '@/components/maps/layerGroups/catalog'

export default defineComponent({
  name: 'CeControls',
  components: { SplitControlBtn },
  data() {
    return {
      /**
       * Overflow Y is turned off by default in VList component.
       * When the device has a small height, the overflow Y
       * should be `auto`.
       * When overflow Y auto is activated and VList max-height is set,
       * the scrollbar is going to show.
       */
      isOverflowYEnabled: false,
      /**
       * The following value is arbritary and is based on tests
       * with different device heights.
       */
      minHeightBreakpoint: 900, // pixels
    }
  },
  computed: {
    ...mapState(useGridMapStore, ['switches', 'mapLayersGroups']),
    ...mapState(useCustomFiles, ['customMapLayers']),
    groups(): GroupSchema[] {
      const groups: GroupSchema[] = []

      for (const lg of this.mapLayersGroups ?? []) {
        const mapLayerGroupId = lg.id as MapLayerGroupId

        const lgConfig = mapLayerGroupCatalog.get(mapLayerGroupId)

        if (lgConfig === undefined) continue

        const group: GroupSchema = {
          subgroups: [{ items: [], idOfActiveOnes: [] }],
          id: mapLayerGroupId,
          isActive: this.switches?.has(lg.id) ?? false,
          icon: lgConfig.icon,
          tooltip: lgConfig.label,
        }

        const layers = lg.layers.filter(
          (l) => !l.hideSwitch && mapLayerCatalog.has(l.id as MapLayerId)
        )

        for (const layer of layers) {
          const layerId = layer.id as MapLayerId

          // The filter above guarantees the layer is in the map
          const layerConfig = mapLayerCatalog.get(layerId)
          // Determine which group the layer belongs in
          const layerMenu = mapLayerMenuGroups.get(layerId)
          // Assume it's going in the first subgroup
          let subgroup = group.subgroups[0]

          if (!layerConfig) continue

          if (layerMenu != null) {
            if (!group.subgroups[layerMenu]) {
              group.subgroups[layerMenu] = { items: [], idOfActiveOnes: [] }
            }
            subgroup = group.subgroups[layerMenu]
          }

          const item: ControlListItem = {
            id: layerId,
            text: layerConfig.label,
            icon: layerConfig.icon,
            props: { disabled: layer.disabled },
          }

          subgroup.items.push(item)

          // Add the id if the layer is active
          if (this.switches?.has(layer.id)) {
            subgroup.idOfActiveOnes.push(layer.id)
          }
        }

        groups.push(group)
      }

      const gridGroup = groups.find((g) => g.id === MapLayerGroupId.GRID)
      // If there are custom map layers, add them in a new sub-group
      if (gridGroup && this.customMapLayers.length) {
        const idOfActiveOnes: string[] = []
        const items = this.customMapLayers.map((layer) => {
          if (this.switches?.has(layer.id)) {
            idOfActiveOnes.push(layer.id)
          }
          const item: ControlListItem = {
            id: layer.id as MapLayerId,
            text: layer.title,
            icon: layer.icon,
            props: { disabled: false },
          }
          return item
        })
        gridGroup.subgroups.push({ items, idOfActiveOnes })
      }
      return groups
    },
    computedVListMaxHeight(): number | undefined {
      return this.isOverflowYEnabled
        ? 400 // pixels
        : undefined
    },
  },
  watch: {
    '$vuetify.display.height': {
      immediate: true,
      handler: function () {
        this.checkHeightAndAdjustOverflowY()
      },
    },
  },
  methods: {
    ...mapActions(useGridMapStore, ['updateSwitches']),
    /** * Required for devices with small height dimension. */
    checkHeightAndAdjustOverflowY(): void {
      const currentHeightInPixels = this.$vuetify.display.height
      this.isOverflowYEnabled = currentHeightInPixels < this.minHeightBreakpoint
    },
    /** * When a layer group contains at least 2 subgroups or 2 layers. */
    useSplitBtn(group: GroupSchema): boolean {
      return (
        group.subgroups.length > 1 ||
        group.subgroups.reduce<boolean>((acc, g) => {
          return acc || g.items.length > 1
        }, false)
      )
    },
    toggleVisibility(id: MapLayerGroupId | MapLayerId): void {
      this.updateSwitches([{ id, newValue: 'toggle' }])
      Analytics.logMapLayer(id, this.switches.has(id))
    },
    handleItemClicked(payload: {
      id: unknown
      value: boolean
      path: unknown[]
    }): void {
      const id = payload.id as MapLayerGroupId | MapLayerId
      this.toggleVisibility(id)
    },
    /**
     * When the group has a single map layer, toggle the group AND the layer
     * visibility at the same time.
     */
    toggleGroupAndLayersVisibility(group: GroupSchema): void {
      const payload: UpdateSwitches = [{ id: group.id, newValue: 'toggle' }]

      for (const subgroup of group.subgroups) {
        for (const layer of subgroup.items) {
          payload.push({ id: layer.id, newValue: 'toggle' })
        }
      }

      this.updateSwitches(payload)
    },
  },
})
</script>
