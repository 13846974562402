import {
  Accessor,
  CompositeLayer,
  CompositeLayerProps,
  DefaultProps,
  Layer,
  LayerDataSource,
  LayerProps,
  UpdateParameters,
} from '@deck.gl/core'
import {
  PathStyleExtension,
  PathStyleExtensionProps,
} from '@deck.gl/extensions'
import {
  GeoJsonLayer,
  GeoJsonLayerProps,
  IconLayer,
  IconLayerProps,
  PathLayer,
  PathLayerProps,
  ScatterplotLayer,
  ScatterplotLayerProps,
  SolidPolygonLayer,
  SolidPolygonLayerProps,
} from '@deck.gl/layers'
import { TileLayer, TileLayerProps } from '@deck.gl/geo-layers'
import { TileLoadProps } from '@deck.gl/geo-layers/dist/tileset-2d'
import { ResourceType } from '@/constants/resourceType'
import {
  TileRequest_DetailLevel as DetailLevel,
  TileResponse,
} from 'rfs/frontend/proto/tile_pb'
import {
  COMMON_SIZE_DIVISOR_14,
  ICON_MAX_PIXELS,
  ICON_SIZE,
} from '@/model/map/constants'
import { LatLng, MapLayerFeature } from '@/model/map/types'
import { Feature, GeometryType, TileService } from '@/services/tile.service'
import { getIconAtlas } from './iconAtlas'

/**
 * An extension of the ScatterplotLayer that supports resizing the circle on hover.
 */
export class CeScatterplotLayer extends ScatterplotLayer {
  static layerName = 'CeScatterplotLayer'

  getShaders() {
    const shaders = super.getShaders()
    // TODO: Remove the hard-coded 1.5 and replace with `props.radiusScale`
    shaders.inject = {
      'vs:DECKGL_FILTER_SIZE': `
       vec3 normalizedPickingColor = picking_normalizeColor(geometry.pickingColor);
       if (!isVertexHighlighted(normalizedPickingColor)) { size /= 1.5; }
      `,
    }
    return shaders
  }
}

type NoId<T> = Omit<T, 'id' | 'data'>

interface ExtraProps {
  circles?: NoId<ScatterplotLayerProps<Feature>>
  icons?: NoId<IconLayerProps<Feature>>
  lines?: NoId<PathLayerProps<Feature> & PathStyleExtensionProps<Feature>>
  polygons?: NoId<SolidPolygonLayerProps<Feature>>
}

// https://github.com/visgl/deck.gl/issues/2169#issuecomment-704585080
export const IconLoadNoAlpha = {
  imagebitmap: { premultiplyAlpha: 'none' },
} as const

/**
 * Convert the tile response layer data into deck.gl Layers.
 * The following mapping is used:
 * - GeometryType.POINT -> ScatterplotLayer or IconLayer
 * - GeometryType.LINE_STRING -> PathLayer
 * - GeometryType.POLYGON -> SolidPolygonLayer
 */
export function getTileResponseLayers(
  tileProps: LayerProps,
  extraProps: ExtraProps
): Layer[] {
  const [iconAtlas, iconMapping] = getIconAtlas()
  const response = tileProps.data as unknown as TileResponse | null
  // Handle props.getTileData() returning `null` because of an error
  if (response == null) return []

  return response.layers.map((layer, index) => {
    // Extract the primary properties that can apply to any layer
    const props = {
      ...tileProps,
      id: `${tileProps.id}--${index}`,
      data: layer.features as LayerDataSource<Feature>,
    }

    switch (layer.geometryType) {
      case GeometryType.POINT:
        if (!extraProps.circles) {
          return new IconLayer(props, {
            // @ts-ignore
            iconAtlas,
            iconMapping,
            getIcon,
            getPosition,
            getSize: ICON_SIZE,
            ...extraProps.icons,
          })
        } else {
          return new CeScatterplotLayer(props, {
            getPosition,
            ...extraProps.circles,
          })
        }
      case GeometryType.LINE_STRING:
        return new PathLayer(props, {
          getPath,
          positionFormat: 'XY',
          ...extraProps.lines,
        })
      case GeometryType.POLYGON:
        return new SolidPolygonLayer(props, {
          getPolygon,
          positionFormat: 'XY',
          ...extraProps.polygons,
        })
      default:
        throw new Error('Unknown Geometry', layer.geometryType)
    }
  })
}

function getIcon(f: MapLayerFeature): string {
  return f.resource?.type ?? 'unknown'
}

export function getPosition(f: MapLayerFeature): [number, number] {
  const { lat, lng } = f.resource?.location?.point || {}

  if (lat === undefined || lng === undefined) {
    console.error(`getPosition: "${f.resource?.id}" has no location`)
    return [0, 0]
  }

  return [lng, lat]
}

export function getPath(f: MapLayerFeature): number[] {
  return f.resource?.location?.lineString.flatMap(toPosition) ?? []
}

export function getPolygon(f: MapLayerFeature): number[] {
  return f.resource?.location?.polygon.flatMap(toPosition) ?? []
}

export function toPosition(latlng: LatLng): [number, number] {
  return [latlng.lng, latlng.lat]
}

/**
 * Return props for an icon that maintains a size between 24px and 48px
 * based on the zoom level.
 */
export function scalingIconProps(
  offset: Accessor<Feature, [number, number]>,
  commonSizeDivisor: number = COMMON_SIZE_DIVISOR_14
): NoId<IconLayerProps<Feature>> {
  return {
    /* Using the "common" size unit allows the icon to get smaller as the user zooms out. */
    sizeUnits: 'common',
    sizeMinPixels: ICON_MAX_PIXELS / 2,
    sizeMaxPixels: ICON_MAX_PIXELS,
    getSize: (ICON_MAX_PIXELS * 0.75) / commonSizeDivisor,
    /* The pixel offset is needed to prevent stacking */
    getPixelOffset: offset,
  }
}

export type MultiGeoJsonLayerProps = CompositeLayerProps & {
  propsList: Array<Partial<GeoJsonLayerProps>>
}

/**
 * An extension of _CompositeLayer_ that renders multiple instances of
 * _GeoJsonLayer_ together.
 */
export class MultiGeoJsonLayer extends CompositeLayer<MultiGeoJsonLayerProps> {
  static layerName = 'MultiGeoJsonLayer'
  static defaultProps: DefaultProps<MultiGeoJsonLayerProps> = {
    propsList: { type: 'data', value: [] },
  }

  renderLayers() {
    return this.props.propsList.map(
      (props, idx) =>
        new GeoJsonLayer(
          this.getSubLayerProps({
            ...props,
            id: `${idx}`,
            data: props.data,
          })
        )
    )
  }
}

/**
 * This class extension has only one objective: block tile data fetching
 * while the layer not visible to the user.
 */
export class CustomTileLayer<DataT = any> extends TileLayer<DataT> {
  static layerName = 'CustomTileLayer'

  /**
   * Prevents execution of code related to the tiles when "visible: false".
   *
   * NOTE: Even when blocking the normal code execution path, the tileset
   * must be instantiated to avoid errors.
   */
  updateState(params: UpdateParameters<this>): void {
    if (!params.props.visible) {
      if (!this.state.tileset) {
        this.setState({
          tileset: new this.props.TilesetClass(this._getTilesetOptions()),
        })
      }

      // Abort.
      return
    }

    // Normal execution path.
    super.updateState(params)
  }
}

interface DetailLevelsProps extends LayerProps {
  resourceType: ResourceType
  low: NoId<TileLayerProps>
  medium: NoId<TileLayerProps>
  high: NoId<TileLayerProps>
  renderSubLayers: TileLayerProps['renderSubLayers']
  tileService: TileService
  hasCallouts?: boolean
}

/**
 * The DetailLevels layer manages individual tile layers for the three levels of detail:
 * Low, Medium, and High.
 */
export class DetailLevelsLayer extends CompositeLayer<DetailLevelsProps> {
  static layerName = 'DetailLevelsLayer'

  constructor(readonly layerProps: DetailLevelsProps) {
    super(layerProps)
  }

  renderLayers(): Layer<TileLayerProps>[] {
    const { low, medium, high, hasCallouts } = this.props
    const dashExtension = [new PathStyleExtension({ dash: true })]

    return [
      new CustomTileLayer(
        this.getSubLayerProps({
          id: 'low',
          getTileData: this.getTileData.bind(this, DetailLevel.LOW),
          renderSubLayers: this.props.renderSubLayers,
          ...low,
        })
      ),
      new CustomTileLayer(
        this.getSubLayerProps({
          id: 'med',
          getTileData: this.getTileData.bind(this, DetailLevel.MEDIUM),
          renderSubLayers: this.props.renderSubLayers,
          ...medium,
        })
      ),
      new CustomTileLayer(
        this.getSubLayerProps({
          id: 'high',
          getTileData: this.getTileData.bind(this, DetailLevel.HIGH),
          renderSubLayers: this.props.renderSubLayers,
          extensions: hasCallouts ? dashExtension : [],
          ...high,
        })
      ),
    ]
  }

  private async getTileData(detailLevel: DetailLevel, props: TileLoadProps) {
    const { hasCallouts, tileService, resourceType } = this.props

    if (hasCallouts && detailLevel === DetailLevel.HIGH) {
      const responses = await Promise.all([
        tileService.getTileResources(ResourceType.CALLOUT, props),
        tileService.getTileResources(resourceType, props, detailLevel),
      ])
      return new TileResponse({ layers: responses.flatMap((r) => r!.layers) })
    }
    return tileService.getTileResources(resourceType, props, detailLevel)
  }
}
