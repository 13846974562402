import { RouteRecordRaw, RouteMeta } from 'vue-router'
import { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import ControlOverviewPage from '@/views/ControlOverviewPage.vue'
import DeviceResource from '@/components/control/DeviceResource.vue'
import Group from '@/components/control/group/Group.vue'
import Device from '@/components/control/device/Device.vue'

export const TAB = TabId.CONTROL

export function controlRoutes(config: Readonly<RittaConfig>): RouteRecordRaw[] {
  if (!config.control?.enabled) return []

  const meta: RouteMeta = { tab: TAB }

  return [
    // Main route.
    {
      path: '/control',
      name: RouteNames.CONTROL_OVERVIEW,
      meta: { ...meta, pageTitle: TAB },
      component: ControlOverviewPage,
    },
    // Groups and Devices
    {
      path: '/control/group/:groupId',
      name: RouteNames.CONTROL_GROUP_DETAILS,
      meta,
      redirect: { name: RouteNames.CONTROL_GROUP_CONTROLS },
    },
    {
      // Legacy route.
      path: '/control/group/:groupId/scheduled-controls',
      redirect: () => {
        return { name: RouteNames.CONTROL_GROUP_CONTROLS }
      },
    },
    {
      path: '/control/group/:groupId/controls',
      component: Group,
      name: RouteNames.CONTROL_GROUP_CONTROLS,
      meta,
      props: true,
    },
    {
      path: '/control/group/:groupId/performance',
      component: Group,
      name: RouteNames.CONTROL_GROUP_PERFORMANCE,
      meta,
      props: true,
    },
    {
      path: '/control/group/:groupId/history',
      component: Group,
      name: RouteNames.CONTROL_GROUP_HISTORY,
      meta,
      props: true,
    },
    // Device routes.
    {
      path: '/control/group/:groupId/device/:deviceId',
      name: RouteNames.CONTROL_DEVICE,
      meta,
      redirect: { name: RouteNames.CONTROL_DEVICE_TELEMETRY },
    },
    {
      path: '/control/group/:groupId/device/:deviceId/telemetry',
      component: Device,
      name: RouteNames.CONTROL_DEVICE_TELEMETRY,
      meta,
      props: true,
    },
    {
      path: '/control/group/:groupId/device/:deviceId/performance',
      component: Device,
      name: RouteNames.CONTROL_DEVICE_PERFORMANCE,
      meta,
      props: true,
    },
    {
      path: '/control/group/:groupId/device/:deviceId/events',
      component: Device,
      name: RouteNames.CONTROL_DEVICE_HISTORY,
      meta,
      props: true,
    },
    // Device Resource routes.
    {
      path: '/control/group/:groupId/resource/:resourceId',
      name: RouteNames.CONTROL_DEVICE_RESOURCE,
      meta,
      redirect: {
        name: RouteNames.CONTROL_DEVICE_RESOURCE_TELEMETRY,
      },
    },
    {
      path: '/control/group/:groupId/resource/:resourceId/telemetry',
      component: DeviceResource,
      name: RouteNames.CONTROL_DEVICE_RESOURCE_TELEMETRY,
      meta,
      props: true,
    },
  ]
}
