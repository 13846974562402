<template>
  <info-panel :tabs :model-value @update:model-value="emitNewSelectedTab">
    <!-- Tab: Site -->
    <template v-slot:[SiteTab.SITE]>
      <site-customer-tab
        :site-resource="siteResource"
        :other-resources="otherResources"
      />
    </template>

    <!-- Tab: Consumption -->
    <template v-slot:[SiteTab.CONSUMPTION]>
      <site-consumption-tab
        :site-resource="siteResource"
        :other-resources="otherResources"
      />
    </template>

    <!-- Tab: DER -->
    <template v-slot:[SiteTab.DER]>
      <site-d-e-r-tab
        :site-resource="siteResource"
        :solar-distributed-resources="solarDistributedResources"
        :battery-distributed-resources="batteryDistributedResources"
        :charger-resources="chargerResources"
      />
    </template>
  </info-panel>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import { Resource } from 'rfs/pb/resource_pb'
import { SiteTab } from '@/model/site/tabs'
import InfoPanel, { type InfoPanelTab } from '@/components/InfoPanel.vue'
import SiteCustomerTab from '@/components/SiteCustomerTab.vue'
import SiteConsumptionTab from '@/components/SiteConsumptionTab.vue'
import SiteDERTab from '@/components/SiteDERTab.vue'

// Icons
import iconSite from '@/assets/imgs/albert_ic_location--black.svg'
import iconConsumption from '@/assets/imgs/albert_ic_meter--black.svg'
import iconDer from '@/assets/imgs/albert_ic_der--black.svg'
import iconGrid from '@/assets/imgs/albert_ic_grid--black.svg'

export default defineComponent({
  name: 'SiteInfoPanel',
  props: {
    modelValue: {
      type: String as PropType<SiteTab>,
      required: true,
    },
    siteResource: {
      type: Object as PropType<Resource>,
      required: true,
    },
    otherResources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  components: {
    InfoPanel,
    SiteCustomerTab,
    SiteConsumptionTab,
    SiteDERTab,
  },
  setup() {
    return { SiteTab }
  },
  computed: {
    tabs(): InfoPanelTab[] {
      return [
        { id: SiteTab.SITE, text: 'Site', icon: iconSite },
        {
          id: SiteTab.CONSUMPTION,
          text: 'Consumption',
          icon: iconConsumption,
        },
        {
          id: SiteTab.DER,
          text: 'DER',
          icon: iconDer,
          disabled: !this.isTabDEREnabled,
        },
        { id: SiteTab.GRID, text: 'Grid', icon: iconGrid, disabled: true },
      ]
    },
    isTabDEREnabled(): boolean {
      return (
        !!this.solarDistributedResources.length ||
        !!this.batteryDistributedResources.length ||
        !!this.chargerResources.length
      )
    },
    solarDistributedResources(): Resource[] {
      return this.otherResources.filter(
        (r) => r.type === ResourceType.SOLAR_DISTRIBUTED
      )
    },
    batteryDistributedResources(): Resource[] {
      return this.otherResources.filter(
        (r) => r.type === ResourceType.BATTERY_DISTRIBUTED
      )
    },
    chargerResources(): Resource[] {
      return this.otherResources.filter((r) => r.type === ResourceType.CHARGER)
    },
  },
  created(): void {
    // Select another tab when DER is disabled.
    if (!this.isTabDEREnabled && this.modelValue === SiteTab.DER) {
      this.emitNewSelectedTab(SiteTab.SITE)
    }
  },
  methods: {
    emitNewSelectedTab(newSelectedTab: SiteTab): void {
      this.$emit('update:model-value', newSelectedTab)
    },
  },
})
</script>
