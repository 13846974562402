<template>
  <v-chip
    :color="bgColor"
    density="comfortable"
    variant="flat"
    aria-label="Current Policy"
    :style="{ border: `1px solid ${fontColor}` }"
  >
    <span class="text-body-2" :style="{ color: fontColor }">{{ label }}</span>
  </v-chip>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import {
  NEUTRAL_100,
  NEUTRAL_700,
  OCHRE_50,
  OCHRE_800,
  TEAL_50,
  TEAL_700,
} from '@/constants/colors'
import { GroupHelper } from '@/model/control/group/helper'
import { Params, Policy } from 'rfs/control/proto/policy_pb'

export default defineComponent({
  name: 'PolicyBadge',
  props: {
    currentPolicy: {
      type: Number as PropType<Policy>,
      required: true,
    },
    currentPolicyParams: {
      type: Object as PropType<Params>,
      required: false,
    },
  },
  computed: {
    label(): string {
      return GroupHelper.getPolicyLabel(
        this.currentPolicy,
        this.currentPolicyParams
      )
    },
    bgColor(): string {
      switch (this.currentPolicy) {
        case Policy.MANUAL_CONTROL_EVENT:
          return OCHRE_50.hex
        case Policy.DISPATCH_SCHEDULE_EVENT:
        case Policy.AUTOMATIC_CONTROL_EVENT:
          return TEAL_50.hex
        default:
          return NEUTRAL_100.hex
      }
    },
    fontColor(): string {
      switch (this.currentPolicy) {
        case Policy.MANUAL_CONTROL_EVENT:
          return OCHRE_800.hex
        case Policy.DISPATCH_SCHEDULE_EVENT:
        case Policy.AUTOMATIC_CONTROL_EVENT:
          return TEAL_700.hex
        default:
          return NEUTRAL_700.hex
      }
    },
  },
})
</script>
