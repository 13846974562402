import type { RouteRecordRaw } from 'vue-router'
import type { RittaConfig } from '@/config'
import { TabId } from '@/config/types'
import * as RouteNames from './routeNames'
import Home from '@/views/Home.vue'
import ResourceLeftPanel from '@/components/monitor/ResourceLeftPanel.vue'
import { ResourceType } from '@/constants/resourceType'
import { hasAnyResourceTabEnabled } from '@/model/resource'

export const TAB = TabId.MONITOR

export function resourceRoutes(
  config: Readonly<RittaConfig>,
  redirectRouteName: string
): RouteRecordRaw[] {
  // Resource type.
  return Object.values(ResourceType)
    .filter((rt) => hasAnyResourceTabEnabled(rt, config))
    .reduce((acc, rt) => {
      acc.push({
        path: `/${rt}`,
        component: Home,
        children: [
          {
            path: '',
            name: RouteNames.createAllResourcesRouteName(rt),
            redirect: () => {
              switch (rt) {
                case ResourceType.SUBSTATION:
                case ResourceType.FEEDER:
                  return { name: RouteNames.SUBSTATIONS }
                case ResourceType.TRANSFORMER:
                  return { name: RouteNames.TRANSFORMERS }
                case ResourceType.BREAKER:
                case ResourceType.FUSE:
                case ResourceType.RECLOSER:
                case ResourceType.REGULATOR:
                case ResourceType.SECTIONALIZER:
                case ResourceType.SWITCH:
                  return { name: RouteNames.GRID_IMPACT }
                default:
                  return { name: redirectRouteName }
              }
            },
          },
          {
            path: ':unqualifiedId/:tab?',
            name: RouteNames.createResourceDetailsRouteName(rt),
            meta: { tab: TAB, drawerLeftWide: true, resourceType: rt },
            components: { leftPanel: ResourceLeftPanel },
            props: { leftPanel: true },
          },
        ],
      })

      return acc
    }, [] as RouteRecordRaw[])
}
