import { Color } from '@deck.gl/core'
import { MapLayerId } from '@/config/types'
import { ResourceType } from '@/constants/resourceType'
import { Services } from '@/services'
import {
  COMMON_SIZE_DIVISOR,
  ICON_MAX_PIXELS,
  TILE_SIZE,
  ZOOM_METERS,
} from '@/model/map/constants'
import { MapManagerLayer } from '@/model/map/types'
import { ApplicationMapLayer } from './catalog'
import { CustomTileLayer, getTileResponseLayers } from './extensions'

import iconMeterEndpoints from '@/assets/imgs/albert_ic_meter--black.svg'

export const FILL_COLOR_DEFAULT = [0, 0, 0, 255] as Color
const FILL_COLOR_MOUSE_OVER = [99, 98, 98, 255]

export const POINT_RADIUS_DEFAULT = 6

function meterEndpointMapManagerLayer(
  id: MapLayerId,
  services: Services
): MapManagerLayer {
  return new CustomTileLayer({
    id,
    minZoom: ZOOM_METERS - 1,
    maxZoom: ZOOM_METERS - 1,
    tileSize: TILE_SIZE,
    pickable: true,
    autoHighlight: true,
    highlightColor: FILL_COLOR_MOUSE_OVER,
    // prettier-ignore
    getTileData: async (props) =>
      services.tileService.getTileResources(ResourceType.METER_ELECTRICAL, props),
    renderSubLayers(props) {
      return getTileResponseLayers(props, {
        circles: {
          radiusUnits: 'common',
          radiusMaxPixels: ICON_MAX_PIXELS / 2,
          getRadius: (POINT_RADIUS_DEFAULT * 0.8) / COMMON_SIZE_DIVISOR,
          getFillColor: FILL_COLOR_DEFAULT,
        },
      })
    },
  })
}

export function meterEndpoint(id: MapLayerId): ApplicationMapLayer {
  return {
    id,
    label: 'Meter Endpoint',
    icon: iconMeterEndpoints,
    mapManagerLayer: (_config, services) =>
      meterEndpointMapManagerLayer(id, services),
  }
}
