<template>
  <div style="position: relative; height: 64px">
    <div class="c-BaseCustomControl gmaps-box-shadow rounded-sm bg-white">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.c-BaseCustomControl {
  position: absolute;
  left: -50px;
  width: 40px;
  height: 40px;
}
</style>
