import type { ChartOptions } from 'chart.js'
import type { AnnotationOptions } from 'chartjs-plugin-annotation'
import { DateTime } from 'luxon'
import type { TimeMarker } from '@/types/charts'

/** Use this value in a time marker annotation to use observation time. */
export const NOW_MARKER: TimeMarker = { xValue: -1, label: 'Now' }

export function createTimeMarkerAnnotations(
  timeMarkers: TimeMarker[],
  now: DateTime
): ChartOptions {
  return {
    plugins: {
      annotation: {
        annotations: timeMarkers.reduce((acc, { xValue, label }, idx) => {
          acc[`timeMarker-${idx}`] = {
            type: 'line',
            scaleID: 'x',
            value: xValue === NOW_MARKER.xValue ? now.toMillis() : xValue,
            borderColor: 'black',
            borderWidth: 1,
            borderDash: [1, 1],
            label: {
              display: true,
              content: label,
              position: 'start',
              backgroundColor: 'white',
              color: '#383d3f',
              borderRadius: 2,
              borderColor: '#c2c2c2',
              borderWidth: 1,
              padding: { bottom: -1, right: 2, left: 2 },
              font: { weight: 'normal', size: 10 },
            },
          }

          return acc
        }, {} as { [key: string]: Partial<AnnotationOptions> }),
      },
    },
  }
}
