// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file frontend/proto/gcs.proto (package ritta.frontend.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Bucket not in request because GCS service account can only connect to
 * one bucket for now and RFS only configured to serve from one bucket
 *
 * @generated from message ritta.frontend.proto.GcsFileRequest
 */
export const GcsFileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GcsFileRequest",
  () => [
    { no: 1, name: "object", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.GcsFileResponse
 */
export const GcsFileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.GcsFileResponse",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.EnumObjectsRequest
 */
export const EnumObjectsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.EnumObjectsRequest",
  () => [
    { no: 1, name: "object_type", kind: "enum", T: proto3.getEnumType(StorageObject_Type) },
    { no: 2, name: "base_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message ritta.frontend.proto.EnumObjectsResponse
 */
export const EnumObjectsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.EnumObjectsResponse",
  () => [
    { no: 1, name: "objects", kind: "message", T: StorageObject, repeated: true },
  ],
);

/**
 * @generated from message ritta.frontend.proto.StorageObject
 */
export const StorageObject = /*@__PURE__*/ proto3.makeMessageType(
  "ritta.frontend.proto.StorageObject",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from enum ritta.frontend.proto.StorageObject.Type
 */
export const StorageObject_Type = /*@__PURE__*/ proto3.makeEnum(
  "ritta.frontend.proto.StorageObject.Type",
  [
    {no: 0, name: "ALL"},
    {no: 1, name: "IMAGE"},
    {no: 2, name: "MAP_LAYER"},
    {no: 3, name: "DATA_TABLE"},
  ],
);

