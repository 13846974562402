export function restrictToPositiveIntegersInput(evt: KeyboardEvent): void {
  // Case 01
  // Prevent when:
  // 1) "key" is not any special type of key AND
  // 2) "key" is a not a digit AND
  // 3) is not a meta key (e.g. CMD + v).
  if (evt.key.length === 1 && !evt.key.match(/^\d$/) && !evt.metaKey) {
    evt.preventDefault()
  }

  const currentValue = (evt.target as undefined | HTMLInputElement)?.value || ''

  // Case 02
  // Prevent when:
  // 1) The user pressed the arrow down key AND
  // 2) the current value is already the minimum allowed which is 0.
  if (
    evt.key === 'ArrowDown' &&
    (!currentValue || parseFloat(currentValue) === 0)
  ) {
    evt.preventDefault()
  }
}

export function restrictToIntegersInput(evt: KeyboardEvent): void {
  // Case 01
  // Prevent when:
  // 1) "key" is not any special type of key AND
  // 2) "key" is a not "-" AND
  // 3) "key" is not a digit AND
  // 4) is not a meta key (e.g. CMD + v).
  if (
    evt.key.length === 1 &&
    evt.key !== '-' &&
    !evt.key.match(/^\d$/) &&
    !evt.metaKey
  ) {
    evt.preventDefault()
  }
}

export function restrictToFloatInput(evt: KeyboardEvent): void {
  // Case 01
  // Prevent when:
  // 1) "key" is not any special type of key AND
  // 2) "key" is a not "-" AND
  // 3) "key" is a not a digit AND
  // 4) "key" is not a point (.)
  // 5) is not a meta key (e.g. CMD + v).
  if (
    evt.key.length === 1 &&
    evt.key !== '-' &&
    !evt.key.match(/^(\d|\.)$/) &&
    !evt.metaKey
  ) {
    evt.preventDefault()
  }

  const currentValue = (evt.target as undefined | HTMLInputElement)?.value || ''

  // Case 02
  // Prevent when there's already a point in the current value.
  if (evt.key === '.' && currentValue.includes('.')) {
    evt.preventDefault()
  }

  // Case 03
  // Prevent when there's already a "-" in the current value.
  if (evt.key === '-' && currentValue.includes('-')) {
    evt.preventDefault()
  }

  // Case 04
  // Prevents double dash "--" at the beginning of the value.
  if (currentValue.length === 0 && evt.key === '-') {
    ;(evt.target as HTMLInputElement).value = '-'
  }
}
