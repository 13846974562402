<template>
  <linear-chart
    :chart-def="chartDef"
    :chart-series="loadDurationSeries"
    :chart-formatters="chartFormatters"
    :chart-options="loadDurationOptions"
  >
    <!-- Custom slots -->
    <template v-for="key of Object.keys($slots)" v-slot:[`${key}`]>
      <slot :name="key"></slot>
    </template>
  </linear-chart>
</template>

<script lang="ts">
import { ChartOptions } from 'chart.js'
import { Interval } from 'luxon'
import { defineComponent, PropType } from 'vue'
import { NamedTimeSeries } from '@/model/charts'
import { computeDurationSeries } from '@/model/charts/loadDuration'
import { ChartDefinition, ChartFormatters } from '@/types/charts'
import LinearChart from './LinearChart.vue'

export default defineComponent({
  name: 'LoadDurationChart',
  components: { LinearChart },
  props: {
    chartDef: {
      type: Object as PropType<ChartDefinition>,
      required: true,
    },
    chartSeries: {
      type: Array as PropType<Readonly<NamedTimeSeries[]>>,
      required: true,
    },
    chartFormatters: {
      type: Object as PropType<ChartFormatters>,
      required: true,
    },
    chartOptions: {
      type: Array as PropType<ChartOptions[]>,
      required: true,
    },
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
  },
  computed: {
    loadDurationSeries(): NamedTimeSeries[] {
      // The prop contains the apparent power series from the data source
      return this.chartSeries.map((series) =>
        computeDurationSeries(series, this.interval)
      )
    },
    loadDurationOptions(): ChartOptions[] {
      // Load duration is a range from 0%-100%
      return this.chartOptions.concat({ scales: { x: { min: 0, max: 1.0 } } })
    },
  },
})
</script>
