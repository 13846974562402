<template>
  <div class="c-HourlyDailyPeakTable pa-4 bg-grey-lighten-4 align-self-start">
    <!-- Table Title -->
    <div class="text-subtitle-2 text-no-wrap pb-4">{{ tableTitle }}</div>

    <!-- Hourly Peak Table -->
    <table width="100%" class="text-body-2">
      <thead class="font-weight-bold text-caption">
        <tr>
          <th class="cell text-left">{{ titlePeak }}</th>
          <th class="text-left">{{ titleDate }}</th>
          <th class="text-right text-no-wrap">{{ titleYTDMax }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in peaksData" :key="item.peak">
          <td class="cell text-left">{{ item.peak }}</td>
          <td class="text-left text-no-wrap">
            {{
              item.peak === 'Hourly'
                ? toDateTimeString(item.date)
                : toDateString(item.date)
            }}
          </td>
          <td class="text-right pl-2">{{ formatPercent(item.percentage) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { DateTime } from 'luxon'
import { PeaksData } from './AnalyzeChart'
import { Format } from '@/utils/format'
import { TEXT_NO_VALUE } from '@/constants/formatText'

export default defineComponent({
  name: 'HourlyDailyPeakTable',
  props: {
    peaksData: {
      type: Array as PropType<PeaksData[]>,
      required: true,
    },
  },
  methods: {
    toDateTimeString(millis?: number): string {
      return millis
        ? DateTime.fromMillis(millis).toFormat('yyyy-MM-dd HH:mm')
        : TEXT_NO_VALUE
    },
    toDateString(millis?: number): string {
      return millis
        ? DateTime.fromMillis(millis).toFormat('yyyy-MM-dd')
        : TEXT_NO_VALUE
    },
    formatPercent(value?: number) {
      return value ? Format.fmtPercent(value) : TEXT_NO_VALUE
    },
  },
  setup() {
    const tableTitle = 'Carbon-Free Electricity (local only)'
    const titlePeak = 'Peak'
    const titleDate = 'Date'
    const titleYTDMax = 'YTD max'

    return {
      tableTitle,
      titlePeak,
      titleDate,
      titleYTDMax,
    }
  },
})
</script>
<style lang="scss">
.c-HourlyDailyPeakTable {
  table {
    width: 270px;
    border-collapse: collapse;
    thead {
      color: #6c6c6c;
    }
    tr {
      line-height: 24px;
      border-bottom: 1px solid #dddddd;
      .cell {
        width: 108px;
      }
    }
  }
}
</style>
