<template>
  <div
    class="d-flex flex-column"
    :class="{ 'flex-1-0': toggleView === ToggleView.MAP }"
  >
    <!-- Table -->
    <ce-data-table
      :headers="tableHeaders"
      :table="tableData"
      :filters
      @new-filters="newFilters"
      @reset-filters="resetFilters"
      :is-loading="!isInitialTableState && isLoading"
      :bg-color="bgColor"
      sticky
      dense
      class="dense-actions"
      :class="{ 'hide-table': toggleView === ToggleView.MAP }"
      data-testid="feeder-operations-table"
    >
      <template v-slot:left-of-actions>
        <div class="d-flex align-center">
          <div
            class="d-flex align-center pa-1"
            :style="{
              border: '1px solid #d4d4d4',
              borderRadius: '4px',
            }"
          >
            <!-- Play time series button -->
            <button-segmented
              :disabled="noTSData"
              :icon="playPauseIcon"
              data-testid="play-button"
              @click="togglePlayPause"
              class="mr-4"
              style="height: 32px"
            />
            <!-- Time series slider -->
            <ce-slider
              style="width: 399px"
              :disabled="noTSData"
              :min="0"
              :max="maxTimeSlider"
              :step="1"
              :appendText
              v-model="timeSlider"
              prepend-text="Now"
            />
            <span
              :style="noTSData ? { opacity: 'var(--v-disabled-opacity)' } : {}"
              class="ml-4 mr-6 text-body-2"
            >
              {{ timeSeriesCurrentDateTime.toFormat('yyyy-MM-dd HH:mm') }}
            </span>
          </div>
          <div class="flex-1-0 d-flex justify-end">
            <!-- Full panel toggle -->
            <button-segmented
              :icon="
                resourceOperationsFullPanel
                  ? 'mdi-arrow-collapse-vertical'
                  : 'mdi-arrow-expand-vertical'
              "
              @click="toggleResourceOperationsFullPanel"
              class="mr-2"
            />
            <!-- Map / Table toggle -->
            <v-btn-toggle density="comfortable" v-model="toggleView" mandatory>
              <button-segmented
                icon="mdi-map-outline"
                :aria-label="ToggleView.MAP"
                :value="ToggleView.MAP"
              />
              <button-segmented
                icon="mdi-table-large"
                :aria-label="ToggleView.TABLE"
                :value="ToggleView.TABLE"
              />
            </v-btn-toggle>
          </div>
        </div>
      </template>
    </ce-data-table>
    <!-- Loading -->
    <centered-spinner
      v-if="isLoading && toggleView === ToggleView.MAP"
      :size="20"
    />
    <!-- GMap -->
    <div
      class="d-flex flex-1-0 flex-row-reverse"
      :style="{
        display: toggleView === ToggleView.MAP ? '' : 'none !important',
      }"
    >
      <!-- Legend & Metric Toggle -->
      <div style="z-index: 1; position: absolute" class="d-flex my-4 mr-4">
        <!-- Legend -->
        <color-gradient-legend
          v-if="!noTSData && activeMetric === ToggleMetric.LOADING"
          type="gradient"
          :colors="gradient"
          min-label="0%"
          max-label="140%"
        />
        <color-gradient-legend
          v-if="!noTSData && activeMetric === ToggleMetric.VOLTAGE"
          type="discrete"
          title="Voltage (p.u.):"
          :colors="[VIOLET_500, ORANGE_CAMUS_ENERGY]"
          :labels="['0.95 or less', '1.05 or more']"
        />
        <!-- Metric Toggle -->
        <v-btn-toggle v-model="activeMetric" :disabled="noTSData" mandatory>
          <button-segmented :value="ToggleMetric.VOLTAGE" label="Voltage" />
          <button-segmented :value="ToggleMetric.LOADING" label="Loading" />
        </v-btn-toggle>
      </div>
      <!-- Map -->
      <div
        class="flex-1-0"
        data-testid="google-maps-feeder-operations"
        :ref="REF_GMAPS_OPERATIONS_HTML_EL"
        style="border: 1px solid #d4d4d4"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, shallowReactive } from 'vue'
import { groupBy, isEqual } from 'lodash-es'
import { DateTime, Interval } from 'luxon'
import { mapActions, mapState } from 'pinia'
import { Resource } from 'rfs/pb/resource_pb'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeSlider from '@/components/others/CeSlider.vue'
import ButtonSegmented from '@/components/common/ButtonSegmented.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import {
  BLUE_100,
  BLUE_50,
  GRAY_COOL_200,
  GREY1,
  GREY6,
  ORANGE_CAMUS_ENERGY,
  VIOLET_500,
} from '@/constants/colors'
import { ResourceType } from '@/constants/resourceType'
import { OperationsMapManager } from './OperationsMapManager'
import { ResourcesTimeSeries } from './FeederOperationsMapCatalog'
import {
  MetricTimeResourceData,
  getInfoColumnProps,
  createOperationsMap,
  currentMetrics,
  reduceTimeSeriesToObject,
  voltageMetrics,
} from './OperationsMapUtils'
import ColorGradientLegend from '@/components/others/ColorGradientLegend.vue'
import {
  Columns,
  FeederOperationsDataTable,
  createFeederOperationsDataTable,
  createInitialFilters,
  feederOperationsHeaders,
  updateSelectFilters,
} from './FeederDataTable'
import { getUnqualifiedId, isMidlineDevice } from '@/model/resource'
import { ICON_MAX_PIXELS } from '@/model/map'
import { FilterMultiSelect, Filters } from '@/model/tables/DataTable'
import { TimeSeries } from 'rfs/frontend/proto/tsdb_pb'
import { TimeSeriesMetric } from '@/services/charts.service'
import { Resolution } from 'rfs/frontend/proto/resolution_pb'
import { Metric } from '@/constants/metrics'
import { ComponentInstance } from '../maps/CeGoogleMaps.vue'
import InfoWindow from '@/components/maps/InfoWindow.vue'
import {
  CloseInfoWindow,
  HoverInfo,
  LatLng,
  OpenInfoWindow,
} from '@/model/map/types'
import { customMount } from '@/utils/vue'
import { usePreferencesStore } from '@/stores/preferences'
import { useGlobalSnackbar } from '@/stores/globalSnackbar'

export enum ToggleView {
  MAP = 'MAP',
  TABLE = 'TABLE',
}

export enum ToggleMetric {
  VOLTAGE = 'VOLTAGE',
  LOADING = 'LOADING',
}

export default defineComponent({
  name: 'FeederOperations',
  props: {
    substation: {
      type: Resource,
      required: true,
    },
    feeder: {
      type: Resource,
      required: true,
    },
  },
  components: {
    ButtonSegmented,
    CenteredSpinner,
    CeSlider,
    CeDataTable,
    ColorGradientLegend,
  },
  setup() {
    return {
      BLUE_50,
      BLUE_100,
      bgColor: GREY6.rgb,
      GREY1,
      GRAY_COOL_200,
      ORANGE_CAMUS_ENERGY: ORANGE_CAMUS_ENERGY.hex,
      VIOLET_500: VIOLET_500.hex,
      // Gradient color palette
      // https://seaborn.pydata.org/tutorial/color_palettes.html#perceptually-uniform-palettes
      gradient: [
        '#4b2362',
        '#5b2867',
        '#6c2b6d',
        '#7e2f70',
        '#8f3371',
        '#a0376f',
        '#b13c6c',
        '#c24168',
        '#d14a61',
        '#dc575c',
        '#e3685c',
        '#e77a62',
        '#e98c6b',
        '#eb9e76',
        '#edb081',
      ],
      ToggleMetric,
      ToggleView,
      getUnqualifiedId,
      tableHeaders: feederOperationsHeaders,
      Metric,
      voltageMetrics,
      currentMetrics,
      // It's used to create Vue component programatically so they can have
      // access to all app's plugins.
      appContext: getCurrentInstance()?.appContext,
      initialLoadingFilters: createInitialFilters([ResourceType.CONDUCTOR]),
      initialVoltageFilters: createInitialFilters([
        ResourceType.CONDUCTOR,
        ResourceType.TRANSFORMER,
      ]),
    }
  },
  data() {
    const feederOpsDate = this.$rittaConfig?.featureFlags?.feederOperationsDate
    return shallowReactive({
      now: feederOpsDate
        ? DateTime.fromISO(feederOpsDate).startOf('hour')
        : DateTime.now().startOf('hour'),
      REF_GMAPS_OPERATIONS_HTML_EL: 'REF_GMAPS_OPERATIONS_HTML_EL',
      mapManager: null as null | OperationsMapManager,
      gmap: {} as google.maps.Map,
      infoWindow: {
        currentResource: null as null | Resource,
        currentComponent: null as null | ComponentInstance,
      },
      toggleView: ToggleView.MAP,
      timeSlider: 0,
      isLoading: false,
      isInitialTableState: true,
      isInitialFilters: true,
      filters: createInitialFilters([
        ResourceType.CONDUCTOR,
        ResourceType.TRANSFORMER,
      ]),
      resources: [] as Resource[],
      timeSeriesData: [] as TimeSeries[],
      activeMetric: ToggleMetric.VOLTAGE,
      isPlaying: false,
      playPauseIcon: 'mdi-play',
      timeoutId: undefined as NodeJS.Timeout | undefined, // Store the timeout ID
    })
  },
  watch: {
    feederId: {
      handler: function (): void {
        this.initMap()
      },
    },
    resourcesData: {
      handler: function (): void {
        this.mapManager?.setLayers(this.resourcesData)
      },
    },
    timeSlider: {
      immediate: true,
      handler: function (): void {
        this.mapManager?.setLayers(this.resourcesData)
      },
    },
    activeMetric(curr: ToggleMetric): void {
      // If the metric is Loading and it's the first time toggling, set the initial filters
      if (this.isInitialFilters) {
        const filters =
          curr === ToggleMetric.LOADING
            ? this.initialLoadingFilters
            : this.initialVoltageFilters
        this.filters = updateSelectFilters(filters, this.resources)
      }
    },
  },
  computed: {
    ...mapState(usePreferencesStore, ['resourceOperationsFullPanel']),
    tableData(): FeederOperationsDataTable {
      const rows = createFeederOperationsDataTable(
        this.filteredResources,
        this.resourcesData
      )
      return {
        rows,
        noDataText: this.isInitialTableState
          ? 'Loading feeder downline resources...'
          : 'No results',
      }
    },
    filteredResources(): Resource[] {
      // If no filters are selected, return all resources
      if (this.filters.size === 0) return this.resources
      const typeFilter = this.filters.get(Columns.TYPE) as FilterMultiSelect
      // If no type filter is selected, return all resources
      if (!typeFilter.value.length) return this.resources
      return this.resources.filter((r) => typeFilter.value.includes(r.type))
    },
    resourcesData(): ResourcesTimeSeries {
      return {
        resources: this.filteredResources,
        metric: this.activeMetric,
        timeStampMillis: this.timeSeriesCurrentDateTime.toMillis(),
        tsValues: this.metricTimeResourceData,
      }
    },
    timeSeriesCurrentDateTime(): DateTime {
      return this.now.plus({ hours: this.timeSlider })
    },
    noTSData(): boolean {
      return Object.keys(this.metricTimeResourceData).length === 0
    },
    metricTimeResourceData(): MetricTimeResourceData {
      return this.timeSeriesData.reduce(reduceTimeSeriesToObject, {})
    },
    maxTimeSlider(): number {
      return this.timeSeriesData.reduce(
        (acc, ts) => Math.max(acc, ts.data.length - 1),
        0
      )
    },
    appendText(): string {
      return `+${this.maxTimeSlider} hrs`
    },
  },
  methods: {
    ...mapActions(usePreferencesStore, ['toggleResourceOperationsFullPanel']),
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.resources = []

      try {
        const resources =
          await this.$services.queryService.getResourcesByUpline({
            feeder: getUnqualifiedId(this.feeder.id),
          })

        // Add upline substation to resources
        this.resources = [...resources, this.substation]
        // Update filters with new resources
        this.filters = updateSelectFilters(this.filters, this.resources)

        // Load real time series data
        await this.fetchTimeSeries()
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
        this.isInitialTableState = false
      }
    },
    async fetchTimeSeries(): Promise<void> {
      const interval = Interval.fromDateTimes(
        this.now.minus({ hour: 1 }),
        this.now.plus({ day: 1 })
      )
      const metrics = [...this.voltageMetrics, ...this.currentMetrics].map(
        (metric) => {
          // Add unit for current metrics
          const unit: TimeSeriesMetric['unit'] = currentMetrics.includes(metric)
            ? 'Apu'
            : undefined
          return { metric, unit }
        }
      )

      // Only midline devices have time series data for these metrics
      const responses =
        this.$services.chartsService.fetchMultiTimeSeriesInChunks(
          this.resources.filter(hasTimeSeries).map((r) => r.id),
          { interval, metrics, resolution: Resolution.ONE_HOUR }
        )

      try {
        for await (const response of responses) {
          this.timeSeriesData = this.timeSeriesData.concat(response.series)
        }
      } catch (err: any) {
        useGlobalSnackbar().openSnackbar(
          `Some voltage & current data could not be downloaded`,
          'error'
        )
        console.error('FeederOperations.fetchTimeSeries: %o', err)
      }
    },
    async initMap(): Promise<void> {
      if (this.resources.length === 0) await this.fetchData()
      try {
        const mapEl = this.$refs[this.REF_GMAPS_OPERATIONS_HTML_EL]

        if (!mapEl) throw new Error('HTML element is missing')

        const gmap = await createOperationsMap(
          this.$rittaConfig,
          mapEl as HTMLElement
        )

        this.mapManager = new OperationsMapManager(gmap, {
          // Move the InfoWindow up near the top of the icon
          infoWindowOffset: { width: 0, height: -ICON_MAX_PIXELS / 2 },
        })

        this.mapManager.setUpInfoWindow(this.manageInfoWindow)

        this.mapManager.setLayers(this.resourcesData)

        const conductorBounds = groupBy(this.resources, (r) => r.type)[
          ResourceType.CONDUCTOR
        ]
        this.mapManager.zoomToResourcesBounds(conductorBounds)
      } catch (err) {
        console.error('FeederOperations.initMap: %o', err)
      }
    },
    newFilters(filters: Filters<Columns>): void {
      if (this.isInitialFilters) this.isInitialFilters = false
      this.filters = updateSelectFilters(filters, this.resources)
    },
    resetFilters(): void {
      // if the table view is active, reset to no filters
      // otherwise if the map is active,
      // reset to the initial filters based on the active metric
      if (this.toggleView === ToggleView.TABLE) {
        const emptyFilters = createInitialFilters()
        this.filters = updateSelectFilters(emptyFilters, this.resources)
      } else {
        this.isInitialFilters = true
        // Reset to initial filters based on the active metric
        const filters =
          this.activeMetric === ToggleMetric.VOLTAGE
            ? this.initialVoltageFilters
            : this.initialLoadingFilters

        this.filters = updateSelectFilters(filters, this.resources)
      }
    },
    togglePlayPause(): void {
      if (this.isPlaying) {
        // Pause
        clearTimeout(this.timeoutId)
        this.playPauseIcon = 'mdi-play'
        this.isPlaying = false
      } else {
        // Play
        this.playTimeSeries()
      }
    },
    playTimeSeries(): void {
      if (!this.isPlaying) {
        // Start or resume playing without resetting timeSlider
        this.isPlaying = true
        this.playPauseIcon = 'mdi-pause'
      } else if (this.timeSlider < this.maxTimeSlider) {
        // Increment timeSlider and continue playing
        this.timeSlider += 1
      } else if (this.timeSlider === this.maxTimeSlider) {
        this.timeSlider = 0 // Loop back to the beginning
      } else {
        // Reached the end, stop playing
        this.playPauseIcon = 'mdi-play'
        this.isPlaying = false
        return // Stop the recursion
      }
      // Set the next timeout for 500ms if still playing
      if (this.isPlaying) {
        this.timeoutId = setTimeout(() => {
          this.playTimeSeries()
        }, 800)
      }
    },
    manageInfoWindow(
      info: HoverInfo,
      open: OpenInfoWindow,
      close: CloseInfoWindow
    ): void {
      if (info.layer == null) {
        close()
        this.destroyInfoWindowComponent()
        return // No layer, no info!
      }
      const resource = info.object as Resource | undefined

      if (!resource) {
        close()
        this.destroyInfoWindowComponent()
        return
      }

      // TODO(Isaac): Uncomment when current rating is available in GIS
      // const hasCurrentRating = resource.ratings?.current !== undefined

      // If the metric is Loading and the resource doesn't have a current ratting,
      // don't show the info window
      const hasVoltageRating = resource.ratings?.voltage !== undefined
      if (this.activeMetric === ToggleMetric.VOLTAGE && !hasVoltageRating) {
        close()
        return
      }

      if (isEqual(resource, this.infoWindow.currentResource)) {
        return // Same resource, keep the same instance.
      }

      // Changed layer and/or object - close the current InfoWindow.
      close()
      this.destroyInfoWindowComponent()

      this.infoWindow.currentResource = resource

      let point = resource?.location?.point as LatLng | undefined

      if (!point && info.coordinate) {
        point = { lat: info.coordinate[1], lng: info.coordinate[0] }
      }

      if (point) {
        const component = customMount(InfoWindow, {
          appContext: this.appContext,
          props: {
            // TODO(Isaac): make this dynamically update the metric and phase's values when the slider changes
            infoColumnProps: getInfoColumnProps(resource, this.resourcesData),
          },
        })

        open(component.$el, point)

        this.infoWindow.currentComponent = component
      }
    },
    destroyInfoWindowComponent(): void {
      if (this.infoWindow.currentComponent) {
        this.infoWindow.currentComponent.$destroy()
        this.infoWindow.currentComponent = null
      }
      if (this.infoWindow.currentResource) {
        this.infoWindow.currentResource = null
      }
    },
  },
  mounted() {
    this.initMap()
  },
})

function hasTimeSeries(r: Resource): boolean {
  switch (r.type) {
    case ResourceType.CONDUCTOR:
    case ResourceType.TRANSFORMER:
      return true
    default:
      return isMidlineDevice(r)
  }
}
</script>
<style lang="scss">
// Hide table and page navigation when map view is active
.hide-table {
  .v-table__wrapper,
  .v-data-table-footer,
  .v-divider {
    display: none;
  }
}

.dense-actions {
  [aria-label='Search & Actions'] {
    padding-top: 0px !important;
  }
}
</style>
