import { Timestamp } from '@/services/timestamp_pb'
import { DateTime, Interval } from 'luxon'

import {
  Transformer,
  TransformerSeriesRequestV2,
  TransformerSeriesResponseV2,
} from 'rfs/frontend/proto/transformer_details_pb'
import {
  TimeSeriesResponse,
  TimeSeries as TsdbTimeSeries,
} from 'rfs/frontend/proto/tsdb_pb'
import { BLACK, JAFFA, PACIFIC, VELVET, WALLABY } from '@/constants/colors'
import { TEXT_NO_VALUE } from '@/constants/formatText'
import {
  transformerLoadCharts,
  apparentPowerDurationChart,
} from '@/constants/transformerLoadDetails'
import { Services } from '@/services'
import { Format } from '@/utils/format'
import { limitInterval } from '@/utils/time'
import { getUnqualifiedId } from '@/model/resource'
import {
  FixedTimeDataSource,
  ITimeSeriesDataSource,
  TimeSeriesMap,
} from '../charts'
import { Metric } from '@/constants/metrics'

type ChartsService = Services['chartsService']
type TransformerDetails = Services['transformerDetailsService']

interface Utilization {
  min: string
  max: string
  mean: string
}
/** Load, Power, Voltage */
export type Utilizations = [Utilization, Utilization, Utilization]

export type MeterStats = TransformerSeriesResponseV2['meterStats']

const [LoadChart, VoltageChart, MetersChart] = transformerLoadCharts

export class TransformerSeriesDataSource implements ITimeSeriesDataSource {
  /** The transformer details UI does not use infinite scrolling */
  private readonly dataSource: FixedTimeDataSource
  /** The response from the server has additional properties we need */
  private response: TransformerSeriesResponseV2 | undefined

  constructor(
    now: DateTime,
    service: TransformerDetails,
    transformer: Transformer,
    interval: Interval
  ) {
    this.dataSource = new FixedTimeDataSource(
      limitInterval(interval, now),
      async (request) => {
        const r = new TransformerSeriesRequestV2({
          id: transformer.id,
          start: Timestamp.fromDateTime(request.interval.start),
          end: Timestamp.fromDateTime(request.interval.end),
          metrics: request.metrics,
        })
        this.response = await service.transformerSeriesV2(r)

        return new TimeSeriesResponse({
          resource: this.response.transformer,
          series: [
            ...this.response.series,
            new TsdbTimeSeries({
              metric: Metric.COND_POWER_APPARENT,
              data: this.response.apparentPowerDuration.map((dp) => {
                return { x: dp.percentage, y: dp.value }
              }),
            }),
          ],
        })
      }
    )

    this.dataSource.addChartSeries(LoadChart, {
      metric: Metric.TRANSFORMER_LOAD_PERCENT,
      config: { seriesName: 'Load %', seriesColor: PACIFIC.hex },
    })
    this.dataSource.addChartSeries(LoadChart, {
      metric: Metric.COND_POWER_APPARENT,
      config: { seriesName: 'Apparent Power', seriesColor: WALLABY.hex },
    })
    this.dataSource.addChartSeries(VoltageChart, {
      metric: 'min(conditions.voltage)',
      unit: 'Vpu',
      config: { seriesName: 'Voltage min (p.u.)', seriesColor: VELVET.hex },
    })
    this.dataSource.addChartSeries(VoltageChart, {
      metric: 'max(conditions.voltage)',
      unit: 'Vpu',
      config: { seriesName: 'Voltage max (p.u.)', seriesColor: JAFFA.hex },
    })
    this.dataSource.addChartSeries(VoltageChart, {
      metric: 'avg(conditions.voltage)',
      unit: 'Vpu',
      config: { seriesName: 'Voltage mean (p.u.)', seriesColor: BLACK.hex },
    })
    this.dataSource.addChartSeries(apparentPowerDurationChart, {
      metric: Metric.COND_POWER_APPARENT,
      config: { seriesName: 'Apparent Power', seriesColor: PACIFIC.hex },
    })
  }

  /** The total load factor percent for the utilization table */
  get loadFactorPercent() {
    return this.response?.loadFactorPercent
  }

  /** This returns power statistics for each meter */
  get meterStats(): MeterStats {
    return this.response?.meterStats ?? {}
  }

  fetchTimeSeries(visibleInterval: Interval): Promise<TimeSeriesMap> {
    return this.dataSource.fetchTimeSeries(visibleInterval)
  }

  getTimeSeriesMap(): TimeSeriesMap {
    return this.dataSource.getTimeSeriesMap()
  }

  static utilizationData(seriesMap: TimeSeriesMap): Utilizations {
    const loadAndPower = seriesMap.get(LoadChart.id)
    const loadPercent = loadAndPower?.find(
      (s) => s.metric === Metric.TRANSFORMER_LOAD_PERCENT
    )
    const power = loadAndPower?.find(
      (s) => s.metric === Metric.COND_POWER_APPARENT
    )
    const voltages = seriesMap.get(VoltageChart.id)
    const voltageMin = voltages?.find(
      (s) => s.metric === 'min(conditions.voltage)'
    )
    const voltageMax = voltages?.find(
      (s) => s.metric === 'max(conditions.voltage)'
    )
    const voltageMean = voltages?.find(
      (s) => s.metric === 'avg(conditions.voltage)'
    )

    const { fmtApparentPower, fmtPercent, fmtVoltsPerUnit } = Format

    return [
      {
        min: loadPercent ? fmtPercent(loadPercent.minY) : TEXT_NO_VALUE,
        max: loadPercent ? fmtPercent(loadPercent.maxY) : TEXT_NO_VALUE,
        mean: loadPercent ? fmtPercent(loadPercent.meanY) : TEXT_NO_VALUE,
      },
      {
        min: power ? fmtApparentPower(power.minY) : TEXT_NO_VALUE,
        max: power ? fmtApparentPower(power.maxY) : TEXT_NO_VALUE,
        mean: power ? fmtApparentPower(power.meanY) : TEXT_NO_VALUE,
      },
      {
        min: voltageMin ? fmtVoltsPerUnit(voltageMin.minY) : TEXT_NO_VALUE,
        max: voltageMax ? fmtVoltsPerUnit(voltageMax.maxY) : TEXT_NO_VALUE,
        mean: voltageMean ? fmtVoltsPerUnit(voltageMean.meanY) : TEXT_NO_VALUE,
      },
    ]
  }
}

export function newMeterDataSource(
  now: DateTime,
  chartsService: ChartsService,
  meterId: string,
  interval: Interval
) {
  const dataSource = new FixedTimeDataSource(
    limitInterval(interval, now),
    (request) => {
      return chartsService.fetchTimeSeries(meterId, request)
    }
  )

  dataSource.addChartSeries(MetersChart, {
    metric: Metric.POWER_CONSUMED_NET,
    config: { seriesName: getUnqualifiedId(meterId), seriesColor: '' },
  })

  return dataSource
}
