import { CILANTRO } from '@/constants/colors'
import { Services } from '@/services'
import { ITimeSeriesDataSource, TimeSeriesDataSource } from '@/model/charts'
import { ChartType, ChartDefinition } from '@/types/charts'

const powerFlowChartDefinition: ChartDefinition = {
  id: 'power-chart',
  title: 'Power Flow',
  type: ChartType.Power,
  isAreaChart: true,
}

export const windChartDefinitions: ChartDefinition[] = [
  powerFlowChartDefinition,
]

export function newWindTimeSeriesDataSource(
  services: Services,
  windId: string
): ITimeSeriesDataSource {
  const ds = new TimeSeriesDataSource((request) =>
    services.chartsService.fetchTimeSeries(windId, { ...request })
  )

  // Power flow.
  ds.addChartSeries(powerFlowChartDefinition, {
    metric: 'power.produced',
    unit: 'W',
    config: { seriesName: 'Power Flow', seriesColor: CILANTRO.hex },
  })

  return ds
}
