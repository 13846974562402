<template>
  <div class="position-relative min-height-100">
    <!-- Loading -->
    <centered-spinner v-if="isLoading" />

    <!-- Table -->
    <ce-data-table v-else :headers :table dense :show-pagination="false">
      <!-- Custom "Envelope Status" cell -->
      <template v-slot:[`item.envelopeStatus`]="{ item: device }">
        <operating-envelope-badge
          :status="getDeviceCurrentEnvelopeStatus(device)"
        />
      </template>
    </ce-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowReactive, type PropType } from 'vue'
import { ResourceType } from '@/constants/resourceType'
import * as RouteNames from '@/router/routeNames'
import { getDeviceCurrentEnvelopeStatus } from '@/model/control/operatingEnvelope'
import type { Header, DataTable } from '@/model/tables/DataTable'
import CenteredSpinner from '@/components/CenteredSpinner.vue'
import CeDataTable from '@/components/common/CeDataTable.vue'
import OperatingEnvelopeBadge from '@/components/control/OperatingEnvelopeBadge.vue'
import type { Device } from 'rfs/control/proto/model_pb'
import { getResourceType, getUnqualifiedId } from '@/model/resource'
import type { Notification } from 'rfs/frontend/proto/notification_pb'

export interface WarningDetailsDataTable extends DataTable<Device> {}

const headers: Header<Device>[] = [
  {
    title: 'EVSEs',
    key: 'evse',
    value: (device) => device.displayName,
    routeFactory: (_config, device) => ({
      name: RouteNames.CONTROL_DEVICE,
      params: { groupId: device.groupId, deviceId: device.id },
    }),
  },
  { title: 'Envelope Status', key: 'envelopeStatus' },
]

export default defineComponent({
  name: 'NotificationDers',
  props: {
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  components: { CenteredSpinner, CeDataTable, OperatingEnvelopeBadge },
  setup() {
    return { headers, getDeviceCurrentEnvelopeStatus }
  },
  data() {
    return shallowReactive({
      isLoading: false,
      devices: [] as Device[],
    })
  },
  computed: {
    table(): WarningDetailsDataTable {
      return { rows: this.devices, noDataText: 'No devices found.' }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData(): Promise<void> {
      this.isLoading = true
      this.devices = []

      try {
        if (
          !this.notification.resourceId ||
          getResourceType(this.notification.resourceId) !==
            ResourceType.TRANSFORMER
        ) {
          throw new Error('Resource of type "transformer" was expected')
        }

        const transformerId = this.notification.resourceId

        const downlineChargers =
          await this.$services.queryService.getResourcesByUpline(
            { transformer: getUnqualifiedId(transformerId) },
            [ResourceType.CHARGER]
          )

        const results = await Promise.all(
          downlineChargers.map((charger) => this.fetchDevice(charger.id))
        )

        this.devices = results.reduce((acc, res) => {
          if (res !== null) acc.push(res)
          return acc
        }, [] as Device[])
      } catch (err) {
        console.error('NotificationDers.fetchData: %o', err)
      } finally {
        this.isLoading = false
      }
    },
    async fetchDevice(id: string): Promise<null | Device> {
      try {
        const device = await this.$services.control.getDevice({ id })
        return device
      } catch (err) {
        console.error(
          `NotificationDers.fetchDevice: problems finding device with id "${id}"`,
          err
        )
        return null
      }
    },
  },
})
</script>
