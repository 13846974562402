import { TEXT_NO_VALUE } from '@/constants/formatText'
import {
  getDisplayName,
  getPowerRating,
  getPowerRatingDC,
} from '@/model/resource'
import { Format } from '@/utils/format'
import { Resource } from 'rfs/pb/resource_pb'
import { InfoColumnAllProps } from '@/model/types'

export const SolarFarmHelper = {
  formattedRatedOutput(r: Resource): string {
    return Format.fmtWatts(getPowerRating(r)) || TEXT_NO_VALUE
  },

  infoWindowInfoColumn(r: Resource): InfoColumnAllProps {
    const meterNumber = r.meter?.electrical

    const items = meterNumber
      ? [{ label: 'Meter number', text: meterNumber }]
      : []

    items.push(
      {
        label: 'Capacity (AC)',
        text: Format.fmtWatts(getPowerRating(r)) || TEXT_NO_VALUE,
      },
      {
        label: 'Capacity (DC)',
        text: Format.fmtWatts(getPowerRatingDC(r)) || TEXT_NO_VALUE,
      }
    )

    return { title: getDisplayName(r), items }
  },

  sortUtilitySolars(utilitySolars: Resource[]): Resource[] {
    return utilitySolars.sort((a, b) => {
      return getDisplayName(a).localeCompare(getDisplayName(b))
    })
  },
}
