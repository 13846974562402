<template>
  <nav class="c-ResourceHeader" :class="{ 'd-flex': horizontalItems }">
    <!-- Breadcrumbs -->
    <v-breadcrumbs
      v-if="breadcrumbs.length"
      :items="breadcrumbs"
      class="pa-0 flex-wrap"
      :class="{
        'pb-4': items.length && !horizontalItems,
        'flex-wrap': standOutLastItem,
      }"
      role="navigation"
      aria-label="breadcrumbs"
    >
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :exact="item.exact === undefined ? true : item.exact"
          :to="item.to"
          class="pa-0"
          :style="{
            width: standOutLastItem && isLastBreadcrumb(item) ? '100%' : 'auto',
          }"
          :class="{
            'text-primary': Boolean(item.to),
            'text-subtitle-1': small && !isLastBreadcrumb(item),
            'text-h5': !small || (standOutLastItem && isLastBreadcrumb(item)),
            'font-weight-medium':
              !small || (standOutLastItem && isLastBreadcrumb(item)),
          }"
        >
          {{ item.title }}
          <slot v-if="isLastBreadcrumb(item)" name="after-last-breadcrumb" />
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <span
          class="text-black"
          :class="small ? 'text-subtitle-1' : 'text-h5 font-weight-medium'"
        >
          {{ divider }}
        </span>
      </template>
    </v-breadcrumbs>

    <!-- Items -->
    <dl
      v-if="items.length"
      :class="horizontalItems ? 'd-flex align-center justify-end' : null"
      :style="horizontalItems ? 'flex: 1' : null"
      aria-label="Resource information"
    >
      <div
        v-for="(item, idx) in items"
        :key="item.id"
        class="d-flex text-body-2"
        :class="{ 'pb-2': !horizontalItems && idx + 1 !== items.length }"
        role="none"
      >
        <!-- Item Label -->
        <dt class="pr-1">{{ item.label }}</dt>

        <!-- Item Content -->
        <dd
          v-for="(c, i) in item.content"
          :key="`${c.text}-${i}`"
          class="item--text"
        >
          <safe-router-link :to="c.to">
            {{ c.text }}
          </safe-router-link>
        </dd>

        <!-- Divider -->
        <div
          v-if="horizontalItems && idx + 1 !== items.length"
          class="d-inline px-2"
        >
          ·
        </div>
      </div>
    </dl>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BreadcrumbItem, ResourceHeaderItem } from './ResourceHeader'
import SafeRouterLink from '@/components/common/SafeRouterLink.vue'

export default defineComponent({
  name: 'ResourceHeader',
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbItem[]>,
      required: false,
      default: () => [],
    },
    items: {
      type: Array as PropType<ResourceHeaderItem[]>,
      required: false,
      default: () => [],
    },
    horizontalItems: {
      type: Boolean,
      required: false,
      default: false,
    },
    divider: {
      type: String,
      required: false,
      default: '/',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    standOutLastItem: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { SafeRouterLink },
  methods: {
    isLastBreadcrumb(item: BreadcrumbItem): boolean {
      return this.breadcrumbs.indexOf(item) === this.breadcrumbs.length - 1
    },
  },
})
</script>

<style lang="scss">
.c-ResourceHeader {
  ul li a.v-breadcrumbs-item--link {
    text-decoration: none;
  }

  // Decrease the padding a little bit.
  ul li.v-breadcrumbs-divider {
    padding: 0 0.25rem;
  }

  .item--text {
    &:not(:last-of-type)::after {
      content: ', ';
    }
  }
}
</style>
