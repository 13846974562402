<template>
  <div class="c-ControlGroupDetailsSystemMode">
    <!-- Loading user permission -->
    <div v-if="isLoading" class="text-center">
      <v-progress-circular :size="50" color="primary" indeterminate />
    </div>

    <!-- Content -->
    <div v-if="showContent">
      <!-- Not Authorized -->
      <control-group-details-system-mode-not-authorized
        v-if="!isAuthorizedUser"
        :group-id="groupId"
      />

      <!-- Authorized -->
      <control-group-details-system-mode-authorized
        v-if="isAuthorizedUser"
        :key="componentKey"
        :group-id="groupId"
        @success="handleFormSuccess"
        @error="handleFormError"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Empty } from '@bufbuild/protobuf'
import { mapActions } from 'pinia'

import { useGlobalSnackbar } from '@/stores/globalSnackbar'

// Components
import ControlGroupDetailsSystemModeNotAuthorized from './ControlGroupDetailsSystemModeNotAuthorized.vue'
import ControlGroupDetailsSystemModeAuthorized from './ControlGroupDetailsSystemModeAuthorized.vue'

export default defineComponent({
  name: 'ControlGroupDetailsSystemMode',
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  components: {
    ControlGroupDetailsSystemModeNotAuthorized,
    ControlGroupDetailsSystemModeAuthorized,
  },
  data: () => ({
    isLoading: false,
    hasLoadingFailed: false,
    isSnackBarOpened: false,
    snackBarColor: null as string | null,
    snackBarMessage: null as string | null,
    /**
     * `componentKey` is used to force update the form component.
     */
    componentKey: 0,
    /**
     * Only authorized users can schedule an event.
     */
    isAuthorizedUser: false,
  }),
  computed: {
    /**
     * Computed property that controls when the content should be displayed
     * to the user.
     */
    showContent(): boolean {
      return !this.isLoading && !this.hasLoadingFailed
    },
  },
  watch: {
    groupId(): void {
      this.checkUserPermission()
    },
  },
  created(): void {
    this.checkUserPermission()
  },
  methods: {
    ...mapActions(useGlobalSnackbar, ['openSnackbar']),
    async checkUserPermission(): Promise<void> {
      this.isLoading = true

      // Reset state.
      this.hasLoadingFailed = false
      this.isAuthorizedUser = false

      try {
        const res = await this.$services.control.canSetGroupPolicy(new Empty())

        this.isAuthorizedUser = res.value
      } catch (err) {
        this.hasLoadingFailed = true
        console.error(
          'ControlGroupDetailsSystemMode.checkUserPermission: %o',
          err
        )
      } finally {
        this.isLoading = false
      }
    },
    /**
     * Displays the snackbar with success message and reloads the controls
     * component.
     */
    handleFormSuccess(): void {
      this.openSnackbar('Policy successfully changed.', 'info')

      this.componentKey = this.componentKey + 1
    },
    /**
     * Displays the snackbar with error message.
     */
    handleFormError(): void {
      this.openSnackbar(
        'Could not change policy. Please refresh the page and try again. Email support@camus.energy if that fails.',
        'error'
      )
    },
  },
})
</script>

<style lang="scss">
.c-ControlGroupDetailsSystemMode {
  width: 100%;
}
</style>
