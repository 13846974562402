<template>
  <div>
    <button
      class="body-2"
      :class="disabled ? 'text-grey' : 'text-primary'"
      @click.stop="() => !disabled && $emit('click')"
    >
      <slot />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * This component creates a simple and customized HTML Button.
 * The customization happens with the help of Vuetify's CSS utility classes.
 * It emits a single event: `click`.
 * When the component has `disabled` prop as `true`, the event `click` won't
 * be triggered.
 * The default slot accepts any type of value: text, HTML components or
 * Vue components.
 *
 * Example:
 *
 * <flat-btn-small :disabled="false" @click="doSomething">
 *  Your text here (or any other HTML component or Vue component).
 * </flat-btn-small>
 */
export default defineComponent({
  name: 'FlatBtnSmall',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
</script>
