<template>
  <div aria-label="Fixed Interval Picker">
    <v-select
      :model-value="selectedOption"
      :items="options"
      :menu-props="{ maxHeight: 'none' }"
      label="Interval"
      max-width="180"
      @update:model-value="emitNewOption"
    >
      <template v-slot:item="all">
        <template v-if="shouldShowItem(all.item)">
          <!-- Divider -->
          <v-divider
            v-if="shouldShowDivider(all.item)"
            class="my-4"
            :color="NEUTRAL_100.hex"
            style="opacity: 1"
          />

          <v-list-item v-bind:="all.props" />
        </template>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { DateTime, Interval } from 'luxon'
import { NEUTRAL_100 } from '@/constants/colors'
import {
  intervalLast7Days,
  intervalLast30Days,
  intervalLast90Days,
  intervalLast12Months,
  intervalMonthToDate,
  intervalYearToDate,
  intervalLast24Hours,
} from '@/utils/time'

enum FixedIntervalOption {
  LAST_24_HOURS = 'LAST_24_HOURS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_12_MONTHS = 'LAST_12_MONTHS',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  CUSTOM = 'CUSTOM',
}

const OPTION_LABEL: Record<FixedIntervalOption, string> = {
  [FixedIntervalOption.LAST_24_HOURS]: 'Last 24 hours',
  [FixedIntervalOption.LAST_7_DAYS]: 'Last 7 days',
  [FixedIntervalOption.LAST_30_DAYS]: 'Last 30 days',
  [FixedIntervalOption.LAST_90_DAYS]: 'Last 90 days',
  [FixedIntervalOption.LAST_12_MONTHS]: 'Last 12 months',
  [FixedIntervalOption.MONTH_TO_DATE]: 'Month to date',
  [FixedIntervalOption.YEAR_TO_DATE]: 'Year to date',
  [FixedIntervalOption.CUSTOM]: 'Custom',
}

const intervalFactories: Record<
  FixedIntervalOption,
  (now: DateTime) => undefined | Interval
> = {
  [FixedIntervalOption.LAST_24_HOURS]: intervalLast24Hours,
  [FixedIntervalOption.LAST_7_DAYS]: intervalLast7Days,
  [FixedIntervalOption.LAST_30_DAYS]: intervalLast30Days,
  [FixedIntervalOption.LAST_90_DAYS]: intervalLast90Days,
  [FixedIntervalOption.LAST_12_MONTHS]: intervalLast12Months,
  [FixedIntervalOption.MONTH_TO_DATE]: intervalMonthToDate,
  [FixedIntervalOption.YEAR_TO_DATE]: intervalYearToDate,
  [FixedIntervalOption.CUSTOM]: () => undefined,
}

type SelectItem = { title: string; value: FixedIntervalOption }

function computeSelectedOption(
  now: DateTime,
  interval: Interval
): FixedIntervalOption {
  // Last 24 hours.
  if (
    interval.toDuration(['hours']).toObject().hours === 24 &&
    interval.end.hasSame(now, 'day')
  ) {
    return FixedIntervalOption.LAST_24_HOURS
  }

  // Other options.
  for (const [option, factory] of Object.entries(intervalFactories)) {
    if (interval.toISO() === factory?.(now)?.toISO()) {
      return option as FixedIntervalOption
    }
  }
  return FixedIntervalOption.CUSTOM
}

function createInterval(
  now: DateTime,
  option: FixedIntervalOption
): undefined | Interval {
  const factory =
    option !== FixedIntervalOption.CUSTOM
      ? intervalFactories[option]
      : undefined
  return factory?.(now)
}

export default defineComponent({
  name: 'FixedIntervalPicker',
  props: {
    interval: {
      type: Object as PropType<Interval>,
      required: true,
    },
  },
  emits: ['interval'],
  setup() {
    return {
      NEUTRAL_100,
      options: Object.values(FixedIntervalOption).map((option): SelectItem => {
        return { title: OPTION_LABEL[option], value: option }
      }),
    }
  },
  data() {
    return {
      selectedOption: computeSelectedOption(
        this.$observationTime(),
        this.interval
      ),
    }
  },
  watch: {
    interval(newValue: Interval) {
      const newOption = computeSelectedOption(this.$observationTime(), newValue)

      if (newOption !== this.selectedOption) {
        this.selectedOption = newOption
      }
    },
  },
  methods: {
    shouldShowItem(item: SelectItem): boolean {
      return item.value !== FixedIntervalOption.CUSTOM
    },
    shouldShowDivider(item: SelectItem): boolean {
      return item.value === FixedIntervalOption.MONTH_TO_DATE
    },
    emitNewOption(newOption: FixedIntervalOption) {
      const newInterval = createInterval(this.$observationTime(), newOption)

      if (newInterval) {
        this.$emit('interval', newInterval)
      }
    },
  },
})
</script>
