import { DateTime } from 'luxon'
import {
  PeakLoadAnalytics,
  PeakLoadAnalyticsResponse,
} from 'rfs/frontend/proto/forecast_pb'
import { Date } from 'rfs/pb/calendar_pb'
import { Services } from '@/services'
import { numberOrNull } from '@/utils/lang'
import {
  HistoricalContextChartDataProvider,
  Options,
} from './HistoricalContextChartDataProvider'
import { PeakPeriod } from '@/services/forecast.service'

const MAX_FORECAST_DAYS = 6

export class ForecastPeakLoadAnalyticsData extends HistoricalContextChartDataProvider {
  constructor(services: Services, options: Options) {
    super(services, options)
  }

  public override clone(
    newOptions: Partial<Options>
  ): HistoricalContextChartDataProvider {
    return new ForecastPeakLoadAnalyticsData(
      this.services,
      Object.assign({}, this.options, newOptions)
    )
  }

  override async getTimeSeries(): Promise<void> {
    const res = await this.fetchData()

    for (const v of res.values) {
      this.createLoadActualDataPoint(v)
      this.createLoadForecastDataPoint(v)
      this.createMinMaxDataPoint(v)
      this.createHighestPeaksDataPoint(v)
    }
  }

  private async fetchData(): Promise<PeakLoadAnalyticsResponse> {
    return this.services.forecastService.getPeakLoadAnalytics(
      this.options.balancingArea,
      this.options.numberOfYears,
      this.options.numberOfHighestPeakDaysPerMonthPerYear,
      this.options.referencePeriod,
      this.options.productId
    )
  }

  private createLoadActualDataPoint(value: PeakLoadAnalytics): void {
    const y = numberOrNull(value.actualPeakLoadValue)

    this.saveMinOrMaxLoadValue(y)
    const dayNumber = this.getDayNumber(value.date!)
    this.loadActualSeries[0].data.push({ x: dayNumber, y })
  }

  createLoadForecastDataPoint(value: PeakLoadAnalytics): void {
    // If the date is more than 6 days in the future, skip
    // CP chart only shows load forecast for today + 6 days
    if (
      this.dateToDateTime(value.date!).diffNow('days').days > MAX_FORECAST_DAYS
    )
      return

    const y = numberOrNull(value.forecastPeakLoadValue)

    this.saveMinOrMaxLoadValue(y)

    const dayNumber = this.getDayNumber(value.date!)

    this.loadForecastSeries[0].data.push({
      x: dayNumber,
      y: numberOrNull(value.forecastPeakLoadValue),
    })
  }

  createMinMaxDataPoint(value: PeakLoadAnalytics): void {
    const loadMin = value.minPeakLoadValue
    const loadMax = value.maxPeakLoadValue

    this.saveMinOrMaxLoadValue(loadMin)
    this.saveMinOrMaxLoadValue(loadMax)

    const valid = isFinite(loadMin) && isFinite(loadMax)

    const dayNumber = this.getDayNumber(value.date!)

    this.minMaxSeries[0].data.push({
      x: dayNumber,
      y: valid ? { min: loadMin, max: loadMax } : null,
    })
  }

  createHighestPeaksDataPoint(value: PeakLoadAnalytics): void {
    const dayNumber = this.getDayNumber(value.date!)

    const yearsWithDataPoints = new Set<string>()

    // NOTE(rafael): Capture high/peaks.
    // Some years may not have high/peaks in the day of the month.
    for (const p of value.topPrevious) {
      const year = `${p.year}`
      yearsWithDataPoints.add(year)

      const series = this.highestPeaksSeries.find(({ name }) => name === year)
      if (series === undefined) {
        console.debug(
          `ForecastHistoricalContextChartDataProvider.createHighestPeaksDataPoint: year "${year}" not expected`
        )
        continue
      }

      const isPeak = p.rank === 0

      const loadValue = p.peakLoadValue
      this.saveMinOrMaxLoadValue(loadValue)

      const { label: highLabel, shape: highShape } =
        this.options.series.highestPeaks.high

      const { label: peakLabel, shape: peakShape } =
        this.options.series.highestPeaks.peak

      const newY = {
        label: isPeak ? peakLabel : highLabel,
        value: loadValue,
        shape: isPeak ? peakShape : highShape,
      }

      const dayDataPoint = series.data.find(({ x }) => x === dayNumber)

      if (dayDataPoint === undefined) {
        series.data.push({
          x: dayNumber,
          y: [newY],
        })
      } else if (dayDataPoint.y === null) {
        dayDataPoint.y = [newY]
      } else {
        dayDataPoint.y.push(newY)
      }
    }

    // NOTE(rafael): When the year doesn't have any high/peak in
    // the day of the month, create an empty data point.
    for (const s of this.highestPeaksSeries) {
      const year = s.name
      if (!yearsWithDataPoints.has(year)) {
        s.data.push({ x: dayNumber, y: null })
      }
    }
  }

  private saveMinOrMaxLoadValue(value: number | null): void {
    if (value !== null) {
      if (this.minLoadValue === undefined || value < this.minLoadValue) {
        this.minLoadValue = value
      }
      if (this.maxLoadValue === undefined || value > this.maxLoadValue) {
        this.maxLoadValue = value
      }
    }
  }

  // Returns the day number of the month (TWELVE_CP) or the day number of the year (ONE_CP).
  private getDayNumber(date: Date): number {
    return this.options.referencePeriod === PeakPeriod.TWELVE_CP
      ? date.day
      : this.dateToDateTime(date).ordinal
  }

  private dateToDateTime(date: Date): DateTime {
    return DateTime.fromObject({
      year: date.year,
      month: date.month,
      day: date.day,
    })
  }
}
