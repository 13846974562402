import {
  Chart as ChartJS,
  ChartType,
  Filler,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  ActiveElement,
  Point,
  ScatterController,
  TooltipModel,
  TooltipPosition,
  TooltipPositionerFunction,
} from 'chart.js'
import 'chartjs-adapter-luxon'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import ZoomPlugin from 'chartjs-plugin-zoom'
import { BLACK, WHITE, ExtraPalette } from '@/constants/colors'
import { colorWithOpacity } from '../colors'
import SyncPlugin from './syncPlugin'
import { PersistPlugin } from './persistPlugin'

// @ts-ignore
window.Chart = ChartJS

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  BarElement,
  LineElement,
  PointElement,
  ScatterController,
  Filler,
  Title,
  Tooltip,
  Legend,
  AnnotationPlugin,
  SyncPlugin,
  PersistPlugin,
  ZoomPlugin
)

const defaultLegendGenerateLabels =
  ChartJS.defaults.plugins.legend.labels.generateLabels

// https://www.chartjs.org/docs/latest/configuration/animations.html#disabling-animation
ChartJS.defaults.animations.numbers = false // The animation of new chart data is slow and somewhat unhelpful
ChartJS.defaults.animations.colors = false // disables animation defined by the collection of 'colors' properties
ChartJS.defaults.transitions.active.animation.duration = 0 // disables the animation for 'active' mode
ChartJS.defaults.transitions.hide.animations.visible = false // clicking a legend hides the series immediately

ChartJS.defaults.font.family = "'IBM Plex Sans', serif"
// Configure tooltips to appear when the mouse is over any part of the x-axis
ChartJS.defaults.interaction.mode = 'nearest'
ChartJS.defaults.interaction.axis = 'x'
ChartJS.defaults.interaction.intersect = false
// Allow the height of a specific chart to be set via a component
ChartJS.defaults.maintainAspectRatio = false
// Make the legend series box a square
ChartJS.defaults.plugins.legend.labels.boxWidth = ChartJS.defaults.font.size!
ChartJS.defaults.plugins.legend.labels.generateLabels = customGenerateLabels
// Change the defaults for the chart title here
ChartJS.defaults.plugins.title.align = 'start'
ChartJS.defaults.plugins.title.color = 'rgba(0, 0, 0, 0.87)'
ChartJS.defaults.plugins.title.font = { weight: 500, size: 20 }
ChartJS.defaults.plugins.title.padding = { top: 5, bottom: 25 }
// Change the defaults for the tooltip to match Apex style
ChartJS.defaults.plugins.tooltip.backgroundColor = WHITE.hex
ChartJS.defaults.plugins.tooltip.bodyColor = BLACK.hex
ChartJS.defaults.plugins.tooltip.borderColor = ExtraPalette.gray100.hex
ChartJS.defaults.plugins.tooltip.borderWidth = 1
ChartJS.defaults.plugins.tooltip.boxPadding = 4
ChartJS.defaults.plugins.tooltip.caretSize = 0
ChartJS.defaults.plugins.tooltip.titleColor = BLACK.hex
ChartJS.defaults.plugins.tooltip.titleMarginBottom = 12

// NOTE(andrew): Enabling major ticks highlights reasonable time boundaries in the chart.
ChartJS.defaults.scales.time.ticks.major.enabled = true
// Set defaults for the time series chart x-axis
// ChartJS.defaults.scales.time.ticks.maxTicksLimit = 12
// NOTE(rafael): ensure that the X-Axis labels are always displayed horizontally.
// ChartJS.defaults.scales.time.ticks.maxRotation = 0
ChartJS.defaults.scales.time.time.minUnit = 'hour'
ChartJS.defaults.scales.time.time.displayFormats = {
  day: 'dd MMM',
  hour: 'HH:mm',
}
ChartJS.defaults.scales.time.time.tooltipFormat = 'dd MMM yyyy, HH:mm (ZZZZ)'

/**
 * Generate labels which show being hidden by dimming the label text
 * and removing the color box fill.
 */
function customGenerateLabels(chart: ChartJS) {
  return defaultLegendGenerateLabels(chart).map((item) => {
    if (item.hidden) {
      item.hidden = false
      item.fontColor = colorWithOpacity(BLACK.hex, 0.4)
      item.fillStyle = 'transparent'
    }
    return item
  })
}

/**
 * Position the tooltip in a specific location on the chart.
 * @function Tooltip.positioners.fixedPosition
 * @param elements {Chart.Element[]} the tooltip elements
 * @param _eventPosition {Point} the position of the event in canvas coordinates
 * @returns {TooltipPosition} the tooltip position
 */
function fixedPositionTooltip(
  this: TooltipModel<ChartType>,
  elements: readonly ActiveElement[],
  _eventPosition: Point
): TooltipPosition {
  let pos = this.chart.chartArea.right
  // Determine the area where the tooltip will be displayed
  const area = { x: this.chart.width - this.width, y: this.height + 64 }
  // Move the tooltip farther left if it covers the active elements
  if (elements.some(({ element: el }) => el.x > area.x && el.y < area.y)) {
    pos -= this.width
  }
  // `y: 64` to account for the chart menu.
  return { x: pos, y: 64 }
}

// Use module-augmentation to add our tooltip function to the known list
declare module 'chart.js' {
  interface TooltipPositionerMap {
    fixedPosition: TooltipPositionerFunction<ChartType>
  }
}

Tooltip.positioners.fixedPosition = fixedPositionTooltip
